<template>
<div class="me-code-wrapper">
   <svg version="1.1" class="me-code" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 974.5 953.3" style="enable-background:new 0 0 974.5 953.3;" xml:space="preserve">
      <path class="st0" d="M349.1,445.4c0,0,5.2,150.1,57.1,248.8s64.2,118.4,64.2,118.4L551,693.3c0,0,45.7-163.5,47-247.9H349.1z"/>
      <path class="st1" d="M428.5,472.2c0,0,0.2,6.3,1.1,17.1c13.9-0.9,27.8,0.7,41.2,4.6c7.5,2,14.6,5.1,21.2,9.2
         c16.5,10.7-48,26.8-42.9,44.4c2,7,1.2,13-0.1,20c-1.8,10.4-1.2,21.1-0.7,31.6c0.7,13.2,2.1,26.4,4.3,39.5
         c-17.5-14.8-35.5-30.2-44.9-50.4c-4.9-10.6-7.3-22.1-9.6-33.4c-11.7,15.7-13.4,36.4-9.3,55.1c4.1,18.7,13.6,36,22.9,53.1l30.2,55.1
         c11,20,22.2,40.8,23.1,63.3c0,1,0,2.1,0,3.1c1.8,2.8,2.4,3.8,2.4,3.8l75.3-103.6c0,0,42.7-141.9,44-215.2L428.5,472.2z"/>
      <g id="FACE">
         <path class="st0" d="M310.4,292.1c-1.7,0-26.3-23.3-20.9,21.6s6.7,55.3,10.1,76.1s26,25.2,30.7,25.5
            C335,415.4,312.2,292,310.4,292.1z"/>
         <path class="st0" d="M634.4,286.8c0,0,21.7-24.7,29.8,0S675,321,657,392.7c0,0-7.3,30-31.9,23.1C619.6,402.3,629,337.6,634.4,286.8
            z"/>
         <path class="st0" d="M325.7,382.6c0,0,7.2,75.4,18,85.3c10.8,9.9,71,85.3,88.9,88s40.8,0,40.8,0s36.4,12.6,66.9-12.6
            c30.5-25.1,92.5-82.6,85.3-141l6.3-63.7l2.7-58.4c0,0,20.6-171.5-164.3-168.8S309.7,276.6,309.7,276.6S309.6,368.3,325.7,382.6z"/>
         <path class="st1" d="M324.4,377.3c0,0,7.2,75.4,18,85.3s71,85.3,88.9,88s40.8,0,40.8,0s36.4,12.6,66.9-12.6
            c30.5-25.2,92.5-82.6,85.3-141l6.3-63.7l2.7-58.4c0,0,20.7-171.4-164.3-168.7S308.3,271.4,308.3,271.4S308.2,362.9,324.4,377.3z"/>
         <path class="st1" d="M307.7,286.6c-1.7,0-26.6-23-20.6,21.8s7.4,55.2,11.1,76s26.3,24.9,31,25.1S309.5,286.5,307.7,286.6z"/>
         <path class="st1" d="M633,290.3c0,0,21.7-23.2,29.8,0s10.8,32.2-7.2,99.5c0,0-7.3,28.2-31.9,21.7C618.3,398.8,627.6,338,633,290.3z
            "/>
         <path class="st2" d="M419.1,320.3c8.4,0,16.6,0.4,24.3,1c-3.3-7.7-13.9-13.4-26.5-13.4c-12.8,0-23.6,5.9-26.7,13.9
            C399.8,320.8,409.4,320.3,419.1,320.3z"/>
         <path class="st2" d="M535.1,320.3c-8.4,0-16.6,0.4-24.3,1c3.3-7.7,13.9-13.4,26.5-13.4c12.8,0,23.6,5.9,26.7,13.9
            C554.4,320.8,544.8,320.3,535.1,320.3z"/>
         <path class="st0" d="M297.4,362.3c0.8-5.9-0.5-11.9-0.5-17.8s1.6-12.5,6.6-15.7c0.8,6.1-0.1,12.3-0.2,18.5s0.8,12.7,4.5,17.6
            c2.6,3.4,6.7,6.5,6.7,10.8c-0.1,4.1-4.1,7.6-3.3,11.5c0.2-3.3,2-6.4,4.9-8.1c1.3-0.8,2.9-1.4,3.6-2.8c0.3-0.8,0.5-1.7,0.4-2.6
            l-0.3-58.7c0.1-3.9-0.2-7.7-1-11.5c-0.3-1.5-0.9-2.9-1.7-4.2c-3.3-5.5-10.4-7.2-15.9-4c-1.9,1.2-3.5,2.8-4.4,4.8
            c-0.8,1.7-0.6,4-0.7,5.8c-0.2,2.6-0.4,5.1-0.5,7.7c-0.5,10.4-0.4,20.8,0.2,31.2C296.1,350.6,296.6,356.5,297.4,362.3z"/>
         <path class="st0" d="M654.3,362.2c-0.6-5.9,1-11.8,1.2-17.8c0.2-5.9-1.2-12.6-6-16c-1,6.1-0.4,12.3-0.5,18.5
            c-0.2,6.2-1.3,12.6-5.2,17.4c-2.8,3.3-7,6.2-7.1,10.5c-0.1,4.1,3.8,7.7,2.9,11.7c-0.1-3.3-1.8-6.4-4.5-8.3
            c-1.3-0.9-2.9-1.5-3.5-2.9c-0.3-0.9-0.4-1.8-0.3-2.7l2.6-58.7c0-3.9,0.5-7.7,1.4-11.4c0.4-1.5,1-2.9,1.8-4.2
            c3.5-5.3,10.7-6.8,16-3.3c1.9,1.2,3.3,3,4.2,5c0.7,1.7,0.4,4,0.5,5.8c0.1,2.6,0.2,5.1,0.2,7.7c0.1,10.4-0.4,20.8-1.4,31.2
            C656.1,350.6,655.3,356.5,654.3,362.2z"/>
         <path class="st2" d="M650.2,314.1c3-2,5.1-5.3,5.6-8.9c-3.3,1.9-7.8-0.9-9-4.5c-1.2-3.7-0.3-7.7,0.5-11.4c0.8-3.7,1.5-7.9-0.3-11.3
            l3.8,1.1c0.1-2.7,0.1-5.5,0.2-8.2l6.4,2.1c-0.6-21.4-6-42.3-15.7-61.4c5.8,2.6,13.3-2.5,13.1-8.8c-2.4,2.8-7.3,1.4-9.3-1.7
            s-1.7-7.1-1.4-10.7l1.8-23.4c-5.2,4.3-12.1,6.2-18.8,5.2c1.1-16-5.6-31.5-18-41.6c-3.5-2.9-7.6-5-12-6c-0.2-0.6-0.4-1.2-0.6-1.8
            c-1-3.1-2.1-6.4-4.5-8.7c-3-3-7.4-3.8-11.4-5.3c-9.8-3.6-17.7-11-26.4-16.7C536,80.2,513.9,76,492.4,72.6c-1.5-0.2-3.2-0.6-4-1.9
            c-0.5-1-0.7-2.2-0.6-3.4l0.7-18.4c-4.2,8-14,11-23,12.4s-18.6,2.2-25.3,8.2c0.9-1.8,0.7-3.9-0.4-5.5c-6.3,12.3-17,21.8-30,26.5
            c0.4-3.7-1.6-7.2-4.9-8.9c3,9.6-6.5,18.3-15.2,23.4c-7.4,4.3-16.1,9.6-18.4,17.3c-13.7,0.2-28.2,1.1-38.9,9.5
            c-8.5,6.7-13,17.1-18.9,26.1c-4.2,6.4-9.2,12.4-11.7,19.6c-5,14.4,1,30.5-2.6,45.3c-1.9,7.6-6.2,14.4-8.1,22s-0.4,17.1,6.4,20.8
            l-0.1-5.4c5.6,2.2,5.8,10.9,1.6,15.2c-4.2,4.3-10.8,5.1-16.8,4.8c1,2.3,2,4.6,3,6.9l-8.5,0.6c9.7,10,23.6,14.8,37.3,12.9
            c-0.5,6.9-5.4,12.7-12.1,14.4c2.3,0.8,3.4,3.6,3,6s-1.9,4.4-3.3,6.4l9.7,0.6c-1.2,6.9-2.3,14.1-0.4,20.8c1.9,6.7,8,12.8,15,12.5
            c1.1-17.9,2.3-35.7,3.7-53.5c0.9-11.2,1.9-22.6,6.2-33c3.3-8.1,8.6-15.3,11.6-23.5c4.1-10.9,4.1-22.9,8-33.9
            c3.4-9.6,9.6-17.9,13.6-27.2c3.4-8.1,5.2-16.8,8.2-25c1.2-3.3,2.6-6.6,4.2-9.8c1.4-2.9,3-5.8,4.6-8.6c1.3-2.3,3.1-4.7,4.1-7.3
            c5.2,0.1,10.5-0.9,15.7-1.3c6.1-0.6,12.1-0.8,18.2-0.6c6.9,0.2,13.7,1.1,20.3,2.9c7.3,2,14.9,5.2,22.3,3.2
            c1.4,7.3,8.8,11.7,15.8,14.1c7,2.4,14.8,4,20,9.2c5.2,5.3,5.5,16-1.4,18.7c6.2,0.2,11.6-4.4,12.3-10.5c12.5-1.4,25.8-2.7,37.2,2.6
            s19.6,19.8,13.6,30.8c5.3-1.8,10.8-3.6,15.2-7c2.3-1.7,4.1-3.9,5.5-6.4c0.5,0.1,1,0.2,1.6,0.3c2.9,0.8,5.5,2.5,7.3,4.9
            c2.3,2.8,4,6.1,4.8,9.7c1.7,7.4,0.7,15.1,1.3,22.7c2.2,27.3,24.9,49.2,29.8,76.1c0.9,5.2,1.3,10.4,1.3,15.6
            c0.1,13.7-3,29.7-15.2,36c16.3-4.7,28.9-20.4,29.9-37.3c4.5,0.7,9.1,1.3,13.4,0.1c4.3-1.2,8.4-4.9,8.5-9.4
            C661.5,314.5,655.8,314.6,650.2,314.1z"/>
         <path class="st0" d="M429.3,285.4c5.1,1.8,9.6,5.3,13.8,9c0.7,0.6,1.3,1.2,1.9,1.9c3.3,4,2.9,9.9,2.4,15.1
            c-0.6,5.8-1.3,11.8-4.4,16.6c-3.1,4.7-8.3,7.5-12.2,11.5c7-1.5,13.3-5.4,17.8-11.1c3.7,4.9,5.4,11.2,5.8,17.5s-0.3,12.5-1,18.8
            c6-7.3,9.8-16.2,11.1-25.5c0.5-4.4,0.8-8.8,0.8-13.2c0.1-6.2,0.2-12.4-0.8-18.4c-0.7-3.6-1.6-7.2-2.8-10.7
            c-0.7-2.4-1.5-4.9-3.1-6.9c-2-2.5-5.1-3.7-8.1-4.8c-8.6-3.1-17.3-5.6-26.3-6.5c-4.4-0.4-8.7-0.3-13.1,0.2
            c-4.4,0.6-8.6,1.8-12.6,3.6c-0.7,0.4-1.4,0.8-0.3,1.1c1.3,0.3,2.6,0.4,3.9,0.3c4.1,0.1,8.1-0.1,12.2-0.3c4.5-0.3,9,0,13.3,1.1
            C428.2,285,428.7,285.2,429.3,285.4z"/>
         <path class="st3 left-eyebrow" d="M520.8,287.8c13.4,0.7,26.8,1,40.1,1c3.6-0.1,7.3,0.1,10.9,0.5c3,0.5,6,1.3,8.9,2.2l15,4.5
            c-4.4-1.3-7.7-5-11.7-7.1c-4-2.2-8.4-2.7-12.8-3.7c-9.9-2.2-19.4-6.1-29.2-9c-7.8-2.3-16.4-4.2-24.6-3.3c-2.3,0.1-4.5,1.1-6.1,2.6
            c-2,2.1-3.1,5.5-3.4,8.3c-0.3,2.4,3.6,2.8,5.3,3.2C515.8,287.5,518.3,287.7,520.8,287.8z"/>
         <path class="st3" d="M417.1,283.9c-21.8-0.8-42.9,10.5-64.5,8.1c6.3-1.2,12.4-2.8,18.5-4.7c11.3-3.5,22.2-8,33.6-11.3
            s23.3-5.2,35-3.3c2.8,0.5,5.7,1.2,7.9,3c2.1,1.6,3.1,4.1,2.8,6.7c-0.3,2.1-2.1,3.7-4.3,3.8c-5.9-0.1-11.8-0.5-17.6-1.2
            C424.7,284.6,420.9,284.1,417.1,283.9z"/>
         <path class="st0" d="M373.3,429.5c0.2,6.8,0.2,13.6,0.2,20.4c0.1-5.8-1.3-11.5-3.9-16.6c-0.5-1.1-1.2-2.1-2.1-2.9
            c-0.6-0.4-1.1-0.8-1.6-1.2c-0.5-0.8-0.9-1.7-1-2.6c-0.8-3.9-1.5-7.8-2.3-11.7c-0.3-1.6-1-3.5-1-5.2c0-0.7,0.4-1.4,1.1-1.6
            c1.7-0.7,3.9,0.4,5.3,1.3c2.4,1.7,3.3,4.6,4,7.4C372.9,420.8,373.4,425.2,373.3,429.5z"/>
         <path class="st0" d="M583,436.4l6.4-16.8c1.1-2.9,2.2-6.1,1.3-9.1c-0.6-2.2-2.5-3.7-4.7-3.9c-2-0.1-3.1,1-3.1,2.9
            c0,5.6,0.3,11.2,0.3,16.8C583.2,429.7,583.1,433.1,583,436.4z"/>
         <path class="st0" d="M392.2,508.4c-3.4-9.2-1-20-5.4-28.8c5.1,16,13,31,23.4,44.3c7.1,9.1,15.3,17.5,19.6,28.2
            c-8.2-7.9-20.9-11.2-27.2-20.6c-1-1.4-1.5-3.4-2.8-4.3c-1.4-0.9-2.6-2-3.7-3.2c-0.7-0.8-1-1.8-0.9-2.9c0-1.8,0.4-4.5-0.3-6.2
            C394,512.7,393,510.6,392.2,508.4z"/>
         <path class="st0" d="M510.3,396.6c-11.3,6.3-23.2,12.4-36.1,13.1c-3.6,0.3-7.1-0.1-10.5-1.2c-2.5-1-4.9-2.3-7.2-3.8l-6.9-4.3
            c-1.3-0.8-2.2-1.7-0.5-2.6c1.5-0.7,3.2-1,4.9-0.7c2.6,0.4,5.1,0.8,7.7,1.2c10.4,1.3,20.9,1.6,31.3,0.7c2.9-0.2,5.9-0.5,8.8-1
            c1.4-0.2,2.9-0.4,4.3-0.7l2.2-0.4C509,396.9,509.7,396.8,510.3,396.6z"/>
         <path class="st0" d="M532,398.8c3.8,3.5,8.8,5.3,13.4,7.7c6.7,3.4,13.3,8.5,14.9,15.9c1.9,8.7-3.8,17.3-10.4,23.3
            c6.9-5.5,12.1-13.4,12.8-22.2c0.7-8.8-3.6-18.1-11.5-22c-5.2-2.6-11.6-2.8-16.3-6.3c-5.5-4.5-6.7-12.5-2.8-18.5
            c-0.4,0.6-1.6,1.1-2.2,1.6c-0.7,0.7-1.4,1.4-1.9,2.2c-0.9,1.2-1.6,2.6-1.8,4c0,1.3,0.1,2.5,0.4,3.8c0.2,1.9,0.7,3.7,1.4,5.4
            C529,395.6,530.3,397.3,532,398.8z"/>
         <path class="st0" d="M412.5,404.8c-2.5,1.2-5.1,2-7.6,3.1c-9.8,4.8-15.7,15.2-14.8,26.1l-0.6-19.2c-0.1-2.7-0.1-5.7,1.3-8
            c2.2-3.5,6.8-4.2,10.8-5.1c5.3-1.1,10.2-3.5,14.3-6.9c4-3.6,6.5-8.6,6.9-14c0.9,2,1.6,4,2,6.1c0.4,1.3,0.4,2.7,0,4
            c-1.3,3.8-3.6,7.2-6.6,9.9C416.5,402.4,414.5,403.7,412.5,404.8z"/>
         <path class="st0" d="M457.7,488.9l3.5-2.1c1.9-1.2,4-2.2,6.2-2.9c1.9-0.4,3.8-0.6,5.8-0.6c3.1,0,6.4-0.1,9.3,1.1
            c1.6,0.7,5.1,2.6,3.7,4.9c-0.6,1.1-1.9,0.4-2.8,0.2c-1.6-0.4-3.2-0.7-4.8-1c-3.3-0.4-6.6-0.6-9.9-0.5c-1.8,0.1-3.7,0.2-5.5,0.3
            c-1,0.1-1.9,0.2-2.8,0.2C459.7,488.7,458.3,488.6,457.7,488.9z"/>
         <path class="st0" d="M398.4,428.5c0,0,29.7,14.7,71.7,12.9s62.9-3,77.7-12.9c0,0,1.7,13.9-78.1,17.1
            C469.8,445.5,407.8,441.1,398.4,428.5z"/>
         <path class="st4" d="M398.4,416.3"/>
         <path class="st2" d="M398.4,428.5c0,0,73.5,31.4,149.4,0c0,0-15.3,43.8-74.7,42.5S398.4,428.5,398.4,428.5z"/>
         <path class="st5" d="M398.1,428.5c0,0,0.3,0.9,1.1,2.4c2.7,4.1,7.2,7.4,10.9,10.1c11.1,8,23.9,13.3,37.4,15.5
            c1.7,0.3,3.4,0.5,5.1,0.7c23.3,2.7,46.8-2.4,69-10c7.2-2.3,14.2-5.3,20.9-9c2-3.1,3.8-6.3,5.1-9.8
            C471.7,460.1,398.1,428.5,398.1,428.5z"/>
         <path class="st6" d="M437.8,234.4c10.4-3.1,19.9-10.7,23.2-21c4.4-13.7-3-29-14.3-38c-11.2-9-25.6-13-39.6-16.6
            c-1.7-0.6-3.6-0.8-5.4-0.6c-2.9,0.5-5.1,2.9-6.7,5.2c-9.3,13-9.1,29.3-3.5,43.6c1.9,4.9,3.1,10.4,6,14.8c3.2,4.9,8.8,8.6,13.9,11.1
            C419.6,236.7,429.2,237,437.8,234.4z"/>
         <path class="st6" d="M462.1,365.4c-1.8,2.8-3.2,5.7-4.4,8.7c-0.7,1.8-1.5,3.8-0.8,5.6c0.7,1.3,1.7,2.4,3,3
            c4.1,2.6,8.7,4.4,13.5,5.4c4,0.8,9,0.6,11.2-2.8c0.6-1,1-2.1,1.3-3.2c0.9-3.4,1.8-6.8,2.6-10.2c0.5-2.1,1-4.2,0.4-6.3
            c-0.6-1.6-1.4-3.1-2.5-4.4c-3.1-4.1-6.5-8.5-11.3-10.7c-0.8-0.4-2.1-1.1-3-0.6c-0.7,0.4-0.8,1.2-0.9,1.9c-0.4,2.3-1.5,4.5-3,6.3
            C466.1,360.5,463.9,362.8,462.1,365.4z"/>
         <path class="st6" d="M381.2,333.2c-7.3,2.5-13.3,8.5-19.1,14.4c-3.7,3.7-7.5,7.7-9.2,13c-0.3,0.8-0.4,1.7-0.2,2.6
            c0.3,0.7,0.9,1.3,1.6,1.7c7.3,4.5,15.9,4.5,24.2,4.3c9.8-0.2,19.9-0.4,29.1-4.7c4.5-2.1,8.9-5.6,10.8-10.9c0.8-2.5,1.2-5.1,1.2-7.7
            c0.1-4.2-0.4-8.2-3.1-11.1c-1.6-1.8-3.4-3.2-5.6-3.3c-2.2-0.1-4.3,0.5-6.4,0.6c-3.6,0.1-7.2-0.4-10.8-0.4
            C389.4,331.4,385.2,331.9,381.2,333.2z"/>
         <path class="st6" d="M583.7,334c-6.4-1.2-12.8-2-19.3-2.5c-6-0.5-12.1-0.6-18,1s-11.5,5-14.8,10.4c-0.7,0.9-1.1,2.1-1.1,3.2
            c0.2,1.4,0.9,2.6,2,3.5c2.9,2.6,6.7,3.5,10.3,5c8.6,3.5,15.8,10.2,23.8,15.3c8,5.1,17.7,8.6,26.4,5.2c7.3-2.9,12.6-10.9,12.8-19.3
            s-4.7-16.8-11.8-20.1c-4.9-2.3-10.4-2.3-15.7-1.8"/>
         <path class="st6" d="M445.3,512.5c-3.5,2.3-6.7,6.4-5.4,10.5c1.3,4.2,6.6,5.8,11,5.4c5-0.3,9.7-2.1,13.7-5
            c2.9,4.3,7.1,7.9,12.2,9.1c5.1,1.1,10.9-0.7,13.7-5.1c2.7-4.4,1.7-10.4-1.5-14.5c-3.2-4.1-8.2-6.4-13.2-7.4
            c-1.5-0.4-3.2-0.4-4.7,0.1c-1.7,0.7-2.9,2.1-4.4,3.1c-1.5,0.9-2.2,0.3-3.8-0.1c-3.6-1-8.3,0-11.7,1.2
            C449.1,510.3,447.1,511.3,445.3,512.5z"/>
         <path class="st0" d="M553.5,510c3.4-9.7,1-21,5.4-30.2c-5.1,16.7-13,32.5-23.4,46.5c-7.1,9.5-15.3,18.4-19.6,29.6
            c8.2-8.3,20-12.7,26.3-22.7c1-1.5,1.8-3,2.8-4.5c1-1.3,2.3-2.5,3.7-3.4c1-0.8,0.9-1.9,0.9-3c0.1-1.9,0.5-3.8,1.2-5.6
            C551.7,514.5,552.7,512.3,553.5,510z"/>
         <path class="st0" d="M626.1,412.5V373c0,0-9.4,78.9-15.3,86.9C610.8,460,627.5,428,626.1,412.5z"/>
         <polygon class="st0" points="343.7,468 324.4,377.3 336.6,456.1 	"/>
         <path class="st3" d="M328.5,275.7c-3.6,2.3-7.1,4.9-10.4,7.6C322.1,281.5,325.6,278.9,328.5,275.7z"/>
         <path class="st3" d="M375.2,137.8c-0.6,0-1.2,0-1.8,0c0,0.4,0.1,0.9,0.4,1.2C374.5,140,376.5,137.9,375.2,137.8z"/>
         <path class="st3" d="M379,117.7l-6.4,0.4c-0.6,1-1,2-1.4,3.1c-4.7,0.1-9.4,0.2-14.1,0.7c1.9,0.4,3.8,0.7,5.8,0.8
            c-14.7,7-29,14.7-42.8,23.2c-1.4,2.3-2.7,4.6-4.1,7c2.9,0.8,5.6,2.2,8.1,4c-6,2.8-11.7,6-17.2,9.7c-1.4,2.1-2.7,4.4-3.7,6.7
            l4.8,1.1l-7.2,5.8c-0.6,2.8-0.9,5.7-1,8.6l11.1,1.4c-2.8,5.9-6.3,11.3-10.5,16.4c0.1,1.8,0.1,3.6,0,5.4l4,0.7
            c0.5,7-3.1,12.9-8.3,17.8l-1.9,4.6c4.3-1.6,8.6-2.9,13.1-3.9c-1.4,3.7-2.8,7.5-4.2,11.2l11.6-8.5c-7.7,7.9-15.3,15.7-23,23.6
            l0.1,0.1c3.3-2.9,6.9-5.4,10.8-7.5c0.2,5.3,0.4,10.9-0.6,16.1c0.2,3-0.8,6-2.9,8.2l-0.2,0.2c-0.3,0.5-0.6,1-1,1.5
            c-3.2,4.4-7.9,7.2-12.7,9.9l0.1,0.2h-0.5c-1.8,1-3.7,2-5.4,3.1c2.1,1.8,4.3,3.4,6.7,4.8c0.5-0.1,1-0.1,1.4-0.1v-0.1
            c0.1-0.8,1-1.1,1.8-1.3c4.4-0.8,8.9-1.4,13.4-1.8c-0.9,2.8-1.4,5.7-1.5,8.7c4.3,0.7,8.6,0.8,12.9,0.2c-0.5,6.5-4.8,12-10.9,14
            c-0.1,0.3-0.2,0.6-0.3,1c1.6,1.3,2.3,3.4,1.9,5.4c-0.4,2.4-1.9,4.4-3.3,6.4l4.6,0.3c3.1-3.5,6.8-6.4,10.9-8.6
            c3.9-5.9,8.2-11.5,12.9-16.7c-4.3,2.6-8.5,5.1-12.8,7.7c-1.4-5.9,1.1-12,6.1-15.2c-5,1.4-10.3,1.8-15.5,1.2l11-4.2l-8.8-0.2
            c2.6-2.6,5.4-5,8.2-7.3c-0.4,0.2-0.9,0.4-1.3,0.6c4.2-14.1,11.8-27,22.2-37.4c-8.4,7.2-17,14.1-25.7,20.9c1-5.6,4.1-10.7,8.6-14.3
            c-2.6-0.4-2.1-4.4-0.7-6.6c4.4-7,10.6-12.8,18-16.6l-18.3,7.9c5.1-14.6,15.8-26.6,29.9-33.2l-13,0.4c2.3-3.8,5.1-7.3,8.4-10.3
            c-1.5,1.2-3.8-1.1-3.4-3c0.5-1.9,2.4-3.1,4.1-4s3.6-2,4.2-3.9l-29.7,20.3c-1.3-8.4,4-16.8,11-21.6s15.5-6.9,23.9-8.8
            c-2.6-0.6-0.5-4.7,2.1-5.5c-2.2-2.3-5.2-3.7-8.4-4c5.3-4.6,11.7-7.7,18.5-9.1c-8.9-0.1-17.8,1.1-26.4,3.5
            c8-6.5,17.4-10.9,27.5-12.9c-2.8-2.9-6.6-4.5-10.7-4.5c4.6-2.3,9.6-3.4,14.7-3.4c0.1-0.9,0.3-1.8,0.5-2.7c0.6-3.3-3.6-6.6-6.8-6
            v-0.8C370.5,125.5,376.5,121.4,379,117.7z"/>
         <path class="st3" d="M288.3,293.7l-1.1,0.1C287.3,294.5,288.9,294.3,288.3,293.7z"/>
         <path class="st3" d="M323,315.4c-2.1,0.7-4.1,1.6-6.1,2.7c-0.5,0.8-1.1,1.6-1.6,2.5C318.4,319.6,321.1,317.8,323,315.4z"/>
         <path class="st3" d="M651.5,244.1c-0.6-1.3-0.8-2.8-0.7-4.3c0-1.3,0-2.6,0-3.9c-0.4-1.2-0.7-2.4-1.1-3.5L637,230l4.4-1.2l-6.5-5.3
            c3-1.3,6.2-1.9,9.4-1.8c-4.9-2.4-10.3-3.8-15.8-4.1l5.1-2.1c-2.1-1.2-3.7-3.1-4.6-5.3l4.6-1c-6.4-5.1-11.9-11.3-16.2-18.2
            c4.5,3,9.6,4.9,14.9,5.6c-0.7-1.2-1.5-2.2-2.5-3.2c-4,0-7.8-1.9-10.3-5.1c3.9,0.5,7.5,2.3,10.3,5.1c1.1,0,2.2-0.1,3.3-0.5
            c3,4,7.1,7,11.7,8.7c-0.7-1.5-1.1-3.1-1.1-4.8c-1-1.4-2.1-2.7-3.1-4.1c-2-2.9-2.6-7.9,0.8-9.2c1-0.3,2.1-0.6,3.1-0.7l0.3-4
            c-2.2-2-3-5.1-2.1-8c-4.8,2.9-10.4,4-16,3.2c0.1-1.1,0.1-2.3,0.1-3.4h-4.4c1.5,0,2.9,0,4.4-0.2c0-2.1-0.2-4.3-0.5-6.4
            c-2.3-1.7-4.2-3.9-5.6-6.5l3.8-1.3c-1-3.2-2.4-6.3-4-9.3l-1-0.1l0.8-0.2c-0.8-1.3-1.6-2.6-2.5-3.9c-2.7-0.4-5.4-0.6-8.2-0.5
            c-16.9,2-34.1-0.5-49.7-7.2c15.3,2.2,30.9,1.3,45.9-2.5l-21,0.3c4-1.7,8.3-2.7,12.6-2.9c-15.7,0.3-31.4-0.1-47.1-1.2l31.6-3
            l-20.8-1l27.7-1.3c0.1-0.4,0.3-0.9,0.6-1.2c0.9,0.6,1.9,0.9,3,1l1.4-0.1c-0.5,0.1-0.9,0.1-1.4,0.1l-3.6,0.2
            c-0.6,2.5,2.7,5.8,5.2,4.1c2.5-1.6,0.8-5.8-1.7-6.3l-1.5,0.1c0.5-0.2,1-0.2,1.5-0.1l1.9-0.1c-2.7,0-5.4-0.2-8.1-0.7l7.2-1.4
            l-0.1-0.1c-19.1,0.3-38.1-0.4-57.1-1.9c-15.8-0.5-31.5-2.5-46.9-5.9c15.5,2.6,31.2,4.6,46.9,5.9c16.9,0.6,33.8-0.5,50.5-3.2
            c-2.1-0.9-4.4-1.5-6.6-2.3c-2-0.7-4-1.6-5.9-2.7c-1.4-0.1-2.8-0.1-4.1,0l-5.9,0.5c2-0.3,3.9-0.4,5.9-0.5l3.6-0.3l-0.8-0.5
            c-12.4-1.1-24.7-3.7-36.4-8c7.2-0.6,14.4-1.4,21.6-2.3c-1.2-0.8-2.4-1.6-3.6-2.4l-2.5-0.1c-10.7-1.8-21.2-4.7-31.3-8.7
            c3.8-0.4,7.6-1.1,11.3-1.9c-12.9-4.4-26.6-6.8-40.1-8.9c-1.5-0.2-3.2-0.6-4-1.8c-0.5-1-0.7-2.2-0.6-3.3l0.7-18.2
            c-4.2,7.9-14,10.8-23,12.2s-18.6,2.2-25.4,8.1c0.9-1.7,0.7-3.8-0.4-5.4c-6.3,12.2-17,21.6-30,26.2c0.4-3.7-1.6-7.2-4.9-8.8
            c3,9.5-6.5,18.1-15.2,23c-7.1,4.1-15.3,9.1-18.1,16.2c1.7,1.6,3.4,3.3,4.8,5.2c3.3-1.6,5.8-4.4,6.9-7.8c1.4,1.5,2.4,3.3,2.6,5.4
            c1-3.7,2.1-7.6,4.5-10.6c2.4-3.1,6.5-5.2,10.3-4.2c-0.8,6.1-1.6,12.2-2.3,18.3c3.2-3.3,5.9-7.2,7.7-11.4c0,5.1-0.9,11.1,0.9,16
            c2-0.3,3.5-0.5,3.8-0.6c6-1.2,11.8-3.1,17.4-5.6l-5.1,4.3c18.1,5.1,37.3,4.3,54.8-2.4l-16.3,7.6c17.7,2.1,35.7,0.9,52.9-3.5
            c-12.3,5.6-25.7,8.6-39.2,8.7c6.3,1.8,12.1,5,17.1,9.2l-12.9,1.1c1.8,4.2,5.3,6.9,9.5,8.6c1,0.3,1.9,0.6,2.9,1
            c5,1.3,10.2,1.9,15.3,1.9c7.1,0.2,14.1,0.1,21.2-0.5c6-1.9,12-3.3,18.2-4.3c-5.8,2.3-12,3.8-18.2,4.3c-3.9,1.2-7.7,2.7-11.5,4.3
            l28.7,2.9c1.8,0.1,3.6,0.5,5.2,1.2c3,1.5,4.6,4.6,7.1,6.7c3.3,2.7,7.8,3.4,11.8,1.9l-0.1,6.6l-7.1-2.1c4.1,3.7,6.4,9,6.3,14.5
            l10.9-14.8c-1.3-12.1-13.9-19.4-25-24.7c12.7-0.3,24.8,5.3,32.8,15.2l-0.4-18.2c2.9-1.6,6.7,0,8.5,2.8c1.8,2.7,2.2,6.1,2.5,9.4
            c0.3,3.5,0.6,7,1,10.5c-1.2,7-2.4,13.9-3.6,20.9c2.2-0.3,4.2-1.3,5.8-2.8c0.1-0.2,0.3-0.4,0.5-0.5l-0.5,0.5
            c-0.8,1.2,0.5,3.2,2.1,3.4c1.4-1.4,3.1-2.5,5-3c-1.3,1.5-2.8,3.1-4.8,3h-0.2c-1.3,1.3-2.3,2.9-2.8,4.7c4,1.7,8.3,2.8,12.7,3
            c1.5-0.2,3-0.3,4.6-0.1c-1.5,0.1-3,0.1-4.6,0.1c-2.4,0.4-4.7,1.2-6.8,2.5c5.5,1.3,10.5,4,14.7,7.9l-6.9,2.4
            c2.2,0.9,4.2,2.4,5.7,4.3l-8.3,3.2c5.9,8.3,14.6,14.3,24.4,16.8l-27.4,0.1c11.5,8.1,24.9,12.8,38.8,13.9c-8.6,3.2-18,3.2-26.6,0.1
            c1.7,4.4,5.5,7.7,10.2,8.7l-4.6,2.4c1.8-1.1,1.9,3,0,3.8c7,2,14.1,3.5,21.3,4.5c-0.2-0.7-0.4-1.3-0.7-1.9l3.8,1.1
            c0.1-2.7,0.1-5.4,0.2-8.1l6.4,2.1c-0.3-9.2-1.5-18.4-3.5-27.4C652.8,245.7,652,245,651.5,244.1z"/>
         <path class="st7" d="M483.7,86.4c-4,3.5-8.3,6.7-12.8,9.5c8.7,1.5,17.7,0.5,25.9-2.9c-4.1,1.7-7.9,4-11.3,6.7l8.8,1l-2.2,2.1
            c21.2,1.8,43.1,3.5,63.1-3.7l-41.3,14.5c4.4,0.4,8.9,0.2,13.2-0.4l-6.5,1.1c6.4,4.5,13.8,7.4,21.6,8.3c-3.2,0-6.3,0.6-9.3,1.6
            c15.9,3.7,32.5,4,48.5,1c-11.2,2.3-22.1,5.8-32.6,10.4l12.7,4.5l-4.7,1.7l19.8,5.1l-3,2.3l10.1,4.3c-16.4-4.7-33.4-7.7-50.4-8.7
            c3.7-1.5,7.7-2.4,11.7-2.5l-12.3-3.7l5.6-1.2l-31.4-1l23.2-7.4l-4.9-0.6c-4.2-1.6-8.6-2.2-13-1.7l4.2-3.8l-5.5-0.2
            c0.5,0.3,1-0.6,0.7-1.1c-0.4-0.5-1-0.7-1.6-0.7c-19.4-1.8-38.9,0.1-57.6,5.6c15.5-8.6,32-15.2,48.5-21.7l-25.7,3.8l2.7-2.9
            l-9.7-0.2c0.1-0.8,0.7-1.4,1.5-1.4l-5.5,1.5c0.8-1.5,1.2-3.2,1-4.9c-20.1,2.8-40.3,4.9-60.6,6.1c1.5-0.3,3.1-0.7,4.5-1.2l4.8-1.2
            c3.1-0.8,6.2-1.6,9.4-2.3c6.3-1.5,12.7-2.9,19-4.3c3.6-0.8,7.3-1.4,10.8-2.3c-2.2-0.1-0.4,3.3,0.9,3.7c1.7,0.6,3.8-0.4,5.4-1
            c5.4-2,10.6-4.4,15.7-7.1C478.1,89.7,480.9,88.1,483.7,86.4z"/>
      </g>
      <path class="st0" d="M558.9,626.4c0,0-11.7,37.3-72.1,51l70.2-6.8C557,670.6,568.7,628.5,558.9,626.4z"/>
      <path class="st6" d="M492.5,592.7c-5.5,1.6-10.7,5.1-13.3,10.1c-2.8,5.2-2.7,11.5-2.5,17.4l0.4,16.5c0,1.3,0.2,2.6,0.6,3.8
         c0.5,1,1.1,1.9,1.9,2.6c8.1,7.8,20,10.1,30.4,6c3.8-1.7,7.3-3.8,10.5-6.4c2-1.3,3.7-2.9,4.9-4.9c0.9-1.9,1.5-4.1,1.6-6.2
         c0.8-6.1,1.6-12.1,1.9-18.2c0.2-3.8,0.5-8.2-1.1-11.8c-2.7-6.3-10.7-7.9-16.7-9.1C505.3,591.3,498.5,590.9,492.5,592.7z"/>
      <path class="st0" d="M604.9,558.5c4.3,8.8,6.3,19.2,3.2,28.5c-1.6,4.2-3.7,8.2-6.3,11.9c-6.6,10-13.9,19.7-21.8,28.8
         c-4.8,5.6-10.8,11.4-18.2,11.2c-0.7-8.4-1.4-17.2,1.5-25.1c1.3-3.6,3.4-6.9,4.8-10.4c4.7-11.4,3.3-24.4,6.3-36.3
         c2.3-9.1,7.3-17.4,8.3-26.9c0.1-1.4-0.4-4,1-4.9c1.1-0.7,2.7-0.1,3.7,0.4c3.6,1.8,6.3,6.1,8.7,9.2
         C599.4,549.1,602.4,553.6,604.9,558.5z"/>
      <g id="SHIRT">
         <path class="st8" d="M496.2,958.8c0,0-0.5-174-43.5-246c0,0-6.3-14.4-30.5-19.4c0,0-70.2-94.4-73.9-115.7c0,0-16.8-11.6-84.6,38.7
            c0,0-59.5,15.2-93.3,47.4c0,0-81.4,25.5-76.9,51c0,0-38.1,19.2-74,244.1h476.7V958.8z"/>
         <path class="st9" d="M415.1,701.7l-16.3-6.4l19.5,67.1c-16.1-16.5-20-41-23.1-63.8c-4.8,10.9-12.9,19.9-23.2,25.8l0.2-15.6
            c-21.8,8.2-45.1,12-68.4,11.1c17.8-1.5,30.8-16.9,41.7-31.1c1.2-1.4,2.2-3,2.8-4.8c1.2-4.3-1.6-8.6-3.8-12.5
            c-7-12-9.5-26.1-11.8-39.8c-2.2-13.1-4.4-26.9,0-39.3c3.4-9.6,13-18.4,22.7-15.5c6.8,2.1,10.7,9,13.9,15.3
            c8.1,15.7,16.5,31.8,20.1,49.3c1,4.8,1.2,9.8,3.2,14.4c3.2,7.3,8.7,13.7,13.2,20.2c2.8,3.8,5.3,8,7.3,12.3
            C413.6,689.4,416.1,702,415.1,701.7z"/>
         <path class="st9" d="M383.9,883.9c11,0.7,21.7,4.1,31.1,10.1l0.5-13.6c3.9-0.4,7.9-0.2,11.8,0.6c-3.8-24-8.4-47.8-13.8-71.5
            c12.9,4.5,22.1,17.8,22,31.4c-0.7-4.3,5.1-6,8.6-8.7c5.7-4.4,5-13.2,2.9-20.1c-2.1-6.9-5-14.4-2.1-21c-0.7,5.8,0.8,11.6,4.1,16.3
            c7.5-8.6,10.6-20.2,8.4-31.4c5.1,2.9,9.6,6.8,15.2,8.2s13-1.6,13.4-7.4l-11.2,86.3c-4,31.2-8.1,62.5-13,93.6
            c-0.1,1.4-0.6,2.8-1.4,4c-1.3,1.6-3.6,1.9-5.8,2.1c-8.3,0.7-17.2,1-24.6-3.4c-6.4-3.8-4.1-10.8-4.9-17.3
            c-1.8-15.7-9.4-30.3-20.1-41.7C399.1,893.7,391.9,888.2,383.9,883.9z"/>
         <path class="st8" d="M445.5,956.5c0,0,24.2-193.8,56-216.4c0,0,44.3-47.6,25.9-62.7c0,0-10.9-5,14.2-18.4s77.6-89.4,45.1-104
            l33.7,26.6c0,0,108.4,48.2,130.5,66.2c0,0,166.7,15,203,311L445.5,956.5z"/>
         <path class="st9" d="M533.3,665c-2.9,2.3-5.8,4.7-7.4,8c-2.4,4.8-1.7,10.5-1.1,15.9c2.6,24.6-0.6,49.4-9.1,72.5
            c10.3-7.6,19.4-16.7,27-27.1c-11.2,18.3-17,39.3-16.8,60.7l18.5-48.4c3.4-8.9,7-18.1,13.7-24.8s17.6-10.4,26.1-6l-11-14.9
            c12.9,2.3,26.8-5.4,31.6-17.6l-13.6-0.5c-0.7-14.7,2.5-32.2,15.9-38.5c11-5.2,26.1-1.1,34.8-9.6c-5.7,0.9-10.1-6.3-8.4-11.8
            c1.7-5.5,7.2-9,12.7-10.8s11.4-2.4,16.6-4.7c-21.3-9.6-41.6-21.4-60.4-35.2c2.4,6.9-1.7,14.4-7,19.3c-5.4,4.9-12.1,8.2-17.3,13.3
            c-8.3,8.1-10.8,18.8-13.9,29.5c-2.3,8-6.9,11.6-13.2,16.6C544.9,655.5,539.1,660.3,533.3,665z"/>
         <circle class="st5" cx="439.8" cy="734.5" r="9.2"/>
         <circle class="st5" cx="473.3" cy="921.1" r="9.2"/>
         <path class="st9" d="M125.5,931.1l-30-1.7l25.5,13.2c-1.3-0.7-14.2,8.3-16.1,9.3c-2.8,1.5-5.9,3.1-5.9,5.9h102.4
            c9.1-1.1,18.1-2.3,27.1-3.5c0-4.9-3.6-8.9-8.4-9.6c2.2-4.7,5.5-8.8,9.7-11.9l-15.5-0.5c-0.4-11.6,2.4-23,8.1-33.1
            c-12.9,11-28.5,18.3-45.2,21.3c-0.6-10.1,2.8-20,9.4-27.7c-4.2-1.1-8.6,1.4-9.8,5.6c4-45.4,17.1-91.3,46.3-126.2l-73.7,81.7
            c-5.7,6.3-11.7,13.7-10.9,22.1c-2.3-14.7-3.2-29.5-2.9-44.4c-5,10.4-7.9,21.6-8.5,33.1c-9-8.7-16.9-18.5-23.2-29.3
            c1.8,19.4,3.6,38.8,5.3,58.2c-4.3-2.4-9-3.9-13.8-4.4C109.3,900,119.7,914.5,125.5,931.1z"/>
         <path class="st9" d="M905.6,782.4c3.1,15.6,0.5,31.8-7.3,45.6c13.9,17.9,16.4,43.9,6.2,64.2c-1.4,2.7-3,5.4-5.4,7.2
            c-7,5-16.6,0.7-24-3.6l-14,20.4c-10.4-4.6-17.7-14-19.6-25.2c-6.7,5.7-15.9,7.3-24.1,4.3c0.4-19.3,0.8-38.5,1.2-57.7
            c-8,13.2-14.9,27-20.8,41.3c-0.5-10-0.9-20-1.4-30L786.1,860c-10-16.1-15-34.8-14.2-53.7c-0.3,3.4-0.6,6.9-0.7,10.3
            c-0.7,13.8-0.5,27.7,0.7,41.5s5,27.2,6.4,40.9c-5.8-2.4-10.9-6.3-16.7-8.5c-1.9,12.3,0.4,25.7,1.1,38.1c0.5,8.3,0.5,16.6,1.1,24.9
            c0.1,1.5,0.2,2.9,0.4,4.4l189.9,0.9C944.7,883.1,926.9,825.8,905.6,782.4z"/>
         <path class="st9" d="M771.8,806.3c0.4-4.5,0.9-9,1.5-13.4C772.5,797.3,772,801.8,771.8,806.3z"/>
         <path id="RIGHT_COLLAR" class="st8" d="M356.1,524.6c0,0-29.3,60.2-16.7,85.2c0,0,2.5,28.4,20.9,65.2c0,0,2.5,17.5,5,19.2
            c0,0-3.7,20.4,9,10.2s16.1-14.4-2.3-43.6c0,0-8.5-13.3,17-17.9c0,0,3.8-2.1-12.9-24.7c0,0-6.6-45.1-13.3-51.3L356.1,524.6z"/>
         <path id="LEFT_COLLAR" class="st8" d="M588.4,532.1c0,0,45.6,26.3,28.2,76.2c0,0-57,83.5-57.2,98.9c-6.4-7.2-9.6-16.6-8.9-26.2
            c1.2-16.1,7.8-22,7.8-22s7.5-20.1,5-20.9c0,0,17.5-45.1,29.2-60.1C592.5,578.1,584.2,565.5,588.4,532.1z"/>
      </g>
      <g id="THOUGHTS">
         <g>
            <ellipse style="animation-delay: 0.1s;" class="st5" cx="179.9" cy="82.4" rx="172.7" ry="58.8"/>
            <g style="animation-delay: 0.1s;">
               <path class="st10" d="M72.9,60.4c0.3,1.7-0.9,4.1-4,6.7C61.2,73.6,56.2,84.6,57.3,98c0.1,0.5-0.3,0.5-0.6,0.3
                  c-0.6-0.3-1.3-1.4-1.6-2.9C52.9,83.1,58.4,68,71.8,60C72.4,59.7,72.8,59.7,72.9,60.4z"/>
               <path class="st10" d="M81.9,74c0.5-0.7,1-0.8,1.4-0.3c0.9,1,1.3,3.3-0.1,6c-1.3,2.4-4.1,6.5-5.5,9.4c-1.4,3-1.5,5.4,0.5,5.3
                  c2.4-0.1,5.5-3.8,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.2,1.8-0.5,3.4c-1.5,3-5.1,6-8.3,5.8c-3.1-0.2-4.7-1.9-4.7-4.4
                  c-1.8,2.2-4.4,4.2-7.2,4.2c-5.3,0-6.4-6.9-1.7-13.5c4.6-6.4,9.2-8.8,12-8.8c2.5,0,3.6,1.8,3.5,3.9C80.6,76,81.2,74.8,81.9,74z
                  M77.3,76.2c-1.8-0.2-6,3.4-9.2,8.2c-3,4.7-3.8,9.4-1.5,9.5c2.4,0.1,5.3-3.4,8.3-8.2C78.4,79.7,78.9,76.3,77.3,76.2z"/>
               <path class="st10" d="M88.6,93.2c0.4-1.6,1.8-2.6,3-2.5c1.4,0.1,2.2,1.7,1.5,3.7c-0.7,2.1-3.2,3.7-5.2,2.9
                  c-0.4-0.2-0.3-0.5,0-0.7c0.5-0.2,1-0.4,1.5-0.9C88.7,95.4,88.3,94.4,88.6,93.2z"/>
               <path class="st10" d="M119.2,95.5c-3,0-5-1.5-6-3.9c-0.5,1.1-1.1,2.4-1.7,3.6c-0.2,0.5-0.6,0.6-0.9,0.2c-0.9-1.1-1.4-2.7-0.3-6
                  c1.5-4.5,12.8-24.6,16.7-29.8c0.5-0.7,1-0.7,1.4,0.1c0.9,1.5,0.9,4-1.5,8c-0.9,1.5-3.3,5.3-5.1,8.2c1.7-1.4,3.4-2.3,4.9-2.3
                  c4.9,0,5.1,6.4,1.9,13C126.1,92.4,122.5,95.5,119.2,95.5z M121.8,78.1c-2.6,2.5-5.3,7.1-7.5,11.2c0.1,1.6,0.9,3.3,2.4,3.6
                  c2,0.4,4.6-1.2,8-7.5c3.3-6.1,2.6-9.4,1.3-9.5C124.7,75.8,123.5,76.4,121.8,78.1z"/>
               <path class="st10" d="M129.7,96.4c-1.7,1.2-3.4,1.7-4.5,1.7c-0.5,0-0.5-0.3-0.2-0.6c10.8-8.7,15.7-20.9,15.2-30.2
                  c-0.2-3.9,0.9-6.2,2.3-7.3c0.5-0.4,0.9-0.2,1,0.4C147,75.3,138.4,90.3,129.7,96.4z"/>
               <path class="st10" d="M157.2,82.1c-0.1-0.5-0.1-0.8,0.6-0.8c3.1,0.2,7.3-0.6,9.9-0.5c2.9,0.1,4,1.3,4.3,2.9
                  c0.1,0.5,0,0.8-0.6,0.8c-4.2-0.3-7.4,0.6-10,0.5C158.7,84.9,157.6,83.7,157.2,82.1z M160.1,76.2c-0.1-0.5,0-0.8,0.6-0.7
                  c3.1,0.2,7.3-0.5,10-0.4c2.8,0.2,4,1.4,4.2,3c0.1,0.5,0,0.8-0.7,0.8c-4.1-0.4-7.4,0.3-10,0.3C161.5,79.1,160.4,77.9,160.1,76.2z"
                  />
               <path class="st10" d="M182.5,77.7c-1.3-1.1-1.6-2.6-1.5-3.5c0.1-0.7,0.5-0.8,1-0.4c2.4,1.6,5.7,3.4,8.4,4.4
                  c0.9,0.3,1,0.8,0.5,1.6c-0.8,1.6-1.4,2.3-2.5,2.8c-2.8,1-6.4,2.3-9.1,3.6c-0.6,0.3-0.8,0.2-0.9-0.5c-0.1-0.9,0.9-2.4,2.2-3.4
                  c1.4-1,3.3-1.7,5.3-2.4C184.5,79.2,183.2,78.4,182.5,77.7z"/>
               <path class="st10" d="M224.8,74c0.5-0.7,1-0.8,1.4-0.3c0.9,1,1.3,3.3-0.1,6c-1.3,2.4-4.1,6.5-5.5,9.4c-1.4,3-1.5,5.4,0.5,5.3
                  c2.4-0.1,5.5-3.8,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.2,1.8-0.5,3.4c-1.5,3-5.1,6-8.3,5.8c-3.1-0.2-4.7-1.9-4.7-4.4
                  c-1.8,2.2-4.4,4.2-7.2,4.2c-5.3,0-6.4-6.9-1.7-13.5c4.6-6.4,9.2-8.8,12-8.8c2.5,0,3.6,1.8,3.5,3.9C223.5,76,224.2,74.8,224.8,74z
                  M220.2,76.2c-1.8-0.2-6,3.4-9.2,8.2c-3,4.7-3.8,9.4-1.5,9.5c2.4,0.1,5.3-3.4,8.3-8.2C221.4,79.7,221.9,76.3,220.2,76.2z"/>
               <path class="st10" d="M258.5,80.8c0.1,0.5,0.1,0.8-0.6,0.7c-2.1-0.2-3.9-0.1-5.6,0.1c-1.1,1.8-2.3,3.7-3,5.4
                  c-0.2,0.7-0.5,0.8-1,0.4c-0.8-0.6-1.8-2-0.7-4.5c0.1-0.3,0.2-0.6,0.4-0.9c-0.1,0-0.1,0-0.1,0c-2.6-0.1-3.8-1.2-4.2-2.9
                  c-0.1-0.5,0-0.8,0.7-0.8c1.7,0.1,3.7,0,5.6-0.2c1.1-1.8,2.2-3.7,3.1-5.3c0.4-0.7,0.9-0.5,1.3-0.1c0.9,1,1.3,2.7,0.1,5
                  C257.1,77.9,258.2,79.2,258.5,80.8z"/>
               <path class="st10" d="M279,95.5c-3,0-5-1.5-6-3.9c-0.5,1.1-1.1,2.4-1.7,3.6c-0.2,0.5-0.6,0.6-0.9,0.2c-0.9-1.1-1.4-2.7-0.3-6
                  c1.5-4.5,12.8-24.6,16.7-29.8c0.5-0.7,1-0.7,1.4,0.1c0.9,1.5,0.9,4-1.5,8c-0.9,1.5-3.3,5.3-5.1,8.2c1.7-1.4,3.4-2.3,4.9-2.3
                  c4.9,0,5.1,6.4,1.9,13C285.9,92.4,282.4,95.5,279,95.5z M281.6,78.1c-2.6,2.5-5.3,7.1-7.5,11.2c0.1,1.6,0.9,3.3,2.4,3.6
                  c2,0.4,4.6-1.2,8-7.5c3.3-6.1,2.6-9.4,1.3-9.5C284.6,75.8,283.3,76.4,281.6,78.1z"/>
               <path class="st10" d="M289.1,93.2c0.5-1.6,1.8-2.6,3-2.5c1.4,0.1,2.1,1.7,1.4,3.7c-0.7,2.1-3.1,3.7-5.1,2.9
                  c-0.3-0.2-0.4-0.5,0-0.7c0.5-0.2,1-0.4,1.5-0.9C289.2,95.4,288.7,94.4,289.1,93.2z M301.6,74.4c1,0.4,1.5,1.7,0.9,3.2
                  c-0.6,1.4-2.1,2.3-3.2,1.9s-1.5-1.8-0.9-3.3C299,75,300.4,74,301.6,74.4z"/>
            </g>
         </g>
         <g>
            <ellipse style="animation-delay: 0.2s;" class="st5" cx="736.9" cy="70.1" rx="222.1" ry="58.8"/>
            <g style="animation-delay: 0.2s;">
               <path class="st10" d="M566.1,77.4c-4.2,0-5.2-5.5-1.4-10.8c3.7-5,7.3-7,9.6-7c1.7,0,2.8,1.2,2.9,2.7c0.5-0.8,1-1.5,1.5-2.1
                  c0.5-0.5,0.6-0.7,1-0.3c0.7,0.7,1.1,2.5,0,4.8c-0.9,1.9-3.3,5.2-4.4,7.4c-1,2.1-1.1,3.9-0.3,4.1c1.2,0.3,3.6-1.6,5.9-6.6
                  c2.8-6,1.9-12.2-1.8-13.7c-3.9-1.6-10.8,0.9-15.9,7.7c-5.2,6.9-4.9,15.1-0.3,17.1c3.2,1.4,7.7,0,10-2.1c0.4-0.4,0.9-0.4,0.9,0.2
                  c0,0.8-0.3,2.3-2.2,3.4c-3.1,1.7-7.1,1-8.8,0.3c-4.7-1.7-6.4-5.5-6.5-9.5c-0.2-10.2,10.1-18.6,16.4-19.9c3.7-0.8,6.2,0.1,7.9,1.7
                  c6.3,2.8,5.9,10.3,3.8,15.1c-2.2,5.2-5.8,8.1-9.2,7.9c-2.2-0.2-3.6-1.6-3.7-3.4C570.2,76,568.2,77.4,566.1,77.4z M567.8,68.4
                  c-2.4,3.7-3,7.4-1.1,7.4c1.8,0.1,4.1-2.5,6.5-6.3c2.9-4.8,3.2-7.5,1.9-7.5C573.6,61.8,570.3,64.5,567.8,68.4z"/>
               <path class="st10" d="M602.1,61.5c2.2-0.7,3.6,0.1,4.7,2.4c1.1,2.1,0.9,4.4-1.2,6.3c-1.9,1.8-4.4,3.4-8,4.4
                  c0.2,4.4,1.8,7.5,4,7.8c2.4,0.2,5.5-4,7.2-7.3c0.3-0.7,0.9-0.7,1.1-0.1c0.3,0.7,0.2,2-0.7,3.3c-1.4,2.3-4,5.8-8.5,5.7
                  c-4-0.1-7.4-3.1-7.3-10.5c0-1,0.1-1.1,1.2-1.5c7.1-2.2,10.9-6.9,10-8.4c-0.6-1-3.2-0.4-6.2,1.8c-4.3,3.1-6.9,7.9-11.3,17.8
                  c-0.2,0.4-0.6,0.5-1,0.1c-0.9-0.9-1.1-2.7-0.1-6c1.6-4.9,11.1-22.8,16.2-29.3c0.5-0.7,1.1-0.7,1.4-0.1c0.9,1.6,0.7,3.8-1.7,7.8
                  c-1.4,2.2-3.6,6-5.2,8.7C598.8,62.9,600.6,62,602.1,61.5z"/>
               <path class="st10" d="M614.9,82.7c4.8,0.2,8.9-4.1,11.4-7.7c0.3-0.4,0.7-0.4,1,0.1c0.2,0.4,0.1,2-1,3.4
                  c-2.4,3.3-6.8,5.8-11.2,5.9c-7.3,0.1-9.8-6.7-6.1-13.7s9.6-9.8,12.4-8.2c1.4,0.8,2.5,1.6,3.4,2.8c1,1.3,0.6,4.6-1.8,7.4
                  c-2.8,3.3-6.6,4.7-10.7,1.6C610.5,79.1,611.9,82.5,614.9,82.7z M619.7,70.2c1.8-2.3,3.1-5.1,2.3-5.7c-1.2-0.9-6,2.3-8.7,7.5
                  c-0.2,0.3-0.3,0.7-0.5,0.9C615.7,74.6,618,72.3,619.7,70.2z"/>
               <path class="st10" d="M636.5,85.4c-3.2,7.3-7.3,16-12,17.7c-1.1,0.4-2.2,0.4-3.3-1.1c-1.1-1.6-2.3-3.5-1.1-6.7
                  c1.7-4.9,6.6-8.1,12.3-10.3c0.6-1.2,1.1-2.6,1.8-3.9c-1.8,1.7-4,3.1-6.2,3c-4.1-0.3-4.2-4.3-2.9-7.9c1.3-3.5,4.1-8.8,7.8-13.7
                  c0.5-0.6,1-0.5,1.4,0c1.4,1.7,1,4-1.1,7.2c-2.1,3.1-3.3,5.4-4.4,7.9c-1.1,2.7-1,4.6,0.3,4.7c2.2,0.2,5.1-3.3,8-8
                  c2.5-4,4.9-8.9,6.9-11.7c0.5-0.7,0.9-0.8,1.4-0.4c0.8,1,1.3,3.3-0.2,6.1c-1.7,3.1-5.2,9.7-7.6,14.9c4.5-2.3,7.9-5.4,10.1-8.4
                  c0.3-0.4,0.8-0.4,0.9,0.1c0.1,0.5-0.1,1.7-1,3.1C645.3,81.5,641.3,83.5,636.5,85.4z M622.4,100.1c0.7,1,5.4-4.9,9-12.9
                  C623.9,91.4,621.3,98.8,622.4,100.1z"/>
               <path class="st10" d="M656.6,80.1c-0.1,0.5-0.1,1-0.1,1.5c3.6-2.1,7.1-7.8,9.5-14.2c-4.5-1.6,0.7-8.9,3.2-9.1
                  c1.8-0.1,1.3,3.3,0.1,6.8c2-0.2,4.1-1.1,6.3-2.5c0.7-0.5,1.2-0.7,1.7-0.2c1.1,1.2,2.5,3.7,0.8,5.5c-2.8,2.9-5.5,6.6-6.5,9.4
                  c-1.1,3-0.3,5.2,1.4,5.3c2.3,0.1,5.2-3.4,7.1-7.5c0.2-0.5,0.6-0.5,0.9,0c0.3,0.6,0.4,2-0.3,3.5c-1.4,3.3-4.9,5.5-8,5.5
                  c-4.4-0.1-6.1-3.9-4.6-8.2c1.2-3.6,4.6-7.6,7.1-10.3c-2.5,1.5-5,2-6.8,2c-2.5,6.7-6.8,13.5-12,15.5c-1,5.6-3.8,11.9-6.6,15.2
                  c-2.2,2.5-4,2.1-5.6,0.7c-1.5-1.2-3-3.1-2.4-7.8c1.6-12,14.7-32.8,21.2-41.8c0.5-0.7,1.1-0.6,1.4,0.1c0.8,1.8,0.5,4.1-1.9,8.1
                  c-2.3,3.7-6.4,9.8-9.4,15.5C655.7,74.1,656.9,76.2,656.6,80.1z M648.8,81.8c-3.2,7.2-5.3,13.9-4,15.5c1.3,1.5,5.6-6,7.5-13.4
                  C650.7,83.8,649.5,83.1,648.8,81.8z M653.2,78.5c0.1-2-0.5-3.7-0.9-3.5c-0.6,0.2-1.2,3-1.2,4.3c0,1.7,0.5,3,1.6,3.1
                  C653,81.1,653.1,79.7,653.2,78.5z"/>
               <path class="st10" d="M700,62.4c0.5-0.7,1-0.8,1.4-0.3c0.9,1,1.3,3.3-0.1,6c-1.3,2.4-4.1,6.5-5.5,9.4c-1.4,3-1.5,5.4,0.5,5.3
                  c2.4-0.1,5.5-3.8,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.2,1.8-0.5,3.4c-1.5,3-5.1,6-8.3,5.8c-3.1-0.2-4.7-1.9-4.7-4.4
                  c-1.8,2.2-4.4,4.2-7.2,4.2c-5.3,0-6.4-6.9-1.7-13.5c4.6-6.4,9.2-8.8,12-8.8c2.5,0,3.6,1.8,3.5,3.9
                  C698.7,64.4,699.3,63.3,700,62.4z M695.4,64.7c-1.8-0.2-6,3.4-9.2,8.2c-3,4.7-3.8,9.4-1.5,9.5c2.4,0.1,5.3-3.4,8.3-8.2
                  C696.5,68.2,697,64.8,695.4,64.7z"/>
               <path class="st10" d="M720.6,62.1c1.9,0.1,3,2.9,2.2,5.3c2.8-2.5,6.6-5.1,8.5-5c2.1,0.1,2.5,3.3,1.4,6c-1,2.4-3.8,6-5.3,8.7
                  c-1.6,3.1-0.8,5.6,1.3,5.5c2.4-0.1,4.9-3.6,7.2-7.4c0.3-0.5,0.7-0.5,1-0.1s0.1,2.2-0.5,3.4c-1.6,3.1-5.3,5.8-8.6,5.8
                  c-5,0-6.8-3.7-4.7-8.2s7.2-10.6,6.4-10.8c-0.5-0.2-2.4,0.4-6.1,4c-4.8,4.5-7.6,9.7-10,14.8c-0.3,0.7-0.8,0.8-1.3,0.3
                  c-1-0.8-1.8-2.9-0.1-6.8c1.9-4.5,8.1-12,6.9-12.4c-0.7-0.3-3.5,1.2-7.2,5c-3.4,3.5-6.1,8.5-8.4,13.6c-0.2,0.5-0.6,0.7-1.1,0.3
                  c-1.3-1-1.5-2.8-0.6-5.6c1.2-3.4,5.4-11.1,9-16.2c0.4-0.6,0.9-0.5,1.2,0c1,1.5,0.8,3.6-0.1,5.8C714.2,65.2,718.5,62,720.6,62.1z"
                  />
               <path class="st10" d="M742.9,82.7c4.8,0.2,8.9-4.1,11.4-7.7c0.3-0.4,0.7-0.4,1,0.1c0.2,0.4,0.1,2-1,3.4
                  c-2.4,3.3-6.8,5.8-11.2,5.9c-7.3,0.1-9.8-6.7-6.1-13.7s9.6-9.8,12.4-8.2c1.4,0.8,2.5,1.6,3.4,2.8c1,1.3,0.6,4.6-1.8,7.4
                  c-2.8,3.3-6.7,4.7-10.7,1.6C738.5,79.1,739.9,82.5,742.9,82.7z M747.7,70.2c1.8-2.3,3.1-5.1,2.3-5.7c-1.2-0.9-6,2.3-8.7,7.5
                  c-0.2,0.3-0.3,0.7-0.5,0.9C743.6,74.6,746,72.3,747.7,70.2z"/>
               <path class="st10" d="M761.9,65.2c0.3-2.1,0.9-3.1,2.1-4.2c1.3-1.1,2.6-1.3,3.1-0.8c0.6,0.5,0.3,1.1-0.5,2.4
                  c-0.2,0.3-0.4,0.7-0.6,1c-0.4,5,0.7,9.2,0.5,13c-0.1,2.1-0.5,4.4-2.6,5.7c3.8-1.4,6.2-4.6,7.7-7.5c0.3-0.6,0.9-0.4,1.1,0
                  c0.2,0.5,0.2,1.9-0.8,3.6c-2.2,3.7-6.1,5.8-10.1,6c-3.3,0.1-6.5-0.7-8.5-4.1c-1.4-2.4-1.2-6,0.7-7.5c0.7-0.5,1.1-0.3,1.2,0.5
                  c0.1,0.4,0.1,0.9,0.1,1.3C757.7,71.4,760.3,67.9,761.9,65.2z M755.9,77.4c0.9,3.1,2.5,5.4,4.4,4.8c2.6-0.8,1.8-9.3,1.5-13.6
                  C760.2,71.5,758,74.5,755.9,77.4z"/>
               <path class="st10" d="M792.1,79.7c0,0.6-0.1,1.2-0.1,1.8c2.2-1.4,4.5-4.1,6-6.9c0.3-0.7,0.9-0.7,1.2,0.1c0.3,0.8-0.1,2-0.6,3.3
                  c-0.6,1.3-3.4,4.5-6.9,5.6c-0.9,5.5-3.4,11.5-6.2,14.8c-1.9,2.3-3.4,2.5-5.1,1.1c-1.4-1.1-3.2-3.3-2.5-7.9
                  c1.6-12.1,14.8-32.9,21.2-41.9c0.5-0.7,1.2-0.6,1.4,0.1c0.7,1.8,0.5,4.2-1.9,8.1c-2.3,3.7-6.3,9.8-9.4,15.5
                  C791.3,74,792.1,76.1,792.1,79.7z M781,97.3c1.3,1.6,5.6-5.9,7.5-13.3c-1.7-0.2-2.8-0.9-3.5-2.2C781.8,89,779.7,95.7,781,97.3z
                  M789.3,78.5c0.1-2-0.5-3.7-0.9-3.5c-0.6,0.2-1.1,3.1-1.1,4.3c-0.1,1.7,0.5,2.9,1.5,3.1C789.1,81.1,789.3,79.7,789.3,78.5z"/>
               <path class="st10" d="M817.7,62.4c0.5-0.7,1-0.8,1.4-0.3c0.9,1,1.3,3.3-0.1,6c-1.3,2.4-4.1,6.5-5.5,9.4c-1.4,3-1.5,5.4,0.5,5.3
                  c2.3-0.1,5.5-3.8,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.2,1.8-0.5,3.4c-1.5,3-5.1,6-8.3,5.8c-3.1-0.2-4.7-1.9-4.7-4.4
                  c-1.8,2.2-4.4,4.2-7.2,4.2c-5.3,0-6.4-6.9-1.7-13.5c4.6-6.4,9.2-8.8,12-8.8c2.5,0,3.6,1.8,3.5,3.9
                  C816.4,64.4,817.1,63.3,817.7,62.4z M813.1,64.7c-1.8-0.2-6,3.4-9.2,8.2c-3,4.7-3.8,9.4-1.5,9.5s5.3-3.4,8.3-8.2
                  C814.3,68.2,814.8,64.8,813.1,64.7z"/>
               <path class="st10" d="M836.6,82.8c2.4-0.1,5.4-3.7,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.3,1.9-0.5,3.4
                  c-1.5,3-5.1,5.9-8.3,5.7c-3.1-0.2-4.6-1.8-4.6-4.3c-1.8,2.2-4.4,4.2-7.1,4.2c-5.3,0-6.4-6.9-1.7-13.4c4.5-6.3,9.1-8.7,12-8.7
                  c2.4,0,3.5,1.7,3.4,3.7c3.1-5.6,7.5-13,9.5-15.7c0.6-0.8,1.1-0.7,1.4,0c0.8,1.8,0.6,4.1-1.8,8.2c-2.8,4.7-8.3,13.6-11.1,19.4
                  C834.7,80.7,834.7,82.9,836.6,82.8z M826.6,72.9c-3.1,4.7-3.7,9.4-1.5,9.4c2.3,0.1,5.4-3.1,8.1-8.1c3.3-6,4.2-9.4,2.5-9.5
                  C834,64.7,829.8,68.1,826.6,72.9z"/>
               <path class="st10" d="M850.7,82.7c4.8,0.2,8.9-4.1,11.4-7.7c0.3-0.4,0.7-0.4,1,0.1c0.2,0.4,0.1,2-1,3.4
                  c-2.4,3.3-6.8,5.8-11.2,5.9c-7.3,0.1-9.8-6.7-6.1-13.7s9.6-9.8,12.4-8.2c1.4,0.8,2.5,1.6,3.4,2.8c1,1.3,0.6,4.6-1.8,7.4
                  c-2.8,3.3-6.6,4.7-10.7,1.6C846.2,79.1,847.6,82.5,850.7,82.7z M855.4,70.2c1.8-2.3,3.1-5.1,2.3-5.7c-1.2-0.9-6,2.3-8.7,7.5
                  c-0.2,0.3-0.3,0.7-0.5,0.9C851.4,74.6,853.8,72.3,855.4,70.2z"/>
               <path class="st10" d="M881.7,72.6c-1.5,2.8-5.6,7.9-5,10.5c0.2,0.8-0.2,1-0.9,0.7c-1.7-1-2.7-3.2-1.5-5.9c1.4-3.4,4.1-6.2,5-8.4
                  c0.6-1.4,0.5-2.2-0.2-2.8c-0.4-0.5-0.4-1.2,0.4-1.3c1-0.1,2.1-0.5,3-1.7c1.6-2.1,4-8.4,6.1-11.3c1.7-2.4,4.4-2.7,6.2-2.4
                  c0.7,0.1,0.7,0.6,0.1,1c-2.9,1.9-6,9-7.6,11.7c-1.5,2.5-3.1,3.8-5.4,3.9C883.3,68.1,883.2,70,881.7,72.6z"/>
               <path class="st10" d="M910.3,61.6c1.5-2.8,6-8.5,5.3-11c-0.2-0.7,0.1-0.9,0.9-0.7c2,0.8,2.9,2.6,2.1,4.9
                  c-1.4,3.9-4.8,7.6-5.7,9.7c-0.6,1.4-0.3,2.3,0.3,2.9c0.5,0.5,0.1,1-0.8,1.2c-1,0.2-1.8,0.8-2.6,1.8c-1.7,2-3.8,7.9-5.8,10.8
                  c-1.7,2.4-4.1,2.8-6.2,2.4c-0.7-0.1-0.6-0.6,0-1c2.6-1.5,5.8-8.7,7.4-11.5c1.4-2.4,3.1-3.7,5.3-4C908.9,66.1,908.9,64,910.3,61.6
                  z"/>
            </g>
         </g>
         <g>
            <ellipse style="animation-delay: 0.3s;" class="st5" cx="185.3" cy="282" rx="172.7" ry="58.8"/>
            <g style="animation-delay: 0.3s;">
               <path class="st10" d="M72.8,293.9c2.4-0.1,5.4-3.7,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.3,1.9-0.5,3.4
                  c-1.5,3-5.1,5.9-8.3,5.7c-3.1-0.2-4.6-1.8-4.6-4.3c-1.8,2.2-4.4,4.2-7.1,4.2c-5.3,0-6.4-6.9-1.7-13.4c4.5-6.3,9.1-8.7,12-8.7
                  c2.4,0,3.5,1.7,3.4,3.7c3.1-5.6,7.5-13,9.5-15.7c0.6-0.8,1.1-0.7,1.4,0c0.8,1.8,0.6,4.1-1.8,8.2c-2.8,4.7-8.3,13.6-11.1,19.4
                  C70.9,291.8,70.8,294.1,72.8,293.9z M62.8,284.1c-3.1,4.7-3.7,9.4-1.5,9.4c2.3,0.1,5.4-3.1,8.1-8.1c3.3-6,4.2-9.4,2.5-9.5
                  C70.1,275.9,65.9,279.3,62.8,284.1z"/>
               <path class="st10" d="M79.6,287.4c1.4-4,5.2-10.4,7.3-13.2c0.5-0.7,0.9-0.8,1.4-0.3c0.9,0.9,1.3,3.2-0.1,5.9
                  c-1.2,2.4-3.6,6.1-4.8,8.8c-1.4,3.1-1.3,5.3,0.6,5.2c2.6-0.1,5.7-3.7,7.5-7.4c0.3-0.5,0.7-0.4,0.9,0c0.5,0.9,0.3,2-0.4,3.4
                  c-1.7,3.4-5.3,5.6-8.5,5.6C79.1,295.4,78,291.9,79.6,287.4z M92.4,273.3c-0.7,0.8-2.4,0.6-3.2-0.8c-0.9-1.5-0.6-3.1,0.3-4
                  s2.6-0.3,3.3,1.2C93.6,271.1,93.2,272.5,92.4,273.3z"/>
               <path class="st10" d="M99.4,276.3c0.3-2.1,0.9-3.1,2.1-4.2c1.3-1.1,2.6-1.3,3.1-0.8c0.6,0.5,0.3,1.1-0.5,2.4
                  c-0.2,0.3-0.4,0.7-0.6,1c-0.4,5,0.7,9.2,0.5,13c-0.1,2.1-0.5,4.4-2.6,5.7c3.8-1.4,6.2-4.6,7.7-7.5c0.3-0.6,0.9-0.4,1.1,0
                  c0.2,0.5,0.2,1.9-0.8,3.6c-2.2,3.7-6.1,5.8-10.1,6c-3.3,0.1-6.5-0.7-8.5-4.1c-1.4-2.4-1.2-6,0.7-7.5c0.7-0.5,1.1-0.3,1.2,0.5
                  c0.1,0.4,0.1,0.9,0.1,1.3C95.2,282.6,97.7,279.1,99.4,276.3z M93.3,288.6c0.9,3.1,2.5,5.4,4.4,4.8c2.6-0.8,1.8-9.3,1.5-13.6
                  C97.6,282.7,95.5,285.6,93.3,288.6z"/>
               <path class="st10" d="M131,286.4c0.3-0.8,1-0.5,1.1,0s0.2,1.9-0.7,3.6c-2.2,3.7-6.1,5.5-10.2,5.8c-3.7,0.2-6.1-0.3-8.1-2
                  c-1.2-1-1.8-2.3-1.9-3.6c-2.7,4.7-5.6,10.2-8.4,16.2c-0.2,0.5-0.6,0.6-1,0.1c-0.9-1.1-1.1-2.7-0.1-6c1.7-5.3,11.4-22.5,15-27
                  c0.6-0.7,1.1-0.6,1.4,0.1s0.4,1.5,0.3,2.4c2.5-2.1,5.9-3.6,8.4-1.1c2.8,2.8,1.5,7.6-0.6,12c-1.7,3.6-3.8,6.2-6,7.4
                  C125.7,294,129.2,290.1,131,286.4z M114.2,292.6c1.8,1.4,5.6-2.5,8.3-7.8c2.2-4.3,2.9-8.8,1.4-9.5c-1.4-0.7-4.9,2.1-7.6,6.2
                  c-0.6,1-1.3,2-1.9,3.1C112.9,288.1,112.6,291.4,114.2,292.6z"/>
               <path class="st10" d="M135.3,293.7c2.8,0.2,6.8-3.7,9-7.9c0.4-0.7,1-0.8,1.3,0c0.3,0.9,0.1,2.1-0.6,3.4c-0.8,1.6-5,6.1-9.6,6
                  c-4-0.1-6.1-2.7-4.8-8.6c2.1-9.4,8.7-19.3,14.5-25c0.9-0.9,1.7-1,2.5-0.3c1.7,1.4,2,3.4,1.3,6c-1.8,6.7-8.2,13.2-13.3,16.3
                  C133.4,289,132.8,293.5,135.3,293.7z M147,266.2c-0.7-0.4-5,5.6-8.9,12.5c-0.5,0.9-1,1.7-1.4,2.6
                  C144.6,275.3,147.8,266.7,147,266.2z"/>
               <path class="st10" d="M163.9,273.6c0.5-0.7,1-0.8,1.4-0.3c0.9,1,1.3,3.3-0.1,6c-1.3,2.4-4.1,6.5-5.5,9.4c-1.4,3-1.5,5.4,0.5,5.3
                  c2.4-0.1,5.5-3.8,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.2,1.8-0.5,3.4c-1.5,3-5.1,6-8.3,5.8c-3.1-0.2-4.7-1.9-4.7-4.4
                  c-1.8,2.2-4.4,4.2-7.2,4.2c-5.3,0-6.4-6.9-1.7-13.5c4.6-6.4,9.2-8.8,12-8.8c2.5,0,3.6,1.8,3.5,3.9
                  C162.6,275.6,163.3,274.5,163.9,273.6z M159.3,275.8c-1.8-0.2-6,3.4-9.2,8.2c-3,4.7-3.8,9.4-1.5,9.5c2.4,0.1,5.3-3.4,8.3-8.2
                  C160.5,279.4,161,276,159.3,275.8z"/>
               <path class="st10" d="M177.7,296.5c-3.2,7.3-7.3,16-12,17.7c-1.1,0.4-2.2,0.4-3.3-1.1c-1.1-1.6-2.3-3.5-1.1-6.7
                  c1.7-4.9,6.6-8.1,12.3-10.3c0.6-1.2,1.1-2.6,1.8-3.9c-1.8,1.7-4,3.1-6.2,3c-4.1-0.3-4.2-4.3-2.9-7.9c1.3-3.5,4.1-8.8,7.8-13.7
                  c0.5-0.6,1-0.5,1.4,0c1.4,1.7,1,4-1.1,7.2c-2.1,3.1-3.3,5.4-4.4,7.9c-1.1,2.7-1,4.6,0.3,4.7c2.2,0.2,5.1-3.3,8-8
                  c2.5-4,4.9-8.9,6.9-11.7c0.5-0.7,0.9-0.8,1.4-0.4c0.8,1,1.3,3.3-0.2,6.1c-1.7,3.1-5.2,9.7-7.6,14.9c4.5-2.3,7.9-5.4,10.1-8.4
                  c0.3-0.4,0.8-0.4,0.9,0.1c0.1,0.5-0.1,1.7-1,3.1C186.5,292.6,182.5,294.7,177.7,296.5z M163.5,311.3c0.7,1,5.4-4.9,9-12.9
                  C165.1,302.5,162.5,309.9,163.5,311.3z"/>
               <path class="st10" d="M193.8,290.3c1.1,0.4,1.5,1.8,0.9,3.3c-0.6,1.4-2.1,2.3-3.3,1.9c-1-0.3-1.5-1.9-0.9-3.3
                  C191.3,290.7,192.7,289.9,193.8,290.3z M202.8,274.1c1.1,0.4,1.5,1.7,0.9,3.2c-0.6,1.4-2.1,2.3-3.2,1.9c-1.1-0.4-1.5-1.8-0.9-3.3
                  C200.2,274.6,201.6,273.7,202.8,274.1z"/>
               <path class="st10" d="M224.4,290.9c0,0.6-0.1,1.2-0.1,1.8c2.2-1.4,4.5-4.1,6-6.9c0.3-0.7,0.9-0.7,1.2,0.1c0.3,0.8-0.1,2-0.6,3.3
                  c-0.6,1.3-3.4,4.5-6.9,5.6c-0.9,5.5-3.4,11.5-6.2,14.8c-1.9,2.3-3.4,2.5-5.1,1.1c-1.4-1.1-3.2-3.3-2.5-7.9
                  c1.6-12.1,14.8-32.9,21.2-41.9c0.5-0.7,1.2-0.6,1.4,0.1c0.7,1.8,0.5,4.2-1.9,8.1c-2.4,3.7-6.3,9.8-9.4,15.5
                  C223.6,285.2,224.4,287.3,224.4,290.9z M213.3,308.5c1.3,1.6,5.6-5.9,7.5-13.3c-1.7-0.2-2.8-0.9-3.5-2.2
                  C214,300.2,212,306.9,213.3,308.5z M221.6,289.7c0.1-2-0.5-3.7-0.9-3.5c-0.6,0.2-1.1,3.1-1.1,4.3c-0.1,1.7,0.5,2.9,1.5,3.1
                  C221.4,292.3,221.6,290.9,221.6,289.7z"/>
               <path class="st10" d="M234.6,293.7c2.8,0.2,6.8-3.7,9-7.9c0.4-0.7,1-0.8,1.3,0c0.3,0.9,0.1,2.1-0.6,3.4c-0.8,1.6-5,6.1-9.6,6
                  c-4-0.1-6.1-2.7-4.8-8.6c2.1-9.4,8.7-19.3,14.5-25c0.9-0.9,1.7-1,2.5-0.3c1.7,1.4,2,3.4,1.3,6c-1.8,6.7-8.2,13.2-13.3,16.3
                  C232.7,289,232,293.5,234.6,293.7z M246.3,266.2c-0.7-0.4-5,5.6-8.9,12.5c-0.5,0.9-1,1.7-1.4,2.6
                  C243.9,275.3,247.1,266.7,246.3,266.2z"/>
               <path class="st10" d="M263.4,274c-0.2-0.7,0.3-0.9,1.1-0.8c1.9,0.4,3.3,1.7,3.7,5.4c0.2,1.4,0.3,3,0.5,4.6
                  c3.6-3.4,6.6-7.1,8.8-9.7c0.5-0.6,1.3-0.7,1.1,0.3c-0.2,1.3-1.3,3-2.5,4.6s-3.8,4.6-7.2,7.6c0.2,1.8,0.3,3.5,0.5,4.7
                  c0.3,2.2,1,2.9,1.8,2.9c2,0.1,4.9-4,6.4-7.3c0.3-0.5,0.9-0.4,1,0.1c0.2,0.8,0.2,2.1-0.7,3.7c-2,3.5-5,5.3-7.9,5.4
                  c-3,0.1-4.6-1.7-4.9-6.1c0-0.1,0-0.1,0-0.1c-4.3,3.3-9.2,5.9-13.9,6.1c-7.7,0.3-10.4-6.6-6.6-13.6c3.6-6.7,9.5-9.8,12.4-8.2
                  c1.4,0.8,2.5,1.6,3.3,2.7c1,1.4,0.4,4.6-1.9,7.3c-2.8,3.3-6.5,4.7-10.5,1.6c-1.8,5-0.4,8.8,3.6,8.5c4.2-0.3,9-3.3,13.5-7
                  C264.5,282.5,264,276.6,263.4,274z M255,281.2c1.8-2.3,3-5,2.3-5.6c-1.3-1-6,2.4-8.6,7.4c-0.2,0.3-0.3,0.7-0.5,0.9
                  C251.1,285.6,253.4,283.3,255,281.2z"/>
               <path class="st10" d="M279.6,292.9c0.5-1.6,1.8-2.6,3-2.5c1.4,0.1,2.1,1.7,1.4,3.7c-0.7,2.1-3.1,3.7-5.1,2.9
                  c-0.3-0.2-0.4-0.5,0-0.7c0.5-0.2,1-0.4,1.5-0.9C279.7,295,279.3,294.1,279.6,292.9z M292.1,274.1c1,0.4,1.5,1.7,0.9,3.2
                  c-0.6,1.4-2.1,2.3-3.2,1.9s-1.5-1.8-0.9-3.3C289.5,274.7,291,273.7,292.1,274.1z"/>
            </g>
         </g>
         <g>
            <ellipse style="animation-delay: 0.4s;" class="st5" cx="175.9" cy="511" rx="156.5" ry="51"/>
            <g style="animation-delay: 0.4s;">
               <path class="st10" d="M69.8,522.9c-5.3,0-6.4-6.9-1.7-13.4c4.6-6.2,9.1-8.7,12-8.7c2.2,0,3.4,1.7,3.4,3.6c0.9-1.4,1.6-2.4,2-3
                  c0.5-0.7,0.9-0.8,1.4-0.3c0.9,1,1.3,3.3,0.1,6c-1.1,2.4-4.2,6.7-5.4,9.4c-1.4,3.1-1.1,5.1,0.6,4.9c3.7-0.3,8.8-7,11.5-15
                  c-4.6-1.6,0.8-9,3.1-9.1c1.8-0.1,1.2,3.1,0,6.8c2.1-0.2,4.2-1.1,6.4-2.5c0.8-0.5,1.2-0.7,1.7-0.1c1.1,1.1,2.5,3.6,0.8,5.5
                  c-2.7,2.9-5.6,6.7-6.5,9.4c-1,3-0.3,5.1,1.4,5.2c2.4,0.2,5.3-3.4,7.1-7.5c0.2-0.4,0.6-0.5,0.9,0c0.3,0.7,0.5,2-0.2,3.5
                  c-1.5,3.3-4.9,5.6-8,5.5c-4.4-0.2-6.1-3.9-4.7-8.2c1.1-3.6,4.6-7.6,7.1-10.2c-2.5,1.4-5,2-6.8,2c-2.8,7.6-8,15.8-14.1,16.3
                  c-3.2,0.2-4.9-1.7-4.8-4.4C75.2,520.8,72.6,522.9,69.8,522.9z M71.9,511.7c-3.1,4.7-3.7,9.4-1.5,9.4c2.3,0.1,5.3-3.3,8.2-8.1
                  c3.6-5.9,4.1-9.3,2.4-9.4C79.3,503.4,75.1,506.9,71.9,511.7z"/>
               <path class="st10" d="M106.8,515.2c1.4-4,5.2-10.4,7.3-13.2c0.5-0.7,0.9-0.8,1.4-0.3c0.9,0.9,1.3,3.2-0.1,5.9
                  c-1.2,2.4-3.6,6.1-4.8,8.8c-1.4,3.1-1.3,5.3,0.6,5.2c2.6-0.1,5.7-3.7,7.5-7.4c0.3-0.5,0.7-0.4,0.9,0c0.5,0.9,0.3,2-0.4,3.4
                  c-1.7,3.4-5.3,5.6-8.5,5.6C106.3,523.1,105.2,519.6,106.8,515.2z M119.6,501c-0.7,0.8-2.4,0.6-3.2-0.8c-0.9-1.5-0.6-3.1,0.3-4
                  s2.6-0.3,3.3,1.2C120.7,498.8,120.3,500.3,119.6,501z"/>
               <path class="st10" d="M138.6,501.4c0.5-0.7,1-0.8,1.4-0.3c0.9,1,1.3,3.3-0.1,6c-1.3,2.4-4.1,6.5-5.5,9.4c-1.4,3-1.5,5.4,0.5,5.3
                  c2.4-0.1,5.5-3.8,7.5-7.5c0.3-0.5,0.7-0.5,0.9-0.1c0.2,0.5,0.2,1.8-0.5,3.4c-1.5,3-5.1,6-8.3,5.8c-3.1-0.2-4.7-1.9-4.7-4.4
                  c-1.8,2.2-4.4,4.2-7.2,4.2c-5.3,0-6.4-6.9-1.7-13.5c4.6-6.4,9.2-8.8,12-8.8c2.5,0,3.6,1.8,3.5,3.9
                  C137.2,503.4,137.9,502.2,138.6,501.4z M134,503.6c-1.8-0.2-6,3.4-9.2,8.2c-3,4.7-3.8,9.4-1.5,9.5c2.4,0.1,5.3-3.4,8.3-8.2
                  C135.1,507.1,135.6,503.7,134,503.6z"/>
               <path class="st10" d="M142.7,508.6c-0.1-0.5-0.1-0.8,0.6-0.8c3.1,0.2,7.3-0.4,10-0.4c2.9,0,4,1.2,4.3,2.8c0.1,0.5,0,0.8-0.6,0.8
                  c-4.2-0.3-7.4,0.6-10,0.5C144.2,511.4,143,510.3,142.7,508.6z"/>
               <path class="st10" d="M164,521.5c2.8,0.2,6.8-3.7,9-7.9c0.4-0.7,1-0.8,1.3,0c0.3,0.9,0.1,2.1-0.6,3.4c-0.8,1.6-5,6.1-9.6,6
                  c-4-0.1-6.1-2.7-4.8-8.6c2.1-9.4,8.7-19.3,14.5-25c0.9-0.9,1.7-1,2.5-0.3c1.7,1.4,2,3.4,1.3,6c-1.8,6.7-8.2,13.2-13.3,16.3
                  C162.1,516.8,161.4,521.3,164,521.5z M175.7,494c-0.7-0.4-5,5.6-8.9,12.5c-0.5,0.9-1,1.7-1.4,2.6
                  C173.2,503.1,176.4,494.4,175.7,494z"/>
               <path class="st10" d="M172,515.2c1.4-4,5.2-10.4,7.3-13.2c0.5-0.7,0.9-0.8,1.4-0.3c0.9,0.9,1.3,3.2-0.1,5.9
                  c-1.2,2.4-3.6,6.1-4.8,8.8c-1.4,3.1-1.3,5.3,0.6,5.2c2.6-0.1,5.7-3.7,7.5-7.4c0.3-0.5,0.7-0.4,0.9,0c0.5,0.9,0.3,2-0.4,3.4
                  c-1.7,3.4-5.3,5.6-8.5,5.6C171.5,523.1,170.4,519.6,172,515.2z M184.8,501c-0.7,0.8-2.4,0.6-3.2-0.8c-0.9-1.5-0.6-3.1,0.3-4
                  c0.9-0.9,2.6-0.3,3.3,1.2C186,498.8,185.6,500.3,184.8,501z"/>
               <path class="st10" d="M202.2,501c0.6-0.6,1-0.5,1.1,0.2c0.2,1.1-0.5,2.5-2.4,4.7c-2.9,3.4-8.1,8.6-13.6,16.9
                  c-0.3,0.4-0.6,0.8-1.1,0.5c-2.2-1-3-2.1-2.9-5c0.2-3.8,1.8-11.6,3.7-17.2c0.2-0.6,0.7-0.8,1.1-0.4c1.9,1.5,2.2,3.5,1.4,6.8
                  c-1,4.2-2.2,7.2-3,13.1C190.7,514.4,198.3,504.8,202.2,501z"/>
               <path class="st10" d="M207.5,521.6c4.8,0.2,8.9-4.1,11.4-7.7c0.3-0.4,0.7-0.4,1,0.1c0.2,0.4,0.1,2-1,3.4
                  c-2.4,3.3-6.8,5.8-11.2,5.9c-7.3,0.1-9.8-6.7-6.1-13.7s9.6-9.8,12.4-8.2c1.4,0.8,2.5,1.6,3.4,2.8c1,1.3,0.6,4.6-1.8,7.4
                  c-2.8,3.3-6.6,4.7-10.7,1.6C203.1,518,204.5,521.4,207.5,521.6z M212.3,509.1c1.8-2.3,3.1-5.1,2.3-5.7c-1.2-0.9-6,2.3-8.7,7.5
                  c-0.2,0.3-0.3,0.7-0.5,0.9C208.3,513.5,210.6,511.2,212.3,509.1z"/>
               <path class="st10" d="M221,509.5c-0.1-0.5-0.1-0.8,0.6-0.8c3.1,0.2,7.3-0.6,9.9-0.5c2.9,0.1,4,1.3,4.3,2.9c0.1,0.5,0,0.8-0.6,0.8
                  c-4.2-0.3-7.4,0.6-10,0.5C222.5,512.3,221.3,511.1,221,509.5z M223.8,503.6c-0.1-0.5,0-0.8,0.6-0.7c3.1,0.2,7.3-0.5,10-0.4
                  c2.8,0.2,4,1.4,4.2,3c0.1,0.5,0,0.8-0.7,0.8c-4.1-0.4-7.4,0.3-10,0.3C225.3,506.5,224.1,505.3,223.8,503.6z"/>
               <path class="st10" d="M247.1,499.4c-0.5-0.1-0.5-0.4-0.3-0.9c1.2-2.8,4.5-8.3,6.5-10.4c0.6-0.7,1.1-0.4,1.4,0.1
                  c0.5,1.1,0,2.3-0.7,3.4c-1.3,1.9-3.8,5.3-5.3,7C247.9,499.3,247.5,499.5,247.1,499.4z M249.6,501.2c-0.5-0.3-0.3-0.5,0-1.1
                  c1.7-2.4,6.2-7.9,8.5-9.7c0.7-0.5,1.4-0.3,1.5,0.3c0.1,1.2-0.6,2.4-1.4,3.3c-1.5,1.9-5.3,5.2-7.1,6.6
                  C250.4,501.1,249.9,501.4,249.6,501.2z"/>
               <path class="st10" d="M264.9,499.4c-0.5-0.1-0.5-0.4-0.3-0.9c1.2-2.8,4.5-8.3,6.5-10.4c0.6-0.7,1.1-0.4,1.4,0.1
                  c0.5,1.1,0,2.3-0.7,3.4c-1.3,1.9-3.8,5.3-5.3,7C265.7,499.3,265.3,499.5,264.9,499.4z M267.4,501.2c-0.5-0.3-0.3-0.5,0-1.1
                  c1.7-2.4,6.2-7.9,8.5-9.7c0.7-0.5,1.4-0.3,1.5,0.3c0.1,1.2-0.6,2.4-1.4,3.3c-1.5,1.9-5.3,5.2-7.1,6.6
                  C268.2,501.1,267.7,501.4,267.4,501.2z"/>
            </g>
         </g>
         <g>
            <ellipse style="animation-delay: 0.5s;" class="st5" cx="730.2" cy="532.8" rx="222.1" ry="58.8"/>
            <g style="animation-delay: 0.5s;">
               <path class="st10" d="M567.7,530.5c-0.1-0.5-0.1-0.8,0.6-0.8c3.1,0.2,7.3-0.4,10-0.4c2.9,0,4,1.2,4.3,2.8c0.1,0.5,0,0.8-0.6,0.8
                  c-4.2-0.3-7.4,0.6-10,0.5C569.2,533.3,568.1,532.1,567.7,530.5z"/>
               <path class="st10" d="M586.4,530.5c-0.1-0.5-0.1-0.8,0.6-0.8c3.1,0.2,7.3-0.4,10-0.4c2.9,0,4,1.2,4.3,2.8c0.1,0.5,0,0.8-0.6,0.8
                  c-4.2-0.3-7.4,0.6-10,0.5C587.8,533.3,586.7,532.1,586.4,530.5z"/>
               <path class="st10" d="M625.3,536c0.3-0.8,1-0.5,1.1,0s0.2,1.9-0.7,3.6c-2.2,3.7-6.1,5.5-10.2,5.8c-3.7,0.2-6.1-0.3-8.1-2
                  c-1.2-1-1.8-2.3-1.9-3.6c-2.7,4.7-5.6,10.2-8.4,16.2c-0.2,0.5-0.6,0.6-1,0.1c-0.9-1.1-1.1-2.7-0.1-6c1.7-5.3,11.3-22.5,15-27
                  c0.6-0.7,1.1-0.6,1.4,0.1c0.3,0.7,0.4,1.5,0.3,2.4c2.5-2.1,5.9-3.6,8.4-1.1c2.8,2.8,1.5,7.6-0.6,12c-1.7,3.6-3.8,6.2-6,7.4
                  C620,543.6,623.4,539.7,625.3,536z M608.5,542.3c1.8,1.4,5.6-2.5,8.3-7.8c2.2-4.3,2.9-8.8,1.4-9.5c-1.4-0.7-4.9,2.1-7.6,6.2
                  c-0.6,1-1.3,2-1.9,3.1C607.2,537.8,606.9,541,608.5,542.3z"/>
               <path class="st10" d="M640.1,524.5c0.7-0.4,1.1-0.5,1.5-0.1c1.1,1.1,2.5,3.5,0.8,5.3c-2.6,2.8-5.4,6.2-6.3,9
                  c-0.9,2.8-0.2,4.9,1.4,5c2.2,0.1,5-3.3,6.8-7.2c0.2-0.4,0.6-0.5,0.8-0.1c0.3,0.6,0.3,1.9-0.3,3.4c-1.3,3.1-4.6,5.4-7.7,5.3
                  c-4.1-0.2-5.8-3.7-4.4-7.8c1.3-3.6,4.6-7.4,6.9-9.9c-2.5,1.6-5,2.1-6.7,2.1c-2.2,3.8-7.7,11.5-8.8,16.4c-0.1,0.5-0.5,0.8-1,0.3
                  c-1.7-1.6-1.7-4.1-0.1-6.9c1.3-2.4,5.2-7.5,7.3-10.4c-3.3-2,1.7-8.4,3.8-8.5c1.8-0.1,1.3,3.1-0.1,6.5
                  C636,526.8,637.9,525.9,640.1,524.5z"/>
               <path class="st10" d="M643.7,537.1c1.4-4,5.2-10.4,7.3-13.2c0.5-0.7,0.9-0.8,1.4-0.3c0.9,0.9,1.3,3.2-0.1,5.9
                  c-1.2,2.4-3.6,6.1-4.8,8.8c-1.4,3.1-1.3,5.3,0.6,5.2c2.6-0.1,5.7-3.7,7.5-7.4c0.3-0.5,0.7-0.4,0.9,0c0.5,0.9,0.3,2-0.4,3.4
                  c-1.7,3.4-5.3,5.6-8.5,5.6C643.2,545,642.1,541.5,643.7,537.1z M656.5,522.9c-0.7,0.8-2.4,0.6-3.2-0.8c-0.9-1.5-0.6-3.1,0.3-4
                  c0.9-0.9,2.6-0.3,3.3,1.2C657.7,520.7,657.3,522.1,656.5,522.9z"/>
               <path class="st10" d="M673.1,522.8c1.9,0.1,3,2.9,2.2,5.3c2.8-2.5,6.6-5.1,8.5-5c2.1,0.1,2.5,3.3,1.4,6c-1,2.4-3.8,6-5.3,8.7
                  c-1.6,3.1-0.8,5.6,1.3,5.5c2.4-0.1,4.9-3.6,7.2-7.4c0.3-0.5,0.7-0.5,1-0.1s0.1,2.2-0.5,3.4c-1.6,3.1-5.3,5.8-8.6,5.8
                  c-5,0-6.8-3.7-4.7-8.2s7.2-10.6,6.4-10.8c-0.5-0.2-2.4,0.4-6.1,4c-4.8,4.5-7.6,9.7-10,14.8c-0.3,0.7-0.8,0.8-1.3,0.3
                  c-1-0.8-1.8-2.9-0.1-6.8c1.9-4.5,8.1-12,6.9-12.4c-0.7-0.3-3.5,1.2-7.2,5c-3.4,3.5-6.1,8.5-8.4,13.6c-0.2,0.5-0.6,0.7-1.1,0.3
                  c-1.3-1-1.5-2.8-0.6-5.6c1.2-3.4,5.4-11.1,9-16.2c0.4-0.6,0.9-0.5,1.2,0c1,1.5,0.8,3.6-0.1,5.8C666.7,526,671,522.8,673.1,522.8z
                  "/>
               <path class="st10" d="M692.3,544.8c-5.3,0-6.4-6.9-1.7-13.4c4.6-6.2,9.1-8.7,12-8.7c2.2,0,3.4,1.7,3.4,3.6c0.9-1.4,1.6-2.4,2-3
                  c0.5-0.7,0.9-0.8,1.4-0.3c0.9,1,1.3,3.3,0.1,6c-1.1,2.4-4.2,6.7-5.4,9.4c-1.4,3.1-1.1,5.1,0.6,4.9c3.7-0.3,8.8-7,11.5-15
                  c-4.6-1.6,0.8-9,3.1-9.1c1.8-0.1,1.2,3.1,0,6.8c2.1-0.2,4.2-1.1,6.4-2.5c0.8-0.5,1.2-0.7,1.7-0.1c1.1,1.1,2.5,3.6,0.8,5.5
                  c-2.7,2.9-5.6,6.7-6.5,9.4c-1,3-0.3,5.1,1.4,5.2c2.4,0.2,5.3-3.4,7.1-7.5c0.2-0.4,0.6-0.5,0.9,0c0.3,0.7,0.5,2-0.2,3.5
                  c-1.5,3.3-4.9,5.6-8,5.5c-4.4-0.2-6.1-3.9-4.7-8.2c1.1-3.6,4.6-7.6,7.1-10.2c-2.5,1.4-5,2-6.8,2c-2.8,7.6-8,15.8-14.1,16.3
                  c-3.2,0.2-4.9-1.7-4.8-4.4C697.7,542.6,695.1,544.8,692.3,544.8z M694.4,533.5c-3.1,4.7-3.7,9.4-1.5,9.4c2.3,0.1,5.3-3.3,8.2-8.1
                  c3.6-5.9,4.1-9.3,2.4-9.4C701.7,525.3,697.6,528.8,694.4,533.5z"/>
               <path class="st10" d="M740.9,546.2c-3.2,7.3-7.3,16-12,17.7c-1.1,0.4-2.2,0.4-3.3-1.1c-1.1-1.6-2.3-3.5-1.1-6.7
                  c1.7-4.9,6.6-8.1,12.3-10.3c0.6-1.2,1.1-2.6,1.8-3.9c-1.8,1.7-4,3.1-6.2,3c-4.1-0.3-4.2-4.3-2.9-7.9c1.3-3.5,4.1-8.8,7.8-13.7
                  c0.5-0.6,1-0.5,1.4,0c1.4,1.7,1,4-1.1,7.2c-2.1,3.1-3.3,5.4-4.4,7.9c-1.1,2.7-1,4.6,0.3,4.7c2.2,0.2,5.1-3.3,8-8
                  c2.5-4,4.9-8.9,6.9-11.7c0.5-0.7,0.9-0.8,1.4-0.4c0.8,1,1.3,3.3-0.2,6.1c-1.7,3.1-5.2,9.7-7.6,14.9c4.5-2.3,7.9-5.4,10.1-8.4
                  c0.3-0.4,0.8-0.4,0.9,0.1c0.1,0.5-0.1,1.7-1,3.1C749.7,542.3,745.7,544.3,740.9,546.2z M726.8,560.9c0.7,1,5.4-4.9,9-12.9
                  C728.3,552.1,725.8,559.6,726.8,560.9z"/>
               <path class="st10" d="M757.1,539.9c1.1,0.4,1.5,1.8,0.9,3.3c-0.6,1.4-2.1,2.3-3.3,1.9c-1-0.3-1.5-1.9-0.9-3.3
                  C754.5,540.4,756,539.5,757.1,539.9z M766,523.7c1.1,0.4,1.5,1.7,0.9,3.2c-0.6,1.4-2.1,2.3-3.2,1.9s-1.5-1.8-0.9-3.3
                  C763.4,524.2,764.8,523.3,766,523.7z"/>
               <path class="st10" d="M770.7,530.5c-0.4,0-0.6-0.2-0.3-0.5c0.4-0.5,1.3-1,3.5-1.3c0.6-0.1,1.4-0.2,2.2-0.2
                  c1.4-2.5,2.8-4.8,3.7-6.1c0.3-0.5,0.6-0.5,0.8-0.1c0.4,1-0.3,2.8-1.1,4.5c-0.2,0.5-0.5,1-0.8,1.5c1.3-0.1,2.6-0.1,3.9-0.1
                  c1.5-2.6,3-4.8,3.9-6c0.3-0.4,0.7-0.4,0.8,0c0.3,1-0.3,2.7-1.2,4.4c-0.3,0.5-0.6,1-0.9,1.6c1.5,0,2.8,0,3.8,0.2
                  c0.6,0.1,0.7,0.3,0.4,0.6c-1,1-2.8,1.2-4.8,1.2c-0.2,0-0.4,0-0.6,0c-1,1.7-2.2,3.6-3.3,5.7c1.8,0,3.4,0,4.6,0.1
                  c0.6,0,0.7,0.3,0.4,0.6c-0.9,0.9-2.8,1.2-4.7,1.2c-0.5,0-0.9,0-1.4,0c-1.1,1.8-2.1,3.8-3.1,5.7c-0.2,0.3-0.4,0.3-0.6,0
                  c-0.3-0.4-0.4-1.5,0.4-3.5c0.3-0.6,0.6-1.4,1-2.2c-1.4,0-2.7,0-3.9,0.1c-0.8,1.6-1.7,3.3-2.6,5.1c-0.2,0.4-0.4,0.3-0.6,0
                  c-0.3-0.4-0.4-1.4,0.3-3.5c0.2-0.4,0.4-1,0.6-1.5c-1.4,0.1-2.7,0.2-4.1,0.3c-0.5,0-0.5-0.2-0.3-0.4c0.4-0.6,1.2-1.1,3.4-1.4
                  c0.5-0.1,1.1-0.2,1.9-0.2c0.8-1.8,1.9-3.9,3.1-6C773.6,530.3,772.2,530.4,770.7,530.5z M777.6,530.2c-1,1.8-2.1,3.8-3.3,5.9
                  c1.2-0.1,2.6-0.1,3.9-0.2c0.9-1.8,2-3.8,3.1-5.7C780.1,530.2,778.9,530.2,777.6,530.2z"/>
               <path class="st10" d="M803.5,535.8c0.1,0.4,0,0.6-0.5,0.5c-0.6-0.1-1.4-0.2-2.3-0.1c-1.8,3.1-3.4,6.2-4.5,8.7
                  c-0.2,0.4-0.5,0.4-0.7,0.2c-0.6-0.9-1.1-2-0.3-4.4c0.4-1.1,1.1-2.6,1.9-4.2c-2.2,0.3-4.4,0.6-6.2,1c-0.6,0.1-1.1,0.1-1.4-0.3
                  c-0.5-0.7-1.1-1.7-1.5-2.3c-0.3-0.4-0.2-0.9,0.2-1.3c3.5-3.9,11.7-10.1,17.1-13.4c0.5-0.3,1.2-0.5,1.6,0.1
                  c0.4,0.6,0.7,1.1,1.2,1.9c0.5,0.9,0.5,1.1-0.3,2.3c-1.5,2.2-3.7,5.8-6,9.6C802.8,534.5,803.3,535.2,803.5,535.8z M790.2,534.9
                  c2.6-0.6,5.8-1.2,8.5-1.3c1.9-3.4,4.1-7.1,6.1-9.9C799.9,526.9,793.9,531.1,790.2,534.9z"/>
               <path class="st10" d="M811.7,521.1c-0.1-0.4,0-0.5,0.5-0.5c3.5,0.2,8.2-1,10.5-1c1.8,0,2.7,0.6,3.2,2c0.1,0.3,0,0.6-0.5,0.5
                  c-3,0-6,0.7-8.6,1.1c-1.7,2.4-3.8,5.9-5.8,9.4c1.1-0.2,2.2-0.3,3-0.3c2.1-0.1,3,0.6,3.5,1.8c0.1,0.4,0.1,0.6-0.4,0.6
                  c-2.3,0-5.5,0.6-7.8,1.1c-1.2,2.2-2.4,4.4-3.3,6.3c2-0.6,4.8-1.2,6.6-1.2c2.1-0.1,3.1,0.6,3.6,1.8c0.1,0.4,0.1,0.6-0.4,0.6
                  c-2.4,0-7,1-9.3,1.6c-0.8,0.2-1.3,0.2-1.8-0.4c-0.3-0.4-0.6-1-0.9-1.5c-0.4-0.7-0.4-1.2,0-2.3c1.9-4.9,6.2-12.4,9.7-17.6
                  C812.4,522.8,811.9,522,811.7,521.1z"/>
               <path class="st10" d="M836.6,536c1.1,2.7-1.6,5.7-3.4,6.9c-3,2-6.6,2-8.8,0.8c-1.9-1-3.6-3.4-3.6-6.7
                  c-0.2-8.5,7.4-15.7,12.1-17.4c2.3-0.8,4.4-1,5.7,0.1c0.9,0.8,1.9,1.9,2.2,2.9c0.4,1.2,0.3,2.3-0.4,3.5c-0.6,1.2-1.2,2.8-1.7,4
                  c-0.1,0.3-0.4,0.6-0.9,0.2c-1-0.6-1.6-1.8-1-3.8c0.7-2.1,2.2-4.4,1.2-5.4c-1.2-1.2-7.6,2-11.4,8c-3.7,5.8-3.3,11.8-1.2,13.4
                  c2.7,2.1,7.2-0.6,10.4-6.6C836,535.5,836.4,535.5,836.6,536z"/>
               <path class="st10" d="M844.2,522.4c-0.2-0.4-0.1-0.7,0.4-0.8c2.9-0.4,5-2,7.8-2.4c2.7-0.4,4.8,0.9,6.4,3.5
                  c3.9,6.4-3.6,18.1-15,21.6c-1.2,0.4-2,0.3-2.7-0.3c-0.5-0.4-1.2-0.9-1.8-1.7c-0.8-1-1-1.6-0.1-4.2c1.3-3.8,4.8-10,7.7-14.1
                  C845.3,524,844.4,523,844.2,522.4z M848.1,527.9c-2.1,3.6-5.5,8.9-6.8,12.6c-0.5,1.7-0.3,2.2,1,1.8c11.5-4.2,16.9-15.6,14.4-19.4
                  c-1.5-2.2-4.6-0.1-7.5,0.8C849.4,524.7,849.1,526.2,848.1,527.9z"/>
               <path class="st10" d="M875.7,536c1.1,2.7-1.6,5.7-3.4,6.9c-3,2-6.6,2-8.8,0.8c-1.9-1-3.5-3.4-3.6-6.7
                  c-0.2-8.5,7.4-15.7,12.1-17.4c2.3-0.8,4.4-1,5.7,0.1c0.9,0.8,1.9,1.9,2.2,2.9c0.4,1.2,0.3,2.3-0.4,3.5c-0.6,1.2-1.2,2.8-1.7,4
                  c-0.1,0.3-0.4,0.6-0.9,0.2c-1-0.6-1.6-1.8-1-3.8c0.7-2.1,2.2-4.4,1.2-5.4c-1.2-1.2-7.6,2-11.4,8c-3.7,5.8-3.3,11.8-1.2,13.4
                  c2.7,2.1,7.2-0.6,10.4-6.6C875.1,535.5,875.5,535.5,875.7,536z"/>
               <path class="st10" d="M893.8,535.8c0.1,0.4,0,0.6-0.5,0.5c-0.6-0.1-1.4-0.2-2.3-0.1c-1.8,3.1-3.4,6.2-4.5,8.7
                  c-0.2,0.4-0.5,0.4-0.7,0.2c-0.6-0.9-1.1-2-0.3-4.4c0.4-1.1,1.1-2.6,1.9-4.2c-2.2,0.3-4.4,0.6-6.2,1c-0.6,0.1-1.1,0.1-1.4-0.3
                  c-0.5-0.7-1.1-1.7-1.5-2.3c-0.3-0.4-0.2-0.9,0.2-1.3c3.5-3.9,11.7-10.1,17.1-13.4c0.5-0.3,1.2-0.5,1.6,0.1
                  c0.4,0.6,0.7,1.1,1.2,1.9c0.5,0.9,0.5,1.1-0.3,2.3c-1.5,2.2-3.7,5.8-6,9.6C893.2,534.5,893.6,535.2,893.8,535.8z M880.6,534.9
                  c2.6-0.6,5.8-1.2,8.5-1.3c1.9-3.4,4.1-7.1,6.1-9.9C890.3,526.9,884.3,531.1,880.6,534.9z"/>
            </g>
         </g>
         <g>
            <ellipse style="animation-delay: 0.7s;" class="st5" cx="778.6" cy="284.4" rx="172.7" ry="58.8"/>
            <g style="animation-delay: 0.6s;">
               <path class="st10" d="M699.9,296.3c3.5,0.2,8.7-8.3,11.9-16.9c-4.7-1.7,0.8-9.9,3.3-10c1.9-0.1,1.4,3.6,0.1,7.5
                  c2.1-0.2,4.3-1.3,6.6-2.9c0.7-0.5,1.3-0.7,1.8-0.1c1.1,1.3,2.5,4.1,0.8,6.2c-2.8,3.3-5.7,7.4-6.8,10.5c-1.1,3.3-0.4,5.7,1.5,5.8
                  c2.4,0.2,5.5-3.7,7.4-8.3c0.2-0.5,0.7-0.5,0.9-0.1c0.3,0.7,0.5,2.3-0.2,3.9c-1.6,3.6-5.2,6.2-8.4,6.2c-4.6-0.1-6.5-4.3-5-9.1
                  c1.3-4.2,5-8.6,7.5-11.5c-2.7,1.7-5.2,2.3-7.2,2.3c-3.1,8-8.3,18.2-14.7,18.4c-4.6,0.1-6.3-4.3-4.8-9.1c1.3-4.2,5-8.6,7.5-11.5
                  c-2.6,1.7-5.2,2.4-7.2,2.4c-3.1,7.9-8.3,17.5-14.8,18.2c-3.4,0.3-5.1-1.9-5-5c-2,2.4-4.7,4.8-7.7,4.8c-5.5,0-6.7-7.5-1.7-14.8
                  c4.8-7,9.6-9.6,12.6-9.6c2.4,0,3.6,1.8,3.6,3.9c0.9-1.5,1.7-2.7,2.1-3.2c0.5-0.7,1-0.9,1.4-0.4c0.9,1,1.4,3.6,0,6.6
                  c-1.3,2.7-4.2,7.3-5.7,10.5c-1.5,3.4-1.2,5.7,0.7,5.4c3.8-0.4,8.8-8.1,12.1-16.7c-4.8-1.7,0.8-9.8,3.3-10
                  c1.9-0.1,1.4,3.6,0.1,7.5c2.1-0.2,4.3-1.3,6.6-2.8c0.7-0.5,1.3-0.8,1.8-0.2c1.1,1.4,2.7,4.2,0.8,6.2c-2.9,3.2-5.8,7.3-6.9,10.4
                  C697.3,293.9,698,296.2,699.9,296.3z M679.4,276.4c-1.9-0.1-6.3,3.7-9.6,9c-3.3,5.2-3.9,10.6-1.6,10.6c2.4,0,5.5-3.8,8.6-9
                  C680.6,280.2,681.1,276.5,679.4,276.4z"/>
               <path class="st10" d="M733,292.4c1.2,0.4,1.6,2,0.9,3.6c-0.7,1.6-2.2,2.5-3.4,2.1s-1.6-2.2-0.9-3.6
                  C730.3,292.9,731.8,292,733,292.4z"/>
               <path class="st10" d="M750.1,293c0,0.7-0.1,1.3-0.1,2.1c2.3-1.5,4.7-4.5,6.3-7.7c0.4-0.7,1-0.8,1.3,0.1s-0.1,2.2-0.6,3.6
                  c-0.6,1.4-3.6,5-7.2,6.3c-1,6.1-3.6,12.8-6.5,16.4c-2,2.5-3.6,2.8-5.4,1.2c-1.5-1.3-3.3-3.6-2.7-8.7
                  c1.7-13.4,15.6-36.5,22.3-46.6c0.5-0.7,1.3-0.7,1.5,0.1c0.7,2.1,0.5,4.6-2.1,9c-2.5,4.1-6.6,10.9-9.9,17.2
                  C749.3,286.7,750.2,289,750.1,293z M738.5,312.6c1.3,1.7,5.8-6.6,7.8-14.8c-1.7-0.2-3-1-3.7-2.4
                  C739.3,303.4,737.1,310.9,738.5,312.6z M747.2,291.7c0.1-2.2-0.5-4.1-1-3.9c-0.6,0.2-1.1,3.4-1.2,4.8c-0.1,1.9,0.5,3.2,1.6,3.4
                  C747,294.6,747.2,293.1,747.2,291.7z"/>
               <path class="st10" d="M755.5,289.2c1.5-4.4,5.5-11.5,7.7-14.7c0.5-0.7,1-0.9,1.4-0.4c0.9,1,1.4,3.6-0.1,6.6
                  c-1.3,2.7-3.8,6.8-5.1,9.8c-1.4,3.5-1.3,5.8,0.6,5.7c2.7-0.1,6-4.1,7.8-8.3c0.3-0.5,0.7-0.5,1,0c0.5,1,0.3,2.2-0.4,3.7
                  c-1.8,3.7-5.6,6.3-9,6.3C755,298,753.8,294.2,755.5,289.2z M768.9,273.5c-0.8,0.8-2.5,0.7-3.4-0.9c-1-1.6-0.7-3.4,0.3-4.5
                  c1-1,2.7-0.3,3.5,1.3C770.2,271,769.7,272.6,768.9,273.5z"/>
               <path class="st10" d="M772.9,296.2c2.9,0.2,7.1-4.1,9.5-8.7c0.4-0.8,1.1-0.8,1.3,0c0.3,1,0.1,2.3-0.6,3.7
                  c-0.8,1.8-5.2,6.8-10.1,6.7c-4.2-0.1-6.4-3-5-9.5c2.2-10.5,9.1-21.4,15.3-27.8c0.9-1,1.7-1.1,2.6-0.3c1.7,1.6,2.1,3.8,1.4,6.6
                  c-1.9,7.4-8.6,14.7-14,18.2C770.8,291,770.2,296,772.9,296.2z M785.2,265.6c-0.8-0.5-5.2,6.2-9.4,13.9c-0.5,1-1,1.9-1.4,2.9
                  C782.6,275.8,786,266.1,785.2,265.6z"/>
               <path class="st10" d="M784.8,272.1c-0.6-0.8-0.2-1.3,0.7-1.2c1.7,0.1,3.4-0.1,5-0.2c1.9-3.3,3.6-6.2,4.6-7.5
                  c0.5-0.8,1.1-0.7,1.4,0c0.7,1.7,0.7,3.7-0.8,6.8c2.4-0.1,3.3,1,3.7,1.8c0.4,0.7,0.2,1.1-0.7,1.1c-1.3-0.2-3.2,0-4.7,0.2
                  c-2.4,4-5.8,9.9-8.1,15c-2,4.4-1.3,8.1,1.2,8.3c2.7,0.1,6.2-4.2,8.4-8.7c0.4-0.7,1-0.8,1.3,0c0.3,1,0,2.1-0.6,3.4
                  c-0.8,1.7-4.8,7.1-9.6,6.9c-4-0.1-7.2-4.6-4.3-11.5c1.3-3,3.8-8.1,6.4-12.8C786.7,273.7,785.3,272.8,784.8,272.1z"/>
               <path class="st10" d="M824.7,298c-4.6-0.2-6.4-4.3-4.9-9.2c1.3-4,4.9-8.5,7.5-11.4c-2.7,1.7-5.4,2.3-7.2,2.3
                  c-5,10.5-11.5,18.1-18.7,18.2c-7.7,0.1-10.3-7.4-6.3-15.1c3.8-7.5,10-10.9,13-9.1c1.5,0.9,2.6,1.7,3.6,3.1s0.4,5-2,8.1
                  c-2.8,3.7-6.9,5.2-11.1,1.8c-1.9,5.5-0.4,9.4,2.7,9.5c6.6,0.3,13.3-9.2,16.6-16.9c-4.6-1.9,1.3-10,3.8-10.1
                  c1.9-0.1,1.3,3.6-0.2,7.5c2.1-0.3,4.2-1.3,6.6-2.8c0.7-0.5,1.3-0.8,1.7-0.2c1.2,1.3,2.7,4.2,0.9,6.2c-2.8,3.1-5.9,7.2-6.9,10.5
                  s-0.3,5.7,1.5,5.8c2.5,0.1,5.6-3.9,7.5-8.3c0.2-0.5,0.7-0.6,0.9-0.1c0.3,0.7,0.4,2.2-0.3,3.9C831.6,295.4,828,298.2,824.7,298z
                  M806.1,282.4c1.9-2.5,3.2-5.6,2.4-6.3c-1.3-1.1-6.3,2.7-9,8.2c-0.2,0.3-0.3,0.7-0.5,1C801.9,287.2,804.3,284.7,806.1,282.4z"/>
               <path class="st10" d="M853.8,258.8c0.3,1.9-0.9,4.5-4.2,7.4c-8.1,7.3-13.4,19.5-12.2,34.4c0.1,0.5-0.4,0.5-0.7,0.4
                  c-0.6-0.4-1.4-1.5-1.7-3.3c-2.3-13.6,3.4-30.5,17.6-39.3C853.3,258,853.7,258,853.8,258.8z"/>
               <path class="st10" d="M861,298.8c-1.8,1.3-3.6,1.9-4.8,1.9c-0.5,0-0.5-0.4-0.2-0.7c11.4-9.6,16.5-23.2,16-33.5
                  c-0.2-4.3,0.9-6.9,2.4-8.1c0.5-0.4,1-0.2,1.1,0.5C879.2,275.3,870.2,292.1,861,298.8z"/>
               <path class="st10" d="M871.6,295.3c0.5-1.8,1.9-2.9,3.2-2.8c1.4,0.1,2.2,1.9,1.5,4.1c-0.8,2.4-3.3,4.1-5.4,3.3
                  c-0.4-0.2-0.4-0.6,0-0.7c0.5-0.2,1.1-0.5,1.6-1C871.8,297.7,871.3,296.6,871.6,295.3z M884.8,274.4c1,0.4,1.6,1.9,0.9,3.6
                  c-0.7,1.6-2.2,2.5-3.4,2.1s-1.6-2.1-0.9-3.6C882.1,275,883.6,273.9,884.8,274.4z"/>
            </g>
         </g>
      </g>
      <g id="POWERS">
         <polygon id="WHITE" class="st11 star star-1" points="476.6,149.5 494.6,123.6 501.3,154.4 527.8,137.4 522.2,168.4 553.3,162.8 536.2,189.4 
            567,196.1 541.1,214.1 567,232 536.2,238.7 553.3,265.3 522.2,259.7 527.8,290.7 501.3,273.7 494.6,304.5 476.6,278.6 458.6,304.5 
            451.9,273.7 425.4,290.7 431,259.7 400,265.3 417,238.7 386.2,232 412.1,214.1 386.2,196.1 417,189.4 400,162.8 431,168.4 
            425.4,137.4 451.9,154.4 458.6,123.6 	"/>
         <polygon id="BLUE" class="st12 star star-2" points="476.6,189.1 492.1,166.5 491.3,193.8 517.1,184.7 500.4,206.3 526.6,214.1 500.4,221.8 
            517.1,243.4 491.3,234.3 492.1,261.6 476.6,239.1 461.2,261.6 461.9,234.3 436.2,243.4 452.8,221.8 426.6,214.1 452.8,206.3 
            436.2,184.7 461.9,193.8 461.2,166.5 	"/>
      </g>
</svg>

</div>
</template>

<script>
export default {
  name: 'MeCode',
}
</script>

<style lang="scss" scoped>
@import '../styles/palette';
@import '../styles/animations';

.st0{fill:#D19477;}
.st1{fill:#F2CCB7;}
.st2{fill:#281305;}
.st3{fill:#84522C;}
.st4{fill:#00A0E7;}
.st5{fill:#FFFFFF;}
.st6{fill:#FEE3D4;}
.st7{fill:#B78B6E;}
.st8{fill:#72B776;}
.st9{fill:#19601B;}
.st10{fill:#1A545C;}
.st11{fill:#F5FFF6;}
.st12{fill:#00D0C5;}

.me-code-wrapper {
   margin-top: -20px;
}

.me-code {
   position: relative;

   ellipse, path, .star {
      transition: all 0.2s;
   }

   .star {
      opacity: 0;
   }

   &:hover {
      #THOUGHTS {
         ellipse {
            fill: white;
            animation: fade 1s;
         }

         path {
            fill: var(--primary);
            animation: fade 1s;
         }
      }

      .star {
         animation-name: fade-in;
         animation-duration: 2.5s;
         animation-iteration-count: infinite;
         filter: blur(10px);
      }

      .star-2 {
         animation-delay: 0.2s;
         filter: blur(5px);
      }

      .left-eyebrow {
         transform: translateY(-15px);
      }
   }

   #THOUGHTS {
      ellipse, path {
         fill: transparent;
         transition: all 0.3s;
      }
   }
}
</style>
