<template>
   <div class="me-futbol-wrapper">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 974.5 953.3" style="enable-background:new 0 0 974.5 953.3;" xml:space="preserve">
         <polygon id="STARBURST" class="st1" points="479.6,364.4 511.6,162 511.5,366.9 574.7,172 542.6,374.3 635.5,191.8 572.1,386.6 
	692.4,220.8 599.3,403.2 744.1,258.3 623.6,424 789.3,303.5 644.3,448.3 826.8,355.2 661,475.5 855.8,412.1 673.3,505 875.6,472.9 
	680.7,536.1 885.6,536 683.2,567.9 885.6,599.9 680.7,599.8 875.6,663 673.3,630.9 855.8,723.8 661,660.4 826.8,780.7 644.3,687.6 
	789.3,832.4 623.6,711.9 744.1,877.6 599.3,732.6 692.4,915.1 572.1,749.3 635.5,944.1 542.6,761.6 574.7,963.9 511.5,769 
	511.6,973.9 479.6,771.5 447.7,973.9 447.8,769 384.6,963.9 416.7,761.6 323.8,944.1 387.2,749.3 266.9,915.1 360,732.6 
	215.2,877.6 335.7,711.9 170,832.4 314.9,687.6 132.5,780.7 298.3,660.4 103.5,723.8 286,630.9 83.7,663 278.6,599.8 73.7,599.9 
	276.1,567.9 73.7,536 278.6,536.1 83.7,472.9 286,505 103.5,412.1 298.3,475.5 132.5,355.2 314.9,448.3 170,303.5 335.7,424 
	215.2,258.3 360,403.2 266.9,220.8 387.2,386.6 323.8,191.8 416.7,374.3 384.6,172 447.8,366.9 447.7,162 "/>
         <g id="FLAGS">
            <g id="GOAL-FLAG-LEFT">
               <path d="M163.3,742.5c-0.4,0.5-1,1.2-1.5,2c-0.8-0.8-84.9-195.8-85.2-196.7c-2.7-7.6-5.3-15.3-7.8-23
                  c-10.5-32.5-25-62.9-42.6-91.6c-1.6-2.6-3.5-4.9-4.9-7.5c-0.5-0.9,0-2.5,0-3.8c1,0.5,2.4,0.7,2.9,1.6c6.2,9.7,12.6,19.3,18.3,29.4
                  c16.4,28.6,27.4,59.7,36.9,91.6C79.9,545.4,163,740.9,163.3,742.5z"/>
               <path d="M167.9,461.1c-1.1-3.2-2.2-6.4-3.2-9.6c-5-15-13.6-28-23-40.6c-2.9-3.9-6.6-6.4-11.4-6.1c-12.2,1-24.5,1.5-36.4,3.9
                  c-17,3.4-33.7,8.3-50.4,12.7c-4,1-8,2.4-12.1,3.7c33.2,14.2,40.6,41.2,38.2,73.6c1-0.4,2-0.8,2.9-1.4
                  c15.1-9.5,31.5-16.4,48.5-21.3c14.5-4.2,29.3-7.1,44.1-10.4C168,464.9,168.9,463.9,167.9,461.1z"/>
               <path d="M314.5,440.1c-0.9,0-1.7,0-2.6,0c-11.8-0.2-23.5-1.2-34.6-5.3c-4.2-1.6-4.6-3.2-1.6-6.5c4.7-5.1,9.5-9.9,14.2-15
                  c1.6-1.8,3-3.8,4.6-5.7c-2-0.3-3.5,0.1-5,0.4c-15,3-30,2.6-45.2,1.9c-18.2-0.9-36.4-3.1-54.6-0.8c-11.3,1.5-22.2,4.6-32.4,9.9
                  c-2.4,1.3-3,2.4-1.7,5.2c5.8,12.4,11.1,25,16.8,37.5c2.3,5.1,1.7,9.1-2.5,13c-3.1,2.8-5.6,6.2-8.3,9.4c0.2,0.3,0.4,0.5,0.5,0.8
                  c1.2-0.1,2.4,0,3.6-0.3c5.9-1.7,11.8-3.2,17.5-5.3c9.5-3.6,18.6-8.1,28.1-11.4c19.4-6.6,39.5-9.5,60-10.1
                  c11.3-0.3,22-3.2,31.9-8.6c4.2-2.3,8-5.5,11.9-8.2C314.8,440.7,314.6,440.4,314.5,440.1z"/>
               <g>
                  <path class="st0" d="M94.2,439.1c-0.8-0.4-1.8-0.7-2.8-0.8c-1.1-0.1-2.1,0-3.1,0.2c-1.1,0.3-2,0.7-2.8,1.3
                     c-0.8,0.6-1.4,1.3-1.9,2.2c-0.5,0.8-0.8,1.8-0.9,2.9c-0.1,1.1-0.1,2.1,0.2,3.3c0.3,1.1,0.7,2.1,1.4,3c0.6,0.9,1.4,1.6,2.3,2.1
                     c0.9,0.5,1.8,0.9,2.9,1c1,0.2,2.1,0.1,3.2-0.2c1.3-0.3,2.4-0.9,3.3-1.6c0.9-0.8,1.6-1.7,2.1-2.8l-3.3,0.8l-1.9-7.8l14.3-3.5
                     l3,12.4c-0.5,1.7-1.2,3.4-2.1,4.9c-0.9,1.5-2,2.9-3.2,4.2c-1.3,1.2-2.7,2.3-4.3,3.2c-1.6,0.9-3.3,1.6-5.1,2
                     c-2.7,0.6-5.3,0.8-8,0.3c-2.6-0.4-5-1.3-7.2-2.6c-2.2-1.3-4.1-3-5.7-5.2c-1.6-2.1-2.7-4.6-3.4-7.3c-0.6-2.7-0.8-5.3-0.4-7.9
                     c0.4-2.6,1.2-5,2.5-7.2c1.3-2.2,2.9-4.1,5-5.6c2.1-1.6,4.5-2.7,7.1-3.4c2.1-0.5,3.9-0.7,5.6-0.7c1.6,0,3,0.2,4.2,0.5
                     c1.4,0.3,2.6,0.7,3.5,1.2L94.2,439.1z"/>
                  <path class="st0" d="M110,441.6c-0.6-2.7-0.8-5.3-0.3-8s1.3-5,2.7-7.2c1.3-2.2,3.1-4.1,5.2-5.7c2.1-1.6,4.5-2.7,7.2-3.4
                     c2.7-0.6,5.3-0.7,8-0.3c2.6,0.4,5,1.3,7.2,2.7c2.2,1.3,4.1,3.1,5.7,5.2c1.6,2.1,2.7,4.5,3.4,7.2c0.6,2.7,0.8,5.3,0.3,8
                     c-0.4,2.6-1.3,5-2.7,7.2c-1.3,2.2-3.1,4.1-5.2,5.7c-2.1,1.6-4.5,2.7-7.2,3.4c-2.7,0.6-5.3,0.8-8,0.3c-2.6-0.4-5-1.3-7.2-2.7
                     c-2.2-1.3-4.1-3.1-5.7-5.2C111.7,446.7,110.6,444.3,110,441.6z M121.7,438.8c0.3,1.1,0.7,2,1.4,2.9c0.6,0.8,1.4,1.5,2.3,2.1
                     c0.9,0.5,1.8,0.9,2.9,1.1c1,0.2,2.1,0.1,3.2-0.1c1.1-0.3,2-0.7,2.9-1.3c0.8-0.6,1.5-1.4,2.1-2.3c0.5-0.9,0.9-1.8,1.1-2.9
                     c0.2-1,0.1-2.1-0.1-3.2c-0.3-1.1-0.7-2-1.4-2.9c-0.6-0.8-1.4-1.5-2.3-2.1c-0.9-0.5-1.8-0.9-2.9-1.1c-1-0.2-2.1-0.1-3.2,0.1
                     c-1.1,0.3-2,0.7-2.9,1.4c-0.8,0.6-1.5,1.4-2.1,2.3c-0.5,0.9-0.9,1.8-1.1,2.9C121.4,436.6,121.4,437.7,121.7,438.8z"/>
               </g>
               <g>
                  <path class="st0" d="M183.2,427.7l7-1.7l18.2,26.6l-9.2,2.2l-1.6-2.4l-8.9,2.1l-0.3,2.9l-9.2,2.2L183.2,427.7z M193.6,446.6
                     l-3.2-4.7l-0.7,5.6L193.6,446.6z"/>
                  <path class="st0" d="M202,423.2l9.7-2.4l5.2,21.6l8.1-2l1.9,7.7l-17.8,4.3L202,423.2z"/>
                  <path class="st0" d="M222,418.3l10-2.4l3.5,21l-7,1.7L222,418.3z M227.6,440.2l9.4-2.3l1.8,7.3l-9.4,2.3L227.6,440.2z"/>
               </g>
            </g>
            <g id="GREEN-FLAG-LEFT">
               <g>
                  <path class="st1" d="M326.5,504.6c-11.2,2.6-22.4,1.7-33.4-1.2c-9.2-2.4-18.3-4.1-27.6-3.1c-6.6,0.8-13.1,2.8-19.7,4.3
                     c-0.1,0.3-0.2,0.7-0.3,1c0.3,0.2,0.6,0.4,1,0.6c3.5,2.2,5.7,5.7,7.1,10c1.1,3.5,2.2,7,3,10.6c1.6,7.2,3.2,14.6,0.7,22
                     c-0.1,0.3-0.1,1,0.1,1.1c0.2,0.2,0.7,0.1,1,0c0.6-0.3,1.1-0.7,1.6-1c5-3.1,9.8-6.4,14.9-9.2c5.4-3,11.1-4.7,17.1-5.3
                     c5-0.5,9.9-0.9,14.7-3.1c9.9-4.5,16.4-13.3,21.7-23.8c0.5-1,0.6-2.3,0.9-3.4C328.4,504.4,327.4,504.4,326.5,504.6z"/>
               </g>
               <path d="M281,765.1c-0.4,0.3-0.8,0.7-1.3,1.1c-0.4-0.7-22-161.2-22-161.9c-0.4-6.1-0.8-12.3-1.1-18.4c-1.3-26-5.6-51.3-12.1-76.1
                  c-0.6-2.2-1.4-4.3-1.9-6.5c-0.2-0.8,0.4-1.8,0.7-2.8c0.6,0.6,1.4,1.1,1.6,1.8c2.4,8.4,4.9,16.8,7,25.4c5.8,24.4,7.7,49.5,8.4,74.7
                  C260.2,603.3,281,763.9,281,765.1z"/>
            </g>
            <g id="BLACK-FLAG-LEFT">
               <g>
                  <path d="M215.8,583c-6.1,1.5-12.2,1-18.2-0.7c-5-1.3-10-2.3-15.1-1.7c-3.6,0.4-7.2,1.6-10.7,2.4c-0.1,0.2-0.1,0.4-0.2,0.6
                     c0.2,0.1,0.4,0.2,0.5,0.3c1.9,1.2,3.1,3.3,3.9,5.7c0.6,2,1.2,4,1.6,6c0.9,4.1,1.7,8.3,0.4,12.5c-0.1,0.2,0,0.6,0.1,0.6
                     c0.1,0.1,0.4,0.1,0.5,0c0.3-0.2,0.6-0.4,0.9-0.6c2.7-1.7,5.4-3.6,8.1-5.2c2.9-1.7,6.1-2.6,9.3-3c2.7-0.3,5.4-0.5,8-1.7
                     c5.4-2.5,9-7.6,11.8-13.5c0.3-0.6,0.3-1.3,0.5-1.9C216.8,582.9,216.3,582.9,215.8,583z"/>
               </g>
               <path d="M190.9,730.8c-0.2,0.2-0.5,0.4-0.7,0.6c-0.2-0.4-12-91.5-12-91.9c-0.2-3.5-0.4-7-0.6-10.5c-0.7-14.7-3-29.1-6.6-43.2
                  c-0.3-1.2-0.8-2.4-1-3.7c-0.1-0.5,0.2-1,0.4-1.6c0.3,0.3,0.8,0.6,0.9,1c1.3,4.8,2.7,9.6,3.8,14.4c3.2,13.9,4.2,28.1,4.6,42.4
                  C179.6,639,190.9,730.1,190.9,730.8z"/>
            </g>
            <g id="STAR-FLAG-RIGHT">
               <path d="M730.1,699.3c-0.4,0.2-1,0.7-1.6,1.1c-0.1-0.8-0.5-1.7-0.3-2.5c2.1-10.7,33.8-161.9,35.4-172.7
                  c1.8-11.3,2.7-22.8,4.1-34.1c0.1-0.8,0.8-1.6,1.2-2.3c0.4,0.8,1.1,1.6,1.1,2.4c0.1,12.9-1.7,25.7-4.3,38.3
                  c-1.7,8.1-3.4,16.3-5.2,24.4C760.3,555.4,730.6,697.5,730.1,699.3z"/>
               <path class="st0" d="M840.3,556.3c-0.7-2.1-1.4-4.3-2.2-6.4c-2.3-5.7-4.8-11.4-7.2-17c-1-2.4-0.5-4.2,1.9-5.3
                  c4.4-2.1,8.7-4.2,13-6.3c1.6-0.8,3.3-1.4,5-2.1c-9.7-11.4-67.3-30-78.4-25.4c9,15.8,3.7,29.9-5.7,43.8c27.2-1.2,51.2,7,73.9,21.8
                  C840.5,557.8,840.5,557,840.3,556.3z"/>
               <polygon points="813.3,517.7 822.4,522.2 813.3,526.7 811.8,536.8 804.7,529.5 794.6,531.2 799.4,522.2 794.6,513.2 804.7,514.9 
                  811.8,507.6 		"/>
            </g>
            <g id="GREEN-FLAG-RIGHT">
               <g>
                  <path class="st1" d="M886.3,602.5c-5.2-1.2-9.6-3.8-13.6-7.2c-3.3-2.8-6.8-5.4-10.9-6.8c-2.9-1-6-1.5-9-2.2
                     c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4c1,1.6,1.2,3.5,0.9,5.6c-0.2,1.7-0.5,3.4-0.9,5c-0.8,3.3-1.6,6.7-4.1,9.3
                     c-0.1,0.1-0.2,0.4-0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2c0.3,0,0.6-0.1,0.9-0.1c2.7-0.3,5.4-0.7,8-0.8c2.8-0.1,5.6,0.3,8.1,1.3
                     c2.2,0.8,4.3,1.6,6.7,1.7c5,0.1,9.5-2.2,13.8-5.5c0.4-0.3,0.7-0.8,1.1-1.2C887.1,602.8,886.8,602.6,886.3,602.5z"/>
               </g>
               <path d="M815,701.5c-0.2,0.1-0.5,0.1-0.8,0.2c0-0.4,23.3-71.3,23.5-71.6c1.1-2.6,2.1-5.3,3.2-7.9c4.7-11,8-22.4,10.3-34
                  c0.2-1,0.3-2.1,0.5-3.1c0.1-0.4,0.5-0.7,0.8-1c0.1,0.4,0.4,0.7,0.3,1.1c-0.7,4-1.4,8-2.2,11.9c-2.5,11.3-6.8,22.1-11.5,32.7
                  C838.9,630.1,815.3,701,815,701.5z"/>
            </g>
            <g id="TRI-COLOR-FLAG">
               <path d="M779,425.1c2-7.7,4.1-15.4,6.3-23.1c2.1-7.2,4.5-14.3,6.5-21.4c1-3.5-0.2-4.8-3.8-4.8c-17.9-0.3-35.5,1.7-53.1,4.6
                  c-2.8,0.5-4,2-4.7,4.4c-4.3,15.7-8.7,31.4-13.1,47c-0.6,2-1.4,4-2.3,6.7c1.9-0.2,3-0.3,4-0.5c8.4-1.7,16.8-3.9,25.3-5.1
                  c9.2-1.4,18.6-2.4,27.9-2.8C777.7,429.9,777.7,430.4,779,425.1z"/>
               <path class="st1" d="M834.3,435.2c1.2-2.4,1.9-5.1,2.7-7.6c3.6-11.6,7.1-23.2,10.9-34.8c2.1-6.3,1.7-7.1-5.4-7.6
                  c-15-1.1-29.9-0.2-44.7,1.7c-2.6,0.3-4.2,1.4-4.9,4.2c-2.7,10.1-5.4,20.3-8.6,30.2c-2.1,6.5-1.7,14.3-10.1,18
                  c0.2,0.2,0.4,0.4,0.6,0.7c6.5-0.7,13.1-1.4,19.5-2.2c12.3-1.6,24.5-3.6,36.9-0.7C832,437.2,833.9,436.1,834.3,435.2z M826.2,394.3
                  c0,0-0.1-0.1-0.1-0.1C826.2,394.2,826.2,394.2,826.2,394.3C826.3,394.2,826.3,394.3,826.2,394.3z"/>
               <path class="st0" d="M901.6,402c1.5-1.9,3.1-3.8,4.8-5.9c-0.9-0.3-1.3-0.6-1.7-0.6c-1.4-0.1-2.9-0.3-4.3-0.3
                  c-15,0.5-30,1-45.1,1.5c-4.6,0.2-5.5,1-6.6,5c-2.8,9.6-5.5,19.1-8.4,28.7c-1.8,5.9-3.5,11.8-10.3,14.6c-0.1,0-0.1,0.3-0.4,1.4
                  c23.3-0.5,46.4-0.9,69.9-1.4c-0.6-1.4-1-2.2-1.4-3c-3.1-5.8-6.1-11.7-9.3-17.5c-1.4-2.5-1.4-4.6,0.5-6.9
                  C893.4,412.5,897.5,407.2,901.6,402z"/>
               <path d="M726.9,384.7c-0.1,0-0.2,0-0.2-0.1c0.2-0.8,0.6-1.7,0.5-2.4c-0.3-1.1-0.9-2.2-1.4-3.3c-0.7,0.9-1.8,1.6-2.1,2.6
                  c-1.2,4-2.1,8-3.3,12c-4.4,14.9-8.8,29.8-13.5,44.7c-2.2,6.9-70.3,230.7-72.7,237.6c-0.3,0.8,0.4,2,0.6,3c1-0.6,2.3-0.9,2.9-1.7
                  c0.9-1.4,1.4-3,1.9-4.6c4.8-13.9,75-245,79.4-259C722,404,724.3,394.3,726.9,384.7z"/>
               <path d="M764.9,440.5c3.8-2.3,7.1-4.2,11-6.6C764.9,432.1,764.4,433.1,764.9,440.5z"/>
               <path class="st1" d="M820.4,445.7c3.5-1.7,6.8-3.2,10.2-4.8C821.8,438,819.9,439,820.4,445.7z"/>
            </g>
         </g>
         <path class="st2" d="M350,444.3c0,0,5.2,150.8,57.4,250s64.5,119,64.5,119l81-119.9c0,0,45.9-164.2,47.3-249L350,444.3L350,444.3z"
            />
         <path class="st3" d="M429.8,471.2c0,0,0.2,6.4,1.1,17.2c13.9-1,28.1,0.8,41.3,4.7c7.5,2.2,14.9,5.1,21.3,9.2
            c16.6,10.7-48.3,26.9-43.1,44.6c2,7,1.2,13.1-0.1,20.1c-1.8,10.4-1.2,21.2-0.7,31.7c0.7,13.3,2.1,26.6,4.3,39.7
            c-17.6-14.9-35.6-30.4-45.1-50.6c-5-10.7-7.3-22.2-9.6-33.6c-11.8,15.8-13.5,36.6-9.4,55.4c4.1,18.8,13.6,36.2,23,53.3
            c10.1,18.4,20.2,36.9,30.3,55.3c11,20.1,22.3,41,23.2,63.5c0,1,0.1,2.1,0,3.2c1.8,2.8,2.5,3.9,2.5,3.9l75.7-104.1
            c0,0,42.9-142.6,44.2-216.2L429.8,471.2z"/>
         <g id="FACE_5_">
            <path class="st2" d="M311.2,290.2c-1.7,0-26.4-23.4-21,21.7s6.8,55.5,10.1,76.5c3.4,20.9,26.1,25.4,30.8,25.6
               C335.8,414.2,313,290.2,311.2,290.2z"/>
            <path class="st2" d="M636.7,285c0,0,21.8-24.8,29.9,0c8.1,24.8,10.8,34.4-7.2,106.4c0,0-7.3,30.1-32.1,23.2
               C621.9,401,631.3,336,636.7,285z"/>
            <path class="st2" d="M326.6,381.3c0,0,7.2,75.8,18,85.7c10.8,9.9,71.3,85.7,89.3,88.4s41,0,41,0s36.5,12.6,67.2-12.6
               c30.7-25.3,92.9-83,85.7-141.6l6.3-64l2.7-58.6c0,0,20.7-172.3-165.1-169.6S310.3,274.8,310.3,274.8S310.3,366.8,326.6,381.3z"/>
            <path class="st3" d="M325.2,375.8c0,0,7.2,75.8,18,85.7c10.8,9.9,71.3,85.7,89.3,88.4s41,0,41,0s36.5,12.6,67.2-12.6
               c30.7-25.3,92.9-83,85.7-141.6l6.3-64l2.7-58.6c0,0,20.7-172.3-165.1-169.6s-161.5,166-161.5,166S309,361.4,325.2,375.8z"/>
            <path class="st3" d="M308.5,284.7c-1.7,0-26.7-23.1-20.7,21.9s7.5,55.4,11.1,76.3s26.5,25,31.1,25.2
               C334.7,408.3,310.2,284.7,308.5,284.7z"/>
            <path class="st3" d="M635.3,288.5c0,0,21.8-23.3,29.9,0c8.1,23.3,10.8,32.3-7.2,100c0,0-7.3,28.3-32.1,21.8
               C620.5,397.5,629.9,336.4,635.3,288.5z"/>
            <path class="st4" d="M420.4,318.6c8.5,0,16.7,0.4,24.4,1c-3.3-7.8-14-13.5-26.6-13.5c-12.9,0-23.7,5.9-26.8,13.9
               C400.4,319.1,410.2,318.6,420.4,318.6z"/>
            <path class="st4" d="M537,318.6c-8.5,0-16.7,0.4-24.4,1c3.3-7.8,14-13.5,26.6-13.5c12.9,0,23.7,5.9,26.8,13.9
               C557,319.1,547.2,318.6,537,318.6z"/>
            <path class="st2" d="M298.1,360.8c0.8-5.9-0.5-11.9-0.5-17.9s1.6-12.6,6.7-15.8c0.8,6.2-0.1,12.4-0.2,18.6s0.8,12.8,4.6,17.7
               c2.6,3.5,6.8,6.5,6.7,10.8c-0.1,4.1-4.1,7.6-3.3,11.6c0.2-3.3,2.1-6.4,4.9-8.2c1.3-0.8,2.9-1.4,3.6-2.8c0.4-0.8,0.4-1.8,0.4-2.7
               c-0.1-19.7-0.2-39.3-0.3-59c0-3.9,0-7.8-1-11.5c-0.4-1.5-0.9-2.9-1.7-4.3c-2.2-3.7-6.7-6-11-5.6c-3.9,0.4-7.7,2.9-9.4,6.5
               c-0.8,1.7-0.6,4-0.7,5.8c-0.2,2.6-0.4,5.2-0.5,7.8c-0.5,10.5-0.4,20.9,0.2,31.4C296.8,349.1,297.4,354.9,298.1,360.8z"/>
            <path class="st2" d="M656.7,360.7c-0.6-5.9,0.9-11.9,1.2-17.9s-1.2-12.6-6.1-16.1c-1,6.1-0.4,12.4-0.5,18.6
               c-0.2,6.2-1.3,12.7-5.2,17.5c-2.8,3.4-7,6.2-7.1,10.6c-0.1,4.1,3.8,7.7,2.9,11.7c-0.1-3.3-1.8-6.5-4.6-8.4c-1.3-0.9-2.8-1.5-3.5-3
               c-0.4-0.8-0.3-1.8-0.3-2.7c0.9-19.7,1.7-39.3,2.6-59c0.2-3.9,0.3-7.8,1.4-11.5c0.4-1.5,1-2.9,1.8-4.2c2.4-3.6,6.9-5.7,11.2-5.2
               c3.8,0.5,7.6,3.2,9.1,6.8c0.7,1.7,0.4,4,0.5,5.8c0.1,2.6,0.2,5.2,0.2,7.8c0.1,10.5-0.4,20.9-1.4,31.4
               C658.5,349.1,657.7,354.9,656.7,360.7z"/>
            <path class="st4" d="M652.6,312.3c3-2,5.1-5.3,5.6-8.9c-3.4,1.9-7.8-0.9-9.1-4.6c-1.2-3.7-0.3-7.7,0.5-11.5s1.5-7.9-0.3-11.4
               c1.3,0.4,2.5,0.7,3.8,1.1c0.1-2.8,0.1-5.5,0.2-8.3c2.1,0.7,4.3,1.4,6.4,2.1c-0.6-21.4-6-42.6-15.8-61.7c5.8,2.6,13.4-2.5,13.1-8.8
               c-2.4,2.8-7.4,1.4-9.3-1.7c-1.9-3.1-1.7-7.1-1.4-10.8c0.6-7.8,1.2-15.6,1.8-23.5c-5.2,4.3-12.3,6.3-18.9,5.3
               c1.3-15.8-5.7-32-18.1-41.8c-3.5-2.8-7.7-5.1-12-6c-0.2-0.6-0.4-1.2-0.6-1.8c-1-3.2-2.2-6.4-4.5-8.8c-3-3-7.5-3.9-11.5-5.4
               c-9.9-3.6-17.8-11.1-26.6-16.8c-18.3-11.9-40.5-16.2-62.1-19.6c-1.5-0.2-3.2-0.6-4.1-1.9c-0.6-1-0.6-2.2-0.6-3.4
               c0.2-6.2,0.5-12.3,0.7-18.5c-4.2,8-14.1,11-23.1,12.4s-18.7,2.3-25.5,8.3c0.9-1.7,0.7-3.9-0.4-5.5c-6.2,12.3-17.2,22-30.1,26.7
               c0.4-3.6-1.7-7.4-4.9-9c3,9.7-6.5,18.4-15.3,23.5c-7.5,4.3-16.2,9.7-18.5,17.4c-13.7,0.2-28.4,1.1-39.1,9.6
               c-8.5,6.7-13,17.1-19,26.2c-4.2,6.4-9.3,12.5-11.8,19.7c-5,14.4,1,30.6-2.7,45.5c-1.9,7.6-6.2,14.5-8.1,22.1s-0.4,17.1,6.4,20.9
               c0-1.8-0.1-3.6-0.1-5.4c5.6,2.3,5.8,10.9,1.6,15.2c-4.2,4.3-10.9,5.1-16.9,4.8c1,2.3,2,4.6,3,6.9c-2.9,0.2-5.7,0.4-8.6,0.6
               c9.5,9.9,23.9,14.9,37.5,13c-0.3,6.8-5.5,13-12.2,14.5c2.3,0.8,3.4,3.6,3,6s-1.9,4.5-3.3,6.4c3.3,0.2,6.5,0.4,9.8,0.6
               c-1.2,6.9-2.3,14.2-0.4,20.9c2,6.8,8,12.9,15,12.5c1.1-17.9,2.3-35.9,3.8-53.8c0.9-11.3,1.9-22.7,6.2-33.2
               c3.3-8.1,8.6-15.4,11.7-23.6c4.1-10.9,4.2-23,8.1-34c3.4-9.6,9.6-18,13.6-27.4c3.5-8.1,5.2-16.9,8.2-25.1c1.2-3.3,2.7-6.6,4.2-9.8
               c1.4-2.9,3-5.8,4.6-8.7c1.3-2.4,3.1-4.7,4.1-7.3c5.3,0.1,10.6-0.9,15.8-1.3c6.1-0.5,12.2-0.8,18.3-0.6c6.9,0.2,13.7,1.1,20.4,2.9
               c7.4,2,15,5.3,22.4,3.3c1.4,7.3,8.8,11.8,15.9,14.2s14.8,4,20.1,9.3c5.2,5.3,5.5,16.1-1.4,18.8c6,0.4,11.9-4.6,12.4-10.6
               c12.6-1.4,25.9-2.7,37.4,2.6s19.7,19.9,13.6,31c5.4-1.8,10.8-3.6,15.3-7.1c2.2-1.7,4.2-3.9,5.5-6.4c0.5,0.1,1,0.2,1.6,0.3
               c2.9,0.6,5.4,2.6,7.4,4.9c2.4,2.8,4,6.2,4.8,9.7c1.7,7.4,0.7,15.2,1.3,22.8c2.2,27.5,25,49.4,30,76.5c0.9,5.2,1.2,10.4,1.3,15.7
               c0.1,13.8-3,29.9-15.3,36.2c16.4-4.7,29.1-20.4,30-37.5c4.5,0.7,9.2,1.3,13.5,0.1c4.4-1.2,8.4-4.9,8.5-9.5
               C663.9,312.8,658.2,312.9,652.6,312.3z"/>
            <path class="st2" d="M430.6,283.5c5.2,1.8,9.6,5.4,13.8,9.1c0.7,0.6,1.3,1.2,1.9,1.9c3.3,4,2.9,9.9,2.4,15.2
               c-0.6,5.8-1.3,11.9-4.5,16.7c-3.2,4.8-8.3,7.5-12.2,11.6c6.9-1.4,13.3-5.4,17.9-11.1c3.7,4.9,5.4,11.3,5.9,17.5s-0.3,12.6-1,18.9
               c5.9-7.2,9.8-16.2,11.2-25.6c0.6-4.4,0.7-8.8,0.8-13.3c0.1-6.2,0.2-12.4-0.8-18.5c-0.6-3.6-1.7-7.2-2.8-10.7
               c-0.7-2.4-1.5-5-3.1-6.9c-2-2.5-5.1-3.7-8.1-4.8c-8.6-3.1-17.4-5.7-26.5-6.5c-4.4-0.4-8.8-0.4-13.1,0.2c-4.3,0.6-8.7,1.7-12.7,3.6
               c-0.8,0.4-1.4,0.8-0.3,1.1c1.3,0.4,2.7,0.3,4,0.3c4.1,0.1,8.2-0.1,12.3-0.3c4.5-0.2,9-0.1,13.4,1.1
               C429.5,283.2,430.1,283.3,430.6,283.5z"/>
            <path class="st5 left-eyebrow" d="M522.6,285.9c13.4,0.7,26.9,1,40.3,1c3.7,0,7.3,0,10.9,0.5c3.1,0.5,6,1.4,9,2.2
               c5,1.5,10.1,3,15.1,4.5c-4.4-1.3-7.7-5-11.7-7.2c-3.9-2.1-8.5-2.7-12.8-3.7c-10-2.2-19.5-6.2-29.3-9c-7.8-2.3-16.5-4.2-24.7-3.3
               c-2.3,0.3-4.5,1-6.1,2.7c-2,2.1-3.1,5.5-3.5,8.3c-0.3,2.4,3.6,2.8,5.3,3.2C517.6,285.7,520.1,285.8,522.6,285.9z"/>
            <path class="st5" d="M418.4,282c-21.8-0.8-43.1,10.6-64.8,8.1c6.3-1.2,12.5-2.8,18.6-4.7c11.3-3.5,22.3-8.1,33.7-11.3
               c11.4-3.3,23.4-5.2,35.1-3.3c2.8,0.5,5.7,1.2,7.9,3c1.9,1.6,3.3,4.2,2.8,6.7c-0.4,2.1-2.1,3.9-4.3,3.9c-5.9-0.1-11.8-0.4-17.7-1.2
               C426,282.7,422.2,282.2,418.4,282z"/>
            <path class="st2" d="M374.4,428.3c0.2,6.8,0.2,13.6,0.2,20.5c0.1-5.8-1.3-11.6-3.9-16.7c-0.6-1.1-1.2-2.2-2.1-2.9
               c-0.5-0.4-1.2-0.7-1.6-1.3c-0.6-0.7-0.8-1.7-1-2.6c-0.8-3.9-1.5-7.9-2.3-11.8c-0.3-1.6-1-3.5-0.9-5.2c0-0.8,0.4-1.4,1.1-1.7
               c1.7-0.7,4,0.4,5.3,1.3c2.4,1.7,3.3,4.6,4,7.4C374.2,419.5,374.3,423.9,374.4,428.3z"/>
            <path class="st2" d="M585.1,435.3c2.1-5.6,4.3-11.2,6.4-16.8c1.1-2.9,2.3-6.2,1.3-9.1c-0.7-2-2.5-3.9-4.7-4c-2.1-0.1-3.1,1-3.1,2.9
               c0,5.6,0.3,11.3,0.3,16.9C585.2,428.5,585.2,431.9,585.1,435.3z"/>
            <path class="st2" d="M393.3,507.6c-3.4-9.2-1-20.1-5.4-28.9c5.1,16,13.1,31.2,23.5,44.5c7.1,9.1,15.4,17.6,19.7,28.3
               c-8.2-8-21-11.2-27.4-20.7c-0.9-1.4-1.5-3.4-2.8-4.3c-1.3-0.8-1.8-1.3-3.7-3.3c-0.9-0.9-0.9-1.8-0.9-2.9c0-1.8,0.4-4.5-0.3-6.2
               C395.2,511.9,394.1,509.8,393.3,507.6z"/>
            <path class="st2" d="M512,395.2c-11.3,6.3-23.3,12.4-36.3,13.2c-3.6,0.2-7.2,0-10.6-1.2c-2.6-0.9-4.9-2.4-7.2-3.8
               c-2.3-1.4-4.6-2.9-6.9-4.3c-1.3-0.8-2.2-1.7-0.5-2.6c1.5-0.8,3.3-1,4.9-0.7c2.6,0.4,5.1,0.8,7.7,1.2c10.4,1.3,21,1.6,31.5,0.7
               c3-0.2,5.9-0.6,8.8-1c1.4-0.2,2.9-0.4,4.3-0.7c0.7-0.1,1.5-0.3,2.2-0.4C510.6,395.5,511.5,395.5,512,395.2z"/>
            <path class="st2" d="M533.8,397.4c3.9,3.5,8.9,5.4,13.5,7.7c6.7,3.4,13.4,8.6,15,16c1.9,8.7-3.9,17.3-10.5,23.4
               c6.9-5.5,12.1-13.5,12.9-22.3c0.7-8.8-3.6-18.2-11.5-22.1c-5.3-2.6-11.7-2.8-16.4-6.4c-5.5-4.2-6.8-12.9-2.8-18.6
               c-0.4,0.6-1.6,1.1-2.2,1.6c-0.7,0.7-1.3,1.4-1.9,2.2c-0.8,1.2-1.7,2.6-1.8,4.1c-0.1,1.3,0.2,2.5,0.4,3.8c0.4,1.9,0.6,3.7,1.4,5.4
               C530.9,394.2,532.2,396,533.8,397.4z"/>
            <path class="st2" d="M413.7,403.5c-2.5,1.2-5.1,2-7.6,3.2c-9.7,4.6-15.9,15.6-14.9,26.2c-0.2-6.4-0.4-12.9-0.6-19.3
               c-0.1-2.7-0.1-5.7,1.4-8c2.2-3.5,6.9-4.2,10.9-5.1c5.2-1.2,10.4-3.4,14.4-7c4.1-3.7,6.5-8.6,6.9-14c1,1.9,1.5,4.1,2,6.2
               c0.3,1.4,0.4,2.7,0,4c-1.1,3.8-3.8,7.3-6.6,9.9C417.8,401.1,415.8,402.4,413.7,403.5z"/>
            <path class="st2" d="M459.2,488c1.2-0.7,2.3-1.4,3.5-2.1c2-1.2,4-2.3,6.2-2.9c1.9-0.5,3.8-0.5,5.8-0.6c3.1,0,6.4-0.1,9.3,1.1
               c1.6,0.7,5.1,2.6,3.7,4.9c-0.6,1.1-1.9,0.4-2.8,0.2c-1.6-0.4-3.2-0.7-4.8-1c-3.3-0.5-6.6-0.5-9.9-0.4c-1.8,0.1-3.7,0.2-5.5,0.3
               c-0.9,0.1-1.9,0.1-2.8,0.2C461.2,487.8,459.8,487.7,459.2,488z"/>
            <path class="st2" d="M399.7,427.3c0,0,29.9,14.8,72.1,13s63.2-3,78.1-13c0,0,1.7,14-78.4,17.1C471.4,444.4,409,440,399.7,427.3z"/>
            <path class="st6" d="M399.6,415.1"/>
            <path class="st4" d="M399.7,427.3c0,0,73.9,31.6,150.1,0c0,0-15.3,44-75.1,42.7S399.7,427.3,399.7,427.3z"/>
            <g>
               <path class="st0" d="M399.3,427.3c0,0,0.3,0.9,1.1,2.4c2.7,4.1,7.2,7.4,10.9,10.2c11,8.1,24.2,13.3,37.6,15.6
                  c1.7,0.3,3.4,0.5,5.2,0.7c23.4,2.7,47-2.4,69.3-10c7.1-2.4,14.4-5.3,21-9c3.7-5.7,5.1-9.8,5.1-9.8
                  C473.2,459,399.3,427.3,399.3,427.3z"/>
            </g>
            <path class="st7" d="M439.2,232.3c10.4-3.1,19.9-10.7,23.3-21.1c4.4-13.8-3.1-29.2-14.4-38.2s-25.7-13.1-39.7-16.7
               c-1.8-0.5-3.6-0.9-5.5-0.6c-2.9,0.5-5.1,2.9-6.8,5.2c-9.3,13-9.1,29.4-3.5,43.8c1.9,4.9,3.2,10.4,6,14.9c3.2,4.9,8.8,8.7,14,11.1
               C420.9,234.6,430.6,234.8,439.2,232.3z"/>
            <path class="st7" d="M463.6,363.9c-1.9,2.7-3.2,5.8-4.4,8.8c-0.8,1.8-1.5,3.8-0.8,5.6c0.5,1.3,1.8,2.3,3,3
               c4.2,2.6,8.8,4.4,13.6,5.4c4,0.8,9,0.6,11.3-2.8c0.6-1,1-2.1,1.3-3.3c0.9-3.4,1.8-6.8,2.6-10.3c0.5-2.1,0.9-4.2,0.4-6.3
               c-0.4-1.7-1.5-3.1-2.5-4.5c-3.1-4.1-6.5-8.5-11.4-10.7c-0.8-0.4-2.2-1.1-3-0.6c-0.7,0.4-0.8,1.3-0.9,1.9c-0.5,2.5-1.5,4.3-3,6.4
               C467.6,359,465.4,361.3,463.6,363.9z"/>
            <path class="st7" d="M382.3,331.5c-7.3,2.5-13.3,8.6-19.2,14.5c-3.7,3.7-7.5,7.7-9.2,13.1c-0.3,0.8-0.5,1.8-0.2,2.6
               c0.3,0.8,1,1.3,1.6,1.7c7.4,4.5,16,4.5,24.3,4.3c9.9-0.2,20-0.4,29.2-4.8c4.5-2.1,8.9-5.6,10.8-11c0.8-2.4,1.1-5.1,1.2-7.8
               c0.1-4.2-0.4-8.2-3.1-11.1c-1.6-1.8-3.4-3.2-5.7-3.3c-2.2-0.1-4.3,0.5-6.5,0.6c-3.6,0.1-7.2-0.4-10.8-0.4
               C390.6,329.8,386.4,330.1,382.3,331.5z"/>
            <path class="st7" d="M585.8,332.4c-6.4-1.2-12.9-2-19.4-2.5c-6-0.5-12.2-0.6-18,0.9s-11.5,5-14.9,10.4c-0.6,1-1.1,2.1-1.1,3.3
               c0.1,1.4,1,2.6,2,3.5c3,2.6,6.8,3.5,10.3,5c8.7,3.6,15.9,10.3,23.9,15.4s17.8,8.7,26.5,5.2c7.3-2.9,12.6-11,12.8-19.4
               c0.2-8.5-4.7-16.8-11.8-20.2c-4.9-2.3-10.5-2.4-15.8-1.8"/>
            <path class="st7" d="M446.8,511.7c-3.5,2.4-6.7,6.5-5.4,10.5c1.3,4.2,6.6,5.8,11.1,5.5c4.9-0.3,9.8-2.1,13.8-5.1
               c2.9,4.3,7.2,8,12.2,9.1c5.1,1.1,11-0.7,13.7-5.1c2.7-4.4,1.7-10.5-1.5-14.6c-3.2-4.1-8.2-6.4-13.3-7.5c-1.6-0.3-3.2-0.5-4.7,0.1
               c-1.7,0.7-2.9,2.2-4.4,3.1c-1.6,1-2.2,0.3-3.8-0.1c-3.7-0.9-8.3,0-11.8,1.2C450.6,509.5,448.6,510.5,446.8,511.7z"/>
            <path class="st2" d="M555.5,509.2c3.4-9.7,1-21.1,5.4-30.4c-5.1,16.9-13.1,32.8-23.5,46.8c-7.1,9.6-15.4,18.5-19.7,29.8
               c8.2-8.4,20.1-12.7,26.5-22.8c0.9-1.5,1.8-3.1,2.8-4.5c1.1-1.5,2.3-2.3,3.7-3.4c1-0.8,0.9-1.9,0.9-3.1c0-1.9,0.5-3.8,1.2-5.6
               C553.6,513.7,554.7,511.5,555.5,509.2z"/>
            <path class="st2" d="M628.4,411.2v-39.6c0,0-9.5,79.3-15.4,87.3C613,458.9,629.8,426.8,628.4,411.2z"/>
            <polygon class="st2" points="344.6,466.9 325.2,375.8 337.6,455.1 	"/>
            <g>
               <path class="st5" d="M329.4,273.7c-3.6,2.4-7.1,4.9-10.5,7.6C322.9,279.6,326.5,277,329.4,273.7z"/>
               <path class="st5" d="M376.3,135.2c-0.6,0-1.2,0-1.8,0c0,0.4,0.1,0.9,0.4,1.2C375.6,137.5,377.6,135.3,376.3,135.2z"/>
               <path class="st5" d="M380.1,115c-2.2,0.1-4.3,0.3-6.5,0.4c-0.6,1-1,2-1.4,3.1c-4.7,0.1-9.5,0.2-14.2,0.7c1.9,0.4,3.8,0.7,5.8,0.8
                  c-14.7,7-29.1,14.8-43,23.3c-1.4,2.3-2.8,4.7-4.2,7c2.9,0.8,5.7,2.2,8.1,4.1c-6,2.8-11.8,6.1-17.3,9.8c-1.4,2.2-2.7,4.4-3.8,6.7
                  c1.6,0.4,3.2,0.7,4.9,1.1c-2.4,1.9-4.8,3.9-7.2,5.8c-0.6,2.8-0.9,5.7-1,8.6c3.7,0.5,7.4,0.9,11.1,1.4c-2.8,5.9-6.4,11.4-10.5,16.4
                  c0.1,1.8,0.1,3.6,0,5.4c1.3,0.2,2.7,0.5,4,0.7c0.5,7-3.2,12.9-8.4,17.9c-0.6,1.5-1.3,3.1-2,4.6c4.3-1.6,8.7-2.9,13.2-3.9
                  c-1.4,3.7-2.8,7.5-4.2,11.2c3.9-2.9,7.8-5.7,11.6-8.6c-7.7,7.9-15.4,15.8-23.2,23.7c0,0,0,0.1,0.1,0.1c3.3-2.9,6.9-5.5,10.8-7.5
                  c0.3,5.4,0.4,11-0.6,16.2c0.2,2.9-0.8,6.1-2.9,8.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.5-0.6,1-1,1.5c-3.2,4.4-7.9,7.2-12.8,10
                  c0,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.3,0-0.4,0c-1.8,1-3.7,2-5.5,3.1c2.1,1.8,4.3,3.4,6.7,4.8c0.5-0.1,0.9-0.1,1.4-0.1v-0.1
                  c0.1-0.8,1-1.2,1.8-1.3c4.5-0.8,8.9-1.4,13.5-1.8c-0.9,2.8-1.4,5.8-1.5,8.7c4.3,0.7,8.7,0.8,13,0.2c-0.3,6.3-4.8,12.2-10.8,14.1
                  c-0.1,0.3-0.2,0.6-0.3,1c1.6,1.2,2.3,3.5,1.9,5.5c-0.4,2.4-1.9,4.5-3.3,6.4c1.6,0.1,3.1,0.2,4.7,0.3c3.1-3.5,6.9-6.5,11-8.7
                  c3.9-5.9,8.2-11.5,12.9-16.8c-4.3,2.6-8.5,5.1-12.8,7.7c-1.5-5.7,1.2-12.2,6.2-15.3c-5,1.4-10.3,1.8-15.5,1.2
                  c3.7-1.4,7.3-2.8,11-4.3c-2.9-0.1-5.9-0.1-8.8-0.2c2.6-2.6,5.4-5,8.2-7.3c-0.4,0.2-0.9,0.4-1.3,0.6c4.1-14.1,11.9-27.2,22.3-37.6
                  c-8.4,7.2-17,14.2-25.8,21c1-5.6,4.1-10.8,8.6-14.4c-2.6-0.4-2.2-4.4-0.7-6.7c4.4-7,10.7-12.9,18.1-16.7
                  c-6.1,2.6-12.3,5.3-18.4,7.9c4.9-14.5,16.1-26.9,30-33.3c-4.4,0.1-8.7,0.3-13.1,0.4c2.3-3.8,5.2-7.3,8.5-10.3
                  c-1.5,1.2-3.9-1.1-3.4-3s2.4-3.1,4.1-4s3.7-2,4.2-3.9c-10,6.8-19.9,13.6-29.9,20.4c-1.3-8.5,4-16.9,11.1-21.7
                  c7.1-4.9,15.6-6.9,24-8.9c-2.6-0.6-0.5-4.7,2.1-5.5c-2.2-2.3-5.3-3.7-8.5-4c5.3-4.6,11.7-7.7,18.6-9.2c-8.9-0.1-17.9,1.1-26.5,3.5
                  c8-6.5,17.5-11,27.6-13c-2.8-2.9-6.7-4.5-10.7-4.6c4.6-2.3,9.6-3.4,14.7-3.4c0-0.9,0.3-1.8,0.5-2.7c0.6-3.3-3.6-6.6-6.9-6.1
                  c0-0.3,0-0.5,0-0.8C371.6,122.9,377.6,118.7,380.1,115z"/>
               <path class="st5" d="M289,291.9c-0.4,0-0.8,0.1-1.1,0.1C288,292.7,289.5,292.4,289,291.9z"/>
               <path class="st5" d="M323.8,313.6c-2.1,0.7-4.1,1.6-6.1,2.7c-0.5,0.8-1.1,1.7-1.6,2.5C319.2,317.9,321.9,316.1,323.8,313.6z"/>
            </g>
            <path class="st5" d="M653.9,242c-0.7-1.3-0.7-2.8-0.7-4.3c0-1.3,0-2.6,0-4c-0.4-1.2-0.8-2.4-1.2-3.5c-4.3-0.8-8.5-1.6-12.8-2.4
               c1.5-0.4,3-0.8,4.5-1.2c-2.2-1.8-4.4-3.6-6.6-5.3c3-1.3,6.2-1.9,9.5-1.8c-4.9-2.4-10.4-3.8-15.8-4.1c1.7-0.7,3.4-1.4,5.1-2.1
               c-2.1-1.2-3.7-3.1-4.6-5.4c1.5-0.3,3.1-0.6,4.6-1c-6.4-5.2-11.9-11.4-16.3-18.3c4.5,3,9.6,5,15,5.7c-0.7-1.2-1.5-2.2-2.5-3.2
               c-4,0-8-1.9-10.3-5.1c3.9,0.5,7.6,2.3,10.3,5.1c1.1,0,2.2-0.1,3.3-0.5c2.9,4,7.1,7.1,11.8,8.8c-0.7-1.5-1-3.1-1.1-4.8
               c-1-1.4-2.1-2.7-3.1-4.1c-2-2.9-2.6-7.9,0.8-9.3c0.9-0.4,2.1-0.5,3.2-0.7c0.1-1.4,0.2-2.7,0.3-4.1c-2.1-2-3.1-5.2-2.1-8
               c-4.8,2.8-10.5,4-16,3.2c0.1-1.1,0.1-2.3,0.1-3.4c-1.5,0-2.9,0-4.4,0c1.5,0,2.9,0,4.4-0.2c0-2.1-0.2-4.3-0.5-6.4
               c-2.3-1.7-4.3-4-5.6-6.5c1.3-0.4,2.6-0.9,3.8-1.3c-1-3.2-2.4-6.4-4.1-9.3c-0.3,0-0.6-0.1-0.9-0.1c0.3-0.1,0.5-0.2,0.8-0.2
               c-0.8-1.3-1.6-2.6-2.5-3.9c-2.7-0.4-5.5-0.6-8.2-0.5c-16.9,2-34.3-0.5-49.9-7.2c15.3,2.2,31.1,1.3,46.1-2.5
               c-7,0.1-14.1,0.2-21.1,0.3c4-1.7,8.3-2.7,12.7-2.9c-15.8,0.3-31.6,0-47.3-1.2c10.6-1,21.2-2,31.8-3.1c-7-0.3-13.9-0.7-20.9-1
               c9.3-0.4,18.5-0.9,27.8-1.3c0.1-0.4,0.3-0.8,0.6-1.2c0.9,0.6,2,0.9,3,1c0.5,0,0.9,0,1.4-0.1c-0.5,0.1-0.9,0.1-1.4,0.1
               c-1.2,0.1-2.4,0.1-3.6,0.2c-0.6,2.5,2.8,5.8,5.2,4.2c2.4-1.6,0.8-5.8-1.7-6.3c-0.5,0-1,0.1-1.5,0.1c0.5-0.2,1-0.2,1.5-0.1
               c0.7,0,1.3-0.1,2-0.1c-2.7,0-5.5-0.2-8.2-0.8c2.4-0.5,4.8-0.9,7.2-1.4c0,0,0-0.1-0.1-0.1c-19.1,0.3-38.3-0.4-57.4-2
               c-15.9-0.5-31.7-2.5-47.1-5.9c15.6,2.6,31.4,4.6,47.1,5.9c17,0.6,34-0.5,50.8-3.2c-2.1-0.9-4.4-1.5-6.6-2.3c-2-0.7-4-1.7-5.9-2.7
               c-1.4-0.1-2.8-0.1-4.2,0c-2,0.2-3.9,0.3-5.9,0.5c2-0.3,3.9-0.4,5.9-0.5c1.2-0.1,2.4-0.2,3.6-0.3c-0.3-0.2-0.6-0.3-0.8-0.5
               c-12.5-1.1-24.9-3.8-36.7-8c7.3-0.5,14.5-1.2,21.8-2.2c-1.2-0.8-2.4-1.7-3.6-2.5c-0.8,0-1.7-0.1-2.5-0.1
               c-10.8-1.8-21.3-4.7-31.5-8.8c3.8-0.4,7.6-1.1,11.3-2c-12.9-4.4-26.7-6.9-40.2-9c-1.5-0.2-3.2-0.6-4.1-1.8c-0.6-1-0.6-2.2-0.6-3.3
               c0.2-6.1,0.5-12.2,0.7-18.2c-4.2,7.9-14.1,10.9-23.1,12.3S448,64,441.2,70c0.9-1.7,0.7-3.9-0.4-5.4c-6.2,12.1-17.2,21.7-30.1,26.3
               c0.4-3.6-1.7-7.3-4.9-8.8c3,9.5-6.5,18.2-15.3,23.2c-7.2,4.1-15.4,9.1-18.2,16.3c1.7,1.6,3.4,3.3,4.9,5.2c3.3-1.6,5.8-4.5,7-7.8
               c1.4,1.4,2.4,3.4,2.7,5.4c1-3.8,2.1-7.6,4.5-10.7s6.5-5.2,10.3-4.2c-0.8,6.1-1.6,12.3-2.4,18.4c3.3-3.3,5.9-7.2,7.7-11.5
               c0,5.1-0.9,11.1,0.9,16.1c2-0.3,3.5-0.5,3.8-0.6c6-1.2,11.9-3.1,17.5-5.7c-1.7,1.4-3.4,2.9-5.1,4.3c18,5.1,37.6,4.2,55.1-2.5
               c-5.5,2.5-10.9,5.1-16.4,7.6c17.7,2.1,35.9,0.9,53.2-3.5c-12.3,5.6-25.8,8.7-39.4,8.8c6.3,1.8,12.2,5,17.1,9.3
               c-4.3,0.4-8.7,0.7-13,1.1c1.8,4.2,5.3,6.9,9.6,8.6c1,0.3,1.9,0.6,2.9,1c4.9,1.4,10.4,1.8,15.5,1.9c7.1,0.1,14.3,0.2,21.3-0.5
               c6-1.9,12.1-3.3,18.3-4.3c-5.8,2.4-12,3.7-18.3,4.3c-3.9,1.3-7.8,2.7-11.6,4.4c9.6,1,19.2,1.9,28.8,2.9c1.8,0.2,3.6,0.4,5.3,1.2
               c3,1.5,4.6,4.6,7.2,6.8c3.2,2.7,7.9,3.5,11.8,2c0,2.2,0,4.4-0.1,6.6c-2.4-0.7-4.7-1.4-7.1-2.1c4.1,3.7,6.5,9.1,6.3,14.5
               c3.7-5,7.3-9.9,11-14.9c-1.3-12.2-14-19.5-25.1-24.8c12.6-0.4,25.2,5.5,33,15.3c-0.1-6.1-0.3-12.2-0.4-18.2c2.9-1.6,6.7,0,8.6,2.8
               c1.8,2.7,2.2,6.1,2.5,9.4c0.3,3.5,0.6,7,0.9,10.6c-1.2,7-2.4,14-3.6,21c2.2-0.3,4.3-1.3,5.9-2.8c0.1-0.2,0.3-0.3,0.5-0.5
               c-0.2,0.2-0.3,0.3-0.5,0.5c-0.8,1.2,0.5,3.2,2.1,3.4c1.4-1.4,3.1-2.5,5-3c-1.3,1.5-2.8,3.1-4.8,3.1c-0.1,0-0.2,0-0.2,0
               c-1.3,1.3-2.3,2.9-2.8,4.7c4,1.8,8.4,2.8,12.8,3c1.5-0.2,3.1-0.3,4.6-0.1c-1.5,0.1-3,0.1-4.6,0.1c-2.4,0.4-4.8,1.2-6.8,2.6
               c5.5,1.3,10.6,4.1,14.7,7.9c-2.3,0.8-4.7,1.6-7,2.4c2.2,0.9,4.2,2.5,5.7,4.3c-2.8,1.1-5.5,2.1-8.3,3.2c5.8,8.3,14.7,14.4,24.6,16.9
               c-9.2,0-18.3,0.1-27.5,0.1c11.4,8,25.1,12.9,39,13.9c-8.5,3.2-18.2,3.2-26.7,0.1c1.6,4.3,5.6,7.8,10.2,8.7
               c-1.5,0.8-3.1,1.6-4.6,2.4c1.8-1.1,1.9,3,0,3.8c7,2,14.2,3.5,21.4,4.5c-0.2-0.7-0.4-1.3-0.7-2c1.3,0.4,2.5,0.7,3.8,1.1
               c0.1-2.7,0.1-5.4,0.2-8.2c2.1,0.7,4.3,1.4,6.4,2.1c-0.3-9.3-1.5-18.5-3.6-27.6C655.2,243.5,654.4,242.9,653.9,242z"/>
            <path class="st8" d="M485.3,83.6c-4,3.5-8.3,6.7-12.9,9.6c8.7,1.5,17.9,0.5,26-2.9c-4.1,1.7-7.9,4-11.3,6.8c3,0.3,5.9,0.6,8.9,0.9
               c-0.7,0.7-1.5,1.4-2.2,2.1c21.3,1.8,43.3,3.5,63.4-3.7c-13.8,4.9-27.7,9.8-41.5,14.6c4.4,0.4,8.9,0.3,13.3-0.4
               c-2.2,0.4-4.4,0.8-6.5,1.1c6.4,4.5,13.9,7.4,21.7,8.3c-3.2,0-6.3,0.6-9.3,1.6c16,3.7,32.7,4,48.8,1c-11.3,2.3-22.3,5.8-32.8,10.5
               c4.3,1.5,8.5,3,12.8,4.4c-1.6,0.6-3.1,1.1-4.7,1.7c6.6,1.7,13.2,3.4,19.9,5.1c-1,0.8-2,1.5-3.1,2.3c3.4,1.4,6.8,2.9,10.2,4.3
               c-16.5-4.8-33.5-7.7-50.7-8.8c3.7-1.5,7.8-2.4,11.8-2.5c-4.1-1.3-8.2-2.5-12.4-3.8c1.9-0.4,3.8-0.8,5.7-1.2
               c-10.5-0.3-21-0.7-31.5-1c7.7-2.4,15.5-4.9,23.2-7.3c-1.6-0.2-3.3-0.4-4.9-0.6c-4.2-1.6-8.7-2.2-13.1-1.7c1.4-1.3,2.8-2.5,4.2-3.8
               c-1.8-0.1-3.6-0.2-5.4-0.2c0.5,0.3,1-0.6,0.7-1.1s-1-0.6-1.6-0.7c-19.4-1.8-39.2,0.1-57.9,5.7c15.5-8.7,32.2-15.2,48.7-21.8
               c-8.6,1.3-17.2,2.6-25.8,3.9c0.9-0.9,1.8-1.9,2.7-2.8c-3.2-0.1-6.5-0.1-9.7-0.2c0.1-0.8,0.8-1.4,1.5-1.4c-1.8,0.5-3.7,1-5.5,1.5
               c0.9-1.5,1.2-3.2,1-4.9c-20.2,2.9-40.5,4.9-60.9,6.2c1.5-0.1,3.1-0.8,4.6-1.2c1.6-0.4,3.2-0.8,4.8-1.2c3.1-0.8,6.3-1.5,9.4-2.3
               c6.4-1.5,12.7-3,19.1-4.3c3.6-0.8,7.3-1.4,10.9-2.3c-2.2-0.1-0.4,3.3,0.9,3.7c1.7,0.6,3.9-0.4,5.5-1c5.4-2,10.7-4.4,15.8-7.1
               C479.7,86.9,482.5,85.3,485.3,83.6z"/>
         </g>
         <path class="st2" d="M560.9,626.1c0,0-11.8,37.5-72.5,51.2l70.6-6.8C559,670.5,570.7,628.2,560.9,626.1z"/>
         <path class="st7" d="M494.2,592.2c-5.5,1.6-10.7,5.1-13.4,10.2c-2.8,5.3-2.7,11.5-2.5,17.5c0.2,5.5,0.3,11,0.5,16.6
            c0,1.3,0.1,2.7,0.6,3.9c0.4,1,1.2,1.9,2,2.6c7.8,7.7,20.3,10.2,30.5,6c3.8-1.6,7.2-4,10.6-6.4c1.9-1.4,3.8-2.9,4.9-4.9
            c1.1-1.9,1.4-4.1,1.6-6.2c0.8-6.1,1.6-12.2,1.9-18.3c0.2-3.9,0.5-8.2-1.1-11.9c-2.7-6.3-10.7-8-16.8-9.2
            C506.8,590.8,500.2,590.5,494.2,592.2z"/>
         <path class="st2" d="M597.4,582c4.3,8.8,6.3,19.3,3.2,28.6c-1.4,4.3-3.9,8.2-6.4,11.9c-6.7,10.1-14,19.7-21.9,28.9
            c-4.8,5.6-10.9,11.4-18.3,11.3c-0.7-8.5-1.4-17.3,1.6-25.2c1.3-3.6,3.4-6.9,4.8-10.5c4.7-11.4,3.3-24.5,6.3-36.5
            c2.3-9.1,7.3-17.5,8.3-27c0.1-1.4-0.4-4,1-4.9c1.1-0.7,2.7-0.1,3.7,0.4c3.7,1.8,6.3,6.1,8.7,9.2C591.9,572.6,595,577.1,597.4,582z"
            />
         <path class="st9" d="M362.7,569c0,0,15.5,112.4,89.2,120.7c73.8,8.3,141.1-22.6,146-108.3c0,0,29.6,1.1,71.7,21.8
            c42.1,20.6-194.5,241.1-194.5,241.1l-166.4-234C308.7,610.2,326,583.6,362.7,569z"/>
         <g>
            <path class="st2" d="M563.8,643.8c0,0-11.9,37.8-73.1,51.7l71.2-6.8C561.9,688.6,573.7,646,563.8,643.8z"/>
            <path class="st2" d="M608.5,559.4c4.4,8.9,6.4,19.4,3.2,28.8c-1.4,4.3-3.9,8.2-6.4,12c-6.7,10.2-14.1,19.9-22.1,29.1
               c-4.9,5.7-11,11.6-18.4,11.4c-0.7-8.5-1.4-17.4,1.6-25.5c1.3-3.6,3.4-7,4.9-10.6c4.8-11.5,3.3-24.7,6.4-36.8
               c2.4-9.2,7.4-17.7,8.4-27.2c0.1-1.4-0.4-4,1-4.9c1.1-0.7,2.7-0.1,3.7,0.4c3.7,1.9,6.3,6.1,8.8,9.3
               C603,549.9,606.1,554.5,608.5,559.4z"/>
            <g id="SHIRT_2_">
               <path class="st1" d="M500.2,960.3c0,0-0.5-176.4-44.1-249.4c0,0-6.4-14.6-30.9-19.7c0,0-71.1-95.7-74.9-117.3
                  c0,0-17.1-11.8-85.8,39.2c0,0-60.3,15.4-94.6,48c0,0-82.6,25.8-77.9,51.7c0,0-38.6,19.5-75,247.5L500.2,960.3L500.2,960.3z"/>
               <path class="st1" d="M448.8,958c0,0,24.6-196.5,56.7-219.3c0,0,57.5-44.4,38.9-59.6c0,0-11-5.1,14.4-18.6
                  C584.2,646.9,635,563,602,548.1l24,29.9c0,0,109.9,48.8,132.3,67c0,0,168.9,15.2,205.8,315.2L448.8,958z"/>
            </g>
            <path class="st10" d="M300.2,642.5c5,11.1,17.9,17.9,29.9,15.8c-7.9,7.2-15.8,14.4-23.7,21.7c13.6,6.8,27.1,13.6,40.7,20.4
               c-39.3-1.1-76.3,32.8-78.5,72c15.2,7.2,30.4,14.4,45.5,21.6c-23.1-2-45.7,18.2-46.4,41.4s20.7,44.6,43.9,44
               c-16.7,10.3-33.4,20.5-50.1,30.8c-36.5-64-80.1-123.8-129.8-178.1c43.5-35,87.1-70,130.6-105c9.2-7.4,20-16.9,31.7-19.9
               c9.4-2.4,6.3,10.9,5.2,16.1C297.7,629.9,297.4,636.2,300.2,642.5z"/>
            <path d="M359.6,534.8c0,0,19,147.6,112.4,154.4c93.4,6.8,123.2-151.7,123.2-151.7l24.3,16.5c0,0-36.8,182.2-141.1,172.2
               S329.8,564.3,329.8,564.3L359.6,534.8z"/>
            <polygon points="556.3,960.3 415.3,960.3 415.3,683.3 465,710.5 555.5,676.5 556.3,726.1 	"/>
            <path d="M108.7,715.1c23.4-17.5,47.9-33.4,73.4-47.7s51.8-26.9,78.9-37.7c27.1-10.9,54.9-20,83.1-27.2c7.3-1.9,14.8-4.1,22.3-5.5
               c-9.1-14.6-15.8-27.1-16.9-33.3c0,0-17.1-11.8-85.8,39.2c0,0-60.3,15.4-94.6,48c0,0-82.6,25.8-77.9,51.7c0,0-3.3,1.7-8.8,9.7
               c5.9,3.2,11.7,6.4,17.6,9.5C102.9,719.5,105.8,717.3,108.7,715.1z"/>
            <path class="st10" d="M606.7,715.2c26.3,0,52.6-0.1,78.9-0.1c-3.7,17.1-7.4,34.1-11.2,51.2c-3.3,15.1-11.6,34-26.9,32.2
               c16.5-6.9,35.7,10.2,35.5,28.1c-0.2,17.9-14.2,33-30.3,40.8c-16.1,7.7-34.2,9.8-51.7,13.3s-35.6,9.1-47.9,22.1
               c102.9-1.7,205.3-25.6,298.3-69.7c-26.6,2.1-54-8.1-72.6-27.3c-12.3-12.7-20.7-28.6-28.9-44.3c-6.9-13.1-13.7-26.3-20.6-39.4
               c-4.8-9.2-9-21.6-18.2-27.5c-6.5-4.1-14.3-3.3-21.5-1.7c-11.8,2.5-23.5,6.2-35.2,9.4c-8,2.2-16,4.4-24,6.5c-4,1.1-8,2.2-12,3.3
               C614.9,713,610.2,715.2,606.7,715.2z"/>
            <path d="M733.5,654.6c24,11.8,47.2,24.9,69.7,39.4c22.4,14.5,44,30.2,64.6,47.2c2.5,2.1,5.1,4.2,7.6,6.3
               c4.5-9.7,9.8-18.9,15.9-27.7c-57.8-74.4-121.8-80.1-121.8-80.1c-22.4-18.2-132.3-67-132.3-67L609.9,551
               c8.4,11.4,0.9,33.5-11.5,54.2c20.6,5.4,41.1,11.1,61.2,18.3C684.8,632.4,709.5,642.8,733.5,654.6z"/>
            <polygon points="288.1,959.9 147.1,959.9 147.1,682.8 201.5,647.3 288,610.4 288.1,725.6 	"/>
            <polygon points="670.4,960 811.3,960 811.3,682.9 757,647.5 670.4,610.5 670.4,725.7 	"/>
            <path d="M889.7,717.3c-4.8,5.8-10.1,20.8-14.5,26.9c-1.3,2.6,8.9-17.8,7.7-15.2c-0.4-0.3-5.5,7.1-5.8,6.8c-0.1,0.1-5.7,1.8-5.8,2
               c-12.8,18.5-25.2,51.8-34.8,72.2c-9.5,20.4-17.4,41.6-23.4,63.3s-10.2,43.9-12.5,66.3c-0.7,6.7-1.1,13.3-1.5,20l172.1,0.8
               C957.4,846.3,925.2,764.1,889.7,717.3z"/>
            <path d="M149,795.1c-13.4-30.2-31.4-57.5-53.1-79c-7-6.9,4.1-14.1-4.3-20.1c-12.3,22.6-55.2,86.8-78.1,264.4H184
               c-2.9-53.5-11.4-106.3-30.3-154.2C152.2,802.4,150.6,798.7,149,795.1z"/>
            <polygon class="st0" points="356,785.3 382.5,828.1 303.5,828.1 	"/>
         </g>
         <g>
            <g>
               <g>
                  <path d="M668,914.8c-2.2,0-4.4-0.2-7.3-0.8c-3.5-0.6-6.9-1.6-10.1-2.8c-10-3.9-18.2-10.3-24.4-18.9c-6.3-8.8-9.8-18.8-10.2-29.7
                     l0-0.1l-0.1-0.5v-86.8h108.5l-0.1,2.9c0,0.1,0,0.2,0,0.3l0,16c0,22.3,0,44.6,0,66.8c0,3-0.3,6.1-0.8,9c-1,5.7-3,11.2-5.8,16.2
                     c-5.1,9.2-12.2,16.4-21.4,21.5c-6.7,3.7-14,6-21.7,6.6c-1.5,0.1-3,0.1-4.2,0.1C669.9,914.8,668,914.8,668,914.8z"/>
                  <path class="st0" d="M721.8,777.8c0,0.2,0,0.4,0,0.6c0,27.6,0,55.3,0,82.9c0,2.9-0.3,5.8-0.8,8.6c-1,5.5-2.8,10.6-5.5,15.5
                     c-4.8,8.8-11.6,15.6-20.4,20.5c-6.4,3.6-13.3,5.7-20.7,6.3c-1.3,0.1-2.6,0.1-4,0.1c-0.8,0-1.7,0-2.5,0c-2.3,0-4.6-0.3-6.9-0.7
                     c-3.3-0.6-6.5-1.5-9.6-2.7c-9.5-3.7-17.3-9.7-23.3-18c-6.1-8.4-9.3-17.9-9.7-28.3c0-0.2-0.1-0.4-0.1-0.6c0-28,0-56.1,0-84.1
                     C652.9,777.8,687.4,777.8,721.8,777.8 M727.1,772.8h-5.2H618.5h-5v5v84.1v0.4l0.1,0.4c0,0.1,0,0.2,0,0.3
                     c0.5,11.3,4.1,21.7,10.7,30.8c6.5,9,15.1,15.7,25.5,19.7c3.4,1.3,6.9,2.3,10.6,3c3,0.5,5.4,0.8,7.7,0.8l0.8,0c0.6,0,1.1,0,1.7,0
                     c1.2,0,2.8,0,4.4-0.1c8-0.6,15.7-2.9,22.7-6.9c9.5-5.3,17.1-12.9,22.3-22.5c2.9-5.3,4.9-11,6-17c0.6-3.1,0.9-6.3,0.8-9.5
                     c0-22.3,0-44.7,0-67l0-15.8c0,0,0-0.1,0-0.2l0-0.3L727.1,772.8L727.1,772.8z"/>
               </g>
               <g>
                  <polygon class="st0" points="716.8,812.9 726.2,809.3 703.5,809.3 703.5,818.1 703.6,818.1 703.6,832.9 698.5,832.9 698.5,841.2 
                     703.6,841.2 703.6,858.7 703.5,858.7 703.5,867.5 703.6,867.5 716.8,867.5 726.2,867.5 716.8,863.9 			"/>
                  <path class="st0" d="M681.9,787.9h-43.7v22.7l4.4-11.2h40.2v33.4h-8.6v-23.4h0v-0.1h-22.7l9.5,3.7v50.9l-9.5,3.7h22.7v-0.1h0
                     v-26.3h8.6v17.7c0,17.4-6.8,25-21.8,25c-8.2,0-16.6-4.5-22.7-13.9l-8.5,8.2c7.4,12.1,19.8,17.6,31.6,17.6
                     c26.3,0,35.1-16.1,35.1-35.8v-72H681.9z"/>
               </g>
            </g>
            <polygon class="st0" points="677.2,723.5 691.4,725.6 681.1,735.7 683.5,749.9 670.7,743.1 658,749.8 660.4,735.6 650.2,725.5 
               664.4,723.5 670.8,710.6 	"/>
         </g>
         <g id="WORDS">
            <g id="VERDE">
               <path d="M94.2,131.2l0.2,0l-0.3,0.6l-0.5,0.2l-0.2,0.5c-0.3,0.4-0.7,0.8-1.1,1.1c-0.4,0.8-1.5,1.7-2.1,2.4l-0.1,0.8l-0.8,0.4
                  c-0.1,0.5-0.5,1-0.9,1.3l-0.3,0.1l-0.4,0.7l-0.1,0l-0.3,0.5l-1,0.7c0,0.4-0.5,2.3-1.1,2.7l-0.3,0.6c-0.1,0.1-0.6,0.4-0.7,0.3
                  c-0.4,0.9-1.1,2-2,2.4c-0.3,0.4-1.1,1.9-1.5,2.1l-0.2,0.8c-0.5,0.5-1,0.8-1.1,1.5c-0.5,0.3-0.5,0.7-0.9,1l-0.4,0.2l-0.5,0.6l0,0.3
                  l-0.4,0.2l-0.2,0.2l-0.2,0.5l-0.1,0.2c-0.2,0.7-0.7,3-1.4,3.3l-0.3,0.7l-0.7,0.4l-0.1,0c-0.3,1.1-1.3,2.3-2.3,2.7
                  c-0.4,0.7-0.8,1.3-1.3,1.9l0,0.2l-0.4,0.3l-0.3,0.8c-0.3,0.3-0.7,0.6-1,1l-0.2,0.7l-0.5,0.4c-0.1,0.2-0.2,0.6-0.4,0.8
                  c-0.4,0.2-0.7,0.1-1,0.5l-0.2,0.8l-0.6,0.4l-0.2,0.6c-1.3,1.2-1.8,3.8-3,4.6l-0.4,0.6l-0.6,0.3l0,0.1l-0.6,0.4
                  c-0.1,0.2-0.2,0.7-0.1,0.8l-0.2,0.7c-0.5,0.6-1.1,1.1-1.4,1.8c-0.2,0.2-0.4,0.4-0.5,0.6l-0.1,0.8c-0.3,0.1-1,0.8-1,1.1
                  c-0.6,0.4-0.8,1.1-1.5,1.5l-0.2,0.8c-0.5,0.6-1.1,0.8-1.3,1.8c-0.3,0.2-0.5,0.6-0.7,0.9l-0.5,0.3l0.6,0.5l-0.2,0.5l-0.8,0.5
                  c-0.3,0.4-0.5,0.8-0.6,1.2l-0.3,0.2l-0.2,0.4L52,191c-0.2,0.4-0.4,0.8-0.8,1l-0.1,0.9l-0.7,0l-0.2,0.5l-0.5,0.4l0,0.8
                  c-0.4,0.1-0.7,0.4-1,0.6c0,0.5-1.2,2.6-1.6,3.1c0.2,0.6,0,1-0.4,1.5l0.4,0.8l-0.2,0.8l-0.5,0.6l-0.4-0.4l0.2-0.7l-0.5,0.6
                  c0.1,0.2,0,0.5-0.1,0.7l-0.5,0.6c0,0.5-0.3,1.2-0.8,1.5c-0.2,0.4-0.4,0.8-0.6,1.2l-0.1,0c-0.1-0.2-0.2-0.4-0.2-0.6
                  c-0.1-0.5,0.2-1.1,0.3-1.5l0.5-0.6l0-0.1l-0.2-0.3l-0.6,0.7c0.1,0.3-0.2,1.4-0.4,1.7l-0.3,0.2c-0.1,0.2-0.2,0.5-0.4,0.6l0.4,0.6
                  c-0.1,0.4-0.3,0.8-0.5,1.2l-0.3-0.3l0.2-0.5l-0.4,0.4c0.1,0.1,0,0.4-0.1,0.6l-0.5,0.6c-0.1,0.4-0.3,1-0.6,1.2
                  c-0.1,0.3-0.3,0.7-0.5,0.9l-0.1,0c-0.1-0.1-0.1-0.4-0.1-0.5c0-0.4,0.3-1.4,0.6-1.8c0-0.1-0.1-0.3-0.1-0.4l-0.5,0.6
                  c0,0.4-0.1,0.9-0.2,1.3c-0.5,0.3-0.8,0.8-1.3,1.2l-0.3,0l-0.4,0.5l-1.2,0.1c-0.4-0.1-1.1-0.3-1.4-0.2c-0.3-0.3-0.6-0.7-1-0.9
                  l-0.5,0.3l-1.2,0.1c-0.4-0.1-0.7-0.2-1.1-0.3c-0.3-0.3-0.7-0.4-1.1-0.6c-0.3-0.2-0.6-0.5-0.6-0.8l0-0.2c-0.2-0.3-0.6-0.5-0.9-0.7
                  l0-0.2l-0.3-0.5l-0.5-0.2l-0.1,0l-1.7-3.1c0.2-0.5,0.3-1,0.3-1.4l-0.3-0.5l0.4-0.4l0.2-0.6c-0.1-0.6-0.1-1.2-0.3-1.7l0.3-0.2
                  l0-0.4l-0.3-0.8c0.2-0.6,0.3-1.2,0.4-1.8l-0.4-0.6c0.1-0.1,0.8-0.7,0.5-0.9c0-0.3-0.1-0.7,0.1-0.9c-0.1-0.5-0.1-1.1-0.2-1.6
                  l0.3-0.3c0-0.4,0-1,0.3-1.4c0-0.4-0.2-0.9-0.3-1.2c0.2-0.5,0.4-0.9,0.7-1.4l-0.1-0.1l-0.1-0.6l0.2-0.2l-0.4-0.6l0-0.7
                  c0.2-0.2,0.3-0.5,0.4-0.8c-0.1-0.9-0.4-2.5,0.7-3c-0.1-0.6-0.5-1.8,0.1-2.4c-0.4-2.1,0.7-3.9,0.2-5.9l0.6-0.4
                  c-0.1-0.4-0.3-0.7-0.3-1.1l0.5-0.3l-0.3-0.6c0.2-0.4,0.4-0.8,0.3-1.3c0.1-0.3,0.2-0.8,0.4-1c-0.1-0.4-0.1-0.9,0-1.4l-0.1,0
                  l-0.2-0.6l0.4-0.6c0-0.6-0.2-1.2-0.3-1.8l0.5-0.6l0-0.1l0.1-0.4c-0.1-0.4,0-1,0.1-1.4l-0.4-0.4l-0.1-0.6l0.2-0.5l-0.2-0.8l0.4-0.3
                  l-0.4-0.8c0.1-0.6,0.6-1.6,0.5-2.2c0-0.4-0.2-0.9-0.3-1.2l0.3-0.3l-0.1-0.7c0.1-0.2,0.2-0.7,0.4-0.8l-0.2-1.1
                  c0.2-0.2,0.4-0.5,0.4-0.8l-0.1-0.7l0.1-0.1c0.4,0,0.7,0,1.1-0.2l-0.2-0.8l0.6-0.4l0.1,0l0.4-0.2c0.4-0.7,0.9-1.3,1.4-2l0.1,0
                  c0.3-0.3,0.8-0.6,1.3-0.7c0.4-0.8,0.3-1.8,0.9-2.5l0.7,0.5c0,0.1,1.3,4.6,1.5,4.7l0.4,1.1l0.3,0l0.2-0.7l0-1.1l-0.3-0.5
                  c0-0.5,0.2-1.3,0.2-1.8c0-0.4-0.2-0.7-0.4-1c0-0.4,0.1-0.8,0-1.1l0.2-0.7l0.2,0c0.4,1.2,0.9,2.5,1,3.8l0.5,0.8l0.6,0.3
                  c-0.3,1.7,0.1,1.9,1.2,3.4l0.4,4c0.1,0.7,0.3,1.3,0.3,2l-0.2,0.5l0,0.4l0.4,0.7c-0.2,1.2,0.2,2.3,0.3,3.5l-0.3,0.3
                  c-0.2,0.4-0.2,1-0.1,1.4l-0.3,0.4l0.5,0.6c0.1,0.2-0.3,1.4-0.4,1.6l0,0.3c0.2,2,0.7,5.9,0.1,7.6l0.1,0.7l-0.3,0.3l-0.2,0.7l0,0.1
                  l0.9-0.3l0.3-0.6l0.5-0.3l0.2-0.8l0.5-0.6c0.2-0.7,0.9-2,1.4-2.4l0-0.3c0.8-0.7,1.2-1.6,2-2.4c0.2-0.5,0.3-1,0.3-1.5
                  c0.6-0.3,1-0.8,1.6-1.1l0-1c0.8-0.6,1.2-1.4,1.6-2.4l0-0.1c0.3-0.3,0.7-0.9,0.9-1.3l0.5-0.5l0.2-0.8c0.7-0.1,0.9-0.4,1.2-1
                  l0.3-0.1c0.3-0.8,2.9-4.6,3.3-4.8c0-0.3-0.1-0.6-0.2-0.9l0.4-0.6l0.1,0c0.5-0.3,0.8-0.8,1.3-1.1l0.1-0.6c0.4-0.7,0.8-1.5,1.5-1.8
                  l0-0.1c0.3-0.3,0.6-0.8,0.7-1.2c0.2,0.1,0.7-0.3,0.8-0.4c0.1-0.2,0.3-0.8,0.2-1c0.3-0.2,0.6-0.4,0.8-0.7c0.1-0.6,0.4-1.2,0.8-1.7
                  l0.5-0.3l0.5-0.7l0.7-0.4c0.2-0.8,0.8-1.3,1.2-2l0-0.1l0.7,0.2l0.3,0.3l0.5-0.6l0-0.1l0.7-0.4l0.2-0.5l0-0.1l0.4-0.6l0.1,0
                  l0.4-0.3c0-0.2,0.5-0.6,0.7-0.7c0-0.4,0.2-0.9,0.5-1.2l-0.1-0.5l0.5-0.6l0.1,0c0.3-0.4,0.7-0.8,1.1-1.1l0.3-0.8l0.1,0l0.3,0.3
                  l0.2-0.6l0-0.1l0.7-0.7c0-0.5,0.3-1.1,0.7-1.5l0.4-0.2l0.5-0.6l0.6-0.4c0.2-0.7,0.7-1.2,1.1-1.7l0-0.1l0.6,0.2l0.2,0.3l0.5-0.5
                  l0-0.1l0.6-0.3c0.2-0.5,0.8-0.8,1-1.4c0.4-0.3,0.8-0.5,1.1-0.9l-0.2-1l0.4-0.6l0.1,0c0.3-0.4,0.6-0.7,0.9-1l0.3-0.7l0.1,0l0.5,0.5
                  l0,0.4l-0.4,0.2l-0.5,0.6l0,0.1l-0.5,0.3l0.4,0.3c-0.5,1.5-2,2.4-2.5,3.9l-0.6,0.4l-0.4,0.6l-0.1,0l-0.5,0.3
                  c-0.1,0.2-0.2,0.5-0.4,0.7l0.2,0.4l0.6-0.4c0.4-0.6,1-1,1.4-1.5l0.6-0.4l0.1-0.2l0.5-0.6l0.4,0c1.2-1,2.4-2.5,3.7-3.2l0.4-0.6
                  l0.1,0l0.4-0.6c0.3,0,0.5-0.3,0.8-0.2l0-0.4c0.6-0.3,0.7-1,1.4-1.1c0.3-0.1,0.4-0.5,0.7-0.5l0.2,0c-0.4,0.6-0.7,1.2-0.8,1.9
                  l-0.4,0.2c-0.5,0.8-0.4,1.6-1.3,2.1c-0.4,0.8-0.9,1.3-1.2,2.2c-0.6,0.4-1.7,2.5-2,3.1l0.5-0.6l0.1,0c0.3-0.3,0.7-0.7,1-1l0-0.1
                  l0.5-0.3l0.2-0.8l0.6-0.2l0.5-0.6c-0.1,1.8-1,1.3-1.2,2.4c-0.3,0.3-0.5,0.8-0.7,1.2c-0.4,0.2-0.6,0.4-0.9,0.8l-0.2,0.7
                  c-0.5,0.4-1,1.3-1.1,2l1.1-0.5l-0.1-0.7l0.5-0.3l0.1,0l0.4-0.6c0.8-0.4,1.8-1.3,2.5-1.5l-0.4,0.7l0.1,0.9c0.6-0.9,1-1.6,1.7-2.5
                  l0.5-0.1l0.5-0.5l0.3-0.7l0.5-0.5l0-0.1c0.5-0.3,1-0.6,1.2-1.1l0-0.1c1-0.6,1.8-1.7,2.8-2.4c0.2-0.5,0.8-0.6,1.2-0.9l0.2-0.6
                  l0.1-0.1c1.4-0.6,2.3-2.4,3.5-2.6l0.1,0l-0.2,0.5l-0.5,0.1l-0.1,0.5c-1,0.9-1.8,2.1-2.8,3.1l-0.2,0.7l-0.8,0.4l-0.2,0.6l-0.5,0.6
                  l-0.2,0.1l-0.4,0.6l-0.1,0l-0.2,0.5l-1.1,0.7c0,0.5-0.3,1.1-0.8,1.2c-0.3,0.4-0.4,0.9-0.8,1.1c-0.2,0.4-0.5,0.7-0.9,1
                  c-0.1,0.2-0.2,0.5-0.1,0.7c-0.4,0.3-0.7,0.7-0.9,1.1C92,132.9,93,131.3,94.2,131.2z M27.8,200.7l-0.2,0.5l0.2,0.6l0.1-0.2L28,201
                  l-0.2-0.6L27.8,200.7z M28.9,190.5l0,0.3l0.3,0.1l0-0.4L28.9,190.5z M38.9,207.6L38.9,207.6l-0.4,0.3l0.1,0.6l0.2,0.1
                  c0.2-0.6,0.4-1.1,0.8-1.6l0-0.1l-0.2,0l-0.1,0.2L38.9,207.6z M39.8,167.5l0.5-0.5l0.3-0.8l-0.2,0l-0.7,0.1L39.8,167.5z
                  M40.6,206.9l0.2,0.1L40.6,206.9L40.6,206.9z M42.5,174.8l0,0.1l-0.6,0.4c0.4,0.7,0.1,0.7-0.5,0.8c0.3,0.4,0.6,1,0.6,1.5l1.2,0.5
                  l0.1-0.8l0.1-0.1c0-0.6,0-1.1,0-1.7c0-0.4-0.4-1.1-0.4-1.3l0.4-0.5l0.2-0.6l-0.1-0.5l-0.3-0.3c-0.2,0.3-0.4,0.9-0.3,1.3l0.1,0.6
                  L42.5,174.8z M42,166.6l0.1,0.7l0.6-0.1l-0.1-0.5L42,166.6z M42.2,160.9l0.3,0.7l0.4-0.1l-0.1-0.5L42.2,160.9z M43,162.2
                  c-0.1,0.4-0.4,0.7-0.6,0.9c0,0.3,0,0.5,0,0.8c0,0.1,0,0.3,0.1,0.4l0.6-0.4c0-0.5,0.1-1,0.2-1.5L43,162.2z M42.5,167.6l0,0.1
                  c0.1,0.6,0.5,1,0.5,1.6c0,0.2,0,0.5-0.1,0.7l0.6-0.3c-0.1-0.5,0-1,0.1-1.6c-0.1-0.3-0.4-0.5-0.6-0.8L42.5,167.6z M43,171.1
                  c0.2,0.3,0.4,0.7,0.8,0.7l-0.1-0.6L43,171.1z M43.9,198.3l-0.2,0.5l0,0.8l0.5-0.9l-0.3-0.5L43.9,198.3z M45.1,198.1l-0.5-0.2
                  l-0.2,0.2l0.6,0.2l0.4,0l0.3-0.5l-0.2-0.1l-0.2,0l0,0.1L45.1,198.1z M44.9,166.9c0.3,0,0.8,0.2,0.8,0.6l0,0.8l-0.1,0L44.9,166.9z
                  M45.7,169.6l0.2,0.5c-0.3-0.2-0.8-0.5-0.9-0.9L45.7,169.6z M47.3,192.6c-0.5,1.1-2.2,2.3-2.1,3.3l0.5,0.2l0.7-0.4l0-0.1
                  c0.1-0.3,0.4-0.7,0.7-0.9c0.1-0.3,0.1-0.8,0.5-0.9l0.1,0l0.1-0.6L47.3,192.6z M45.5,200.2l0.1-0.2l-0.2-0.1l-0.2,0.2L45.5,200.2z
                  M45.6,198.7l0.1,0.6l0-0.1c0.1-0.2,0.1-0.4,0.2-0.5L45.6,198.7z M47.9,192.5l0.5,0.1c0.2-0.4,0.4-0.8,0.6-1.2
                  c0-0.3-0.2-0.8-0.5-0.8C48.2,191.2,48.1,191.9,47.9,192.5z M50.2,189.9c-0.3,0.2-0.7,0.4-0.9,0.6c0.4,0.2,0.8,0.3,1.3,0.2l0.4-0.6
                  l-0.2-0.1L50.2,189.9z M50.7,191.3l0.1,0.7c0-0.3,0.2-0.6,0.4-0.8L50.7,191.3z M53.2,184.4l-0.6,0.2l-0.1,0.7
                  c-0.4,0.2-0.8,0.6-1.2,0.9l-0.2,0.5l0.4,0.4c0.3,0,0.9-0.2,0.8-0.5l0.4-0.6l0-0.4l1.1-0.5l-0.3-0.7L53.2,184.4z M54.6,163
                  l-0.3,0.3l0.5-0.3L54.6,163z M55.5,185.4c0,0.2,0,0.7-0.3,0.8l-0.1,0c0-0.4-0.1-0.8,0.1-1.1L55.5,185.4z M58.5,176.9l-0.2,0.7
                  c-0.5,0.9-1.3,1.7-2.1,2.3c-0.1,0.7-0.5,1.4-0.5,2.2l0.5-0.5l1-0.3c-0.1-0.3-0.3-0.5-0.5-0.7l0.8-0.7l0.2-0.8
                  c0.3-0.3,0.5-0.7,0.9-1c0.4-0.7,0.8-1.3,0.9-2.1l0.5-0.5c0.1-0.4,0.5-1.2,0.8-1.3l-0.3-0.1C59.5,174.9,59.3,176.1,58.5,176.9z
                  M56.4,183.4c0.1,0.2,0.1,0.4,0.1,0.7c0,0.1,0,0.3,0,0.4l-0.4,0.2l-0.1-0.4C56.1,184,56.4,183.7,56.4,183.4z M62.1,172.8l0.7,0.1
                  l0.1,0c0.9-0.9,2.1-3,2.8-4.1l-0.6-0.3l-0.5,0l-0.3,0.8l0.1-0.1l-0.4,0.8l-0.7,0.4l0,0.1l-0.5,0.6l0,0.1l-0.3,0.3l0.1,0.7
                  L62.1,172.8L62.1,172.8z M67.1,148l-0.3,0.3l0.4-0.3L67.1,148z M68.7,166l0.1,1l-0.4,0.2l-0.1-0.4C68.4,166.5,68.6,166.3,68.7,166
                  z M76.5,146.6l0-0.2c-0.2-0.1-0.4-0.2-0.6-0.2L76.5,146.6z M76.7,136.6l-0.1,0.3c0.6-0.3,0.9-0.9,1-1.5l-0.4,0.8L76.7,136.6z
                  M77.9,143.6c-0.2,0.2-0.4,0.5-0.5,0.7l0.7,0l0.1,0l0.2-0.5L77.9,143.6z M77.7,135.3l0.5-0.3l0.3-0.9l-0.7-0.1
                  c-0.1,0.3-0.2,0.7-0.1,1L77.7,135.3z M78.7,142.6l0.6,0.4l-0.1-0.5L78.7,142.6z M80.8,139.8l-0.4,0.6c0.4-0.3,0.8-0.7,1.1-1.1
                  l0.1-0.5l-0.4,0.7L80.8,139.8z M84.9,134.8l-0.2,0.3c-0.3,0.1-0.7,0.1-0.9,0.2c-0.3,0.8-0.9,1.6-1.3,2.3l0,0.3
                  c0.2-0.2,0.5-0.5,0.8-0.6c0.2-0.7,0.7-1.2,1.3-1.5l0.6-1L84.9,134.8z M83.9,143.7l0.4-0.2l0.3-0.4c-0.1,0.1-0.2,0.2-0.4,0.2
                  L83.9,143.7z M86.2,122.7l0.5,0.5l-0.2,0.5l-0.5-0.3L86.2,122.7z"/>
               <path d="M143.8,140.4c-0.2,0-1.3-0.1-1.2,0.3l0,0.1c-1.2,0.1-2.3,0.5-3.5,0.8l-0.6,0.5c-1.2,0.1-1.6,0.8-2.2,0.8
                  c-0.2,0-0.6-0.1-0.8-0.1c-2.2,0.5-4.2,1.2-6.5,1.4l-2.3,0.2c0,0-0.4,0-0.4,0c0-0.1,0.1-0.2,0.2-0.2c0-0.2,0.1-0.3,0.3-0.3l0.2,0
                  l-0.6-0.2l-0.2-0.3l-0.5-0.2c-0.2,0-0.4,0.2-0.3,0.4l0,0.1l0.2,0.2c0,0.2-0.6,0.3-0.6,0.3c-0.7,0.1-0.4,0-0.8,0.4
                  c-0.6,0.1-0.7-1.1-1.3-1.1c-0.4,0-0.6,0.4-0.9,0.4c0,0.1,0,0.3-0.1,0.3c-0.1,0-0.3-0.2-0.4-0.3c-0.1,0.2-0.3,0.4-0.5,0.4
                  c-0.1,0-0.1-0.1-0.1-0.1l0-0.1c-0.1,0.2-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.1-0.2-0.1l0-0.1l-0.8,0.3l-0.2-0.3c-0.1,0-0.4,0.1-0.3,0.3
                  l0,0.1l-0.1,0.1c-0.2,0-0.3-0.3-0.5-0.3c-0.1,0,0,0.4,0,0.4c0,0.2,0.1,0.5,0.4,0.6c0.2,0,0.6,0,0.8,0.2l0.8-0.5
                  c-0.2-0.1-1.1-0.2-1.1-0.3c0-0.1,0.4-0.1,0.4-0.1l2.5-0.2l0.5-0.3l0.5,0.8c0.3-0.1,1.2-0.3,1.2,0.2L123,145
                  c-0.1,0.1-0.1,0.4-0.3,0.4c-0.1,0,0-0.4-0.4-0.3c-0.3,0-0.6,0.3-0.9,0.5l-1.5,0.2c-0.1,0.1-0.3,0.3-0.4,0.3
                  c-0.1,0-0.1-0.1-0.1-0.1l0-0.1c-0.1-0.1-0.2-0.3-0.4-0.3c-0.2,0-0.2,0.3-0.2,0.5c-0.9,0.2-1.6-0.1-1.7,0.9
                  c-0.9,0.1-1.7,0.5-2.5,0.5l-0.3,0.3c-0.2-0.1-0.4-0.3-0.7-0.3c-0.1,0,0,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2
                  c-0.1,0-1.3,0.6-1.3,0.6l-0.7-0.2l-1.5,0.4c-0.1-0.6-1.3,0.3-1.5,0.4c-0.6,0.1-1.1-0.2-1.7-0.1c-1,0.1-2,0.9-3,1l-0.5,0.3
                  c-0.1,0.2-0.3,0.3-0.4,0.3c-0.4,0-0.5-0.3-0.8-0.3c-0.1,0-0.1,0.2-0.1,0.3c0.2,0.1,0.6,0.2,0.1,0.3l-0.1,0c0,0.1,0.5,0.3,0.7,0.4
                  c-0.4,0.6-0.8,1.3-1.1,2l-0.7-0.3l-0.4,0.8l-0.3,0.2l0.3,0c0-0.4,0.6-0.2,0.6-0.1c0,0.1-0.2,0.2-0.3,0.2l-0.6,1.2l-0.2-0.9
                  c-0.2-0.1-0.6-0.2-0.8-0.1c-0.4,0-0.4,1.4-0.4,1.7c0.2,0,0.3,0.2,0.3,0.2c0.1,0,0.2-0.3,0.3-0.3c0.1,0,0.3,0.3,0.4,0.5
                  c0,0.6-0.8,0.5-0.8,1.1l0.3,0.5c0.3,0,0.5-0.3,0.5-0.6c1.2,0.3,1.6-0.5,2.7-0.6c0.1,0,0.4,0,0.4,0.1l1.1,0.3l0.4-0.6
                  c0.4,0,1.8-0.1,1.9-0.6l0.7-0.4c0.2,0.1,0.5,0.1,0.7,0.2l0.3-0.3l1.3-0.1l0.1-0.3c0.2,0,0.5,0,0.7,0.2l1.5-0.2
                  c0,0.6,1.3-0.1,1.6,0.4l0.6-0.2c0-0.4,0.4,0,0.5,0.1c0.2-0.1,0.9-0.2,0.9,0.1c0.7-0.1,1.6,0.1,2.4,0.1c0.1,0,0.5-0.1,0.5,0
                  c0,0.1-0.8,0.3-0.9,0.3c-0.2,0.1-0.4,0.2-0.6,0.2l-3,0.4c-0.9,0.2-1.9,1.1-3,1.2c-0.4,0-2.1,0.1-2.1,0.4c0.1,0.7,2.2-0.2,3.1,0.4
                  c2.1-0.2,4.3-0.9,6.5-0.3c0,0.4-1.4,0.4-1.7,0.5c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.4,0-0.4,0.3c1.5-0.2,3.2,0.1,4.7,0.2
                  c0-0.8,2.2,0.2,2.1-0.8c0.7-0.1,1.4,0.2,2.1,0.2c0-0.3,0.3-0.4,0.5-0.4c0,0.1,0.1,0.4,0.3,0.3c0.1,0,0.3-0.2,0.3-0.3
                  c0.3,0.1,0.5-0.1,0.6-0.4l0.5,0.2c0,0,0.2,0,0.2,0.1c0,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.3-0.1,0.3c-0.1,0-0.3-0.2-0.4-0.3l-0.5,0.3
                  c0,0.3-0.3,0.3-0.5,0.3l-2,0.2l0,0.5c-0.2,0-1.6,0-1.6,0.5l-2.3,0.5c-0.1,0.3-0.6,0.4-0.8,0.4l-0.3,0.3l-1.5,0.6
                  c-1.1,0.4-1.9,1.2-3,1.3c-0.7,0.1-0.8,0.6-1.6,0.7c-0.1,0-0.4,0-0.4,0.3l-0.4,0c0,0.2-0.3,0.3-0.4,0.3l-0.8,0l0,0.3
                  c0,0.1,0.1,0.4,0.3,0.3c0.2,0,0.5-0.2,0.6-0.4l1.3-0.1c0-0.1,0.8-0.6,1-0.6c1.2-0.1,2.4-0.7,3.5-0.8c1.8-0.2,4-0.8,6-1.3
                  c0.3-0.3,0.7-0.5,1.1-0.6c0.1,0,0.2,0.1,0.2,0.2c0.1,0.6-0.1,0.6-0.6,0.7c-0.3,0-0.7-0.1-0.8,0.4c-1.4,0.1-2.7,0.4-4.1,0.7
                  c-0.4,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2-0.1-0.2-0.2l-1.1,0.3c0,0.1,0.3,0,1,0l-0.1,0.6c-0.2,0-1.1-0.1-1.1,0.3l-0.6,0.5
                  c0.1,0.7,0.6-0.4,0.6-0.1l0,0.1c0.1,0,0.8,0.1,0.8,0.4c-0.9,0.1-2.4,0.8-3.3,0.9c-0.5,0.8-2.6,0.9-2.8,1.5c-0.2-0.1-1,0-0.9,0.4
                  l-0.7-0.2l-0.8,0.5l-0.5,0.1c0-0.2-0.2-0.4-0.4-0.4c-0.4,0-0.1,0.6-0.3,0.6c-0.1,0-0.4-0.4-0.5-0.5c-0.1,0-0.1,0.5-0.1,0.6
                  c-0.3,0-0.3,0.3-0.6,0.4c-0.4,0-0.6-0.2-0.9-0.5c-0.5,0.6-1.5,0.8-1.7,1.1l-0.5,0.1c-0.2-0.2-0.4-0.4-0.6-0.5
                  c-0.2-0.1-0.2,0.4-0.2,0.5l-2,0.2l-0.3-0.3c-0.4,0-0.3,0.3-0.6,0.4c-0.1-0.6-1-0.5-1.4-0.4c-0.6,0.1-1.2,0.2-1.8,0.3l0.1,0.6
                  c0.1,0,0.4,0,0.4,0.1c0,0.1-0.2,0.2-0.3,0.2c-0.5,0.1-0.1,0.5-0.6,0.6c0,0.3-0.3,0.4-0.5,0.4c-0.4,0-2.4,0-2.4,0.4
                  c0,0.3,0.8,0.9,0,1l0,0.4l-0.3,0.1c-0.2,0.5-0.5,0.9-0.9,1.3c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.1-0.3,0.3-0.2,0.5
                  c-0.5,0.6-0.8,1.9-1,2.6l-0.5,0.1c0.2-1.9,2.3-5,2.3-5.1c0-0.1-0.2-0.1-0.2-0.1l-0.9,0.7c-0.1,0.2-0.3,1.1-0.3,1.1
                  c-0.1,0-0.2-0.1-0.3-0.2c-0.6,0.1-0.7,0.8-0.7,1.2l-0.5,0.6c0,0.4-0.8,2.7-0.8,3.3l-0.6,0.5c0,0.1-0.1,0.2-0.1,0.2
                  c-0.2,0-0.4,0-0.4,0.3c-0.4,0.3-0.5,1.5-0.5,2c0,0.1,0,0.4-0.2,0.4l-0.1,0l0.4,0.7l0.4-1.1c0.6-0.1,0.5-0.8,0.5-1.2
                  c0.3,0,0.3-0.3,0.6-0.4c0.1,0,0.7,0.2,0.8,0.2c0,0.4-0.2,0.5-0.2,0.9l-0.5,0.6l0,0.1c0,0.2,0.2,0.2,0.4,0.2c0.4,0,0.7-0.2,1-0.4
                  l0-0.3l1.3-0.1l0.6-0.4c0-0.3,0.2-0.3,0.4-0.3c0.9-0.1,1.8-0.8,2.8-0.9l0-0.3c1.3-0.1,5.5-1.1,5.7-1.5c1.1-0.1,2.2-0.7,3.4-0.9
                  l0.8-0.5l0.9,0l0.6-0.4c2.8-0.3,5.8-1.4,8.6-1.7c1.2-0.1,2.5-0.2,3.7-0.1c0.1,0.1,0.6,0.2,0.6,0.3c0,0.2-0.9,0.3-1,0.3
                  c-1.4,0.1-2.7,0.6-4.1,0.7c0,0.4-1,0.7-1.3,0.7c-0.1-0.6-1.2,0.1-1.4,0.3c0.1,0.6,1.7,0.1,1.8,0.6c0.1,0,0.6-0.1,0.6-0.4
                  c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0,0.1-0.1,0.1-0.2l0.6-0.2c0-0.1,0-0.3,0-0.3c0.1,0,0.3,0.2,0.4,0.3l-0.3,0.3
                  c-0.1,0-0.4,0.1-0.4,0.3c0.1,0.7,1.8-0.2,2.1-0.2c0.2,0.6,4.1,0,5.3-0.5l1,1c0.3,0,2.1-0.3,2.1,0c0,0.1-0.2,0.1-0.3,0.1l-1.9,0.2
                  l-0.1,0.2l-1,0.1l-0.5,0.3c-1.2,0.1-2.2-0.2-3.3,0l0.3,0.6c0.7-0.1,1.6-0.1,2.4,0.1c0,0.1,0.1,0.2,0.2,0.2c0.4,0,0.6-0.4,1-0.4
                  c0.1,0,0.2,0.1,0.2,0.2l0,0.1c0.3,0,0.4-0.3,0.4-0.5l0-0.1c0.4,0,0.3-0.3,0.7-0.4c0,0.2,0.1,0.3,0.3,0.3c0.4,0,0.7-0.2,1-0.4
                  l0.4,0c0.2,0,0.4,0,0.4,0.3c0.1,0,1.6-0.8,1.7,0c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.5-2.3,0.5-2.7,0.6c0,0.2-0.3,0.3-0.5,0.3
                  c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.2-0.2,0.3l-0.9,0.2c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.2-0.2-0.2-0.3l-0.8,0.1
                  c0,0.2,0.1,0.6,0.4,0.6l0.5-0.1c0,0,3.3-0.6,3.3,0c0.3,0,0.5,0,0.8,0.2l0.3-0.3l0.5-0.1c0.2,0.2,0.5,0.2,0.8,0.2l1.4-0.1
                  c0.1-0.2,0.2-0.4,0.4-0.4c0.1,0,0.2,0.2,0.3,0.3l1.5-0.2l0.2-0.2c0.3,0,0.5-0.3,0.8-0.2l0.4,0.3l0.7-0.1c0,0,0-0.3,0-0.3
                  c0.1,0,0.2,0.3,0.3,0.3c0.3-0.3,0.7-0.7,1.2-0.8c0.3,0.1,0.6,0.3,0.8,0.6c-0.1,0-0.4,0.2-0.4,0.3l0.1,0l0,0.3
                  c-1.2,0.1-2.4,0.5-3.6,0.7c-0.4,0.4-0.7,0.6-1.3,0.6c0,0.2-0.3,0.3-0.4,0.3c-0.9,0.1-1.7,0.5-2.5,0.5c-0.7,0.1-2.1-0.2-2.4,0.6
                  c-1.8,0.2-3,1.1-4.1,1.2l-0.5,0.3l1-0.1c0.1-0.2,0.6-0.6,0.7-0.1l0,0.1c0.1,0,0.2-0.2,0.2-0.3l2-0.2c0.3-0.4,1.3-0.4,1.7-0.4
                  c0.1-0.2,0.5-0.3,0.7-0.4c0.1,0.7-0.7,0.8-1.2,0.9c-2.4,0.2-5,1.3-7.5,1.6c-0.7,0.1-0.7,0.7-1.5,0.7l-0.5,0.3l-2.1,0.2l-0.1,0.3
                  l-0.7,0.1c-0.3,0.3-0.6,0.7-1,0.7l-1.1,0.1c-0.3-0.3-0.7-0.6-1-0.8c-0.3,0-0.2,0.5-0.2,0.6l0.4,0.6l-1.1,0.2
                  c0,0.3-0.8,0.4-1.1,0.4c-0.7,0.1-1.2,0.2-1.9,0.3l-0.4,0.3l-1,0.1l-0.2-0.3c0.1,0.9-0.7,0.7-1.4,0.7l-0.3,0.6
                  c-0.4,0-0.7,0.4-1,0.7c-0.1,0-0.4,0.1-0.4,0.3c-0.3,0-2.4-0.1-2.5,0.5c-0.2,0-1.5,0-1.5,0.4c-0.7,0.4-1.7,1-2.5,1.1
                  c-0.1,0-0.4,0-0.4-0.3l0.1,0c0.3-0.1-0.4-0.2-0.4-0.2c-0.1,0.1-0.9,0.3-0.8,0.4c0,0.1,0.2,0.3,0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.2
                  c-0.1,0-0.1,0-0.2-0.1l0-0.1l-1.1,0.2c0,1.1-1.7,1.1-2.5,1.5c0-0.4-0.7,0.1-0.9,0.2l-0.3,0.2c-0.5-0.2-1.1-0.1-1.6-0.1
                  c-0.3,0-0.1,0.9-0.3,0.9c-0.2,0-0.4-0.2-0.5-0.3l-0.4,0l-0.1,0.6c-1.8,0.2-3.7,1.1-5.4,1.6c0,0.3-0.3,0.8-0.7,0.8l-0.8,0l-0.6-0.2
                  c-0.1,0-1.4-0.4-1.4-0.5c-0.3,0-0.9,0.3-1-0.2c-0.6,0.1-0.7-0.5-1.3-0.5c-0.2-0.3-0.7-0.8-1.2-0.8l-1.2-0.9l-0.4-0.6
                  c-0.7,0.1-1-0.9-1.4-1.3c0-0.1,0-0.3-0.2-0.3c-0.9,0.1-0.1-1.2-0.7-1.2c0-0.4,0.3-1,0.6-1.4l0.4-1.8c0.1,0,0.2-0.1,0.2-0.2
                  c0-0.2-0.1-0.4,0.2-0.4c0-0.2,0-0.5,0.2-0.7l0.6-1.7c0.4,0,0.3-1.2,0.3-1.5c-0.1-0.5-0.4-2.8,0.2-2.8c-0.1-0.7,0.4-1.1,0.4-1.7
                  l1-0.8l0.1-0.7c0.1,0,0.2,0,0.2-0.2c0-0.1-0.3-0.3-0.3-0.4c0-0.2,0.1-0.4,0.4-0.4l0.1,0c0.4-0.6,1.3-2,1.2-2.8l0.4-0.6
                  c0.1,0,0.2,0,0.2-0.2c0-0.3-0.4-0.5-0.5-0.8c0-0.4,0.7-0.1,0.6-0.4c0-0.2-0.1-0.4,0.2-0.4c0-0.3,0.1-0.5,0.3-0.8
                  c-0.1-0.5,0.5-0.6,0.4-1.4l-0.3-0.3l0.5-0.5c0-0.4,0.3-0.2,0.2-0.6l0.2-0.3l-0.2-0.1c-0.6,0.2-1.3,0.6-2,0.6
                  c-0.2,0.5-1,0.2-1.1,0.7c-0.6,0.1-1.1,0.3-1.7,0.4l-0.4,0.6c-0.1,0-0.5,0.1-0.5,0.3c-1.2,0.1-2.3,0.6-3.4,0.7l-0.6,0.4
                  c-0.3,0-0.8-0.1-0.8,0.4l-0.9-0.1c0,0-0.3,0-0.3-0.1l0.2,0c0.2,0,0.4-0.1,0.5-0.3c0.2,0,0.5-0.2,0.5-0.4c0.1,0,0.4,0,0.4-0.2
                  l0-0.1c0.2,0,0.6-0.1,0.7-0.4c0.6-0.1,0.9-0.4,1.2-0.9c0.9-0.2,1.9-0.6,2.6-1.1c0,0,0.1,0,0.1-0.1c0,0-0.4-0.2-0.5-0.2
                  c-0.6,0-0.6-0.3-1-0.3l-0.6,0.4c-0.1,0-0.6,0.1-0.6-0.2l0.1-0.2c0.4,0,1.3-0.4,1.7-0.5c0-0.3,0.1-0.5,0.4-0.5c0.4,0,1,0,1.2-0.4
                  c0-0.3,0.2-0.3,0.4-0.3c0.3,0,0.7-0.1,0.8-0.4c0.4,0,0.2,0,0.5-0.3l0.6-0.3l-0.8-1c-0.7,0.1-0.9-0.7-0.9-1.3l0.2-0.7
                  c0-0.1,0.1-0.2,0.2-0.2s0.4,0,0.3-0.3c0.2-0.4,0.5-1,0.9-1.1c0.3-0.5,0.4-0.9,1.1-0.9c0-0.9,0.5-0.5,0.8-1l0.7-0.3
                  c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0.2,0.2,0.3,0.2c0.3,0,0.8-0.3,0.7-0.7c0.8-0.2,1.5-0.3,2.3-0.5l0.3-0.5
                  l0.3-0.3c0.1-0.4,0.2-0.8,0.4-1.1l-0.1-0.7l0.5-0.7c0-0.2,0-0.6,0.2-0.8c-0.1-0.6,0.2-0.5,0.3-1c0.2-0.2,0.2-0.5,0.2-0.8
                  c0.1-0.2,0.2-0.5,0.1-0.8c0.2-0.2,0.2-0.5,0.3-0.7l0.5-0.4c-0.1-0.6,0.2-1.3,0.7-1.6c0.1-0.5,0.3-0.8,0.5-1.2
                  c0-0.3-0.1-0.9,0.3-0.9c0-0.3-0.3-0.3-0.5-0.3c-0.4,0-1.1,0.4-1.2-0.2l-1.5,0.1c0,0.3-0.3,0.4-0.5,0.4c-0.1-0.5-0.6-0.3-1-0.3
                  c-0.7,0.1-1.6,0.5-1.9,0.6l0-0.4c-0.1-0.5,1.5-0.4,1.8-0.5l1.3-0.6c0.4,0,2,0,2-0.6c-0.2,0.1-0.6,0.1-0.6-0.2l0-0.1l-3.1,0.3
                  c-0.3-0.5-1.9-0.1-2.3,0.2l-1,0.1c0-0.2,0.2-0.7,0.4-0.7h0.7c0.3-0.3,1.1-0.6,1.6-0.6l-0.8-0.4l-1.3,0.1l-0.4,0.3l-0.5,0.1
                  c-0.1,0-0.4,0-0.4-0.2l0-0.1c-1.8,0.2-3.3,1.2-5.1,1.4c-0.4,0-0.5-0.2-0.8-0.2c0,0.2-1.9,0.8-2.4,0.8l-0.7,0.1
                  c0-0.1-0.1-0.6,0.2-0.6c1.8-0.2,5-1.2,6.8-1.8l0.5-0.5c0-0.1-0.2-0.2-0.3-0.2c-0.5,0.1-1,0.3-1.4,0.5c-0.1,0-0.4,0.1-0.4,0.3
                  l-0.7-0.2c-0.2,0.5-1.4,0.4-1.7,0.5l-0.6,0.4l-1.3,0l-0.5-0.2l0.5-0.5c0.4,0,2.6-0.7,2.7-0.8l1.1-0.1c0.4-0.5,1-0.8,1.3-1.3
                  l0.7-0.1l0.2-0.2c0.7-0.1,1.1-0.4,1.8-0.5l0.6-0.4c0.2,0,0.6-0.1,0.7-0.4c0.9-0.1,5.3-1.2,5.5-1.4c0.2,0,1.6,0,1.6-0.5
                  c0.9-0.3,3.2-0.7,3.7-1.1c1.7-0.2,2.7-0.6,4.3-0.8c0-0.3,0.3-0.3,0.6-0.4c0.3,0,1.7,0.1,1.7-0.2c0.6-0.3,1.4-0.4,2.2-0.4l0.3-0.3
                  c1.2-0.1,4.3-1.1,5.2-1.5c0.4,0,2.9,0.2,2.8-0.5l0.3-0.3c0.5-0.1,2.7,0,3-0.6l11.8-1.2c2.6-0.3,5.7,0.6,8.4,0.3
                  c0.3,0.4,1.3,0,1.4,0.5l2.9-0.3c0.2,0.1,0.5,0.2,0.8,0.1c0.2,0.2,0.5,0.2,0.7,0.2l-0.4,0.3c-0.2,0-1,0.2-1.1-0.2
                  c-0.1,0-0.6,0.3-0.6,0.3c0,0.1,0.2,0.1,0.2,0.1l-0.3,0.3c-0.1,0-0.4,0-0.4,0.3l0,0.1l-1.8,0.2l-0.5,0.6l-1.5,0.1
                  c-0.2,0.2-0.7,0.7-0.7,0.9c0,0.3,0.7,0,0.8-0.2l0.7-0.1l0.3,0.3c0.1-0.2,0.5-0.3,0.7-0.4l0.2,0.3l0.4-0.3c0.5-0.1,0.7-0.4,1.1-0.5
                  l0.9-0.1c0,0.1,0.1,0.4,0.3,0.3s0.3-0.2,0.3-0.4c0.4,0,0.4,0.3,0.8,0.3l0.5-0.6l1-0.2l2.8-0.3l0.1,0.3c0.1,0.8-2.9,0.7-3.5,0.7
                  c0,0-0.3,0-0.3,0.1c0.1,0.5,3-0.4,3.1,0.3c0,0.2-1.5,0.3-2,0.3l-0.6,0.4c-0.2,0-0.5,0-0.7-0.2c-0.4,0.2-2.2,0.4-2.2,0.7l0,0.1
                  l-2.2,0.2c-0.1,0.1-0.3,0.3-0.2,0.5c0,0.1,0.1,0.1,0.2,0.1c0.4,0,2.1-0.4,2.2,0.3l0,0.4c-0.2-0.1-0.9-0.3-1.1-0.3l-0.6,0.1
                  c-0.1,0.4-0.9,0.3-0.9,0.8c0,0,0.5,0.2,0.5,0.2c0.6-0.1,1.1,0.2,1.6,0.1c2.6-0.3,5.3,0.3,8,0c0.5-0.1,3.1-0.6,3.5-0.1L143.8,140.4
                  z M76.4,168.5c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.3-0.1l-0.2,0C76.2,168.3,76.3,168.5,76.4,168.5z M77.6,167.9l-0.6,0
                  C77,168.2,77.4,168,77.6,167.9z M83,189.5l0.3,0l0-0.7l-0.3-0.1L83,189.5z M83.7,198.8c0,0.1,0.2,0.1,0.2,0.1
                  c0.1,0,0.9-0.3,0.8-0.5c0-0.1-0.2-0.1-0.2-0.1l-0.6,0.1C83.9,198.5,83.7,198.6,83.7,198.8z M84.7,186.4
                  C84.8,186.4,84.8,186.4,84.7,186.4c0-0.4-0.2-0.5-0.5-0.5C84.3,186.1,84.6,186.4,84.7,186.4z M85.7,184.7c0.1,0,0.1-0.2,0.1-0.2
                  l-0.2-0.1C85.5,184.5,85.5,184.7,85.7,184.7z M85.9,186.5c-0.3,0-0.3,0.3-0.3,0.5c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2
                  l0.5-0.6L85.9,186.5z M86,182.7c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.1C85.9,182.5,85.9,182.6,86,182.7z M86.8,181.5c0.1,0,0-0.3,0-0.3
                  s0.4,0,0.4-0.1s-0.6-0.2-0.7-0.2C86.5,181,86.7,181.5,86.8,181.5z M88.1,181.9c0-0.3,0.3-0.3,0.5-0.3c0.1,0,0.3,0,0.3,0
                  c0,0.1-0.2,0.2-0.3,0.3c0.1,0.2,0,0.4-0.2,0.5L88.1,181.9z M89.3,196.4c0,0-0.1,0-0.1,0.1c0,0.1,0.6,0.5,0.7,0.5l1-0.2
                  c-0.4,0-0.9-0.1-0.9-0.1s0.7-0.5,0.9-0.6l-1.3-0.2L89.3,196.4z M90.4,174.7l-0.1-0.5l-0.6,0.1C89.8,174.7,89.9,174.7,90.4,174.7z
                  M90.9,173.8c0,0.4,0.3,0.3,0.5,0.2l0-0.6l-0.6,0.1L90.9,173.8z M91.7,173.2c0.2,0,0.4,0,0.4,0.3l0.2,0c0-0.3,0.2-0.4,0.2-0.7
                  l-1,0.2C91.4,173.1,91.5,173.2,91.7,173.2z M94.6,193.4l-0.3,0l-0.3,0.4c-1,0.1-1.6,0.7-2.5,0.8l0.3,0.6c0.4-0.1,1.8-0.4,2.1-0.8
                  c0.5-0.1,0.9-0.4,1.5-0.4l0.6-0.3l0.3-0.4l-1.3-0.2C95,193.2,94.8,193.3,94.6,193.4z M91.6,196.7c0,0.1,0,0.2,0.2,0.2
                  c0.1,0,0.1-0.2,0.1-0.2L91.6,196.7L91.6,196.7z M92.4,195.9c0.1,0,0.2-0.1,0.2-0.2l-0.5,0L92.4,195.9z M93.5,195.7
                  c0-0.1-0.2-0.3-0.3-0.3C93.3,195.8,93.3,195.8,93.5,195.7z M97.7,192.7l0.1,0.3l1-0.1c0,0.2-0.2,0.7-0.4,0.7l-0.7,0l-0.7,0.7
                  c-0.1,0-0.4,0-0.4,0.2c0,0.1,0.2,0.3,0.3,0.3c0.6-0.2,2.7-0.7,3.2-1.1c0.1,0,1.9-0.7,1.9-0.7c0.4,0,0.4-0.4,0.8-0.4
                  c0.2,0,0.6-0.1,0.7-0.4l1-0.1c0,0,0.1,0,0.1-0.1c0,0-0.4-0.2-0.5-0.2c-0.7,0-1.4-0.2-2.1-0.1c-0.2,0-0.3,0.2-0.2,0.5l0,0.1l-1,0.1
                  c0-0.2-0.2-0.4-0.4-0.4l-0.1,0l-0.5-0.3C99.3,192,97.7,192.5,97.7,192.7z M97.2,192.7l-0.1-0.6l-0.6,0.1
                  C96.6,192.8,96.7,192.8,97.2,192.7z M100.5,192.7c0,0.3-0.7,0.6-1,0.6c-0.1,0-0.1-0.4-0.1-0.5L100.5,192.7z M102.4,167.9
                  c-0.7,0.7-2.3,0.2-2.4,1.4l1.4-0.1c0.6-0.3,1.4-0.8,2-0.9l0.6-0.4c0.3,0,0.4,0.3,0.4,0.5l0,0.1l-0.6,0.2l0.2,0.4l0.5-0.1l0.2-0.3
                  l1.9-0.8c-0.2-0.1-0.8-0.4-0.8-0.4c0-0.1,0.4,0,0.3-0.3c0-0.1-0.3-0.3-0.4-0.4C104.7,167.2,103.5,167.8,102.4,167.9z M104.3,191.3
                  C104.3,191.4,104.3,191.4,104.3,191.3l0.2,0.1l0-0.1c0-0.1-0.2-0.1-0.2-0.1L104.3,191.3z M106.4,147.6c0,0.3,0.1,0.6,0.4,0.5
                  l0.5-0.1l0.2-0.6L106.4,147.6z M107.2,191c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1
                  C107.1,190.8,107.1,191,107.2,191z M107.7,185.5l0.1,0c0-0.1-0.2-0.2-0.3-0.2C107.5,185.4,107.5,185.5,107.7,185.5z M108,190.1
                  l-0.2,0l-0.1,0.5c0.4,0,0.7-0.4,1.1-0.4c0.2,0,0.5-0.1,0.7-0.1c0-0.1-1-0.3-1.1-0.3C108.4,189.9,108.3,190.1,108,190.1z
                  M108.1,166.5c0.1,0,0.3-0.1,0.3-0.2l-0.5,0.1L108.1,166.5z M108.3,184.6c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0
                  C108.1,184.5,108.2,184.6,108.3,184.6z M108.1,165.7c0,0.2,0.4,0,0.6-0.1L108.1,165.7L108.1,165.7z M109.4,147.1
                  c0.1,0,0.4-0.1,0.4-0.3l-0.9,0.1L109.4,147.1z M109,165.9c0,0.2,0.4,0,0.5-0.1L109,165.9L109,165.9z M109.7,165.4
                  c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1l-0.1,0C109.5,165.2,109.6,165.4,109.7,165.4z M109.8,189c0,0.1,0.2,0.2,0.3,0.2
                  c0-0.2-0.1-0.4-0.3-0.6L109.8,189z M110.1,146.6c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C110,146.5,110,146.6,110.1,146.6z M110.5,189.1
                  l0.8-0.1c0.2,0.2,0.5,0.2,0.8,0.2l0.4,0l0.3-0.3l0.6-0.1l0.1-0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3
                  C112.8,188.1,110.4,188.3,110.5,189.1z M117.3,144.2c-0.1-0.1-0.2-0.3-0.4-0.3c-0.1,0-0.1,0.1-0.1,0.2c-0.9-0.1-0.3,0.8-1.1,0.8
                  c-1.6,0.2-1.4,0.7-3.3,0.8c0.1,0.6-1.9,0.2-1.8,0.7c0,0.1,0.2,0.1,0.3,0.1c0.7-0.1,1.2,0.2,1.9,0.1l2-0.2c0.1,0,0.3,0,0.3-0.2
                  c0-0.3-0.8,0.1-0.8-0.3c0-0.3,1.3-0.1,1.5-0.6l2-0.2c0-0.2,0.2-0.4,0.4-0.3l-0.5-0.2l-0.5,0.1L117.3,144.2z M110.8,179.6
                  c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1C110.7,179.4,110.8,179.6,110.8,179.6z M111.6,165.6
                  c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0C111.5,165.4,111.5,165.6,111.6,165.6z M113.4,182.8l0.3,0l-0.1-0.6l-0.6,0.1
                  C113.1,182.6,113.1,182.9,113.4,182.8z M114.2,184c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C114.1,183.8,114.1,184,114.2,184z M114.5,181.9
                  c0.6-0.1,1,0.1,1.4,0.1l1-0.1c0.1,0,0.3-0.1,0.3-0.2l-0.1-0.3c-0.7,0.1-1.6,0.1-2.4-0.1C114.8,181.6,114.5,181.6,114.5,181.9z
                  M115.2,164.6c0.1,0,0.3-0.1,0.4-0.2l-0.6-0.1C115,164.5,115.1,164.6,115.2,164.6z M115.8,183.6l0.2-0.1c0-0.2-0.2-0.3-0.4-0.3
                  l-0.1,0C115.5,183.4,115.6,183.6,115.8,183.6z M116.3,183.1l0.9,0.4l0.2-0.5L116.3,183.1z M120.7,141.2c0,0,0.3,0,0.3-0.1
                  c0-0.1-0.8-0.2-0.9-0.2c-0.1,0-0.3,0-0.3,0.2C120.1,141.1,120.4,141.3,120.7,141.2z M120.5,182.5c0.1,0,0.4-0.1,0.3-0.3l-0.7,0.1
                  L120.5,182.5z M122.1,140.1L122.1,140.1l0.3,0.4c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4-0.2,0.6-0.4c0.4,0,0.6-0.3,1-0.3
                  c0.9-0.1,1.1,0.5,2-0.6c0-0.2-0.6-0.2-0.8-0.1c0,0.1,0,0.4-0.2,0.4c-0.3,0-0.5-0.2-0.7-0.3l-2.2,0.2
                  C122.4,139.8,122.3,140.1,122.1,140.1z M125.1,144.2c0.2,0,0.6-0.1,0.6,0.2l-0.7,0.1c-0.1,0-0.4,0-0.4-0.3L125.1,144.2z
                  M125.2,137.4c0,0.1,0.2,0.1,0.2,0.1l0-0.1c0-0.1,0-0.2-0.1-0.2C125.3,137.1,125.2,137.3,125.2,137.4z M126.5,179.5
                  c0,0.2-0.3,0.2-0.3,0.3c0,0-0.4,0-0.4,0c0-0.1,0.2-0.3,0.2-0.5C126.1,179.1,126.5,179.2,126.5,179.5z M125.8,137.3
                  c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.5-0.3,0.6-0.4l-0.8,0.1L125.8,137.3z M126.5,139.7c0,0.1,0.2,0.1,0.3,0.1l-0.3-0.6L126.5,139.7z
                  M130.7,184.7c0.1,0.5-1.7,0.4-1.6,0.8c0,0.1-0.2,0.2-0.3,0.2c-0.4,0-0.9,0-1.3-0.1l0-0.1c0-0.2,1.6-0.2,1.6-0.7L130.7,184.7z
                  M127.5,139.7l0.6,0c0-0.4,0.3-0.2,0.2-0.5l-0.5,0C127.8,139.5,127.5,139.3,127.5,139.7z M128.3,137.8l-0.6,0
                  C127.7,138.1,128.2,137.9,128.3,137.8z M128.4,183.7c0.1,0,0.3-0.1,0.4-0.2l-0.5,0C128.2,183.6,128.3,183.7,128.4,183.7z
                  M130.2,183.3c0.2,0,0.4-0.1,0.4-0.3l-0.8,0.1L130.2,183.3z M137.6,183.5c0,0.1-0.1,0.1-0.2,0.1c-0.6,0.1-1.8,0-2.2,0.6
                  c-0.8,0.1-1.6,0.5-2.2,0.5l-0.3-0.2c0.1,0.5-0.8-0.1-1-0.2c0-0.2,0.1-0.5,0.3-0.5l0.1,0l0.2,0.3l1.2-0.1l0.5-0.3l1.2-0.1
                  c0.1,0,1.8,0,1.7-0.5C137,183.1,137.6,183.2,137.6,183.5z M132.5,137c0.1,0,0.1-0.2,0.1-0.2l-0.3,0
                  C132.3,136.8,132.4,137,132.5,137z M133.8,136.7l-0.9,0C132.9,137,133.6,136.8,133.8,136.7z M135,136.6l-0.7,0
                  C134.3,136.8,134.9,136.6,135,136.6z M146,139.5l0.2,0.3l-0.5,0.3l-0.2-0.6L146,139.5z"/>
               <path d="M178.7,132.6c0.2,0,0.3-0.2,0.3-0.3c0.3,0,0.7,0,0.8,0.2l0.1,0.3l0.7,0l0.6,0.5l-0.9,0.3c0,0.4,0.5,0.3,0.8,0.3
                  c0.4,0,1.1-0.5,1.5,0c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.1-0.2-0.1-0.3l-0.3,0l0.3,0.4l0.8,0.2c-0.2-0.2-0.4-0.4,0-0.4l0.5,0.2
                  l-0.3,0.3c0.2,0.1,0.6,0.2,0.9,0.1c0.3,0.4,0.5,1,1.1,0.9l0.5,0.5l0.3-0.3l0.6,0.5l1.4,0.5l0.9,1.2c0,0.2,0,0.4-0.1,0.7
                  c-0.2-0.1-0.4-0.5-0.7-0.5c0,0,0-0.4-0.1-0.4s-0.1,0.3-0.1,0.4c0.7,0.4,1.2,1.1,1.9,1.4c0,0.4,0.4,0.9,0.6,1.3
                  c-0.1,0.1-0.5,0.6-0.1,0.6c-0.1,0.2,0.1,0.5,0.3,0.5c-0.1,1.1-0.2,1.9-0.1,3l-0.2,0.2c0,0.2,0,0.6-0.2,0.8l0,0.4
                  c-0.8,0.5-1,1.9-1.9,2.5c0.1,0.9-4.1,4.3-5.1,5.5c0,0.1,0,0.3-0.1,0.3c-0.4,0-0.8-0.4-1.1-0.4c-0.1,0-0.2,0.2-0.2,0.3l-1,1
                  l-0.9,0.1c-0.3,0.2-0.5,0.5-0.6,0.8c-0.6,0.3-1.2,0.6-1.7,1.1c-0.1-0.1-0.3-0.2-0.3-0.3s0.2-0.2,0.3-0.2c0.2-0.2,0.3-0.5,0.4-0.8
                  l0.8-0.1l0.5-0.6l-0.3-0.4l-0.6,0.4c-2.1,0.4-1.4,1.2-2,1.3l-0.3,0l-0.8,0.5c0,0.3,0.3,0.4,0.6,0.4l0.1,0c-0.1-0.6,1.1,0,1.3,0.1
                  c0.1,0.6-0.6,1-1.1,1.1l-1.1,0.6c0,0.4,0.5,0.4,0.8,0.4l0.2,0c0,0.4-0.4,0.3-0.6,0.2c0,0.1,0,0.3-0.1,0.3c-0.1,0-0.2-0.2-0.2-0.3
                  l-0.3-0.3c0,0.2-0.3,0.2-0.4,0.3c0,0.4-0.9,0.3-0.9,1c-0.2,0.5-0.5,0.9-0.8,1.3l-0.5-0.5l-0.9-0.3c0-0.7-1.4-0.2-1.4-0.7l0-0.1
                  c-0.6,0.3-1.3,0.6-1.7,1.1c-0.5,0-1.7,0.2-1.7,0.8l-0.8,0.1c0,0.2-0.1,0.3-0.3,0.3l-0.2,0l0.1,0.4c0.3-0.4,0.9-0.2,1.3-0.2
                  c0.3-0.2,0.7-0.4,1-0.4c0.2,0,0.4,0,0.4,0.2c0.1,0,0.3,0,0.3-0.1c0-0.1-0.6-0.4-0.1-0.4l0.8-0.6l0.2,0.3l0.3-0.3
                  c0.5,0,1.4,0,1.9-0.2c-0.8,0.6,0.5,0.3,0.8,0.6c0,0.3-0.7,0.4-0.9,0.5c0.1,0.2,0,0.4-0.3,0.4c-0.4,0-0.7-0.1-0.9,0.4l-0.6,0.3
                  c0,0.3-0.3,0.4-0.5,0.4c-0.1,0.2-0.3,0.8-0.4,0.9c-0.1,0-0.1-0.1-0.1-0.1c0-0.2,0-0.4-0.3-0.3c-0.4,0-0.9,0.3-1.2,0.6
                  c-0.2-0.1-0.5,0.1-0.6,0.4l0.7,0.5c0.4,0,1.4,1.2,1.7,1.5c0,0.3,0.1,0.4,0.4,0.4c0.4,0,0.7,0.1,1.1,0.2c0,0.2,0.1,0.4,0.3,0.5
                  c-0.2,0.2-0.3,0.5-0.4,0.8l0.6-0.6l1.2,1.3c0.1,0.7-0.1,0.7,0.7,0.7c0,0.2,0.1,0.5,0.4,0.6c-0.1,0.1-0.7,0.4-0.7,0.6
                  c0,0.1,0.2,0.1,0.3,0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1-0.5,0.1-0.5l0.5-0.1c0.1,0,0.3,0,0.3,0.2c0.1,0.6-0.1,0.7,0.6,0.7
                  c0,0.1,0.5,0.8,0.6,0.8c0.2,0,0.3,0.1,0.3,0.3c0.1,1,1.1,1.7,2,1.9l0,0.8l0.5,0.4l0.7,1.3l0,0.7l0.4,0.6l0.2,0
                  c0.5-0.1,0.1-0.8,0.4-0.9c0.1,0,0.1,0.1,0.1,0.1l0,0.1c0.2,0.4,0.6,0.8,0.9,0.9l0,1l0.4,0.8l0.5,0.3c0.1,1.1,0.6-0.3,0.8,1.6
                  l0.7,0.7c0.3-0.1,0.6,0,0.6,0.4l0.6,0.5c0.1,0.5,0.3,1,0.6,1.4c0,0.3,0.1,0.5,0.4,0.5c0,0.4,0.2,0.2,0.3,0.5l0,0.1
                  c0.3,0.3,0.7,0.7,0.8,1.1c0,0.4,1.3,1,1.3,1.6l0.5,0.5c-0.1,0.2,0,0.6,0.3,0.6c0.2,0.1,0.3,0.3,0.4,0.6l0.6,0.5
                  c0,0.3-0.1,0.9,0.3,0.9c0.3,0.3,0.7,0.7,0.8,1.1c0.3,0.8,0.8,1.5,1.3,2.2l0.1,0c0,0.1-0.5,0.3-0.6,0.4c-0.5-0.4-0.8-1.1-1.1-1.7
                  c0.2-0.7-0.6-0.6-1-0.6c-0.2-0.2-0.3-0.6-0.3-0.8l-0.6-0.5c0-0.7-0.8-0.4-0.8-1.1l0-0.1c-0.6,0.1-0.7-0.7-1.4-0.7
                  c-0.1,0.1-0.3,0.5-0.4,0.2c-0.2-0.1-0.6-0.4-0.6-0.7l0-0.1l-0.6-0.4l-0.4-0.6c-0.2-0.1-0.3-0.3-0.4-0.6l-0.6-0.4l-0.3-0.9
                  l-0.7-0.6c0-0.2-0.4-0.3-0.5-0.2c-0.6-0.6-1.3-1-1.8-1.6c-0.3,0-0.5-0.3-0.8-0.3c0-0.1,0-0.3-0.2-0.3c-0.2,0-0.3,0-0.3-0.3
                  c-0.3,0-0.4,0.2-0.4,0.5l0,0.1l-0.7-0.2c0-0.4-0.7-0.2-0.7-0.6c-0.1-1.2-0.8-0.1-0.9-0.7l0-0.2c-0.2-0.1-0.6-0.3-0.9-0.3
                  c-0.1,0-0.4,0-0.4,0.2c-0.1,0-0.6,0.3-0.6,0.3c0,0.1,0.2,0.1,0.2,0.1c0.4,0.9,1.6,2,1.7,2.8l0.6,0.5l-0.2,0.5
                  c-0.3,0-0.7-0.6-0.7-0.8c-0.1,0-2-1.3-2.6-1.3c-0.1-0.1-1.5-1.6-1.9-1.6c-0.1,0-0.1,0.2-0.1,0.2l-0.3,0.3l0.3,0.2
                  c0,0.3,0.2,0.8,0.4,1c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.5-0.2,0.5c-0.1,0-0.3-0.2-0.4-0.3c-0.6,0.1-0.7-0.8-1.4-0.8l-0.2-0.7
                  c-0.3-0.4-1.4-1.2-1.4-1.7c-0.2-0.1-0.3-0.3-0.4-0.6l-0.6-0.5c-0.2-0.1-0.2-0.2-0.3-0.3c-0.1-0.7-0.6,0-0.7-0.8l-0.5-0.3l0-0.4
                  c-0.1-0.1-0.4-0.1-0.4-0.3c0-0.1,0.5-0.3,0.6-0.3l0.2-0.3l-0.2-0.3c0-0.1,0-0.1-0.1-0.1c-0.3,0-0.7,0.7-0.8,0.1l0-0.1
                  c-2.3-1-2.1-1.3-3.8-2.9c-0.4,0-0.7-0.7-0.8-1.1c-0.2-0.2-0.7-0.9-0.9-0.9c-0.2,0,0,0.4-0.2,0.4c-0.4,0-2.2-1.3-2.4-1.5l-0.8-0.3
                  c-0.1,0-0.2,0-0.2-0.1c0-0.1,0.1-0.5-0.2-0.4c-0.1,0.2-0.7,0.4-0.7,0.3s0.3-0.3,0.3-0.5c0-0.1-0.8-0.1-0.9-0.2
                  c-1.4,0.6-1.1-0.3-2-0.2c-0.7,0.4-1.4,0.5-1.3,1.4l-0.4-0.2l-0.2,0.2c-0.3,0-1.1,2.7-1.2,2.8l-0.3,0.5l-0.3-0.1
                  c-0.5,0.6-0.7,1.4-1,2c0.1-0.1,0.5-0.6,0.5-0.2c0,0.4-0.2,0.5-0.3,0.8c-0.2,0.5-0.4,0.9-0.7,1.2c0,0.3-0.3,0.4-0.3,0.8l-0.5,0.6
                  L146,183c-0.5,0.6-1.1,1.1-1.7,1.6c0.1,0.7-0.8,0.8-0.7,1.4c-0.1,0.2-0.4,0.7-0.7,0.7c-0.1,0-0.2-0.2-0.2-0.3l0-0.1
                  c0.4,0,0.7-1.1,0.8-1.5c0.1,0,0.2,0,0.2-0.2c0-0.1-0.3-0.3-0.3-0.4c0-0.3,0.2-0.6,0.4-0.8c-0.1-0.7-0.8-1.1-1.4-1
                  c-0.3,0-0.7,0.1-0.6,0.5l-0.6-0.3l0.3,0.9l-1,2c0,0.4-0.2,0.5-0.2,0.9c-0.6,0-0.4,1.1-0.9,1.1c-0.4,0-0.8-0.1-0.8,0.5
                  c-0.2,0.2-0.3,0.5-0.3,0.8c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.1,0.2-0.1,0.3c-0.6,0,0,0.5-0.5,0.6c0,0.6-0.6,0.9-0.5,1.5l-0.2,0.7
                  l-0.5,0.1l-0.3,0.5c0,0.3,0.1,1-0.4,1.1c-0.8,0.1,0,0.6-0.7,0.7l-0.3-0.9l0.8-1.3c-0.1-0.8,0.5-0.9,0.4-1.7c0.4,0,0.4-0.4,0.4-0.7
                  l-0.1-0.4l0.8-1.3l0.2-1.4c0.1,0,0.3-0.1,0.3-0.2c0-0.3-0.2-0.6,0.2-0.6c-0.1-0.2-0.1-0.6,0.2-0.6l-0.2-0.3l-0.5,0.9
                  c0.1,0.7-0.2,0.8-0.8,1.2c0,0.3-0.2,0.6-0.2,0.9c-0.1-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.2,0.7-0.3,0.8c1,0-1.4,2.1-1.4,2.6
                  c-0.6,0.1-0.9,2.6-1.9,2.7l-0.5,1.5c-0.7,0-0.3,0.5-0.6,0.5l-0.1,0l-0.2,0.5c0.1,0.6-0.6,0.2-0.5,0.6c-0.4,0.9-1.8,3.7-2.5,4.1
                  c0,0,0,0.1-0.1,0.2c0,0-0.2-0.6-0.2-0.8l0.5-1.4c0.4-0.3,0.5-1.7,0.9-2.2c0.2-0.5,0.5-3,0.8-3.3c0-0.5,0.3-1,0.3-1.6l0.2-0.6
                  c0-0.4,0.3-0.4,0.2-0.7l0.2-0.5c0-0.3,0-0.6,0.2-0.8l0.5-1.7c0-0.4,0.6-2.6,0.8-2.7c0-0.2,0-0.6,0.2-0.8c0-0.3,0.2-0.5,0.2-0.8
                  l0.5-0.5c0-0.4,0.4-1.1,0.6-1.5c0-0.3,0.2-0.5,0.3-0.8l0.3-0.6c0-0.4-0.1-1.5,0.4-1.5c0-0.1-0.1-0.5-0.3-0.5l-0.1,0l0.3-0.8v-0.7
                  l0.2-0.2c0,0,0-0.3,0-0.3c0.1,0,0.2,0.2,0.3,0.3c0.7-0.1,0.4-1.7,0.4-2c0-0.1,0-0.3-0.2-0.3c-0.1,0-0.3,0.2-0.3,0.3l-0.7-0.3
                  c-0.2,0.2-2.3,0.7-2.8,0.7l-0.7,0.4l-0.7,0c-0.1,0.7-1.8,0.7-1.9,1.1l-1.5,0.1c-0.1-0.1-0.4-0.3-0.4-0.4c0-0.1,1-0.2,1.2-0.3
                  c0.6-0.2,1.3-0.7,2-0.9l-1.1-0.2l-0.4,0.3c-0.4,0-0.7,0.1-0.8-0.4c1-0.3,3.7-1.4,4.1-2.3l1.1-0.1c0-0.4,0.3-0.3,0.2-0.8l1,0
                  l-0.2-0.6c-0.4,0,0.1-0.5,0.2-0.6c-0.1-0.1-0.2-0.2-0.4-0.2c-0.7,0.1-1.9,1-2,0.1l0-0.1l1.1-0.5c0.4,0,1.1-0.5,1.6-0.6
                  c0.7-0.1,0.5-1.2,1-1.2c0.2,0,0.6,0,0.6-0.4c0.3-0.5,0.7-0.9,1.1-1.2c0.5,0,0.5,0.2,0.7-0.3l1.3-0.6c0.4-0.5,0.9-1.6,0.9-2.3
                  c0.8,0.1,1.1-1.2,1.1-1.9c0-0.9,0.6-0.2,0.6-0.6c0-0.2-0.2-0.4-0.3-0.5c0.6-0.2-0.1-1.5,0.4-1.5l0.1,0c0-0.1,0-0.4,0.1-0.4
                  c1.3-0.1,0.2-0.6,1-0.7l-0.1-1.3c0.5-1.5,0.5-3.3,1.8-4.2c-0.4,0-0.4-0.5-0.4-0.8c0.6-0.1,0.5-0.8,0.5-1.2l0.5-0.6
                  c-0.1-0.7,0.8-1.6,0.8-2c0.4-0.3,0-1,0.4-1.4l0.2-0.5c0-0.5,0.3-0.6,0.3-1c0.1,0,0.2,0,0.2-0.1c0-0.1-0.2-0.1-0.2-0.2
                  c-0.3,0.2-0.6,0.4-1,0.3l-0.6-0.3l0.3-0.2c0.1,0,1.6,0.1,1.6-0.2c0-0.1-0.8-0.4-0.9-0.4c0.1,1-1.8-0.1-2-0.3l1.5-0.2l0-0.3
                  c0.3-0.3,0.7-0.4,1.1-0.6c0.1,0,0.2,0,0.2-0.1c0-0.2-0.2-0.4-0.3-0.4c-0.4,0-0.4-0.4-0.9-0.4l-4.8,0.3l0.1-0.3
                  c1.1,0,2.3-0.3,3.3-0.6c-2.2,0-4.6-0.1-6.9,0.1c-0.7,0.1-1.4,0.4-2.1,0.4c0,0.4-0.8,0.4-1.1,0.4c-0.2,0-0.7,0.1-0.8-0.3l1.5-0.7
                  c0.4,0,0.9-0.2,1.4-0.3c0.1,0,1.8,0.1,1.7-0.3c-0.4,0-0.4-0.4-0.8-0.4l-0.5-0.2c0.9-0.1,1.7-0.3,2.5-0.5c0-0.4,1.1-0.3,1.1-0.5
                  c0-0.1-0.2-0.1-0.2-0.1c0.1-0.2-0.1-0.3-0.2-0.3c-1.2,0.1-2.5,0.2-3.7,0.2c0.5-0.1,1-0.3,1.4-0.4c0.4,0,1.5,0,1.5-0.2
                  c0-0.1-0.2-0.3-0.3-0.3l-2.9,0.1c0,0.4-0.3,0.2-0.4,0l-0.4,0c-2.1,0.2-3.9,0.8-6.1,0.8l-0.3,0.3l-5.6,0.3l-0.2,0.3
                  c-0.1,0-0.4,0-0.4-0.2c0-0.4-0.1-0.7,0.3-0.8l0.7,0l0.5-0.3c0.4,0,0.9-0.2,1.3-0.4c0.2-0.3,0.5-0.6,0.9-0.6c0.6-0.1,1.1,0,1.6,0
                  l1.8-0.8c0.1-0.2,0.4-0.2,0.5-0.2c0.2,0,0.7-0.1,0.8-0.4l1-0.1c-0.1-0.3,0.1-0.6,0.5-0.6c1.5-0.1,2.8-0.7,4.3-0.8l0.6-0.3l0.6,0.1
                  c0.4,0,0.6-0.3,0.9-0.5l0.6,0l0.6-0.5l0.7-0.1c1.2-0.1,1.9-0.4,3.3-0.5c0.2-0.4,4.6-0.5,5.3-0.5c0-0.2,0.1-0.2,0.3-0.3
                  c0.5-0.1,1.1-0.4,1.2-0.4c0.4,0,0.7,0.3,1.1,0.3c0.1,0,0.2-0.2,0.2-0.3l3.6-0.4c0.8-0.1,5.4-0.6,5.5,0c1.6-0.1,3.6,0.2,5.2,0
                  c0,0.4,0.5,0.3,0.8,0.3c0.2,0,0.7-0.1,0.8,0.1l1.9,0l0.2,0.3l0.7-0.1c0.2,0.1,0.3,0.3,0.5,0.2s0.1-0.2,0.1-0.3l0.7,0l0.3-0.3
                  c0.5,0.3,1.2,0.5,1.7,0.5l0.6,0.6c0.4,0,0.8,0.1,1.1,0.1l0.9,0.5c-0.1-0.6,0.4,0,0.5,0.1c0.7-0.1,1.2,0.5,2,0.4
                  C178.4,132.5,178.5,132.7,178.7,132.6z M123.9,176.6c0.4,0,0.5,0.2,0.5,0.5l-0.7,0.1l-0.3-0.6L123.9,176.6z M129.5,174.2
                  c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0C129.2,174,129.3,174.2,129.5,174.2z M132.3,191.7l0.2-0.1
                  c0-0.2-0.2-0.3-0.4-0.3l-0.2,0C131.9,191.6,132,191.8,132.3,191.7z M134.2,170.1l-0.8-0.1C133.4,170.3,134,170.2,134.2,170.1z
                  M134.7,194.5c0,0.4-0.3,1.1-0.7,1.1c-0.1,0-0.2-0.1-0.2-0.1c0-0.4,0.4-0.9,0.6-1.2C134.6,194.2,134.7,194.3,134.7,194.5z
                  M134.5,187.9c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0C134.3,187.8,134.4,187.9,134.5,187.9z M134.5,169.7
                  c0,0.1,0.1,0.1,0.2,0.1l0.1,0l0-0.1C134.7,169.6,134.6,169.7,134.5,169.7L134.5,169.7z M136.3,182.8c0,0.3,0.3,0.3,0.5,0.3
                  l0.5-1.1c0.2,0.1,0.2-0.4,0.2-0.5c0,0,0-0.3-0.1-0.3l0,0.2C136.9,181.4,136.3,182.4,136.3,182.8z M137.8,141.1l-0.1,0.3
                  c-0.3,0-1,0-1-0.3L137.8,141.1z M137.6,133.6c0.1,0,0.1-0.2,0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1
                  C137.4,133.4,137.5,133.6,137.6,133.6z M137.7,182.6c0,0.1,0.2,0.2,0.4,0.2l0.2-0.5l-0.4-0.5C137.9,182.1,137.7,182.3,137.7,182.6
                  z M138.7,179.4l-0.9,0.3c0,0.3,0.3,0.3,0.5,0.3l0.1,0c0-0.1,0-0.3,0.2-0.3c0.1,0,0.3,0.1,0.4,0.3c-0.1-0.2-0.2-0.8,0.1-0.8
                  l0.4-0.9l-0.6,0.3C138.9,178.8,138.7,179,138.7,179.4z M138.7,140.5c0.2,0,0.4,0,0.5,0.3l0,0.1l-0.5,0.1c-0.3,0-0.6,0.1-0.6-0.3
                  L138.7,140.5z M139.1,177.9c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.2-0.1-0.2-0.1l-0.1,0C138.8,177.8,138.9,177.9,139.1,177.9z
                  M139.7,177.2c0,0.1,0.2,0.1,0.3,0.1l-0.3-0.5L139.7,177.2z M141.3,182.4c0.1,0,0.1-0.1,0.1-0.2l-0.3-0.1
                  C141,182.2,141.1,182.4,141.3,182.4z M141.1,172c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.2,0.2-0.3c0-0.1-0.1-0.1-0.1-0.1L141.1,172
                  L141.1,172z M142.7,181.2l-0.1-0.5l-0.5,0.1C142.2,181.2,142.3,181.1,142.7,181.2z M142.4,136.3c0,0.2,0.1,0.4,0.4,0.4
                  c0.4,0,0.9-0.2,1.4-0.3l1.1-0.2c0-0.1,0.4-0.1,0.4-0.2l-0.1,0L142.4,136.3L142.4,136.3z M143.2,178.9c0,0.2,0.2,0.3,0.3,0.3
                  l0.3-0.1c0.2-0.5,0.4-1.1,0.6-1.8l-0.5-0.2C143.7,177.5,143.2,178.5,143.2,178.9z M145.9,178.8l-1.3,1.4l0.1,0.5l0.6,0
                  c0.3-0.6,1.5-1.7,1.4-2.4c0-0.4-0.4-0.6-0.7-0.7L145.9,178.8z M145.3,173.6c0,0.7-0.4,0.3-0.3,1.4c0,0.4,0.3,0.3,0.6,0.3l0.1-0.8
                  l0.4-0.7l-0.3-0.8c0.1,0,0.4,0,0.4-0.3c0-0.1-0.2-0.3-0.3-0.3c-0.2,0-0.2,0.5-0.2,0.5L145.3,173.6z M146.2,181.3
                  c-0.3,0-0.3,0-0.3,0.3C146,181.6,146.1,181.4,146.2,181.3z M145.9,175.8c0.1,0.5,0.4,0.6,0.9,0.6c-0.1-0.5-0.5-0.8-0.9-1
                  L145.9,175.8z M146.7,180.1l0.2,0c0-0.1-0.2-0.2-0.3-0.2C146.7,180,146.7,180.1,146.7,180.1z M148.8,176.2c0,0,0,0.1,0.1,0.1
                  c0.1,0,0.1-0.2,0.1-0.2s0-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1L148.8,176.2z M152.7,157.8l0.1,0.6l0.5-0.1l0.5-0.6l-0.6-0.3
                  C153.1,157.6,152.9,157.8,152.7,157.8z M155.3,154.6l-0.8-0.1c0,0.4-0.2,0.6-0.3,1l-0.6,0.4c0,0.2,0.5,0.2,0.6,0.2
                  c0.2,0,0.6-0.1,0.8-0.2l0.2-0.9l0.5-0.7c0.2,0,0.3-0.3,0.2-0.5l-0.6,0.1C155.3,154,155.3,154.4,155.3,154.6z M157.8,148.6l0.6-0.8
                  c-0.1-0.6,0.5-0.7,0.5-1.4l-0.3-0.5c-0.7,0.1-0.4,1.1-1.1,1.7c0.1,1.2-1.8,1.8-1.6,4.1c-0.1,0.1-0.3,0.3-0.2,0.5
                  c0,0.1,0.2,0.1,0.3,0.1l0.5-0.6c-0.1-0.2-0.1-0.7,0.2-0.7l0.5-0.6c0.7,0.1,0.6-0.4,1.2-0.5l0.1,0l-0.1,0.8
                  c-1.2,0.1-0.2,0.7-0.8,1.1c0,0.1,0.1,0.6-0.1,0.7l-0.2-0.1c-0.2,0.5-0.1,1.1-0.8,1.2l-0.2,0.6c0.2,0.1,0.4,0.3,0.4,0.4
                  c0,0.1-0.2,0.2-0.3,0.2c-0.1,0.2,0,0.9-0.3,0.9c-0.3,0-0.4,0.1-0.4,0.4c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.1,0.2,0.1,0.3,0.1
                  c0.6-0.1,2.1-0.9,2.6-1.1l0.5-0.5c0.2,0,0.4-0.1,0.5-0.3c0.3-0.2,0.6-0.4,1-0.6c0.5-0.1,0.5-0.1,0.5-0.6l0.9,0.1l0.1-0.2
                  c0-0.3-0.3-0.3,0.3-0.3c0.1-0.2,0.4-0.2,0.5-0.2l0.1,0l0.5-0.5l1.1-0.3l0-0.6l0.5-0.1c0-0.1,0.1-0.2,0.2-0.2l0.1,0
                  c0.5-0.4,1.5-1,2.1-1l0.7-0.5c0.1-0.2,0.3-0.3,0.5-0.3c0-0.4,0.2-0.5,0.5-0.4l0.6-0.3c0.5,0.1,0.1-0.5,0.5-0.5
                  c0.5-0.1,1.5-0.3,1.5-0.9c0-0.1-0.1-0.4,0.2-0.4l0.1-0.2l0.7,0l0.9-1.5c-0.1-0.1-0.4-0.8-0.5-0.8c-0.1,0-0.1,0.2-0.1,0.2l-0.9-0.1
                  c-0.1-1-1.5-0.2-1.6-1.1l0-0.1c-2.9,0.1-5.5-0.7-8.6-0.4c-0.4,0-0.5,0.4-0.4,0.8c-0.2,0-0.3,0.2-0.2,0.5c0,0.3,0.1,0.5-0.4,0.6
                  l-0.1,0c-0.1,0.6-0.3,1.1-0.4,1.7l-0.6,0.7l0.1,0.5l-0.2,0.5c-0.8-0.7-0.6,0.8-0.5,1.2l-1.2,0.1
                  C157.8,149.2,157.9,148.8,157.8,148.6z M155.8,153.4c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
                  L155.8,153.4L155.8,153.4z M158.5,144.2l-0.2,0.5c0,0.1,0.2,0.4,0.3,0.3s0.3-0.2,0.3-0.4l0.5-1.2c0.1-0.1,0.5-0.3,0.5-0.4
                  c0-0.1-0.6-0.1-0.7-0.1C159.1,143.7,158.5,143.7,158.5,144.2z M164.5,180.3c-0.6,0.1-0.7-0.2-0.7-0.7L164.5,180.3z M165.1,178.4
                  c0.1,0.6,0.3,0.5,0.8,0.4l-0.8-0.7L165.1,178.4z M166.4,166.6c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.1
                  C166.2,166.4,166.2,166.7,166.4,166.6z M167.8,131.4c0-0.4-0.9-0.7-1.2-0.7C166.6,131.6,167.1,131.5,167.8,131.4z M167.9,176.5
                  l-0.6,0C167.3,176.8,167.7,176.6,167.9,176.5z M168.5,177.8c0,0.2,0.3,0.1,0.5,0.1l0.1-0.6l-0.5,0.1
                  C168.6,177.6,168.5,177.6,168.5,177.8z M170,132.5c0.1,0,0.1-0.1,0.1-0.2l-0.5-0.2C169.6,132.1,169.8,132.5,170,132.5z
                  M170.8,179.7c0,0.1,0.2,0.6,0.3,0.6c0.1,0,0.1-0.5,0.1-0.5c-0.5-0.3-1-0.6-1.4-1l0.2,0.2C170,179.6,170.3,179.8,170.8,179.7z
                  M170,157.8c0,0.1,0.2,0.3,0.3,0.3c0.4,0,0.6-0.4,0.9-0.7l-0.5,0L170,157.8z M172,131c0.1,0,0.1,0,0.1-0.1c0-0.1-0.8-0.3-1.1-0.3
                  c-0.1,0-0.1,0.1-0.1,0.2C171.2,130.9,171.6,131,172,131z M174.6,158.8c-0.2,0.1-0.2-0.3-0.3-0.3c-0.2,0-2.3,1.5-2.4,1.6
                  c0,0.2-0.3,0.3-0.4,0.3l-0.3,0.3l0,0.1c0,0.2,0.2,0.2,0.3,0.2c0.3,0,0.6-0.2,0.8-0.4c0.4,0,0.4-0.6,0.8-0.6l0.1,0
                  c0.2-0.4,0.6-0.6,1-0.7L174.6,158.8z M171.4,161.3c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2L171.4,161.3L171.4,161.3z
                  M172.7,182.5l-0.3-0.2c0-0.1,0-0.4-0.1-0.4c-0.1,0-0.1,0.2-0.1,0.3c0,0.1,0.1,0.3,0.2,0.3L172.7,182.5z M173.2,183.7
                  c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C173.1,183.5,173.2,183.8,173.2,183.7z M173.5,173.6l0.1,0c0-0.1-0.2-0.2-0.3-0.2
                  C173.3,173.5,173.3,173.6,173.5,173.6z M173.9,184.1L173.9,184.1c0.1-0.1,0-0.1-0.1-0.1l-0.1,0
                  C173.7,184.1,173.8,184.1,173.9,184.1z M178.3,152.5l0,0.1c-0.2-0.1-0.5,0.1-0.7,0.3c-0.2,0-0.5,0.1-0.6,0.3
                  c-0.3,0.3-1,1.2-1.6,1.3c-0.1,0.2-0.3,0.2-0.4,0.3l-0.1,0l-0.7,0.8l0.1-0.1l0.1,0l1.1-0.6c0.5-0.3,0.9-0.4,1.3-0.7l0.3-0.6
                  c0.2,0.1,0.6,0.2,0.6-0.2l0.9-0.8L178.3,152.5z M174.3,174.6c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
                  L174.3,174.6L174.3,174.6z M174.4,159.3l0,0.3c0.1,0,0.2-0.2,0.2-0.3L174.4,159.3z M175.1,159.1c0.1,0,0.1-0.2,0.1-0.2l-0.3,0
                  C174.9,158.9,175,159.1,175.1,159.1z M176.3,158.6c0.1,0,0.2,0,0.3-0.1l-0.5-0.1C176.1,158.6,176.2,158.7,176.3,158.6z
                  M176.9,151.9c0,0.2,0.4,0,0.5-0.1L176.9,151.9L176.9,151.9z M178.4,151.2c0.1,0,0.1-0.2,0.1-0.2l-0.3,0
                  C178.2,151,178.3,151.2,178.4,151.2z M178.3,180.7l0.2,0l0-0.1c0-0.1-0.1-0.1-0.2-0.1C178.4,180.6,178.3,180.7,178.3,180.7z
                  M180,187l-0.3,0.5l-0.8-0.4c0-0.5,0.1-0.6,0.6-0.6L180,187z M181.7,149.2c-0.6,0.1-0.5,0.6-1,0.6c0.1,0.9-0.6,1-1.3,1.2l-0.2,0.6
                  l-0.3,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2c0.4,0,0.5-0.6,0.8-0.6l0.1,0c0.5-0.5,1.1-1.2,1.4-1.8l0.6-0.4L181.7,149.2
                  z M179.1,150.5c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1C179,150.4,179.1,150.5,179.1,150.5z M179.7,154.8
                  c0.1,0,0.1-0.2,0-0.3c0,0,0-0.1-0.1-0.1l-0.2,0.1C179.5,154.7,179.6,154.8,179.7,154.8z M180.1,155.2l0.6,0c0-0.4,0.3-0.2,0.2-0.5
                  l-0.6,0.1C180.4,155.2,180.1,154.9,180.1,155.2z M180.9,181.8c0-0.2-0.1-0.6,0.1-0.8c0.2,0,0.5,0.1,0.7,0.1l0.1,0.6L180.9,181.8z
                  M181,154.3c0,0.1,0,0.2,0.1,0.2c0.1,0,0.1-0.2,0.1-0.2L181,154.3L181,154.3z M183.7,151.2c0.2,0.8,0.3,1.1-0.5,1.2
                  c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2-0.3-0.3-0.3l-0.6-0.5l-0.3,0.3l0.5,0.7L182,153l-0.3-0.3l-0.2,0.3c-0.4,0-0.5,0.1-0.5,0.5
                  c0,0,0,0.1,0.1,0.1c0.1,0,0.2-0.2,0.3-0.3l0.5-0.2c0,0.4-0.3,0.4-0.2,0.8l0.6-0.3c0.4,0,0.8,0.1,0.8-0.4c0.5-0.8,1.2-1.6,1.8-2.2
                  c-0.1,0-0.5-0.3-0.2-0.4l0.1,0l1.5-0.8c0.5-0.9,1.3-1.8,2-2.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.3,0.2-0.3l0.5-0.6
                  c0.1,0,0.2,0.1,0.2-0.1c0-0.1-0.1-0.3,0-0.3l0.1,0c0.1-0.1,0.1-0.2,0.1-0.2c0-0.2-0.3-0.4-0.4-0.5c0-0.1,0-0.1-0.1-0.1
                  c-0.3,0-0.5,1.3-0.6,1.4c-0.2,0.5-0.8,1.2-1.3,1.5c-0.6,0-0.2,0.5-0.7,0.6c-0.7,0.5-1.4,1.6-1.8,2.3
                  C184.4,150.5,184,150.9,183.7,151.2z M182.5,149c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C182.4,148.8,182.5,149,182.5,149z M185,136.4
                  c0,0.1,0,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1l0-0.1c0-0.1-0.2-0.1-0.2-0.1L185,136.4L185,136.4z M185.7,191.5c0.1,0,0.1-0.1,0.1-0.2
                  c0-0.1-0.2-0.1-0.3-0.1C185.4,191.3,185.5,191.5,185.7,191.5z M187.6,138.8c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2
                  L187.6,138.8L187.6,138.8z"/>
               <path d="M226.7,119.7v0.7l-0.3,0.3c0,0.4-0.2,1-0.2,1.4l-0.2,0.5c0,0.4-0.3,0.8-0.3,1.1c0,0.1,0.2,0.3,0.3,0.3l0.6-0.7l0.2-0.6
                  c-0.1-0.5-0.1-0.6,0.4-0.6c0.3,0,0.5,0.2,0.5,0.5c0,0.4,0.1,1-0.3,1.2c0.1,0.3,0.2,0.9-0.3,1l0,0.9c0,0.4,0.4,0.6,0.8,0.6l0.6-0.4
                  c0,0.4,0.8,0.3,1,0.3c0.4,0.1,0.9,0.7,1.3,0.6c0.2,0,0.2-0.2,0.2-0.3c0.8,0.1,2.4,0.8,3.2,0.9c0,0.6,2,0.8,2.5,0.9
                  c0.5-0.1,2,0.9,2.5,0.9l1,0.9c0.6,0.1,1.3,0.5,1.7,0.9c0.6,0.1,0.9,0.6,1.5,0.5l0.1,0c0,0.3,0.3,0.4,0.6,0.4l0.1,0l0.6,0.5
                  c0.5,0,0.9,0.6,1.3,0.9c-0.1,0.2,0.2,0.4,0.3,0.3l0.3,0.3l0.6,0.2c0.4,0.3,0.7,0.8,1,1.2c0,0.1,0,0.2-0.1,0.2
                  c-0.3,0-0.9-0.7-0.9-0.9l0-0.1l-0.6-0.4l-0.1,0c0,0-0.1,0-0.1,0.1c0,0.1,0.2,0.3,0.4,0.3l0.4,0.6l0.2,0.9l0.6,0.5l0.2,0.8
                  c0.5-0.1,0.8,0.6,0.8,1c0.1,1.2,1,0.6,1.1,1.2l0,0.1c0.3,0.3,0.7,0.6,0.7,1.1c0.1,0.7,0.2,0.9,0.6,1.4l0.3,4.5
                  c-0.4,0-0.1,1.6-0.5,1.7l-0.1,0c-0.2,0.3-0.4,0.8-0.4,1.2c0,0.1,0.1,0.2,0.2,0.2l0.1,0c0.3,0,0.1-1,0.5-1l0.1,0
                  c0,0.8-0.7,2.8-0.6,3.5c0,0.1,0.1,0.6-0.2,0.6l-0.1,0l0,1.8c-0.7,0.8-0.9,1.8-1.7,2.6c0,0.4-0.2,0.5-0.2,0.6l0,0.1
                  c-0.1,0-0.6,0.3-0.6,0.4s0.2,0.1,0.2,0.1l-0.6,1.5c-0.3,0.3-0.7,0.7-0.7,1.2c0,0.1,0,0.3-0.1,0.3c-0.2,0-0.4,0-0.4,0.3
                  c0.1,0.1,0.5,0.3,0.2,0.4l-0.1,0l-1,0.8c0,0.4-0.2,1.3-0.8,1.3l-0.1,0c-0.2,0.2-0.5,0.5-0.8,0.5l-0.1,0l-0.5,0.5
                  c0,0.6-1.2,0.7-1.2,1.4l-0.5,0.3c0,0.3-0.1,0.7-0.4,0.7l-0.4,0l-0.9,1.2c-0.1,0.2-0.3,0.4-0.5,0.4c-0.1,0.2-0.3,0.3-0.5,0.3
                  l-0.1,0l-1.1,1.1c-0.2,0-0.5,0.1-0.6,0.4l-0.2-0.3l-0.3,0.3c-0.3,0-0.5,0.2-0.5,0.4c-0.5,0.1-0.6,0.4-1,0.4c0,0.3-0.2,0.4-0.5,0.4
                  c-0.1,0-0.4,0-0.4,0.1c0,0.1,0.2,0.1,0.2,0.2c-0.1,0.7-0.9,0.8-0.8,1.2c-0.2-0.1-0.4-0.3-0.4-0.5l0.1-0.2c0.2,0.2,0.4,0,0.4-0.2
                  l-0.2-0.3l-0.7,0.2l-0.2,0.9c0.1,0,0.4,0,0.5,0.2c0,0.3-0.5,0.4-0.7,0.4l-0.1,0c0-0.3-0.1-0.7-0.4-0.6l0.1,0.7
                  c-0.1,0.2-0.3,0.4-0.4,0.4c-0.1,0-0.1-0.1-0.1-0.2c0,0,0-0.2-0.1-0.2c-0.1,0-0.3,0.2-0.3,0.3l-0.6-0.6l-0.1,0
                  c-0.3,0-0.5,0.3-0.9,0.4l-0.4,0.6c0.2,0,1.3-0.3,1.3,0c0,0.3-0.5,0.7-0.7,0.8l-0.8-0.1l-0.3-0.2l-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2
                  c0,0.3-0.2,0.6-0.4,0.7c-0.3,0-0.5-0.1-0.8-0.2c0-0.2-0.3-0.3-0.5-0.2l-0.6,0.6l-0.5-0.5c-0.3,0.2-0.9,1.1-1.4,1.2
                  c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.2-0.2-0.2c-0.2,0-0.4,0.3-0.4,0.5c0.1,0.1,0.6,0.2,0.6,0.4l-0.3,0c0,0.2-0.1,0.4-0.3,0.4
                  c-0.3,0-0.7-0.1-0.9,0.2c-0.1,0-0.3,0-0.3,0.2l0,0.1c-0.3-0.2-0.6-0.5-1.1-0.4c-0.1,0-0.3,0.2-0.5,0.2l-0.1,1
                  c-0.4,0.2-0.8,0.4-1.1,0.4c-0.5,0.1-0.5-0.4-0.5-0.8l-0.3-0.3c-0.1,0.2-0.2,0.5-0.4,0.7c0.2,0.1,0.9,0.5,0.9,0.7
                  c0,0.1-0.2,0.2-0.3,0.2l-2,0.3c0,0.2-0.3,0.2-0.4,0.2c-0.2,0-0.5,0.3-0.8,0.4l0.2-0.5c-0.2-0.1-0.3-0.3-0.5-0.3
                  c-0.1,0-0.1,0.2-0.1,0.2c0,0.3,0.2,1-0.2,1.1c-0.3-0.1-0.6-0.3-0.6,0.2c0,0-0.3,0-0.3,0c0,0.1,0.4,0.2,0.5,0.2
                  c-0.2,0.5-0.5,0.9-0.8,1.3c-0.7,0.1-1.5,0.2-2.3,0.3c-0.2,0-0.4,0-0.4,0.3c-0.1,0-0.4,0.1-0.4,0.3c-0.4,0-2.4-0.1-2.3,0.3
                  c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0-0.5,0.2-0.6,0.4c-0.2-0.1-0.4,0-0.5,0.2c-0.3,0-0.5,0.1-0.7,0.4l-0.7-0.1l-0.2,0.3
                  c-0.4,0-0.6-0.6-0.7,0.1c-0.1,0.2,0,0.4-0.2,0.6c-1.4-0.4-1,0.8-1.2,0.9c-0.1,0-0.3-0.2-0.4-0.3c-0.1,0-0.3,0-0.3-0.1
                  c0-0.1,0.2-0.3,0.3-0.4c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.2-0.3-0.3l-0.2-0.4l-0.4-0.3c0-0.1,0.1-0.2-0.1-0.2
                  c-0.2,0-0.6,0.7-0.7,0.9c0.1,0.7,0,1.2-0.8,1.3l-0.7,0.1l-0.4,0.7c-0.4,0-0.4,0-0.6,0.3c-0.1-0.2-0.2-0.4-0.2-0.6
                  c0-0.2,0.1-0.4,0.3-0.4c0-0.1-0.2-0.9,0.2-0.9c0-0.1,0-0.2-0.2-0.2c-0.1,0-0.3,0.3-0.3,0.4c0,0.4-0.2,0.5-0.2,0.9
                  c-0.3,0.3-1.5,1.9-1.7,2.4c0,0.4-0.3,0.4-0.2,0.8l-0.6,0.4c0-0.4,0.6-1.5,0.6-1.8c0-0.1,0-0.3-0.2-0.3c-0.1,0-0.1,0.6-0.1,0.7
                  c-0.6,0-0.9,0.6-0.8,1.1l-0.6,1.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.4-0.4,0.4-0.8,0.5l0.2-0.5l0-1.1c0.2,0.1,0.5-0.1,0.6-0.4l-0.3-0.6
                  c0.5,0,1-2,0.9-2.5c0-0.2-0.2-0.4-0.4-0.3c-0.4,0-0.3,0.4-0.8,0.4c-0.3,0-0.7,0-1-0.1c-0.2,0.1-2.2,0.9-2.3,1l-0.9-0.1l-0.5,0.4
                  c0.1,0.4-0.5,0.4-0.8,0.4c0,0.2-0.2,0.2-0.3,0.3c-0.4,0-0.9,0-1.3-0.1c-1.1,0.3-2.3,0.7-3.4,0.8c-0.2,0-0.6,0-0.8-0.1
                  c-0.1,0.2-0.4,0.4-0.6,0.4l-2.2,0.2l-0.3,0.3c-0.4,0-1.2-0.6-1.7-0.6c-0.6,0.1-1.4,0.3-2,0.4c0,0.4-0.9,0.2-1.2,0.2l-0.3,0.3
                  c-0.7,0.1-1.7,0.2-2.3-0.1c0-0.1,1.6-0.5,1.9-0.6c0-0.1,0.1-0.2,0.1-0.2c0.2,0,0.4,0,0.3-0.3c-0.6,0.1-1.1-0.1-1.7-0.1
                  c-0.4,0-0.2,0.4-0.8,0.4c-0.1,0.2-0.4,0.2-0.6,0.2c-0.5,0.1-2.8,0.3-2.8,0.7l-1,0c0-0.1,0-0.3-0.2-0.3c-0.1,0-0.2,0.2-0.3,0.3
                  c-0.5,0.1-1.1,0.4-1.8,0.5l-0.5,0.3c-0.2,0-1.6-0.1-1.6,0.2l-1,0l0.3-0.6l0.7,0.2l0.2-0.4c0.3,0,1.5,0.1,1.5-0.3
                  c0.9-0.2,2-0.5,3-0.5l0.1-0.2c1.1,0,2-0.6,3-0.7c0.7-0.1,1.2-0.3,1.9-0.4c0-0.1,0.2-0.2,0.3-0.3c0.2,0,0.4,0,0.6,0l0.7-0.7
                  c0.5,0.1,2.1-0.9,2.7-0.9c1.2-0.1,2.5-0.5,3.6-0.8c0.3-0.4,0.7-0.7,1.2-0.9c0.1-0.2,0.4-0.5,0.7-0.5l0.1,0l0.4-0.3
                  c0.2,0,1.4-0.4,1.3-0.6l0.8,0.1l0.2-0.5l0.7-0.1l0.2-0.3c0.3,0,0.6-0.1,0.8-0.2l0.7,0l0.3-0.3c0.1,0,0.6-0.3,0.6-0.4
                  c0-0.1-0.2-0.1-0.2-0.1c-0.4,0-0.7,0.2-1,0.5c-1.2,0-2,0.6-3,0.7l-0.2-0.1l-0.6,0.3l-0.7,0.1l-0.1,0.4c-0.2,0.1-0.6,0.2-0.8,0.2
                  l-0.9,0.1l-0.2-0.3l-0.2,0.2c0.4,0-0.1,0.2-0.3,0.2c-0.1,0-0.5-0.1-0.5-0.2l0.2-0.7l0.6,0c0.3-0.4,0.7-0.6,1.2-0.6l0.1-0.3l0.7,0
                  c-0.1-0.6,0.9-0.3,1.2-0.6l1.1-0.2l0.5-0.5c0.4,0,0.5-0.3,0.8-0.4c0.2,0,0.7,0,0.6-0.3l0.4-0.2l-0.3-0.3l-0.1,0
                  c-0.1,0-0.2,0.1-0.2,0.2l0,0.1c-0.7-0.1-1.4,0.2-2.1,0.4l0-0.2c-0.1-0.6,2.1-0.9,2.7-1.4l1.2,0l0.1-0.9l-0.4,0.6l-1.2,0.2
                  l-0.1,0.1c0.1,0.1,0,0.2-0.1,0.2c-0.2,0-0.5-0.1-0.7-0.2c-0.4,0.2-0.8,0.2-1.1,0.3c0.1,0.2-0.3,0.4-0.5,0.4l-0.8-0.1l-0.2,0.4
                  c-0.9,0.1-1.4,0.4-2.5,0.4c-0.2,0-0.5,0.1-0.6,0.2l-0.8-0.2c-0.1,0.7-1.8,0.2-2.1,0.2c-0.1,0-1,0.7-1.1,0.3c0-0.1,1-0.5,1.2-0.5
                  c0.4,0,0.9-0.2,1.3-0.2l0.2,0l0.5-0.4c0.7,0,1.7-0.5,2.4-0.5c0.5-0.1,0.8-0.3,1.3-0.4c0-0.1,0.1-0.3,0.3-0.3l0.1,0
                  c0.8-0.6,1.9-1.1,2.8-1.3c0-0.3,0.4-0.3,0.6-0.3c0.2,0,0.5-0.1,0.6-0.3c0.4,0,0.7-0.2,0.7-0.7l-0.2,0.3l-1.6,0l-0.2,0.4l-0.7-0.1
                  l-0.2,0.2l-1,0.1l-0.6,0.4c-0.2-0.1-0.7-0.2-0.7-0.3c0-0.1,0.1-0.1,0.2-0.1l0.2,0l0.1-0.2c0.8-0.1,1.1-0.5,1.7-0.5l0.9-0.5
                  c0.4,0,0.3-0.3,0.6-0.3l0.2,0c0.4-0.4,1.7-0.8,2.3-1c0.5,0,0.9-0.2,1.5-0.2l0.3,0c0.1,0,0.4-0.2,0.3-0.3c0-0.1-0.1-0.1-0.2-0.1
                  l0.4-0.5c0-0.2,0.1-0.4,0.3-0.4l0.2,0.1l0.3,0.3l-0.3-0.3c0-0.5,1-0.2,1.1-0.8c0.1-0.2,0-0.5,0.3-0.5c0.3,0,0.5,0.2,0.8,0.1
                  l0.8-1.3c0.3,0.1,0.4-0.8,0.5-1c0.5-0.1,0.2-1.2,0.7-1.2c0-0.1,0-0.3-0.2-0.3c-0.7,0.1-1.4,1.7-1.6,2c-0.6,0.1-0.2,0.7-0.6,0.7
                  c-0.1,0-0.6,0.1-0.6-0.2c-0.1-0.7,0.7-0.7,0.7-1.4l-0.5-0.2c0-0.3,0.4-0.6,0.6-0.7l0-0.8c0.3,0,0.6-0.2,0.8-0.4
                  c0-0.1,0.1-0.4,0.3-0.4l0.1,0c0-0.2-0.1-0.6,0.2-0.6l0.1,0l0.5-1.3c0.4,0,0.1-0.5,0.6-0.6c0-0.1,0-0.3-0.2-0.3
                  c-0.1,0-0.3,0.2-0.3,0.3l-0.7,0.4c0,0.4-0.3,0.4-0.2,0.8l-0.9,1.2c0,0.1-0.1,0.7-0.3,0.7c-0.1,0-0.2-0.1-0.2-0.1
                  c0-1.2,0.8-1.9,0.8-2.3l0.5-0.3c0.1,0,0.1-0.1,0.1-0.2c0-0.2-0.1-0.5-0.3-0.7c0-0.3,0.3-0.9,0.6-0.9c0.1,0,0.1,0,0.2,0.1
                  c-0.1,0.1-0.3,0.3-0.3,0.5c0,0.1,0.2,0.2,0.3,0.2c0.3-0.9,0.7-3.8,1.1-4.2c-0.1-0.6,0.5-0.9,0.5-1.6c0.1-0.1,0.9-1.9,1.1-1.9
                  c0.1,0,0.1,0.4,0.1,0.4s0,0.1,0.1,0.1c0.1,0,0.5-0.5,0.5-0.6l-0.2-0.6c0,0.3,0,0.7-0.1,0.9c-0.1,0-0.2-0.1-0.2-0.3
                  c-0.1-0.5,0.4-0.9,0.5-1.4c0.1-0.1,0.5-1.4,0.5-1.6l0.6-0.6c0,0,0-0.1,0.1-0.2c0.1,0,0.3,0.4,0.4,0.4c0.1,0,0.1-0.2,0.1-0.2
                  c0-0.2-0.1-0.6-0.2-0.8l0.4-0.8c0-0.1,0-0.4,0.2-0.5l0.3,0l0.1-1c0.6-0.1,0.1-1.6,0.7-1.6c0.3-0.2,0.5-0.4,0.7-0.7
                  c0-0.2,0.1-0.5,0.3-0.5c0-0.5,0.3-0.9,0.2-1.4l0.9-1.3l-0.1-0.9l0.5-0.5c0.1-0.3,0.3-0.8,0.6-1.1c0-0.1,0-0.4,0.2-0.5
                  c0-0.3,0-1.3,0.5-1.4l0.1,0c0-0.9,1-2.2,1.1-3.1l0.6-0.4l-0.9-0.7c-0.1,0-0.5,0.1-0.5-0.2l0-0.1c-0.4,0-0.5,0.3-0.5,0.6l0,0.1
                  l-0.3-0.3c-1.6,0-3.3,0-4.9,0.1c0.1,0.5-1-0.1-1.2,0c-0.1,0-0.2,0.1-0.2,0.2l-0.6,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4-0.1-0.5-0.2
                  c-0.7-0.1-1.2-0.4-1.5,0.2l-0.9,0.1l-0.3-0.3l-0.2,0.2c-1.4-0.2-2.9,0.3-4.3,0.2l-0.3,0.4c-0.1,0-1.6,0.2-1.6-0.1
                  c0-0.1,0.3-0.2,0.4-0.2c0.7-0.1,1-0.5,1.6-0.5l0.2,0l0.1-0.4l0.7,0.1l0.5-0.6c0.6-0.4,1.5-0.7,2.2-0.8l0.3-0.4l1.6-0.5
                  c-0.1-0.7,1.3-0.2,1.3-0.6l0-0.1c0.3,0,0.7-0.1,0.8-0.5l-0.8-0.3c0.1,0.2,0,0.4-0.2,0.4c-0.8,0.1-0.7-0.4-1.4,0.3l-1.8,0l-0.6,0.4
                  c0.2-0.3-0.3-0.3-0.5-0.3l-0.6,0.4c-0.1,0-0.6,0.1-0.6-0.2l0-0.1l1.7-0.4l0.5-0.4c0.2,0,0.6,0,0.8-0.2c0.2,0.1,0.5-0.1,0.7-0.3
                  c0.9-0.1,2.1,0.1,2.1-1.2l-0.6-0.5c-0.1,0-0.8,0.3-0.8,0c0-0.1,0.2-0.4,0.3-0.5c-1.1,0.1-2.7-0.2-3.9-0.2c0.2-0.2-0.4-0.2-0.4-0.2
                  c-0.8,0-2.2-0.3-2.8-0.2c-0.9,0.1-1.8,0-2.7-0.1c0,0.2-0.2,0.4-0.4,0.4c-1,0.1-1.9-0.4-3.1-0.3c-0.2,0-0.4,0.2-0.4,0.4l-0.4-0.7
                  l-0.4,0c0,0.1,0.2,0.3,0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.4,0-0.9-0.3-1.4-0.2l-0.3,0.5c-1,0-1.8,0.6-2.7,0.7c-0.1,0-0.4,0-0.5-0.2
                  c0-0.4,0.8-0.6,1.2-0.6c0.2,0,1.3,0.2,1.3-0.3c0.7-0.8,3.4-1.3,4.4-1.5c0.1-0.1,0.2-0.2,0.3-0.3l0.2,0.1c0.7,0,1.4-0.2,2-0.4
                  c0-0.1,0.2-0.2,0.3-0.3c0.2,0,1,0.1,1-0.2c0.7-0.3,1.9-0.7,2.7-0.6c0.1-0.2,2.5-0.5,3-0.6c2-0.2,4.1-0.3,6.1-0.2l0.5-0.6l5-0.1
                  c1.5,0,3.7-0.4,5.4-0.4c0.3-0.5,1.1-0.3,1.6-0.7c0-0.2,0-0.5,0.2-0.5c0.1,0,0.3,0,0.4,0c0.2-0.1,0.7-0.1,0.7,0.2
                  c0.1,0.5-0.4,0.3-0.4,0.7c0,0.1,0,0.2,0.2,0.2c0.2,0,0.4-0.2,0.6-0.4c0.4,0,0.2-0.8,0.9-0.8c0.3,0,0.6,0,0.8,0.2l2.2-0.1l0.3-0.5
                  c0.3,0,0.5,0.1,0.8,0.2l0.4-0.6l0.6-0.4c0-0.2,0-0.4,0.3-0.4c0.3,0,0.6,0.2,0.8,0.4l0.2,1c0.1,0,0.4,0,0.5,0.2c0,0.1,0,0.3,0,0.4
                  c0.2,0.1,0.5,0.2,0.8,0.2c0.1,0,0.1-0.1,0.1-0.2c0.9-1,1.5-2.5,2.1-3.8c0-0.1,0.1-0.5,0.3-0.5l0.3-0.5c0-0.4,0.2-0.7,0.5-0.9
                  C226.9,119.1,227.2,119.6,226.7,119.7z M178.8,191.3c0,0.1,0.2,0.1,0.3,0.1l0-0.1c0-0.1-0.1-0.2-0.2-0.2L178.8,191.3z M180.3,187
                  c-0.2-0.1-0.2-0.1-0.2-0.3l0.1-0.2l0.6-0.3L180.3,187z M187.9,181.8c0,0.2,0.3,0.3,0.5,0.2c0.2,0,0.4-0.1,0.3-0.3L187.9,181.8
                  L187.9,181.8z M189.3,181.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0L189.3,181.5z M189.7,184.5
                  l0.7,0.1l0.3-0.3l0.1-0.3l-1,0L189.7,184.5z M191.1,133.8l-0.1,0.3c-0.2,0-0.9,0.2-0.9-0.1l0-0.1L191.1,133.8z M190.7,182
                  c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.3-0.1l-0.3,0C190.4,181.8,190.5,182,190.7,182z M193.4,131.7c-0.1,0.2-0.5,0.6-0.8,0.6
                  l-0.1,0l-0.8,0.5l-0.6-0.4c0-0.4,0.3-0.5,0.4-0.8L193.4,131.7z M191.6,183.8c0,0.1,0.1,0.1,0.2,0.1l0.2,0l0-0.1
                  c0-0.2-0.3-0.3-0.5-0.4L191.6,183.8z M192.7,183.4c0,0.2,0.1,0.3,0.3,0.3l-0.4-0.6L192.7,183.4z M192.7,181.1c0,0.1,0,0.2,0.2,0.2
                  c0.1,0,0.1,0,0.1-0.1L192.7,181.1L192.7,181.1z M194.8,131c0.3,0,0.9-0.1,0.8,0.4c-0.7,0-1.3,0.2-2,0.3l-0.3,0
                  C193.8,131.4,194.3,131.1,194.8,131z M194.1,183.2L194.1,183.2c0-0.4-0.3-0.5-0.5-0.5L194.1,183.2z M194,180.9
                  c0.1,0,0.3-0.1,0.4-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.3,0.1-0.4,0.2C193.8,180.8,193.9,180.9,194,180.9z M195.6,179.8
                  c0,0.2,0.1,0.4,0.3,0.4c0-0.1-0.2-0.6-0.3-0.6C195.6,179.7,195.6,179.8,195.6,179.8z M196.9,182c0,0.1,0.2,0.1,0.2,0.1l0.4-0.1
                  c0-0.1-0.2-0.2-0.3-0.2L196.9,182L196.9,182z M197.6,164.9c0.1,0,0.1-0.1,0.1-0.2l0-0.1l-0.3,0
                  C197.4,164.8,197.5,164.9,197.6,164.9z M198,160.8c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1L198,160.8
                  L198,160.8z M198.5,162.2l-0.2,0.3l0.2,0.3l0.3-0.2c-0.2-1.5,0.2-0.9,0.2-1.4l-0.4,0.2C198.2,161.3,198.5,161.9,198.5,162.2z
                  M199.6,159.9c0.4,0,0.4-0.3,0.4-0.6l0-0.1c0.4,0,0.5-1.2,0.5-1.5C199.9,157.8,199.7,159.5,199.6,159.9z M200.1,156.2l0.1,0
                  c0-0.1-0.1-0.3-0.2-0.3L200,156C200,156.1,200,156.2,200.1,156.2z M200.8,156.5c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
                  l-0.1,0C200.6,156.3,200.7,156.5,200.8,156.5z M204.4,146.5c0,0.1,0.2,0.2,0.3,0.2l0.2-0.2c0-0.1-0.1-0.6-0.2-0.6
                  c-0.1,0,0,0.4,0,0.5C204.6,146.4,204.4,146.5,204.4,146.5z M205.5,145c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
                  c-0.1,0-0.1,0.1-0.1,0.1C205.3,144.8,205.4,145,205.5,145z M207.1,181.3c-0.7,0.1-0.9-0.4-0.9,0.6c0.6,0,1.1,0,1.7,0
                  c0.1,0,1.1-0.5,1.1-0.7c0.5,0.1,0.5-0.2,0.9-0.2c0.2,0,0.6,0,0.8-0.2c0-0.1-1.2-0.3-1.4-0.4C209.3,181.2,207.5,181.3,207.1,181.3z
                  M208.6,183.5c-0.5,0.1-0.7,0.4-1.1,0.5c-0.1,0-0.3,0-0.3,0.2c0,0.1,0.2,0.4,0.3,0.4c0.1,0,0.1-0.1,0.1-0.1l0-0.1l1.8-0.8
                  c0.1-0.2,0.2-0.2,0.4-0.2l0.1,0l0.3-0.3c0.1,0,0.3,0,0.3-0.2c0-0.1-0.3-0.3-0.3-0.4C209.6,182.9,209,183.1,208.6,183.5z
                  M210.4,163.9c-0.1,0.2-0.3,0.3-0.3,0.5c0,0.1,0,0.3-0.2,0.3l-0.1,0l-0.6,0.6l0,0.1c0,0.1,0,0.4,0.2,0.5c0.2,0,1.1-1.4,1.4-1.8
                  l0-0.7l0.3-0.4l0-0.1c0-0.1,0-0.3-0.1-0.3c-0.1,0-0.1,0.5-0.1,0.6L210.4,163.9z M210.2,171.6c0.1,0,0.1-0.2,0-0.3
                  c0,0,0-0.1-0.1-0.1l-0.2,0.1C209.9,171.5,210,171.7,210.2,171.6z M210.4,169.6c0,0.1,0.1,0.1,0.2,0.1l0.1,0
                  C210.7,169.6,210.6,169.5,210.4,169.6L210.4,169.6z M211,182.8l0.5-0.3c0-0.1-0.1-0.3-0.2-0.4L211,182.8z M211.8,166l-0.6,0
                  C211.2,166.2,211.6,166,211.8,166z M211.5,168.9c-0.3,0-0.3,0.3-0.3,0.5c0,0.1,0.1,0.4,0.3,0.3c0.1,0,0.1-0.1,0.1-0.2
                  c0.2-0.1,0.3-0.4,0.4-0.6L211.5,168.9z M211.2,165.2c0,0.1,0.2,0.1,0.2,0.1c0,0.3,0.3,0.4,0.6,0.4l0-0.7l-0.5-0.2
                  C211.3,164.8,211.2,165,211.2,165.2z M211.8,159.1c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1s-0.1,0.1-0.1,0.1
                  C211.6,158.9,211.7,159.1,211.8,159.1z M222,139.6l-0.2,0.6c0.5-0.1,0,0.5-0.2,0.6l0,0.4c0,0.1,0.1,0.3,0.2,0.3
                  c0,0.4-0.4,0.6-0.3,1c0,0.1,0.2,0.3,0.3,0.3c0.3,0,0.4-0.9,0.5-1.2l0.3,0.3c0,0.4-0.1,0.8-0.3,1.1c0,0.3-0.2,0.5-0.1,0.9
                  c0,0.1-0.6,1.2-0.6,1.3c-0.5,0-0.2,0.5-0.5,0.6l-0.1,0c-0.1,0.3-0.4,0.6-0.4,0.9c0,0.2,0.1,0.4,0.3,0.5l-0.6,0.4l0.1,0.9l-0.8,1.7
                  c-0.2,0.2-0.2,0.5-0.2,0.7c0.1,0.7-1.2,1.4-1.2,2c-0.2,0.2-0.2,0.5-0.2,0.7l-0.3,0.2l0.1,0.6l-0.5,0.3l-0.1,1.2l-0.7,0.4
                  c-0.1,0.2-0.2,1.4-0.4,1.5l-0.2-0.2l0.1,0.5l-0.3,1c-0.1,0-0.4,0.2-0.4,0.2c0,0.1,0.2,0.1,0.2,0.1c0,0.3-0.1,0.6-0.3,0.8
                  c-0.8-0.3-0.3,1.4-1,2.2l-0.7,0c-0.1,0.2-0.1,0.2,0,0.4c0,0.1,0.1,0.2,0.2,0.2c-0.1,0.2-0.9,2.2-0.9,2.4c-0.3,0-0.3,0.3-0.3,0.5
                  c0.1,0.5-0.1,0.8-0.1,1.2c-0.1,0.2-0.2,0.5-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.8c0.2,0,0.7-0.1,0.8,0.2c0.4,0,0.4-0.3,0.8-0.3
                  c0.3-0.3,1.5-0.2,1.5-0.4c0-0.1-0.1-0.1,0.1-0.2c0.2,0,0.6-0.1,0.6-0.4c0.4,0,1.5-0.1,1.6-0.5c0.3,0,1.4-0.1,1.3-0.6l1.3-0.7
                  c0.3-0.2,2.5-0.8,2.5-1.2c0.2-0.1,2.3-0.8,2.2-1.3c0.7-0.7,1.4-1.2,2.2-1.6c0-0.4,0.7-0.4,1-0.5l0.1,0c0.3-0.3,1-0.8,0.9-1.3
                  c0.4-0.2,0.8-0.6,1-0.9c0.3,0,2.3-1.7,2.2-2.1l1.1-1.1c0-0.3,2.3-2.7,2.3-3.3c-0.1-0.1-0.6-0.9-0.2-0.9l0.1,0c1.6-2.7,1.3-5,1.4-8
                  c-0.2-0.1-0.3-0.4-0.3-0.6l-0.6-0.4l-0.4-0.6c0.1-0.4-0.9-1.1-1.2-1.3c-0.5,0-0.7-0.8-1.3-0.8c0-0.2-0.3-0.3-0.5-0.3
                  c-0.1-0.5-0.6-0.1-0.6-0.5l-2.2-0.4c-0.7-0.1-3.6-0.2-3.6-0.7l0-0.1c-0.3,0-0.6-0.1-0.8-0.3c-0.2,0.3-0.4,0.7-0.8,0.9l-0.8,0
                  c0,0.3,0.2,1.6-0.2,1.6c-0.7,0.1-0.1,1.3-0.8,1.3l-0.1,0C222.6,140.8,222.8,139.6,222,139.6z M211.8,181.9c0,0.3,0.3,0.2,0.5,0.2
                  l0-0.1C212.3,181.8,211.9,181.8,211.8,181.9L211.8,181.9z M212.3,163.4c0,0.1,0,0.4,0.1,0.4c0.1,0,0.2-0.2,0.2-0.3
                  c0,0,0.3,0,0.3,0l-0.6-0.2L212.3,163.4z M212.5,157.6c0,0.1,0.1,0.2,0.2,0.2s0.1-0.1,0.1-0.2C212.8,157.5,212.7,157.5,212.5,157.6
                  L212.5,157.6L212.5,157.6z M212.9,182.1l-0.1,0.3l0.3-0.3c0-0.1,0-0.2-0.1-0.2C212.9,181.9,212.9,182.1,212.9,182.1z M212.8,157
                  c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0L212.8,157z M213.6,155.9c0-0.1-0.2-0.3-0.3-0.3
                  C213.3,155.9,213.3,155.8,213.6,155.9z M213.2,169.1c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0
                  L213.2,169.1z M213.3,160.5l0.3,0.6c0.3,0,0.3-0.5,0.3-0.6c0.1-0.5,0.4-0.9,0.7-1.2l0.1,0c0-0.2-0.4-0.3-0.6-0.3
                  c-0.1,0-0.2,0.2-0.2,0.3C213.6,159.6,213.3,160,213.3,160.5z M213.7,155.3L213.7,155.3c0.1-0.2-0.1-0.2-0.2-0.2
                  C213.5,155.2,213.6,155.3,213.7,155.3z M214.1,168.8L214.1,168.8c0.1-0.2-0.1-0.2-0.2-0.2l-0.1,0
                  C213.8,168.7,213.9,168.8,214.1,168.8z M213.9,152.5c0,0.1,0.1,0.1,0.2,0.1l0.3-0.5c0.1,0,0.3,0,0.3-0.2c0-0.1-0.2-0.3-0.3-0.3
                  C214.2,151.7,213.8,152.2,213.9,152.5z M214.2,154.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.2,0.2-0.2l-0.3-0.1L214.2,154.6z
                  M214.4,169.3c0,0.1,0,0.1,0.1,0.1c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1-0.1-0.1C214.5,169.2,214.4,169.2,214.4,169.3L214.4,169.3z
                  M214.9,153.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.1,0.2,0.1c0.2-0.2,0.3-0.6,0.4-0.9C215,152.8,215,153,214.9,153.2z M215,169.1
                  C215,169.1,215,169.2,215,169.1c0.2,0.1,0.2-0.1,0.2-0.2c-0.1,0-0.1,0-0.2,0.1L215,169.1z M216.1,151.4c-0.1-0.3,0-0.6,0.2-0.8
                  l-0.1,0c-0.4,0-0.9,1.6-0.9,2L216.1,151.4z M215.8,178.7c0.2,0.2-0.2,0.3-0.3,0.3l-0.1,0.2c0,0.1,0.2,0.2,0.2,0.2l0.5-0.1
                  c0.5-0.1,0.8-0.4,1.4-0.4l-0.8-0.5C216.8,178.7,216,178.7,215.8,178.7z M215.8,156.8c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.1
                  c-0.1,0-0.1,0.1-0.1,0.1C215.6,156.7,215.7,156.9,215.8,156.8z M216.3,168.5c0-0.1-0.2-0.3-0.3-0.3
                  C216,168.6,216.1,168.5,216.3,168.5z M216.4,151.8c-0.2,0-0.4,0.2-0.4,0.4c0,0.1,0.1,0.3,0.2,0.3s0.3-0.1,0.4-0.2l0.3,0.6
                  c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.3-0.2,0.3-0.4l0.1-0.2c0-0.2-0.2-0.4-0.2-0.6c0.2,0.2,0.6,0.5,0.5-0.1l0.3-0.1l0.4-0.6
                  c-0.1-0.4,0.2-0.5,0.2-0.8c0.1-0.4,1.1-2.3,1.1-2.7c0-0.4,0.4-0.7,0.4-0.9c0-0.1-0.1-0.1-0.2-0.1l-0.5,0.6c-0.1,0-0.2,0.1-0.2,0.2
                  c0,0.2,0,0.4-0.2,0.5l-0.6,1.7c-0.4,0-0.2,0.3-0.6,0.4c0,0.6-0.4,1-0.4,1.6c-0.6-0.2-0.1,0.5-0.5,0.5l-0.1,0
                  C216.9,152,216.7,151.8,216.4,151.8z M216.7,168.3c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C216.5,168.1,216.6,168.3,216.7,168.3z
                  M217.1,177.5l-0.4,0.7l1.4-0.5l0.7,0.7c0.2,0,0.2-0.2,0.2-0.4l0-0.2l-1.2-0.6C217.5,177.5,217.7,177.5,217.1,177.5z M216.9,148.5
                  c0,0.1,0.2,0.1,0.3,0c0.1,0,0.3-0.2,0.3-0.4c0-0.1-0.1-0.6-0.2-0.6c-0.1,0-0.1,0.8-0.1,0.8C217,148.5,216.9,148.4,216.9,148.5z
                  M217.3,167.9c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C217.1,167.7,217.2,167.9,217.3,167.9z M217.9,167.6c0-0.1-0.1-0.3-0.3-0.3
                  C217.6,167.4,217.7,167.6,217.9,167.6z M218.4,167.4c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1c0,0-0.1,0.1-0.1,0.1
                  C218.2,167.2,218.2,167.4,218.4,167.4z M219.2,142.8c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.2,0.1-0.2l0.3-0.4l-0.3,0.3
                  C219.4,142.6,219.2,142.6,219.2,142.8z M219.4,176.6l0,0.4l0.7-0.1c0.2,0,0.9-0.3,1.1-0.4c-0.2-0.1-0.4-0.5-0.7-0.5
                  c-0.1,0-0.1,0.1-0.1,0.2L219.4,176.6z M219.9,180.5c-0.1,0-0.2,0-0.2-0.1c0.4-0.2,1-0.5,1.5-0.5l0.1,0
                  C220.9,180.1,220.4,180.5,219.9,180.5z M220,140.8l-0.2,1c-0.1,0.2,0.4,0.3,0.5,0.2c0-0.3,0.3-0.3,0.2-0.6l0.5-0.6
                  c0-0.3,0.3-0.2,0.3-0.6l-0.6-0.5C220.8,140.4,220.3,140.3,220,140.8z M220.7,144.2c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.2,0.1,0.2
                  c0.1,0,0.2-0.2,0.2-0.4c0.2-0.1,0.3-0.4,0.3-0.6c0.2-0.1,0.3-0.4,0.3-0.6c-0.1-0.7,0.6-0.1,0.5-0.7l-0.6,0.3
                  C220.7,143.1,220.7,143.8,220.7,144.2z M220.9,179.4c0-0.4,0.6-0.4,0.9-0.5c0.1,0,0.5-0.1,0.5,0.1c0,0.3-0.7,0.7-1,0.8l-0.1,0
                  C221.2,179.8,220.9,179.6,220.9,179.4z M221.4,177.2c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0
                  C221.1,177,221.2,177.2,221.4,177.2z M222.1,175.6L222.1,175.6c0,0.4,0.5,0.3,0.7,0.3c0.6-0.1,0.7-0.5,0.9-1
                  c0-0.2-0.2-0.4-0.4-0.3c-0.4,0-0.6,0.2-0.9,0.4C222.5,175.3,222.5,175.6,222.1,175.6z M222.3,176.6c0,0.1,0.1,0.3,0.2,0.3
                  c0.1,0,0.3-0.1,0.3-0.2L222.3,176.6L222.3,176.6z M222.8,138c-0.2,0-0.4,0.2-0.3,0.4c0,0.1,0.1,0.5,0.3,0.5c0.1,0,0.1-0.1,0.1-0.2
                  c0.3-0.1,0.6-0.4,0.6-0.7c0.2-0.2,0.2-0.5,0.2-0.7l-0.3-0.3C223.4,137.6,222.8,137.5,222.8,138z M224.2,175.3
                  c0.7-0.1,1.3-0.6,1.8-1.1c0.2,0.1,0.4,0,0.6-0.2c0.3-0.2,0.6-0.4,1-0.5l0.3-0.3l-0.3-0.1c0-0.1,0-0.2-0.2-0.2
                  c-0.3,0-0.6,0.4-0.8,0.6l-1.6,0.6c-0.5,0.2-0.1,0.7-0.7,0.7c-0.1,0.2-0.2,0.2-0.3,0.3l-0.3,0C223.7,175.2,223.8,175.4,224.2,175.3
                  z M227.7,175c0,0.2,0.5,0.2,0.5,0.2l0.2-0.5C228.3,174.6,227.7,174.8,227.7,175z M229.3,174l0.2-0.3c0.1,0,0.4,0,0.4-0.2
                  c0-0.1-0.2-0.2-0.3-0.2C229.3,173.4,229.4,173.8,229.3,174z M231,172.7c0,0.2,0.3,0.5,0.5,0.6c0.1-0.2,0.5-0.6,0.4-0.7
                  c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.4-0.4-0.4c-0.1,0,0,0.3,0,0.4C231.2,172.4,231,172.5,231,172.7z M234.9,170.9l-1.1,0.5
                  c0,0,0,0.1-0.1,0.2c-0.2,0-0.3-0.4-0.5-0.4c-0.1,0-0.1,0.2-0.1,0.2c0.2,0.3,0.4,0.6,0.3,0.9l0.6-0.6c0.3,0,0.8,0.1,0.8-0.4
                  c0.3,0,0.9,0.1,0.8-0.4c0.3-0.1,0.5-0.4,0.6-0.7l0.5-0.5c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.4,0,0.3-0.3
                  C237,169.2,234.9,170.4,234.9,170.9z M238,169.3L238,169.3c0.9-0.1,0.9-1.4,1.5-1.5l0.1,0c0.7-0.4,1.1-1.1,1.8-1.7l0-0.4
                  c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0.3-1.2,1.5-1.6,1.6c-0.1,0-0.1,0-0.2-0.1l0-0.1c-0.4,0-0.3,0.2-0.4,0.5c-0.2-0.1-0.5,0-0.6,0.3
                  c-0.4,0.2-0.8,0.6-0.7,1L238,169.3z M240.8,164.8c0.1,0,0.2-0.1,0.3-0.2l-0.6-0.4C240.5,164.5,240.6,164.9,240.8,164.8z
                  M242.1,165.3l-0.2,0.2c0,0,0,0.1,0.1,0.1C242,165.6,242.1,165.4,242.1,165.3z M242.1,165.3c0.2,0.1,0.4,0,0.4-0.3
                  c0,0,0-0.1-0.1-0.1c-0.1,0-0.1,0.2-0.1,0.2L242.1,165.3z M243.4,163.9c0,0.1,0,0.2,0.1,0.2s0.1-0.2,0.1-0.2l0.5-0.4
                  c0-0.1,0-0.3-0.1-0.3c-0.1,0-0.1,0.2-0.1,0.3L243.4,163.9z M246.9,156.9c-0.5,0.1-0.1,0.5-0.5,0.6l-0.9,0.9c0.1,0.6-0.7,1-1.1,1.1
                  l0,0.2c0.1,0.7-0.3,1.1-0.2,1.4l0,0.1l-0.6,0.7l0.8-0.2c0-0.4,0.6-1.1,0.8-1.4l-0.2-0.2l0.3-0.3c0-0.1,0-0.1,0.1-0.2
                  c0.1,0,0.3,0.5,0.4,0.6c0.1-0.2,0.3-0.3,0.5-0.2l0.5-0.4c0.1-0.2,1.9-4.8,1.9-5c0-0.2-0.1-0.4-0.4-0.4l-0.5-0.3
                  c-0.3,0.5-0.9,1.5-0.8,2.1c0,0.3,0.4,0.3,0.6,0.3L246.9,156.9z M244.9,162.1l-0.2,0.5l0.6,0c0-0.4,0.3-0.7,0.6-0.9l0.2-0.4
                  l-0.5,0.1C245.5,161.9,245.2,162,244.9,162.1z M245,133c0,0.1,0,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2C245.2,133,245.1,133,245,133
                  L245,133L245,133z M249,155.8c0,0.2,0.2,0.6-0.2,0.7l-0.4,0.6l0.2,0.3c0.3-0.3,0.7-1,0.7-1.4l0.3-0.4c0-0.1,0-0.4-0.2-0.4
                  s-0.1,0.2-0.1,0.3C249.1,155.6,249,155.7,249,155.8z M248.6,153.2c0,0.1,0.2,0.3,0.4,0.3l0.2-0.1c0-0.2,0-0.4,0.3-0.5l0.4,0
                  l-0.2-0.6c0.2-0.1,0.3-0.3,0.2-0.5l-0.2-0.1l-0.1-0.7l-0.9,0C248.8,151.6,248.6,152.4,248.6,153.2z M248.9,150.1
                  c0,0.4,0.4,0.3,0.6,0.4c-0.1-0.3-0.4-0.5-0.6-0.6L248.9,150.1z M250.1,142.6c-0.7,0.1-0.4,1.6-0.5,2l0,2.2l0,0.1
                  c0,0.4,0.1,0.7,0.3,1l0.1,0c0.3,0,0.3-1.1,0.2-1.3L250.1,142.6L250.1,142.6z"/>
               <path d="M321.2,122.7c-0.2,0-1.3-0.1-1.2,0.3l0,0.1c-1.2,0.1-2.3,0.5-3.5,0.8l-0.6,0.5c-1.2,0.1-1.6,0.8-2.2,0.8
                  c-0.2,0-0.6-0.1-0.8-0.1c-2.2,0.5-4.2,1.2-6.5,1.4l-2.3,0.2c0,0-0.4,0-0.4,0c0-0.1,0.1-0.2,0.2-0.2c0-0.2,0.1-0.3,0.3-0.3l0.2,0
                  l-0.6-0.2l-0.2-0.3l-0.5-0.2c-0.2,0-0.4,0.2-0.3,0.4l0,0.1l0.2,0.2c0,0.2-0.6,0.3-0.6,0.3c-0.7,0.1-0.4,0-0.8,0.4
                  c-0.6,0.1-0.7-1.1-1.3-1.1c-0.4,0-0.6,0.4-0.9,0.4c0,0.1,0,0.3-0.1,0.3s-0.3-0.2-0.4-0.3c-0.1,0.2-0.3,0.4-0.5,0.4
                  c-0.1,0-0.1-0.1-0.1-0.1l0-0.1c-0.1,0.2-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.1-0.2-0.1l0-0.1l-0.8,0.3l-0.2-0.3c-0.1,0-0.4,0.1-0.3,0.3
                  l0,0.1l-0.1,0.1c-0.2,0-0.3-0.3-0.5-0.3c-0.1,0,0,0.4,0,0.4c0,0.2,0.1,0.5,0.4,0.6c0.2,0,0.6,0,0.8,0.2l0.8-0.5
                  c-0.2-0.1-1.1-0.2-1.1-0.3c0-0.1,0.4-0.1,0.4-0.1l2.5-0.2l0.5-0.3l0.5,0.8c0.3-0.1,1.2-0.3,1.2,0.2l-1.3,0.1
                  c-0.1,0.1-0.1,0.4-0.3,0.4c-0.1,0,0-0.4-0.4-0.3c-0.3,0-0.6,0.3-0.9,0.5l-1.5,0.2c-0.1,0.1-0.3,0.3-0.4,0.3
                  c-0.1,0-0.1-0.1-0.1-0.1l0-0.1c-0.1-0.1-0.2-0.3-0.4-0.3c-0.2,0-0.2,0.3-0.2,0.5c-0.9,0.2-1.6-0.1-1.7,0.9
                  c-0.9,0.1-1.7,0.5-2.5,0.5l-0.3,0.3c-0.2-0.1-0.4-0.3-0.7-0.3c-0.1,0,0,0.3-0.2,0.3c-0.1,0-0.3-0.1-0.4-0.2
                  c-0.1,0-1.3,0.6-1.3,0.6l-0.7-0.2l-1.5,0.4c-0.1-0.6-1.3,0.3-1.5,0.4c-0.6,0.1-1.1-0.2-1.7-0.1c-1,0.1-2,0.9-3,1l-0.5,0.3
                  c-0.1,0.2-0.3,0.3-0.4,0.3c-0.4,0-0.5-0.3-0.8-0.3c-0.1,0-0.1,0.2-0.1,0.3c0.2,0.1,0.6,0.2,0.1,0.3l-0.1,0c0,0.1,0.5,0.3,0.7,0.4
                  c-0.4,0.6-0.8,1.3-1.1,2l-0.7-0.3l-0.4,0.8l-0.3,0.2l0.3,0c0-0.4,0.6-0.2,0.6-0.1c0,0.1-0.2,0.2-0.3,0.2l-0.6,1.2l-0.2-0.9
                  c-0.2-0.1-0.6-0.2-0.8-0.1c-0.4,0-0.4,1.4-0.4,1.7c0.2,0,0.3,0.2,0.3,0.2c0.1,0,0.2-0.3,0.3-0.3c0.1,0,0.3,0.3,0.4,0.5
                  c0,0.6-0.8,0.5-0.8,1.1l0.3,0.5c0.3,0,0.5-0.3,0.5-0.6c1.2,0.3,1.6-0.5,2.7-0.6c0.1,0,0.4,0,0.4,0.1l1.1,0.3l0.4-0.6
                  c0.4,0,1.8-0.1,1.9-0.6l0.7-0.4c0.2,0.1,0.5,0.1,0.7,0.2l0.3-0.3l1.3-0.1l0.1-0.3c0.2,0,0.5,0,0.7,0.2l1.5-0.2
                  c0,0.6,1.3-0.1,1.6,0.4l0.6-0.2c0-0.4,0.4,0,0.5,0.1c0.2-0.1,0.9-0.2,0.9,0.1c0.7-0.1,1.6,0.1,2.4,0.1c0.1,0,0.5-0.1,0.5,0
                  c0,0.1-0.8,0.3-0.9,0.3c-0.2,0.1-0.4,0.2-0.6,0.2l-3,0.4c-0.9,0.2-1.9,1.1-3,1.2c-0.4,0-2.1,0.1-2.1,0.4c0.1,0.7,2.2-0.2,3.1,0.4
                  c2.1-0.2,4.3-0.9,6.5-0.3c0,0.4-1.4,0.4-1.7,0.5c0,0.1-0.1,0.2-0.1,0.2c-0.2,0-0.4,0-0.4,0.3c1.5-0.2,3.2,0.1,4.7,0.2
                  c0-0.8,2.2,0.2,2.1-0.8c0.7-0.1,1.4,0.2,2.1,0.2c0-0.3,0.3-0.4,0.5-0.4c0,0.1,0.1,0.4,0.3,0.3c0.1,0,0.3-0.2,0.3-0.3
                  c0.3,0.1,0.5-0.1,0.6-0.4l0.5,0.2c0,0,0.2,0,0.2,0.1c0,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.3-0.1,0.3c-0.1,0-0.3-0.2-0.4-0.3l-0.5,0.3
                  c0,0.3-0.3,0.3-0.5,0.3l-2,0.2l0,0.5c-0.2,0-1.6,0-1.6,0.5l-2.3,0.5c-0.1,0.3-0.6,0.4-0.8,0.4l-0.3,0.3l-1.5,0.6
                  c-1.1,0.4-1.9,1.2-3,1.3c-0.7,0.1-0.8,0.6-1.6,0.7c-0.1,0-0.4,0-0.4,0.3l-0.4,0c0,0.2-0.3,0.3-0.4,0.3l-0.8,0l0,0.3
                  c0,0.1,0.1,0.4,0.3,0.3c0.2,0,0.5-0.2,0.6-0.4l1.3-0.1c0-0.1,0.8-0.6,1-0.6c1.2-0.1,2.4-0.7,3.5-0.8c1.8-0.2,4-0.8,6-1.3
                  c0.3-0.3,0.7-0.5,1.1-0.6c0.1,0,0.2,0.1,0.2,0.2c0.1,0.6-0.1,0.6-0.6,0.7c-0.3,0-0.7-0.1-0.8,0.4c-1.4,0.1-2.7,0.4-4.1,0.7
                  c-0.4,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2-0.1-0.2-0.2l-1.1,0.3c0,0.1,0.3,0,1,0l-0.1,0.6c-0.2,0-1.1-0.1-1.1,0.3l-0.6,0.5
                  c0.1,0.7,0.6-0.4,0.6-0.1l0,0.1c0.1,0,0.8,0.1,0.8,0.4c-0.9,0.1-2.4,0.8-3.3,0.9c-0.5,0.8-2.6,0.9-2.8,1.5c-0.2-0.1-1,0-0.9,0.4
                  l-0.7-0.2l-0.8,0.5l-0.5,0.1c0-0.2-0.2-0.4-0.4-0.4c-0.4,0-0.1,0.6-0.3,0.6c-0.1,0-0.4-0.4-0.5-0.5c-0.1,0-0.1,0.5-0.1,0.6
                  c-0.3,0-0.3,0.3-0.6,0.4c-0.4,0-0.6-0.2-0.9-0.5c-0.5,0.6-1.5,0.8-1.7,1.1l-0.5,0.1c-0.2-0.2-0.4-0.4-0.6-0.5
                  c-0.2-0.1-0.2,0.4-0.2,0.5l-2,0.2l-0.3-0.3c-0.4,0-0.3,0.3-0.6,0.4c-0.1-0.6-1-0.5-1.4-0.4c-0.6,0.1-1.2,0.2-1.8,0.3l0.1,0.6
                  c0.1,0,0.4,0,0.4,0.1c0,0.1-0.2,0.2-0.3,0.2c-0.5,0.1-0.1,0.5-0.6,0.6c0,0.3-0.3,0.4-0.5,0.4c-0.4,0-2.4,0-2.4,0.4
                  c0,0.3,0.8,0.9,0,1l0,0.4l-0.3,0.1c-0.2,0.5-0.5,0.9-0.9,1.3c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.1-0.3,0.3-0.2,0.5
                  c-0.5,0.6-0.8,1.9-1,2.6l-0.5,0.1c0.2-1.9,2.3-5,2.3-5.1c0-0.1-0.2-0.1-0.2-0.1l-0.9,0.7c-0.1,0.2-0.3,1.1-0.3,1.1
                  c-0.1,0-0.2-0.1-0.3-0.2c-0.6,0.1-0.7,0.8-0.7,1.2l-0.5,0.6c0,0.4-0.8,2.7-0.8,3.3l-0.6,0.5c0,0.1-0.1,0.2-0.1,0.2
                  c-0.2,0-0.4,0-0.4,0.3c-0.4,0.3-0.5,1.5-0.5,2c0,0.1,0,0.4-0.2,0.4l-0.1,0l0.4,0.7l0.4-1.1c0.6-0.1,0.5-0.8,0.5-1.2
                  c0.3,0,0.3-0.3,0.6-0.4c0.1,0,0.7,0.2,0.8,0.2c0,0.4-0.2,0.5-0.2,0.9l-0.5,0.6l0,0.1c0,0.2,0.2,0.2,0.4,0.2c0.4,0,0.7-0.2,1-0.4
                  l0-0.3l1.3-0.1l0.6-0.4c0-0.3,0.2-0.3,0.4-0.3c0.9-0.1,1.8-0.8,2.8-0.9l0-0.3c1.3-0.1,5.5-1.1,5.7-1.5c1.1-0.1,2.2-0.7,3.4-0.9
                  l0.8-0.5l0.9,0l0.6-0.4c2.8-0.3,5.8-1.4,8.6-1.7c1.2-0.1,2.5-0.2,3.7-0.1c0.1,0.1,0.6,0.2,0.6,0.3c0,0.2-0.9,0.3-1,0.3
                  c-1.4,0.1-2.7,0.6-4.1,0.7c0,0.4-1,0.7-1.3,0.7c-0.1-0.6-1.2,0.1-1.4,0.3c0.1,0.6,1.7,0.1,1.8,0.6c0.1,0,0.6-0.1,0.6-0.4
                  c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0,0.1-0.1,0.1-0.2l0.6-0.2c0-0.1,0-0.3,0-0.3c0.1,0,0.3,0.2,0.4,0.3l-0.3,0.3
                  c-0.1,0-0.4,0.1-0.4,0.3c0.1,0.7,1.8-0.2,2.1-0.2c0.2,0.6,4.1,0,5.3-0.5l1,1c0.3,0,2.1-0.3,2.1,0c0,0.1-0.2,0.1-0.3,0.1l-1.9,0.2
                  l-0.1,0.2l-1,0.1l-0.5,0.3c-1.2,0.1-2.2-0.2-3.3,0l0.3,0.6c0.7-0.1,1.6-0.1,2.4,0.1c0,0.1,0.1,0.2,0.2,0.2c0.4,0,0.6-0.4,1-0.4
                  c0.1,0,0.2,0.1,0.2,0.2l0,0.1c0.3,0,0.4-0.3,0.4-0.5l0-0.1c0.4,0,0.3-0.3,0.7-0.4c0,0.2,0.1,0.3,0.3,0.3c0.4,0,0.7-0.2,1-0.4
                  l0.4,0c0.2,0,0.4,0,0.4,0.3c0.1,0,1.6-0.8,1.7,0c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.5-2.3,0.5-2.7,0.6c0,0.2-0.3,0.3-0.5,0.3
                  c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.2-0.2,0.3l-0.9,0.2c0,0.1,0,0.1-0.1,0.2s-0.2-0.2-0.2-0.3l-0.8,0.1c0,0.2,0.1,0.6,0.4,0.6
                  l0.5-0.1c0,0,3.3-0.6,3.3,0c0.3,0,0.5,0,0.8,0.2l0.3-0.3l0.5-0.1c0.2,0.2,0.5,0.2,0.8,0.2l1.4-0.1c0.1-0.2,0.2-0.4,0.4-0.4
                  c0.1,0,0.2,0.2,0.3,0.3l1.5-0.2l0.2-0.2c0.3,0,0.5-0.3,0.8-0.2l0.4,0.3l0.7-0.1c0,0,0-0.3,0-0.3c0.1,0,0.2,0.3,0.3,0.3
                  c0.3-0.3,0.7-0.7,1.2-0.8c0.3,0.1,0.6,0.3,0.8,0.6c-0.1,0-0.4,0.2-0.4,0.3l0.1,0l0,0.3c-1.2,0.1-2.4,0.5-3.6,0.7
                  c-0.4,0.4-0.7,0.6-1.3,0.6c0,0.2-0.3,0.3-0.4,0.3c-0.9,0.1-1.7,0.5-2.5,0.5c-0.7,0.1-2.1-0.2-2.4,0.6c-1.8,0.2-3,1.1-4.1,1.2
                  l-0.5,0.3l1-0.1c0.1-0.2,0.6-0.6,0.7-0.1l0,0.1c0.1,0,0.2-0.2,0.2-0.3l2-0.2c0.3-0.4,1.3-0.4,1.7-0.4c0.1-0.2,0.5-0.3,0.7-0.4
                  c0.1,0.7-0.7,0.8-1.2,0.9c-2.4,0.2-5,1.3-7.5,1.6c-0.7,0.1-0.7,0.7-1.5,0.7l-0.5,0.3l-2.1,0.2l-0.1,0.3l-0.7,0.1
                  c-0.3,0.3-0.6,0.7-1,0.7l-1.1,0.1c-0.3-0.3-0.7-0.6-1-0.8c-0.3,0-0.2,0.5-0.2,0.6l0.4,0.6l-1.1,0.2c0,0.3-0.8,0.4-1.1,0.4
                  c-0.7,0.1-1.2,0.2-1.9,0.3l-0.4,0.3l-1,0.1l-0.2-0.3c0.1,0.9-0.7,0.7-1.4,0.7l-0.3,0.6c-0.4,0-0.7,0.4-1,0.7
                  c-0.1,0-0.4,0.1-0.4,0.3c-0.3,0-2.4-0.1-2.5,0.5c-0.2,0-1.5,0-1.5,0.4c-0.7,0.4-1.7,1-2.5,1.1c-0.1,0-0.4,0-0.4-0.3l0.1,0
                  c0.3-0.1-0.4-0.2-0.4-0.2c-0.1,0.1-0.9,0.3-0.8,0.4c0,0.1,0.2,0.3,0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.1,0-0.2-0.1l0-0.1
                  l-1.1,0.2c0,1.1-1.7,1.1-2.5,1.5c0-0.4-0.6,0.1-0.9,0.2l-0.3,0.2c-0.5-0.2-1.1-0.1-1.6-0.1c-0.3,0-0.1,0.9-0.3,0.9
                  s-0.4-0.2-0.5-0.3l-0.4,0l-0.1,0.6c-1.8,0.2-3.7,1.1-5.4,1.6c0,0.3-0.3,0.8-0.7,0.8l-0.8,0l-0.6-0.2c-0.1,0-1.4-0.4-1.4-0.5
                  c-0.3,0-0.9,0.3-1-0.2c-0.6,0.1-0.7-0.5-1.3-0.5c-0.2-0.3-0.7-0.8-1.2-0.8l-1.2-0.9l-0.4-0.6c-0.7,0.1-1-0.9-1.4-1.3
                  c0-0.1,0-0.3-0.2-0.3c-0.9,0.1-0.1-1.2-0.7-1.2c0-0.4,0.3-1,0.6-1.4l0.4-1.8c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.4,0.2-0.4
                  c0-0.2,0-0.5,0.2-0.7l0.6-1.7c0.4,0,0.3-1.2,0.3-1.5c-0.1-0.5-0.4-2.8,0.2-2.8c-0.1-0.7,0.4-1.1,0.4-1.7l1-0.8l0.1-0.7
                  c0.1,0,0.2,0,0.2-0.2c0-0.1-0.3-0.3-0.3-0.4c0-0.2,0.1-0.4,0.4-0.4l0.1,0c0.4-0.6,1.3-2,1.2-2.8l0.4-0.6c0.1,0,0.2,0,0.2-0.2
                  c0-0.3-0.4-0.5-0.5-0.8c0-0.4,0.7-0.1,0.6-0.4c0-0.2-0.1-0.4,0.2-0.4c0-0.3,0.1-0.5,0.3-0.8c-0.1-0.5,0.5-0.6,0.4-1.4l-0.3-0.3
                  l0.5-0.5c0-0.4,0.3-0.2,0.2-0.6l0.2-0.3l-0.2-0.1c-0.6,0.2-1.3,0.6-2,0.6c-0.2,0.5-1,0.2-1.1,0.7c-0.6,0.1-1.1,0.3-1.7,0.4
                  l-0.4,0.6c-0.1,0-0.5,0.1-0.5,0.3c-1.2,0.1-2.3,0.6-3.4,0.7l-0.6,0.4c-0.3,0-0.8-0.1-0.8,0.4l-0.9-0.1c0,0-0.3,0-0.3-0.1l0.2,0
                  c0.2,0,0.4-0.1,0.5-0.3c0.2,0,0.5-0.2,0.5-0.4c0.1,0,0.4,0,0.4-0.2l0-0.1c0.2,0,0.6-0.1,0.7-0.4c0.6-0.1,0.9-0.4,1.2-0.9
                  c0.9-0.2,1.9-0.6,2.6-1.1c0,0,0.1,0,0.1-0.1c0,0-0.4-0.2-0.5-0.2c-0.6,0-0.6-0.3-1-0.3l-0.6,0.4c-0.1,0-0.6,0.1-0.6-0.2l0.1-0.2
                  c0.4,0,1.3-0.4,1.7-0.5c0-0.3,0.1-0.5,0.4-0.5c0.4,0,1,0,1.2-0.4c0-0.3,0.2-0.3,0.4-0.3c0.3,0,0.7-0.1,0.8-0.4
                  c0.4,0,0.2,0,0.5-0.3l0.6-0.3l-0.8-1c-0.7,0.1-0.9-0.7-0.9-1.3l0.2-0.7c0-0.1,0.1-0.2,0.2-0.2s0.4,0,0.3-0.3
                  c0.2-0.4,0.5-1,0.9-1.1c0.3-0.5,0.4-0.9,1.1-0.9c0-0.9,0.5-0.5,0.8-1l0.7-0.3c0.1-0.2,0.3-0.4,0.5-0.4c0.1,0,0.2,0.1,0.2,0.1
                  c0,0.1,0.2,0.2,0.3,0.2c0.3,0,0.8-0.3,0.7-0.7c0.8-0.2,1.5-0.3,2.3-0.5l0.3-0.5l0.3-0.3c0.1-0.4,0.2-0.8,0.4-1.1l-0.1-0.7l0.5-0.7
                  c0-0.2,0-0.6,0.2-0.8c-0.1-0.6,0.2-0.5,0.3-1c0.2-0.2,0.2-0.5,0.2-0.8c0.1-0.2,0.2-0.5,0.1-0.8c0.2-0.2,0.2-0.5,0.3-0.7l0.5-0.4
                  c-0.1-0.6,0.2-1.3,0.7-1.6c0.1-0.5,0.3-0.8,0.5-1.2c0-0.3-0.1-0.9,0.3-0.9c0-0.3-0.3-0.3-0.5-0.3c-0.4,0-1.1,0.4-1.2-0.2l-1.5,0.1
                  c0,0.3-0.3,0.4-0.5,0.4c-0.1-0.5-0.6-0.3-1-0.3c-0.7,0.1-1.6,0.5-1.9,0.6l0-0.4c-0.1-0.5,1.5-0.4,1.8-0.5l1.3-0.6c0.4,0,2,0,2-0.6
                  c-0.2,0.1-0.6,0.1-0.6-0.2l0-0.1l-3.1,0.3c-0.3-0.5-1.9-0.1-2.3,0.2l-1,0.1c0-0.2,0.2-0.7,0.4-0.7l0.7,0c0.3-0.3,1.1-0.6,1.6-0.6
                  l-0.8-0.4l-1.3,0.1l-0.4,0.3l-0.5,0.1c-0.1,0-0.4,0-0.4-0.2l0-0.1c-1.8,0.2-3.3,1.2-5.1,1.4c-0.4,0-0.5-0.2-0.8-0.2
                  c0,0.2-1.9,0.8-2.4,0.8l-0.7,0.1c0-0.1-0.1-0.6,0.2-0.6c1.8-0.2,5-1.2,6.8-1.8l0.5-0.5c0-0.1-0.2-0.2-0.3-0.2
                  c-0.5,0.1-1,0.3-1.4,0.5c-0.1,0-0.4,0.1-0.4,0.3l-0.7-0.2c-0.2,0.5-1.4,0.4-1.7,0.5l-0.6,0.4l-1.3,0l-0.5-0.2l0.5-0.5
                  c0.4,0,2.6-0.7,2.7-0.8l1.1-0.1c0.4-0.5,1-0.8,1.3-1.3l0.7-0.1l0.2-0.2c0.7-0.1,1.1-0.4,1.8-0.5l0.6-0.4c0.2,0,0.6-0.1,0.7-0.4
                  c0.9-0.1,5.3-1.2,5.5-1.4c0.2,0,1.6,0,1.6-0.5c0.9-0.3,3.2-0.7,3.7-1.1c1.7-0.2,2.7-0.6,4.3-0.8c0-0.3,0.3-0.3,0.6-0.4
                  c0.3,0,1.7,0.1,1.7-0.2c0.6-0.3,1.4-0.4,2.2-0.4l0.3-0.3c1.2-0.1,4.3-1.1,5.2-1.5c0.4,0,2.9,0.2,2.8-0.5l0.3-0.3
                  c0.5-0.1,2.7,0,3-0.6l11.8-1.2c2.6-0.3,5.7,0.6,8.4,0.3c0.3,0.4,1.3,0,1.4,0.5l2.9-0.3c0.2,0.1,0.5,0.2,0.8,0.1
                  c0.2,0.2,0.5,0.2,0.7,0.2l-0.4,0.3c-0.2,0-1,0.2-1.1-0.2c-0.1,0-0.6,0.3-0.6,0.3c0,0.1,0.2,0.1,0.2,0.1l-0.3,0.3
                  c-0.1,0-0.4,0-0.4,0.3l0,0.1l-1.8,0.2l-0.5,0.6l-1.5,0.1c-0.2,0.2-0.7,0.7-0.7,0.9c0,0.3,0.7,0,0.8-0.2l0.7-0.1l0.3,0.3
                  c0.1-0.2,0.5-0.3,0.7-0.4l0.2,0.3l0.4-0.3c0.5-0.1,0.7-0.4,1.1-0.5l0.9-0.1c0,0.1,0.1,0.4,0.3,0.3s0.3-0.2,0.3-0.4
                  c0.4,0,0.4,0.3,0.8,0.3l0.5-0.6l1-0.2l2.8-0.3l0.1,0.3c0.1,0.8-2.9,0.7-3.5,0.7c0,0-0.3,0-0.3,0.1c0.1,0.5,3-0.4,3.1,0.3
                  c0,0.2-1.5,0.3-2,0.3l-0.6,0.4c-0.2,0-0.5,0-0.7-0.2c-0.4,0.2-2.2,0.4-2.2,0.7l0,0.1l-2.2,0.2c-0.1,0.1-0.3,0.3-0.2,0.5
                  c0,0.1,0.1,0.1,0.2,0.1c0.4,0,2.1-0.4,2.2,0.3l0,0.4c-0.2-0.1-0.9-0.3-1.1-0.3l-0.6,0.1c-0.1,0.4-0.9,0.3-0.9,0.8
                  c0,0,0.5,0.2,0.5,0.2c0.6-0.1,1.1,0.2,1.6,0.1c2.6-0.3,5.3,0.3,8,0c0.5-0.1,3.1-0.6,3.5-0.1L321.2,122.7z M253.9,150.8
                  c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.3-0.1l-0.2,0C253.6,150.6,253.7,150.9,253.9,150.8z M255,150.2l-0.6,0
                  C254.4,150.5,254.8,150.3,255,150.2z M260.4,171.8l0.3,0l0-0.7l-0.3-0.1V171.8z M261.2,181.1c0,0.1,0.2,0.1,0.2,0.1
                  c0.1,0,0.9-0.3,0.8-0.5c0-0.1-0.2-0.1-0.2-0.1l-0.6,0.1C261.3,180.8,261.1,181,261.2,181.1z M262.1,168.8
                  C262.2,168.8,262.2,168.7,262.1,168.8c0-0.4-0.2-0.5-0.5-0.5C261.7,168.4,262.1,168.8,262.1,168.8z M263.1,167
                  c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.1C262.9,166.8,262.9,167.1,263.1,167z M263.3,168.8c-0.3,0-0.3,0.3-0.3,0.5
                  c0.1,0.1,0.2,0.2,0.2,0.2c0.1,0,0.1-0.2,0.1-0.2l0.5-0.6L263.3,168.8z M263.4,165c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.1
                  C263.3,164.8,263.3,165,263.4,165z M264.2,163.8c0.1,0,0-0.3,0-0.3s0.4,0,0.4-0.1s-0.6-0.2-0.7-0.2
                  C264,163.3,264.1,163.8,264.2,163.8z M265.5,164.2c0-0.3,0.3-0.3,0.5-0.3c0.1,0,0.3,0,0.3,0c0,0.1-0.2,0.2-0.3,0.3
                  c0.1,0.2,0,0.4-0.2,0.5L265.5,164.2z M266.7,178.7c0,0-0.1,0-0.1,0.1c0,0.1,0.6,0.5,0.7,0.5l1-0.2c-0.4,0-0.9-0.1-0.9-0.1
                  s0.7-0.5,0.9-0.6l-1.3-0.2L266.7,178.7z M267.8,157l-0.1-0.5l-0.6,0.1C267.2,157,267.3,157,267.8,157z M268.3,156.1
                  c0,0.4,0.3,0.3,0.5,0.2l0-0.6l-0.6,0.1L268.3,156.1z M269.1,155.5c0.2,0,0.4,0,0.4,0.3l0.2,0c0-0.3,0.2-0.4,0.2-0.7l-1,0.2
                  C268.8,155.4,268.9,155.5,269.1,155.5z M272,175.7l-0.3,0l-0.3,0.4c-1,0.1-1.6,0.7-2.5,0.8l0.3,0.6c0.4-0.1,1.8-0.4,2.1-0.8
                  c0.5-0.1,0.9-0.4,1.5-0.4l0.6-0.3l0.3-0.4l-1.3-0.2C272.4,175.5,272.2,175.7,272,175.7z M269.1,179c0,0.1,0,0.2,0.2,0.2
                  c0.1,0,0.1-0.2,0.1-0.2L269.1,179L269.1,179z M269.9,178.2c0.1,0,0.2-0.1,0.2-0.2l-0.5,0L269.9,178.2z M271,178.1
                  c0-0.1-0.2-0.3-0.3-0.3C270.7,178.1,270.7,178.1,271,178.1z M275.1,175l0.1,0.3l1-0.1c0,0.2-0.2,0.7-0.4,0.7l-0.7,0l-0.7,0.7
                  c-0.1,0-0.4,0-0.4,0.2c0,0.1,0.2,0.3,0.3,0.3c0.6-0.2,2.7-0.7,3.2-1.1c0.1,0,1.9-0.7,1.9-0.7c0.4,0,0.4-0.4,0.8-0.4
                  c0.2,0,0.6-0.1,0.7-0.4l1-0.1c0,0,0.1,0,0.1-0.1c0,0-0.4-0.2-0.5-0.2c-0.7,0-1.4-0.2-2.1-0.1c-0.2,0-0.3,0.2-0.2,0.5l0,0.1l-1,0.1
                  c0-0.2-0.2-0.4-0.4-0.4l-0.1,0L277,174C276.7,174.3,275.1,174.9,275.1,175z M274.6,175l-0.1-0.6l-0.6,0.1
                  C274.1,175.1,274.1,175.1,274.6,175z M277.9,175c0,0.3-0.7,0.6-1,0.6c-0.1,0-0.1-0.4-0.1-0.5L277.9,175z M279.8,150.2
                  c-0.7,0.7-2.3,0.2-2.4,1.4l1.4-0.1c0.6-0.3,1.4-0.8,2-0.9l0.6-0.4c0.3,0,0.4,0.3,0.4,0.5l0,0.1l-0.6,0.2l0.2,0.4l0.5-0.1l0.2-0.3
                  l1.9-0.8c-0.2-0.1-0.8-0.4-0.8-0.4c0-0.1,0.4,0,0.3-0.3c0-0.1-0.3-0.3-0.4-0.4C282.1,149.5,280.9,150.1,279.8,150.2z M281.7,173.7
                  C281.7,173.7,281.7,173.7,281.7,173.7l0.2,0.1l0-0.1c0-0.1-0.2-0.1-0.2-0.1L281.7,173.7z M283.8,129.9c0,0.3,0.1,0.6,0.4,0.5
                  l0.5-0.1l0.2-0.6L283.8,129.9z M284.7,173.3c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1
                  C284.5,173.1,284.5,173.3,284.7,173.3z M285.1,167.8l0.1,0c0-0.1-0.2-0.2-0.3-0.2C284.9,167.7,284.9,167.8,285.1,167.8z
                  M285.5,172.4l-0.2,0l-0.1,0.5c0.4,0,0.7-0.4,1.1-0.4c0.2,0,0.5-0.1,0.7-0.1c0-0.1-1-0.3-1.1-0.3
                  C285.8,172.2,285.7,172.4,285.5,172.4z M285.5,148.8c0.1,0,0.3-0.1,0.3-0.2l-0.5,0.1L285.5,148.8z M285.7,166.9
                  c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.1,0C285.5,166.8,285.6,166.9,285.7,166.9z M285.5,148.1c0,0.2,0.4,0,0.6-0.1
                  L285.5,148.1L285.5,148.1z M286.8,129.4c0.1,0,0.4-0.1,0.4-0.3l-0.9,0.1L286.8,129.4z M286.4,148.3c0,0.2,0.4,0,0.5-0.1
                  L286.4,148.3L286.4,148.3z M287.1,147.7c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1l-0.1,0C287,147.5,287.1,147.8,287.1,147.7z
                  M287.3,171.3c0,0.1,0.2,0.2,0.3,0.2c0-0.2-0.1-0.4-0.3-0.6L287.3,171.3z M287.5,129c0.1,0,0.1-0.2,0.1-0.2l-0.3,0
                  C287.4,128.8,287.5,128.9,287.5,129z M287.9,171.4l0.8-0.1c0.2,0.2,0.5,0.2,0.8,0.2l0.4,0l0.3-0.3l0.6-0.1l0.1-0.3
                  c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.3C290.2,170.5,287.9,170.6,287.9,171.4z M294.7,126.5c-0.1-0.1-0.2-0.3-0.4-0.3
                  c-0.1,0-0.1,0.1-0.1,0.2c-0.9-0.1-0.3,0.8-1.1,0.8c-1.6,0.2-1.4,0.7-3.3,0.8c0.1,0.6-1.9,0.2-1.8,0.7c0,0.1,0.2,0.1,0.3,0.1
                  c0.7-0.1,1.2,0.2,1.9,0.1l2-0.2c0.1,0,0.3,0,0.3-0.2c0-0.3-0.8,0.1-0.8-0.3c0-0.3,1.3-0.1,1.5-0.6l2-0.2c0-0.2,0.2-0.4,0.4-0.3
                  l-0.5-0.2l-0.5,0.1L294.7,126.5z M288.2,162c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1
                  C288.1,161.7,288.2,162,288.2,162z M289.1,147.9c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1l-0.1,0
                  C288.9,147.7,288.9,147.9,289.1,147.9z M290.9,165.2l0.3,0l-0.1-0.6l-0.6,0.1C290.5,165,290.5,165.2,290.9,165.2z M291.6,166.3
                  c0.1,0,0.1-0.2,0.1-0.2l-0.3,0C291.5,166.1,291.6,166.3,291.6,166.3z M291.9,164.2c0.6-0.1,1,0.1,1.4,0.1l1-0.1
                  c0.1,0,0.3-0.1,0.3-0.2l-0.1-0.3c-0.7,0.1-1.6,0.1-2.4-0.1C292.2,163.9,291.9,163.9,291.9,164.2z M292.7,146.9
                  c0.1,0,0.3-0.1,0.4-0.2l-0.6-0.1C292.4,146.8,292.5,146.9,292.7,146.9z M293.2,165.9l0.2-0.1c0-0.2-0.2-0.3-0.4-0.3l-0.1,0
                  C292.9,165.8,293,165.9,293.2,165.9z M293.7,165.5l0.9,0.4l0.2-0.5L293.7,165.5z M298.1,123.5c0,0,0.3,0,0.3-0.1
                  c0-0.1-0.8-0.2-0.9-0.2c-0.1,0-0.3,0-0.3,0.2C297.5,123.5,297.9,123.6,298.1,123.5z M297.9,164.8c0.1,0,0.4-0.1,0.3-0.3l-0.7,0.1
                  L297.9,164.8z M299.5,122.4L299.5,122.4l0.3,0.4c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4-0.2,0.6-0.4c0.4,0,0.6-0.3,1-0.3
                  c0.9-0.1,1.1,0.5,2-0.6c0-0.2-0.6-0.2-0.8-0.1c0,0.1,0,0.4-0.2,0.4c-0.3,0-0.5-0.2-0.7-0.3l-2.2,0.2
                  C299.8,122.1,299.7,122.4,299.5,122.4z M302.5,126.5c0.2,0,0.6-0.1,0.6,0.2l-0.7,0.1c-0.1,0-0.4,0-0.4-0.3L302.5,126.5z
                  M302.6,119.7c0,0.1,0.2,0.1,0.2,0.1l0-0.1c0-0.1,0-0.2-0.1-0.2C302.7,119.4,302.6,119.6,302.6,119.7z M304,161.8
                  c0,0.2-0.3,0.2-0.3,0.3c0,0-0.4,0-0.4,0c0-0.1,0.2-0.3,0.2-0.5C303.5,161.5,303.9,161.6,304,161.8z M303.2,119.6
                  c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.5-0.3,0.6-0.4l-0.8,0.1L303.2,119.6z M303.9,122c0,0.1,0.2,0.1,0.3,0.1l-0.3-0.6L303.9,122z
                  M308.2,167c0.1,0.5-1.7,0.4-1.6,0.8c0,0.1-0.2,0.2-0.3,0.2c-0.4,0-0.9,0-1.3-0.1l0-0.1c0-0.2,1.6-0.2,1.6-0.7L308.2,167z
                  M304.9,122l0.6,0c0-0.4,0.3-0.2,0.2-0.5l-0.5,0C305.2,121.8,304.9,121.6,304.9,122z M305.7,120.1l-0.6,0
                  C305.1,120.4,305.6,120.2,305.7,120.1z M305.8,166c0.1,0,0.3-0.1,0.4-0.2l-0.5,0C305.6,165.9,305.7,166,305.8,166z M307.7,165.6
                  c0.2,0,0.4-0.1,0.4-0.3l-0.8,0.1L307.7,165.6z M315.1,165.8c0,0.1-0.1,0.1-0.2,0.1c-0.6,0.1-1.8,0-2.2,0.6
                  c-0.8,0.1-1.6,0.5-2.2,0.5l-0.3-0.2c0.1,0.5-0.8-0.1-1-0.2c0-0.2,0.1-0.5,0.3-0.5l0.1,0l0.2,0.3l1.2-0.1l0.5-0.3l1.2-0.1
                  c0.1,0,1.8,0,1.7-0.5C314.4,165.4,315,165.6,315.1,165.8z M309.9,119.3c0.1,0,0.1-0.2,0.1-0.2l-0.3,0
                  C309.7,119.1,309.8,119.4,309.9,119.3z M311.2,119l-0.9,0C310.3,119.3,311.1,119.1,311.2,119z M312.4,118.9l-0.7,0
                  C311.7,119.2,312.3,119,312.4,118.9z M323.4,121.8l0.2,0.3l-0.5,0.3l-0.2-0.6L323.4,121.8z"/>
            </g>
            <g id="LISTO">
               <path d="M702,174.5c-0.4,1.2-2.1,2.5-2.5,3.6c-0.3,0.3-0.8,0.5-0.8,1c0,0.2,0.6,0.3,0.8,0.3c0.2,0,0.4,0,0.4-0.3
                  c0.4,0.1,0.8,0.4,0.9-0.3l0.2,0.3l0.2,0.1c0,0,1.8-0.3,1.9-0.3c0.4,0.1,0.6,0.2,0.9-0.1c1,0.2,2.1,0.1,3.1,0.2
                  c0.1-0.2,0.4-0.2,0.6-0.2c2.5,0.1,4.6,0.1,6.9,0l0.6,0.3c0.2-0.1,0.5-0.3,0.7-0.2c0.1,0,0.1,0.1,0.1,0.2c1.6-0.2,3.8,0.9,5.3,0.3
                  c0.1,0.2,0.3,0.4,0.5,0.5c0.1,0,0.1-0.1,0.1-0.2c0.4,0,1,0,1.4-0.1c-0.1,0.4,0.8,0.1,1,0c0.2,0.3,0.5,0.5,0.6,0l0.8,0.3l0.5-0.1
                  l0.3,0.1c0.1,0.2,0.5,0.6,0.6,0.2l0.6,0.1c-0.1,0.4,0.5,0.1,0.6,0l0.9,0.4c0.2-0.5,0.3-0.3,0.4,0.1l0.2-0.3l0.3,0.4
                  c0.2,0,0.5,0,0.7-0.2l1.3,0.8l0.3,0c0,0.1-0.8,0.2-1,0.1l-0.2-0.3c-0.9-0.3-2.1,0-3.1-0.2c-0.2,0-0.8-0.3-0.8,0.2l0.2,0.4
                  c-0.2,0.7-1.1,0.3-1.7,0.4l-0.3,0.3c0,0.1,0.5,0.2,0.6,0.2l0.9,0.1c0.3-0.2,0.6-0.3,1-0.2c1.4,0.2,2.8,0.6,4.3,0.7
                  c0-0.1,0.2-0.3,0.3-0.2c0.1,0,0.3,0,0.3,0.3l0,0.1c0.9,0.2,2.3,0.7,3.1,0.8c0.1,0,0.8,0.1,0.8,0.3l-0.1,0.1
                  c-0.2-0.1-0.6-0.3-0.7,0.1c-1.3,0.1-2.8-0.3-4-0.5l-1,0.2c0,0.2-0.2,0.3-0.4,0.3c-0.4-0.1-0.8-0.3-1-0.5l-0.7,0.2l-0.9-0.2
                  l-0.5,0.1l-0.5,0.4c-0.4-0.1-1.3-0.5-1.5,0l1.8,0.3c0.1-0.2,0.4-0.3,0.6-0.3c0.1,0,0.4,0.1,0.3,0.3l0,0.1c-0.5,0.1-1.4,0.4-2,0.3
                  c-0.2,0.3-0.6,0.2-0.8,0.2c0.1-0.5-0.3-0.3-0.5-0.1l-1-0.1l-0.8,0.2c0.8,0.3,1.9,0.8,2.7,0.9c0.2,0,0.5,0,0.6-0.2l2.3,0.3
                  c0.7,0.1,1.3,0.5,2,0.7c0.1-0.4,0.1-0.6,0.6-0.6l0.3,0.4c0.6,0.2,1.4,0.8,2,0.9c1.5,0.2,2.9,0.6,4.3,0.9c0.2,0.3,0.5,0.3,0.8,0.4
                  c0.4,0.1,2.6,0.1,2.5,0.8l1.4,0.2l-0.5,0.2c-0.1,0-0.4,0.1-0.4,0.2l0,0.1l-4.4-0.7l-0.5,0.2c0.1,0,0.4,0.1,0.4,0.2
                  c0,0.1-0.2,0.1-0.3,0.2c-3.2,0-6.1-0.4-9.3-0.6c-0.1,0.4-0.2,0.6-0.5,0.5c-1.7-0.3-3.4-0.3-5.1-0.5c-0.9-0.2-0.9,0.5-1.4,0.4
                  c0-0.2-0.2-0.3-0.4-0.4c-0.1,0-0.2,0.2-0.2,0.3l0.5,0.4l-0.8,0.2c0.1-0.3,0.2-0.6-0.2-0.6l-1-0.1l-0.8,0.5c0-0.1,0-0.4-0.2-0.4
                  c-0.2,0-0.4,0.1-0.4,0.3l-0.8-0.4l-0.7,0.1l-0.1,0.8l-0.8-0.4l-0.2,0.3l-0.7-0.1c-0.5,0.5-2.1-0.1-3,0c0.1-0.4-0.7-0.1-0.9,0
                  l-0.2,0c-0.2,0-0.3-0.2-0.3-0.4c0-0.3,0.3-0.2,0.5-0.1c0-0.1,0.1-0.3,0.3-0.2c0.3,0,1.2,0.8,1.3,0.3c-0.4-0.4-0.9-0.8-1.4-0.8
                  c-0.4-0.1-0.7,0-0.9,0.4l-3.2-0.2l-0.7-0.1l-0.8,0.2c-0.3-0.1-0.7-0.3-0.8,0.2c-0.5,0-1.1,0-1.6,0.2l-0.3,0.6l-0.2-0.3
                  c-0.3,0.4-1.3,0.1-1.6,0c0,0,0,0.3-0.1,0.3c-0.1,0-0.2-0.3-0.2-0.3c-0.2,0.1-0.6,0.3-0.9,0.3c-0.1,0-0.1-0.1,0-0.2
                  c0.1-0.5-0.7-0.6-1-0.6l-0.6-0.1c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.3,1.5,0.4,1.7,0.4c0.1,0,0.1,0,0.1,0.1c0,0.1-0.2,0.1-0.3,0.2
                  c-1-0.2-4.9,0-5,0.5l-0.2-0.3l-0.3,0.3c-0.7-0.1-1.5,0.1-2.2,0l-0.4-0.3c-0.2,0-0.4,0.3-0.4,0.5c0.1,0.1,0.3,0.3,0.3,0.4
                  c0,0.1-0.2,0-0.2,0c-0.3,0.2-0.6,0.3-0.9,0.2c-0.4,0.2-0.8,0.2-1.2,0.3c-0.2,0.3-0.5,0.6-0.8,0.6c-1.8,2.8-3.9,5.6-5.8,8.4
                  c-0.2,0.4-0.6,0.2-0.9,0.7c-2.5,3.5-4.8,7-7.3,10.4l-0.7-0.1c0.1-0.3-0.1-0.2-0.1-0.5c0-0.1,2.8-3.8,2.9-4
                  c-0.1-0.4,0.3-0.7,0.3-1.1c0.3-2,5.5-6.7,6.1-10.3c0.2-1.3,1.7-2.3,2-3.5c0-0.1,0-0.2-0.1-0.3l0.6-0.9l-0.3,0
                  c-0.2,0.5-1.4,3-1.8,3c-0.2,0-0.3-0.1-0.5-0.2c-0.7,1.1-1.5,3.2-3,3l-0.3-0.1c-0.7-0.5-2.9,3.8-3.7,5c-0.1,0-0.1-0.2-0.1-0.2
                  c0.2-1.2,2.4-4.4,3.2-5.6c0.3-0.4,1.8-2.9,2.1-2.8l0.1,0.1c0.2-0.3,0.6-0.3,0.8-0.5c0-0.1,0-0.8-0.2-0.8c-0.1,0-0.4,0.5-0.5,0.6
                  l-0.4-0.1c-0.3,0.5-0.9,0.2-1.3,0.4l0,0.1c-0.4,0.3-1.3,1.8-1.5,1.8c-0.3,0-0.6,1.1-1.4,1.1c-0.2,0.4-1.9,3.3-2.3,3.3l-0.1,0
                  c0.1-0.3,1.1-2,1.3-2.5l-0.1,0c-0.4-0.1-1,1.2-1.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0-0.5-0.8-0.6-1.1c0.7-1.7-0.1-1.4-0.9-2.5
                  c0.1-0.4,2-3.2,2.3-3.6l-0.6-0.7l-0.1-0.4l-1.2-0.2l-0.2-0.3c-0.2,0.1-0.6,0.4-0.5,0l-0.2-0.8l-0.9-0.1l-0.4-0.7l0.2-1.6l0.2-1
                  l0.4,0.1l0.2-0.6c0.3,0,0.4-0.3,0.4-0.5l0-0.3c0.1,0,1.5-0.7,1.5-0.8c0.1-0.4,0-0.9,0.3-1.2c0.6,0,0.6,0.1,0.7-0.6
                  c0.3,0,0.8,0,0.9-0.3c0.1-0.7-0.3-1.5,1.2-1.3l0.6-0.5c0.3,0.1,0.6,0.2,0.6-0.2c0.1-0.1,0.3-0.3,0.5-0.2c0.1,0,0,0.2,0,0.2
                  c0.2,0,2.9-0.2,2.9-0.4l0-0.1l1,0c0-0.3,0.1-0.9,0.5-0.8c0-0.3,0.2-0.6,0.4-0.8c-0.5-0.1-0.2-0.3,0.1-0.3c0.1-0.4-0.1-0.7,0-1
                  c0.4-0.2,0.2-0.9,0.7-1.2c0.1-0.6,1-0.4,1.2-1c-0.1-0.3,0.1-0.5,0.4-0.5c0.1-0.5,0.4-0.8,0.8-1.2l0-0.5c0.4-0.2,0.2-0.9,0.7-1.2
                  c0.1-0.6,1-0.4,1.2-1c-0.1-0.3,0.1-0.5,0.4-0.5c0-0.3,0.2-0.6,0.4-0.8c0-0.1,0.7-1.2,0.8-1.3l0.3-0.7l0.8,0
                  c0.2-0.5,0.5-0.9,0.9-1.4c0.1,0,0.3,0,0.3-0.2c0-0.2,0.1-0.4,0.4-0.3c0-0.3,0.2-0.6,0.5-0.7c0-0.3,1.1-2,1.2-2
                  c0.1-0.3,0.4-0.4,0.5-0.8c0.5,0.1,0.8-0.6,0.9-1c0.7-0.6,1.3-1.5,1.7-2.3c1,0.4,2.3-2.5,2.6-3.3c0.2,0.1,0.6,0.1,0.6-0.2l0.9-1.6
                  l0.5-0.2c0-0.1,0-0.5,0.2-0.5l0.4,0.1l0.2-1l0.6-0.2c0.1-0.4,0.4-0.7,0.5-1.3l0.4-0.3c0.1-0.4,0.4-0.2,0.5-0.6l0.4-0.5
                  c0.1-0.7,1-0.4,1.1-1c0.1-0.1,0.7-1.1,0.7-1.3l0.3-0.1l0.4-0.4c0.1-0.3,0.3-0.5,0.6-0.6l0.9-1.5c0.6,0.1,0.2-0.6,0.8-0.5l0.5-0.5
                  c0.1-0.3,0.6-1.4,1-1.3c0.1,0,0.2,0,0.2-0.1c0.2-0.9,0.7,0,0.8-0.8l0.2-1c1.3,0.2,1.3-1.3,2.1-2.1c0-0.1,0.1-0.3,0.3-0.3
                  s0.3,0.3,0.4,0.4l0.6-1.6c0.3-0.2,0.7-0.2,1.1-0.1l-0.3-0.7c0-0.1,2.5-2.7,2.7-2.8l0.7-1.2l0.7-0.2c0.7-0.8,3.2-1.9,3.5-2.3
                  l1.7-0.3c0,0.1-0.3,0.8,0,0.8c0.4,0.1,0.6-0.3,0.8-0.3c0.1,0,0.3,0.2,0.3,0.4c0.4,0.3,0.9,0.5,1.4,0.6c0.1,0,0.4,0.1,0.5-0.1
                  l0.9-0.7l0.3,0.2c-0.3,0.5-0.5,1-0.6,1.5c0.1,0.4,0.3,0.9,0.7,1.1l-1.7,1.7c0.3-0.2,1.9-1.5,2-1.5c0.3,0,0.5,0.4,0.7,0.6
                  c0.4,0.1,1.1,0.2,1,0.8c-0.1,0.7,0.3,0.9,0.9,1.3l-0.5,1.1c0.4,0.1,0.5,0.5,0.9,0.6c-0.2,0.2-0.3,0.5-0.4,0.8
                  c-0.2,0-0.4,0.3-0.4,0.5l-0.2,0.2c0.3,0.2-0.1,0.3-0.3,0.3c0,0.2,0.3,0.7,0,0.6l-0.3,0.5l-0.3,0.1c0,0.2-0.1,0.4-0.4,0.5l-0.6,0.8
                  c-0.6-0.1-0.1,0.7-0.7,0.6c-0.1,0.7-1.3,2.8-1.9,3.2c-0.1,0-0.3,0-0.3,0.2c0,0.2-0.1,0.4-0.4,0.3l-0.5,0.5c-1-0.2-1,0.9-1.2,1.6
                  l0.3,0.7c0.1,0,0.2,0,0.2,0.1c0,0.2-0.3,0.4-0.5,0.5l-0.2,0.7l-0.7,0.6c-0.1,0.4-0.4,0.3-0.5,0.7c-0.9,0.7-1.2,2-2.3,2.4
                  c-0.1,0.4-1.7,1.1-1.8,1.1c-0.7,0.5,0,1.5-1.1,1.3l-0.7,0.5c0-0.3,0.1-1.2,0.3-1.2c0.2,0,0.2,0.4,0.4,0.4c0.1,0,0.2-0.3,0.2-0.3
                  c-0.1,0-0.3-0.1-0.3-0.2c0.1-0.7,1-0.9,1.2-1.6c0.3-0.2,0.4-0.4,0.4-0.7c0.7-0.3,0.4-1.1,1-1.4c0.2-0.3,0.3-0.6,0.5-0.9l-0.7,0.7
                  l-1,0.2c-0.2-1.1-2.2,3.5-2.2,3.7c0,0.2-0.1,0.7-0.4,0.7l-0.5,0.6c-0.2,0.1-1,0.6-1,0.8c0,0.3,0.6,0.2-0.6,0.6l0,0.5
                  c-0.2,0-0.3,0.2-0.4,0.4l0,0.2c-0.8,0.6-3,2.8-3.2,3.8c0,0.1,0.1,0.4,0.2,0.4s0.1-0.1,0.1-0.2c0.3,0,0.8,0.3,1-0.1l0.8,0.3
                  c0.3-0.2,0.4-0.4,0.4-0.7l0.6-0.6c-0.2-0.3-0.3-0.7,0.2-0.6l-0.2-0.8l-0.6,0.6c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4-0.3-0.5-0.5
                  c0.1-0.7,1.1-0.6,1.3-1.2c0.4-0.2,0.8-0.7,1-1.1c0.2-0.1,0.4-0.4,0.5-0.7c0.5,0.1,0.1-0.6,0.8-0.5c0.1-0.4,0-0.6,0.5-0.5
                  c0.2,0.4,0.3,1,0.3,1.5l-0.3,0.5c-0.1,0.7-0.8,0.9-0.9,1.5l-0.4,0.5c0,0.3-0.4,0.4-0.4,0.7c-0.4,0.4-0.8,0.8-1.2,1.1
                  c0,0.1-1.2,2.3-1.3,2.3c-0.1,0.5-0.4,0.5-0.5,0.9l-1.2,1.4c-0.1,0.7-0.8,0.8-1,1.4c-0.2,0.1-0.4,0.5-0.4,0.7l-0.7,0.5l-0.4,0.8
                  l-0.5,0.4l-0.9,1.4c-0.3,0-0.5-0.2-0.4-0.5l0.1-0.4c0.5,0.1,0.2-0.6,0.7-0.5c0.2-0.6,0.8-0.9,0.9-1.6c0.3,0,0.4-0.3,0.5-0.5
                  c0-0.1,0-0.3,0-0.3c-0.1,0-1,0.9-1.1,1c0,0.2-0.2,0.6-0.5,0.5c-0.1,0-0.1-0.2-0.1-0.2l0-0.1c-0.8,1-1.1,1.4-1.5,2.7
                  c-0.3-0.1-3.1,3.6-3.3,4.2l-0.8,0.8L702,174.5z M691.8,191.3c0.1,0,0.3,0.1,0.3,0.3L692,192l-1-0.1c-0.2,0.1-0.5,0.2-0.8,0.2
                  c-0.1,0-0.2-0.1-0.2-0.3l0-0.1C690.7,191.8,690.2,191.1,691.8,191.3z M692.3,190.1c0,0.1,0.1,0.1,0.1,0.1l1.7-0.1
                  c0-0.1-1-0.4-1.1-0.4C692.8,189.8,692.3,190,692.3,190.1z M700.2,191.4c0.7,0.1,3.2,0,3.7-0.1c0.4-0.5,4.6,0.1,5.7,0.2
                  c-2.2,0.2-10.3,0.7-12.8,0.2c-0.1,0.5-3.4,0.1-4,0l-0.4-0.4c0-0.1,0-0.2,0.2-0.2c0.2,0,0.4,0.1,0.5-0.1l2,0.4
                  c0.7-0.4,1.9-0.4,2.2-0.7c0.7,0.1,0.6,0.8,1.2,0.9c0.4,0.1,0.9-0.7,1.1-1.1l1.5,0.2c0.1,0,0.2,0,0.2,0.2c0,0.1-0.3,0.3-0.4,0.4
                  C700.5,191.1,700.2,191.1,700.2,191.4z M700.3,175.9c0-0.1,0-0.3-0.1-0.3c-0.1,0-0.1,0-0.2,0C700,175.8,700.1,175.9,700.3,175.9z
                  M700.6,175.4c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C700.5,175.2,700.5,175.4,700.6,175.4z M701.7,185.8c0.1,0,0.5,0.1,0.5,0
                  c0-0.1-0.6-0.6-0.8-0.7C701.3,185.5,701.3,185.7,701.7,185.8z M702.5,190.6l-0.1,0.3l-0.7,0c-0.2,0-0.3-0.2-0.2-0.4L702.5,190.6z
                  M703,176c-0.1,0.4-0.6,1.3-1.1,1.2c-0.1,0-0.2-0.1-0.2-0.3c0.1-0.5,0.6-1.2,0.9-1.6L703,176z M703.5,171.4c0-0.1,0-0.3-0.1-0.3
                  s-0.1,0-0.2,0C703.2,171.4,703.2,171.4,703.5,171.4z M703.8,171c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C703.7,170.7,703.7,171,703.8,171z
                  M708.9,189.7c0.1,0,0.4,0.1,0.4,0.3l-3.9,0.4c-0.2,0-0.8-0.3-1-0.5C705.7,189.8,707.7,189.5,708.9,189.7z M706.1,171.6
                  c-0.1,0.4-0.6,1.3-1.1,1.2c-0.1,0-0.2-0.1-0.2-0.3c0.1-0.5,0.6-1.2,0.9-1.6L706.1,171.6z M705.7,166.5l-0.2,0.9
                  c0,0.1,0,0.2,0.2,0.3c0.1,0,0.3,0,0.4-0.1l0.3-0.6l0.6-0.6l0.1-0.7l-0.5-0.4C706.5,165.6,706.3,166.6,705.7,166.5z M706.4,185.7
                  c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.2l-0.1,0C706.3,185.4,706.2,185.6,706.4,185.7z M707.6,165c0,0.2,0,0.3,0.2,0.3
                  c0.1,0,0.5,0.2,0.5,0c0-0.2-0.4-0.7-0.6-0.7C707.6,164.6,707.6,164.9,707.6,165z M709.3,162c-0.4-0.1-0.6,0.3-0.6,0.6
                  c0,0.3,0.1,1,0.2,1.4c0.1,0,0.3,0,0.3-0.1c0.1-0.3-0.5-0.9,0.1-0.8l0.2-0.5l0.4-0.4c0-0.2,0.1-0.4,0.2-0.6
                  c0.1,0.1,0.2,0.3,0.4,0.3l0.4-0.7l0.1-0.7l-0.9-0.7C710,160.6,709.5,161.2,709.3,162z M711,163.4l0.1,0c0-0.1-0.1-0.2-0.2-0.2
                  l-0.1,0C710.8,163.3,711,163.3,711,163.4z M712.4,185.5c0.1,0,0.5,0,0.6-0.2l-0.9-0.1C712,185.4,712.2,185.5,712.4,185.5z
                  M712.6,160.7c0,0.2,0,0.4,0.2,0.4l-0.2-0.6L712.6,160.7z M713.5,185.2l0.1,0.1l1.5,0.3l0.2-0.3c0.4,0,1.6,0,1.6,0l-0.1-0.1
                  C716.7,185.1,713.5,184.9,713.5,185.2z M713.8,189.8c0.1,0,0.3,0,0.3-0.2l-0.6-0.2L713.8,189.8z M715.3,184.7
                  c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C715.2,184.5,715.2,184.7,715.3,184.7z M715.7,189.8c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C715.5,189.6,715.6,189.8,715.7,189.8z M715.6,156.6c0,0.1,0.2,0.2,0.3,0.2c0-0.1-0.1-0.5-0.2-0.6L715.6,156.6z M715.9,182.2
                  l-0.1,0c0,0.1,0.7,0.5,0.8,0.5c0.3,0,0.8,0.1,0.9-0.3l-0.8-0.3L715.9,182.2z M716.3,131.3l-0.4,0.3c0.2-0.5,0.1-0.8,0.8-0.7
                  C716.6,131.1,716.5,131.4,716.3,131.3z M719,185.1c0,0.2,0.7,0.2,0.9,0.2L719,185.1L719,185.1z M720,148.1c0.1,0,0.2,0,0.2-0.1
                  c0-0.1,0-0.2-0.1-0.2c0,0-0.1,0.1-0.2,0C719.8,147.9,719.9,148,720,148.1z M720.3,147.5l0.1,0c0-0.1,0-0.3-0.2-0.3
                  C720.2,147.3,720.1,147.5,720.3,147.5z M720.3,182c0,0.1,0.3,0.2,0.4,0.2c0.1,0,0.7,0.1,0.7,0c0-0.1-0.7-0.5-0.8-0.6
                  C720.5,181.8,720.3,181.9,720.3,182z M722.8,190.1c0.4,0.1,1.2,0.1,1.2,0.6c0,0.3-1,0.3-1.2,0.3l-1.3-0.5c0-0.2,0.2-0.4,0.4-0.4
                  c0.3,0,0.6,0.2,0.7-0.1L722.8,190.1z M721.6,148.4c0,0.1,0,0.2,0,0.2c0.1,0,0.2-0.1,0.2-0.2L721.6,148.4L721.6,148.4z
                  M722.4,182.2c0.2,0,0.5,0,0.6-0.2l0.5,0.1c0-0.1-0.3-0.1-0.3-0.1l-0.9-0.3L722.4,182.2z M722.4,145.4l0.5,0.3l0.3-0.7l-0.5-0.3
                  L722.4,145.4z M723,146.6l0.3,0.3c0.2-0.3,0.4-0.7,0.5-1.1c0-0.1,0-0.2,0-0.2l-0.2,0C723.4,145.9,723.1,146.3,723,146.6z
                  M723.5,184.1c0.3,0.1,1.9,0.6,2,0.2L723.5,184.1z M725.6,142.4c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C725.5,142.2,725.5,142.4,725.6,142.4z M726,181c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2-0.1-0.2l-0.1,0C725.9,180.8,726,181,726,181z
                  M732.4,132.7L732.4,132.7L732.4,132.7L732.4,132.7z"/>
               <path d="M776.1,143.4c0.4-0.3,0.8-0.7,0.9-1.2l0.5,0.3l-0.3,1.1l-0.6,0.6l-0.3,1l-0.5,0.5l-0.3,0.8l-0.6,0.6
                  c-0.1,0.7-0.9,0.8-1,1.6c-0.2,0.3-0.4,0.5-0.7,0.8c0,0.2-0.1,0.4-0.4,0.5c-0.1,0.4-0.3,1.3-0.8,1.2c-0.1,0.5-0.5,0.9-0.5,1.4
                  l0.3,0.7c0.1,0,0.1,0,0.1,0.1c0,0.1-0.3,0.3-0.4,0.4c0,0.3-0.2,0.4-0.4,0.4l-0.2,0l-0.2,0.8l-1,1.2l-0.3,0.2
                  c-0.1,0.4-0.5,1-0.8,1.4l-0.2,0.9l-0.7,0.4l-0.4,0.7l-0.5,0.3c-0.1,0.5-0.6,0.6-0.7,1.1l-0.9,1.1c-0.5-0.1-0.4,1.1-1.1,1l0.4-2
                  c-0.3,0-0.5-0.2-0.4-0.5c0-0.2,0.3-0.2,0.6-0.2l0.3,0.1l0.8-1.1c0.1,0,0.3,0,0.3-0.2c0-0.2,0-0.4,0.3-0.3l1.3-2.2
                  c0.2,0,0.4,0,0.4-0.2c0-0.3,0.1-0.4,0.4-0.3l0.5-0.7c0.1-0.4,0.7-2,1-2.1c0.1,0,0.3,0.1,0.4,0.3l0.3-0.7c0.1-0.1,0.3-0.2,0.3-0.3
                  s-0.3-0.1-0.4-0.1c0.1-0.7,1.1-1.6,1.2-2c-0.3,0-0.6,0-0.8,0.2c-0.1,0.7-0.5,1-0.6,1.5c-0.1,0.4,0,0.7-0.6,0.6
                  c-0.2,0.3-1.2,1.6-1.3,1.9c-0.4,0.4-0.9,0.7-1,1.2l-0.4,0.6c-0.2,0.1-0.3,0.4-0.3,0.6c-0.1,0.7-1.1,1.2-1.2,1.8l-0.9,0.3l-0.2,0.8
                  l-0.6,0.5c-0.2,1.3-0.9,2.1-1.1,3.1c-0.1,0.7-0.2,0.6-1,0.5c0.1,0.2,0.4,0.9-0.1,0.8c-0.1,0-0.4-0.1-0.4,0.1
                  c0,0.2,0.6,0.7,0.8,0.8c0.4-0.3,0.7-0.9,0.8-1.4c0.3-0.2,0.4-0.4,0.4-0.7l0.4-0.6c0-0.1,0-0.3,0.1-0.3c0.3,0.1,0.4,0.8,0.3,1
                  c0,0.1,0,0.2-0.2,0.2c-0.2,0.6-0.8,1.6-1.4,1.9c0,0.3-0.3,0.3-0.3,0.6c-0.3,0.5-0.7,1-1.1,1.4c0,0.1-0.1,0.3-0.3,0.3
                  c-0.1,0-0.2-0.3-0.1-0.5c0.1-0.6,0.2-0.6-0.5-0.8c0.4-0.3,0.8-0.8,0.9-1.3c0-0.1,0-0.2-0.2-0.3l-0.6,0.7c0,0.1-0.1,0.2-0.3,0.2
                  c-0.1,0-0.4-0.3-0.5-0.5c0,0.2-0.1,1-0.5,0.9l-0.4,0.5c0,0.3-0.2,0.6-0.4,0.7l-0.3-0.3l-0.3,0.2c-0.3,0.5-0.7,1.3-0.8,1.9l0.2,0.1
                  c0.2,0,0.4,0,0.4-0.2c0.1,0,0.3,0,0.3-0.2c0-0.2,0-0.4,0.3-0.3c0-0.3,0.6-0.9,0.9-0.9c0.1,0,0.1,0.2,0.1,0.2
                  c0,0.1-0.1,1.2-0.3,1.1c-0.1,0-0.3-0.3-0.4-0.4c-0.1,0.4-0.4,0.5-0.5,0.9c0,0.1,0,0.4,0.1,0.5c-0.6-0.1-0.3,0.6-0.8,0.5l-0.1,0.8
                  l0.3-0.3l0.1-0.5l0.8,0.1l-0.1,0.3c-0.5-0.1-0.1,0.5,0,0.7c0,0.2-0.6,1.2-0.8,1.1c-0.1,0.5-0.1,0.6-0.6,0.5l-0.1,0.8
                  c-0.4-0.3-0.7-0.8-0.8-1.3c-0.1,0-0.1,0-0.1-0.1c0-0.2,0.1-0.3,0.3-0.5c-0.2,0.1-0.5,0.3-0.8,0.2c-0.1,0-0.1-0.2-0.1-0.2l-0.3,0.1
                  c-0.3,0-0.4,0.2-0.4,0.4l0,0.1c0-0.2-0.2-0.3-0.4-0.4l0.2,0.3c-0.1,0.8,0.1,1.4-0.1,2.1c-0.4-0.1-0.2,0.5-0.1,0.7l0.5,0.3l0.2-0.6
                  c0-0.3,0-0.7-0.1-1c0-0.2,0.7-0.1,0.8-0.1l0.3-0.8l0.4,0.8c-0.2,0.3-1.3,2.8-2,2.6c0,0.2-0.5,1-0.7,1c-0.1,0,0-0.2,0-0.2
                  c-0.5-0.1-0.1-0.5,0.1-0.6l-0.2-0.7l0.4-0.5c0-0.1,0-0.3-0.2-0.3l-0.2,0c-0.1,0.6-0.7,0.8-0.8,1.3c-0.6,0.5-1,1.2-1.7,1.8
                  c-0.1,0.3-0.5,1.2-0.9,1.1c0,0.1,0,0.6,0.1,0.6c0.1,0,0.1-0.2,0.1-0.2c0.6-0.8,1.5-1.5,1.8-2.5l0.4,0.1c0.3,0,1,0.2,0.9,0.6
                  c-0.1,0.8-0.6,1.3-1.1,1.8c0,0.1,0.2,1.2,0,1.2c-0.2,0-0.3-0.2-0.5-0.4c-0.1,0.5-0.4,1.2-0.7,1.5l-0.3-0.6l0.3-0.7
                  c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.5-0.1,0.5-0.1c0.1-0.4,0.4-0.9,0.7-1.3l0-0.3l-0.2-0.4l-0.5,0.3l-0.5,1.6
                  c-0.6,0.3-1.2,1.3-1.4,1.8c-0.1,0.6-0.5,1-0.8,1.5l-1.2,0.9l-0.9,1.5c-0.1,0-0.3-0.1-0.3,0.1c0,0.1,0.2,0.3,0.2,0.4l-0.9,0.3
                  l-0.6-0.1c0.1-0.7,0.5-1,0.5-2l-0.6,0.6c-0.2,0.1-0.4,0.5-0.4,0.7l-1.2,0.9c-1.1-0.2-0.2,0.9-1,0.8l-0.2,0
                  c-0.1,0.4-0.4,0.5-0.5,0.9l-0.7,0.3c-0.3,0-0.3-0.4-0.2-0.6c-0.1,0-0.3-0.2-0.2-0.3c0.1-0.7,0.8-1,0.9-1.8c0,0,0-0.1,0-0.2
                  s-0.4,0.5-0.4,0.5c-0.2,0-0.2,0.1-0.2,0.2c0,0.3-0.1,0.4-0.4,0.4c-0.3,0.2-0.8,0.2-1.1,0.1l-0.4,0.5l-0.3,0.1l-0.4,0.3
                  c-0.1,0-0.4-0.1-0.4,0.2l0,0.4l-0.7-0.2c0-0.2,0.7-1.1,0.3-1.1c-0.1,0-0.4,0.2-0.5,0.2l-0.3,0.8l-0.5,0.5c-0.1,0.4-0.4,0.8-0.7,1
                  c-0.1,0.7-0.5,1-0.6,1.5c-0.4-0.1-0.6-0.2-0.6,0.3l-0.6,0.6l-0.4,0c-0.1,0-0.3-0.1-0.2-0.3c0.6,0.1,0.1-1.2,0.8-1.6
                  c0-0.2,0.1-0.3,0.4-0.3c0.2,0,1.1,0.3,0.6-0.3l0.4-0.7l-0.3,0l0.4-0.3l0.1-0.8l0.6-0.3l0.2-0.7c0.4-0.4,0.7-0.6,0.9-1.1
                  c-0.4-0.2,0-1.5,0.3-1.7c0.4,0.1,0.5-0.9,0.5-1.1c0.2,0,0.4-0.3,0.4-0.5c0.1-0.4-0.2-0.5-0.2-0.8c-0.7-0.1-0.4,1.3-1,1.2
                  c-0.1,0.5-0.5,0.9-1,1.1l-0.2,1c-1.5,0.3-1.4,1.2-2.3,2.6c-0.6-0.1-1,0.4-1.5,0.3l-0.1,0.9l-0.2,0c-0.4-0.1-0.3-0.3-0.2-0.6
                  l0.3-0.5c0-0.1,0-0.6-0.1-0.6l0,0.2c-0.4-0.1-1.2,0.5-1.3,1c-0.3,0.2-0.4,0.5-0.4,0.8l-0.6,0.3c-0.1,0.3-0.2,0.6-0.4,0.7l-0.1,0.6
                  l-0.3,0.2c-0.1,0.5-0.6,0.9-1,1.1c-0.3,0.4-1.1,1.3-1.6,1.4c0,0.2-0.1,0.6-0.3,0.7l-0.3,0.6l-0.5,0.6c0,0.2-0.1,0.3-0.4,0.3
                  l-0.2-0.1c0-0.3,0.2-0.6,0.4-0.8c0-0.1,0.7-1.3,0.8-1.3c0-0.3,0-1,0.5-0.9c0.2-1.4,2-4.1,3-4.8c0.1-0.5,0.4-0.9,0.5-1.4
                  c0.2,0,0.3-0.2,0.4-0.4c0.1-0.4,0.4-0.6,0.5-1l0.5-0.7c0.3,0,0.4-0.3,0.5-0.5l0-0.4c0.5-0.7,0.8-1.6,1.1-2.3
                  c0.2-0.1,0.5-0.3,0.7-0.5c0-0.2,0.1-0.6,0.4-0.8c0-0.3,0-1,0.5-1c0.1-0.4,1.3-1.6,1.3-1.7l0.4-0.5c0.2-1,0.8-1.5,1-2.5l0.3-0.2
                  c0.1-0.7,1.1-1.4,1.2-1.9c0.5-0.1,0.3-1.1,0.8-1.3c0-0.3,1.8-3.3,1.9-3.4c0.1-0.3,0-0.7,0.4-0.7c0.5-0.8,2-2.3,2.1-3.2
                  c0.1-0.1,0.3-0.2,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2l0.3-0.6c0.2-0.1,0.5-0.3,0.7-0.5l0.7-1.4c0-0.3,0.2-0.6,0.5-0.5
                  c0-0.3,0.3-0.3,0.3-0.5c0.1-0.1,0.9-1.6,0.9-1.7c0.1-0.5,0.6-0.5,0.7-1l0.4-0.5c0.1-0.4,0.5-0.4,0.6-0.7c0.4,0.1,0.7-0.6,0.8-1
                  c0.7-0.8,1.3-1.7,1.9-2.7c0-0.1,0.7-1.2,0.8-1.2c0.1-0.5,0.4-0.8,0.7-1.2l0.3-0.2c0.3-0.3,0.4-0.7,0.6-1.2
                  c0.5,0.1,0.7-1.2,0.8-1.6c0.1,0,0.4,0.1,0.4-0.2s0.1-0.3,0.3-0.2l0.4-0.5l0.3-0.2l0.7-0.9c0.1-0.3,0.4-0.4,0.4-0.7
                  c0.9-0.7,2.2-3.4,2.6-4.5c0.8,0.4,1.5,0.1,1.7-0.8l0.5-0.8l0.7,0.1c0-0.3,0.2-0.6,0.5-0.5l0.1,0c0-0.2,0.1-0.4,0.4-0.4l0.3,0.1
                  l0.2-0.9l1.2-0.5l0.9,0.1c0.2-0.2,0.4-0.4,0.7-0.4l0.1,0.1c-0.1,0.5,0.6,0.1,0.5,0.6l0.2-0.1c0.3-0.3,1-1.4,1.2-1.4l0.1,0.1
                  c-0.1,0.6,1.1,0,1.3-0.1c0.1,0.2,0.5,0.4,0.7,0.4l0,0.8l0.6,0.2c0,0.1-0.1,0.4,0,0.4c0.1,0,0.6-0.9,0.7-1.1l0.7-0.1l0.5,0.3l0.7,0
                  l0.7,0.3l0.6,0.5c0,0.2-0.1,0.5-0.3,0.6c0,0.3,0,1-0.5,0.9l0.1,1c0.5,0.1,0.7-1.1,1.3-1c0.4,0.1,1.1-0.3,1.2-0.8
                  c0.3-0.2,0.4-1,0.5-1c0.1,0,0.2,0.2,0.3,0.3c0.1,0,0.2,0.1,0.2,0.3c-0.1,0.3-0.3,0.5-0.5,0.8c0,0.1,0,1.6-0.5,1.5l-0.4,0.4
                  C776.7,141.8,776.5,142.5,776.1,143.4z M732.5,188.3c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C732.4,188.1,732.4,188.3,732.5,188.3z
                  M733,187.7L733,187.7c0.1-0.1,0-0.2-0.2-0.3C732.8,187.6,732.8,187.7,733,187.7z M739.4,181.9c0,0.2,0.2,0.3,0.3,0.3l-0.2-0.7
                  L739.4,181.9z M740,187.7l0.2,0c0-0.1-0.1-0.2-0.3-0.3C739.9,187.6,739.9,187.7,740,187.7z M740.4,181.1c0.1,0,0.2-0.1,0.2-0.2
                  c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0C740.3,180.9,740.3,181.1,740.4,181.1z M741.4,179c-0.1,0-0.4-0.1-0.4,0
                  c0,0.1,0.4,0.4,0.6,0.5l0.7-0.7l-0.5-0.8C741.7,178.3,741.5,178.7,741.4,179z M742.1,185.8l0.1,0c0-0.1-0.1-0.2-0.3-0.3
                  C742,185.6,742,185.8,742.1,185.8z M742.3,177.4c-0.1,0.4,0.2,0.5,0.5,0.5l0.4-0.5c0-0.2,0.2-0.5,0.3-0.7c0-0.3,0.4-0.4,0.4-0.7
                  l-0.4-0.5C743.1,176.1,742.4,176.8,742.3,177.4z M744.4,182.7c-0.1,0-0.4-0.1-0.4,0c0,0.1,0.6,0.3,0.7,0.3l0.6-0.3
                  c-0.2,0-0.2-0.4-0.2-0.6l0.3-0.2C744.6,181.8,744.8,182.8,744.4,182.7z M745,173.4c0,0.1,0,0.2,0.1,0.2l0.4-0.2l-0.2-0.6
                  C745.2,173,745,173.2,745,173.4z M746.2,188.8l-0.2,0.6c-0.4-0.1-0.2,0.6-0.9,0.4c0.1-0.7,0.8-1,0.9-1.6L746.2,188.8z
                  M746.4,180.8c0.2-0.1,0.4-0.4,0.4-0.6c0-0.1,0.1-0.6-0.1-0.6c-0.4-0.1-0.4,1-0.9,1.2c-0.1,0.4-0.3,0.7-0.4,1.1
                  C745.8,181.6,746.2,181.2,746.4,180.8z M746.3,172.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0
                  C746.2,171.9,746.3,172.1,746.3,172.1z M747.7,184.2c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C747.6,183.9,747.6,184.1,747.7,184.2z
                  M748.1,171c0-0.1,0-0.2-0.2-0.3l-0.1,0C747.8,170.9,747.9,170.9,748.1,171z M748.2,170.2c0,0.2,0,0.3,0.2,0.3l-0.2-0.6
                  L748.2,170.2z M748.4,182.6l0.1,0.8c0.1-0.3,0.5-0.9,0.9-0.8c0.1-0.4,0.4-0.6,0.5-1c-0.5-0.1-0.6,0.6-0.7,1
                  C748.9,182.5,748.6,182.5,748.4,182.6z M750.5,167.6c0,0.1,0.1,0.2,0.2,0.3l0.4-0.3c0-0.1,0.1-0.4-0.1-0.5c-0.1,0-0.2,0.4-0.2,0.5
                  C750.7,167.5,750.5,167.5,750.5,167.6z M756.2,172.9c0.5,0.1,0.7,0.1,0.8-0.5l0.3-1.7l-0.5-0.3
                  C756.7,171.3,756.5,172.2,756.2,172.9z M760.6,165.7c0.1,0,0.1-0.1,0.1-0.2l-0.2-0.1C760.5,165.6,760.6,165.7,760.6,165.7z
                  M761.7,162.4l0.1,0c0-0.1-0.1-0.3-0.2-0.3C761.6,162.2,761.6,162.4,761.7,162.4z M761.9,163.2l0,0.2c0.3,0,0.8,0,0.9-0.3
                  c0-0.1-0.2-0.3-0.3-0.3c-0.1,0-0.3,0.2-0.3,0.3C762.1,163.1,761.9,163.1,761.9,163.2z M766.1,157.7c0.1,0,0.2-0.1,0.2-0.2
                  s0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0C766,157.4,766.1,157.7,766.1,157.7z M766.9,155.6c0,0.2,0,0.4,0.2,0.4l-0.2-0.6L766.9,155.6z
                  M767.5,155.2c0.1,0,0.1-0.1,0.1-0.2l-0.2-0.1C767.3,155,767.3,155.2,767.5,155.2z M769.8,151.5c0-0.1,0-0.3-0.2-0.3
                  c-0.1,0-0.1,0.1-0.1,0.1C769.5,151.4,769.6,151.4,769.8,151.5z M782.2,111.4c0.2,0.3,0.5,0.4,0.7,0.4l1,1.3
                  c0.4,0.1,0.6,0.4,1.2,0.5l0.3,0.4c0.8-0.6,1.2,0.2,1.6,0.3c0,0.3,0.2,0.3,0.5,0.4c1.2,0.2,0.1,0.5,1,0.7c0.1,0.1,0.1,0.3,0.2,0.3
                  c0.1,0,0.1-0.2,0.1-0.3c0.6,0.1,2.3,0.8,2.3,1.5l0.6,0.5c-0.1,0.3,0.1,0.5,0.4,0.6c0.4,0.8,0.8,1.7,1.3,2.4
                  c-0.1,0.4-0.4,0.5-0.5,0.9l-0.4,0.5l-0.5,0.2l-0.2,0.6c-0.1,0-0.3-0.1-0.3,0.1c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.5-0.6,0-0.7,0.8
                  c-0.2,1.2-1.5,2-1.7,3.4l-0.6,0c-0.1,0.4,0.2,0.7,0.1,1.1c-0.4,0.4-1,0.6-1.5,0.8c0,0.2-0.2,0.5-0.4,0.5c-0.3-0.1-0.5,0-0.6,0.3
                  l0,0.3c-0.3,0.2-0.4,0.4-0.4,0.7l-0.6,0.6c-0.3,0-0.4,0.3-0.5,0.5l0,0.1l-0.6,0.5l-0.3,0.7l-0.7,0.3l-0.3,0.3
                  c-0.1,0-0.5-0.1-0.5,0.1c-0.4-0.1-0.7,0.1-0.8,0.5c0.2-0.8,0.7-1.9,0.8-2.5c0-0.3,0-0.4-0.2-0.5l-0.3,0c0-0.3-0.1-0.5-0.1-0.9
                  c-0.2-0.3-0.2-0.6-0.1-0.9c0-0.2,0.2-0.5,0.4-0.5l-0.4-0.5c-0.7-0.1-0.5,1-1.1,0.9l-0.5-0.1c-0.2-0.4-0.4-0.8-0.5-1.2
                  c-0.3-0.1-0.6-0.4-0.7-0.6l-0.8,0.1c0,0.2-0.2,0.6-0.4,0.6c-0.2,0-0.5-0.2-0.8-0.3c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.1,0.3-0.1,0.3
                  c-0.2,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.3-0.3,0.4-0.4l0.3-1.3l0.6-0.5c0.1-0.4,1-1.5,1.2-1.7
                  c-0.1-0.2-0.3-0.3-0.4-0.3c-0.4-0.1-0.5,0.3-0.5,0.6l-0.5,0.6c-0.3,0.1-0.8,0.8-0.8,0.8c-0.3,0-0.5-0.7-0.7-0.7
                  c-0.4-0.1,0.2,0.9-0.9,0.7l-0.4,0.2c0,0.2-0.3,0.2-0.5,0.2c0,0.1-0.1,0.3-0.3,0.2c-0.1,0-0.3-0.1-0.2-0.3c0.4-0.2,0.4-0.5-0.1-0.6
                  l-0.3-0.6l-0.8-0.3c-0.2-0.2-0.4-0.5-0.4-0.7l0.1-0.7l-0.2-0.4c0-0.3,0.4-0.4,0.4-0.7c0-0.1,0-0.3-0.2-0.3l-0.1,0
                  c0-0.1,0.1-0.3,0.3-0.3l0.1,0c0.4-0.4,0.7-0.9,0.8-1.4c0.2-0.1,0.4-0.5,0.4-0.7c0.3-0.4,0.6-0.8,0.9-1.1c0-0.3,0.1-0.8,0.5-0.7
                  l0.7-0.5l0.1-0.7l0.4-0.3l0.5-0.1c0.1-0.5,0.4-0.7,0.5-1.1c0.2-0.1,0.4-0.4,0.4-0.6l1-0.4l0.3-0.5l0.9-0.4c1.1,0.2,2-1.1,2.6-1.5
                  C782,111.2,782,111.4,782.2,111.4z M772.2,150.4c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.2-0.2l-0.1,0
                  C772,150.3,772,150.4,772.2,150.4z M772.8,149c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.4,0.2,0.4c0.1,0,0.1-0.1,0.1-0.1l0.7-0.9l0-0.7
                  c0,0,0-0.2,0-0.2l0,0.2l-0.4,0.3L772.8,149z M786.5,126.8c-0.2,0-0.4-0.1-0.5,0.2c0,0.2,0.1,1.1,0.1,1.4c0.4,0.1,0.5,0,0.6-0.3
                  c0.1-0.1,0.7-1.1,0.7-1.2l-0.4-0.6C787,126.6,786.9,126.8,786.5,126.8z M786.3,126.1l0.2,0c0.3,0.1,0.3-0.2,0.4-0.4
                  c0.1-0.1,0.3-0.2,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2l-0.7,0L786.3,126.1z M789.3,121.8l-0.7,0.8c-0.1,0.4-0.4,0.6-0.5,1
                  c-0.1,0-0.4-0.1-0.4,0.1c0,0.2,0.2,0.5,0.4,0.5c0.1,0,0.2-0.1,0.2-0.2l0.7-0.7c0.1-0.4,0.3-0.6,0.4-1l0.8-0.9l0.1-0.7l-0.5-0.3
                  C789.6,121,789.3,121.3,789.3,121.8z M788.5,121c0,0.1,0,0.4,0.2,0.4l-0.2-0.6L788.5,121z M791.5,120.7c0,0.2-0.1,1-0.5,1
                  l-0.6,0.6c-0.1,0-0.2,0-0.2,0.1c0,0.3,0.4,0.4,0.6,0.5c0.1-0.6,0.9-0.7,1-1.3c0-0.2,0-0.9,0.4-0.8l0.1,0c0-0.3-0.2-0.6-0.5-0.8
                  C791.8,120.1,791.7,120.5,791.5,120.7z M790.4,119.6l0.6,0.1c0.1-0.4,0.3,0,0.4-0.4l-0.6-0.1C790.8,119.6,790.5,119.3,790.4,119.6
                  z"/>
               <path d="M791.1,195c1.2-0.2,2-0.9,3.3-0.7c0.4-0.5,1-0.8,1.6-0.9c0.1,0,0.1,0,0.2,0c0-0.2-0.2-0.4-0.3-0.6
                  c-0.2,0.2-0.6,0.2-0.8,0.2l-0.7,0.5l-1.7-0.3c0,0.1,0.9,0.4,0.9,0.5c-0.1,0.4-2.4,0.7-2.9,0.6l-0.6,0.5c0-0.1,0.1-0.3-0.2-0.3
                  c-0.2,0-0.9,0.6-1.1,0.8c-0.1,0-0.1,0-0.2,0c0,0.1,0.3,0.4,0.4,0.4c0.1,0,0.2,0,0.2-0.1l1.1-0.4c0.1,0,0.4,0.1,0.4,0.3
                  c0,0.1-0.2,0.3-0.4,0.4c0,0.2,0.5,0.3,0.6,0.3c-0.1,0.3-0.4,0.2-0.6,0.2l-0.3,0c-0.2,0-0.4,0.2-0.4,0.3c0,0.2,0.3,0.3,0.4,0.3
                  l0,0.8c-0.3-0.1-0.5,0-0.6,0.3l-1.6,0c0.2,0,0.4-0.2,0.4-0.4c0-0.3-0.7-0.1-0.6-0.5c0-0.1,0.2-0.3,0.3-0.3l-0.7,0.2
                  c-0.4-0.1-0.8-0.3-0.9,0.2c0.1,0.1,0.4,0.6,0.1,0.5c-0.3,0.2-0.5,0.2-0.8,0.2l-0.1-0.6c0.1,0,0.5,0.1,0.5-0.1
                  c0-0.1-0.3-0.2-0.4-0.2c-0.3,0-0.5,0.1-0.6,0.4l0,0.1l-1,0c0,0.1-0.2,0.1-0.2,0.1c-1.2-0.2-0.3,0.5-1.1,0.4l-0.6-0.2
                  c0,0.2-1.4,0.4-1.7,0.3l-0.4,0.2c-0.5-0.1-1.5-0.3-1.6,0.2l0,0.1c-0.3,0-0.8-0.4-0.8,0.1l-1.6,0c-1.2,0.8-3.6,0.7-4.8,0.4
                  c-0.7,0.4-1.1,0.2-1.8,0.1l-0.8,0.2c-0.9-0.2-1.7-0.1-2.6-0.2c-0.2,0.2-0.5,0.2-0.7,0.2c-1.2-0.2-2.1,0.2-3.2,0
                  c-2-0.4-4-1.1-6-1.3c-0.1,0.4-1.4,0.3-1.7,0.2c-0.7-0.1-0.1-0.8-0.6-0.9c-0.1,0-1,0.3-1,0.1c0-0.2,0.2-0.5,0.3-0.7
                  c0.4,0,2.5,0.4,2.6-0.1c0-0.3,0.1-0.4,0.4-0.3c0.5,0.1,1.2,0.4,1.7,0.5l1.8-0.2l0.3-0.3l0.6,0.1c0.3-0.3,2.8,0,3.3,0.1
                  c0.2-0.2,0.5-0.2,0.7-0.2c0.1-0.6,0.6,0,0.7-0.5c-1.4,0.4-4-0.4-4.7,0.1c-0.2,0-0.6-0.1-0.8,0.1l-0.8-0.4
                  c-0.7,0.2-1.5,0.3-2.2,0.1c-0.3-0.1-0.8-0.2-0.9,0.2c-1.5-0.3-3.1,0.1-4.6-0.2c-0.2,0.6-1.5,0.1-1.9,0c0,0.2-0.3,0.2-0.5,0.2
                  c-0.3,0-0.8-0.4-0.9,0.1l-6.1-0.7c0,0.1,0,0.2-0.2,0.2c-0.2,0-0.5-0.4-0.4-0.6c0.1-0.4,1.3-0.1,1.5,0c0-0.3,0.5-0.2,0.6-0.2
                  c1.3,0.1,2.3,0,3.6-0.1c0.1,0,0.6,0.2,0.7,0c0-0.2-0.4-0.5-0.3-0.7c0-0.1,0.1-0.3,0.3-0.3c1.7,0.3,3.2,0,4.7,0.2l0.7-0.2l0.8,0.2
                  l1.5-0.3l0.2-0.3c0.5,0.2,1.1,0.1,1.6,0c-0.1,0.2,0.3,0.3,0.3,0l0.1-0.6c0.5,0.1,1,0.5,1.5,0.6c0.9,0.2,0.7-0.5,1.2-0.4l0.6-0.2
                  c0.7,0.1,2.1-0.5,2.8-0.4c0.1,0,0.3,0.1,0.3,0c0-0.2-0.4-0.3-0.5-0.3l-1-0.2c-0.1,0.8-1.7,0.1-2,0.5c-0.5-0.1-0.7-0.2-1.1,0.1
                  c-1.6-0.3-2.8,0.1-3.7,0c-0.3,0-0.6-0.3-0.8-0.4c-0.7-0.1-0.8,0.5-1.4,0.4c-0.2,0-0.9-0.4-1.1-0.5c-0.5-0.2-0.2,0.6-0.8,0.5
                  c-0.4-0.1-0.8-0.4-1.2-0.6c-0.1,0.1-0.2,0.3-0.3,0.2c-0.1,0,0-0.2,0-0.3c-0.7-0.1-1.2-0.6-1.9-0.7l0.4-0.2c0.3,0,0.7,0,0.8-0.2
                  c0.2,0.2,0.5,0.2,0.5-0.1l0.9,0.4c1,0,2,0,3-0.1c0.1-0.3,0-0.7-0.4-0.8c0.1-0.7-0.6-0.2-0.5-0.8l-1.2-0.2l0.3-0.5
                  c0.8,0.1-0.3-0.6,1.5-0.3l0.3-0.2c0.4,0.1,0.8,0.1,1.1-0.1c1,0.2,3.4-0.9,5-0.6l0.1-0.2c0.3,0.1,1.3,0.2,1.4-0.1
                  c0.2,0,0.4,0,0.6-0.2l0.7,0.1l0.3-0.2l0.7,0.1l0.2-0.3c0.1,0,0.3,0,0.3-0.2c0.1-0.4,2-0.6,2.3-0.6c0.3,0,0.6,0.2,0.7,0.4l0.8-0.2
                  c0-0.2,0.3-0.3,0.5-0.2l0.1,0c0-0.1,0.1-0.5,0.2-0.5c0.1,0,0.3,0.3,0.3,0.4c0.7,0.1,1.3-0.2,2-0.1c0.3-0.4,1.1-0.1,1.4-0.1
                  l0.1-0.2c0.2,0,0.5,0,0.7-0.2c0.4,0.1,2.8-0.4,2.8-0.4l0.3-0.3l0.7,0.1c0.1-0.2,0.4-0.2,0.6-0.2c0.1-0.2,0.5-0.1,0.6-0.1l0.1-0.3
                  c1.2,0.2,1.8-0.3,2.7-0.1l0.2-0.3l0.7,0.1l0.5-0.5l1.4-0.1c0-0.3,0.3-0.3,0.5-0.3l0.1,0c0.1-0.4,0.4-0.5,0.7-0.4
                  c0.3,0,1.6-0.1,1.6-0.5c0.5-0.1,1.1-0.3,1.6-0.4c0.4,0.1,0.5-0.2,1-0.1c0.1-0.2,0.5-0.1,0.6-0.1l0.5-0.4l0.7-0.2
                  c0.5,0.1,0.9-0.7,1-1.1l0.6-0.2c1.7,0.3,0.5-0.4,1.3-0.3c0-0.2,0.2-0.5,0.4-0.4l0.7-0.5c0-0.3,0.3-0.2,0.4-0.5l0.6-0.6
                  c0-0.1,0-0.3,0.2-0.3c0.2,0,0.3,0.3,0.4,0.5c0.4-0.2,0.6-0.6,0.9-1l0.2,0c-0.1-0.5-0.3-1-0.4-1.5c-0.2-0.1-0.2-0.3-0.2-0.5l0-0.1
                  l-1.5-0.9c-0.4-0.2-0.6-0.8-1-0.9l-0.4-0.4c-1.1-0.3-2-1.6-2.9-2.3c0.2-0.4-1.7-1-2-1.2l-0.3-0.7c0.1-0.4-0.6-0.4-0.6-0.5
                  c0-0.1,0.6-0.2,0.6-0.3c0.3,0.2,0.6,0.4,0.8,0.7l1.1,0.3l0.6-0.2c0.1-0.8-1.5-0.9-1.9-0.9l-0.3-0.6c0.2-0.6-0.9-0.7-0.8-1.2l0-0.1
                  c-0.2,0-0.7-0.1-0.7-0.4c-0.5-0.5-1.1-1.2-1.9-1.3c0.1-0.4-0.6-0.5-0.9-0.5l0-0.3l-0.3,0.3l0.6,0.5c0.1,0.3,0.2,0.7,0.6,0.8
                  c0.1,0,0.6,0,0.6,0.2l0,0.1c0.2,0,1.1,0.1,1,0.4l-0.1,0.1l0.2,0.3l0.7,0.1c0,0.2,0,0.4,0,0.5l0,0.3l-0.7-0.4
                  c-0.1,0.4-0.2,0.9-0.7,0.8c0-0.2-1.3-0.9-1.5-0.9c0.5-0.9-1.2-0.6-1.1-1.1l-0.9-1l0.8-0.5c-0.4-0.5-1.8-0.8-2.4-0.9
                  c-0.3-0.6-0.9-1-1.2-1.5c-0.3,0-0.3,0.3-0.6,0.2l-0.4-0.7c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.2-0.3,0.4-0.4c-0.2-0.2-0.4-0.4-0.3-0.7
                  l0-0.1c-0.2-0.2-0.3-0.5-0.2-0.7l0-0.1c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.1,0.1,0.1,0.1,0.1l0.4,0.6l-0.1,0.7l-0.4-0.7
                  c-0.4-0.1,0-0.2-0.5-0.4c-0.1,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3-0.2-0.4c0-0.1-0.2-0.8-0.1-1.1l0.1-0.3l-0.5-0.4
                  c0.1-0.2,0.2-0.6,0-0.8l-0.3-0.9c0.1-0.7,0.4-1.5,0.6-2.2l0.4-0.3c0-0.2,0.9-1.2,1.2-1.7c0.1-0.2,0.2-0.6,0.5-0.7l0.5-0.6
                  c0.3,0,0.6,0,0.8-0.2l-0.4-0.5c0.1,0,1,0.1,1.1,0l-0.1,0c0.6-0.7,1.8-0.7,2.2-1.6c0.7,0.1,0.9-0.4,1.5-0.3l0.8-0.5
                  c0.8-0.1,1.7-0.4,2.4-0.9c0.2,0,2.8-0.1,3-0.6c0.3,0.1,0.7-0.1,1.1,0c0-0.1,1-0.4,1.2-0.4c-0.2,0.6,2-0.1,2.5,0
                  c0.1,0,0.4,0,0.5-0.1l0-0.1l2.2,0.4l0.6-0.3c0.4,0.1,0.7,0.2,1.1,0c0.7,0.1,1.5-0.3,2.1-0.5l1,0.2c0.3-0.7,2.4,0.2,2.6,0.2
                  l0.6-0.2l0.2,0.4l0.3,0c0.1-0.5,1.3,0.1,1.9-0.2l12.8,2.7c-0.1,0.3,0.4,0.4,0.6,0.5c0.2,0,1.5,0.1,1.4,0.4l4.5,1.4
                  c-0.3,0.5,1.2,0.3,1.2,0.6c-0.1,0.3-0.5,0.3-0.7,0.2c-0.3,0-1.2-0.7-1.4-0.2l-3.4-0.7l-0.7,0.1c-0.1,0.3-0.4,0.4-0.6,0.3l-0.3-0.1
                  c0.1-0.3-0.1-0.4-0.3-0.4c-0.2,0-0.7-0.2-0.7,0c0,0.1,0,0.3,0.1,0.5c0,0.1-0.2,0.3-0.3,0.2l-0.1-0.1c0.1-0.4,0.1-0.5-0.3-0.6
                  c-0.3,0-0.6,0-0.8,0.2c-0.4,0.5-1.7-0.4-1.9,0.8c0,0.1-0.1,0.4,0,0.6l0.9,0.2c-0.1,0.3-0.3,0.6,0.2,0.7l0.2,0
                  c-0.1,0.3-0.5,0.5-0.8,0.5c-1.1-0.2-2.1-0.6-3.2-0.8l-0.2,0.5c0.1,0.1,0.5,0.8,0.6,0.8c0.2,0,0.3-0.3,0.3-0.3l0.9,0.2
                  c-0.2,0.5-0.1,0.6-0.7,0.5l-1.4-0.3c-0.2,0-1,0-1.1,0.3c0,0.1,0.3,0.2,0.4,0.2c1.2,0.2,2.5,0.6,3.7,0.9c0,0.1,0,0.2,0.1,0.2
                  c0.2,0,0.4,0.1,0.3,0.3c-0.2,1.4,1.7,0.1,1.5,1.3c-0.2,1.2-1,0.4-1,0.9c0,0.1,0.9,0.2,1.6,0.3c0.4,0.1,0.4,0.4,0.7,0.5l0.1,0
                  c0,0.2-0.7,0.1-1.8-0.1l-2-0.3c0,0.2-0.3,0.2-0.4,0.2c-0.3-0.1-1.3-0.5-1.5-0.2c-0.5,0.1-1.1,0.2-1.7,0.1l-0.1,0l-0.7,0.2
                  l-0.9-0.2l-0.3,0.2l-0.8-0.4c-1,0.2-2.8-0.3-3.8-0.7c0,0.1-0.1,0.4-0.3,0.5c-0.1,0-0.3-0.3-0.6-0.3c-0.2,0.2-0.6,0.2-0.8,0.2
                  l-0.4-0.4c-0.1,0-0.7,0-0.7,0.1c0,0.1,0.3,0.1,0.3,0.1c0.1,0.1,0.3,0.3,0.3,0.4l-0.8-0.1c0,0.1-0.1,0.3-0.3,0.3
                  c-0.2,0-0.3-0.2-0.5-0.4c0-0.2-0.1-0.5-0.4-0.5c-0.1,0-0.4,0-0.4,0.2c0,0.1,0.3,0.2,0.3,0.2l0,0.4l-1.2-0.1
                  c-0.2,0.2-0.6,0.2-0.8,0.2l-0.5-0.3c0,0.1-0.1,0.2-0.3,0.2c-0.2,0-0.3-0.2-0.4-0.4c-0.1,0.3-0.7,0.4-1,0.4l-0.8-0.2l-0.7,0.4
                  c-0.1,0.4,1.6,0.9,1.8,1c0.2,0.3,0.3,0.8,0.7,0.9l0.5,0.4l0.3,0.6l0.7-0.4c0.3,0.3,0.5,0.5,0.4,0.9l0,0.1l1.2,0.7
                  c0.2-0.6,1.2,0.2,1.3,0.4c-0.2,0-0.5,0.1-0.5,0.4c0,0.1,0.2,0.1,0.3,0.1l0.7,0.5c-0.1,0.4,0.6,0.2,0.5,0.6l0,0.1
                  c0.4,0.1,0.2-0.6,0.8-0.5l0.3,0.7l-0.6,0c0,0.1,0.5,0.2,0.6,0.2l0.9,0.8l0.8,0.3c0,0.1-0.2,0.3-0.1,0.4c0.1,0,0.4,0.1,0.4,0.4
                  l0.8,0.2l0.2,0.8l0.8,0.3l0.2,0.8l0.8,0.1l0.2,0.6c0.2,0,0.3,0.2,0.3,0.4l0,0.1c0.7,0.2,1,0.9,1.5,1.5c-0.2,0.6,1.2,0.5,1.1,1.1
                  l0,0.1c0.5,0.5,0.8,1.2,1.1,1.8l0,0.4l0.1,0l0.1,0.5c0.2-0.4,0.7,0,0.8,0.3c-0.1,0.2,0,0.3,0,0.5c-0.1,0.4-0.3,0.6-0.5,0.9l-0.6,0
                  l0.5,0.8c-0.1,0.4-0.5,1.9,0.2,2l0,0.3c0,0.2-0.1,0.3-0.3,0.2c-0.1,0.7,0.5,0.6,0.4,1l0.2,0.7c0,0.2-0.1,0.3-0.3,0.2
                  c-0.1,0-0.2,0-0.3,0.2c0,0.3,0,1-0.5,0.9c-0.1,0.4,0.3,0.1,0.3,0.6c-0.6,0.6-1.5,2.5-2.2,2.8c0.1,1.6-0.3,0.8-0.5,1.9
                  c-0.3,0.5-0.7,1.1-1.2,1.4c-0.1,0.4-0.6,0.3-0.7,0.8l-0.3,0.2l-0.4-0.3c0-0.2-0.2-0.3-0.4-0.4c0,0.3-0.1,0.6-0.5,0.5
                  c0,0.2-0.2,0.3-0.4,0.3l-0.1,0c-0.2,0.1-0.5,0.4-0.8,0.3c-0.1,0-0.1-0.2-0.1-0.3c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.3,0.2,0.3
                  l0.2,0.3c0.4-0.2,1.2-0.8,1.6-0.7c0.2,0,0.2,0.3,0.2,0.5c-0.1,0.4-1.2,0.2-1.3,0.7l-0.7,0.3c-0.9-0.2-1.3-0.8-2.5-0.1
                  c-0.6-0.5-0.4,0.3-0.7,0.2c-0.1,0-0.5-0.2-0.5-0.4c0-0.1,0.2,0,0.2,0c0.2,0,0.5-0.2,0.5-0.4c0-0.1-0.1-0.1-0.1-0.1l-0.2-0.3
                  c-0.3,0-0.2,0.3-0.5,0.2c-0.3,0.1-0.6,0.2-0.8,0.4c-0.1,0.4-0.3,0.5-0.4,0.9c0.4,0.1,0.6-0.2,0.9-0.1l-0.1,0.4
                  c-0.1,0.4-0.2,0.4-0.5,0.4c-0.2,0-0.5-0.2-0.6-0.4l-0.2,0.6l-0.5-0.1c-0.1,0.4-1.3,0.5-1.6,0.4c-0.1,0.3-0.1,0.4-0.4,0.4
                  c-0.2,0-0.6-0.2-0.6,0.1c-0.1,0.7-0.5,0.1-0.9,0.3c0,0.2,0.7,0.5,0.8,0.6c0.1-0.6,1.2,0.1,1.1,0.6c-0.2-0.1-0.8-0.3-0.8,0.1
                  l-0.9-0.4l-0.5,0.1l-0.3-0.6c-0.1,0.5-0.6-0.1-0.7,0.4l0.5,0.3c0,0.3-0.8,0.3-1,0.3l-0.4-0.4c-0.4,0.4-1.4,0.1-1.8,0l-0.3,0.5
                  c0.4,0.1-0.9,0.4-1,0.4c0,0-0.4-0.1-0.4,0c0,0.1,0.8,0.4,0.8,0.4s-0.5,0.1-0.6,0.2c-0.1,0-0.4,0-0.4,0.2l0,0.1
                  c-0.2-0.3-0.5-0.4-0.8-0.4c-0.5,0.1-0.9,0.3-1.5,0.3l0.3-0.2L791.1,195z M751.3,188.9c0,0.3-0.4,0.3-0.6,0.3l-0.1,0l-0.8,0.2
                  l-0.7-0.2l-1.4,0.3c-0.4-0.1-2.5-0.7-2.6-0.2l0,0.1l-3.6-0.6c-0.1,0-0.4-0.1-0.3-0.1c0.1-0.9,4.4,0.5,5.3-0.1l1.4,0.2l0.4-0.3
                  L751.3,188.9z M746.3,191.1c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.2,0,0.2,0L746.3,191.1z M746.6,191.1
                  c0.2,0,0.4-0.2,0.5-0.1C747,191.1,746.9,191.2,746.6,191.1z M747.6,190.9c-0.2,0-0.3,0.1-0.5,0.1
                  C747.3,190.9,747.5,190.9,747.6,190.9z M751,194.8c0.2,0,0.5-0.1,0.5-0.3l-0.9-0.1L751,194.8z M754.2,189.2
                  c-0.1,0-0.8-0.1-0.8,0.1c0,0.1,0.3,0.1,0.4,0.1l1.2,0.2l0.5-0.2c0.2,0,0.7,0,0.7-0.2c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0.1-0.1,0.2
                  C755.4,189.1,754.9,189.3,754.2,189.2z M754.9,190.3l-0.8-0.2C754.1,190.4,754.7,190.4,754.9,190.3z M754.5,197.4
                  c0.2,0,2.6,0.5,2.7-0.1l-1.4-0.2C755.7,197.8,754.6,196.7,754.5,197.4z M757.6,189.2c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C757.5,189,757.6,189.2,757.6,189.2z M758.5,197.3l-0.9-0.2C757.5,197.4,758.3,197.4,758.5,197.3z M768,191.5
                  c-0.2-0.1-1.2-0.5-1.2-0.1c0.1,0,0.6,0.1,0.6,0.2c0,0.1-0.9,0.1-1.1,0c-0.8-0.1-0.8,0.5-1.5,0.4l-0.6,0.3c1.4-0.3,1.6-0.5,3.2-0.2
                  c0.4-0.3,1-0.3,1.5-0.3l0-0.3c0-0.1,0.1-0.4-0.1-0.5C768.7,191,768.1,191.4,768,191.5z M768.3,194.9c0.1,0,0.3,0,0.3-0.2l-0.5-0.2
                  L768.3,194.9z M769.4,194.6c0,0.1,0.6,0.2,0.6,0.3l1-0.4c0.1,0,0.4,0,0.5-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.1,0.2
                  L769.4,194.6z M770.3,190.8c-0.2,0-0.2-0.2-0.2-0.3l0.1-0.3l-0.2-0.1c-0.4-0.1-0.4,0.4-0.5,0.7c-0.1,0.5-0.2,1.4,0.5,1.5
                  c0.2-0.2,0.5-0.2,0.7-0.2c0-0.1-0.1-0.9,0.1-0.9c0.1,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.3,0.1,0.3c0.1,0,0.3-0.1,0.4-0.2l0.6,0.7
                  c0.1,0,0.5-0.1,0.5-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.2,0-0.5-0.2-0.6-0.4c-0.1,0-0.4-0.1-0.3-0.2c0-0.1,0.5-0.2,0.6-0.3l1.1-0.5
                  c-0.3,0.2-1.1,0.3-1-0.2l-0.7,0.4C772,190.1,770.3,190.7,770.3,190.8z M771.9,190.2c0.1,0,0.1-0.1,0.1-0.2l-0.2-0.1
                  C771.8,190,771.8,190.2,771.9,190.2z M772.7,186.3c0.2,0,0.3-0.2,0.4-0.3l-0.5-0.2L772.7,186.3z M772.9,190.1
                  c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.2-0.2l-0.1,0C772.7,189.9,772.8,190,772.9,190.1z M774.5,190.6c0.1,0,0.2-0.1,0.2-0.2
                  l-0.3-0.1C774.4,190.4,774.4,190.6,774.5,190.6z M776.3,190.1c0,0.1,0,0.2,0.2,0.3c0.1-0.6,1.3,0.2,1.4-0.4c0-0.2-0.3-0.4-0.5-0.4
                  c0,0.2-0.3,0.2-0.5,0.2l-0.2-0.3C776.6,189.7,776.4,189.9,776.3,190.1z M777.5,196.9L777.5,196.9c0,0.2-0.1,0.4,0.1,0.4l0.2,0
                  c-0.2-0.3,2.4-0.5,2.6-0.7c0-0.1,0-0.3,0-0.3c-0.1,0-0.1,0.3-0.1,0.3C779.3,196.7,778.4,196.9,777.5,196.9z M778.4,154.6l0,0.1
                  l0.4-0.4l0-0.1c0-0.3-0.4-0.1-0.4-0.5c0.2-1.1,1.3-1.6,1.5-2.4c-1-0.2-1.5,1.5-1.7,2.2c0,0.1-0.1,0.4,0.1,0.5
                  C778.3,154.2,778.5,154.4,778.4,154.6z M780.4,155.5c0,0.2,0.1,0.4,0,0.6c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.3,0.1,0.4
                  c0.5,0.1,0.7-0.9,0.8-1.3l-0.5-0.1L780.4,155.5z M780.1,154.2c0,0.2,0,0.5,0.1,0.8c0.1,0,0.2,0,0.3-0.2c0-0.3-0.1-0.7-0.2-0.9
                  L780.1,154.2z M780.5,152.3c0,0.2,0.3,0.3,0.5,0.3l0.2-0.5l-0.6-0.2C780.6,152,780.5,152.2,780.5,152.3z M781.1,156.8l-0.4,0.5
                  c0.3,0,0.3,0.3,0.6,0.4c0,0.2,0.2,0.5,0.4,0.5l0.2,0l-0.1-0.6c-0.2,0-0.4-0.1-0.3-0.3l0-0.1c0.7,0.1-0.2-1.1-0.2-1.2L781.1,156.8z
                  M784.5,162.1c0.1,0,0.3-0.2,0.3-0.3l-0.2-0.3c0-0.1,0.2-0.6,0-0.7c-0.2,0-0.5,0.3-0.7,0.2c-0.1,0-0.1,0-0.1-0.1l0-0.1l-0.7-0.9
                  c-0.1,0-0.3-0.2-0.2-0.3l0-0.1l-1.1-0.5l-0.7,0.4c-0.1,0.3,0.1,0.5,0.4,0.6l0.5-0.3c0.2,0,0.5,0.2,0.4,0.5
                  c0.7,0.5,1.1,1.4,2.1,1.6C784.6,161.8,784.4,162.1,784.5,162.1z M782.3,196.6c0.1,0,0.3,0,0.3-0.2l-0.5-0.2L782.3,196.6z
                  M782.6,157.8c-0.1,0.7,0.2,1.1,0.8,1.2l0-0.2c0-0.2,0-0.4,0-0.7L782.6,157.8L782.6,157.8z M784.9,196c0.1,0,0.2-0.1,0.2-0.2
                  s0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0C784.8,195.7,784.9,195.9,784.9,196z M785.5,195.6l0.7-0.1c0.3,0,0.7-0.2,1-0.1
                  c0.1,0,0.1,0,0.2,0c0-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.1-0.3,0.3C786.3,195.2,785.8,195.4,785.5,195.6z M786.1,161.3
                  c0,0.1,0.3,0.3,0.4,0.3c0.1,0,0.3,0,0.4-0.1L786.1,161.3L786.1,161.3z M791.8,166.5c0-0.1-0.2-0.2-0.3-0.2
                  c-0.1,0.4-0.4-0.1-0.5-0.2c-0.4-0.1-0.7-0.6-0.9-1c-0.2,0-0.2,0.1-0.3,0.3c-0.1,0.6,0.7,1.3,1.2,1.4c0.2,0,0.4-0.1,0.4-0.2
                  C791.5,166.5,791.8,166.6,791.8,166.5z M790.1,164.7c0,0,0,0.1,0,0.2s0.2-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0.1-0.2,0
                  L790.1,164.7z M792.9,196.1l-0.3,0.5c-0.6-0.1-0.8,0.5-1.3,0.4l-0.3,0.1c-0.2,0-0.2-0.4-0.1-0.5c0.4,0,1.1,0,1.2-0.5L792.9,196.1z
                  M791.2,193.5c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1L791.2,193.5z M792.2,167.1c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.3
                  c0,0,0-0.1-0.1-0.1L792.2,167.1L792.2,167.1z M792.6,167.5L792.6,167.5c0.2,0.1,0.2,0,0.2-0.1c0-0.1,0-0.1-0.1-0.1l-0.1,0
                  C792.7,167.4,792.6,167.5,792.6,167.5z M793,168.8c0,0.1,0,0.1,0,0.2c0.1,0,0.2-0.1,0.2-0.2l-0.2-0.1L793,168.8z M793.1,168
                  c0.1,0,0.1-0.1,0.1-0.1l-0.2-0.2C793,167.8,793,168,793.1,168z M793.4,169.2c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2
                  s0-0.1-0.1-0.2C793.6,169.1,793.5,169.1,793.4,169.2L793.4,169.2z M794.2,192.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2
                  c-0.1,0-0.1,0.1-0.2,0C794.1,192.6,794.1,192.8,794.2,192.9z M795,180.4c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C794.9,180.2,794.9,180.4,795,180.4z M795.8,179.4c-0.3,0-0.3,0-0.3,0.2C795.7,179.6,795.8,179.5,795.8,179.4z M795.6,170.3
                  c-0.1,0.4,1.1,0.3,0.9,0.7l0.7,0.3c0,0.4,0.2,0.7,0.6,0.8c0.1,0,0.1,0,0.2,0c0-0.3-0.5-1-0.7-1.2c-0.5-0.1-0.9-0.6-1.3-1
                  C795.8,170,795.6,170.1,795.6,170.3z M796,179.1l0.1,0c0-0.1-0.1-0.2-0.3-0.3C795.9,178.9,795.9,179.1,796,179.1z M797,178.8
                  c0.1,0,0.2-0.1,0.2-0.2s0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0C796.9,178.6,796.9,178.8,797,178.8z M797,155.7l0,0.6l0.6-0.4
                  L797,155.7L797,155.7z M797,193.6l0.5,0l-0.4-0.5L797,193.6z M798.4,174.7c0-0.2,0.2-0.3-0.2-0.4l-0.7-1.4c0,0,0.1-0.3,0-0.3
                  c-0.1,0-0.4,0.5-0.4,0.5l0.1,0.5c0.3,0.3,0.6,0.7,0.7,1.1c-0.2,0.4,0.6,0.8,0.8,1.2l0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1
                  C798.6,174.8,798.4,174.8,798.4,174.7z M797.4,194.5c0.1,0,0.3-0.3,0.3-0.3l-0.3,0.1C797.4,194.4,797.3,194.5,797.4,194.5z
                  M798.5,192.4l-0.1,0c-0.2,0.1-0.4,0.4-0.5,0.7c0,0.1,0,0.2,0.1,0.2c0.4,0.1,1.1-0.3,1.4-0.4c0.4,0.1,0.7-0.3,0.9-0.7
                  c-0.6-0.1,0.8-0.7,0.9-0.7c0.7,0.1,0.8-0.5,1.4-0.4c0-0.2,0.4-0.2,0.5-0.1l0.6-0.5l-0.4-0.5c-0.1,0.2-0.5,0.1-0.7,0.1l-0.5,0.7
                  l-0.6-0.1l-0.4,0.2c0,0.2-0.3,0.3-0.4,0.2c-0.5,0.4-1.8,0.4-2.6,0.8c0,0.2,0.7,0.3,0.8,0.4C799,192.4,798.7,192.4,798.5,192.4z
                  M801.9,193.2c0.3,0.1,0.5-0.1,0.7-0.4c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.2,0.5-0.4,0.6c-0.4-0.1-0.8,0.2-1.2,0.1L801.9,193.2z
                  M811.5,184.4l-0.2-0.3l-0.1,0.3c-0.1,0-0.3,0-0.3,0.1c0,0.3-0.1,0.3-0.4,0.3c-0.1,0.5-0.5,0-0.6,0.6c-0.5,0.6-1,1.2-1.8,1.6
                  c0.1,0.1,0.1,0.2,0.3,0.2c0.3,0.1,0.3,0.1,0.2,0.4c-0.6,0-1.2,0.2-1.8,0.1l-0.3,0.7l0.8,0c0.2,0.1,0.8,0.2,0.8-0.2l0.3-0.1
                  c-0.1-0.6,1.2-0.2,1.4-1.3c0.6-0.2,1.3-0.4,1.9-0.6c0.1-0.4,0.3-0.1,0.4-0.5c-0.2,0-0.3-0.3-0.2-0.4c-0.1,0-0.4-0.1-0.4-0.3
                  c0.1-0.4,0.5-0.5,0.9-0.7C812.1,184.2,811.7,184.2,811.5,184.4z M808,190.4L808,190.4c0.1-0.1,0-0.2-0.2-0.3
                  C807.7,190.3,807.8,190.4,808,190.4z M809.2,189c-0.3,0-0.6,0.2-0.7,0.4C808.8,189.4,809.1,189.2,809.2,189z M810.6,150.7
                  c0,0-0.2,0-0.2,0c0,0.1,0,0.1,0,0.2c0.7,0.2,1.4,0.4,2.1,0.5c0.2,0,1,0.2,1.1-0.1C812.6,151.2,811.6,150.9,810.6,150.7z
                  M812.7,184c0.1,0,0.3,0,0.3-0.2c0-0.3-0.1-0.7-0.2-0.9c0-0.1,0.1-0.4-0.1-0.5c-0.2,0-0.4,0.5-0.4,0.6c-0.1,0.4,0.2,0.8,0.1,1.2
                  C812.5,184.2,812.6,184.1,812.7,184z M816.6,153.7c0.1,0,0.3,0.1,0.3,0L816.6,153.7l-0.8-0.4L816.6,153.7z M816.8,183
                  c0,0.1-0.2,1.1-0.5,1c-0.4-0.1-0.3-0.4-0.3-0.6c0.1-0.4,0.6-1.9,0.9-1.9L816.8,183z M816.3,180.2c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  L816.3,180.2z M817.6,176.3c0,0.1-0.2,0-0.3,0c-0.1,0-0.5-0.1-0.5-0.2c0-0.1,0.3-0.2,0.4-0.5C817.3,175.8,817.7,176,817.6,176.3z
                  M817.3,176.5c0.2,0,0.6,0.3,0.6,0.5l-0.2,0.6l-0.3,0c-0.1,0-0.3-0.1-0.2-0.3L817.3,176.5z"/>
               <path d="M867,159c-0.1,0.7,1.9,0.3,2.2,0.4c0.6,0.1,1.3,0.1,1.7,0.6c0.7,0.1,1.4-0.1,2,0c0.1,0,0.1,0,0.2,0c0-0.2,0.2-0.2,0.4-0.2
                  c0.4,0.1,1,0.1,1.2,0.5c-0.5,0.1-0.9,0.4-1.2,0.7c-0.4-0.1-0.8,0.2-1.3,0.1c-0.4-0.1-1.4-0.5-1.5,0.3c1.2,0.2,6.9,1,7.3,0.7
                  c0-0.2,0.3-0.3,0.5-0.2l0.2,0c0.1,0.2,0.4,0.5,0.4,0.1l0.5,0.3l0,0.3c-0.1,0.5-0.9,0.1-1.2,0.1l-0.5,0.2l-0.9,0l1,0.6l2.4,0.4
                  l0.5-0.2c0.1,0,0.2,0,0.2,0c0-0.2,0.1-0.2,0.3-0.2c0.4,0.1,0.9,0.1,1.2,0.5c0.2,0,1,0.1,0.9,0.5c0,0.2-0.1,0.3-0.4,0.3l-0.6-0.2
                  l-0.3,0.2c-0.7-0.1-3.4-1.2-3.6-0.2l-0.5,0.4c-1.1-0.3-2.6,0.1-3.6-0.1c-0.4-0.1-0.4-0.4-0.7-0.4l-0.7,0.4c-0.2,0-1.2-0.7-1.3-0.2
                  c-0.7-0.1-4.1-1-4.7-0.5c-0.4-0.1-1.5-0.4-1.8,0c-1.2-0.2-1.9,0-3.1-0.2l-0.6,0.2c-1.7-0.3-2-0.3-2.3,1.8l-0.3,0.1l-0.4,0.4
                  c0,0.2-0.2,0.6-0.4,0.7l-0.4,0.5c0,0.2-0.1,0.5-0.3,0.6c0,0.3-0.2,0.4-0.5,0.4l-0.1,0c-1.2,1-1.2,3-3.1,3.9c0.4,0.1,0,0.4-0.1,0.4
                  c0,0.3,0,1-0.5,0.9c-0.2,1.1-3,2.9-3.2,4.3c-0.2,0.5-0.6,0.9-1,1.2c-0.1,0.4-0.6,1.3-1.1,1.2l-0.4,0.4l-0.3,0.8
                  c-0.4,0.2-0.9,0.4-1,0.9c-0.2,0.1-0.4,0.4-0.4,0.6c-0.1,0.4,0,0.6-0.5,0.5c-0.5-0.1-0.9,3-1.6,3.4c-0.2,0.4-0.4,0.7-0.7,1.1
                  c-0.2,0-0.4,0.3-0.4,0.5l-0.5,0.5l-0.6,0.2c-0.1,0-0.2,0-0.2,0.1c0,0.2,0.1,0.4,0.2,0.6c-0.2,0.4-1.5,2.4-2,2.3l-0.1,0.8l-0.4-0.4
                  c0-0.2,0.1-0.4-0.2-0.5l-0.2,0c-0.1,0.8-0.3-0.1-0.2-0.3l0.8,0l1.7-2.3c-0.4-0.1,0.1-0.2,0.3-0.3c0.4-0.7,0.8-1.4,1.3-2.1
                  c0.9,0.2,0.7-0.9,0.7-1.6c-0.1-0.2-0.2-0.6,0.2-0.5c0-0.2,0.1-0.6,0.4-0.5l0.2,0.3c-0.2-0.4-0.5-0.7-0.4-1.2l0.6-0.6l0.5,0.3
                  c0.1-0.4,0.4-0.6,0.5-1l0.6-0.5c0-0.1,0-0.6,0-0.6c-0.1,0-0.1,0.4-0.1,0.4l-0.8,0.4c-0.2,0-0.4,0-0.4,0.2l0,0.3
                  c-0.1,0-0.3,0-0.3,0.2c0,0.2-0.1,0.3-0.3,0.2c-0.1,0.4-0.4,0.5-0.4,0.8c-0.6-0.1-0.2,1.4-1.1,1.2c-0.4,0.4-0.6,0.9-0.8,1.4
                  c-0.4,0.2-0.5,1-0.8,1.4l-0.3,0.2c-0.1,0.3-0.5,0.7-0.8,0.9c0.1,0.1,0.5,0.4,0.5,0.5c0,0.1-0.2,0-0.2,0l-2.2,2.2
                  c-0.5-0.1,0,0.6-0.6,0.5l0.6,0.2l0.2,0.4c0,0.3-0.3,0.3-0.5,0.3l-0.1,0c0,0.3-0.1,0.8-0.5,0.7l0.2,0.3c0.1-0.4,0.6-0.2,0.9-0.1
                  l0.5-0.1c0,0.2-0.2,0.6-0.3,0.7l-0.3,0.6c-0.1,0-0.3,0-0.3,0c0,0.1,0.1,0.2,0.2,0.3l-0.4,0.6l-0.1,0.6l-0.3,0.2
                  c-0.4,0-0.4,0.8-0.8,1.1c0,0.1,0,0.3-0.2,0.3c-0.2,0-0.3-0.7-0.3-0.9c0.8-0.5,0.2-1,0.2-1.3c0,0-0.3,0-0.3-0.1
                  c0-0.1,0.3-0.2,0.3-0.2c0-0.2,0.3-0.2,0.5-0.2l0.4,0.1c0.1-0.6-0.6-0.2-0.5-0.7l-0.3,0c-0.1,0.4,0,0.5-0.5,0.4l-0.6,0.6l0,0.7
                  c0.3,0,0.2,0.6,0.2,0.8c0,0.1-0.1,0.7-0.3,0.6c-0.1,0-0.3-0.1-0.4-0.3l0.5,1.3c0,0.1-0.2,0.1-0.2,0.1c-0.4-0.1-0.6-0.7-0.7-0.1
                  c-0.4,0.4-0.9,1.5-1,2.1c-0.6-0.1-0.8,0.6-0.9,1c-0.3,0.3-0.5,0.6-0.8,0.9c-0.1,0.4,0,0.9-0.4,1.2c-0.1,0.8-1.5,1.6-1.5,2
                  c-0.1,0.6-0.1,1.3-0.9,1.2c-0.1,0-0.1-0.3-0.1-0.4c0.1-0.5,0.3-1,0.6-1.5c0.1,0,0.2,0,0.2-0.1c0-0.2-0.4-0.5-0.5-0.5
                  c-0.4-0.1-0.2,1.1-1,0.9l-0.4,0.5l-0.2,0c0-0.1,0.1-0.6,0-0.6c-0.1,0-0.2,0.4-0.2,0.5c-0.2,0-0.4,0.1-0.4,0.3l0,0.6
                  c-0.3,0.5-0.7,0.9-1,1.2c-0.1,0.4-0.3,1.3-0.9,1.2c-0.2,0-0.2-0.3-0.2-0.4c0.1-0.4,0.3-0.8,0.5-1.3l0.3-1.5l-0.9-1.2l-0.7,0.3
                  c-0.1,0.6-0.7,0.6-0.8,1.3c0,0.2-0.1,0.4-0.4,0.4l-0.6,0.7l-0.2,0.9c-0.6-0.1-1.1,1.5-1.2,2.1L819,211l0-1c0.2-0.1,0.7-0.9,0.4-1
                  c-0.1,0-0.4,0.4-0.5,0.5c-0.2,0-0.5-0.2-0.6-0.5c0.1-0.4,0.8-2.3,1.3-2.2c0.1-0.6,0.5-1.1,0.6-1.7l0.3-0.2c-0.2-0.3-0.5-0.6-0.4-1
                  l0.1-0.5l0.3-0.2c0.2-1.1,1.6-2.7,1.7-3.5c0-0.1,0.1-0.4-0.1-0.4c-0.2,0-0.7,0.4-0.8,0.6c-0.4,0-0.9,2.3-1.2,2.2l-0.2-0.1
                  c-0.1,0-0.2,0.5-0.2,0.6l-0.3,0.4l-0.3,0.2l-0.1,0.4c-0.5,0.1-0.2,1-0.8,1.1c-0.3,1.1-0.8,2.1-1.4,3c-0.2,0-0.3-0.3-0.2-0.4
                  c0.1-0.4,0.3-0.8,0.5-1.1l0.2-1c0,0,0-0.1,0-0.2l-0.2,0.4c-0.2,0.5-1,0.5-1.1,0.9l-1,1.3c0,0.3-0.1,0.6-0.5,0.5
                  c0,0.3-0.3,0.2-0.4,0.5l-0.4,0.5c0,0.2,0,1-0.4,1l-0.9,1.3c-0.6-0.1-1,1.5-1.1,1.8c-0.4-0.1-0.5-0.2-0.4-0.5
                  c0.2-0.9,0.5-1.8,0.7-2.7l0.3-0.2l-0.3-0.2c0.3-1,0.8-2,1.4-2.9c0,0,0.3,0,0.3,0c0-0.1-0.2-0.2-0.3-0.3l0.7-0.6
                  c-0.2-0.3-0.2-0.6-0.2-0.8l0.4-0.4c0.1,0,0.4,0.1,0.4,0c0-0.3-0.1-0.5-0.1-0.8c0.1-0.7,0.9-0.3,1-0.9c0-0.2,0-0.9,0.4-0.8
                  l-0.4-0.4c0-0.4,0-0.8,0-1.1c0-0.2,0.1-0.3,0.3-0.2c0-0.3,0.2-0.5,0.4-0.7l1.1-0.1c0-0.1-0.7-0.2-0.8-0.2l0.7-1.1
                  c0.3-1.7,1.3-2.6,1.4-2.9c0.4-0.4,0.7-1.1,0.8-1.6c0.3-0.4,0.6-0.8,0.8-1.3c0.5-0.1,0.2-1,0.8-1.1c0-0.3,0.4-0.4,0.4-0.7
                  c0.2-0.2,0.3-0.5,0.4-0.8c0.2-0.1,0.4-0.5,0.4-0.7c0.1,0,0.3,0,0.3-0.2c0-0.2,0.1-0.4,0.4-0.3c0.1-0.5,1.9-3.3,2.3-3.2
                  c0.1-0.7,0.8-1.4,1-2.1l0.4-0.6c0.2,0,0.4-0.3,0.4-0.5l0.3-0.3c0.2-1.4,1.6-2.3,1.8-3.3c0.2-0.2,0.4-0.3,0.7-0.4
                  c0.2-0.9,1.4-1.5,1.4-1.7c0.2-0.1,0.4-0.4,0.4-0.6c0.1,0,0.3,0,0.3-0.1c0-0.3,0.1-0.4,0.4-0.3l0.2-0.9l0.2-0.3
                  c-0.1,0-0.4,0-0.4,0.2c-0.1,0-0.1-0.1-0.1-0.1c0.1-0.6,1.8-2.4,2.2-2.9c0.1-0.6,0.7-0.7,0.8-1.2l1-1.2c0.1-0.6,0.7-0.8,0.8-1.3
                  l0.4-0.5c0-0.1,0-0.4,0.2-0.4l0.4,0.1c0.1-0.9,0.7-0.8,1.1-1.3c0.1-0.6,0.7-0.7,0.8-1.3l0.6-0.2c0-0.3,0.2-0.6,0.4-0.7l-0.2-0.5
                  l-1.3-0.1c-0.1,0.2-0.4,0.2-0.6,0.2l-3.4-0.6c-0.2,0.2-0.6,0.4-0.9,0.4c-0.2,0-2.3-0.6-2.4-0.1c-0.5-0.1-1.8-0.5-1.9,0.3
                  c-0.2-0.1-0.6-0.2-0.7,0.2l-1.4-0.2c0,0.1-0.2,0.3-0.3,0.3c-0.1,0,0-0.2,0-0.3c-0.2,0-0.5,0-0.7,0.2l-2.1-0.4l-0.2-0.3
                  c-0.6-0.1-1.1-0.5-1.7-0.6c-0.5,0.1-1.2,0.4-1.7,0.3l-0.3-0.6l0.9,0.2c0.7-0.3,1.4-0.5,2.1-0.5c0-0.3,0.1-0.5,0.4-0.5
                  c0.4,0.1,2.7-0.1,2.7-0.3c0.2,0,0.4,0,0.4-0.2c0.2-1.1,1-0.4,1.1-0.9l1.4-0.1l-0.2-0.6c-0.3,0-0.6-0.2-0.7-0.4l-1.2-0.2l0-0.6
                  c0.1,0,0.4,0.1,0.4-0.1c0-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.5,0-0.6,0.2l-0.9-0.2l0-0.2c0.1-0.5,0.6,0,0.6-0.3c0-0.2-0.2-0.5-0.3-0.6
                  c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.9-1,0.9-1.1l0.8-0.2c0.2-1.1,0.7-0.1,1.2-0.6c0.7,0.1,0.6-1.1,1.1-1c0.4-0.2,2-1.1,2.6-1
                  c0.4,0.1,1.3,0.2,1.4-0.4c0.3,0,0.8,0.4,0.9-0.1c0.2,0,0.5-0.1,0.8-0.1c0.3,0,3,0.1,3.1-0.4c0.6,0.1,1.2-0.3,1.8-0.2
                  c0.1,0.5,0.9,0.5,1.2,0.2c3.3,0.6,6.9-0.4,10.3-0.1l0.7-0.3l0.6,0.1l0.3,0.4l0.5-0.5c0.5,0,1.2,0.2,1.6,0.5l0.6-0.4l0.9,0.2
                  c0,0.1-0.1,0.3,0.2,0.3c0.1,0,0.4-0.2,0.6-0.3c1.2,0.2,2.6,0.1,3.5,0.3c0.1,0,0.6,0.3,0.7,0.4c1.4-0.3,6.8,0.6,8.4,0.8l7.9,1.4
                  c0.9,0.2,2.1,1,3,1.1c0.9,0.1,5.9,0.4,5.7,1.4l0,0.1c0.8,0.1,1.6,0.2,2.4,0.2c0,0.2,0.1,0.2,0.3,0.3c0.3,0,0.7,0,1.1-0.1
                  c-0.1,0.4,0.3,0.3,0.2,0.6c-0.6-0.1-2.2-0.5-2.3-0.2c0,0.1,0.5,0.2,0.6,0.2c-0.1,0.5,0.2,0.3,0.4,0.1c0.1,0.2,0.3,0.4,0.5,0.4
                  c0.1,0,0.1,0,0.2-0.1c0.4,0.1,2.3,0.1,2.2,0.5c-0.2,0.1-0.6,0.4-0.2,0.5l-0.1,0.3l-0.5-0.1c-0.1-0.2-0.4-0.4-0.6-0.4
                  c-0.2,0-0.5,0-0.6,0.2l-1.2-0.2c-0.7-0.8-4.9-1.2-6-1.3l-1.3,0.4c-0.3-0.1-1.6-0.5-1.7-0.1c-0.3-0.1-0.5,0-0.6,0.3
                  c0.3,0.2,0.6,0.3,0.9,0.4l0.7,0l0.2,0.5c0,0.1-0.1,0.4-0.3,0.3c-0.3-0.1-0.4-0.5-0.7-0.5c-0.1,0-0.2,0.2-0.2,0.3l-3.3-0.6
                  c-0.4,0.1-0.8,0.2-1.1,0.3l-3.4-0.6l-0.5,0.5c-0.1-0.1-0.2-0.4-0.3-0.4c-0.1,0-0.2,0.3-0.2,0.3l-0.9,0c-0.1,0-0.3,0-0.3,0
                  c0,0.1,0.5,0.2,0.6,0.2l1,0.2l0,0.1c-0.1,0.6-1.5,0.5-2,0.4c-0.1,0-2.2-0.5-2.3-0.1L867,159z M817.4,204.8c0.1,0,0.2-0.1,0.2-0.2
                  s0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0.1S817.3,204.8,817.4,204.8z M819.5,200.9c0,0.2,0.1,0.2,0.3,0.3l-0.2-0.6L819.5,200.9z
                  M819.6,208.6c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.3,0.2-0.4l0.3-0.4l0-0.1c-0.1,0-0.3,0.4-0.3,0.5
                  C819.8,208.3,819.6,208.4,819.6,208.6z M821.9,198.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3,0,0.3-0.1c0-0.1-0.1-0.4-0.2-0.5
                  C822.1,198.3,821.9,198.4,821.9,198.5z M824.6,194.3c0,0.2-0.1,0.4-0.4,0.5l-0.4-0.3l-0.2,0.7c0,0.3,0.2,0.4,0.1,0.7l-0.3,0.5
                  l-0.3,0c0,0.2-0.2,0.6-0.4,0.7l0.3,0.2l0.6-0.9l0.3-0.2c0.4-0.8,0.9-1.5,1.2-2.4L824.6,194.3z M822.8,198.5l0.2,0
                  c0-0.1-0.1-0.2-0.3-0.3C822.7,198.4,822.7,198.5,822.8,198.5z M823.4,210.7c-0.3,0-0.5-0.1-0.4-0.4l0.1-0.4
                  C823.5,209.8,823.5,210.4,823.4,210.7z M823.6,199.9c0,0.2,0,0.3,0.2,0.3l-0.2-0.6L823.6,199.9z M823.6,197.9c0,0.1,0,0.2,0.1,0.2
                  c0.4-0.5,0.8-1,0.9-1.6c0-0.1,0-0.1,0-0.2C824.3,196.4,823.7,197.7,823.6,197.9z M825,192.7c0.1,0,0.2-0.1,0.2-0.2
                  c0-0.1,0-0.2-0.1-0.2s-0.1,0.1-0.2,0C824.9,192.5,824.9,192.7,825,192.7z M824.9,195.6c0,0.1-0.1,0.4,0.1,0.5
                  c0.1,0,0.2-0.2,0.2-0.3c0.3,0.1,0.6-0.2,0.6-0.5l-0.4-0.5C825.2,194.9,825,195.3,824.9,195.6z M825.8,191.7c0.1,0,0.2-0.1,0.2-0.2
                  l-0.3-0.1C825.7,191.4,825.7,191.7,825.8,191.7z M825.9,192.3c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C825.8,192.1,825.9,192.3,825.9,192.3z M826.2,193.2l0.4,0.7l0.1-0.4c0-0.1,0.1-0.8,0-0.8C826.6,192.6,826.2,193.1,826.2,193.2z
                  M826.3,191.8c0.1,0,0.1-0.1,0.1-0.2l-0.2-0.1C826.2,191.5,826.2,191.8,826.3,191.8z M827,204.6c0,0.1,0,0.4,0.2,0.4l-0.2-0.6
                  L827,204.6z M829.8,157.3l-0.6-0.2C829.2,157.4,829.7,157.3,829.8,157.3z M830.1,187.1l0.3-0.2c0,0,0-0.3,0-0.3l0,0.3L830.1,187.1
                  z M830.8,201.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.2-0.2l-0.1,0C830.6,200.9,830.6,201.1,830.8,201.1z M830.9,157.4
                  c0.1,0,0.3,0,0.3-0.2l-0.6-0.2C830.7,157.2,830.8,157.4,830.9,157.4z M830.8,186.1c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C830.7,185.8,830.8,186.1,830.8,186.1z M831.9,185.3L831.9,185.3c0.1-0.1,0-0.1-0.1-0.2l-0.1,0
                  C831.6,185.2,831.7,185.3,831.9,185.3z M832.2,184.8l0.1,0c0-0.1-0.1-0.2-0.3-0.3C832,184.6,832,184.8,832.2,184.8z M832.4,184.3
                  l0.4-0.2c0.1,0,0.3,0,0.3-0.1c0-0.2-0.3-0.6-0.5-0.6c0,0-0.1,0.1-0.1,0.1L832.4,184.3z M833.7,200.8c0,0.1-0.2,0.2-0.3,0.2
                  c0-0.2-0.1-0.5-0.1-0.7c0-0.1,0.1-0.2,0.3-0.2C833.7,200.3,833.8,200.6,833.7,200.8z M833.9,199.3c0.1,0,0,0.3,0,0.3
                  c0.1,0,0.3,0,0.3,0.2c0,0.3-0.4,0.3-0.6,0.3C833.6,200,833.9,199.3,833.9,199.3z M840.6,188.1c0-0.1,0.1-0.4,0.4-0.4
                  c0.1,0,0.2,0.2,0.2,0.3L840.6,188.1z M842,180.5c0.3,0.1,0.5,0.3,0.5,0.6c0.2,0,0.4,0,0.5-0.3l0.1-0.4l-0.7-0.6L842,180.5z
                  M843.7,184.3c0.1,0,0.1-0.1,0.1-0.2s0-0.1,0-0.2s-0.2,0-0.2,0C843.6,184.1,843.6,184.3,843.7,184.3z M844.2,181.7
                  c0.1,0,0.1-0.3,0.1-0.3c0.1,0,0.4,0.1,0.5-0.1c0-0.2-0.3-0.5-0.4-0.5c-0.1,0-0.2,0-0.2,0.1C844.1,181.1,844.1,181.7,844.2,181.7z
                  M846,180.4c0,0.2,0.1,0.2,0.3,0.3l-0.2-0.6L846,180.4z M846.4,179.5l0.2,0.3l0.1-0.5l-0.1-0.5L846.4,179.5z M847.6,179.8
                  c0.1,0,0.1-0.1,0.1-0.1s0-0.1,0-0.2s-0.2,0-0.2,0C847.5,179.6,847.6,179.8,847.6,179.8z M848,179c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
                  C847.9,178.8,847.9,179,848,179z M848.7,178.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.2,0
                  C848.6,178.1,848.6,178.3,848.7,178.4z M849.6,176.7l-0.5,0.2c0,0.2-0.3,0.3-0.3,0.4c0,0.1,0.3,0.1,0.5,0.2l0.2-0.5l0.4-0.2
                  c0.2-0.6,1.4-1.8,2-2.3l-0.3,0.2C851.1,174.6,849.9,176.3,849.6,176.7z M852.4,171.5c-0.1,0.4,0.3,0.5,0.6,0.5
                  c0.1,0,0.3,0,0.3-0.2c0-0.2-0.1-0.9-0.4-1C852.8,170.8,852.5,171.3,852.4,171.5z M852.9,170.3l0.6,0.2l0.7-0.6l-0.8-0.4
                  C853.3,169.8,853.2,170.2,852.9,170.3z M854.7,168.4l-0.6,0.2l-0.4,0.3c0,0.3,0.1,0.5,0.3,0.6c0.2,0,0.4-0.1,0.4-0.3
                  c0.1,0,0.5,0.2,0.5,0c0-0.1-0.2-0.3-0.1-0.5C854.8,168.7,854.7,168.4,854.7,168.4z M854.9,168.6c0.1,0,0.3,0,0.3-0.1
                  c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.2,0L854.9,168.6z M856,167.5l-0.3-0.3l0.5-0.4c-0.1-0.2-0.3-0.4-0.5-0.4
                  c-0.2,0-0.2,0.1-0.3,0.3c-0.1,0-0.3,0-0.3,0.2c0,0.2,0.3,0.4,0.5,0.5L856,167.5z M856.2,166l0.3,0c0-0.1-0.1-0.2-0.3-0.3l-0.2,0
                  C856,165.9,856.1,166,856.2,166z M856.5,164.9c0,0.3,0.1,0.4,0.4,0.4c0.1,0,0.6,0.2,0.7-0.1c0.1-0.4-0.2-0.8-0.4-1L856.5,164.9z
                  M858.7,158.8l0.3,0l0-0.1c-0.3-0.2-0.8-0.3-1.1-0.4C857.8,158.8,858.4,158.7,858.7,158.8z M858.2,164.9c0,0.1,0.2,0.1,0.3,0.1
                  c0.1,0,0.3,0.1,0.3-0.2c0-0.2-0.1-0.5-0.3-0.6C858.5,164.5,858.2,164.6,858.2,164.9z M861,158.7c0,0.1,0.2,0.2,0.3,0.2l1.4,0.2
                  c0-0.1,0.1-0.4-0.1-0.5l-0.1,0c-0.1,0-0.2,0.1-0.3,0.2l-0.9-0.4C861.2,158.4,861,158.6,861,158.7z M863.2,159
                  c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.2,0.4-0.4c0.1-0.1,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.2-0.4,0.3l0,0.2
                  C863.4,159,863.2,159,863.2,159z M868.4,161.3c0.1,0,0.2,0,0.3,0c0-0.1-0.1-0.2-0.3-0.2l-0.2,0
                  C868.2,161.2,868.3,161.2,868.4,161.3z M869.8,161.3c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C869.7,161,869.7,161.3,869.8,161.3z
                  M883.7,158.5l-0.3,0.5c-0.4-0.1-0.2-0.3-0.5-0.4c0.2,0,0.3-0.2,0.4-0.5C883.7,158.2,883.4,158.5,883.7,158.5z"/>
               <path d="M883.3,160.7l1.2,0.1l2.6-0.8c0.3,0,1.8-0.1,1.8-0.2c0.2,0,0.5,0.1,0.7,0l1.8-0.2l0.3-0.3l0.3,0.6l1.2,0.1
                  c0.2-0.3,1.1-0.2,1.4-0.1c0.1-0.1,0.4-0.1,0.4-0.2c0-0.1-0.1-0.2-0.2-0.2l0.5-0.2c0.3,0.2,0.9,0.1,1.3,0c0.6,0.2,0.4,0.1,0.8-0.1
                  c0.2,0.1,0.5,0.2,0.7,0c0.5,0.2,1-0.1,1.4-0.1c0.3,0.1,0.6,0.2,0.8,0l0.5-0.1c0.1,0,0.5,0.2,0.5,0c0.3,0.1,0.6,0,0.8-0.1
                  c0.1,0,0.6,0.3,0.7,0c1,0.2,4.8,1.2,5.2,0.8c0.3,0.1,0.6,0.4,0.9,0.2l1.1,0.4c0.1-0.1,0.3-0.2,0.4-0.2c1.1,0.2,2.1,1,3.3,1.2
                  s1.6,0.5,2.8,1.2c-0.2,0.5,1.1,0.8,1.3,0.9c0.8,0.3,1.6,1.2,2.2,1.8c0.2,0.2,0.5,0.3,0.7,0.4l0.5,0.1c0.8,0.5,1.7,1.6,2.1,2.5
                  c-0.1,0.3,0.4,1,0.7,1.2c-0.1,0.4,0.5,0.2,0.4,0.6l0,0.1c0.3,0.1,0.2,0.4,0.4,0.5l0.4,1.3c0.2,0,0.5,0.3,0.4,0.5l0,0.1
                  c0.2,0.4,0.4,0.8,0.3,1.2l-0.1,0.3l0.4,0.7c-0.1,0.2,0.3,2.6,0.2,3.4c-0.1,0.3-0.2,0.6-0.2,0.9c0.1,0.2-0.1,0.4,0,0.7l0.3,0.2
                  c-0.3-0.1-0.4,0.2-0.5,0.4c-0.1,0.4,0.1,1.2-0.1,1.6l0.1,0.5c-0.7,2.3-1.7,4.6-2.5,6.8c-0.5-0.3-1.4,1.8-1.6,2.1
                  c-0.5,0.9-1.2,1.6-1.8,2.5c-0.4,0.3-0.8,0.7-1,1.1l-0.4,0.1c0,0.1-0.4,1.5-0.7,1.4c-0.1,0,0-0.3,0-0.4l-0.1,0
                  c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.3,0.1,0.5c0,0.2-0.2,0.2-0.3,0.2l-0.3,0l0,0.1c-0.1,0.3,0.2,0.2,0.1,0.5l-0.6,0.1
                  c0.3,0.1-0.2,0.1-0.2,0.1c0,0.1-0.2,0.9-0.4,0.8c-0.1,0,0-0.2,0-0.3l-0.7,0.5c-0.2,0.6-0.9,0.7-1.2,1.1l-0.8,0.5l-0.4,0.8
                  c-0.3-0.1-0.5,0.1-0.5,0.3l0,0.1c-1,0.7-1.9,1.9-2.8,2.8l-0.5,0.1c-0.1,0.3-0.3,0.5-0.6,0.6c-0.1,0.4-0.4,0.4-0.6,0.7
                  c-1.2,0.5-2,1.6-3.2,2c-0.6-0.2-0.8,0.8-1.3,0.7l-0.1,0l-1.3,0.7c0,0.2-0.2,0.5-0.4,0.4l-0.1,0c-0.1,0.3-0.4,0.4-0.7,0.4
                  c-0.5,0.2-1,0.4-1.5,0.7c-0.1,0.2-0.3,0.3-0.5,0.4c-0.7,0.5-1.5,0.7-2.2,1c-0.4,0.8-1.6,0.4-2,1.1l-0.6,0.3l-1.3,0.1l-0.7,0.3
                  c0,0.1-0.2,0.3-0.3,0.2c-0.2,0-0.5-0.3-0.7,0l-0.7,0c-0.1,0.3-0.3,0.5-0.6,0.5l-0.1,0l-0.3,0.6c-0.6-0.2-0.5,0.6-0.9,0.3
                  c0,0.1-0.2,0.2-0.3,0.2l-0.3-0.1l-0.7,0.3c-0.2-0.1-0.6-0.3-0.8,0.1l-0.8,0.2c-0.3-0.2-0.5-0.1-0.6,0.2c-0.5-0.2-0.2,0.3-0.6,0.3
                  c-0.7-0.1-1.8,0.2-2.6,0.2c0.2-0.5-0.6-0.1-0.7,0c-0.6-0.2-1.2,0.3-1.8,0.2c-0.2,0-0.4-0.1-0.4-0.4c0-0.1,0.1-0.2,0-0.2
                  c-0.4-0.1-0.8,0.2-1,0.3c-0.3-0.2-0.7-0.1-1.1,0.1l-0.6-0.3l-0.3,0.2l-0.3-0.2l-0.7,0.5c-0.3-0.1-0.6-0.6-1.1-0.7
                  c-0.1,0-0.3,0.3-0.3,0.4c-0.7-0.1-1.5-0.3-1.8-1c-0.1,0.6-2-0.4-2.4-0.6c-1.8-0.8-3.8-1.8-5.6-2.5l-0.2-0.8
                  c-2.1-0.9-2.4-3.2-3.3-3.6c-0.4-1.3-0.6-2.4-1.2-3.6l-0.8-0.4c0-0.1,0.2-0.6,0-0.7c0-0.1,0.1-0.5-0.1-0.5l0.1-0.8
                  c-0.2-0.4-0.2-0.9-0.1-1.3c0-0.2,0.3-0.3,0.5-0.3l0.1,0c0.2-0.3,0.4-0.5,0.5-0.9l-0.2-1c0-0.1,0.1-0.4-0.1-0.5
                  c-0.2,0-0.8,0.2-0.8-0.1c0.1-0.5,0.3-1,0.5-1.5l0.3-0.1l-0.1-0.5c1.2-2.8,2.2-5.1,3.5-7.6c0.7-0.5,0.6-1.6,1.4-2.1l0.1-0.4
                  l0.3-0.1c0.1-0.3,0.3-0.6,0.6-0.7c0-0.1,0.1-0.4,0.3-0.4l0.1,0c0.1-0.3,0.3-0.6,0.6-0.7l-0.2-0.6c0-0.2,0.2-0.7,0.5-0.7
                  c0.9,0.2,0.5-1.2,1.4-1l0.1,0l0.4-1.1l0.7-0.2l0.4-0.8c0.1,0,0.3,0.1,0.3,0.4c0,0.1-0.2,0.1-0.2,0.1c-0.1,0.3,0,0.6,0.3,0.7
                  c0,0.1-0.1,0.3,0,0.4c0.2,0,0.4-0.2,0.6-0.2c0.3,0.2,0.5-0.7,0.5-0.9c0.2-0.3,0.4-0.5,0.6-0.6c0.2,0.1,0.4-0.2,0.4-0.4
                  c0.3-0.8,1-1.2,1.6-1.9c-0.1,0-0.4-0.1-0.4-0.4c0-0.3,0.5-0.5,0.6-0.9c0.4-0.3,0.8-0.5,1.2-0.8c0.1-0.2,0.2-0.5,0.4-0.4l0.3,0.1
                  l0.5-0.4c0.1,0,0.4,0.1,0.4,0c0-0.1-0.1-0.2-0.2-0.2l0.4-0.4c0.2-0.6,1.2,0,1.4-0.5c0.2-0.6,0.9-0.7,1.1-1.3l0.6-0.2l0.6-0.6
                  c0.4,0.2,0.3-0.4,0.6-0.3l0.1,0l0.7-0.2c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.3,0.1,0.5,0.2c-0.7,0.4-1.5,0.7-2.2,1l-0.1,0.3
                  c0,0.1,0,0.2,0.2,0.3c0.3,0,0.6-0.3,0.9-0.2l0.1,0c0.9-0.4,1.8-0.9,2.7-1.4c0.6,0.2,0.9-0.5,1.4-0.4l0.1,0c0.2,0,0.5-0.1,0.5-0.4
                  c0-0.1-0.2-0.2-0.3-0.2l-1.5,0.7c-0.1,0-0.3-0.1-0.3-0.3c0-0.2,0.4-0.3,0.5-0.4c0.1-0.4,1.3-0.2,1.6-0.8l1.3-0.5
                  c0,0,0.3,0.2,0.3,0c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.3,0.3,0.4,0c0.7,0.1,1.9-0.5,2.6-0.7
                  c0.7,0.1,1.3,0.2,1.9,0.4c0.3-0.1,0.6-0.3,1-0.4l-0.2-0.1l-1.2,0c-0.3-0.1-0.6-0.3-0.9-0.3c-0.3-0.1-0.6-0.2-0.9-0.1
                  c-0.3-0.4-0.9-0.1-1.3,0c0-0.1,0-0.6-0.2-0.6c-0.1,0-0.2,0.1-0.3,0.2c-0.3-0.3-0.6-0.1-0.7,0.2c-0.3-0.1-1.1-0.3-1.3,0.1
                  c-0.1,0-1.4-0.6-1.5-0.4c-0.4,0.2-0.4-0.6-0.7-0.1l-1-0.5c-0.2,0.3-0.9,0.1-1.2,0c-0.3-0.3-0.3,0.2-0.6-0.2l-0.8,0.5l-0.8,0
                  l-0.4-0.2c-0.2-0.3-0.4-0.1-0.5,0.1c-0.3-0.3-1.1,0.1-1.5,0.3c-1.1-0.6-0.8,0-1.7,0.2c-0.5-0.4-1.3,0.1-1.8,0.4l-0.5-0.4l0.7-0.6
                  l1-0.4l-0.4-0.1c-0.4-0.1-0.7,0.3-1.1,0.5c-0.6-0.2-1.1,0.2-1.6,0.5l-0.6-0.2c0.4-0.3,0.8-0.9,1.4-0.8l0.1,0
                  c0.6,0,1.3-0.6,1.7-0.5l0.1,0c0.4-0.2,0.8-0.6,1.3-0.5l0.1,0c0.4-0.2,0.7-0.4,1.2-0.3l0.2,0l0.5-0.4l0.3,0.1l0.2-0.3l0.5,0.2
                  l0.1-0.3l0.2,0c0.4,0.1,0.3,0.4,0.6-0.3c0.4,0.2,1.9-0.1,2.4-0.3l-1,0.2c-0.2-0.3-0.4-0.1-0.7-0.3l0-0.8l-0.1,0
                  c-0.5-0.1-1,0.4-1.3,0.7c-0.5,0-1.1,0.2-1.6,0.1c-0.2,0-0.7,0-0.6-0.3l0.1-0.3c0.4,0,2.6,0.1,2.7-0.7c0.2-0.3,1.1-0.3,1.5-0.2
                  l0.5-0.4l0.2,0.7l-0.2,0c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.2,0,0.2s0.1-0.1,0.1-0.1l0.2-0.1L883.3,160.7z M860.2,204.8
                  c0.2,0.3,0.3,0.6,0.2,0.9l-0.1,0.3c0,0.6-0.1,1.3,0.7,1.4c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.2-0.2-0.3c-0.3-1.1-0.5-2.2-0.6-3.3
                  c-0.2,0-0.2-0.2-0.2-0.3l0-0.1l-0.1,0.3L860.2,204.8z M860.6,198.2c0,0.1,0,0.2,0.1,0.2c0.1,0,0.1-0.1,0.1-0.2
                  S860.8,198.2,860.6,198.2L860.6,198.2L860.6,198.2z M860.9,196.9c0.1,0,0.2,0,0.2-0.1l-0.3-0.1
                  C860.8,196.8,860.8,196.9,860.9,196.9z M861.4,193.9c0,0.1,0,0.2,0.1,0.3l-0.4,1.6l0.4-0.4l-0.1-0.3c0.1-0.4,0.4-0.9,0.5-1.4
                  c0,0,0-0.2,0-0.2l-0.1,0.1C861.6,193.6,861.5,193.8,861.4,193.9z M862,193l0-0.6C861.8,192.7,861.6,192.8,862,193z M863.8,188.2
                  c0,0.1-0.1,0.3-0.2,0.3c-0.1,0-0.1-0.9-0.5-1c-0.2,0.5-0.5,1-0.8,1.4l0.8,0.2c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0,0.2,0,0.2
                  c-0.1,0.4-0.3,0.7-0.6,0.9c0,0.3,0.5,0.5,0.7,0.5l0.9-1.3l-0.4-0.5c0.1-0.5,0.5-0.9,0.7-1.3l0.5-0.4l0.2-0.5l-0.5-0.2
                  C864.6,187,864.1,187.6,863.8,188.2z M862.9,191c0,0.1,0.2,0.2,0.3,0.3l0-0.1c0-0.1-0.1-0.2-0.2-0.3L862.9,191z M866.3,184.1
                  c-0.2,0.1-0.9,0.4-0.9,0.6c0,0.2,0.2,0.3,0.3,0.4l0.8-0.3l0.4-0.7c0.3,0,0.7-0.2,0.8-0.5l-0.4-0.7
                  C867,183.5,866.5,183.4,866.3,184.1z M867.8,184.1c0,0.2,0.1,0.3,0.2,0.3c0-0.1,0-0.7-0.1-0.8l-0.1,0.1L867.8,184.1z M868.3,182.6
                  c-0.1,0-0.4-0.1-0.4,0.1c0,0.2,0.1,0.5,0.3,0.6c0.1,0,1-1.2,1.1-1.3l-0.5-0.2C868.8,182.1,868.4,182.3,868.3,182.6z M870.1,178
                  c0.2,0,0.4,0.2,0.4,0.5c-0.1,0.5-0.5,0.4-0.6,0.9c0,0.2-0.1,0.4-0.3,0.5c-0.1,0-0.2-0.3-0.5-0.4L870.1,178z M869.8,181.7
                  c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.3-0.2C869.7,181.5,869.7,181.7,869.8,181.7z M873.7,176.2c0,0.1,0,0.4,0.1,0.5l0.1-0.1
                  l0.6-0.5c0-0.1,0-0.3,0-0.3l-0.1,0.3C874.1,175.8,873.8,175.7,873.7,176.2z M906,171.3l-1.4,0.1c-0.2,0.5-1.2,0.3-1.4,0.8
                  l-1.4,0.2c-0.3,0.7-1.1,0.5-1.3,1c0.1-0.1,0.6-0.3,0.5-0.1l0,0.1l0.7,0.5l0.5-0.2c0.1,0,0.3,0.1,0.3,0.3c0,0.2-0.4,0.4-0.5,0.4
                  c-0.4,0-1.3,0.1-1.4,0.4c-0.4,0-1.2,0.1-1.4,0.4c-0.8,0.2-2.1,0.4-2.7,0.9c-0.2,0.4-2.7,1.5-2.8,1.7c-0.5-0.2-1,0.4-1.3,0.7
                  c-0.9,0.4-3.3,2-3.9,2.2c-0.4-0.1-0.7,0.8-1.3,0.7l-0.1,0c-0.3,1-2.1,1.5-2.2,1.7c-1.8,1.2-3.1,2.7-4.5,4.2l-0.4,0
                  c-0.1,0.3-0.6,0.6-0.8,0.8c-0.1,0.2-0.3,0.5-0.5,0.4l-0.3,0.7c-0.4,0.2-1.1,0.9-1.3,1.3c-0.3,0.8-0.9,1.3-1.1,2.1l0.1,0.5
                  c-0.2,0.3-0.3,0.8-0.7,0.9l-0.5,0.5l0.1,0.7c0.1,0.1,0.2,0.3,0.2,0.3c-0.1,0.5-1,1.6-0.1,1.8c-0.3,0.6,0.3,0.9,0.2,1.6
                  c-0.1,0.4-0.1,0.7-0.1,1c0.1-0.1,0.4-0.3,0.4-0.1l-0.1,0.3l0.7,0c0,0.1-0.1,0.3,0,0.3c0.1,0,0.3-0.1,0.4-0.2l0.1,0.6l1.1,0.3
                  l0.6-0.3c0.6,0.1,1.1,0.4,1.6,0.6c0,0.2,0.2,0.4,0.3,0.4c0.1,0,0.3-0.2,0.3-0.3c0.7-0.2,1.4-0.4,2.1-0.7c0.3,0.1,0.5-0.3,0.8-0.2
                  c0.4,0.1,0.7,0.3,1,0.4l1.2-1.1l1.4-0.4l0.6,0.2c0.4-0.4,1.2-0.6,1.7-0.9l0.6,0c0.4,0.1,1.2-0.5,1.5-0.7c0.4,0,1-0.6,1.3-0.7
                  c0.3,0.2,2.7-1.2,2.9-1.4c0.4,0.2,1-0.6,1.5-0.6l0.1,0c0,0,0.1-0.3,0.2-0.3c0.1,0,0.2,0.3,0.2,0.3l0.5,0.2l0.3-0.5
                  c0.1,0,0.2,0,0.3-0.2c0-0.1,0-0.3,0.2-0.3l0.1,0l0.7-0.4c0-0.1,0.3-0.7,0.6-0.6c0.1,0,0.1,0.2,0.1,0.2c-0.1,0.3-0.3,0.6,0,0.7
                  l0.3,0l1.8-1.6l0.4-0.2l0-0.1c0-0.1,0-0.2-0.2-0.3l1.4-1.8c0.1,0,0.2,0,0.2-0.1c0-0.2-0.1-0.4-0.1-0.5c0.4-0.3,0.7-0.8,1-1.1
                  c0.1-0.3,0.5-0.4,0.7-0.8c0.2-0.1,0.5-0.4,0.6-0.7c0.3,0.1,0.4-0.2,0.4-0.4l0-0.1c0.2-0.4,0.5-0.9,1-1l0.9-1.3
                  c0.4,0.2,0.9-1.2,1.1-1.5c0.6,0.3,1.7-3.1,2-3.7c0.6-1.5,1.3-2.6,2-4.1c-0.1-0.5-0.4-1.9-1-2c-0.2,0-0.3,0.1-0.4,0.2
                  c-0.3-0.2-1.5-1.5-1.8-1.5c-0.4-0.1-0.1,0.4-1.1-0.3l-0.1-0.5l-0.2-0.1c-0.2,0-0.4,0-0.5,0.1l-0.5-0.5l-0.4,0.1
                  c-0.3-0.1-0.7-0.3-1.1-0.3l-0.9-0.7l-0.3,0.2L906,171.3z M875.7,165.1c-0.1,0-0.1,0-0.2,0c0,0.1,0,0.1,0.1,0.1
                  C875.6,165.2,875.7,165.1,875.7,165.1z M877.2,164.7l-1.3,0.4l0.4,0.2l0.7-0.2c0.1-0.1,0.3-0.2,0.5-0.1c0.1,0,0.2,0,0.3,0.1
                  l0.5-0.3l-0.6,0.1L877.2,164.7z M896.2,197.4l-0.3-0.1c-0.2,0.6-0.8,0.9-1.4,0.7L894,198c0-0.2-0.2-0.4-0.3-0.1l-1.5,0.7l-0.3,0.8
                  l0.6-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.3-1,0.9-1.3,0.8l-0.3-0.1l-1.3,0.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.4-0.3,0.5-0.4l0.3-0.5
                  c-0.1-0.1-0.1-0.4-0.2-0.4c-0.1,0-0.1,0.1-0.1,0.1l-1.4,0.4c-0.2,0.5-1.2,0.2-1.5,0.7l-1.4,0.2l-0.4,0.5c0.1,0.1,0.4,0.4,0.4,0.6
                  c0,0.1-0.1,0.1-0.2,0c-0.2,0-0.7,0-0.8,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.3-0.3-0.4c-0.1,0-0.1,0.1-0.1,0.1l0.2,0.2
                  c0,0.1-0.1,0.3-0.3,0.3l-0.1,0l-0.6,0c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3,0-0.4,0.2-0.5,0.4
                  c0.1,0.1,0.4,0.4,0.1,0.4c-0.7-0.1-1.4-0.3-2.2-0.4l-0.5,0c-0.1,0-0.4-0.1-0.4,0c0,0.1,0.2,0.1,0.2,0.1c-0.4,0.1-0.8,0.2-1.2,0.1
                  c-0.3-0.3-0.7-0.8-1-0.9c-0.1,0-0.1,0-0.2,0l-0.7,0.4c0.3,0,0.4,0.1,0.4,0.4c-0.1,0.4-0.1,0.5,0.3,0.6l0.1,0
                  c0.3-1.1,1.8-0.1,2.7,0c0.2,0,0.5-0.1,0.7,0c0.1,0,0.2,0,0.2,0.2l0,0.1c0.6,0,1.7,0,2.3,0.3c0.1-0.3,0.5-0.4,0.8-0.5l0.7,0.1
                  c0.1,0,0.7,0.2,0.7,0.1c0-0.1-0.2-0.2-0.3-0.2l0.5-0.4c0.7,0.1,0.7,0,1.3-0.4l0.9-0.1c0,0,3.6-1.8,3.9-1.8l0.1,0
                  c0.7,0,1.3-0.4,1.9-0.9c-0.4-0.1,0.2-0.6,0.3-0.8l2-0.5c0.1-0.3,0.4-0.4,0.7-0.4l0.5-0.3c0.1,0,0.3,0,0.3-0.2
                  c0-0.2-0.1-0.5-0.1-0.6c0.6-0.2,1.4-0.5,1.9-0.9l-0.3-0.1c-0.2,0-0.3,0.2-0.6,0c-0.1,0.3-0.5,0.4-0.7,0.8
                  C896.8,197,896.7,197.4,896.2,197.4z M877.6,174.2c0,0.1-0.1,0.5,0,0.5c0.1,0,0.1-0.3,0.1-0.3s0.4,0.1,0.4,0.1
                  c0-0.1-0.4-0.5-0.5-0.6L877.6,174.2z M879,164.4c0.1,0,0.2,0,0.3,0.1l0.2-0.1C879.4,164.4,879.1,164.4,879,164.4L879,164.4z
                  M880.2,215.3c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.2-0.1l0.4-0.3l-0.4,0.2C880.5,215.3,880.3,215.2,880.2,215.3z M880.7,164.2
                  l0.2,0.1l0.2,0C881,164.2,880.9,164.2,880.7,164.2L880.7,164.2z M884.4,204.7c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.4-0.1-0.6,0
                  c-0.2,0-0.4,0-0.6,0.1c-0.6-0.3-1-0.5-1.1,0.3c0,0.1,0,0.1,0,0.2c0.1,0,1-0.1,0.9,0.3c0,0.1-0.2,0-0.2,0c-0.4-0.1-0.5,0.1-0.5,0.5
                  l0,0.1l-0.6-0.7c-0.1,0-0.4,0.6-0.4,0.7c0,0.3,0.2,0.4,0.5,0.5c0.4,0.1,0.8,0,1.2-0.1c0.1-0.3,0.2-0.6,0.5-0.7
                  c0.2,0.1,0.8,0.4,1,0.1l0.8-0.3c-0.2,0-0.6-0.1-0.5-0.4c0-0.1,0.1-0.4,0.2-0.3c0.1,0,0.2,0,0.3-0.2L884.4,204.7z M882.1,215.3
                  l0.2,0c0-0.1,0-0.3-0.2-0.3l-0.1,0.1C882.1,215.1,882,215.3,882.1,215.3z M884.9,162.3l0.1-0.3c-0.1-0.1-0.1-0.1-0.3-0.1
                  c-0.4-0.1-0.9,0.3-1.2,0.5l0.7,0C884.6,162.6,884.6,162.3,884.9,162.3z M884.5,214.5c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2,0,0.2-0.1
                  c0-0.1,0-0.2-0.1-0.2L884.5,214.5L884.5,214.5z M885.4,204.7c-0.2,0-0.4,0-0.5,0.1l0.3,0.1c0.1,0,0.4,0,0.4-0.2L885.4,204.7z
                  M886.4,161.5l-0.2,0c0,0.1-0.1,0.3,0.1,0.3l0.1-0.1l0.3-0.2L886.4,161.5z M886.8,202.1c0.4,0.1-0.1,0.2-0.3,0.2l-0.2-0.7
                  c0.3,0.2,0.5-0.1,0.8,0l0.1,0C887.2,202,886.9,201.8,886.8,202.1z M887.4,203.5c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2
                  L887.4,203.5L887.4,203.5z M889.7,161.3L889.7,161.3c0.2,0,0.5-0.1,0.5-0.3c0-0.1-0.2-0.2-0.3-0.2c-0.2,0.5-0.5-0.2-0.7,0.4
                  c-0.4,0.1-0.8,0.2-1.2,0.2l0.6,0.2C888.6,161.2,889.4,161.3,889.7,161.3z M888.4,213.5c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2
                  L888.4,213.5L888.4,213.5z M889.6,213.3l0.1,0c0-0.1-0.2-0.3-0.2-0.4L889.6,213.3z M892,201.9c0,0.1,0.3,0.2,0.3,0.2s0.1,0,0.2,0
                  c0-0.1,0-0.3-0.1-0.5C892.3,201.6,892.1,201.7,892,201.9z M894.1,167.1c0.1,0.1,0.1,0.2,0.2,0.2s0.2,0,0.2-0.1L894.1,167.1z
                  M894.6,198.8l-0.3,0.2c0.1-0.3-0.1-0.2-0.1-0.5l0-0.1c0.2-0.1,0.5-0.1,0.7,0l0.2,0C895,198.6,894.8,198.8,894.6,198.8z
                  M896.8,198c0,0.1-0.2,0.1-0.3,0.1l-0.1,0l-0.7,0.6c-0.1,0-0.4-0.1-0.4-0.3c0-0.3,1-0.6,1.2-0.9L896.8,198z M897.2,199.3
                  c0,0.2-0.2,0.6-0.5,0.7c0.4,0,0.8-0.4,1.1-0.6c0.2,0.1,0.3-0.2,0.4-0.3C897.9,199.3,897.5,199.3,897.2,199.3z M901.1,198.1
                  c-0.4-0.1-0.2,0.6-0.6,0.4c-0.1,0.1-0.2,0.1-0.4,0.1l-0.3-0.1c-0.1,0.4-0.7,0.7-1,0.9c-0.2,0-0.8,0.1-0.8,0.4
                  c0,0.2,0.2,0.3,0.4,0.4c0.7-0.6,1.6-1.1,2.5-1.4l0.4-0.5L901.1,198.1z M900.6,172.1c0,0.1,0.2,0.2,0.3,0.2l0-0.1
                  c0-0.1,0-0.3-0.2-0.3L900.6,172.1z M901.4,198.2l0.2-0.3l0.7-0.2c0.2-0.5,0.4,0.1,0.6-0.8c0,0,0-0.1-0.1-0.1
                  c-0.6-0.1-0.9,0.7-1.5,1.1l-0.1,0.2c0,0-0.1,0.1,0,0.1L901.4,198.2z M903.6,196c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2
                  c0-0.1,0-0.2-0.1-0.2L903.6,196L903.6,196z M904.6,191.6l-0.4,0.2l0.7-0.2c0.2-0.4,0.5-0.4,0.7-0.9l-0.4,0.4
                  C904.7,191,904.8,191.2,904.6,191.6z M905.6,192.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2s-0.1,0.1-0.2,0
                  C905.5,191.8,905.4,192,905.6,192.1z M906.3,189.6l-0.4,0.4l-0.1,0.3c0,0,0,0.1,0.1,0.2c0.1,0,0.3-0.5,0.5-0.5
                  c0.1,0,0.1,0,0.1,0.1c0.2-0.5,0.7-1,0.8-1.6c-0.1,0-0.4,0.7-0.4,0.8C906.6,189.3,906.3,189.4,906.3,189.6z M907.3,193.2
                  c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2l-0.2-0.1L907.3,193.2z M907.6,192.6c0,0.1,0,0.2,0.1,0.2c0.1,0,0.2-0.1,0.2-0.2
                  c0-0.1,0-0.2-0.1-0.2L907.6,192.6L907.6,192.6z M910.7,189.9l-1,0.3c0.4,0.3-1.1,1.1-1.2,1.7c0,0-0.1,0.4,0,0.4
                  c0.1,0,0.2-0.1,0.2-0.2c0.2-0.5,0.8-0.6,0.9-1c0.2,0,0.4-0.3,0.4-0.4C910.4,190.5,910.6,190.2,910.7,189.9l0.3-0.2
                  c0-0.1,0.1-0.4,0.4-0.4l0.1,0c0.1-0.2,0.1-0.8,0.5-0.7l0.1,0c0-0.1,0-0.4,0-0.4c-0.1,0-0.2,0.3-0.2,0.3
                  C911.3,188.3,910.9,189.5,910.7,189.9z M910,184.8l0.3,0.1l0.2-0.5l-0.2-0.3L910,184.8z M910.4,189c0,0.1,0,0.2,0.1,0.2
                  c0.1,0,0.1-0.1,0.1-0.1l0-0.1C910.7,189,910.5,189,910.4,189L910.4,189z M911.1,186.5l0-0.7C911,186,910.8,186.3,911.1,186.5z
                  M912.1,174.9l0.2,0.1c0,0.1-0.5,0.3-0.6,0.5l-0.6,0.2l0.6-1.2L912.1,174.9z M911.7,181.9c0,0.1-0.1,0.3,0,0.4
                  c0.1-0.3,0.7-1.7,1-1.8l0.3-0.7l-0.4-0.7C912.3,180.1,911.9,180.9,911.7,181.9z M912.9,183.9l0-0.6
                  C912.8,183.5,912.7,183.8,912.9,183.9z M913.2,183.1C913.2,183.1,913.3,183.1,913.2,183.1c0.1-0.2,0-0.3-0.2-0.3
                  C913.1,182.8,913.1,183.1,913.2,183.1z M913.9,177c0,0.1,0,0.2,0.1,0.2c0,0,0.1-0.1,0.1-0.1L913.9,177z M917.9,182.8
                  c0,0.1-0.1,0.4,0.2,0.4l0-0.2c0-0.1,0.1-0.4-0.1-0.5C918,182.5,917.9,182.7,917.9,182.8z"/>
                     <path d="M941.2,223.7c1.2-0.2,2-0.9,3.3-0.7c0.4-0.5,1-0.8,1.6-0.9c0.1,0,0.1,0,0.2,0c0-0.2-0.2-0.4-0.3-0.6
               c-0.2,0.2-0.6,0.2-0.8,0.2l-0.7,0.5l-1.7-0.3c0,0.1,0.9,0.4,0.9,0.5c-0.1,0.4-2.4,0.7-2.9,0.6l-0.6,0.5c0-0.1,0.1-0.3-0.2-0.3
               c-0.2,0-0.9,0.6-1.1,0.8c-0.1,0-0.1,0-0.2,0c0,0.1,0.3,0.4,0.4,0.4c0.1,0,0.2,0,0.2-0.1l1.1-0.4c0.1,0,0.4,0.1,0.4,0.3
               c0,0.1-0.2,0.3-0.4,0.4c0,0.2,0.5,0.3,0.6,0.3c-0.1,0.3-0.4,0.2-0.6,0.2h-0.3c-0.2,0-0.4,0.2-0.4,0.3c0,0.2,0.3,0.3,0.4,0.3v0.8
               c-0.3-0.1-0.5,0-0.6,0.3h-1.6c0.2,0,0.4-0.2,0.4-0.4c0-0.3-0.7-0.1-0.6-0.5c0-0.1,0.2-0.3,0.3-0.3l-0.7,0.2
               c-0.4-0.1-0.8-0.3-0.9,0.2c0.1,0.1,0.4,0.6,0.1,0.5c-0.3,0.2-0.5,0.2-0.8,0.2l-0.1-0.6c0.1,0,0.5,0.1,0.5-0.1
               c0-0.1-0.3-0.2-0.4-0.2c-0.3,0-0.5,0.1-0.6,0.4v0.1h-1c0,0.1-0.2,0.1-0.2,0.1c-1.2-0.2-0.3,0.5-1.1,0.4l-0.6-0.2
               c0,0.2-1.4,0.4-1.7,0.3l-0.4,0.2c-0.5-0.1-1.5-0.3-1.6,0.2v0.1c-0.3,0-0.8-0.4-0.8,0.1h-1.6c-1.2,0.8-3.6,0.7-4.8,0.4
               c-0.7,0.4-1.1,0.2-1.8,0.1l-0.8,0.2c-0.9-0.2-1.7-0.1-2.6-0.2c-0.2,0.2-0.5,0.2-0.7,0.2c-1.2-0.2-2.1,0.2-3.2,0
               c-2-0.4-4-1.1-6-1.3c-0.1,0.4-1.4,0.3-1.7,0.2c-0.7-0.1-0.1-0.8-0.6-0.9c-0.1,0-1,0.3-1,0.1s0.2-0.5,0.3-0.7
               c0.4,0,2.5,0.4,2.6-0.1c0-0.3,0.1-0.4,0.4-0.3c0.5,0.1,1.2,0.4,1.7,0.5l1.8-0.2l0.3-0.3l0.6,0.1c0.3-0.3,2.8,0,3.3,0.1
               c0.2-0.2,0.5-0.2,0.7-0.2c0.1-0.6,0.6,0,0.7-0.5c-1.4,0.4-4-0.4-4.7,0.1c-0.2,0-0.6-0.1-0.8,0.1l-0.8-0.4
               c-0.7,0.2-1.5,0.3-2.2,0.1c-0.3-0.1-0.8-0.2-0.9,0.2c-1.5-0.3-3.1,0.1-4.6-0.2c-0.2,0.6-1.5,0.1-1.9,0c0,0.2-0.3,0.2-0.5,0.2
               c-0.3,0-0.8-0.4-0.9,0.1l-6.1-0.7c0,0.1,0,0.2-0.2,0.2s-0.5-0.4-0.4-0.6c0.1-0.4,1.3-0.1,1.5,0c0-0.3,0.5-0.2,0.6-0.2
               c1.3,0.1,2.3,0,3.6-0.1c0.1,0,0.6,0.2,0.7,0c0-0.2-0.4-0.5-0.3-0.7c0-0.1,0.1-0.3,0.3-0.3c1.7,0.3,3.2,0,4.7,0.2l0.7-0.2l0.8,0.2
               l1.5-0.3l0.2-0.3c0.5,0.2,1.1,0.1,1.6,0c-0.1,0.2,0.3,0.3,0.3,0l0.1-0.6c0.5,0.1,1,0.5,1.5,0.6c0.9,0.2,0.7-0.5,1.2-0.4l0.6-0.2
               c0.7,0.1,2.1-0.5,2.8-0.4c0.1,0,0.3,0.1,0.3,0c0-0.2-0.4-0.3-0.5-0.3l-1-0.2c-0.1,0.8-1.7,0.1-2,0.5c-0.5-0.1-0.7-0.2-1.1,0.1
               c-1.6-0.3-2.8,0.1-3.7,0c-0.3,0-0.6-0.3-0.8-0.4c-0.7-0.1-0.8,0.5-1.4,0.4c-0.2,0-0.9-0.4-1.1-0.5c-0.5-0.2-0.2,0.6-0.8,0.5
               c-0.4-0.1-0.8-0.4-1.2-0.6c-0.1,0.1-0.2,0.3-0.3,0.2c-0.1,0,0-0.2,0-0.3c-0.7-0.1-1.2-0.6-1.9-0.7l0.4-0.2c0.3,0,0.7,0,0.8-0.2
               c0.2,0.2,0.5,0.2,0.5-0.1l0.9,0.4c1,0,2,0,3-0.1c0.1-0.3,0-0.7-0.4-0.8c0.1-0.7-0.6-0.2-0.5-0.8l-1.2-0.2l0.3-0.5
               c0.8,0.1-0.3-0.6,1.5-0.3l0.3-0.2c0.4,0.1,0.8,0.1,1.1-0.1c1,0.2,3.4-0.9,5-0.6l0.1-0.2c0.3,0.1,1.3,0.2,1.4-0.1
               c0.2,0,0.4,0,0.6-0.2l0.7,0.1l0.3-0.2l0.7,0.1l0.2-0.3c0.1,0,0.3,0,0.3-0.2c0.1-0.4,2-0.6,2.3-0.6s0.6,0.2,0.7,0.4l0.8-0.2
               c0-0.2,0.3-0.3,0.5-0.2h0.1c0-0.1,0.1-0.5,0.2-0.5c0.1,0,0.3,0.3,0.3,0.4c0.7,0.1,1.3-0.2,2-0.1c0.3-0.4,1.1-0.1,1.4-0.1l0.1-0.2
               c0.2,0,0.5,0,0.7-0.2c0.4,0.1,2.8-0.4,2.8-0.4l0.3-0.3l0.7,0.1c0.1-0.2,0.4-0.2,0.6-0.2c0.1-0.2,0.5-0.1,0.6-0.1l0.1-0.3
               c1.2,0.2,1.8-0.3,2.7-0.1l0.2-0.3l0.7,0.1l0.5-0.5l1.4-0.1c0-0.3,0.3-0.3,0.5-0.3h0.1c0.1-0.4,0.4-0.5,0.7-0.4
               c0.3,0,1.6-0.1,1.6-0.5c0.5-0.1,1.1-0.3,1.6-0.4c0.4,0.1,0.5-0.2,1-0.1c0.1-0.2,0.5-0.1,0.6-0.1l0.5-0.4l0.7-0.2
               c0.5,0.1,0.9-0.7,1-1.1l0.6-0.2c1.7,0.3,0.5-0.4,1.3-0.3c0-0.2,0.2-0.5,0.4-0.4l0.7-0.5c0-0.3,0.3-0.2,0.4-0.5l0.6-0.6
               c0-0.1,0-0.3,0.2-0.3s0.3,0.3,0.4,0.5c0.4-0.2,0.6-0.6,0.9-1h0.2c-0.1-0.5-0.3-1-0.4-1.5c-0.2-0.1-0.2-0.3-0.2-0.5v-0.1l-1.5-0.9
               c-0.4-0.2-0.6-0.8-1-0.9l-0.4-0.4c-1.1-0.3-2-1.6-2.9-2.3c0.2-0.4-1.7-1-2-1.2l-0.3-0.7c0.1-0.4-0.6-0.4-0.6-0.5s0.6-0.2,0.6-0.3
               c0.3,0.2,0.6,0.4,0.8,0.7l1.1,0.3l0.6-0.2c0.1-0.8-1.5-0.9-1.9-0.9l-0.3-0.6c0.2-0.6-0.9-0.7-0.8-1.2v-0.1c-0.2,0-0.7-0.1-0.7-0.4
               c-0.5-0.5-1.1-1.2-1.9-1.3c0.1-0.4-0.6-0.5-0.9-0.5v-0.3l-0.3,0.3l0.6,0.5c0.1,0.3,0.2,0.7,0.6,0.8c0.1,0,0.6,0,0.6,0.2v0.1
               c0.2,0,1.1,0.1,1,0.4l-0.1,0.1l0.2,0.3l0.7,0.1c0,0.2,0,0.4,0,0.5v0.3l-0.7-0.4c-0.1,0.4-0.2,0.9-0.7,0.8c0-0.2-1.3-0.9-1.5-0.9
               c0.5-0.9-1.2-0.6-1.1-1.1l-0.9-1l0.8-0.5c-0.4-0.5-1.8-0.8-2.4-0.9c-0.3-0.6-0.9-1-1.2-1.5c-0.3,0-0.3,0.3-0.6,0.2l-0.4-0.7
               c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.2-0.3,0.4-0.4c-0.2-0.2-0.4-0.4-0.3-0.7v-0.1c-0.2-0.2-0.3-0.5-0.2-0.7v-0.1
               c-0.2,0.1-0.4,0.2-0.4,0.4c0,0.1,0.1,0.1,0.1,0.1l0.4,0.6l-0.1,0.7l-0.4-0.7c-0.4-0.1,0-0.2-0.5-0.4c-0.1,0-0.3-0.1-0.3-0.3
               s0.1-0.3-0.2-0.4c0-0.1-0.2-0.8-0.1-1.1l0.1-0.3l-0.5-0.4c0.1-0.2,0.2-0.6,0-0.8l-0.3-0.9c0.1-0.7,0.4-1.5,0.6-2.2l0.4-0.3
               c0-0.2,0.9-1.2,1.2-1.7c0.1-0.2,0.2-0.6,0.5-0.7l0.5-0.6c0.3,0,0.6,0,0.8-0.2l-0.4-0.5c0.1,0,1,0.1,1.1,0h-0.1
               c0.6-0.7,1.8-0.7,2.2-1.6c0.7,0.1,0.9-0.4,1.5-0.3l0.8-0.5c0.8-0.1,1.7-0.4,2.4-0.9c0.2,0,2.8-0.1,3-0.6c0.3,0.1,0.7-0.1,1.1,0
               c0-0.1,1-0.4,1.2-0.4c-0.2,0.6,2-0.1,2.5,0c0.1,0,0.4,0,0.5-0.1v-0.1l2.2,0.4l0.6-0.3c0.4,0.1,0.7,0.2,1.1,0
               c0.7,0.1,1.5-0.3,2.1-0.5l1,0.2c0.3-0.7,2.4,0.2,2.6,0.2l0.6-0.2l0.2,0.4h0.3c0.1-0.5,1.3,0.1,1.9-0.2l12.8,2.7
               c-0.1,0.3,0.4,0.4,0.6,0.5c0.2,0,1.5,0.1,1.4,0.4l4.5,1.4c-0.3,0.5,1.2,0.3,1.2,0.6c-0.1,0.3-0.5,0.3-0.7,0.2
               c-0.3,0-1.2-0.7-1.4-0.2l-3.4-0.7l-0.7,0.1c-0.1,0.3-0.4,0.4-0.6,0.3l-0.3-0.1c0.1-0.3-0.1-0.4-0.3-0.4s-0.7-0.2-0.7,0
               c0,0.1,0,0.3,0.1,0.5c0,0.1-0.2,0.3-0.3,0.2l-0.1-0.1c0.1-0.4,0.1-0.5-0.3-0.6c-0.3,0-0.6,0-0.8,0.2c-0.4,0.5-1.7-0.4-1.9,0.8
               c0,0.1-0.1,0.4,0,0.6l0.9,0.2c-0.1,0.3-0.3,0.6,0.2,0.7h0.2c-0.1,0.3-0.5,0.5-0.8,0.5c-1.1-0.2-2.1-0.6-3.2-0.8l-0.2,0.5
               c0.1,0.1,0.5,0.8,0.6,0.8c0.2,0,0.3-0.3,0.3-0.3l0.9,0.2c-0.2,0.5-0.1,0.6-0.7,0.5l-1.4-0.3c-0.2,0-1,0-1.1,0.3
               c0,0.1,0.3,0.2,0.4,0.2c1.2,0.2,2.5,0.6,3.7,0.9c0,0.1,0,0.2,0.1,0.2c0.2,0,0.4,0.1,0.3,0.3c-0.2,1.4,1.7,0.1,1.5,1.3
               s-1,0.4-1,0.9c0,0.1,0.9,0.2,1.6,0.3c0.4,0.1,0.4,0.4,0.7,0.5h0.1c0,0.2-0.7,0.1-1.8-0.1l-2-0.3c0,0.2-0.3,0.2-0.4,0.2
               c-0.3-0.1-1.3-0.5-1.5-0.2c-0.5,0.1-1.1,0.2-1.7,0.1h-0.1l-0.7,0.2l-0.9-0.2l-0.3,0.2l-0.8-0.4c-1,0.2-2.8-0.3-3.8-0.7
               c0,0.1-0.1,0.4-0.3,0.5c-0.1,0-0.3-0.3-0.6-0.3c-0.2,0.2-0.6,0.2-0.8,0.2l-0.4-0.4c-0.1,0-0.7,0-0.7,0.1s0.3,0.1,0.3,0.1
               c0.1,0.1,0.3,0.3,0.3,0.4l-0.8-0.1c0,0.1-0.1,0.3-0.3,0.3s-0.3-0.2-0.5-0.4c0-0.2-0.1-0.5-0.4-0.5c-0.1,0-0.4,0-0.4,0.2
               c0,0.1,0.3,0.2,0.3,0.2v0.4l-1.2-0.1c-0.2,0.2-0.6,0.2-0.8,0.2l-0.5-0.3c0,0.1-0.1,0.2-0.3,0.2s-0.3-0.2-0.4-0.4
               c-0.1,0.3-0.7,0.4-1,0.4l-0.8-0.2l-0.7,0.4c-0.1,0.4,1.6,0.9,1.8,1c0.2,0.3,0.3,0.8,0.7,0.9l0.5,0.4l0.3,0.6l0.7-0.4
               c0.3,0.3,0.5,0.5,0.4,0.9v0.1l1.2,0.7c0.2-0.6,1.2,0.2,1.3,0.4c-0.2,0-0.5,0.1-0.5,0.4c0,0.1,0.2,0.1,0.3,0.1l0.7,0.5
               c-0.1,0.4,0.6,0.2,0.5,0.6v0.1c0.4,0.1,0.2-0.6,0.8-0.5l0.3,0.7h-0.6c0,0.1,0.5,0.2,0.6,0.2l0.9,0.8l0.8,0.3
               c0,0.1-0.2,0.3-0.1,0.4c0.1,0,0.4,0.1,0.4,0.4l0.8,0.2l0.2,0.8l0.8,0.3l0.2,0.8l0.8,0.1l0.2,0.6c0.2,0,0.3,0.2,0.3,0.4v0.1
               c0.7,0.2,1,0.9,1.5,1.5c-0.2,0.6,1.2,0.5,1.1,1.1v0.1c0.5,0.5,0.8,1.2,1.1,1.8v0.4h0.1l0.1,0.5c0.2-0.4,0.7,0,0.8,0.3
               c-0.1,0.2,0,0.3,0,0.5c-0.1,0.4-0.3,0.6-0.5,0.9h-0.6l0.5,0.8c-0.1,0.4-0.5,1.9,0.2,2v0.3c0,0.2-0.1,0.3-0.3,0.2
               c-0.1,0.7,0.5,0.6,0.4,1l0.2,0.7c0,0.2-0.1,0.3-0.3,0.2c-0.1,0-0.2,0-0.3,0.2c0,0.3,0,1-0.5,0.9c-0.1,0.4,0.3,0.1,0.3,0.6
               c-0.6,0.6-1.5,2.5-2.2,2.8c0.1,1.6-0.3,0.8-0.5,1.9c-0.3,0.5-0.7,1.1-1.2,1.4c-0.1,0.4-0.6,0.3-0.7,0.8l-0.3,0.2l-0.4-0.3
               c0-0.2-0.2-0.3-0.4-0.4c0,0.3-0.1,0.6-0.5,0.5c0,0.2-0.2,0.3-0.4,0.3h-0.1c-0.2,0.1-0.5,0.4-0.8,0.3c-0.1,0-0.1-0.2-0.1-0.3
               c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.3,0.2,0.3l0.2,0.3c0.4-0.2,1.2-0.8,1.6-0.7c0.2,0,0.2,0.3,0.2,0.5c-0.1,0.4-1.2,0.2-1.3,0.7
               l-0.7,0.3c-0.9-0.2-1.3-0.8-2.5-0.1c-0.6-0.5-0.4,0.3-0.7,0.2c-0.1,0-0.5-0.2-0.5-0.4c0-0.1,0.2,0,0.2,0c0.2,0,0.5-0.2,0.5-0.4
               c0-0.1-0.1-0.1-0.1-0.1l-0.2-0.3c-0.3,0-0.2,0.3-0.5,0.2c-0.3,0.1-0.6,0.2-0.8,0.4c-0.1,0.4-0.3,0.5-0.4,0.9
               c0.4,0.1,0.6-0.2,0.9-0.1l-0.1,0.4c-0.1,0.4-0.2,0.4-0.5,0.4c-0.2,0-0.5-0.2-0.6-0.4l-0.2,0.6l-0.5-0.1c-0.1,0.4-1.3,0.5-1.6,0.4
               c-0.1,0.3-0.1,0.4-0.4,0.4c-0.2,0-0.6-0.2-0.6,0.1c-0.1,0.7-0.5,0.1-0.9,0.3c0,0.2,0.7,0.5,0.8,0.6c0.1-0.6,1.2,0.1,1.1,0.6
               c-0.2-0.1-0.8-0.3-0.8,0.1l-0.9-0.4l-0.5,0.1l-0.3-0.6c-0.1,0.5-0.6-0.1-0.7,0.4l0.5,0.3c0,0.3-0.8,0.3-1,0.3l-0.4-0.4
               c-0.4,0.4-1.4,0.1-1.8,0l-0.3,0.5c0.4,0.1-0.9,0.4-1,0.4c0,0-0.4-0.1-0.4,0s0.8,0.4,0.8,0.4s-0.5,0.1-0.6,0.2
               c-0.1,0-0.4,0-0.4,0.2v0.1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.5,0.1-0.9,0.3-1.5,0.3l0.3-0.2L941.2,223.7z M901.4,217.6
               c0,0.3-0.4,0.3-0.6,0.3h-0.1l-0.8,0.2l-0.7-0.2l-1.4,0.3c-0.4-0.1-2.5-0.7-2.6-0.2v0.1l-3.6-0.6c-0.1,0-0.4-0.1-0.3-0.1
               c0.1-0.9,4.4,0.5,5.3-0.1l1.4,0.2l0.4-0.3L901.4,217.6z M896.4,219.8c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.3-0.1-0.3-0.3
               c0-0.1,0.2,0,0.2,0L896.4,219.8z M896.7,219.8c0.2,0,0.4-0.2,0.5-0.1C897.1,219.8,897,219.9,896.7,219.8z M897.7,219.6
               c-0.2,0-0.3,0.1-0.5,0.1C897.4,219.6,897.6,219.6,897.7,219.6z M901.1,223.5c0.2,0,0.5-0.1,0.5-0.3l-0.9-0.1L901.1,223.5z
                  M904.3,217.9c-0.1,0-0.8-0.1-0.8,0.1c0,0.1,0.3,0.1,0.4,0.1l1.2,0.2l0.5-0.2c0.2,0,0.7,0,0.7-0.2c0-0.1,0-0.1,0-0.2
               c-0.1,0-0.1,0.1-0.1,0.2C905.5,217.8,905,218,904.3,217.9z M905,219l-0.8-0.2C904.2,219.1,904.8,219.1,905,219z M904.6,226.1
               c0.2,0,2.6,0.5,2.7-0.1l-1.4-0.2C905.8,226.5,904.7,225.4,904.6,226.1z M907.7,217.9c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
               C907.6,217.7,907.7,217.9,907.7,217.9z M908.6,226l-0.9-0.2C907.6,226.1,908.4,226.1,908.6,226z M918.1,220.2
               c-0.2-0.1-1.2-0.5-1.2-0.1c0.1,0,0.6,0.1,0.6,0.2c0,0.1-0.9,0.1-1.1,0c-0.8-0.1-0.8,0.5-1.5,0.4l-0.6,0.3c1.4-0.3,1.6-0.5,3.2-0.2
               c0.4-0.3,1-0.3,1.5-0.3v-0.3c0-0.1,0.1-0.4-0.1-0.5C918.8,219.7,918.2,220.1,918.1,220.2z M918.4,223.6c0.1,0,0.3,0,0.3-0.2
               l-0.5-0.2L918.4,223.6z M919.5,223.3c0,0.1,0.6,0.2,0.6,0.3l1-0.4c0.1,0,0.4,0,0.5-0.2c0-0.1,0-0.2-0.1-0.2s-0.1,0.1-0.1,0.2
               L919.5,223.3z M920.4,219.5c-0.2,0-0.2-0.2-0.2-0.3l0.1-0.3l-0.2-0.1c-0.4-0.1-0.4,0.4-0.5,0.7c-0.1,0.5-0.2,1.4,0.5,1.5
               c0.2-0.2,0.5-0.2,0.7-0.2c0-0.1-0.1-0.9,0.1-0.9c0.1,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.3,0.1,0.3c0.1,0,0.3-0.1,0.4-0.2l0.6,0.7
               c0.1,0,0.5-0.1,0.5-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.2,0-0.5-0.2-0.6-0.4c-0.1,0-0.4-0.1-0.3-0.2c0-0.1,0.5-0.2,0.6-0.3l1.1-0.5
               c-0.3,0.2-1.1,0.3-1-0.2l-0.7,0.4C922.1,218.8,920.4,219.4,920.4,219.5z M922,218.9c0.1,0,0.1-0.1,0.1-0.2l-0.2-0.1
               C921.9,218.7,921.9,218.9,922,218.9z M922.8,215c0.2,0,0.3-0.2,0.4-0.3l-0.5-0.2L922.8,215z M923,218.8c0.1,0,0.1-0.1,0.1-0.1
               c0-0.1,0-0.2-0.2-0.2h-0.1C922.8,218.6,922.9,218.7,923,218.8z M924.6,219.3c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1
               C924.5,219.1,924.5,219.3,924.6,219.3z M926.4,218.8c0,0.1,0,0.2,0.2,0.3c0.1-0.6,1.3,0.2,1.4-0.4c0-0.2-0.3-0.4-0.5-0.4
               c0,0.2-0.3,0.2-0.5,0.2l-0.2-0.3C926.7,218.4,926.5,218.6,926.4,218.8z M927.6,225.6L927.6,225.6c0,0.2-0.1,0.4,0.1,0.4h0.2
               c-0.2-0.3,2.4-0.5,2.6-0.7c0-0.1,0-0.3,0-0.3c-0.1,0-0.1,0.3-0.1,0.3C929.4,225.4,928.5,225.6,927.6,225.6z M928.5,183.3v0.1
               l0.4-0.4v-0.1c0-0.3-0.4-0.1-0.4-0.5c0.2-1.1,1.3-1.6,1.5-2.4c-1-0.2-1.5,1.5-1.7,2.2c0,0.1-0.1,0.4,0.1,0.5
               C928.4,182.9,928.6,183.1,928.5,183.3z M930.5,184.2c0,0.2,0.1,0.4,0,0.6c-0.1,0-0.3,0-0.3,0.2c0,0.1,0,0.3,0.1,0.4
               c0.5,0.1,0.7-0.9,0.8-1.3l-0.5-0.1L930.5,184.2z M930.2,182.9c0,0.2,0,0.5,0.1,0.8c0.1,0,0.2,0,0.3-0.2c0-0.3-0.1-0.7-0.2-0.9
               L930.2,182.9z M930.6,181c0,0.2,0.3,0.3,0.5,0.3l0.2-0.5l-0.6-0.2C930.7,180.7,930.6,180.9,930.6,181z M931.2,185.5l-0.4,0.5
               c0.3,0,0.3,0.3,0.6,0.4c0,0.2,0.2,0.5,0.4,0.5h0.2l-0.1-0.6c-0.2,0-0.4-0.1-0.3-0.3v-0.1c0.7,0.1-0.2-1.1-0.2-1.2L931.2,185.5z
                  M934.6,190.8c0.1,0,0.3-0.2,0.3-0.3l-0.2-0.3c0-0.1,0.2-0.6,0-0.7c-0.2,0-0.5,0.3-0.7,0.2c-0.1,0-0.1,0-0.1-0.1v-0.1l-0.7-0.9
               c-0.1,0-0.3-0.2-0.2-0.3v-0.1l-1.1-0.5l-0.7,0.4c-0.1,0.3,0.1,0.5,0.4,0.6l0.5-0.3c0.2,0,0.5,0.2,0.4,0.5c0.7,0.5,1.1,1.4,2.1,1.6
               C934.7,190.5,934.5,190.8,934.6,190.8z M932.4,225.3c0.1,0,0.3,0,0.3-0.2l-0.5-0.2L932.4,225.3z M932.7,186.5
               c-0.1,0.7,0.2,1.1,0.8,1.2v-0.2c0-0.2,0-0.4,0-0.7L932.7,186.5L932.7,186.5z M935,224.7c0.1,0,0.2-0.1,0.2-0.2s0-0.2-0.1-0.2
               s-0.1,0.1-0.2,0C934.9,224.4,935,224.6,935,224.7z M935.6,224.3l0.7-0.1c0.3,0,0.7-0.2,1-0.1c0.1,0,0.1,0,0.2,0
               c0-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.1-0.3,0.3C936.4,223.9,935.9,224.1,935.6,224.3z M936.2,190c0,0.1,0.3,0.3,0.4,0.3
               c0.1,0,0.3,0,0.4-0.1L936.2,190L936.2,190z M941.9,195.2c0-0.1-0.2-0.2-0.3-0.2c-0.1,0.4-0.4-0.1-0.5-0.2c-0.4-0.1-0.7-0.6-0.9-1
               c-0.2,0-0.2,0.1-0.3,0.3c-0.1,0.6,0.7,1.3,1.2,1.4c0.2,0,0.4-0.1,0.4-0.2C941.6,195.2,941.9,195.3,941.9,195.2z M940.2,193.4
               c0,0,0,0.1,0,0.2s0.2-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0.1-0.2,0V193.4z M943,224.8l-0.3,0.5c-0.6-0.1-0.8,0.5-1.3,0.4
               l-0.3,0.1c-0.2,0-0.2-0.4-0.1-0.5c0.4,0,1.1,0,1.2-0.5H943z M941.3,222.2c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1L941.3,222.2z
                  M942.3,195.8c0,0.1,0,0.2,0.1,0.2s0.2-0.1,0.2-0.3c0,0,0-0.1-0.1-0.1L942.3,195.8L942.3,195.8z M942.7,196.2L942.7,196.2
               c0.2,0.1,0.2,0,0.2-0.1s0-0.1-0.1-0.1h-0.1C942.8,196.1,942.7,196.2,942.7,196.2z M943.1,197.5c0,0.1,0,0.1,0,0.2
               c0.1,0,0.2-0.1,0.2-0.2l-0.2-0.1V197.5z M943.2,196.7c0.1,0,0.1-0.1,0.1-0.1l-0.2-0.2C943.1,196.5,943.1,196.7,943.2,196.7z
                  M943.5,197.9c0,0.1,0,0.2,0.1,0.2s0.2-0.1,0.2-0.2s0-0.1-0.1-0.2C943.7,197.8,943.6,197.8,943.5,197.9L943.5,197.9z M944.3,221.6
               c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2s-0.1,0.1-0.2,0C944.2,221.3,944.2,221.5,944.3,221.6z M945.1,209.1
               c0.1,0,0.2-0.1,0.2-0.2l-0.3-0.1C945,208.9,945,209.1,945.1,209.1z M945.9,208.1c-0.3,0-0.3,0-0.3,0.2
               C945.8,208.3,945.9,208.2,945.9,208.1z M945.7,199c-0.1,0.4,1.1,0.3,0.9,0.7l0.7,0.3c0,0.4,0.2,0.7,0.6,0.8c0.1,0,0.1,0,0.2,0
               c0-0.3-0.5-1-0.7-1.2c-0.5-0.1-0.9-0.6-1.3-1C945.9,198.7,945.7,198.8,945.7,199z M946.1,207.8L946.1,207.8c0.1-0.1,0-0.2-0.2-0.3
               C946,207.6,946,207.8,946.1,207.8z M947.1,207.5c0.1,0,0.2-0.1,0.2-0.2s0-0.2-0.1-0.2s-0.1,0.1-0.2,0
               C947,207.3,947,207.5,947.1,207.5z M947.1,184.4v0.6l0.6-0.4L947.1,184.4L947.1,184.4z M947.1,222.3h0.5l-0.4-0.5L947.1,222.3z
                  M948.5,203.4c0-0.2,0.2-0.3-0.2-0.4l-0.7-1.4c0,0,0.1-0.3,0-0.3s-0.4,0.5-0.4,0.5l0.1,0.5c0.3,0.3,0.6,0.7,0.7,1.1
               c-0.2,0.4,0.6,0.8,0.8,1.2l0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1C948.7,203.5,948.5,203.5,948.5,203.4z M947.5,223.2
               c0.1,0,0.3-0.3,0.3-0.3l-0.3,0.1C947.5,223.1,947.4,223.2,947.5,223.2z M948.6,221.1L948.6,221.1c-0.3,0.1-0.5,0.4-0.6,0.7
               c0,0.1,0,0.2,0.1,0.2c0.4,0.1,1.1-0.3,1.4-0.4c0.4,0.1,0.7-0.3,0.9-0.7c-0.6-0.1,0.8-0.7,0.9-0.7c0.7,0.1,0.8-0.5,1.4-0.4
               c0-0.2,0.4-0.2,0.5-0.1l0.6-0.5l-0.4-0.5c-0.1,0.2-0.5,0.1-0.7,0.1l-0.5,0.7l-0.6-0.1l-0.4,0.2c0,0.2-0.3,0.3-0.4,0.2
               c-0.5,0.4-1.8,0.4-2.6,0.8c0,0.2,0.7,0.3,0.8,0.4C949.1,221.1,948.8,221.1,948.6,221.1z M952,221.9c0.3,0.1,0.5-0.1,0.7-0.4
               c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.2,0.5-0.4,0.6c-0.4-0.1-0.8,0.2-1.2,0.1L952,221.9z M961.6,213.1l-0.2-0.3l-0.1,0.3
               c-0.1,0-0.3,0-0.3,0.1c0,0.3-0.1,0.3-0.4,0.3c-0.1,0.5-0.5,0-0.6,0.6c-0.5,0.6-1,1.2-1.8,1.6c0.1,0.1,0.1,0.2,0.3,0.2
               c0.3,0.1,0.3,0.1,0.2,0.4c-0.6,0-1.2,0.2-1.8,0.1l-0.3,0.7h0.8c0.2,0.1,0.8,0.2,0.8-0.2l0.3-0.1c-0.1-0.6,1.2-0.2,1.4-1.3
               c0.6-0.2,1.3-0.4,1.9-0.6c0.1-0.4,0.3-0.1,0.4-0.5c-0.2,0-0.3-0.3-0.2-0.4c-0.1,0-0.4-0.1-0.4-0.3c0.1-0.4,0.5-0.5,0.9-0.7
               C962.2,212.9,961.8,212.9,961.6,213.1z M958.1,219.1L958.1,219.1c0.1-0.1,0-0.2-0.2-0.3C957.8,219,957.9,219.1,958.1,219.1z
                  M959.3,217.7c-0.3,0-0.6,0.2-0.7,0.4C958.9,218.1,959.2,217.9,959.3,217.7z M960.7,179.4h-0.2c0,0.1,0,0.1,0,0.2
               c0.7,0.2,1.4,0.4,2.1,0.5c0.2,0,1,0.2,1.1-0.1C962.7,179.9,961.7,179.6,960.7,179.4z M962.8,212.7c0.1,0,0.3,0,0.3-0.2
               c0-0.3-0.1-0.7-0.2-0.9c0-0.1,0.1-0.4-0.1-0.5c-0.2,0-0.4,0.5-0.4,0.6c-0.1,0.4,0.2,0.8,0.1,1.2
               C962.6,212.9,962.7,212.8,962.8,212.7z M966.7,182.4c0.1,0,0.3,0.1,0.3,0H966.7l-0.8-0.4L966.7,182.4z M966.9,211.7
               c0,0.1-0.2,1.1-0.5,1c-0.4-0.1-0.3-0.4-0.3-0.6c0.1-0.4,0.6-1.9,0.9-1.9L966.9,211.7z M966.4,208.9c0.1,0,0.2-0.1,0.2-0.2
               l-0.3-0.1L966.4,208.9z M967.7,205c0,0.1-0.2,0-0.3,0s-0.5-0.1-0.5-0.2c0-0.1,0.3-0.2,0.4-0.5C967.4,204.5,967.8,204.7,967.7,205z
                  M967.4,205.2c0.2,0,0.6,0.3,0.6,0.5l-0.2,0.6h-0.3c-0.1,0-0.3-0.1-0.2-0.3L967.4,205.2z"/>
            </g>
         </g>
      </svg>
   </div>
</template>

<script>
export default {
  name: 'MeFB',
}
</script>

<style lang="scss" scoped>
.st0{fill:#FFFFFF;}
.st1{fill:#30BD55;}
.st2{fill:#D19477;}
.st3{fill:#F2CCB7;}
.st4{fill:#281305;}
.st5{fill:#84522C;}
.st6{fill:#00A0E7;}
.st7{fill:#FEE3D4;}
.st8{fill:#B78B6E;}
.st9{fill:#3B3B3D;}
.st10{fill:#038422;}

@keyframes wave {
   from {
      transform: translateY(10px);
   }

   to {
      transform: translateY(-10px);
   }
}

@keyframes yell {
   70% {
      opacity: 0;
   }

   90% {
      opacity: 1;
      transform: translateY(-10px);
   }
}

.me-futbol-wrapper {
   margin-top: -20px;

   ellipse, path {
      transition: all 0.2s;
   }

   #STARBURST {
      fill: white;
      filter: blur(10px);
      opacity: 0;
      transition: all 0.5s;
   }

   #FLAGS {
      transform: translateY(100px);;
      transition: all 0.4s;
      opacity: 0;
   }

   #WORDS {
      transform: translateY(150px);
   }

   #VERDE, #LISTO {
      opacity: 0;
      transition: all 0.2s;
   }

   #VERDE {
      transform: translateX(20px);
   }

   #LISTO {
      transform: translateX(-20px);
   }

   &:hover {
      .left-eyebrow {
         transform: translateY(-15px);
      }

      #FLAGS {
         transform: translateY(20px);
         opacity: 1;
      }

      #GREEN-FLAG-LEFT, #STAR-FLAG-RIGHT {
         animation-name: wave;
         animation-duration: 1s;
         animation-iteration-count: infinite;
         animation-direction: alternate-reverse;
      }

      #GREEN-FLAG-RIGHT, #BLACK-FLAG-LEFT {
         animation-name: wave;
         animation-duration: 1s;
         animation-iteration-count: infinite;
         animation-direction: alternate-reverse;
         animation-delay: 0.4s;
      }

      #GOAL-FLAG-LEFT, #TRI-COLOR-FLAG {
         animation-name: wave;
         animation-duration: 1s;
         animation-iteration-count: infinite;
         animation-direction: alternate-reverse;
         animation-delay: 0.2s;
      }

      #STARBURST {
         opacity: 1;
      }

      #WORDS {
         #VERDE {
            animation-name: yell;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            fill: white;
            transform: translateX(20px);
         }

         #LISTO {
            animation-name: yell;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-delay: 2.5s;
            fill: white;
            transform: translateX(-20px);
         }
      }
   }
}
</style>
