<template>
   <div class="me-sci-fi-wrapper">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 1120.4 1020.7" style="enable-background:new 0 0 1120.4 1020.7;" xml:space="preserve">
      <g id="TENTACLE-LEFT">
         <path class="st0" d="M452.7,657.1c-30.8-26.6-59.9-56.6-77-93.5s-20.4-82.1-0.5-117.6c23.9-42.6,81.3-62.8,126.6-44.4
            c23.6,9.5,43.7,29,50.9,53.4s-0.2,53.3-20.3,68.9c-20.1,15.6-52.2,14.5-69.2-4.4c-17-18.9-13.1-53.4,9.1-65.8
            c-32.8,7.1-53.8,44.9-46.4,77.7c7.4,32.7,39,57,72.3,60.4c33.4,3.4,67-11.9,90.2-36.2c23.2-24.2,36.7-56.5,43.1-89.4
            c9.4-48.8,0.2-106.6-40.9-134.5c-20.4-13.9-45.8-18.3-70.5-19.3c-90.3-3.7-186.1,41.4-223.6,123.7c-24.2,53.2-22,115.9-2.9,171.2
            c19.2,55.2,54.1,103.8,93.4,147.1c45.3,49.8,98.6,94.5,161.9,117.2c63.4,22.7,138.2,20.6,193.7-17.6c9.8-6.8,20.2-15.8,22.8-28.1
            c2-9.4-2.6-22.9-13.2-24.6c-8.9-1.4-18.1,2.9-27.2,2.3c-13.5-1-27.6-7.5-40.3-11.9c-54.3-19.1-106.2-44.8-154.5-76.1
            C503.3,697.7,477.3,678.2,452.7,657.1z"/>
         <path class="st1" d="M406.3,351.9c3.1-0.9,6.1-1.7,9.2-2.4c54.1-12.4,115.6,2.3,152.5,43.8c36.9,41.5,42.7,110,7.2,152.7
            c-25.6,30.8-70.2,44.7-108.5,33c-3.9-1.2-7.7-2.6-11.3-4.3c12.4,9.2,27.4,15.2,42.7,16.8c33.4,3.4,67-11.9,90.2-36.2
            c23.2-24.2,36.7-56.5,43.1-89.4c9.4-48.8,0.2-106.6-40.9-134.5c-20.4-13.9-45.8-18.3-70.5-19.3c-80.2-3.3-164.8,32-209,97.5
            c8.1-8,16.7-15.6,25.8-22.5C357.6,371.4,381.1,359,406.3,351.9z"/>
         <path class="st1" d="M742.6,853.7c9.8-6.8,20.2-15.8,22.8-28.1c2-9.4-2.6-22.9-13.2-24.6c-8.9-1.4-18.1,2.9-27.2,2.3
            c-13.5-1-27.6-7.5-40.3-11.9c-54.3-19.1-106.2-44.8-154.5-76.1c-27.2-17.7-53.2-37.2-77.7-58.3c-30.8-26.6-59.9-56.6-77-93.5
            c-17-37-20.4-82.1-0.5-117.6c4.4-7.8,9.9-14.9,16.3-21.1c-24.3,16.9-41.1,45.4-45.9,75c-6.5,40.6,6.5,82.4,27.8,117.6
            c28.8,47.5,73.1,85.5,124.4,106.6c38.3,50.7,82.2,97.2,130.6,138.3c9.5,8.1,19.5,16.1,30.4,22.1
            C688.7,880.7,717.6,870.9,742.6,853.7z"/>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -300.2378 395.5503)" class="st2" cx="595.6" cy="763.8" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -301.2748 394.5169)" class="st3" cx="593.1" cy="765.2" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -301.2494 394.6572)" class="st2" cx="593.4" cy="765.2" rx="6.7" ry="12.1"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -319.362 385.3083)" class="st2" cx="566.7" cy="794.7" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -320.399 384.2749)" class="st3" cx="564.2" cy="796.1" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -320.3736 384.4153)" class="st2" cx="564.5" cy="796.2" rx="6.7" ry="12.1"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -286.7382 367.7275)" class="st2" cx="549.9" cy="724.4" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -287.7752 366.6941)" class="st3" cx="547.4" cy="725.9" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -287.7498 366.8344)" class="st2" cx="547.7" cy="725.9" rx="6.7" ry="12.1"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -305.8624 357.4855)" class="st2" cx="521" cy="755.3" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -306.8994 356.4521)" class="st3" cx="518.5" cy="756.8" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -306.874 356.5925)" class="st2" cx="518.8" cy="756.8" rx="6.7" ry="12.1"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -277.2505 338.9617)" class="st2" cx="500.4" cy="692.1" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -278.2875 337.9283)" class="st3" cx="497.9" cy="693.6" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -278.2621 338.0686)" class="st2" cx="498.2" cy="693.6" rx="6.7" ry="12.1"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -296.3747 328.7197)" class="st2" cx="471.5" cy="723.1" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -297.4118 327.6863)" class="st3" cx="469" cy="724.5" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -297.3864 327.8267)" class="st2" cx="469.3" cy="724.5" rx="6.7" ry="12.1"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -262.7683 308.2699)" class="st2" cx="449.8" cy="649.5" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -263.8054 307.2365)" class="st3" cx="447.3" cy="650.9" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -263.7799 307.3768)" class="st2" cx="447.6" cy="651" rx="6.7" ry="12.1"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -281.8925 298.0279)" class="st2" cx="420.9" cy="680.4" rx="17.9" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -282.9296 296.9945)" class="st3" cx="418.4" cy="681.9" rx="13.6" ry="24.7"/>
               
                  <ellipse transform="matrix(0.8686 -0.4956 0.4956 0.8686 -282.9041 297.1348)" class="st2" cx="418.7" cy="681.9" rx="6.7" ry="12.1"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.9413 -0.3377 0.3377 0.9413 -180.0625 172.5343)" class="st2" cx="405.9" cy="603.8" rx="16.3" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9413 -0.3377 0.3377 0.9413 -180.5094 171.7641)" class="st3" cx="403.4" cy="604.7" rx="12.4" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9413 -0.3377 0.3377 0.9413 -180.5147 171.8496)" class="st2" cx="403.7" cy="604.8" rx="6.1" ry="10.6"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.9413 -0.3377 0.3377 0.9413 -189.5504 163.4333)" class="st2" cx="375" cy="626.5" rx="16.3" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9413 -0.3377 0.3377 0.9413 -189.9973 162.6631)" class="st3" cx="372.5" cy="627.4" rx="12.4" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9413 -0.3377 0.3377 0.9413 -190.0026 162.7486)" class="st2" cx="372.8" cy="627.5" rx="6.1" ry="10.6"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.9903 -0.1391 0.1391 0.9903 -73.225 57.6862)" class="st2" cx="376.1" cy="552.7" rx="16.3" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9903 -0.1391 0.1391 0.9903 -73.3035 57.3325)" class="st3" cx="373.5" cy="553.1" rx="12.4" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9903 -0.1391 0.1391 0.9903 -73.3161 57.365)" class="st2" cx="373.7" cy="553.2" rx="6.1" ry="10.6"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.9903 -0.1391 0.1391 0.9903 -75.7839 52.9893)" class="st2" cx="341.2" cy="568.6" rx="16.3" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9903 -0.1391 0.1391 0.9903 -75.8624 52.6357)" class="st3" cx="338.6" cy="569" rx="12.4" ry="21.5"/>
               
                  <ellipse transform="matrix(0.9903 -0.1391 0.1391 0.9903 -75.8749 52.6681)" class="st2" cx="338.8" cy="569.1" rx="6.1" ry="10.6"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.1646 -0.9864 0.9864 0.1646 -188.2333 781.052)" class="st2" cx="367" cy="501.6" rx="17.4" ry="14.4"/>
               
                  <ellipse transform="matrix(0.1646 -0.9864 0.9864 0.1646 -189.7703 778.5203)" class="st3" cx="364.7" cy="501.3" rx="17.4" ry="10.9"/>
               
                  <ellipse transform="matrix(0.1646 -0.9864 0.9864 0.1646 -189.7708 778.7981)" class="st2" cx="364.9" cy="501.4" rx="8.5" ry="5.4"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.1646 -0.9864 0.9864 0.1646 -219.5066 750.898)" class="st2" cx="333.5" cy="505" rx="17.4" ry="14.4"/>
               
                  <ellipse transform="matrix(0.1646 -0.9864 0.9864 0.1646 -221.0437 748.3663)" class="st3" cx="331.3" cy="504.7" rx="17.4" ry="10.9"/>
               
                  <ellipse transform="matrix(0.1646 -0.9864 0.9864 0.1646 -221.0442 748.6441)" class="st2" cx="331.4" cy="504.8" rx="8.5" ry="5.4"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.5341 -0.8454 0.8454 0.5341 -212.4919 516.4023)" class="st2" cx="362.3" cy="451" rx="15.3" ry="12.4"/>
               
                  <ellipse transform="matrix(0.5341 -0.8454 0.8454 0.5341 -212.3935 514.493)" class="st3" cx="360.6" cy="450" rx="15.3" ry="9.4"/>
               
                  <ellipse transform="matrix(0.5341 -0.8454 0.8454 0.5341 -212.4961 514.641)" class="st2" cx="360.7" cy="450.1" rx="7.5" ry="4.7"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.7994 -0.6008 0.6008 0.7994 -86.4134 436.7667)" class="st2" cx="610.9" cy="347.8" rx="15" ry="22.4"/>
               
                  <ellipse transform="matrix(0.7994 -0.6008 0.6008 0.7994 -87.6654 435.9213)" class="st3" cx="609" cy="349.2" rx="11.4" ry="22.4"/>
               
                  <ellipse transform="matrix(0.7994 -0.6008 0.6008 0.7994 -87.6165 436.0645)" class="st2" cx="609.2" cy="349.2" rx="5.6" ry="11"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.7994 -0.6008 0.6008 0.7994 -108.1086 430.568)" class="st2" cx="590.8" cy="377.2" rx="15" ry="22.4"/>
               
                  <ellipse transform="matrix(0.7994 -0.6008 0.6008 0.7994 -109.3606 429.7227)" class="st3" cx="588.9" cy="378.6" rx="11.4" ry="22.4"/>
               
                  <ellipse transform="matrix(0.7994 -0.6008 0.6008 0.7994 -109.3117 429.8659)" class="st2" cx="589.1" cy="378.6" rx="5.6" ry="11"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.9989 -4.644496e-02 4.644496e-02 0.9989 -18.7675 30.2563)" class="st2" cx="641.7" cy="419" rx="11" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -18.774 30.1753)" class="st3" cx="640" cy="419.1" rx="8.3" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -18.7788 30.1822)" class="st2" cx="640.1" cy="419.2" rx="4.1" ry="8.8"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.9989 -4.644496e-02 4.644496e-02 0.9989 -19.2686 29.1392)" class="st2" cx="617.4" cy="429.2" rx="11" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -19.2751 29.0582)" class="st3" cx="615.7" cy="429.3" rx="8.3" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -19.2799 29.0651)" class="st2" cx="615.8" cy="429.4" rx="4.1" ry="8.8"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.9989 -4.644496e-02 4.644496e-02 0.9989 -21.5154 30.1166)" class="st2" cx="637.3" cy="478.1" rx="11" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -21.5219 30.0356)" class="st3" cx="635.6" cy="478.2" rx="8.3" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -21.5267 30.0425)" class="st2" cx="635.7" cy="478.3" rx="4.1" ry="8.8"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.9989 -4.644496e-02 4.644496e-02 0.9989 -22.0165 28.9995)" class="st2" cx="613" cy="488.3" rx="11" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -22.023 28.9185)" class="st3" cx="611.3" cy="488.4" rx="8.3" ry="17.8"/>
               
                  <ellipse transform="matrix(0.9989 -4.644489e-02 4.644489e-02 0.9989 -22.0278 28.9254)" class="st2" cx="611.4" cy="488.5" rx="4.1" ry="8.8"/>
            </g>
         </g>
      </g>
      <g id="TENTACLE-RIGHT">
         <path class="st1" d="M659.4,1544.6c121.6-44.9,176.3-153.3,177.3-282.9c0.4-46.9-9.5-93.3-9.7-140.2c-0.2-35.3,5.5-71.6,23.4-102.1
            c14.7-25.1,36.8-45,52.6-69.3c25.9-39.7,33.5-90.9,20.3-136.4c-13.2-45.5-47-84.7-90.2-104.3c35.3,59.9,32.4,140.1-7.1,197.3
            c-32.3,46.8-85.4,77.3-112.8,127.1c-18.8,34.1-23.6,74-25.2,112.9c-1.6,38.9-0.4,78.2-8.4,116.3c-8,38.1-26.7,75.9-59.5,96.9
            c-18.6,11.9-40.5,17.7-60.2,27.7c-19.8,9.9-38.7,26.1-42.2,48c-4.1,25.2,13.2,48.4,29.4,68.1c14.3,17.4,29.2,35.3,49.3,45.5
            s46.9,10.8,63.2-4.7"/>
         <path class="st0" d="M647.9,1538.9c121.6-44.9,176.3-153.3,177.3-282.9c0.4-46.9-9.5-93.3-9.7-140.2c-0.2-35.3,5.5-71.6,23.4-102.1
            c14.7-25.1,36.8-45,52.6-69.3c25.9-39.7,33.5-90.9,20.3-136.4c-13.2-45.5-47-84.7-90.2-104.3c35.3,59.9,32.4,140.1-7.1,197.3
            c-32.3,46.8-85.4,77.3-112.8,127.1c-18.8,34.1-23.6,74-25.2,112.9s-0.4,78.2-8.4,116.3c-8,38.1-26.7,75.9-59.5,96.9
            c-18.6,11.9-40.5,17.7-60.2,27.7c-19.8,9.9-38.7,26.1-42.2,48c-4.1,25.2,13.2,48.4,29.4,68.1c14.3,17.4,29.2,35.3,49.3,45.5
            c20.1,10.2,46.9,10.8,63.2-4.7"/>
         <path class="st1" d="M581.2,1540.7c20.1,10.2,46.9,10.8,63.2-4.7l-0.2,0.2c121.6-44.9,176.3-153.3,177.3-282.9
            c0.4-46.9-9.5-93.3-9.7-140.2c-0.2-35.3,5.5-71.6,23.4-102.1c14.7-25.1,36.8-45,52.6-69.3c20.4-31.2,29.4-69.5,25.9-106.5
            c-6.8,12.8-14.4,25.1-22.4,37.2c-28.9,43.3-63.1,83.4-86,130.1c-32.7,66.6-40.5,145.1-21.6,216.9c1.7,0.9,3.5,1.8,5.2,2.8
            c-25.3,54.8-58.7,105.8-98.7,150.9c-31.8,35.9-67.8,68.1-107.8,94.5c-16,10.5-32.5,20.1-49.6,28.7
            C546.8,1513.2,561.5,1530.6,581.2,1540.7z"/>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -840.2726 1288.9303)" class="st2" cx="779.5" cy="1426.5" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -840.0065 1285.7109)" class="st3" cx="776.6" cy="1424.7" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -840.2069 1285.9535)" class="st2" cx="776.7" cy="1425" rx="14.5" ry="8"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -850.228 1242.1079)" class="st2" cx="730.9" cy="1412.4" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -849.9619 1238.8884)" class="st3" cx="728.1" cy="1410.5" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -850.1622 1239.1311)" class="st2" cx="728.2" cy="1410.8" rx="14.5" ry="8"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -777.446 1286.4788)" class="st2" cx="808.6" cy="1366.8" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -777.1799 1283.2593)" class="st3" cx="805.7" cy="1365" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -777.3803 1283.502)" class="st2" cx="805.9" cy="1365.3" rx="14.5" ry="8"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -787.4014 1239.6562)" class="st2" cx="760" cy="1352.7" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -787.1353 1236.4369)" class="st3" cx="757.2" cy="1350.8" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.5521 -0.8338 0.8338 0.5521 -787.3357 1236.6794)" class="st2" cx="757.3" cy="1351.1" rx="14.5" ry="8"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -540.9015 1949.7084)" class="st2" cx="827.3" cy="1279.4" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -543.5244 1946.0118)" class="st3" cx="823.9" cy="1279" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -543.5203 1946.4532)" class="st2" cx="824.2" cy="1279.2" rx="14.5" ry="8"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -593.8889 1908.4374)" class="st2" cx="777.6" cy="1288.6" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -596.5118 1904.7408)" class="st3" cx="774.2" cy="1288.2" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -596.5077 1905.1824)" class="st2" cx="774.4" cy="1288.4" rx="14.5" ry="8"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -477.7339 1885.246)" class="st2" cx="822.6" cy="1211.6" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -480.3568 1881.5493)" class="st3" cx="819.2" cy="1211.2" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -480.3527 1881.9908)" class="st2" cx="819.4" cy="1211.4" rx="14.5" ry="8"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -530.7213 1843.975)" class="st2" cx="772.9" cy="1220.8" rx="29.5" ry="21.4"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -533.3442 1840.2784)" class="st3" cx="769.5" cy="1220.4" rx="29.5" ry="16.3"/>
               
                  <ellipse transform="matrix(0.1182 -0.993 0.993 0.1182 -533.3401 1840.72)" class="st2" cx="769.7" cy="1220.6" rx="14.5" ry="8"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -383.6767 1883.6489)" class="st2" cx="815.7" cy="1147.1" rx="23.3" ry="17"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -386.0322 1880.8159)" class="st3" cx="813" cy="1146.9" rx="23.3" ry="12.9"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -386.0014 1881.1736)" class="st2" cx="813.2" cy="1147.1" rx="11.5" ry="6.3"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -429.1859 1853.4937)" class="st2" cx="776.8" cy="1156.3" rx="23.3" ry="17"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -431.5413 1850.6606)" class="st3" cx="774.2" cy="1156.2" rx="23.3" ry="12.9"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -431.5106 1851.0183)" class="st2" cx="774.4" cy="1156.3" rx="11.5" ry="6.3"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -322.9752 1826.401)" class="st2" cx="815.5" cy="1086" rx="23.3" ry="17"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -325.3306 1823.5681)" class="st3" cx="812.8" cy="1085.8" rx="23.3" ry="12.9"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -325.2999 1823.9257)" class="st2" cx="813" cy="1086" rx="11.5" ry="6.3"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -368.4843 1796.2457)" class="st2" cx="776.6" cy="1095.2" rx="23.3" ry="17"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -370.8398 1793.4128)" class="st3" cx="773.9" cy="1095.1" rx="23.3" ry="12.9"/>
               
                  <ellipse transform="matrix(6.737281e-02 -0.9977 0.9977 6.737281e-02 -370.8091 1793.7704)" class="st2" cx="774.1" cy="1095.2" rx="11.5" ry="6.3"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.4498 -0.8931 0.8931 0.4498 -467.6155 1308.2289)" class="st2" cx="828" cy="1033.7" rx="23.3" ry="17"/>
               
                  <ellipse transform="matrix(0.4498 -0.8931 0.8931 0.4498 -467.8837 1305.4119)" class="st3" cx="825.6" cy="1032.5" rx="23.3" ry="12.9"/>
               
                  <ellipse transform="matrix(0.4498 -0.8931 0.8931 0.4498 -468.019 1305.6521)" class="st2" cx="825.7" cy="1032.7" rx="11.5" ry="6.3"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.4498 -0.8931 0.8931 0.4498 -483.437 1269.397)" class="st2" cx="788.6" cy="1027.1" rx="23.3" ry="17"/>
               
                  <ellipse transform="matrix(0.4498 -0.8931 0.8931 0.4498 -483.7052 1266.58)" class="st3" cx="786.2" cy="1025.9" rx="23.3" ry="12.9"/>
               
                  <ellipse transform="matrix(0.4498 -0.8931 0.8931 0.4498 -483.8405 1266.8201)" class="st2" cx="786.3" cy="1026.1" rx="11.5" ry="6.3"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -447.2125 1083.4004)" class="st2" cx="855.9" cy="987.3" rx="19.1" ry="13.9"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -446.8849 1081.4465)" class="st3" cx="854.1" cy="986" rx="19.1" ry="10.5"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -447.0197 1081.5846)" class="st2" cx="854.2" cy="986.2" rx="9.4" ry="5.2"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -450.8965 1054.2764)" class="st2" cx="825" cy="976.4" rx="19.1" ry="13.9"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -450.5689 1052.3226)" class="st3" cx="823.2" cy="975.1" rx="19.1" ry="10.5"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -450.7038 1052.4606)" class="st2" cx="823.3" cy="975.3" rx="9.4" ry="5.2"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -394.5008 1092.208)" class="st2" cx="891" cy="939.2" rx="19.1" ry="13.9"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -394.1732 1090.2543)" class="st3" cx="889.2" cy="937.9" rx="19.1" ry="10.5"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -394.3081 1090.3922)" class="st2" cx="889.3" cy="938.1" rx="9.4" ry="5.2"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -398.1849 1063.0841)" class="st2" cx="860.2" cy="928.3" rx="19.1" ry="13.9"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -397.8572 1061.1302)" class="st3" cx="858.4" cy="927" rx="19.1" ry="10.5"/>
               
                  <ellipse transform="matrix(0.5977 -0.8017 0.8017 0.5977 -397.9922 1061.2683)" class="st2" cx="858.4" cy="927.2" rx="9.4" ry="5.2"/>
            </g>
         </g>
         <g>
            <g>
               
                  <ellipse transform="matrix(0.4813 -0.8765 0.8765 0.4813 -298.4829 1263.4552)" class="st2" cx="918.4" cy="883.9" rx="19.1" ry="13.9"/>
               
                  <ellipse transform="matrix(0.4813 -0.8765 0.8765 0.4813 -298.5763 1261.2078)" class="st3" cx="916.4" cy="882.9" rx="19.1" ry="10.5"/>
               
                  <ellipse transform="matrix(0.4813 -0.8765 0.8765 0.4813 -298.6939 1261.3927)" class="st2" cx="916.5" cy="883.1" rx="9.4" ry="5.2"/>
            </g>
            <g>
               
                  <ellipse transform="matrix(0.4813 -0.8765 0.8765 0.4813 -309.3939 1231.9597)" class="st2" cx="886.3" cy="877.4" rx="19.1" ry="13.9"/>
               
                  <ellipse transform="matrix(0.4813 -0.8765 0.8765 0.4813 -309.4873 1229.7123)" class="st3" cx="884.3" cy="876.4" rx="19.1" ry="10.5"/>
               
                  <ellipse transform="matrix(0.4813 -0.8765 0.8765 0.4813 -309.6049 1229.8972)" class="st2" cx="884.4" cy="876.6" rx="9.4" ry="5.2"/>
            </g>
         </g>
      </g>
      <path class="st4" d="M227.3,605.9c0,0-156.3,34.3-158.7,106.9s103.8,57.9,103.8,57.9l1.6-21.8c0,0-33.2,3.7-50-2.5
         S76.7,729.6,94.5,702s42-37,72.8-49.1s64.5-22.3,64.5-22.3S248.4,617.3,227.3,605.9z"/>
      <path class="st5" d="M225,605.5c0,0-8.8,1.9-22.2,5.8c0.2,2.4,0.2,4.9-0.1,7.3c-0.8,7.8-7.7,13.2-13.9,16.6
         c-0.8,0.4-1.6,0.9-2.4,1.3c-13.6,6.8-28.5,10.5-43,15.2s-29,10.7-40.1,21.2c-10.7,10.1-17.9,25.2-16,39.9c0.9-4,2.6-7.9,4.9-11.3
         c17.8-27.7,42-37,72.8-49.1s64.4-22.3,64.4-22.3S246.1,616.9,225,605.5z"/>
      <path class="st5" d="M121.7,746c-12.6-4.7-33-11.8-34.8-26.8c-1.6,11.9-2.2,24-1.7,36c30,22.7,84.9,15.1,84.9,15.1l1.6-21.8
         C171.7,748.5,138.5,752.2,121.7,746z"/>
      <path class="st6" d="M6.8,1021.5c0,0,31.6-228.5,182.8-279.6S631.7,594.1,774,647.3l101.7,61.3c0,0-3.1-21,68.8,21
         s147.7,185.5,177.8,292L6.8,1021.5z"/>
      <path class="st7" d="M73.9,967.5c-9.7-6.4-20.4-11.3-31.6-14.6L73.9,967.5z"/>
      <path class="st7" d="M1035.9,918.5l7.9-3.1C1041.2,916.4,1038.5,917.4,1035.9,918.5z"/>
      <path class="st7" d="M1035.9,918.5l-81.1,31.7c8.1-41.8,44.1-76.2,85.1-81.5c-34.8,0.7-69.2,7.8-101.3,21l4.8,5.6
         c-4.6-3.4-11.6-3.6-17.2-1.3c-7.3,3-12.9,9-19.3,13.6c-15.4,10.9-35.2,13.6-52.9,7.2c25.7-45,64.8-81.8,110.6-104l-120.4,58
         c-8.3,6.7-21.8,1.7-26.4-8s-2-21.8,3.9-30.8s14.6-15.5,23-22s17-13.4,22.3-22.7l-59.6,53.9c-3.2,2.9-7,6-11.3,5.4
         c-4.7-0.7-7.8-6-7.7-10.9s2.5-9.5,4.9-13.7c17.6-30.4,41.3-56.7,69.8-77.2c-27.7,16.3-55.5,32.7-85.7,43.3s-63.2,15-94,6.8
         c-15.5-4.1-30-11.2-45.4-15.6c-1.9-0.5-3.8-1-5.7-1.5c-19.2-4.5-39.1-4.7-58.3-0.7c-9.5,2-19.2,4.8-27.9,9.1
         c-4.6,2.3-6.4,2.8-11.7,2c-23-3.7-47.6-1.7-68,10.6c-14.7,8.7-26.3,22.1-40.3,32s-32.8,16-47.9,8c-6.7-3.5-12-9.4-18.4-13.4
         s-15.4-5.6-21.1-0.5c-4.4,3.9-5.4,10.4-8,15.7c-6.7,13.2-26.1,17-37,7.3c-5,14.6-24,19.3-38,13.8c-14-5.5-24-18.2-33.3-30.3
         c-2.3,15.8-4.6,31.5-6.9,47.2l-27.1,0.1c-1.1,10.7-2.2,21.4-3.3,32.1l-19.5-44.6c-2.4,16.4,6.3,32,12.3,47.4s8.6,35.3-3.2,46.6
         c-11.4,10.9-31,7-42.2-4.1s-16.3-27.1-21-42.5c1.6,30.3,16.4,59.7,39.7,78.3l-70.2-44c11.2,7.7,18.4,20.1,19.4,33.7l-26.4-12
         c18.5,12,33.5,28.7,43.4,48.5l-83.8-27c6.8,2.2,13.5,6.7,19.8,9.8l20.1,10c10.5,5.2,21.5,9.8,31.1,16.7c5.6,4,10.7,8.9,17.1,11.7
         c10.1,4.2,21.8,4.2,32.6,4.8c13.3,0.7,26.6,0.9,39.8,1c106,0.4,211.8-6.5,316.9-20.6c2.3,12.5,12.7,23.1,24.4,28
         c13.5,5.7,28.6,5.6,43.2,5.4c87.2-1.2,175.2-2.4,260.3-21.9c17.7-4.1,35.2-8.9,52.8-13.7l133.7-36.9l-87.2,1.1
         C965.9,955.9,999.2,933.5,1035.9,918.5z M515.7,978.8c1.3-29.2,2.7-58.5,4-87.8c0.6-12.5,1.3-25.6,7.9-36c1.7-2.7,3.7-5.1,6.1-7.2
         L515.7,978.8z"/>
      <path class="st8" d="M238.7,888.9c2.7,10.9,5.6,22.3,12.9,30.7c8.4,9.7,21.6,13.8,34.2,13.8s24.9-3.6,37-7.2
         c17.3-5.1,35-10.4,49.8-20.9s26.8-27.2,27-45.8c0.3-22.2-15.3-40.8-30-57l-94.9-105.2l-46,22.6c-9.2,4.5-23.6,8.6-24.9,20.9
         c-0.6,5.2,0.8,10.6,2,15.6c2,8.2,4,16.3,6.1,24.5l12.6,50.7L238.7,888.9z"/>
      <path class="st8" d="M648.6,830.1c-11,18.4-21,41.8-10.5,60.5c7.4,13.3,22.9,19.3,37.3,23c34,8.8,69.4,10.9,104.2,6.4
         c5.9-42.1,11.9-84.6,26.2-124.5s37.9-77.5,72.4-100.8l-30.8-17.3c-9.6-5.4-19.5-12.7-29.8-16.4c-8.2-2.9-16.2,5.2-22.5,9.7
         c-8.1,5.8-16,11.8-23.7,18c-15.6,12.5-30.4,26-44.5,40.2c-28.2,28.6-53.2,60.4-74.3,94.6C651.3,825.7,650,827.9,648.6,830.1z"/>
      <path class="st9" d="M240.4,452.3c16,49.7,35.9,100.7,29.3,152.6l4.9-8.7c-13,35.2-26.2,73.6-16.1,109.8
         c11,39.6,48.3,66.7,87.1,76.5s79.5,5.1,119.2,0.5l122.6-14.3c11-10.8,23.9-19.3,38.2-25.1c27-10.8,56.7-11,84.9-17.6
         s57.5-23,65.9-51.6c5.5-18.6,1-38.6-2.8-57.7C761,551.6,757.9,485,764.5,419c1.4-13.8,3.9-28.9,14.2-38c3.5-20,7.1-40.3,4.9-60.5
         s-10.9-40.6-27.3-51.8c-7.5-5.1-16.1-8.1-24.6-11.1l-54.1-18.7c-30.4-10.5-60.9-21.1-92.2-28.4c-77.5-18.1-158.4-16.1-234.9,5.7
         c-17.9,5-35.2,11.8-51.6,20.3c-16.1,8.6-31.3,19.3-43.6,33.1c-6,6.9-11.2,14.4-15.6,22.4c-3.8,6.8-8.6,12.2-11,19.7
         c-6.8,21-7.7,43.8-5.4,65.7c2.3,22.8,7.9,45.1,14.7,66.9C238.7,446.9,239.6,449.6,240.4,452.3z"/>
      <path class="st10" d="M848.5,654.9c-4.7-34.9-50.1-61.3-118-74.8c24.5,12.5,39.9,28.8,42.5,47.9c7.4,55.5-96.4,113.3-231.9,129.2
         S289.8,741,282.4,685.5c-1.8-13.2,2.8-26.6,12.6-39.5c-40.2,24.8-62.5,53.1-58.8,80.7c8.5,63.6,152.5,99.2,321.6,79.3
         S857.1,718.5,848.5,654.9z"/>
      <polygon class="st11" points="264.7,639.1 231.1,640.5 229.4,598.6 263,597.2 "/>
      <g id="FACE">
         <path class="st12" d="M323.3,364.7c-1.8,0-27.4-24.3-21.8,22.5s7,57.6,10.5,79.4s27.1,26.3,32,26.5S325.1,364.7,323.3,364.7z"/>
         <path class="st12" d="M661.1,359.3c0,0,22.6-25.8,31.1,0s11.3,35.7-7.5,110.4c0,0-7.6,31.2-33.3,24.1
            C645.7,479.7,655.5,412.2,661.1,359.3z"/>
         <path class="st12" d="M339.2,459.2c0,0,7.5,78.6,18.7,88.9s74,88.9,92.7,91.7s42.6,0,42.6,0s37.9,13.1,69.8-13.1
            s96.4-86.1,88.9-147l6.5-66.5l2.8-60.8c0,0,21.5-178.8-171.3-176S322.4,348.7,322.4,348.7S322.4,444.2,339.2,459.2z"/>
         <path class="st13" d="M337.9,453.5c0,0,7.5,78.6,18.7,88.9s74,88.9,92.7,91.7s42.6,0,42.6,0s37.9,13.1,69.7-13.1
            s96.4-86.1,88.9-147l6.5-66.5l2.8-60.9c0,0,21.5-178.8-171.3-176S321,343.1,321,343.1S321,438.6,337.9,453.5z"/>
         <path class="st13" d="M320.5,359c-1.8,0-27.8-24-21.5,22.8s7.8,57.5,11.6,79.2s27.5,26,32.3,26.1S322.3,358.9,320.5,359z"/>
         <path class="st13" d="M659.7,362.9c0,0,22.6-24.2,31.1,0s11.3,33.6-7.5,103.8c0,0-7.6,29.4-33.3,22.6
            C644.3,476.1,654.1,412.7,659.7,362.9z"/>
         <path class="st14" d="M436.6,394.2c8.8,0,17.3,0.4,25.3,1c-3.4-8.1-14.5-14-27.6-14c-13.4,0-24.6,6.1-27.8,14.5
            C416.5,394.7,426.5,394.2,436.6,394.2z"/>
         <path class="st14" d="M557.6,394.2c-8.8,0-17.3,0.4-25.3,1c3.4-8.1,14.5-14,27.6-14c13.4,0,24.6,6.1,27.8,14.5
            C577.8,394.7,567.7,394.2,557.6,394.2z"/>
         <path class="st12" d="M309.7,438c0.9-6.1-0.5-12.4-0.5-18.6s1.7-13.1,6.9-16.4c0.8,6.4-0.1,12.9-0.2,19.3s0.8,13.2,4.7,18.4
            c2.7,3.6,7,6.7,6.9,11.2c-0.1,4.2-4.2,7.9-3.5,12c0.2-3.5,2.1-6.6,5.1-8.5c1.4-0.8,3-1.5,3.7-2.9c0.4-0.9,0.5-1.8,0.4-2.8
            l-0.3-61.2c0.2-4-0.2-8.1-1-12c-0.4-1.6-1-3-1.8-4.4c-3.4-5.7-10.8-7.6-16.5-4.1c-2,1.2-3.6,3-4.6,5c-0.8,1.8-0.6,4.1-0.8,6
            c-0.2,2.7-0.4,5.4-0.5,8c-0.5,10.9-0.5,21.7,0.2,32.6C308.4,425.8,309,431.9,309.7,438z"/>
         <path class="st12" d="M681.9,437.9c-0.6-6.2,1-12.3,1.2-18.5s-1.2-13.1-6.3-16.7c-1,6.4-0.4,12.9-0.5,19.3s-1.3,13.2-5.4,18.2
            c-2.9,3.5-7.3,6.5-7.4,11c-0.1,4.2,3.9,8,3,12.2c-0.1-3.5-1.9-6.7-4.7-8.7c-1.3-0.9-3-1.6-3.6-3.1c-0.3-0.9-0.4-1.8-0.3-2.8
            l2.7-61.2c0-4,0.5-8,1.4-11.9c0.4-1.5,1.1-3,1.9-4.4c3.6-5.6,11.1-7.1,16.7-3.5c1.9,1.3,3.5,3.1,4.4,5.2c0.7,1.8,0.5,4.1,0.5,6
            c0.1,2.7,0.2,5.4,0.2,8.1c0.1,10.9-0.4,21.7-1.5,32.5C683.7,425.8,682.9,431.8,681.9,437.9z"/>
         <path class="st14" d="M677.6,387.7c3.2-2.1,5.3-5.5,5.8-9.2c-3.5,2-8.1-0.9-9.4-4.7s-0.3-8,0.5-11.9s1.5-8.2-0.3-11.8l4,1.1
            c0.1-2.9,0.2-5.7,0.2-8.6l6.7,2.2c-0.7-22.3-6.3-44.1-16.3-64c6,2.7,13.9-2.6,13.6-9.2c-2.5,2.9-7.7,1.4-9.7-1.8s-1.8-7.4-1.5-11.2
            c0.6-8.1,1.2-16.2,1.8-24.4c-5.5,4.5-12.6,6.5-19.6,5.5c1.2-16.7-5.8-32.9-18.8-43.4c-3.6-3-7.9-5.2-12.5-6.3
            c-0.2-0.6-0.4-1.2-0.6-1.8c-1.1-3.3-2.2-6.7-4.7-9.1c-3.1-3.1-7.7-4.1-11.9-5.6c-10.2-3.8-18.4-11.5-27.6-17.4
            c-19-12.4-42-16.8-64.5-20.3c-1.6-0.2-3.3-0.6-4.2-1.9c-0.6-1.1-0.8-2.3-0.6-3.5l0.7-19.2c-4.4,8.3-14.6,11.4-24,12.9
            s-19.4,2.3-26.5,8.6c0.9-1.8,0.8-4-0.4-5.7c-6.5,12.8-17.7,22.7-31.2,27.7c0.4-3.9-1.7-7.6-5.1-9.3c3.1,10-6.8,19.1-15.9,24.3
            c-7.8,4.5-16.8,10-19.2,18.1c-14.3,0.2-29.5,1.1-40.6,9.9c-8.8,7-13.5,17.8-19.7,27.2c-4.4,6.7-9.6,12.9-12.2,20.5
            c-5.2,15,1,31.8-2.8,47.2c-2,7.9-6.5,15-8.5,22.9s-0.5,17.8,6.7,21.7l-0.1-5.6c5.8,2.3,6,11.4,1.6,15.8s-11.3,5.3-17.5,5
            c1,2.4,2.1,4.8,3.1,7.2l-8.9,0.6c10.1,10.4,24.6,15.4,38.9,13.5c-0.5,7.2-5.6,13.3-12.6,15.1c2.4,0.8,3.5,3.8,3.1,6.2
            s-2,4.6-3.5,6.7l10.1,0.7c-1.2,7.2-2.4,14.7-0.4,21.7s8.3,13.4,15.6,13c1.1-18.6,2.4-37.2,3.9-55.8c0.9-11.7,2-23.6,6.5-34.4
            c3.5-8.4,8.9-16,12.1-24.5c4.2-11.4,4.3-23.9,8.4-35.3c3.5-10,10-18.6,14.2-28.4c3.6-8.4,5.4-17.5,8.5-26.1
            c1.3-3.5,2.8-6.9,4.4-10.2c1.5-3.1,3.1-6.1,4.8-9c1.4-2.4,3.2-4.9,4.2-7.6c5.5,0.1,11-0.9,16.4-1.4c6.3-0.6,12.7-0.8,19-0.6
            c7.1,0.2,14.2,1.2,21.2,3c7.6,2.1,15.6,5.5,23.2,3.4c1.4,7.6,9.2,12.2,16.5,14.7c7.3,2.5,15.4,4.1,20.9,9.6s5.7,16.7-1.5,19.5
            c6.5,0.2,12.1-4.6,12.9-11c13-1.5,26.9-2.9,38.8,2.7s20.5,20.6,14.2,32.2c5.6-1.9,11.2-3.8,15.9-7.3c2.4-1.8,4.3-4.1,5.8-6.7
            c0.5,0.1,1.1,0.2,1.6,0.3c3,0.8,5.7,2.6,7.6,5.1c2.4,2.9,4.2,6.4,5,10.1c1.8,7.7,0.7,15.7,1.4,23.6c2.3,28.5,25.9,51.3,31.1,79.4
            c0.9,5.4,1.3,10.8,1.3,16.3c0.1,14.3-3.1,31-15.9,37.5c17-4.9,30.2-21.2,31.2-38.9c4.7,0.7,9.5,1.4,14,0.1s8.7-5.1,8.8-9.8
            C689.4,388.1,683.5,388.2,677.6,387.7z"/>
         <path class="st12" d="M447.3,357.8c5.4,1.9,10,5.6,14.4,9.4c0.7,0.6,1.4,1.2,2,1.9c3.4,4.1,3,10.3,2.5,15.8
            c-0.6,6-1.4,12.3-4.6,17.3s-8.6,7.8-12.7,12c7.3-1.6,13.9-5.7,18.6-11.5c3.8,5.1,5.6,11.7,6.1,18.2s-0.3,13.1-1.1,19.6
            c6.2-7.6,10.2-16.8,11.6-26.6c0.6-4.6,0.8-9.2,0.8-13.8c0.1-6.4,0.2-12.9-0.9-19.2c-0.7-3.7-1.7-7.4-2.9-11.1
            c-0.8-2.5-1.6-5.1-3.2-7.2c-2.1-2.6-5.3-3.9-8.4-5c-8.9-3.2-18.1-5.9-27.5-6.7c-4.5-0.4-9.1-0.3-13.6,0.2c-4.6,0.6-9,1.8-13.2,3.7
            c-0.8,0.4-1.5,0.9-0.3,1.2c1.4,0.3,2.7,0.4,4.1,0.3c4.2,0.1,8.5-0.1,12.7-0.3c4.7-0.3,9.4,0.1,13.9,1.1
            C446.1,357.4,446.7,357.5,447.3,357.8z"/>
         <path class="st15 left-eyebrow" d="M542.7,360.3c14,0.7,27.9,1,41.9,1c3.8-0.1,7.6,0.1,11.3,0.6c3.2,0.6,6.3,1.3,9.3,2.3l15.7,4.7
            c-4.6-1.3-8-5.2-12.2-7.4s-8.8-2.9-13.3-3.8c-10.4-2.2-20.2-6.4-30.4-9.4c-8.1-2.4-17.1-4.4-25.6-3.4c-2.4,0.1-4.6,1.1-6.4,2.8
            c-2.1,2.2-3.2,5.7-3.6,8.6c-0.3,2.5,3.7,2.9,5.5,3.3C537.5,359.9,540.1,360.2,542.7,360.3z"/>
         <path class="st15" d="M434.6,356.2c-22.7-0.9-44.7,11-67.3,8.4c6.5-1.3,13-2.9,19.3-4.9c11.8-3.6,23.2-8.4,35-11.8
            s24.3-5.4,36.5-3.4c2.9,0.5,5.9,1.2,8.2,3.1c2.1,1.6,3.3,4.3,3,7c-0.3,2.2-2.2,3.9-4.5,4c-6.2-0.1-12.3-0.5-18.4-1.2
            C442.5,356.9,438.5,356.4,434.6,356.2z"/>
         <path class="st12" d="M388.9,508c0.2,7.1,0.2,14.2,0.2,21.2c0.1-6-1.3-12-4.1-17.4c-0.5-1.1-1.3-2.2-2.2-3
            c-0.6-0.4-1.2-0.8-1.7-1.3c-0.6-0.8-0.9-1.7-1-2.7c-0.8-4.1-1.6-8.2-2.4-12.2c-0.3-1.7-1-3.7-1-5.4c0-0.8,0.4-1.4,1.1-1.7
            c1.7-0.7,4.1,0.4,5.5,1.4c2.5,1.8,3.4,4.8,4.1,7.7C388.5,499,388.9,503.5,388.9,508z"/>
         <path class="st12" d="M607.5,515.2l6.7-17.5c1.2-3,2.3-6.4,1.3-9.5c-0.6-2.2-2.6-3.9-4.9-4.1c-2.1-0.1-3.3,1-3.3,3
            c0,5.9,0.4,11.7,0.3,17.5C607.7,508.3,607.6,511.8,607.5,515.2z"/>
         <path class="st12" d="M408.5,590.3c-3.5-9.6-1.1-20.8-5.6-30c5.3,16.7,13.6,32.3,24.4,46.1c7.4,9.5,16,18.2,20.5,29.4
            c-8.5-8.3-21.8-11.6-28.4-21.5c-1-1.5-1.6-3.6-2.9-4.4c-1.4-0.9-2.7-2.1-3.8-3.4c-0.7-0.8-1.1-1.9-0.9-3c0-1.9,0.4-4.7-0.3-6.4
            C410.5,594.7,409.4,592.6,408.5,590.3z"/>
         <path class="st12" d="M531.7,473.7c-11.8,6.6-24.2,12.9-37.7,13.7c-3.7,0.3-7.5-0.1-11-1.3c-2.6-1.1-5.2-2.4-7.5-4l-7.2-4.5
            c-1.4-0.9-2.3-1.7-0.5-2.7c1.6-0.8,3.4-1,5.1-0.7c2.6,0.4,5.3,0.9,8,1.2c10.8,1.4,21.8,1.6,32.7,0.8c3.1-0.2,6.1-0.6,9.2-1
            c1.5-0.2,3-0.4,4.5-0.7l2.3-0.4C530.4,474.1,531.1,473.9,531.7,473.7z"/>
         <path class="st12" d="M554.4,476c4,3.6,9.2,5.6,14,8c7,3.5,13.9,8.9,15.6,16.5c2,9.1-4,18-10.9,24.2c7.2-5.7,12.6-14,13.3-23.1
            s-3.8-18.9-12-22.9c-5.5-2.7-12.1-2.9-17-6.6c-5.8-4.7-7-13.1-2.9-19.3c-0.5,0.6-1.7,1.1-2.3,1.7c-0.7,0.7-1.4,1.4-2,2.3
            c-1,1.2-1.6,2.7-1.9,4.2c0,1.3,0.1,2.6,0.5,3.9c0.2,1.9,0.7,3.8,1.5,5.6C551.3,472.6,552.7,474.5,554.4,476z"/>
         <path class="st12" d="M429.7,482.2c-2.6,1.2-5.3,2-7.9,3.3c-10.2,5.1-16.3,15.8-15.4,27.2l-0.7-20.1c-0.1-2.9-0.1-5.9,1.4-8.3
            c2.3-3.6,7.1-4.3,11.3-5.3c5.5-1.1,10.6-3.6,14.9-7.2c4.2-3.7,6.8-9,7.2-14.6c0.9,2,1.6,4.2,2.1,6.4c0.4,1.4,0.4,2.8,0,4.2
            c-1.4,4-3.7,7.5-6.9,10.3C433.9,479.8,431.9,481.2,429.7,482.2z"/>
         <path class="st12" d="M476.9,570l3.6-2.2c3.6-2.6,8-3.9,12.4-3.6c3.2,0,6.6-0.1,9.7,1.1c1.7,0.7,5.3,2.7,3.8,5.1
            c-0.7,1.1-1.9,0.5-2.9,0.2c-1.6-0.4-3.3-0.8-5-1c-3.4-0.4-6.8-0.6-10.3-0.5c-1.9,0-3.8,0.2-5.7,0.3l-2.9,0.2
            C479,569.8,477.5,569.6,476.9,570z"/>
         <path class="st12" d="M415.1,507c0,0,31,15.4,74.8,13.5c43.8-1.9,65.6-3.1,81-13.5c0,0,1.7,14.5-81.4,17.8
            C489.5,524.8,424.8,520.2,415.1,507z"/>
         <path class="st16" d="M415.1,494.3"/>
         <path class="st14" d="M415.1,507c0,0,76.7,32.8,155.8,0c0,0-15.9,45.6-77.9,44.4S415.1,507,415.1,507z"/>
         <path class="st17" d="M414.8,507c0,0,0.3,0.9,1.1,2.5c2.8,4.3,7.5,7.7,11.3,10.5c11.6,8.3,24.9,13.9,39,16.2
            c1.8,0.3,3.6,0.6,5.4,0.8c24.3,2.8,48.8-2.5,71.9-10.4c7.5-2.4,14.8-5.6,21.8-9.4c2.1-3.2,3.9-6.6,5.3-10.2
            C491.5,539.9,414.8,507,414.8,507z"/>
         <path class="st18" d="M456.2,304.5c10.8-3.2,20.7-11.1,24.1-21.9c4.6-14.3-3.2-30.3-14.9-39.7s-26.7-13.6-41.3-17.3
            c-1.8-0.6-3.7-0.8-5.6-0.6c-3,0.5-5.3,3-7,5.4c-9.7,13.5-9.5,30.5-3.7,45.5c2,5.1,3.3,10.8,6.3,15.4c3.3,5.1,9.2,9,14.5,11.5
            C437.1,307,447.2,307.2,456.2,304.5z"/>
         <path class="st18" d="M481.4,441.2c-1.8,2.9-3.4,5.9-4.6,9.1c-0.8,1.8-1.6,3.9-0.8,5.8c0.7,1.4,1.8,2.5,3.1,3.1
            c4.3,2.7,9.1,4.6,14.1,5.6c4.2,0.9,9.4,0.7,11.7-2.9c0.6-1.1,1.1-2.2,1.3-3.4c1-3.5,1.9-7.1,2.7-10.7c0.5-2.1,1-4.4,0.4-6.5
            c-0.6-1.7-1.5-3.3-2.6-4.6c-3.2-4.3-6.8-8.8-11.8-11.1c-0.8-0.4-2.2-1.1-3.2-0.6c-0.7,0.4-0.8,1.3-0.9,2c-0.5,2.4-1.6,4.7-3.1,6.6
            C485.6,436.1,483.3,438.4,481.4,441.2z"/>
         <path class="st18" d="M397.1,407.5c-7.6,2.6-13.9,8.9-19.9,15c-3.8,3.9-7.8,8-9.6,13.6c-0.3,0.9-0.4,1.8-0.2,2.7
            c0.3,0.8,0.9,1.4,1.7,1.8c7.7,4.7,16.6,4.6,25.2,4.5c10.2-0.2,20.8-0.4,30.3-4.9c4.7-2.2,9.3-5.8,11.2-11.4
            c0.8-2.6,1.2-5.3,1.2-8.1c0.1-4.3-0.5-8.5-3.2-11.5c-1.7-1.8-3.6-3.3-5.9-3.4s-4.5,0.5-6.7,0.6c-3.7,0.1-7.5-0.4-11.2-0.5
            C405.7,405.6,401.3,406.2,397.1,407.5z"/>
         <path class="st18" d="M608.3,408.5c-6.7-1.2-13.4-2.1-20.2-2.6c-6.3-0.5-12.6-0.6-18.7,1s-12,5.2-15.4,10.8c-0.7,1-1.1,2.2-1.1,3.4
            c0.2,1.4,1,2.7,2.1,3.6c3.1,2.7,7,3.7,10.7,5.2c9,3.7,16.5,10.6,24.8,16s18.5,9,27.5,5.4c7.6-3,13.1-11.4,13.3-20.2
            s-4.9-17.4-12.3-20.9c-5.1-2.4-10.9-2.5-16.4-1.9"/>
         <path class="st18" d="M464,594.5c-3.7,2.4-7,6.7-5.6,10.9c1.4,4.4,6.9,6,11.5,5.7c5.2-0.4,10.1-2.2,14.3-5.3
            c3,4.5,7.4,8.3,12.7,9.5c5.3,1.2,11.4-0.7,14.2-5.3c2.8-4.6,1.8-10.9-1.5-15.1c-3.3-4.2-8.5-6.7-13.8-7.8c-1.6-0.5-3.3-0.4-4.9,0.1
            c-1.8,0.7-3,2.2-4.6,3.2s-2.3,0.3-4-0.2c-3.8-1-8.6,0-12.2,1.2C467.9,592.3,465.9,593.3,464,594.5z"/>
         <path class="st12" d="M576.8,592c3.5-10.1,1.1-21.9,5.7-31.5c-5.3,17.5-13.5,33.9-24.4,48.5c-7.4,9.9-16,19.2-20.5,30.9
            c8.5-8.7,20.9-13.2,27.5-23.6c1-1.5,1.8-3.2,2.9-4.7c1.1-1.4,2.4-2.6,3.8-3.5c1-0.8,0.9-1.9,0.9-3.2c0.1-2,0.5-3.9,1.2-5.8
            C574.9,596.7,576,594.4,576.8,592z"/>
         <path class="st12" d="M652.5,490.3v-41.1c0,0-9.8,82.3-16,90.6C636.5,539.8,654,506.4,652.5,490.3z"/>
         <polygon class="st12" points="358,548.1 337.9,453.6 350.6,535.8 	"/>
         <path class="st15" d="M342.2,347.6c-3.8,2.5-7.4,5.1-10.9,7.9C335.5,353.7,339.2,351,342.2,347.6z"/>
         <path class="st15" d="M390.8,203.8c-0.6,0-1.2,0-1.9-0.1c0,0.5,0.1,0.9,0.4,1.3C390.2,206.2,392.2,204,390.8,203.8z"/>
         <path class="st15" d="M394.9,182.9l-6.7,0.4c-0.6,1-1.1,2.1-1.4,3.3c-4.9,0.1-9.8,0.2-14.7,0.8c2,0.4,4,0.7,6,0.9
            c-15.3,7.3-30.2,15.3-44.7,24.2c-1.5,2.4-2.9,4.8-4.3,7.2c3,0.9,5.9,2.3,8.4,4.2c-6.2,2.9-12.2,6.3-18,10.2
            c-1.5,2.2-2.8,4.5-3.9,6.9l5,1.1l-7.5,6c-0.6,3-0.9,6-1,9l11.6,1.5c-3,6.1-6.6,11.8-10.9,17.1c0.1,1.9,0.1,3.8,0,5.6l4.2,0.7
            c0.5,7.3-3.3,13.4-8.7,18.6l-2,4.8c4.5-1.7,9-3,13.6-4.1c-1.4,3.9-2.9,7.8-4.4,11.7l12.1-8.9l-24,24.6l0.1,0.1
            c3.4-3,7.2-5.7,11.2-7.8c0.3,5.6,0.4,11.4-0.6,16.8c0.2,3.1-0.9,6.2-3,8.5l-0.2,0.2c-0.3,0.5-0.7,1-1,1.5
            c-3.3,4.5-8.2,7.5-13.3,10.3l0.1,0.2h-0.5c-1.9,1.1-3.8,2.1-5.6,3.2c2.2,1.9,4.5,3.6,7,5c0.5-0.1,1-0.1,1.4-0.1v-0.1
            c0.1-0.8,1.1-1.2,1.9-1.4c4.6-0.8,9.3-1.4,14-1.8c-0.9,2.9-1.5,6-1.6,9.1c4.5,0.7,9,0.8,13.5,0.2c-0.5,6.7-4.9,12.5-11.2,14.7
            c-0.1,0.3-0.2,0.7-0.3,1c1.6,1.4,2.4,3.6,2,5.7c-0.4,2.5-2,4.6-3.5,6.7l4.8,0.3c3.3-3.6,7.1-6.7,11.4-9c4-6.1,8.5-12,13.4-17.4
            l-13.3,8c-1.4-6.1,1.1-12.5,6.4-15.9c-5.3,1.4-10.7,1.8-16.1,1.3l11.4-4.4l-9.1-0.2c2.7-2.7,5.6-5.2,8.5-7.6
            c-0.5,0.2-0.9,0.4-1.4,0.6c4.3-14.7,12.3-28.2,23.2-39c-8.9,7.5-17.9,14.7-27,21.7c1.1-5.9,4.3-11.2,8.9-14.9
            c-2.8-0.4-2.2-4.6-0.8-6.9c4.6-7.3,11.1-13.3,18.8-17.3l-19.1,8.2c5.3-15.3,16.5-27.7,31.2-34.6l-13.6,0.5
            c2.4-3.9,5.4-7.5,8.8-10.6c-1.6,1.3-4-1.1-3.5-3.1s2.5-3.2,4.3-4.2c1.8-1,3.8-2.1,4.4-4.1l-31,21.1c-1.4-8.8,4.2-17.5,11.5-22.5
            s16.2-7.2,24.9-9.2c-2.7-0.6-0.5-4.9,2.2-5.7c-2.3-2.4-5.5-3.9-8.8-4.1c5.5-4.8,12.2-8,19.3-9.5c-9.3-0.1-18.6,1.1-27.5,3.6
            c8.3-6.8,18.2-11.4,28.7-13.5c-2.9-3-6.9-4.7-11.1-4.7c4.8-2.4,10-3.6,15.3-3.6c0.1-0.9,0.3-1.9,0.5-2.8c0.6-3.4-3.8-6.8-7.1-6.3
            V194C386,191,392.2,186.7,394.9,182.9z"/>
         <path class="st15" d="M300.3,366.4l-1.2,0.1C299.2,367.3,300.8,367,300.3,366.4z"/>
         <path class="st15" d="M336.4,389c-2.2,0.7-4.3,1.7-6.3,2.8c-0.6,0.9-1.1,1.7-1.6,2.6C331.6,393.5,334.4,391.6,336.4,389z"/>
         <path class="st15" d="M679,314.7c-0.6-1.4-0.9-2.9-0.7-4.4c0-1.4,0-2.7,0-4.1c-0.4-1.2-0.8-2.5-1.2-3.7l-13.2-2.5l4.6-1.2l-6.8-5.5
            c3.1-1.3,6.5-2,9.8-1.8c-5.1-2.5-10.7-4-16.5-4.2l5.3-2.2c-2.2-1.3-3.8-3.2-4.8-5.6l4.8-1c-6.7-5.3-12.4-11.8-16.9-19
            c4.7,3.1,10,5.1,15.6,5.9c-0.7-1.2-1.6-2.3-2.6-3.3c-4.2,0-8.2-1.9-10.7-5.3c4.1,0.5,7.8,2.4,10.7,5.3c1.2,0,2.3-0.2,3.4-0.5
            c3.1,4.2,7.4,7.3,12.3,9.1c-0.7-1.6-1.1-3.3-1.2-5c-1-1.4-2.2-2.8-3.2-4.2c-2.1-3.1-2.7-8.2,0.8-9.6c1.1-0.4,2.2-0.6,3.3-0.7
            l0.3-4.2c-2.3-2.1-3.2-5.3-2.2-8.3c-5,3-10.9,4.2-16.7,3.3c0.1-1.2,0.1-2.4,0.1-3.6h-4.6c1.5,0,3.1,0,4.6-0.2
            c0-2.2-0.2-4.5-0.5-6.7c-2.4-1.8-4.4-4.1-5.8-6.8l4-1.4c-1.1-3.4-2.5-6.6-4.2-9.7l-1-0.1l0.8-0.3c-0.8-1.4-1.7-2.8-2.6-4.1
            c-2.8-0.4-5.7-0.6-8.5-0.5c-17.6,2.1-35.5-0.5-51.8-7.5c16,2.3,32.2,1.4,47.8-2.6l-21.9,0.3c4.2-1.8,8.6-2.8,13.2-3
            c-16.4,0.3-32.7-0.1-49.1-1.2l33-3.2l-21.7-1L614,189c0.1-0.5,0.3-0.9,0.6-1.2c0.9,0.6,2,0.9,3.1,1l1.5-0.1c-0.5,0.1-1,0.1-1.5,0.1
            l-3.8,0.2c-0.6,2.6,2.9,6,5.4,4.3s0.8-6-1.8-6.6l-1.5,0.1c0.5-0.2,1-0.2,1.5-0.1l2-0.1c-2.8,0.1-5.7-0.2-8.5-0.8l7.5-1.5l-0.1-0.1
            c-19.9,0.3-39.8-0.4-59.6-2.1c-16.5-0.6-32.9-2.6-49-6.2c16.2,2.7,32.5,4.8,48.9,6.1c17.6,0.6,35.3-0.5,52.7-3.4
            c-2.2-0.9-4.6-1.6-6.8-2.4c-2.1-0.8-4.2-1.7-6.1-2.8c-1.4-0.1-2.9-0.1-4.3,0l-6.1,0.5c2-0.3,4.1-0.5,6.1-0.5l3.7-0.3
            c-0.3-0.2-0.6-0.3-0.9-0.5c-13-1.1-25.8-3.9-38-8.3c7.6-0.5,15.1-1.3,22.6-2.3c-1.2-0.9-2.5-1.7-3.7-2.5l-2.6-0.1
            c-11.2-1.9-22.1-4.9-32.7-9.1c4-0.5,7.9-1.1,11.8-2c-13.4-4.6-27.7-7.1-41.8-9.3c-1.6-0.2-3.3-0.6-4.2-1.9
            c-0.6-1.1-0.8-2.3-0.6-3.5l0.7-18.9c-4.4,8.2-14.6,11.3-24,12.7s-19.4,2.3-26.5,8.5c0.9-1.8,0.8-4-0.4-5.6
            c-6.6,12.7-17.8,22.5-31.2,27.3c0.4-3.8-1.7-7.5-5.1-9.2c3.1,9.9-6.8,18.8-15.9,24c-7.4,4.2-16,9.4-18.8,16.9
            c1.8,1.7,3.5,3.5,5,5.4c3.4-1.6,6-4.6,7.2-8.1c1.5,1.5,2.5,3.5,2.8,5.6c1.1-3.9,2.2-7.9,4.7-11.1s6.8-5.4,10.7-4.3
            c-0.8,6.4-1.6,12.7-2.4,19.1c3.4-3.4,6.1-7.5,8-11.9c0,5.3-0.9,11.5,1,16.7c2.1-0.3,3.6-0.5,4-0.6c6.2-1.3,12.3-3.2,18.1-5.9
            l-5.3,4.4c18.8,5.3,38.9,4.4,57.2-2.6l-17,7.9c18.5,2.2,37.2,0.9,55.2-3.7c-12.8,5.9-26.7,9-40.8,9.1c6.6,1.9,12.6,5.2,17.8,9.6
            l-13.5,1.1c1.9,4.4,5.5,7.2,9.9,8.9c1,0.3,2,0.7,3,1c5.2,1.3,10.6,2,16,2c7.4,0.3,14.7,0.1,22.1-0.5c6.2-2,12.6-3.5,19-4.5
            c-6.1,2.4-12.5,3.9-19,4.5c-4.1,1.3-8.1,2.8-12,4.5l29.9,3c1.9,0.1,3.7,0.5,5.5,1.2c3.1,1.5,4.8,4.8,7.4,7c3.4,2.8,8.1,3.6,12.3,2
            l-0.1,6.9l-7.3-2.1c4.3,3.8,6.7,9.4,6.5,15.1l11.4-15.4c-1.3-12.6-14.5-20.2-26.1-25.8c13.2-0.3,25.8,5.6,34.2,15.8l-0.4-18.9
            c3-1.7,7,0,8.9,2.9s2.3,6.4,2.6,9.8c0.3,3.6,0.7,7.3,1,11c-1.3,7.3-2.5,14.5-3.8,21.8c2.3-0.3,4.4-1.3,6.1-2.9
            c0.1-0.2,0.3-0.4,0.5-0.5l-0.5,0.5c-0.8,1.2,0.5,3.4,2.2,3.6c1.4-1.5,3.2-2.6,5.2-3.2c-1.3,1.5-2.9,3.2-5,3.2h-0.2
            c-1.4,1.4-2.4,3-3,4.9c4.2,1.8,8.7,2.9,13.2,3.1c1.6-0.2,3.2-0.3,4.8-0.1c-1.6,0.1-3.2,0.1-4.8,0.1c-2.5,0.4-4.9,1.3-7.1,2.6
            c5.7,1.3,11,4.2,15.3,8.2l-7.2,2.5c2.3,1,4.4,2.5,5.9,4.5l-8.6,3.3c6.1,8.7,15.2,14.9,25.5,17.5l-28.5,0.1
            c11.9,8.4,26,13.4,40.5,14.5c-8.9,3.3-18.8,3.3-27.7,0.1c1.8,4.6,5.8,8,10.6,9.1l-4.8,2.5c1.9-1.1,2,3.1,0,3.9
            c7.3,2.1,14.7,3.7,22.2,4.7c-0.2-0.7-0.4-1.4-0.8-2l4,1.1c0.1-2.8,0.2-5.6,0.2-8.5l6.7,2.2c-0.3-9.6-1.5-19.2-3.7-28.6
            C680.3,316.3,679.5,315.6,679,314.7z"/>
         <path class="st19" d="M504,150.2c-4.2,3.7-8.7,7-13.4,9.9c9.1,1.6,18.5,0.5,27-3c-4.2,1.8-8.2,4.1-11.8,7l9.2,1l-2.3,2.2
            c22.1,1.9,44.9,3.6,65.8-3.8l-43.1,15.1c4.6,0.4,9.2,0.3,13.8-0.4l-6.8,1.2c6.6,4.7,14.4,7.7,22.5,8.6c-3.3,0-6.6,0.6-9.7,1.6
            c16.6,3.8,33.9,4.2,50.6,1c-11.7,2.4-23.1,6-34,10.9l13.3,4.6l-4.9,1.8l20.6,5.3l-3.2,2.4l10.6,4.5c-17.1-4.9-34.8-8-52.6-9.1
            c3.9-1.6,8.1-2.5,12.3-2.6l-12.9-3.9l5.9-1.2l-32.7-1l24.1-7.6l-5.1-0.7c-4.3-1.6-9-2.3-13.6-1.8l4.4-3.9l-5.6-0.2
            c0.6,0.3,1.1-0.7,0.7-1.2c-0.4-0.5-1.1-0.7-1.7-0.7c-20.2-1.9-40.6,0.1-60.1,5.9c16.1-9,33.4-15.8,50.6-22.6l-26.8,4l2.8-3
            l-10.1-0.2c0.1-0.8,0.8-1.4,1.6-1.5l-5.7,1.5c0.9-1.5,1.2-3.3,1-5.1c-21,3-42,5.1-63.2,6.4c1.6-0.3,3.2-0.7,4.7-1.2
            c1.7-0.4,3.3-0.8,5-1.3c3.3-0.8,6.5-1.6,9.8-2.4c6.6-1.6,13.2-3.1,19.9-4.5c3.8-0.8,7.6-1.5,11.3-2.3c-2.3-0.1-0.4,3.4,0.9,3.9
            c1.8,0.6,4-0.4,5.7-1.1c5.6-2.1,11.1-4.6,16.4-7.4C498.2,153.7,501.1,152,504,150.2z"/>
      </g>
      <path class="st6" d="M804,284.4l-23.3-44.7c0,0-119.9-177.2-279.1-188.5S229.8,263.9,229.8,263.9s-18.1,11.5-15.2,34.4
         c3,22.9,36,268.6,36,268.6l18.6,108c0,0,32.2,72.2,221.1,63.2s275.3-52.2,272.3-94.5s25.9-216.3,25.9-216.3L804,284.4z M757.7,525.2
         c0,0-3.5,4.6-26,12.5c-22.5,7.9-318.8,87.4-458.7,8.2c0,0-41.4-147.9-38.7-208.9c0,0,190.3-113.8,543.9-18.8L757.7,525.2z"/>
      <path class="st7" d="M321.4,150.2c0,0,111.8-88,185.1-82.1s169.8,36.4,268.3,164.1c0,0-130.8-208.8-300.9-178.9
         C473.9,53.3,409.1,59.4,321.4,150.2z"/>
      <path class="st7" d="M242.6,260.7c0,0,38.1-63,251.5-50.2s278.2,57.8,278.2,57.8s11.4-4.3,2.1-27.6c0,0,21.3,16.1,0.7,56
         c0,0-315.5-103.3-544,14.5C231.1,311.1,201.3,281.3,242.6,260.7z"/>
      <path class="st7" d="M353.4,605c1.9,10.7-2.8,23,3.4,31.8c6.6,9.4,23,9.7,25.8,21c0.7,2.7,0.4,5.6,1.6,8c1.4,2.9,4.5,4.4,7.4,5.7
         l55.4,25.4c-14.5,6.9-30.3,10.8-46.3,11.7c98.2,28.6,205.2,8.3,297.2-37c-12.9,0.9-25.8-1.1-37.8-5.8c16.8-27.2,46.3-42.6,74.4-56.8
         l-35.9-5c-0.1-4.1,1.2-8.2,3.8-11.4c-14.9,1.5-29.9,0.3-44.4-3.5l23.9-37.8c-49,2.3-97.7,7.6-146.1,15.8
         c-42.6,7.2-85.3,16.7-128.3,14.5c-27.3-1.4-54.4-7.5-81.8-6.5c-7.4,0.3-15.4,0.7-22.6,2.8c5.9,3.9,12.8,6.2,19.9,6.7
         c8.6,0.8,17.1,1.9,23.7,8.2C350.1,596,352.5,600.3,353.4,605z"/>
      <path class="st7" d="M521.6,184.7c14.3-5.6,30.2-4.9,45.3-2c0.1-22.6,25.1-36.7,47-37.1c5-0.1,10.1,0.2,14.8-1.6
         c10.7-4.2,15.3-18.1,25.9-22.5c14.3-6,28.3,8,39.2,19.2c19,19.3,42.2,33.8,60.7,53.7s32,48.1,24.7,74.6c-0.5,1.8-1.3,3.8-2.9,4.6
         c-1.1,0.4-2.4,0.5-3.6,0.2L519,240c-6.5-0.9-13.3-1.8-18.7-5.6c-15.9-11.2-2.1-33.8,9-42.6C513,188.8,517.2,186.4,521.6,184.7z"/>
      <path class="st20" d="M493.3,99.6c62.1,0.7,122.9,35.5,156.4,89.5c-26.3-61.2-91.9-99.7-156.9-99.2c-25.8,0.4-51.2,5.9-74.9,16.3
         c-23.9,10.5-45.9,25-65,42.9c-4.7,4.4-9.3,9-13.6,13.8c-1.9,2.1-10.9,9.9-10.1,12.9s7.3,1.4,9.1,0.7c5.2-1.8,7.9-6.4,11.3-10.5
         c16.9-19.6,37.6-35.6,60.8-47.1c23-11.5,48.2-18,73.9-19.2C487.4,99.7,490.3,99.6,493.3,99.6z"/>
      <path class="st20" d="M685.1,216.7l4.4,0.2l-7-43.9c6.3,15.8,20.1,28.1,36.2,32.2c4.3-18.9-8.2-37.7-22.6-50.2
         c-15.3-13.2-33.5-22.7-53.1-27.7c-2.4-0.6-5.7-2-8.1-1.8s-3.2,2.2-1,4.2c-0.4-0.1-0.9,0.1-1.3,0c17,19.9,31.1,42.9,43,66.4
         C678.9,203,682.1,209.8,685.1,216.7z"/>
      <path class="st20" d="M584.7,596.2c-6.6,10.4-6.8,25.6,1.8,34.5c6.9,7.2,17.6,8.9,27.4,8c21.6-1.9,42.4-16.8,39.8-41.3
         c-0.8-7.4-3.4-14.5-10.2-18c-8.5-4.3-20-3.2-29-1.6C602.9,579.9,591.3,585.8,584.7,596.2z"/>
      <path class="st20" d="M605.2,648.7c-10.4,5.8-17.8,18.7-14.6,30.5c3.1,11.4,15.8,18.5,27.1,16.6s20.7-11.6,24.2-22.8
         c2-6.5,4.6-26.9-4.3-28.7C627.2,642.1,614.6,643.5,605.2,648.7z"/>
      <path class="st20" d="M415.5,657.5c5.9,4.8,10.1,11.4,13.8,18.1c5.7,10.2,10.5,20.9,14.3,31.9c-9.7,2.6-20.1-0.5-28.5-6
         s-15.2-13.4-21.8-21.1l-12.7-14.7c-2.8-3.3-8.5-7.5-7.2-12.6c2.5-9.4,20-5,26.2-3.4C405.4,651.3,410.7,653.9,415.5,657.5z"/>
      <path class="st20" d="M355.2,616.1c-8-0.4-12.4-12-7.6-18.7s15.4-6.8,21.3-1.2s7.1,15.2,4.2,22.9l-19.2-2.6"/>
      <path class="st21" d="M234.2,327c0,0,190.3-113.8,543.9-18.8l-20.5,207c0,0-3.5,4.6-26,12.5c-22.5,7.9-318.8,87.4-458.7,8.2
         C272.9,535.9,231.5,387.9,234.2,327z"/>
      <path class="st10" d="M515.5,727.9c-75.9,6.9-145.7,1-198.4-14.1c43.9,25.8,123.4,38.7,212.8,30.6c114.9-10.4,208.8-52,230.5-98.4
         C713.4,685.9,622.1,718.2,515.5,727.9z"/>
      <path class="st10" d="M216.1,888.9L189,903.1c-26.5-43.4-47.3-90-62-138.7c0-0.1,30.5-12.8,34.2-12.8c4.1-0.1,6.9,2.4,9,5.8
         c3.2,5.3,5.8,11.2,8.5,16.8c11.2,23.3,20.3,47.5,27.5,72.4C210.1,860.4,213.4,874.6,216.1,888.9z"/>
      <path class="st10" d="M95.6,839.3c-6.4-5.8-13.3-11.9-21.7-12.9c-8.7-1.1-17.8,4.2-21.5,12.4c-3.7,8.5-1.8,18.4,4.6,25l72,58.6
         c8,6.5,17.5,14.2,28.3,13.8c8.6-0.4,18.7-7.6,16.9-17.4c-1.8-9.6-10.5-17.3-17.2-23.4l-29-26.5L95.6,839.3z"/>
      <path class="st10" d="M808.4,833.6c-1.7,4.8-3.2,9.8-3,14.8s2.7,10.3,7.1,12.5c8.8,4.3,17.9-4.9,23.5-13.1l78.1-113.3
         c3.2-4.6,6.5-9.6,6.5-15.2s-4.2-10.3-9-12.3c-5.3-2.1-8.4-0.8-12.3,2.9c-4.5,4.2-9.8,7.7-14.5,11.8s-9.4,8.3-13.8,12.7
         c-27,26.8-47.9,59.1-61.1,94.8C809.5,830.6,808.9,832.1,808.4,833.6z"/>
      <path class="st10" d="M877.4,868.7c-1.8,4.3-3.4,9-2.8,13.6s4,9.3,8.5,9.6c3.6,0.2,6.7-2.3,9.5-4.6c36.9-30.7,73.8-61.3,110.8-91.9
         c4.9-4,10-8.6,11.2-14.9c1.3-7.2-3.2-14.4-9.1-18.3c-5.1-3.4-11.3-4.7-17.1-6.5c-5.2-1.7-9.1,0.4-13.7,2.9
         c-10.5,5.8-20.6,12.4-30,19.9c-9.5,7.5-18.3,15.8-26.4,24.8c-8.1,9-15.5,18.7-22.1,28.9c-6.6,10.3-12.3,21-17.1,32.3
         C878.5,865.8,878,867.3,877.4,868.7z"/>
      <path class="st5" d="M847.5,654.2c-46.9,58-175.7,110.3-332.1,128.7c-86.2,10.1-167,8.3-232.4-3c57.3,28.7,160,40.5,274.1,27.1
         c169.1-19.8,299.2-87.5,290.7-151.2C847.8,655.2,847.6,654.7,847.5,654.2z"/>
      <polygon class="st9" points="611.3,818 428.6,831.3 425,773.4 607.6,760.1 "/>
      <g id="PACK">
         <polygon class="st22" points="275.8,875.7 247.6,928.7 247.6,1054.9 788.8,1067 788.8,866.7 	"/>
         <polygon class="st23" points="258.6,927.1 746.5,927.1 746.5,1056.9 250.7,1056.9 	"/>
         <rect x="267.8" y="959.7" class="st24" width="190.2" height="99.4"/>
         <rect x="278.9" y="967.3" class="st20" width="168.9" height="99.4"/>
         <ellipse class="st24" cx="533" cy="994.6" rx="14.9" ry="15.3"/>
         <ellipse class="st24" cx="583.9" cy="994.6" rx="14.9" ry="15.3"/>
         <ellipse class="st24" cx="634.8" cy="994.6" rx="14.9" ry="15.3"/>
         <ellipse class="st24" cx="685.7" cy="994.6" rx="14.9" ry="15.3"/>
      </g>
      <path class="st10" d="M237.5,330.2c0,0.2,0,0.5,0,0.8c11.3,1.7,22.9,3.2,34.6,4.6c18.4-17,47.6-21.8,73.8-24.6
         c-9.2,7.6-14.7,18.9-16.1,30.6c10.3,0.9,20.7,1.7,31.2,2.4c35.9-13.8,81.5-22.3,131.2-23.1c7.2-9.8,16.5-15.6,25.5-12.8
         c6,1.8,10.9,7,14.8,13.9c37.9,2.6,72.5,9.8,101.5,20.2c45.5-3.7,88.8-9.1,128.9-15.9l1.4-14.2C421.8,219.9,237.5,330.2,237.5,330.2z
         "/>
      <path class="st5" d="M634,342.1c-28.9-10.5-63.6-17.6-101.5-20.2c3.9,7.1,6.8,16.1,8.6,25.4C573,346.2,604,344.5,634,342.1z"/>
      <path class="st5" d="M480.9,345.3c2.2-8.8,6.1-17.1,11.4-24.5c-49.8,0.8-95.3,9.4-131.2,23.1c38.4,2.6,78.3,4,119.2,4.1
         C480.4,347.1,480.6,346.2,480.9,345.3z"/>
      <path class="st17" d="M532.6,321.8c-3.8-6.9-8.7-12-14.8-13.9c-9-2.8-18.3,3-25.5,12.8c2.6-0.1,5.2-0.1,7.8-0.1
         C511.1,320.7,521.9,321.1,532.6,321.8z"/>
      <path class="st17" d="M480.2,348h4.6c19,0,37.8-0.3,56.4-0.8c-1.8-9.3-4.7-18.3-8.6-25.4c-10.6-0.7-21.4-1.1-32.5-1.1
         c-2.6,0-5.2,0-7.8,0.1c-5.3,7.4-9.2,15.7-11.4,24.5C480.6,346.2,480.4,347.1,480.2,348z"/>
      <path class="st5" d="M345.9,311c-26.2,2.9-55.4,7.6-73.8,24.6c18.7,2.3,38,4.3,57.7,6C331.2,329.9,336.7,318.6,345.9,311z"/>
      </svg>
      
      <div class="alarm">
         <span class="alarm-light"><span class="small-light" /></span>
      </div>
   </div>
</template>

<script>
export default {
  name: 'MeSciFi',
}
</script>

<style lang="scss" scoped>

.st0{fill:#19F22E;stroke:#029B0D;stroke-width:4;stroke-miterlimit:10;}
.st1{fill:#04A00F;}
.st2{fill:#7C7916;}
.st3{fill:#F4F425;}
.st4{fill:#FFFFFF;stroke:#582C0D;stroke-width:2;stroke-miterlimit:10;}
.st5{fill:#979CC9;}
.st6{fill:#DB5E46;stroke:#B12300;stroke-width:4;stroke-miterlimit:10;}
.st7{fill:#B12300;}
.st8{fill:#582C0D;}
.st9{fill:#3A1C07;}
.st10{fill:#D3D6EF;}
.st11{fill:#FFFFFF;stroke:#582C0D;stroke-width:5;stroke-miterlimit:10;}
.st12{fill:#D19477;}
.st13{fill:#F2CCB7;}
.st14{fill:#281305;}
.st15{fill:#84522C;}
.st16{fill:#00A0E7;}
.st17{fill:#FFFFFF;}
.st18{fill:#FEE3D4;}
.st19{fill:#B78B6E;}
.st20{fill:#FFE7BE;}
.st21{fill:none;stroke:#582C0D;stroke-width:8;stroke-miterlimit:10;}
.st22{fill:#AE4200;stroke:#2A2309;stroke-width:5;stroke-miterlimit:10;}
.st23{fill:#EAC928;}
.st24{fill:#2A2309;}

@keyframes tentRise {
   5% {
      transform: translateY(-10%);
   }

   25% {
      transform: translateY(-35%);
      transform: translateX(100px);
   }

   50% {
      transform: rotate(-10deg);
   }

   85% {
      transform: translateX(150px);
      transform: translateY(-40%);
   }

   90% {
      transform: translateX(200px);
      transform: translateY(-20%);
   }

   100% {
      transform: translateX(0);
      transform: translateY(0);
   }
}

@keyframes tentRise2 {
   10% {
      transform: translate(-50px, -100px);
   }

   80% {
      transform: translate(-200px, -200px);
   }

   90% {
      transform: rotate(90deg);
   }
}

$smallLight: 10px;
$lrgLight: 200px;

@keyframes alert {
   from {
      background: radial-gradient(circle, rgba(242,7,7,0) 2%, rgba(0,0,0,0) 10%);
   }

   to {
      background: radial-gradient(circle, rgba(242,7,7,0.5) 20%, rgba(0,0,0,0) 81%);
   }
}

.alarm {
   display: flex;
   justify-content: center;
   margin-top: -150px;
   position: relative;
   pointer-events: none;

   .alarm-light {
      border-radius: 50%;
      display: block;
      transition: all 0.2s;
      height: $lrgLight;
      width: $lrgLight;
      animation-name: alert;
      animation-duration: 1s;
      transform-origin: center;
      animation-iteration-count: none;

      .small-light {
         height: $smallLight;
         width: $smallLight;
         border-radius: 50%;
         position: absolute;
         background-color: darken(maroon, 10%);
         margin: auto;
         right: 0;
         left: 0;
         bottom: 92px;
      }
   }
}

.me-sci-fi-wrapper  {
   width: 110%;
   margin-top: -20px;
   position: relative;
   left: -20px;

   ellipse, path, .star {
      transition: all 0.2s;
   }

   &:hover {
      #TENTACLE-RIGHT {
         animation-name: tentRise;
         animation-duration: 7s;
         opacity: 1;
         animation-direction: alternate;
         animation-iteration-count: infinite;
      }

      #TENTACLE-LEFT {
         animation-name: tentRise2;
         animation-duration: 8s;
         opacity: 1;
         transform: scale(0.8);
         transform-origin: bottom left;
         animation-direction: alternate;
         animation-iteration-count: infinite;
      }

      .left-eyebrow {
         transform: translateY(-15px);
      }

      .alarm-light {
         animation-iteration-count: infinite;

         .small-light {
            background-color: lighten(red, 10%);
         }
      }
   }

   #TENTACLE-RIGHT, #TENTACLE-LEFT {
      opacity: 0;
      transition: all 0.2s;
   }
}
</style>
