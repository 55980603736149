<template>
    <div class="loading" :class="{ rollup: fullyLoaded }">
        <div class="loading-img">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 654.8 705.7" style="enable-background:new 0 0 654.8 705.7;" xml:space="preserve">
            <g id="FACE_5_">
                <path class="st0" d="M160.3,323.6c-1.7,0-26.4-23.4-21,21.7s6.8,55.5,10.1,76.5c3.4,20.9,26.1,25.4,30.8,25.6
                    C184.9,447.6,162.1,323.6,160.3,323.6z"/>
                <path class="st0" d="M485.8,318.4c0,0,21.8-24.8,29.9,0c8.1,24.8,10.8,34.4-7.2,106.4c0,0-7.3,30.1-32.1,23.2
                    C471,434.4,480.4,369.4,485.8,318.4z"/>
                <path class="st0" d="M175.7,414.7c0,0,7.2,75.8,18,85.7c10.8,9.9,71.3,85.7,89.3,88.4s41,0,41,0s36.5,12.6,67.2-12.6
                    c30.7-25.3,92.9-83,85.7-141.6l6.3-64l2.7-58.6c0,0,20.7-172.3-165.1-169.6S159.4,308.2,159.4,308.2S159.4,400.2,175.7,414.7z"/>
                <path class="st0" d="M174.3,409.2c0,0,7.2,75.8,18,85.7c10.8,9.9,71.3,85.7,89.3,88.4s41,0,41,0s36.5,12.6,67.2-12.6
                    c30.7-25.3,92.9-83,85.7-141.6l6.3-64l2.7-58.6c0,0,20.7-172.3-165.1-169.6s-161.5,166-161.5,166S158.1,394.8,174.3,409.2z"/>
                <path class="st0" d="M157.6,318.1c-1.7,0-26.7-23.1-20.7,21.9s7.5,55.4,11.1,76.3s26.5,25,31.1,25.2
                    C183.8,441.7,159.3,318.1,157.6,318.1z"/>
                <path class="st0" d="M484.4,321.9c0,0,21.8-23.3,29.9,0c8.1,23.3,10.8,32.3-7.2,100c0,0-7.3,28.3-32.1,21.8
                    C469.6,430.9,479,369.8,484.4,321.9z"/>
                <path class="st0" d="M269.5,352c8.5,0,16.7,0.4,24.4,1c-3.3-7.8-14-13.5-26.6-13.5c-12.9,0-23.7,5.9-26.8,13.9
                    C249.5,352.5,259.3,352,269.5,352z"/>
                <path class="st0" d="M386.1,352c-8.5,0-16.7,0.4-24.4,1c3.3-7.8,14-13.5,26.6-13.5c12.9,0,23.7,5.9,26.8,13.9
                    C406.1,352.5,396.3,352,386.1,352z"/>
                <path class="st0" d="M147.2,394.2c0.8-5.9-0.5-11.9-0.5-17.9s1.6-12.6,6.7-15.8c0.8,6.2-0.1,12.4-0.2,18.6s0.8,12.8,4.6,17.7
                    c2.6,3.5,6.8,6.5,6.7,10.8c-0.1,4.1-4.1,7.6-3.3,11.6c0.2-3.3,2.1-6.4,4.9-8.2c1.3-0.8,2.9-1.4,3.6-2.8c0.4-0.8,0.4-1.8,0.4-2.7
                    c-0.1-19.7-0.2-39.3-0.3-59c0-3.9,0-7.8-1-11.5c-0.4-1.5-0.9-2.9-1.7-4.3c-2.2-3.7-6.7-6-11-5.6c-3.9,0.4-7.7,2.9-9.4,6.5
                    c-0.8,1.7-0.6,4-0.7,5.8c-0.2,2.6-0.4,5.2-0.5,7.8c-0.5,10.5-0.4,20.9,0.2,31.4C145.9,382.5,146.5,388.3,147.2,394.2z"/>
                <path class="st0" d="M505.8,394.1c-0.6-5.9,0.9-11.9,1.2-17.9s-1.2-12.6-6.1-16.1c-1,6.1-0.4,12.4-0.5,18.6
                    c-0.2,6.2-1.3,12.7-5.2,17.5c-2.8,3.4-7,6.2-7.1,10.6c-0.1,4.1,3.8,7.7,2.9,11.7c-0.1-3.3-1.8-6.5-4.6-8.4c-1.3-0.9-2.8-1.5-3.5-3
                    c-0.4-0.8-0.3-1.8-0.3-2.7c0.9-19.7,1.7-39.3,2.6-59c0.2-3.9,0.3-7.8,1.4-11.5c0.4-1.5,1-2.9,1.8-4.2c2.4-3.6,6.9-5.7,11.2-5.2
                    c3.8,0.5,7.6,3.2,9.1,6.8c0.7,1.7,0.4,4,0.5,5.8c0.1,2.6,0.2,5.2,0.2,7.8c0.1,10.5-0.4,20.9-1.4,31.4
                    C507.6,382.5,506.8,388.3,505.8,394.1z"/>
                <path class="st0" d="M501.7,345.7c3-2,5.1-5.3,5.6-8.9c-3.4,1.9-7.8-0.9-9.1-4.6c-1.2-3.7-0.3-7.7,0.5-11.5s1.5-7.9-0.3-11.4
                    c1.3,0.4,2.5,0.7,3.8,1.1c0.1-2.8,0.1-5.5,0.2-8.3c2.1,0.7,4.3,1.4,6.4,2.1c-0.6-21.4-6-42.6-15.8-61.7c5.8,2.6,13.4-2.5,13.1-8.8
                    c-2.4,2.8-7.4,1.4-9.3-1.7c-1.9-3.1-1.7-7.1-1.4-10.8c0.6-7.8,1.2-15.6,1.8-23.5c-5.2,4.3-12.3,6.3-18.9,5.3
                    c1.3-15.8-5.7-32-18.1-41.8c-3.5-2.8-7.7-5.1-12-6c-0.2-0.6-0.4-1.2-0.6-1.8c-1-3.2-2.2-6.4-4.5-8.8c-3-3-7.5-3.9-11.5-5.4
                    c-9.9-3.6-17.8-11.1-26.6-16.8c-18.3-11.9-40.5-16.2-62.1-19.6c-1.5-0.2-3.2-0.6-4.1-1.9c-0.6-1-0.6-2.2-0.6-3.4
                    c0.2-6.2,0.5-12.3,0.7-18.5c-4.2,8-14.1,11-23.1,12.4s-18.7,2.3-25.5,8.3c0.9-1.7,0.7-3.9-0.4-5.5c-6.2,12.3-17.2,22-30.1,26.7
                    c0.4-3.6-1.7-7.4-4.9-9c3,9.7-6.5,18.4-15.3,23.5c-7.5,4.3-16.2,9.7-18.5,17.4c-13.7,0.2-28.4,1.1-39.1,9.6
                    c-8.5,6.7-13,17.1-19,26.2c-4.2,6.4-9.3,12.5-11.8,19.7c-5,14.4,1,30.6-2.7,45.5c-1.9,7.6-6.2,14.5-8.1,22.1s-0.4,17.1,6.4,20.9
                    c0-1.8-0.1-3.6-0.1-5.4c5.6,2.3,5.8,10.9,1.6,15.2c-4.2,4.3-10.9,5.1-16.9,4.8c1,2.3,2,4.6,3,6.9c-2.9,0.2-5.7,0.4-8.6,0.6
                    c9.5,9.9,23.9,14.9,37.5,13c-0.3,6.8-5.5,13-12.2,14.5c2.3,0.8,3.4,3.6,3,6s-1.9,4.5-3.3,6.4c3.3,0.2,6.5,0.4,9.8,0.6
                    c-1.2,6.9-2.3,14.2-0.4,20.9c2,6.8,8,12.9,15,12.5c1.1-17.9,2.3-35.9,3.8-53.8c0.9-11.3,1.9-22.7,6.2-33.2
                    c3.3-8.1,8.6-15.4,11.7-23.6c4.1-10.9,4.2-23,8.1-34c3.4-9.6,9.6-18,13.6-27.4c3.5-8.1,5.2-16.9,8.2-25.1c1.2-3.3,2.7-6.6,4.2-9.8
                    c1.4-2.9,3-5.8,4.6-8.7c1.3-2.4,3.1-4.7,4.1-7.3c5.3,0.1,10.6-0.9,15.8-1.3c6.1-0.5,12.2-0.8,18.3-0.6c6.9,0.2,13.7,1.1,20.4,2.9
                    c7.4,2,15,5.3,22.4,3.3c1.4,7.3,8.8,11.8,15.9,14.2s14.8,4,20.1,9.3c5.2,5.3,5.5,16.1-1.4,18.8c6,0.4,11.9-4.6,12.4-10.6
                    c12.6-1.4,25.9-2.7,37.4,2.6s19.7,19.9,13.6,31c5.4-1.8,10.8-3.6,15.3-7.1c2.2-1.7,4.2-3.9,5.5-6.4c0.5,0.1,1,0.2,1.6,0.3
                    c2.9,0.6,5.4,2.6,7.4,4.9c2.4,2.8,4,6.2,4.8,9.7c1.7,7.4,0.7,15.2,1.3,22.8c2.2,27.5,25,49.4,30,76.5c0.9,5.2,1.2,10.4,1.3,15.7
                    c0.1,13.8-3,29.9-15.3,36.2c16.4-4.7,29.1-20.4,30-37.5c4.5,0.7,9.2,1.3,13.5,0.1c4.4-1.2,8.4-4.9,8.5-9.5
                    C513,346.2,507.3,346.3,501.7,345.7z"/>
                <path class="st0" d="M279.7,316.9c5.2,1.8,9.6,5.4,13.8,9.1c0.7,0.6,1.3,1.2,1.9,1.9c3.3,4,2.9,9.9,2.4,15.2
                    c-0.6,5.8-1.3,11.9-4.5,16.7c-3.2,4.8-8.3,7.5-12.2,11.6c6.9-1.4,13.3-5.4,17.9-11.1c3.7,4.9,5.4,11.3,5.9,17.5s-0.3,12.6-1,18.9
                    c5.9-7.2,9.8-16.2,11.2-25.6c0.6-4.4,0.7-8.8,0.8-13.3c0.1-6.2,0.2-12.4-0.8-18.5c-0.6-3.6-1.7-7.2-2.8-10.7
                    c-0.7-2.4-1.5-5-3.1-6.9c-2-2.5-5.1-3.7-8.1-4.8c-8.6-3.1-17.4-5.7-26.5-6.5c-4.4-0.4-8.8-0.4-13.1,0.2c-4.3,0.6-8.7,1.7-12.7,3.6
                    c-0.8,0.4-1.4,0.8-0.3,1.1c1.3,0.4,2.7,0.3,4,0.3c4.1,0.1,8.2-0.1,12.3-0.3c4.5-0.2,9-0.1,13.4,1.1
                    C278.6,316.6,279.2,316.7,279.7,316.9z"/>
                <path id="left-eyebrow" class="st0" d="M371.7,319.3c13.4,0.7,26.9,1,40.3,1c3.7,0,7.3,0,10.9,0.5c3.1,0.5,6,1.4,9,2.2
                    c5,1.5,10.1,3,15.1,4.5c-4.4-1.3-7.7-5-11.7-7.2c-3.9-2.1-8.5-2.7-12.8-3.7c-10-2.2-19.5-6.2-29.3-9c-7.8-2.3-16.5-4.2-24.7-3.3
                    c-2.3,0.3-4.5,1-6.1,2.7c-2,2.1-3.1,5.5-3.5,8.3c-0.3,2.4,3.6,2.8,5.3,3.2C366.7,319.1,369.2,319.2,371.7,319.3z"/>
                <path class="st0" d="M267.5,315.4c-21.8-0.8-43.1,10.6-64.8,8.1c6.3-1.2,12.5-2.8,18.6-4.7c11.3-3.5,22.3-8.1,33.7-11.3
                    c11.4-3.3,23.4-5.2,35.1-3.3c2.8,0.5,5.7,1.2,7.9,3c1.9,1.6,3.3,4.2,2.8,6.7c-0.4,2.1-2.1,3.9-4.3,3.9c-5.9-0.1-11.8-0.4-17.7-1.2
                    C275.1,316.1,271.3,315.6,267.5,315.4z"/>
                <path class="st0" d="M223.5,461.7c0.2,6.8,0.2,13.6,0.2,20.5c0.1-5.8-1.3-11.6-3.9-16.7c-0.6-1.1-1.2-2.2-2.1-2.9
                    c-0.5-0.4-1.2-0.7-1.6-1.3c-0.6-0.7-0.8-1.7-1-2.6c-0.8-3.9-1.5-7.9-2.3-11.8c-0.3-1.6-1-3.5-0.9-5.2c0-0.8,0.4-1.4,1.1-1.7
                    c1.7-0.7,4,0.4,5.3,1.3c2.4,1.7,3.3,4.6,4,7.4C223.3,452.9,223.4,457.3,223.5,461.7z"/>
                <path class="st0" d="M434.2,468.7c2.1-5.6,4.3-11.2,6.4-16.8c1.1-2.9,2.3-6.2,1.3-9.1c-0.7-2-2.5-3.9-4.7-4c-2.1-0.1-3.1,1-3.1,2.9
                    c0,5.6,0.3,11.3,0.3,16.9C434.3,461.9,434.3,465.3,434.2,468.7z"/>
                <path class="st0" d="M242.4,541c-3.4-9.2-1-20.1-5.4-28.9c5.1,16,13.1,31.2,23.5,44.5c7.1,9.1,15.4,17.6,19.7,28.3
                    c-8.2-8-21-11.2-27.4-20.7c-0.9-1.4-1.5-3.4-2.8-4.3c-1.3-0.8-1.8-1.3-3.7-3.3c-0.9-0.9-0.9-1.8-0.9-2.9c0-1.8,0.4-4.5-0.3-6.2
                    C244.3,545.3,243.2,543.2,242.4,541z"/>
                <path class="st0" d="M361.1,428.6c-11.3,6.3-23.3,12.4-36.3,13.2c-3.6,0.2-7.2,0-10.6-1.2c-2.6-0.9-4.9-2.4-7.2-3.8
                    c-2.3-1.4-4.6-2.9-6.9-4.3c-1.3-0.8-2.2-1.7-0.5-2.6c1.5-0.8,3.3-1,4.9-0.7c2.6,0.4,5.1,0.8,7.7,1.2c10.4,1.3,21,1.6,31.5,0.7
                    c3-0.2,5.9-0.6,8.8-1c1.4-0.2,2.9-0.4,4.3-0.7c0.7-0.1,1.5-0.3,2.2-0.4C359.7,428.9,360.6,428.9,361.1,428.6z"/>
                <path class="st0" d="M382.9,430.8c3.9,3.5,8.9,5.4,13.5,7.7c6.7,3.4,13.4,8.6,15,16c1.9,8.7-3.9,17.3-10.5,23.4
                    c6.9-5.5,12.1-13.5,12.9-22.3c0.7-8.8-3.6-18.2-11.5-22.1c-5.3-2.6-11.7-2.8-16.4-6.4c-5.5-4.2-6.8-12.9-2.8-18.6
                    c-0.4,0.6-1.6,1.1-2.2,1.6c-0.7,0.7-1.3,1.4-1.9,2.2c-0.8,1.2-1.7,2.6-1.8,4.1c-0.1,1.3,0.2,2.5,0.4,3.8c0.4,1.9,0.6,3.7,1.4,5.4
                    C380,427.6,381.3,429.4,382.9,430.8z"/>
                <path class="st0" d="M262.8,436.9c-2.5,1.2-5.1,2-7.6,3.2c-9.7,4.6-15.9,15.6-14.9,26.2c-0.2-6.4-0.4-12.9-0.6-19.3
                    c-0.1-2.7-0.1-5.7,1.4-8c2.2-3.5,6.9-4.2,10.9-5.1c5.2-1.2,10.4-3.4,14.4-7c4.1-3.7,6.5-8.6,6.9-14c1,1.9,1.5,4.1,2,6.2
                    c0.3,1.4,0.4,2.7,0,4c-1.1,3.8-3.8,7.3-6.6,9.9C266.9,434.5,264.9,435.8,262.8,436.9z"/>
                <path class="st0" d="M308.3,521.4c1.2-0.7,2.3-1.4,3.5-2.1c2-1.2,4-2.3,6.2-2.9c1.9-0.5,3.8-0.5,5.8-0.6c3.1,0,6.4-0.1,9.3,1.1
                    c1.6,0.7,5.1,2.6,3.7,4.9c-0.6,1.1-1.9,0.4-2.8,0.2c-1.6-0.4-3.2-0.7-4.8-1c-3.3-0.5-6.6-0.5-9.9-0.4c-1.8,0.1-3.7,0.2-5.5,0.3
                    c-0.9,0.1-1.9,0.1-2.8,0.2C310.3,521.2,308.9,521.1,308.3,521.4z"/>
                <path class="st0" d="M248.8,460.7c0,0,29.9,14.8,72.1,13s63.2-3,78.1-13c0,0,1.7,14-78.4,17.1C320.5,477.8,258.1,473.4,248.8,460.7
                    z"/>
                <path class="st0" d="M248.7,448.5"/>
                <path class="st0" d="M248.8,460.7c0,0,73.9,31.6,150.1,0c0,0-15.3,44-75.1,42.7S248.8,460.7,248.8,460.7z"/>
                <g>
                    <path class="st0" d="M248.4,460.7c0,0,0.3,0.9,1.1,2.4c2.7,4.1,7.2,7.4,10.9,10.2c11,8.1,24.2,13.3,37.6,15.6
                        c1.7,0.3,3.4,0.5,5.2,0.7c23.4,2.7,47-2.4,69.3-10c7.1-2.4,14.4-5.3,21-9c3.7-5.7,5.1-9.8,5.1-9.8
                        C322.3,492.4,248.4,460.7,248.4,460.7z"/>
                </g>
                <path class="st0" d="M288.3,265.7c10.4-3.1,19.9-10.7,23.3-21.1c4.4-13.8-3.1-29.2-14.4-38.2s-25.7-13.1-39.7-16.7
                    c-1.8-0.5-3.6-0.9-5.5-0.6c-2.9,0.5-5.1,2.9-6.8,5.2c-9.3,13-9.1,29.4-3.5,43.8c1.9,4.9,3.2,10.4,6,14.9c3.2,4.9,8.8,8.7,14,11.1
                    C270,268,279.7,268.2,288.3,265.7z"/>
                <path class="st0" d="M312.7,397.3c-1.9,2.7-3.2,5.8-4.4,8.8c-0.8,1.8-1.5,3.8-0.8,5.6c0.5,1.3,1.8,2.3,3,3
                    c4.2,2.6,8.8,4.4,13.6,5.4c4,0.8,9,0.6,11.3-2.8c0.6-1,1-2.1,1.3-3.3c0.9-3.4,1.8-6.8,2.6-10.3c0.5-2.1,0.9-4.2,0.4-6.3
                    c-0.4-1.7-1.5-3.1-2.5-4.5c-3.1-4.1-6.5-8.5-11.4-10.7c-0.8-0.4-2.2-1.1-3-0.6c-0.7,0.4-0.8,1.3-0.9,1.9c-0.5,2.5-1.5,4.3-3,6.4
                    C316.7,392.4,314.5,394.7,312.7,397.3z"/>
                <path class="st0" d="M231.4,364.9c-7.3,2.5-13.3,8.6-19.2,14.5c-3.7,3.7-7.5,7.7-9.2,13.1c-0.3,0.8-0.5,1.8-0.2,2.6
                    c0.3,0.8,1,1.3,1.6,1.7c7.4,4.5,16,4.5,24.3,4.3c9.9-0.2,20-0.4,29.2-4.8c4.5-2.1,8.9-5.6,10.8-11c0.8-2.4,1.1-5.1,1.2-7.8
                    c0.1-4.2-0.4-8.2-3.1-11.1c-1.6-1.8-3.4-3.2-5.7-3.3c-2.2-0.1-4.3,0.5-6.5,0.6c-3.6,0.1-7.2-0.4-10.8-0.4
                    C239.7,363.2,235.5,363.5,231.4,364.9z"/>
                <path class="st0" d="M434.9,365.8c-6.4-1.2-12.9-2-19.4-2.5c-6-0.5-12.2-0.6-18,0.9s-11.5,5-14.9,10.4c-0.6,1-1.1,2.1-1.1,3.3
                    c0.1,1.4,1,2.6,2,3.5c3,2.6,6.8,3.5,10.3,5c8.7,3.6,15.9,10.3,23.9,15.4s17.8,8.7,26.5,5.2c7.3-2.9,12.6-11,12.8-19.4
                    c0.2-8.5-4.7-16.8-11.8-20.2c-4.9-2.3-10.5-2.4-15.8-1.8"/>
                <path class="st0" d="M295.9,545.1c-3.5,2.4-6.7,6.5-5.4,10.5c1.3,4.2,6.6,5.8,11.1,5.5c4.9-0.3,9.8-2.1,13.8-5.1
                    c2.9,4.3,7.2,8,12.2,9.1c5.1,1.1,11-0.7,13.7-5.1c2.7-4.4,1.7-10.5-1.5-14.6s-8.2-6.4-13.3-7.5c-1.6-0.3-3.2-0.5-4.7,0.1
                    c-1.7,0.7-2.9,2.2-4.4,3.1c-1.6,1-2.2,0.3-3.8-0.1c-3.7-0.9-8.3,0-11.8,1.2C299.7,542.9,297.7,543.9,295.9,545.1z"/>
                <path class="st0" d="M404.6,542.6c3.4-9.7,1-21.1,5.4-30.4c-5.1,16.9-13.1,32.8-23.5,46.8c-7.1,9.6-15.4,18.5-19.7,29.8
                    c8.2-8.4,20.1-12.7,26.5-22.8c0.9-1.5,1.8-3.1,2.8-4.5c1.1-1.5,2.3-2.3,3.7-3.4c1-0.8,0.9-1.9,0.9-3.1c0-1.9,0.5-3.8,1.2-5.6
                    C402.7,547.1,403.8,544.9,404.6,542.6z"/>
                <path class="st0" d="M477.5,444.6V405c0,0-9.5,79.3-15.4,87.3C462.1,492.3,478.9,460.2,477.5,444.6z"/>
                <polygon class="st0" points="193.7,500.3 174.3,409.2 186.7,488.5 	"/>
                <g>
                    <path class="st0" d="M178.5,307.1c-3.6,2.4-7.1,4.9-10.5,7.6C172,313,175.6,310.4,178.5,307.1z"/>
                    <path class="st0" d="M225.4,168.6c-0.6,0-1.2,0-1.8,0c0,0.4,0.1,0.9,0.4,1.2C224.7,170.9,226.7,168.7,225.4,168.6z"/>
                    <path class="st0" d="M229.2,148.4c-2.2,0.1-4.3,0.3-6.5,0.4c-0.6,1-1,2-1.4,3.1c-4.7,0.1-9.5,0.2-14.2,0.7
                        c1.9,0.4,3.8,0.7,5.8,0.8c-14.7,7-29.1,14.8-43,23.3c-1.4,2.3-2.8,4.7-4.2,7c2.9,0.8,5.7,2.2,8.1,4.1c-6,2.8-11.8,6.1-17.3,9.8
                        c-1.4,2.2-2.7,4.4-3.8,6.7c1.6,0.4,3.2,0.7,4.9,1.1c-2.4,1.9-4.8,3.9-7.2,5.8c-0.6,2.8-0.9,5.7-1,8.6c3.7,0.5,7.4,0.9,11.1,1.4
                        c-2.8,5.9-6.4,11.4-10.5,16.4c0.1,1.8,0.1,3.6,0,5.4c1.3,0.2,2.7,0.5,4,0.7c0.5,7-3.2,12.9-8.4,17.9c-0.6,1.5-1.3,3.1-2,4.6
                        c4.3-1.6,8.7-2.9,13.2-3.9c-1.4,3.7-2.8,7.5-4.2,11.2c3.9-2.9,7.8-5.7,11.6-8.6c-7.7,7.9-15.4,15.8-23.2,23.7c0,0,0,0.1,0.1,0.1
                        c3.3-2.9,6.9-5.5,10.8-7.5c0.3,5.4,0.4,11-0.6,16.2c0.2,2.9-0.8,6.1-2.9,8.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.5-0.6,1-1,1.5
                        c-3.2,4.4-7.9,7.2-12.8,10c0,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.3,0-0.4,0c-1.8,1-3.7,2-5.5,3.1c2.1,1.8,4.3,3.4,6.7,4.8
                        c0.5-0.1,0.9-0.1,1.4-0.1v-0.1c0.1-0.8,1-1.2,1.8-1.3c4.5-0.8,8.9-1.4,13.5-1.8c-0.9,2.8-1.4,5.8-1.5,8.7c4.3,0.7,8.7,0.8,13,0.2
                        c-0.3,6.3-4.8,12.2-10.8,14.1c-0.1,0.3-0.2,0.6-0.3,1c1.6,1.2,2.3,3.5,1.9,5.5c-0.4,2.4-1.9,4.5-3.3,6.4c1.6,0.1,3.1,0.2,4.7,0.3
                        c3.1-3.5,6.9-6.5,11-8.7c3.9-5.9,8.2-11.5,12.9-16.8c-4.3,2.6-8.5,5.1-12.8,7.7c-1.5-5.7,1.2-12.2,6.2-15.3
                        c-5,1.4-10.3,1.8-15.5,1.2c3.7-1.4,7.3-2.8,11-4.3c-2.9-0.1-5.9-0.1-8.8-0.2c2.6-2.6,5.4-5,8.2-7.3c-0.4,0.2-0.9,0.4-1.3,0.6
                        c4.1-14.1,11.9-27.2,22.3-37.6c-8.4,7.2-17,14.2-25.8,21c1-5.6,4.1-10.8,8.6-14.4c-2.6-0.4-2.2-4.4-0.7-6.7
                        c4.4-7,10.7-12.9,18.1-16.7c-6.1,2.6-12.3,5.3-18.4,7.9c4.9-14.5,16.1-26.9,30-33.3c-4.4,0.1-8.7,0.3-13.1,0.4
                        c2.3-3.8,5.2-7.3,8.5-10.3c-1.5,1.2-3.9-1.1-3.4-3s2.4-3.1,4.1-4s3.7-2,4.2-3.9c-10,6.8-19.9,13.6-29.9,20.4
                        c-1.3-8.5,4-16.9,11.1-21.7c7.1-4.9,15.6-6.9,24-8.9c-2.6-0.6-0.5-4.7,2.1-5.5c-2.2-2.3-5.3-3.7-8.5-4c5.3-4.6,11.7-7.7,18.6-9.2
                        c-8.9-0.1-17.9,1.1-26.5,3.5c8-6.5,17.5-11,27.6-13c-2.8-2.9-6.7-4.5-10.7-4.6c4.6-2.3,9.6-3.4,14.7-3.4c0-0.9,0.3-1.8,0.5-2.7
                        c0.6-3.3-3.6-6.6-6.9-6.1c0-0.3,0-0.5,0-0.8C220.7,156.3,226.7,152.1,229.2,148.4z"/>
                    <path class="st0" d="M138.1,325.3c-0.4,0-0.8,0.1-1.1,0.1C137.1,326.1,138.6,325.8,138.1,325.3z"/>
                    <path class="st0" d="M172.9,347c-2.1,0.7-4.1,1.6-6.1,2.7c-0.5,0.8-1.1,1.7-1.6,2.5C168.3,351.3,171,349.5,172.9,347z"/>
                </g>
                <path class="st0" d="M503,275.4c-0.7-1.3-0.7-2.8-0.7-4.3c0-1.3,0-2.6,0-4c-0.4-1.2-0.8-2.4-1.2-3.5c-4.3-0.8-8.5-1.6-12.8-2.4
                    c1.5-0.4,3-0.8,4.5-1.2c-2.2-1.8-4.4-3.6-6.6-5.3c3-1.3,6.2-1.9,9.5-1.8c-4.9-2.4-10.4-3.8-15.8-4.1c1.7-0.7,3.4-1.4,5.1-2.1
                    c-2.1-1.2-3.7-3.1-4.6-5.4c1.5-0.3,3.1-0.6,4.6-1c-6.4-5.2-11.9-11.4-16.3-18.3c4.5,3,9.6,5,15,5.7c-0.7-1.2-1.5-2.2-2.5-3.2
                    c-4,0-8-1.9-10.3-5.1c3.9,0.5,7.6,2.3,10.3,5.1c1.1,0,2.2-0.1,3.3-0.5c2.9,4,7.1,7.1,11.8,8.8c-0.7-1.5-1-3.1-1.1-4.8
                    c-1-1.4-2.1-2.7-3.1-4.1c-2-2.9-2.6-7.9,0.8-9.3c0.9-0.4,2.1-0.5,3.2-0.7c0.1-1.4,0.2-2.7,0.3-4.1c-2.1-2-3.1-5.2-2.1-8
                    c-4.8,2.8-10.5,4-16,3.2c0.1-1.1,0.1-2.3,0.1-3.4c-1.5,0-2.9,0-4.4,0c1.5,0,2.9,0,4.4-0.2c0-2.1-0.2-4.3-0.5-6.4
                    c-2.3-1.7-4.3-4-5.6-6.5c1.3-0.4,2.6-0.9,3.8-1.3c-1-3.2-2.4-6.4-4.1-9.3c-0.3,0-0.6-0.1-0.9-0.1c0.3-0.1,0.5-0.2,0.8-0.2
                    c-0.8-1.3-1.6-2.6-2.5-3.9c-2.7-0.4-5.5-0.6-8.2-0.5c-16.9,2-34.3-0.5-49.9-7.2c15.3,2.2,31.1,1.3,46.1-2.5
                    c-7,0.1-14.1,0.2-21.1,0.3c4-1.7,8.3-2.7,12.7-2.9c-15.8,0.3-31.6,0-47.3-1.2c10.6-1,21.2-2,31.8-3.1c-7-0.3-13.9-0.7-20.9-1
                    c9.3-0.4,18.5-0.9,27.8-1.3c0.1-0.4,0.3-0.8,0.6-1.2c0.9,0.6,2,0.9,3,1c0.5,0,0.9,0,1.4-0.1c-0.5,0.1-0.9,0.1-1.4,0.1
                    c-1.2,0.1-2.4,0.1-3.6,0.2c-0.6,2.5,2.8,5.8,5.2,4.2s0.8-5.8-1.7-6.3c-0.5,0-1,0.1-1.5,0.1c0.5-0.2,1-0.2,1.5-0.1
                    c0.7,0,1.3-0.1,2-0.1c-2.7,0-5.5-0.2-8.2-0.8c2.4-0.5,4.8-0.9,7.2-1.4c0,0,0-0.1-0.1-0.1c-19.1,0.3-38.3-0.4-57.4-2
                    c-15.9-0.5-31.7-2.5-47.1-5.9c15.6,2.6,31.4,4.6,47.1,5.9c17,0.6,34-0.5,50.8-3.2c-2.1-0.9-4.4-1.5-6.6-2.3c-2-0.7-4-1.7-5.9-2.7
                    c-1.4-0.1-2.8-0.1-4.2,0c-2,0.2-3.9,0.3-5.9,0.5c2-0.3,3.9-0.4,5.9-0.5c1.2-0.1,2.4-0.2,3.6-0.3c-0.3-0.2-0.6-0.3-0.8-0.5
                    c-12.5-1.1-24.9-3.8-36.7-8c7.3-0.5,14.5-1.2,21.8-2.2c-1.2-0.8-2.4-1.7-3.6-2.5c-0.8,0-1.7-0.1-2.5-0.1
                    c-10.8-1.8-21.3-4.7-31.5-8.8c3.8-0.4,7.6-1.1,11.3-2c-12.9-4.4-26.7-6.9-40.2-9c-1.5-0.2-3.2-0.6-4.1-1.8c-0.6-1-0.6-2.2-0.6-3.3
                    c0.2-6.1,0.5-12.2,0.7-18.2c-4.2,7.9-14.1,10.9-23.1,12.3s-18.7,2.2-25.5,8.2c0.9-1.7,0.7-3.9-0.4-5.4
                    c-6.2,12.1-17.2,21.7-30.1,26.3c0.4-3.6-1.7-7.3-4.9-8.8c3,9.5-6.5,18.2-15.3,23.2c-7.2,4.1-15.4,9.1-18.2,16.3
                    c1.7,1.6,3.4,3.3,4.9,5.2c3.3-1.6,5.8-4.5,7-7.8c1.4,1.4,2.4,3.4,2.7,5.4c1-3.8,2.1-7.6,4.5-10.7c2.4-3.1,6.5-5.2,10.3-4.2
                    c-0.8,6.1-1.6,12.3-2.4,18.4c3.3-3.3,5.9-7.2,7.7-11.5c0,5.1-0.9,11.1,0.9,16.1c2-0.3,3.5-0.5,3.8-0.6c6-1.2,11.9-3.1,17.5-5.7
                    c-1.7,1.4-3.4,2.9-5.1,4.3c18,5.1,37.6,4.2,55.1-2.5c-5.5,2.5-10.9,5.1-16.4,7.6c17.7,2.1,35.9,0.9,53.2-3.5
                    c-12.3,5.6-25.8,8.7-39.4,8.8c6.3,1.8,12.2,5,17.1,9.3c-4.3,0.4-8.7,0.7-13,1.1c1.8,4.2,5.3,6.9,9.6,8.6c1,0.3,1.9,0.6,2.9,1
                    c4.9,1.4,10.4,1.8,15.5,1.9c7.1,0.1,14.3,0.2,21.3-0.5c6-1.9,12.1-3.3,18.3-4.3c-5.8,2.4-12,3.7-18.3,4.3
                    c-3.9,1.3-7.8,2.7-11.6,4.4c9.6,1,19.2,1.9,28.8,2.9c1.8,0.2,3.6,0.4,5.3,1.2c3,1.5,4.6,4.6,7.2,6.8c3.2,2.7,7.9,3.5,11.8,2
                    c0,2.2,0,4.4-0.1,6.6c-2.4-0.7-4.7-1.4-7.1-2.1c4.1,3.7,6.5,9.1,6.3,14.5c3.7-5,7.3-9.9,11-14.9c-1.3-12.2-14-19.5-25.1-24.8
                    c12.6-0.4,25.2,5.5,33,15.3c-0.1-6.1-0.3-12.2-0.4-18.2c2.9-1.6,6.7,0,8.6,2.8c1.8,2.7,2.2,6.1,2.5,9.4c0.3,3.5,0.6,7,0.9,10.6
                    c-1.2,7-2.4,14-3.6,21c2.2-0.3,4.3-1.3,5.9-2.8c0.1-0.2,0.3-0.3,0.5-0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.8,1.2,0.5,3.2,2.1,3.4
                    c1.4-1.4,3.1-2.5,5-3c-1.3,1.5-2.8,3.1-4.8,3.1c-0.1,0-0.2,0-0.2,0c-1.3,1.3-2.3,2.9-2.8,4.7c4,1.8,8.4,2.8,12.8,3
                    c1.5-0.2,3.1-0.3,4.6-0.1c-1.5,0.1-3,0.1-4.6,0.1c-2.4,0.4-4.8,1.2-6.8,2.6c5.5,1.3,10.6,4.1,14.7,7.9c-2.3,0.8-4.7,1.6-7,2.4
                    c2.2,0.9,4.2,2.5,5.7,4.3c-2.8,1.1-5.5,2.1-8.3,3.2c5.8,8.3,14.7,14.4,24.6,16.9c-9.2,0-18.3,0.1-27.5,0.1
                    c11.4,8,25.1,12.9,39,13.9c-8.5,3.2-18.2,3.2-26.7,0.1c1.6,4.3,5.6,7.8,10.2,8.7c-1.5,0.8-3.1,1.6-4.6,2.4c1.8-1.1,1.9,3,0,3.8
                    c7,2,14.2,3.5,21.4,4.5c-0.2-0.7-0.4-1.3-0.7-2c1.3,0.4,2.5,0.7,3.8,1.1c0.1-2.7,0.1-5.4,0.2-8.2c2.1,0.7,4.3,1.4,6.4,2.1
                    c-0.3-9.3-1.5-18.5-3.6-27.6C504.3,276.9,503.5,276.3,503,275.4z"/>
                <path class="st0" d="M334.4,117c-4,3.5-8.3,6.7-12.9,9.6c8.7,1.5,17.9,0.5,26-2.9c-4.1,1.7-7.9,4-11.3,6.8c3,0.3,5.9,0.6,8.9,0.9
                    c-0.7,0.7-1.5,1.4-2.2,2.1c21.3,1.8,43.3,3.5,63.4-3.7c-13.8,4.9-27.7,9.8-41.5,14.6c4.4,0.4,8.9,0.3,13.3-0.4
                    c-2.2,0.4-4.4,0.8-6.5,1.1c6.4,4.5,13.9,7.4,21.7,8.3c-3.2,0-6.3,0.6-9.3,1.6c16,3.7,32.7,4,48.8,1c-11.3,2.3-22.3,5.8-32.8,10.5
                    c4.3,1.5,8.5,3,12.8,4.4c-1.6,0.6-3.1,1.1-4.7,1.7c6.6,1.7,13.2,3.4,19.9,5.1c-1,0.8-2,1.5-3.1,2.3c3.4,1.4,6.8,2.9,10.2,4.3
                    c-16.5-4.8-33.5-7.7-50.7-8.8c3.7-1.5,7.8-2.4,11.8-2.5c-4.1-1.3-8.2-2.5-12.4-3.8c1.9-0.4,3.8-0.8,5.7-1.2
                    c-10.5-0.3-21-0.7-31.5-1c7.7-2.4,15.5-4.9,23.2-7.3c-1.6-0.2-3.3-0.4-4.9-0.6c-4.2-1.6-8.7-2.2-13.1-1.7c1.4-1.3,2.8-2.5,4.2-3.8
                    c-1.8-0.1-3.6-0.2-5.4-0.2c0.5,0.3,1-0.6,0.7-1.1s-1-0.6-1.6-0.7c-19.4-1.8-39.2,0.1-57.9,5.7c15.5-8.7,32.2-15.2,48.7-21.8
                    c-8.6,1.3-17.2,2.6-25.8,3.9c0.9-0.9,1.8-1.9,2.7-2.8c-3.2-0.1-6.5-0.1-9.7-0.2c0.1-0.8,0.8-1.4,1.5-1.4c-1.8,0.5-3.7,1-5.5,1.5
                    c0.9-1.5,1.2-3.2,1-4.9c-20.2,2.9-40.5,4.9-60.9,6.2c1.5-0.1,3.1-0.8,4.6-1.2c1.6-0.4,3.2-0.8,4.8-1.2c3.1-0.8,6.3-1.5,9.4-2.3
                    c6.4-1.5,12.7-3,19.1-4.3c3.6-0.8,7.3-1.4,10.9-2.3c-2.2-0.1-0.4,3.3,0.9,3.7c1.7,0.6,3.9-0.4,5.5-1c5.4-2,10.7-4.4,15.8-7.1
                    C328.8,120.3,331.6,118.7,334.4,117z"/>
                </g>
            </svg>
        </div>
        <div class="loading-right">
            <h1>Welcome</h1>
            <h2>{{ text }}</h2>

            <JHMonogram class="loading-mono" />
        </div>
    </div>
</template>

<script>
import JHMonogram from './JHMonogram.vue'

export default {
  name: 'Loading',
  components: {
      JHMonogram
  },
  data() {
      return {
          text: 'Loading images, fonts, pixels, codez, and other goodies...',
          fullyLoaded: false,
      }
  },
  mounted() {
      const storKey = 'dti-visited';
      setTimeout(() => {
          sessionStorage.removeItem(storKey);
          sessionStorage.setItem(storKey, true);
          this.fullyLoaded = true
      }, 4000);
  }
}
</script>

<style lang="scss">
@import '../styles/palette';
@import '../styles/typog';

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes blink {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 0.9;
    }
}

.loading {
    padding: 1rem;
    display: flex;
    justify-content: center;
    transition: all 0.5s;
    height: 100vh;
    color: $colora1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2335b4ab' fill-opacity='0.89' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    border-top: 10px solid $colora6;

    @media (max-width: 840px) {
      display: block;
    }

    h1 {
        font-family: $fancyFont;
    }

    h1, h2 {
        margin-bottom: 0.25rem;
        padding-bottom: 0;
        padding-top: 0;
        margin-top: 0;
    }

    .st0{
        fill:none;stroke:$colora2;stroke-width:4;stroke-miterlimit:10;
    }

    svg path {
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
        animation: dash 6s linear alternate;
        animation-iteration-count: infinite;
    }

    .loading-img {
        width: 300px;
    }

    .loading-right {
        padding-top: 4rem;
        max-width: 500px;

        @media (max-width: 840px) {
            padding-top: 0rem;
            padding-left: 1rem;

            h1, h2 {
                margin: 0;
                padding: 0;
            }
        }

        h2 {
            animation: blink 1s linear alternate;
            animation-iteration-count: infinite;
        }
    }

    .loading-mono {
        width: 40px;
        margin-top: 0.5rem;

        * {
            fill: $colora2;
        }
    }

    &.rollup {
        height: 0vh;
        padding: 0;
        border: none;

        * {
            display: none;
        }
    }
}
</style>
