<template>
   <div class="me-music-wrapper">
      <svg version="1.1" class="me-music" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 974.5 953.3" style="enable-background:new 0 0 974.5 953.3;" xml:space="preserve">
         <path class="st0" d="M475.8,546.6c0,0-154.6-13-304.7,97.9c0,0-99.7,57.8-123.9,152.5S19.6,960.1,19.6,960.1h939
            c0,0-32.5-310.7-204.1-332.6C754.4,627.5,617.5,525.6,475.8,546.6z"/>
         <path class="st1" d="M126.3,932.3c-10-0.6-20.1-1.2-30.1-1.8c8.5,4.4,17,8.8,25.6,13.2c-1.3-0.7-14.3,8.3-16.2,9.4
            c-2.8,1.5-6,3.1-5.9,5.9h102.8c9.1-1.1,18.2-2.3,27.3-3.5c0.1-4.7-3.8-9.1-8.5-9.6c2.2-4.7,5.6-8.9,9.8-12
            c-5.2-0.2-10.4-0.4-15.5-0.5c-0.4-11.6,2.4-23.2,8.1-33.3c-12.9,11-28.7,18.5-45.4,21.4c-0.6-10,2.8-20.2,9.4-27.9
            c-4-1.2-8.8,1.5-9.8,5.6c4-45.6,17.1-91.8,46.6-126.8c-24.7,27.4-49.4,54.8-74.1,82.1c-5.7,6.3-11.8,13.8-10.9,22.2
            c-2.3-14.7-3.2-29.7-2.8-44.6c-5,10.4-8,21.7-8.6,33.2c-9.1-8.7-17-18.6-23.3-29.5c1.8,19.5,3.6,39,5.4,58.5
            c-4.3-2.4-9-3.9-13.9-4.4C110,900.9,120.6,915.7,126.3,932.3z"/>
         <g>
            <path class="st1" d="M910.1,782.9c3.1,15.5,0.6,32.1-7.3,45.8c13.9,18,16.5,44.1,6.3,64.5c-1.4,2.7-3,5.4-5.5,7.2
               c-7,5.1-16.7,0.7-24.1-3.6c-4.7,6.8-9.4,13.6-14.1,20.5c-10.3-4.4-18-14.3-19.7-25.3c-6.5,5.7-16.2,7.4-24.2,4.3
               c0.4-19.3,0.8-38.7,1.2-58c-8,13.3-15,27.2-20.9,41.5c-0.5-10-0.9-20.1-1.4-30.1c-3.4,3.7-6.9,7.5-10.3,11.2
               c-10-16-15-35.1-14.3-53.9c-0.3,3.4-0.6,6.9-0.7,10.4c-0.7,13.9-0.5,27.9,0.7,41.7c1.2,13.9,5,27.3,6.5,41
               c-5.7-2.4-11-6.4-16.8-8.5c-1.9,12.4,0.4,25.9,1.1,38.3c0.5,8.3,0.5,16.7,1.1,25c0.1,1.5,0.2,2.9,0.4,4.4l190.8,0.9
               C949.5,884,931.6,826.5,910.1,782.9z"/>
            <path class="st1" d="M775.7,806.9c0.4-4.5,0.9-9,1.5-13.5C776.4,797.8,775.9,802.4,775.7,806.9z"/>
         </g>
         <path class="st2" d="M350.7,444.3c0,0,5.2,150.8,57.4,250s64.5,119,64.5,119l81-119.9c0,0,45.9-164.2,47.3-249H350.7z"/>
         <path class="st3" d="M430.5,471.2c0,0,0.2,6.4,1.1,17.2c13.9-1,28.1,0.8,41.3,4.7c7.5,2.2,14.9,5.1,21.3,9.2
            c16.6,10.7-48.3,26.9-43.1,44.6c2,7,1.2,13.1-0.1,20.1c-1.8,10.4-1.2,21.2-0.7,31.7c0.7,13.3,2.1,26.6,4.3,39.7
            c-17.6-14.9-35.6-30.4-45.1-50.6c-5-10.7-7.3-22.2-9.6-33.6c-11.8,15.8-13.5,36.6-9.4,55.4c4.1,18.8,13.6,36.2,23,53.3
            c10.1,18.4,20.2,36.9,30.3,55.3c11,20.1,22.3,41,23.2,63.5c0,1,0.1,2.1,0,3.2c1.8,2.8,2.5,3.9,2.5,3.9l75.7-104.1
            c0,0,42.9-142.6,44.2-216.2L430.5,471.2z"/>
         <g id="HEAD">
            <path class="st2" d="M311.9,290.2c-1.7,0-26.4-23.4-21,21.7s6.8,55.5,10.1,76.5c3.4,20.9,26.1,25.4,30.8,25.6
               C336.5,414.2,313.7,290.2,311.9,290.2z"/>
            <path class="st2" d="M637.4,285c0,0,21.8-24.8,29.9,0c8.1,24.8,10.8,34.4-7.2,106.4c0,0-7.3,30.1-32.1,23.2
               C622.6,401,632,336,637.4,285z"/>
            <path class="st2" d="M327.3,381.3c0,0,7.2,75.8,18,85.7c10.8,9.9,71.3,85.7,89.3,88.4c18,2.7,41,0,41,0s36.5,12.6,67.2-12.6
               c30.7-25.3,92.9-83,85.7-141.6l6.3-64l2.7-58.6c0,0,20.7-172.3-165.1-169.6S311,274.8,311,274.8S311,366.8,327.3,381.3z"/>
            <path class="st3" d="M325.9,375.8c0,0,7.2,75.8,18,85.7c10.8,9.9,71.3,85.7,89.3,88.4c18,2.7,41,0,41,0s36.5,12.6,67.2-12.6
               c30.7-25.3,92.9-83,85.7-141.6l6.3-64l2.7-58.6c0,0,20.7-172.3-165.1-169.6s-161.5,166-161.5,166S309.7,361.4,325.9,375.8z"/>
            <path class="st3" d="M309.2,284.7c-1.7,0-26.7-23.1-20.7,21.9c6,45,7.5,55.4,11.1,76.3s26.5,25,31.1,25.2
               C335.4,408.3,310.9,284.7,309.2,284.7z"/>
            <path class="st3" d="M636,288.5c0,0,21.8-23.3,29.9,0c8.1,23.3,10.8,32.3-7.2,100c0,0-7.3,28.3-32.1,21.8
               C621.2,397.5,630.6,336.4,636,288.5z"/>
            <path class="st4" d="M421.1,318.6c8.5,0,16.7,0.4,24.4,1c-3.3-7.8-14-13.5-26.6-13.5c-12.9,0-23.7,5.9-26.8,13.9
               C401.1,319.1,410.9,318.6,421.1,318.6z"/>
            <path class="st4" d="M537.7,318.6c-8.5,0-16.7,0.4-24.4,1c3.3-7.8,14-13.5,26.6-13.5c12.9,0,23.7,5.9,26.8,13.9
               C557.7,319.1,547.9,318.6,537.7,318.6z"/>
            <path class="st2" d="M298.8,360.8c0.8-5.9-0.5-11.9-0.5-17.9s1.6-12.6,6.7-15.8c0.8,6.2-0.1,12.4-0.2,18.6
               c-0.1,6.2,0.8,12.8,4.6,17.7c2.6,3.5,6.8,6.5,6.7,10.8c-0.1,4.1-4.1,7.6-3.3,11.6c0.2-3.3,2.1-6.4,4.9-8.2c1.3-0.8,2.9-1.4,3.6-2.8
               c0.4-0.8,0.4-1.8,0.4-2.7c-0.1-19.7-0.2-39.3-0.3-59c0-3.9,0-7.8-1-11.5c-0.4-1.5-0.9-2.9-1.7-4.3c-2.2-3.7-6.7-6-11-5.6
               c-3.9,0.4-7.7,2.9-9.4,6.5c-0.8,1.7-0.6,4-0.7,5.8c-0.2,2.6-0.4,5.2-0.5,7.8c-0.5,10.5-0.4,20.9,0.2,31.4
               C297.5,349.1,298.1,354.9,298.8,360.8z"/>
            <path class="st2" d="M657.4,360.7c-0.6-5.9,0.9-11.9,1.2-17.9c0.3-6-1.2-12.6-6.1-16.1c-1,6.1-0.4,12.4-0.5,18.6
               c-0.2,6.2-1.3,12.7-5.2,17.5c-2.8,3.4-7,6.2-7.1,10.6c-0.1,4.1,3.8,7.7,2.9,11.7c-0.1-3.3-1.8-6.5-4.6-8.4c-1.3-0.9-2.8-1.5-3.5-3
               c-0.4-0.8-0.3-1.8-0.3-2.7c0.9-19.7,1.7-39.3,2.6-59c0.2-3.9,0.3-7.8,1.4-11.5c0.4-1.5,1-2.9,1.8-4.2c2.4-3.6,6.9-5.7,11.2-5.2
               c3.8,0.5,7.6,3.2,9.1,6.8c0.7,1.7,0.4,4,0.5,5.8c0.1,2.6,0.2,5.2,0.2,7.8c0.1,10.5-0.4,20.9-1.4,31.4
               C659.2,349.1,658.4,354.9,657.4,360.7z"/>
            <path class="st4" d="M653.3,312.3c3-2,5.1-5.3,5.6-8.9c-3.4,1.9-7.8-0.9-9.1-4.6c-1.2-3.7-0.3-7.7,0.5-11.5
               c0.8-3.8,1.5-7.9-0.3-11.4c1.3,0.4,2.5,0.7,3.8,1.1c0.1-2.8,0.1-5.5,0.2-8.3c2.1,0.7,4.3,1.4,6.4,2.1c-0.6-21.4-6-42.6-15.8-61.7
               c5.8,2.6,13.4-2.5,13.1-8.8c-2.4,2.8-7.4,1.4-9.3-1.7c-1.9-3.1-1.7-7.1-1.4-10.8c0.6-7.8,1.2-15.6,1.8-23.5
               c-5.2,4.3-12.3,6.3-18.9,5.3c1.3-15.8-5.7-32-18.1-41.8c-3.5-2.8-7.7-5.1-12-6c-0.2-0.6-0.4-1.2-0.6-1.8c-1-3.2-2.2-6.4-4.5-8.8
               c-3-3-7.5-3.9-11.5-5.4c-9.9-3.6-17.8-11.1-26.6-16.8c-18.3-11.9-40.5-16.2-62.1-19.6c-1.5-0.2-3.2-0.6-4.1-1.9
               c-0.6-1-0.6-2.2-0.6-3.4c0.2-6.2,0.5-12.3,0.7-18.5c-4.2,8-14.1,11-23.1,12.4c-9,1.4-18.7,2.3-25.5,8.3c0.9-1.7,0.7-3.9-0.4-5.5
               c-6.2,12.3-17.2,22-30.1,26.7c0.4-3.6-1.7-7.4-4.9-9c3,9.7-6.5,18.4-15.3,23.5c-7.5,4.3-16.2,9.7-18.5,17.4
               c-13.7,0.2-28.4,1.1-39.1,9.6c-8.5,6.7-13,17.1-19,26.2c-4.2,6.4-9.3,12.5-11.8,19.7c-5,14.4,1,30.6-2.7,45.5
               c-1.9,7.6-6.2,14.5-8.1,22.1c-1.9,7.6-0.4,17.1,6.4,20.9c0-1.8-0.1-3.6-0.1-5.4c5.6,2.3,5.8,10.9,1.6,15.2
               c-4.2,4.3-10.9,5.1-16.9,4.8c1,2.3,2,4.6,3,6.9c-2.9,0.2-5.7,0.4-8.6,0.6c9.5,9.9,23.9,14.9,37.5,13c-0.3,6.8-5.5,13-12.2,14.5
               c2.3,0.8,3.4,3.6,3,6c-0.4,2.4-1.9,4.5-3.3,6.4c3.3,0.2,6.5,0.4,9.8,0.6c-1.2,6.9-2.3,14.2-0.4,20.9c2,6.8,8,12.9,15,12.5
               c1.1-17.9,2.3-35.9,3.8-53.8c0.9-11.3,1.9-22.7,6.2-33.2c3.3-8.1,8.6-15.4,11.7-23.6c4.1-10.9,4.2-23,8.1-34
               c3.4-9.6,9.6-18,13.6-27.4c3.5-8.1,5.2-16.9,8.2-25.1c1.2-3.3,2.7-6.6,4.2-9.8c1.4-2.9,3-5.8,4.6-8.7c1.3-2.4,3.1-4.7,4.1-7.3
               c5.3,0.1,10.6-0.9,15.8-1.3c6.1-0.5,12.2-0.8,18.3-0.6c6.9,0.2,13.7,1.1,20.4,2.9c7.4,2,15,5.3,22.4,3.3
               c1.4,7.3,8.8,11.8,15.9,14.2c7.1,2.4,14.8,4,20.1,9.3c5.2,5.3,5.5,16.1-1.4,18.8c6,0.4,11.9-4.6,12.4-10.6
               c12.6-1.4,25.9-2.7,37.4,2.6c11.5,5.3,19.7,19.9,13.6,31c5.4-1.8,10.8-3.6,15.3-7.1c2.2-1.7,4.2-3.9,5.5-6.4c0.5,0.1,1,0.2,1.6,0.3
               c2.9,0.6,5.4,2.6,7.4,4.9c2.4,2.8,4,6.2,4.8,9.7c1.7,7.4,0.7,15.2,1.3,22.8c2.2,27.5,25,49.4,30,76.5c0.9,5.2,1.2,10.4,1.3,15.7
               c0.1,13.8-3,29.9-15.3,36.2c16.4-4.7,29.1-20.4,30-37.5c4.5,0.7,9.2,1.3,13.5,0.1c4.4-1.2,8.4-4.9,8.5-9.5
               C664.6,312.8,658.9,312.9,653.3,312.3z"/>
            <path class="st2" d="M431.3,283.5c5.2,1.8,9.6,5.4,13.8,9.1c0.7,0.6,1.3,1.2,1.9,1.9c3.3,4,2.9,9.9,2.4,15.2
               c-0.6,5.8-1.3,11.9-4.5,16.7c-3.2,4.8-8.3,7.5-12.2,11.6c6.9-1.4,13.3-5.4,17.9-11.1c3.7,4.9,5.4,11.3,5.9,17.5s-0.3,12.6-1,18.9
               c5.9-7.2,9.8-16.2,11.2-25.6c0.6-4.4,0.7-8.8,0.8-13.3c0.1-6.2,0.2-12.4-0.8-18.5c-0.6-3.6-1.7-7.2-2.8-10.7
               c-0.7-2.4-1.5-5-3.1-6.9c-2-2.5-5.1-3.7-8.1-4.8c-8.6-3.1-17.4-5.7-26.5-6.5c-4.4-0.4-8.8-0.4-13.1,0.2c-4.3,0.6-8.7,1.7-12.7,3.6
               c-0.8,0.4-1.4,0.8-0.3,1.1c1.3,0.4,2.7,0.3,4,0.3c4.1,0.1,8.2-0.1,12.3-0.3c4.5-0.2,9-0.1,13.4,1.1
               C430.2,283.2,430.8,283.3,431.3,283.5z"/>
            <path class="st5 left-eyebrow" d="M523.3,285.9c13.4,0.7,26.9,1,40.3,1c3.7,0,7.3,0,10.9,0.5c3.1,0.5,6,1.4,9,2.2c5,1.5,10.1,3,15.1,4.5
               c-4.4-1.3-7.7-5-11.7-7.2c-3.9-2.1-8.5-2.7-12.8-3.7c-10-2.2-19.5-6.2-29.3-9c-7.8-2.3-16.5-4.2-24.7-3.3c-2.3,0.3-4.5,1-6.1,2.7
               c-2,2.1-3.1,5.5-3.5,8.3c-0.3,2.4,3.6,2.8,5.3,3.2C518.3,285.7,520.8,285.8,523.3,285.9z"/>
            <path class="st5" d="M419.1,282c-21.8-0.8-43.1,10.6-64.8,8.1c6.3-1.2,12.5-2.8,18.6-4.7c11.3-3.5,22.3-8.1,33.7-11.3
               c11.4-3.3,23.4-5.2,35.1-3.3c2.8,0.5,5.7,1.2,7.9,3c1.9,1.6,3.3,4.2,2.8,6.7c-0.4,2.1-2.1,3.9-4.3,3.9c-5.9-0.1-11.8-0.4-17.7-1.2
               C426.7,282.7,422.9,282.2,419.1,282z"/>
            <path class="st2" d="M375.1,428.3c0.2,6.8,0.2,13.6,0.2,20.5c0.1-5.8-1.3-11.6-3.9-16.7c-0.6-1.1-1.2-2.2-2.1-2.9
               c-0.5-0.4-1.2-0.7-1.6-1.3c-0.6-0.7-0.8-1.7-1-2.6c-0.8-3.9-1.5-7.9-2.3-11.8c-0.3-1.6-1-3.5-0.9-5.2c0-0.8,0.4-1.4,1.1-1.7
               c1.7-0.7,4,0.4,5.3,1.3c2.4,1.7,3.3,4.6,4,7.4C374.9,419.5,375,423.9,375.1,428.3z"/>
            <path class="st2" d="M585.8,435.3c2.1-5.6,4.3-11.2,6.4-16.8c1.1-2.9,2.3-6.2,1.3-9.1c-0.7-2-2.5-3.9-4.7-4c-2.1-0.1-3.1,1-3.1,2.9
               c0,5.6,0.3,11.3,0.3,16.9C585.9,428.5,585.9,431.9,585.8,435.3z"/>
            <path class="st2" d="M394,507.6c-3.4-9.2-1-20.1-5.4-28.9c5.1,16,13.1,31.2,23.5,44.5c7.1,9.1,15.4,17.6,19.7,28.3
               c-8.2-8-21-11.2-27.4-20.7c-0.9-1.4-1.5-3.4-2.8-4.3c-1.3-0.8-1.8-1.3-3.7-3.3c-0.9-0.9-0.9-1.8-0.9-2.9c0-1.8,0.4-4.5-0.3-6.2
               C395.9,511.9,394.8,509.8,394,507.6z"/>
            <path class="st2" d="M512.7,395.2c-11.3,6.3-23.3,12.4-36.3,13.2c-3.6,0.2-7.2,0-10.6-1.2c-2.6-0.9-4.9-2.4-7.2-3.8
               c-2.3-1.4-4.6-2.9-6.9-4.3c-1.3-0.8-2.2-1.7-0.5-2.6c1.5-0.8,3.3-1,4.9-0.7c2.6,0.4,5.1,0.8,7.7,1.2c10.4,1.3,21,1.6,31.5,0.7
               c3-0.2,5.9-0.6,8.8-1c1.4-0.2,2.9-0.4,4.3-0.7c0.7-0.1,1.5-0.3,2.2-0.4C511.3,395.5,512.2,395.5,512.7,395.2z"/>
            <path class="st2" d="M534.5,397.4c3.9,3.5,8.9,5.4,13.5,7.7c6.7,3.4,13.4,8.6,15,16c1.9,8.7-3.9,17.3-10.5,23.4
               c6.9-5.5,12.1-13.5,12.9-22.3c0.7-8.8-3.6-18.2-11.5-22.1c-5.3-2.6-11.7-2.8-16.4-6.4c-5.5-4.2-6.8-12.9-2.8-18.6
               c-0.4,0.6-1.6,1.1-2.2,1.6c-0.7,0.7-1.3,1.4-1.9,2.2c-0.8,1.2-1.7,2.6-1.8,4.1c-0.1,1.3,0.2,2.5,0.4,3.8c0.4,1.9,0.6,3.7,1.4,5.4
               C531.6,394.2,532.9,396,534.5,397.4z"/>
            <path class="st2" d="M414.4,403.5c-2.5,1.2-5.1,2-7.6,3.2c-9.7,4.6-15.9,15.6-14.9,26.2c-0.2-6.4-0.4-12.9-0.6-19.3
               c-0.1-2.7-0.1-5.7,1.4-8c2.2-3.5,6.9-4.2,10.9-5.1c5.2-1.2,10.4-3.4,14.4-7c4.1-3.7,6.5-8.6,6.9-14c1,1.9,1.5,4.1,2,6.2
               c0.3,1.4,0.4,2.7,0,4c-1.1,3.8-3.8,7.3-6.6,9.9C418.5,401.1,416.5,402.4,414.4,403.5z"/>
            <path class="st2" d="M459.9,488c1.2-0.7,2.3-1.4,3.5-2.1c2-1.2,4-2.3,6.2-2.9c1.9-0.5,3.8-0.5,5.8-0.6c3.1,0,6.4-0.1,9.3,1.1
               c1.6,0.7,5.1,2.6,3.7,4.9c-0.6,1.1-1.9,0.4-2.8,0.2c-1.6-0.4-3.2-0.7-4.8-1c-3.3-0.5-6.6-0.5-9.9-0.4c-1.8,0.1-3.7,0.2-5.5,0.3
               c-0.9,0.1-1.9,0.1-2.8,0.2C461.9,487.8,460.5,487.7,459.9,488z"/>
            <path class="st2" d="M400.4,427.3c0,0,29.9,14.8,72.1,13c42.2-1.8,63.2-3,78.1-13c0,0,1.7,14-78.4,17.1
               C472.1,444.4,409.7,440,400.4,427.3z"/>
            <path class="st6" d="M400.3,415.1"/>
            <path class="st4" d="M400.4,427.3c0,0,73.9,31.6,150.1,0c0,0-15.3,44-75.1,42.7S400.4,427.3,400.4,427.3z"/>
            <g>
               <path class="st7" d="M400,427.3c0,0,0.3,0.9,1.1,2.4c2.7,4.1,7.2,7.4,10.9,10.2c11,8.1,24.2,13.3,37.6,15.6
                  c1.7,0.3,3.4,0.5,5.2,0.7c23.4,2.7,47-2.4,69.3-10c7.1-2.4,14.4-5.3,21-9c3.7-5.7,5.1-9.8,5.1-9.8C473.9,459,400,427.3,400,427.3z
                  "/>
            </g>
            <path class="st8" d="M439.9,232.3c10.4-3.1,19.9-10.7,23.3-21.1c4.4-13.8-3.1-29.2-14.4-38.2c-11.3-9-25.7-13.1-39.7-16.7
               c-1.8-0.5-3.6-0.9-5.5-0.6c-2.9,0.5-5.1,2.9-6.8,5.2c-9.3,13-9.1,29.4-3.5,43.8c1.9,4.9,3.2,10.4,6,14.9c3.2,4.9,8.8,8.7,14,11.1
               C421.6,234.6,431.3,234.8,439.9,232.3z"/>
            <path class="st8" d="M464.3,363.9c-1.9,2.7-3.2,5.8-4.4,8.8c-0.8,1.8-1.5,3.8-0.8,5.6c0.5,1.3,1.8,2.3,3,3
               c4.2,2.6,8.8,4.4,13.6,5.4c4,0.8,9,0.6,11.3-2.8c0.6-1,1-2.1,1.3-3.3c0.9-3.4,1.8-6.8,2.6-10.3c0.5-2.1,0.9-4.2,0.4-6.3
               c-0.4-1.7-1.5-3.1-2.5-4.5c-3.1-4.1-6.5-8.5-11.4-10.7c-0.8-0.4-2.2-1.1-3-0.6c-0.7,0.4-0.8,1.3-0.9,1.9c-0.5,2.5-1.5,4.3-3,6.4
               C468.3,359,466.1,361.3,464.3,363.9z"/>
            <path class="st8" d="M383,331.5c-7.3,2.5-13.3,8.6-19.2,14.5c-3.7,3.7-7.5,7.7-9.2,13.1c-0.3,0.8-0.5,1.8-0.2,2.6
               c0.3,0.8,1,1.3,1.6,1.7c7.4,4.5,16,4.5,24.3,4.3c9.9-0.2,20-0.4,29.2-4.8c4.5-2.1,8.9-5.6,10.8-11c0.8-2.4,1.1-5.1,1.2-7.8
               c0.1-4.2-0.4-8.2-3.1-11.1c-1.6-1.8-3.4-3.2-5.7-3.3c-2.2-0.1-4.3,0.5-6.5,0.6c-3.6,0.1-7.2-0.4-10.8-0.4
               C391.3,329.8,387.1,330.1,383,331.5z"/>
            <path class="st8" d="M586.5,332.4c-6.4-1.2-12.9-2-19.4-2.5c-6-0.5-12.2-0.6-18,0.9s-11.5,5-14.9,10.4c-0.6,1-1.1,2.1-1.1,3.3
               c0.1,1.4,1,2.6,2,3.5c3,2.6,6.8,3.5,10.3,5c8.7,3.6,15.9,10.3,23.9,15.4c8,5.1,17.8,8.7,26.5,5.2c7.3-2.9,12.6-11,12.8-19.4
               c0.2-8.5-4.7-16.8-11.8-20.2c-4.9-2.3-10.5-2.4-15.8-1.8"/>
            <path class="st8" d="M447.5,511.7c-3.5,2.4-6.7,6.5-5.4,10.5c1.3,4.2,6.6,5.8,11.1,5.5c4.9-0.3,9.8-2.1,13.8-5.1
               c2.9,4.3,7.2,8,12.2,9.1c5.1,1.1,11-0.7,13.7-5.1c2.7-4.4,1.7-10.5-1.5-14.6c-3.2-4.1-8.2-6.4-13.3-7.5c-1.6-0.3-3.2-0.5-4.7,0.1
               c-1.7,0.7-2.9,2.2-4.4,3.1c-1.6,1-2.2,0.3-3.8-0.1c-3.7-0.9-8.3,0-11.8,1.2C451.3,509.5,449.3,510.5,447.5,511.7z"/>
            <path class="st2" d="M556.2,509.2c3.4-9.7,1-21.1,5.4-30.4c-5.1,16.9-13.1,32.8-23.5,46.8c-7.1,9.6-15.4,18.5-19.7,29.8
               c8.2-8.4,20.1-12.7,26.5-22.8c0.9-1.5,1.8-3.1,2.8-4.5c1.1-1.5,2.3-2.3,3.7-3.4c1-0.8,0.9-1.9,0.9-3.1c0-1.9,0.5-3.8,1.2-5.6
               C554.3,513.7,555.4,511.5,556.2,509.2z"/>
            <path class="st2" d="M629.1,411.2v-39.6c0,0-9.5,79.3-15.4,87.3C613.7,458.9,630.5,426.8,629.1,411.2z"/>
            <polygon class="st2" points="345.3,466.9 325.9,375.8 338.3,455.1 	"/>
            <g>
               <path class="st5" d="M330.1,273.7c-3.6,2.4-7.1,4.9-10.5,7.6C323.6,279.6,327.2,277,330.1,273.7z"/>
               <path class="st5" d="M377,135.2c-0.6,0-1.2,0-1.8,0c0,0.4,0.1,0.9,0.4,1.2C376.3,137.5,378.3,135.3,377,135.2z"/>
               <path class="st5" d="M380.8,115c-2.2,0.1-4.3,0.3-6.5,0.4c-0.6,1-1,2-1.4,3.1c-4.7,0.1-9.5,0.2-14.2,0.7c1.9,0.4,3.8,0.7,5.8,0.8
                  c-14.7,7-29.1,14.8-43,23.3c-1.4,2.3-2.8,4.7-4.2,7c2.9,0.8,5.7,2.2,8.1,4.1c-6,2.8-11.8,6.1-17.3,9.8c-1.4,2.2-2.7,4.4-3.8,6.7
                  c1.6,0.4,3.2,0.7,4.9,1.1c-2.4,1.9-4.8,3.9-7.2,5.8c-0.6,2.8-0.9,5.7-1,8.6c3.7,0.5,7.4,0.9,11.1,1.4c-2.8,5.9-6.4,11.4-10.5,16.4
                  c0.1,1.8,0.1,3.6,0,5.4c1.3,0.2,2.7,0.5,4,0.7c0.5,7-3.2,12.9-8.4,17.9c-0.6,1.5-1.3,3.1-2,4.6c4.3-1.6,8.7-2.9,13.2-3.9
                  c-1.4,3.7-2.8,7.5-4.2,11.2c3.9-2.9,7.8-5.7,11.6-8.6c-7.7,7.9-15.4,15.8-23.2,23.7c0,0,0,0.1,0.1,0.1c3.3-2.9,6.9-5.5,10.8-7.5
                  c0.3,5.4,0.4,11-0.6,16.2c0.2,2.9-0.8,6.1-2.9,8.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.5-0.6,1-1,1.5c-3.2,4.4-7.9,7.2-12.8,10
                  c0,0.1,0.1,0.1,0.1,0.2c-0.1,0-0.3,0-0.4,0c-1.8,1-3.7,2-5.5,3.1c2.1,1.8,4.3,3.4,6.7,4.8c0.5-0.1,0.9-0.1,1.4-0.1
                  c0,0,0-0.1,0-0.1c0.1-0.8,1-1.2,1.8-1.3c4.5-0.8,8.9-1.4,13.5-1.8c-0.9,2.8-1.4,5.8-1.5,8.7c4.3,0.7,8.7,0.8,13,0.2
                  c-0.3,6.3-4.8,12.2-10.8,14.1c-0.1,0.3-0.2,0.6-0.3,1c1.6,1.2,2.3,3.5,1.9,5.5c-0.4,2.4-1.9,4.5-3.3,6.4c1.6,0.1,3.1,0.2,4.7,0.3
                  c3.1-3.5,6.9-6.5,11-8.7c3.9-5.9,8.2-11.5,12.9-16.8c-4.3,2.6-8.5,5.1-12.8,7.7c-1.5-5.7,1.2-12.2,6.2-15.3
                  c-5,1.4-10.3,1.8-15.5,1.2c3.7-1.4,7.3-2.8,11-4.3c-2.9-0.1-5.9-0.1-8.8-0.2c2.6-2.6,5.4-5,8.2-7.3c-0.4,0.2-0.9,0.4-1.3,0.6
                  c4.1-14.1,11.9-27.2,22.3-37.6c-8.4,7.2-17,14.2-25.8,21c1-5.6,4.1-10.8,8.6-14.4c-2.6-0.4-2.2-4.4-0.7-6.7
                  c4.4-7,10.7-12.9,18.1-16.7c-6.1,2.6-12.3,5.3-18.4,7.9c4.9-14.5,16.1-26.9,30-33.3c-4.4,0.1-8.7,0.3-13.1,0.4
                  c2.3-3.8,5.2-7.3,8.5-10.3c-1.5,1.2-3.9-1.1-3.4-3c0.5-1.9,2.4-3.1,4.1-4c1.7-0.9,3.7-2,4.2-3.9c-10,6.8-19.9,13.6-29.9,20.4
                  c-1.3-8.5,4-16.9,11.1-21.7c7.1-4.9,15.6-6.9,24-8.9c-2.6-0.6-0.5-4.7,2.1-5.5c-2.2-2.3-5.3-3.7-8.5-4c5.3-4.6,11.7-7.7,18.6-9.2
                  c-8.9-0.1-17.9,1.1-26.5,3.5c8-6.5,17.5-11,27.6-13c-2.8-2.9-6.7-4.5-10.7-4.6c4.6-2.3,9.6-3.4,14.7-3.4c0-0.9,0.3-1.8,0.5-2.7
                  c0.6-3.3-3.6-6.6-6.9-6.1c0-0.3,0-0.5,0-0.8C372.3,122.9,378.3,118.7,380.8,115z"/>
               <path class="st5" d="M289.7,291.9c-0.4,0-0.8,0.1-1.1,0.1C288.7,292.7,290.2,292.4,289.7,291.9z"/>
               <path class="st5" d="M324.5,313.6c-2.1,0.7-4.1,1.6-6.1,2.7c-0.5,0.8-1.1,1.7-1.6,2.5C319.9,317.9,322.6,316.1,324.5,313.6z"/>
            </g>
            <path class="st5" d="M654.6,242c-0.7-1.3-0.7-2.8-0.7-4.3c0-1.3,0-2.6,0-4c-0.4-1.2-0.8-2.4-1.2-3.5c-4.3-0.8-8.5-1.6-12.8-2.4
               c1.5-0.4,3-0.8,4.5-1.2c-2.2-1.8-4.4-3.6-6.6-5.3c3-1.3,6.2-1.9,9.5-1.8c-4.9-2.4-10.4-3.8-15.8-4.1c1.7-0.7,3.4-1.4,5.1-2.1
               c-2.1-1.2-3.7-3.1-4.6-5.4c1.5-0.3,3.1-0.6,4.6-1c-6.4-5.2-11.9-11.4-16.3-18.3c4.5,3,9.6,5,15,5.7c-0.7-1.2-1.5-2.2-2.5-3.2
               c-4,0-8-1.9-10.3-5.1c3.9,0.5,7.6,2.3,10.3,5.1c1.1,0,2.2-0.1,3.3-0.5c2.9,4,7.1,7.1,11.8,8.8c-0.7-1.5-1-3.1-1.1-4.8
               c-1-1.4-2.1-2.7-3.1-4.1c-2-2.9-2.6-7.9,0.8-9.3c0.9-0.4,2.1-0.5,3.2-0.7c0.1-1.4,0.2-2.7,0.3-4.1c-2.1-2-3.1-5.2-2.1-8
               c-4.8,2.8-10.5,4-16,3.2c0.1-1.1,0.1-2.3,0.1-3.4c-1.5,0-2.9,0-4.4,0c1.5,0,2.9,0,4.4-0.2c0-2.1-0.2-4.3-0.5-6.4
               c-2.3-1.7-4.3-4-5.6-6.5c1.3-0.4,2.6-0.9,3.8-1.3c-1-3.2-2.4-6.4-4.1-9.3c-0.3,0-0.6-0.1-0.9-0.1c0.3-0.1,0.5-0.2,0.8-0.2
               c-0.8-1.3-1.6-2.6-2.5-3.9c-2.7-0.4-5.5-0.6-8.2-0.5c-16.9,2-34.3-0.5-49.9-7.2c15.3,2.2,31.1,1.3,46.1-2.5
               c-7,0.1-14.1,0.2-21.1,0.3c4-1.7,8.3-2.7,12.7-2.9c-15.8,0.3-31.6,0-47.3-1.2c10.6-1,21.2-2,31.8-3.1c-7-0.3-13.9-0.7-20.9-1
               c9.3-0.4,18.5-0.9,27.8-1.3c0.1-0.4,0.3-0.8,0.6-1.2c0.9,0.6,2,0.9,3,1c0.5,0,0.9,0,1.4-0.1c-0.5,0.1-0.9,0.1-1.4,0.1
               c-1.2,0.1-2.4,0.1-3.6,0.2c-0.6,2.5,2.8,5.8,5.2,4.2c2.4-1.6,0.8-5.8-1.7-6.3c-0.5,0-1,0.1-1.5,0.1c0.5-0.2,1-0.2,1.5-0.1
               c0.7,0,1.3-0.1,2-0.1c-2.7,0-5.5-0.2-8.2-0.8c2.4-0.5,4.8-0.9,7.2-1.4c0,0,0-0.1-0.1-0.1c-19.1,0.3-38.3-0.4-57.4-2
               c-15.9-0.5-31.7-2.5-47.1-5.9c15.6,2.6,31.4,4.6,47.1,5.9c17,0.6,34-0.5,50.8-3.2c-2.1-0.9-4.4-1.5-6.6-2.3c-2-0.7-4-1.7-5.9-2.7
               c-1.4-0.1-2.8-0.1-4.2,0c-2,0.2-3.9,0.3-5.9,0.5c2-0.3,3.9-0.4,5.9-0.5c1.2-0.1,2.4-0.2,3.6-0.3c-0.3-0.2-0.6-0.3-0.8-0.5
               c-12.5-1.1-24.9-3.8-36.7-8c7.3-0.5,14.5-1.2,21.8-2.2c-1.2-0.8-2.4-1.7-3.6-2.5c-0.8,0-1.7-0.1-2.5-0.1
               c-10.8-1.8-21.3-4.7-31.5-8.8c3.8-0.4,7.6-1.1,11.3-2c-12.9-4.4-26.7-6.9-40.2-9c-1.5-0.2-3.2-0.6-4.1-1.8c-0.6-1-0.6-2.2-0.6-3.3
               c0.2-6.1,0.5-12.2,0.7-18.2c-4.2,7.9-14.1,10.9-23.1,12.3c-9,1.4-18.7,2.2-25.5,8.2c0.9-1.7,0.7-3.9-0.4-5.4
               c-6.2,12.1-17.2,21.7-30.1,26.3c0.4-3.6-1.7-7.3-4.9-8.8c3,9.5-6.5,18.2-15.3,23.2c-7.2,4.1-15.4,9.1-18.2,16.3
               c1.7,1.6,3.4,3.3,4.9,5.2c3.3-1.6,5.8-4.5,7-7.8c1.4,1.4,2.4,3.4,2.7,5.4c1-3.8,2.1-7.6,4.5-10.7s6.5-5.2,10.3-4.2
               c-0.8,6.1-1.6,12.3-2.4,18.4c3.3-3.3,5.9-7.2,7.7-11.5c0,5.1-0.9,11.1,0.9,16.1c2-0.3,3.5-0.5,3.8-0.6c6-1.2,11.9-3.1,17.5-5.7
               c-1.7,1.4-3.4,2.9-5.1,4.3c18,5.1,37.6,4.2,55.1-2.5c-5.5,2.5-10.9,5.1-16.4,7.6c17.7,2.1,35.9,0.9,53.2-3.5
               c-12.3,5.6-25.8,8.7-39.4,8.8c6.3,1.8,12.2,5,17.1,9.3c-4.3,0.4-8.7,0.7-13,1.1c1.8,4.2,5.3,6.9,9.6,8.6c1,0.3,1.9,0.6,2.9,1
               c4.9,1.4,10.4,1.8,15.5,1.9c7.1,0.1,14.3,0.2,21.3-0.5c6-1.9,12.1-3.3,18.3-4.3c-5.8,2.4-12,3.7-18.3,4.3
               c-3.9,1.3-7.8,2.7-11.6,4.4c9.6,1,19.2,1.9,28.8,2.9c1.8,0.2,3.6,0.4,5.3,1.2c3,1.5,4.6,4.6,7.2,6.8c3.2,2.7,7.9,3.5,11.8,2
               c0,2.2,0,4.4-0.1,6.6c-2.4-0.7-4.7-1.4-7.1-2.1c4.1,3.7,6.5,9.1,6.3,14.5c3.7-5,7.3-9.9,11-14.9c-1.3-12.2-14-19.5-25.1-24.8
               c12.6-0.4,25.2,5.5,33,15.3c-0.1-6.1-0.3-12.2-0.4-18.2c2.9-1.6,6.7,0,8.6,2.8c1.8,2.7,2.2,6.1,2.5,9.4c0.3,3.5,0.6,7,0.9,10.6
               c-1.2,7-2.4,14-3.6,21c2.2-0.3,4.3-1.3,5.9-2.8c0.1-0.2,0.3-0.3,0.5-0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.8,1.2,0.5,3.2,2.1,3.4
               c1.4-1.4,3.1-2.5,5-3c-1.3,1.5-2.8,3.1-4.8,3.1c-0.1,0-0.2,0-0.2,0c-1.3,1.3-2.3,2.9-2.8,4.7c4,1.8,8.4,2.8,12.8,3
               c1.5-0.2,3.1-0.3,4.6-0.1c-1.5,0.1-3,0.1-4.6,0.1c-2.4,0.4-4.8,1.2-6.8,2.6c5.5,1.3,10.6,4.1,14.7,7.9c-2.3,0.8-4.7,1.6-7,2.4
               c2.2,0.9,4.2,2.5,5.7,4.3c-2.8,1.1-5.5,2.1-8.3,3.2c5.8,8.3,14.7,14.4,24.6,16.9c-9.2,0-18.3,0.1-27.5,0.1
               c11.4,8,25.1,12.9,39,13.9c-8.5,3.2-18.2,3.2-26.7,0.1c1.6,4.3,5.6,7.8,10.2,8.7c-1.5,0.8-3.1,1.6-4.6,2.4c1.8-1.1,1.9,3,0,3.8
               c7,2,14.2,3.5,21.4,4.5c-0.2-0.7-0.4-1.3-0.7-2c1.3,0.4,2.5,0.7,3.8,1.1c0.1-2.7,0.1-5.4,0.2-8.2c2.1,0.7,4.3,1.4,6.4,2.1
               c-0.3-9.3-1.5-18.5-3.6-27.6C655.9,243.5,655.1,242.9,654.6,242z"/>
            <path class="st9" d="M486,83.6c-4,3.5-8.3,6.7-12.9,9.6c8.7,1.5,17.9,0.5,26-2.9c-4.1,1.7-7.9,4-11.3,6.8c3,0.3,5.9,0.6,8.9,0.9
               c-0.7,0.7-1.5,1.4-2.2,2.1c21.3,1.8,43.3,3.5,63.4-3.7c-13.8,4.9-27.7,9.8-41.5,14.6c4.4,0.4,8.9,0.3,13.3-0.4
               c-2.2,0.4-4.4,0.8-6.5,1.1c6.4,4.5,13.9,7.4,21.7,8.3c-3.2,0-6.3,0.6-9.3,1.6c16,3.7,32.7,4,48.8,1c-11.3,2.3-22.3,5.8-32.8,10.5
               c4.3,1.5,8.5,3,12.8,4.4c-1.6,0.6-3.1,1.1-4.7,1.7c6.6,1.7,13.2,3.4,19.9,5.1c-1,0.8-2,1.5-3.1,2.3c3.4,1.4,6.8,2.9,10.2,4.3
               c-16.5-4.8-33.5-7.7-50.7-8.8c3.7-1.5,7.8-2.4,11.8-2.5c-4.1-1.3-8.2-2.5-12.4-3.8c1.9-0.4,3.8-0.8,5.7-1.2
               c-10.5-0.3-21-0.7-31.5-1c7.7-2.4,15.5-4.9,23.2-7.3c-1.6-0.2-3.3-0.4-4.9-0.6c-4.2-1.6-8.7-2.2-13.1-1.7c1.4-1.3,2.8-2.5,4.2-3.8
               c-1.8-0.1-3.6-0.2-5.4-0.2c0.5,0.3,1-0.6,0.7-1.1c-0.3-0.5-1-0.6-1.6-0.7c-19.4-1.8-39.2,0.1-57.9,5.7
               c15.5-8.7,32.2-15.2,48.7-21.8c-8.6,1.3-17.2,2.6-25.8,3.9c0.9-0.9,1.8-1.9,2.7-2.8c-3.2-0.1-6.5-0.1-9.7-0.2
               c0.1-0.8,0.8-1.4,1.5-1.4c-1.8,0.5-3.7,1-5.5,1.5c0.9-1.5,1.2-3.2,1-4.9c-20.2,2.9-40.5,4.9-60.9,6.2c1.5-0.1,3.1-0.8,4.6-1.2
               c1.6-0.4,3.2-0.8,4.8-1.2c3.1-0.8,6.3-1.5,9.4-2.3c6.4-1.5,12.7-3,19.1-4.3c3.6-0.8,7.3-1.4,10.9-2.3c-2.2-0.1-0.4,3.3,0.9,3.7
               c1.7,0.6,3.9-0.4,5.5-1c5.4-2,10.7-4.4,15.8-7.1C480.4,86.9,483.2,85.3,486,83.6z"/>
         </g>
         <path class="st2" d="M561.6,626.1c0,0-11.8,37.5-72.5,51.2l70.6-6.8C559.7,670.5,571.4,628.2,561.6,626.1z"/>
         <path class="st8" d="M494.9,592.2c-5.5,1.6-10.7,5.1-13.4,10.2c-2.8,5.3-2.7,11.5-2.5,17.5c0.2,5.5,0.3,11,0.5,16.6
            c0,1.3,0.1,2.7,0.6,3.9c0.4,1,1.2,1.9,2,2.6c7.8,7.7,20.3,10.2,30.5,6c3.8-1.6,7.2-4,10.6-6.4c1.9-1.4,3.8-2.9,4.9-4.9
            c1.1-1.9,1.4-4.1,1.6-6.2c0.8-6.1,1.6-12.2,1.9-18.3c0.2-3.9,0.5-8.2-1.1-11.9c-2.7-6.3-10.7-8-16.8-9.2
            C507.5,590.8,500.9,590.5,494.9,592.2z"/>
         <path class="st2" d="M598.1,582c4.3,8.8,6.3,19.3,3.2,28.6c-1.4,4.3-3.9,8.2-6.4,11.9c-6.7,10.1-14,19.7-21.9,28.9
            c-4.8,5.6-10.9,11.4-18.3,11.3c-0.7-8.5-1.4-17.3,1.6-25.2c1.3-3.6,3.4-6.9,4.8-10.5c4.7-11.4,3.3-24.5,6.3-36.5
            c2.3-9.1,7.3-17.5,8.3-27c0.1-1.4-0.4-4,1-4.9c1.1-0.7,2.7-0.1,3.7,0.4c3.7,1.8,6.3,6.1,8.7,9.2C592.6,572.6,595.7,577.1,598.1,582z
            "/>
         <path class="st0" d="M363.4,569c0,0,15.5,112.4,89.2,120.7c73.8,8.3,141.1-22.6,146-108.3c0,0,29.6,1.1,71.7,21.8
            c42.1,20.6-194.5,241.1-194.5,241.1l-166.4-234C309.4,610.2,326.7,583.6,363.4,569z"/>
         <g>
            <path class="st10" d="M401.6,885.6c-13.7,0-32.7-10.3-32.7-27v-55.9c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2v55.9
               c0,12.4,15.9,20.6,26.4,20.6c10.5,0,26.4-8.2,26.4-20.6v-55.9c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2v55.9
               C434.3,875.4,415.2,885.6,401.6,885.6z"/>
            <path class="st10" d="M401.6,874c-12.7,0-21.2-8.5-21.2-21.2v-50.1c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2v50.1
               c0,9.3,5.5,14.8,14.8,14.8c9.3,0,14.8-5.5,14.8-14.8v-50.1c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2v50.1
               C422.7,865.5,414.2,874,401.6,874z"/>
            <path class="st10" d="M569.8,882.8c-1.8,0-3.2-1.4-3.2-3.2v-77c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2v77
               C572.9,881.4,571.5,882.8,569.8,882.8z"/>
            <path class="st10" d="M582.5,882.8c-1.8,0-3.2-1.4-3.2-3.2v-77c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2v77
               C585.7,881.4,584.2,882.8,582.5,882.8z"/>
            <path class="st10" d="M360.6,882.8c-1.5,0-2.8-1-3.1-2.6c-0.1-0.6-11.1-56.2-12.8-64.1c-1.3-6.3-6.1-10.3-12.5-10.3
               s-11.2,3.9-12.5,10.3c-1.7,7.9-12.7,63.6-12.8,64.1c-0.3,1.7-2,2.8-3.7,2.5c-1.7-0.3-2.8-2-2.5-3.7c0.1-0.6,11.1-56.2,12.8-64.2
               c2-9.3,9.3-15.3,18.7-15.3c9.4,0,16.7,6,18.7,15.3c1.7,8,12.7,63.6,12.8,64.2c0.3,1.7-0.8,3.4-2.5,3.7
               C361,882.8,360.8,882.8,360.6,882.8z"/>
            <path class="st10" d="M349,882.8c-1.5,0-2.8-1-3.1-2.5l-3.6-16.4h-20.1l-3.6,16.4c-0.4,1.7-2.1,2.8-3.8,2.4
               c-1.7-0.4-2.8-2.1-2.4-3.8l4.2-18.8c0.3-1.5,1.6-2.5,3.1-2.5h25.2c1.5,0,2.8,1,3.1,2.5l4.2,18.8c0.4,1.7-0.7,3.4-2.4,3.8
               C349.4,882.8,349.2,882.8,349,882.8z"/>
            <path class="st10" d="M341.7,850.1C341.7,850.1,341.7,850.1,341.7,850.1h-19.1c-0.9,0-1.8-0.4-2.4-1.1c-0.6-0.7-0.9-1.6-0.7-2.5
               l0-0.2c3.3-22.4,6.1-33.8,12.7-33.8c6.5,0,9.4,11.3,12.6,33.7c0.1,0.2,0.1,0.5,0.1,0.8C344.9,848.6,343.5,850.1,341.7,850.1z
               M326.3,843.7H338c-2.1-13.7-4.7-24.4-5.9-24.4C331,819.3,328.4,830.1,326.3,843.7z"/>
            <path class="st10" d="M658.4,882.8c-1.8,0-3.2-1.4-3.2-3.2v-56.5c0-12.1-13.7-19.6-26.4-19.6c-12.7,0-26.4,7.5-26.4,19.6v56.5
               c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2v-56.5c0-16.3,16.6-26,32.7-26s32.7,9.7,32.7,26v56.5C661.6,881.4,660.1,882.8,658.4,882.8
               z"/>
            <path class="st10" d="M646.6,882.8c-1.8,0-3.2-1.4-3.2-3.2v-52.1c0-9.6-7.9-13-14.6-13c-6.7,0-14.6,3.4-14.6,13v52.1
               c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2v-52.1c0-13.3,10.9-19.3,21-19.3s21,6,21,19.3v52.1
               C649.8,881.4,648.4,882.8,646.6,882.8z"/>
            <path class="st10" d="M439.8,870.5c-1.8,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2c9.5,0,16.1-15.1,20.9-26.1c0.7-1.6,1.4-3.2,2-4.6
               c0.4-0.9,0.8-1.9,1.3-2.8c5.5-12.4,13.9-31.2,33.5-31.2c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2c-15.5,0-22.2,15.2-27.7,27.4
               c-0.4,1-0.9,2-1.3,2.9c-0.6,1.4-1.3,2.9-2,4.5C460.9,853.3,453.4,870.5,439.8,870.5z"/>
            <path class="st10" d="M558.6,805.9h-50.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2h50.2c1.8,0,3.2,1.4,3.2,3.2
               C561.7,804.5,560.3,805.9,558.6,805.9z"/>
            <path class="st10" d="M526.9,810.3h-18.5c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2h15.4v63.1c0,1.8,1.4,3.2,3.2,3.2
               s3.2-1.4,3.2-3.2v-66.2C530.1,811.7,528.7,810.3,526.9,810.3z"/>
            <path class="st10" d="M558.6,810.3H540c-1.8,0-3.2,1.4-3.2,3.2v66.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2v-63.1h15.4
               c1.8,0,3.2-1.4,3.2-3.2C561.7,811.7,560.3,810.3,558.6,810.3z"/>
            <path class="st10" d="M439.8,882.8c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2c10.9,0,24.6-13.6,31-30.9
               c6.6-17.7,14.1-35,26.6-35c1.8,0,3.2,1.4,3.2,3.2s-1.4,3.2-3.2,3.2c-8,0-14.6,14.8-20.6,30.9C470.4,864.8,455.3,882.8,439.8,882.8z
               "/>
         </g>
         <path class="st1" d="M666,584.8c-21-10-42-20.1-63-30.1c-3-1.4-6-2.9-9.3-2.9s-6.7,1.7-7.9,5c-0.9,2.4-0.5,5.2,0,7.8
            c1.4,8.2,2.9,16.5,4.3,24.7c0.6,3.2,1.3,5.9,0.2,9c-1.2,3.3-1.8,6.7-2.5,10.2c-1.7,8.7-3.4,17.6-6.2,26c-4.8,14.3-12,29.9-21.8,40.8
            c-23.3,29.6-59,46.7-94.4,45.3c39.5,14.9,87.1-2.7,110.6-40.9c9.8-15.9,15.7-34.8,27.2-49.3c9.8-12.5,23.1-20.9,36-29.1
            c9-5.7,19.1-10.8,27.5-17.5c-6.7-3.9-14.5-6.3-21.6-9.4c-6.7-2.9-13.3-6.2-20.1-8.5c-7.9-2.7-16.4-2.4-22.7,4.1
            c-6.5,6.6-9.5,16.7-11.8,25.9c-0.1,0.5-0.2,0.9-0.3,1.4"/>
         <path class="st1" d="M409,697.9c-1.6-0.5-5.9,1-7.8,1.2c-2.8,0.2-5.7,0.1-8.5-0.1c-5.6-0.6-11.1-2-16.2-4.3
            c-9.8-4.3-20.5-12.6-23.4-23.4c-1.2-4.5,0.7-20.1,8.5-14.6c6.4,4.5,10.3,13.5,15.2,19.5C384.9,686.4,396.6,693.8,409,697.9z"/>
         <g>
            <path class="st11" d="M446.2,897.9c0-1.8-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2V960h6.3V897.9z"/>
            <path class="st11" d="M458.9,897.9c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2V960h6.3V897.9z"/>
            <path class="st11" d="M466.9,901.1h50.2c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2h-50.2c-1.8,0-3.2,1.4-3.2,3.2
               C463.8,899.7,465.2,901.1,466.9,901.1z"/>
            <path class="st11" d="M488.6,908.6c0-1.8-1.4-3.2-3.2-3.2h-18.5c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2h15.4V960h6.3V908.6z
               "/>
            <path class="st11" d="M501.7,911.8h15.4c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2h-18.5c-1.8,0-3.2,1.4-3.2,3.2V960h6.3V911.8z
               "/>
            <path class="st11" d="M374.7,935.8c0-13.9,6.8-22.2,12.5-26.7c7.5-5.9,16.1-8,21.2-8h19.7c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2
               h-19.7c-6.4,0-16.4,2.5-25.2,9.4c-6.8,5.4-14.9,15.3-14.9,31.7c0,10.6,3.4,18.5,7.6,24.2h8.3C378.8,954.5,374.7,946.7,374.7,935.8z
               "/>
            <path class="st11" d="M428.2,959.8h-19.7c-8.8,0-21.9-6.4-21.9-24c0-17.6,13.1-24,21.9-24h19.7c1.8,0,3.2-1.4,3.2-3.2
               c0-1.8-1.4-3.2-3.2-3.2h-19.7c-10.9,0-28.3,8.2-28.3,30.4c0,11.6,4.8,19.4,10.9,24.2h38C428.8,959.9,428.5,959.8,428.2,959.8z"/>
            <path class="st11" d="M554.3,941.2c0-1.5-1-2.8-2.5-3.1c-9.8-2.1-19.7-9.7-19.7-20.2v-20c0-1.8-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2
               v20c0,11.7,9,22,22.2,25.8V960h6.3V941.2z"/>
            <path class="st11" d="M567.8,943.7c13.2-3.8,22.2-14.1,22.2-25.8v-20c0-1.8-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2v20
               c0,10.4-9.9,18.1-19.7,20.2c-1.5,0.3-2.5,1.6-2.5,3.1V960h6.3V943.7z"/>
            <path class="st11" d="M557.9,933.4c14.8,0,21.4-9.3,21.4-18.6v-16.9c0-1.8-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2v16.9
               c0,7.7-5.6,12.2-15.1,12.2c-9.4,0-15.1-4.6-15.1-12.2v-16.9c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2v16.9
               C536.5,924,543.1,933.4,557.9,933.4z"/>
         </g>
         <path class="st12" d="M-783.5-964c0-3.3,1.3-7.6-1.8-8.2v8.2H-783.5z"/>
         <path class="st12" d="M-121.3-1750.1c0.3-0.4,0.8-0.7,1.2-0.8l-1.3-1.4c-0.4,0.9-0.5,1.8-1.8,2.6
            C-122.5-1749.3-121.9-1749.4-121.3-1750.1z"/>
         <g id="HAND-AND-UKE">
            <g id="UKE_1_">
               <path id="UKE-BACKDROP" class="st4" d="M550.9,1006.4c2.3,4.7,4.7,9.1,6.8,13.6c13.3,29.7,13.4,58.5-6.4,85.6
                  c-11.4,15.6-27.7,24.1-46.1,28.9c-7.2,1.9-14.6,3.4-21.9,5.2c-14.2,3.3-24.7,11.5-31.3,24.3c-7.2,13.9-13.6,28.2-20.7,42.1
                  c-9.8,19.3-18.7,39.2-33.5,55.4c-28,30.7-61.8,48.8-104.4,46.4c-27.6-1.5-51.2-13.1-70.8-32.2c-28-27.2-51.6-58.1-69.9-92.6
                  c-24.2-45.8-15.6-98.1,21.1-134.7c20.2-20.2,44.1-33.7,72.1-39.5c22.7-4.7,45.3-9.5,68.1-13.4c20.3-3.4,35-13.3,43.8-32.2
                  c5.4-11.7,12-22.6,21.1-32c24.5-25.2,60.8-31.1,91.7-13.9c9.6,5.4,18,13,26.7,19.9c2.5,2,3.9,2.5,6.7,0.6
                  c67.9-47.9,135.9-95.8,203.9-143.7c17.7-12.5,35.3-25.2,52.9-37.7c6.1-4.4,10.8-9.9,13.4-17c3.7-9.8,10.2-17.1,18.2-23.8
                  c30.4-25.5,60.4-51.4,90.5-77.2c3.2-2.7,6.6-5.1,9.7-7.9c2.5-2.2,4.4-1.7,6.2,0.7c1.6,2.1,3.3,4.2,4.8,6.4
                  c6.6,9.8,14.6,18.1,25.1,23.9c1.8,1,3.8,3.2,4.3,5.1c2.9,13.5,10.6,24.3,19,34.7c5.4,6.7,5.4,7-1.8,12c-26,17.7-52.1,35.3-78.2,53
                  c-10.6,7.2-21.4,14.1-31.8,21.6c-5.5,3.9-11.2,6.6-17.8,7.1c-12.8,0.9-23,6.9-32.6,14.9c-34.5,28.6-69.2,57-103.8,85.4
                  c-37.3,30.7-74.6,61.4-111.9,92.1C566.2,993.8,558.6,1000.1,550.9,1006.4z"/>
               <g id="UKE-BASE">
                  <path class="st13" d="M540.1,1003c2.3,4.7,4.7,9.1,6.8,13.6c13.3,29.7,13.4,58.5-6.4,85.6c-11.4,15.6-27.7,24.1-46.1,28.9
                     c-7.2,1.9-14.6,3.4-21.9,5.2c-14.2,3.3-24.7,11.5-31.3,24.3c-7.2,13.9-13.6,28.2-20.7,42.1c-9.8,19.3-18.7,39.2-33.5,55.4
                     c-28,30.7-61.8,48.8-104.4,46.4c-27.6-1.5-51.2-13.1-70.8-32.2c-28-27.2-51.6-58.1-69.9-92.6c-24.2-45.8-15.6-98.1,21.1-134.7
                     c20.2-20.2,44.1-33.7,72.1-39.5c22.7-4.7,45.3-9.5,68.1-13.4c20.3-3.4,35-13.3,43.8-32.2c5.4-11.7,12-22.6,21.1-32
                     c24.5-25.2,60.8-31.1,91.7-13.9c9.6,5.4,18,13,26.7,19.9c2.5,2,3.9,2.5,6.7,0.6C561,886.6,629,838.8,696.9,790.9
                     c17.7-12.5,35.3-25.2,52.9-37.7c6.1-4.4,10.8-9.9,13.4-17c3.7-9.8,10.2-17.1,18.2-23.8c30.4-25.5,60.4-51.4,90.5-77.2
                     c3.2-2.7,6.6-5.1,9.7-7.9c2.5-2.2,4.4-1.7,6.2,0.7c1.6,2.1,3.3,4.2,4.8,6.4c6.6,9.8,14.6,18.1,25.1,23.9c1.8,1,3.8,3.2,4.3,5.1
                     c2.9,13.5,10.6,24.3,19,34.7c5.4,6.7,5.4,7-1.8,12c-26,17.7-52.1,35.3-78.2,53c-10.6,7.2-21.4,14.1-31.8,21.6
                     c-5.5,3.9-11.2,6.6-17.8,7.1c-12.8,0.9-23,6.9-32.6,14.9c-34.5,28.6-69.2,57-103.8,85.4c-37.3,30.7-74.6,61.4-111.9,92.1
                     C555.4,990.4,547.8,996.7,540.1,1003z M778.1,799.5c-3-4-5.7-7.5-8.6-11.4c35.9-22.6,71.5-45.1,107.4-67.7c0.3,0.9,0.6,1.4,0.8,2
                     c1.5,4.3,4.4,6.7,9.1,6.1c3.9-0.5,6.3-3,6.8-6.9c0.5-3.7-0.9-6.6-4.4-8.2c-4.3-2-7.7-0.6-10.5,3c-0.9,1.1-2,2.2-3.3,3
                     c-31.1,19.7-62.3,39.3-93.4,58.9c-4.5,2.8-9,5.5-13.6,8.3c-3.2-4.2-6.1-8-9.1-12c1.4-1.5,2.7-2.9,4-4.1
                     c15.2-14.5,30.3-29,45.7-43.3c16.1-14.9,32.3-29.6,48.6-44.4c3.6-3.2,4.1-8.5,0.8-12c-3.1-3.3-8.6-3.3-11.6,0
                     c-3.3,3.5-2.9,9,0.9,11.9c1,0.8,2.1,1.3,3.6,2.2c-15,14.2-29.7,28.1-45.3,42.8c1.2-6,0.2-10.4-4.8-11.5c-2.7-0.6-6.9,0.5-8.8,2.5
                     c-4.3,4.6-1.3,9.1,2.5,12.9c-1.2,0.8-2,1.3-2.9,1.9c-18.2,12.4-38.3,21.8-55.7,35.7c-10,8-20.6,15.1-30.9,22.7
                     c-6.8,5-13.4,10-20.2,15c-13.2,9.7-26.3,19.4-39.6,29c-1.4,1-3.8,0.9-5.1,2c-5.6,4.6-10.9,9.6-16.6,14.2
                     c-1.5,1.2-3.9,1.1-5.4,2.3c-2,1.5-3.3,3.9-5.2,5.5c-3.5,2.8-6.9,6.6-11,7.7c-4.7,1.2-5.7,5.5-9.2,7.4c-3.2,1.7-5.8,4.5-8.9,6.4
                     c-1.6,1-5,0.9-5.4,2c-2.2,6.2-8.4,7.5-12.5,11.3c-1.6,1.4-4.1,1.7-6.6,2.7c-0.5,3.5-11.8,12.1-17.1,12.4
                     c-1,4.2-11.9,12.3-16.3,12.1c-1,4.7-11.3,11.9-16.2,11.2c-0.2,5-9.2,11.3-14.7,10.3c0.2,5-8.1,11.1-13.8,10.1
                     c0.5,5-6.7,10.2-13.1,9.4c1.7,4.4-1.9,5.8-4.5,7.3c-2.5,1.4-5.5,2-8.7,3c2.8,2.9-0.6,4-2.8,5.6c-7.8,5.7-15.5,11.6-23.5,17
                     c-2.1,1.4-5.1,2.4-7.6,2.3c-23-1.1-43.6,14.2-49.2,36.7c-0.5,2.2-2.1,4.5-3.9,5.8c-37.3,27.7-74.8,55.3-112.2,82.9
                     c-0.9,0.7-1.9,1.2-3.3,2.1c-2.6-4.3-4.9-8.6-7.7-12.4c-1.4-1.9-4-4.3-6-4.3c-11.1-0.2-20.6,3.9-26.8,13.5
                     c-4.8,7.4-3.9,11.8,2.6,17.9c22.5,21.3,41.7,45.4,57.4,72.1c7.1,12.1,5.3,9.6,17.5,8.4c10.5-1,16.6-7.4,20.4-16.8
                     c1.6-3.9,1.3-7.3-1.5-10.7c-2.6-3-4.8-6.3-7.4-9.6c0.4-0.4,0.8-0.9,1.3-1.3c36.3-29.1,72.5-58.2,108.8-87.2
                     c1.4-1.1,3.6-1.5,5.4-1.5c24.6,0.6,44.6-15.4,49.3-39.5c0.3-1.4,0.6-3.2,1.6-3.9c9.4-7.4,19-14.6,28.6-21.9
                     c0.9,0.6,2.2,1.6,3.5,2.5c0.3-0.3,0.7-0.6,1-0.8c-0.6-1.5-1.1-3.1-1.8-4.9c3-2.5,6-5,8.9-7.5c1.6,0.9,2.8,1.7,5.3,3.1
                     c-0.8-2.8-1.2-4.2-1.7-5.9c3.1-2.4,6.3-4.8,9.4-7.2c1.2,0.7,2.3,1.4,4.4,2.7c-0.7-2.4-1.1-3.7-1.6-5.5c4.9-1.9,7-9.3,13.1-7
                     c1-2,1.3-4.6,2.7-5.4c3.8-2.2,5.9-8.7,12.1-5c-0.5-5.5,9-13.9,14.9-13.4c-0.3-5.1,10.9-13.5,16.4-12.4
                     c-0.7-4.6,10.5-14.3,16.2-14c0.5-4.7,12.6-14.7,17.7-14.5c0.7-4.2,14.2-15.2,18.8-15.3c1.1-4.8,15.6-16,19.2-15.8
                     c1.8-2.2,3-4.3,4.7-5.7c6.6-5.5,13.3-10.8,20-16.2c7.7-6.2,15.3-12.3,23-18.5c10-8,20-16,30-24c24.9-19.8,50.3-39,72.6-61.8
                     c4.3-4.4,9.1-8.5,13.8-12.8c0.5,1,0.8,1.4,0.9,1.9c1.6,4.4,4.9,6.2,9.4,5.4c3.9-0.7,6-3.4,6.3-7.4c0.2-4-1.7-6.8-5.5-8
                     c-4.4-1.4-7.8,0.3-10,4.4c-0.7,1.3-1.7,2.5-2.7,3.5c-6.1,5.9-12.3,11.7-18.6,17.4C792.1,786.8,785.2,793.1,778.1,799.5z"/>
                  <path class="st4" d="M778.1,799.5c7.1-6.5,14-12.7,20.8-19c6.2-5.8,12.4-11.6,18.6-17.4c1.1-1,2-2.2,2.7-3.5
                     c2.2-4.1,5.6-5.8,10-4.4c3.8,1.2,5.7,4.1,5.5,8c-0.2,3.9-2.4,6.7-6.3,7.4c-4.5,0.8-7.8-1-9.4-5.4c-0.2-0.5-0.4-0.9-0.9-1.9
                     c-4.7,4.4-9.5,8.4-13.8,12.8c-22.4,22.8-47.8,42-72.6,61.8c-10,8-20,16-30,24c-7.7,6.2-15.3,12.3-23,18.5
                     c-6.7,5.4-13.4,10.7-20,16.2c-1.7,1.4-2.9,3.4-4.7,5.7c-3.7-0.2-18.1,11-19.2,15.8c-4.6,0-18.1,11-18.8,15.3
                     c-5.1-0.1-17.2,9.8-17.7,14.5c-5.7-0.3-16.9,9.4-16.2,14c-5.5-1.2-16.6,7.2-16.4,12.4c-5.9-0.6-15.5,7.9-14.9,13.4
                     c-6.2-3.7-8.3,2.9-12.1,5c-1.4,0.8-1.7,3.3-2.7,5.4c-6.2-2.3-8.2,5.2-13.1,7c0.5,1.7,0.9,3,1.6,5.5c-2.1-1.3-3.2-1.9-4.4-2.7
                     c-3.1,2.4-6.3,4.8-9.4,7.2c0.5,1.6,0.9,3.1,1.7,5.9c-2.4-1.4-3.7-2.2-5.3-3.1c-2.9,2.5-6,5-8.9,7.5c0.7,1.8,1.2,3.4,1.8,4.9
                     c-0.3,0.3-0.7,0.6-1,0.8c-1.3-0.9-2.6-1.9-3.5-2.5c-9.6,7.3-19.2,14.5-28.6,21.9c-1,0.8-1.4,2.5-1.6,3.9
                     c-4.7,24.1-24.7,40.1-49.3,39.5c-1.8,0-4.1,0.4-5.4,1.5c-36.3,29-72.6,58.1-108.8,87.2c-0.5,0.4-0.9,0.9-1.3,1.3
                     c2.5,3.3,4.8,6.6,7.4,9.6c2.8,3.3,3.1,6.7,1.5,10.7c-3.7,9.4-9.9,15.8-20.4,16.8c-12.2,1.2-10.5,3.6-17.5-8.4
                     c-15.7-26.8-34.8-50.8-57.4-72.1c-6.5-6.1-7.4-10.4-2.6-17.9c6.2-9.6,15.7-13.7,26.8-13.5c2.1,0,4.6,2.4,6,4.3
                     c2.9,3.8,5.1,8.1,7.7,12.4c1.4-0.9,2.4-1.5,3.3-2.1c37.4-27.6,74.8-55.2,112.2-82.9c1.8-1.3,3.3-3.7,3.9-5.8
                     c5.6-22.5,26.1-37.8,49.2-36.7c2.5,0.1,5.5-0.9,7.6-2.3c8-5.5,15.7-11.3,23.5-17c2.2-1.6,5.6-2.7,2.8-5.6c3.1-1,6.2-1.6,8.7-3
                     c2.6-1.5,6.2-2.8,4.5-7.3c6.5,0.8,13.6-4.4,13.1-9.4c5.7,0.9,14-5.1,13.8-10.1c5.5,1,14.5-5.3,14.7-10.3
                     c4.9,0.7,15.2-6.5,16.2-11.2c4.3,0.2,15.3-7.9,16.3-12.1c5.3-0.4,16.7-8.9,17.1-12.4c2.4-0.9,5-1.2,6.6-2.7
                     c4.1-3.7,10.4-5.1,12.5-11.3c0.4-1.1,3.8-1,5.4-2c3.1-1.9,5.7-4.7,8.9-6.4c3.5-1.9,4.6-6.2,9.2-7.4c4.1-1,7.5-4.8,11-7.7
                     c1.9-1.6,3.2-4,5.2-5.5c1.5-1.1,4-1.1,5.4-2.3c5.6-4.6,11-9.6,16.6-14.2c1.3-1.1,3.7-0.9,5.1-2c13.3-9.6,26.4-19.3,39.6-29
                     c6.8-5,13.4-10,20.2-15c10.3-7.6,20.9-14.7,30.9-22.7c17.3-13.9,37.5-23.3,55.7-35.7c0.8-0.6,1.7-1.1,2.9-1.9
                     c-3.9-3.8-6.8-8.3-2.5-12.9c1.8-1.9,6.1-3,8.8-2.5c5,1.1,6.1,5.6,4.8,11.5c15.6-14.7,30.2-28.6,45.3-42.8
                     c-1.5-0.9-2.6-1.4-3.6-2.2c-3.8-2.9-4.1-8.5-0.9-11.9c3.1-3.3,8.5-3.3,11.6,0c3.3,3.5,2.7,8.7-0.8,12
                     c-16.3,14.7-32.5,29.4-48.6,44.4c-15.4,14.3-30.5,28.9-45.7,43.3c-1.3,1.3-2.5,2.6-4,4.1c3,4,5.9,7.8,9.1,12
                     c4.6-2.8,9.1-5.5,13.6-8.3c31.2-19.6,62.3-39.2,93.4-58.9c1.2-0.8,2.4-1.8,3.3-3c2.8-3.6,6.3-5,10.5-3c3.4,1.6,4.8,4.5,4.4,8.2
                     c-0.5,3.9-2.8,6.4-6.8,6.9c-4.6,0.6-7.6-1.8-9.1-6.1c-0.2-0.6-0.5-1.1-0.8-2c-35.8,22.6-71.5,45-107.4,67.7
                     C772.4,792,775.1,795.5,778.1,799.5z M410.1,1094.1c-6.3-2.9-12.2-5.2-17.6-8.3c-2.9-1.7-4.5-1.5-6.9,0.4
                     c-32.6,25.4-65.2,50.7-97.9,76.1c-1.1,0.9-2.1,1.9-3.5,3.1c5.2,5.9,10.1,11.4,15.2,17.2C336.6,1152.9,373.4,1123.5,410.1,1094.1z
                     M267.4,1144.3c0.7-0.2,1.1-0.2,1.3-0.4c34.5-26.1,69-52.2,103.4-78.4c0.9-0.7,1.7-2.2,1.5-3.2c-1.1-6.8-2.4-13.6-3.8-20.8
                     c-38.3,28.3-76.8,56.9-115.6,85.6C258.8,1133.1,263,1138.6,267.4,1144.3z M387.2,1083c-4.5-5.9-8.4-11.1-12.5-16.4
                     c-35.1,26.7-70.1,53.3-105,80c4.3,5.7,8.5,11.2,12.5,16.4C317.4,1136.2,352.2,1109.7,387.2,1083z M483.1,1010.9
                     c-6.5,4.9-12.7,9.6-18.8,14.4c-0.7,0.5-1.1,2-0.9,2.9c1.3,6.5,2.9,13.1,4.2,19c8.8-6.7,18-13.7,27.3-20.8
                     C490.7,1021,487,1016.1,483.1,1010.9z M468.8,992.2c-4-5.2-7.6-10-11.5-15.2c-9.6,7.3-18.9,14.4-28,21.3
                     c5.7,2.8,11.3,5.6,16.9,8.3c1,0.5,2.7,0.6,3.4,0.1C456,1002,462.3,997.1,468.8,992.2z M758,773.1c14.6-13.8,28.9-27.4,43.3-40.9
                     c-0.3-0.5-0.6-1-0.9-1.5c-17.6,9.1-34,20.4-51.3,30.6C752.2,765.4,754.9,769,758,773.1z M481.7,1009.1
                     c-3.9-5.2-7.5-9.9-11.3-14.9c-6.7,5.1-13.3,10.1-19.7,15c3.8,5.1,7.6,10,11.3,14.9C468.6,1019.1,475,1014.3,481.7,1009.1z
                     M726.3,800.1c-8.1,6.2-15.8,12.1-23.5,17.9c3.2,4.2,6.2,8.1,9.1,12c8-6.1,15.7-11.9,23.5-17.9
                     C732.3,807.9,729.4,804.1,726.3,800.1z M728.8,798c3.2,4.2,6.1,8,9,11.8c8.3-6.4,16.5-12.5,24.6-18.7c-3.2-4.2-6.1-8-9-11.8
                     C745,785.6,737,791.7,728.8,798z M701.1,815.8c7.7-5.9,15.5-11.8,23.5-17.9c-3-3.9-5.8-7.6-9-11.9c-7.9,6-15.6,11.9-23.5,17.9
                     C695,807.8,697.9,811.6,701.1,815.8z M713.3,831.8c3.3,4.4,6.2,8.2,9,11.8c7.9-6.1,15.7-11.9,23.4-17.8c-3.2-4.2-6.1-8-9-11.8
                     C728.8,819.9,721.2,825.7,713.3,831.8z M763.8,793c-8.3,6.3-16.4,12.5-24.5,18.7c3.1,4.1,5.9,7.8,8.6,11.4
                     c8.3-6.3,16.3-12.4,24.5-18.7C769.4,800.4,766.6,796.7,763.8,793z M751.8,777.5c-3.1-4-5.9-7.8-8.9-11.7
                     c-8.3,6.3-16.3,12.4-24.5,18.7c3,4,5.9,7.7,8.9,11.7C735.5,789.9,743.5,783.8,751.8,777.5z M700.3,819.7
                     c-7.5,5.7-14.8,11.3-22.2,16.9c3.2,4.2,6.3,8.3,9.4,12.3c7.5-5.7,14.7-11.2,22.2-16.9C706.6,827.9,703.6,824,700.3,819.7z
                     M698.9,817.8c-3.3-4.3-6.2-8.2-9.4-12.4c-7.5,5.7-14.8,11.3-22.1,16.8c3.3,4.3,6.3,8.3,9.4,12.4
                     C684.3,829,691.4,823.5,698.9,817.8z M698,862.9c7.7-5.8,15-11.4,22.3-17c-3.3-4.4-6.2-8.2-9-11.8c-7.6,5.8-14.9,11.3-22.3,17
                     C692.2,855.2,695.1,859,698,862.9z M674.4,836.3c-3.4-4.4-6.4-8.4-9.7-12.8c-7.1,5.4-13.9,10.6-20.8,15.8
                     c3.3,4.4,6.5,8.5,9.7,12.8C660.7,846.8,667.4,841.7,674.4,836.3z M675.5,881.1c7.3-5.5,14.1-10.7,20.9-15.9
                     c-3.4-4.5-6.5-8.5-9.4-12.4c-7.1,5.4-13.9,10.6-20.9,15.9C669.3,873,672.4,877.1,675.5,881.1z M664.5,866.6
                     c7.2-5.5,14.1-10.7,20.9-15.9c-3.3-4.4-6.4-8.4-9.5-12.5c-7.1,5.4-13.9,10.6-20.9,15.9C658.3,858.4,661.3,862.4,664.5,866.6z
                     M641.5,841c-6.3,4.8-12.7,9.6-19.1,14.6c3.2,4.3,6.4,8.4,9.7,12.8c6.5-4.9,12.7-9.7,19.1-14.6C648,849.5,644.9,845.4,641.5,841z
                     M644.8,885c3.5,4.6,6.6,8.7,9.7,12.8c6.5-4.9,12.7-9.7,19.1-14.5c-3.4-4.5-6.6-8.7-9.7-12.8C657.4,875.4,651.3,880.1,644.8,885z
                     M652.8,855.7c-6.6,5-12.8,9.7-19.1,14.5c3.4,4.5,6.7,8.7,9.8,12.9c6.5-5,12.7-9.7,19.1-14.5
                     C659.3,864.2,656.2,860.2,652.8,855.7z M629.5,870.4c-3.4-4.4-6.6-8.7-10.1-13.2c-6,4.6-11.8,9-17.7,13.5
                     c3.4,4.5,6.7,8.8,10.1,13.2C617.8,879.3,623.5,874.9,629.5,870.4z M623.1,898.9c6.1-4.6,11.9-9.1,17.8-13.6
                     c-3.4-4.5-6.6-8.7-9.8-12.9c-6.1,4.6-12,9.1-17.8,13.6C616.7,890.4,619.8,894.6,623.1,898.9z M582,885.1
                     c3.6,4.7,6.9,9.1,10.2,13.5c5.8-4.4,11.3-8.6,16.8-12.8c-3.5-4.6-6.8-8.9-10.2-13.5C593.1,876.6,587.7,880.8,582,885.1z
                     M634.5,913.8c6.1-4.6,11.9-9.1,17.8-13.5c-3.6-4.7-6.7-8.8-9.8-12.8c-6.1,4.7-12,9.1-17.8,13.5
                     C628.2,905.6,631.4,909.8,634.5,913.8z M563.7,898.7c3.6,4.7,7,9.2,10.4,13.7c5.4-4.1,10.5-8,15.6-11.9
                     c-3.6-4.7-6.9-9.1-10.4-13.7C573.9,890.8,568.9,894.7,563.7,898.7z M597.6,943.5c5.5-4.2,10.6-8,15.8-12
                     c-3.6-4.8-7-9.2-10.4-13.7c-5.4,4.1-10.5,8-15.8,12C590.7,934.5,594.1,938.9,597.6,943.5z M593.8,900.6c3.4,4.4,6.7,8.8,10,13.1
                     c5.8-4.4,11.4-8.7,16.9-12.9c-3.5-4.5-6.6-8.7-10-13.1C605.2,892,599.8,896,593.8,900.6z M615.3,929.2c5.9-4.5,11.4-8.7,17-12.9
                     c-3.5-4.6-6.8-8.9-10-13.2c-5.8,4.4-11.4,8.7-17,12.9C608.8,920.6,612,924.8,615.3,929.2z M585.6,927.9
                     c5.5-4.2,10.6-8.1,15.9-12.1c-3.5-4.6-6.7-8.9-10.1-13.3c-5.4,4.1-10.6,8.1-15.9,12.1C579,919.1,582.2,923.4,585.6,927.9z
                     M585.2,932c-5.2,4-10,7.6-14.7,11.2c3.8,5,7.1,9.4,10.3,13.6c5.1-3.9,9.8-7.5,14.7-11.2C591.8,940.7,588.5,936.4,585.2,932z
                     M546.4,911.6c3.6,4.7,6.9,9.1,10.4,13.6c5.1-3.8,9.9-7.5,14.6-11.1c-3.6-4.7-6.9-9-10.4-13.6
                     C556.2,904.2,551.5,907.8,546.4,911.6z M564.5,969.8c4.9-3.7,9.5-7.2,13.9-10.6c-3.8-5-7.2-9.5-10.7-14.1
                     c-4.8,3.7-9.3,7-13.9,10.6C557.5,960.6,561,965.1,564.5,969.8z M554.3,927.3c-3.6-4.7-7.1-9.3-10.7-14.1
                     c-4.8,3.7-9.4,7.2-13.9,10.5c3.7,4.9,7.2,9.4,10.7,14.1C545.2,934.3,549.6,930.9,554.3,927.3z M568.7,941.1
                     c5.1-3.9,9.8-7.5,14.7-11.2c-3.6-4.7-6.9-9.1-10.3-13.5c-5.1,3.8-9.8,7.5-14.7,11.2C562,932.3,565.3,936.6,568.7,941.1z
                     M541.9,940c3.6,4.8,7.1,9.3,10.5,13.8c4.8-3.6,9.3-7.1,14-10.6c-3.6-4.8-7-9.2-10.5-13.8C551.1,932.9,546.6,936.4,541.9,940z
                     M527.2,925.4c-4.5,3.4-8.8,6.7-13.2,10.1c3.6,4.8,7.1,9.4,10.8,14.2c4.5-3.4,8.8-6.7,13.2-10.1
                     C534.4,934.8,530.9,930.2,527.2,925.4z M551.9,957.7c-4.7,3.5-8.9,6.8-13.2,10c3.8,5.1,7.4,9.7,10.7,14.1
                     c4.6-3.5,8.8-6.7,13.2-10C558.9,966.9,555.5,962.4,551.9,957.7z M537.1,965.7c4.5-3.4,8.8-6.7,13.1-10
                     c-3.7-4.8-7.1-9.4-10.6-13.9c-4.5,3.5-8.8,6.7-13.1,10C530.2,956.6,533.6,961.1,537.1,965.7z M522.3,951.6
                     c-3.7-4.9-7.3-9.6-10.9-14.4c-4.1,3.1-7.8,5.9-11.6,8.8c3.7,4.9,7.3,9.6,10.9,14.4C514.7,957.3,518.5,954.5,522.3,951.6z
                     M547.3,984.4c-3.9-5.2-7.5-9.8-11.1-14.6c-4,3.1-7.8,5.9-11.6,8.9c3.8,5,7.5,9.8,11.1,14.6C539.6,990.2,543.3,987.5,547.3,984.4
                     z M512.2,962.5c3.8,5,7.3,9.6,10.8,14.1c4-3.1,7.8-5.9,11.7-8.9c-3.7-4.8-7.1-9.3-10.8-14.1C520,956.6,516.2,959.4,512.2,962.5z
                     M533.2,995.4c-3.9-5.1-7.5-9.9-11.1-14.6c-3.9,3-7.5,5.7-11,8.4c3.9,5.1,7.6,10,11.1,14.6C526.1,1000.8,529.6,998.1,533.2,995.4
                     z M508,962.3c-3.8-5-7.3-9.6-10.9-14.4c-3.9,3-7.5,5.7-11.1,8.5c3.8,4.9,7.3,9.5,10.9,14.4C500.6,968,504.1,965.3,508,962.3z
                     M497.3,1023.7c3.5-2.7,6.8-5.1,9.9-7.5c-4-5.3-7.8-10.2-11.5-15.1c-3.4,2.6-6.6,5-9.9,7.5
                     C489.7,1013.8,493.4,1018.6,497.3,1023.7z M519.9,1006.1c-3.9-5.2-7.6-10-11.4-15c-3.4,2.6-6.7,5.1-10,7.6
                     c3.9,5.2,7.7,10.1,11.4,15C513.4,1011.1,516.6,1008.7,519.9,1006.1z M520.5,978.8c-3.8-5-7.3-9.6-10.8-14.2
                     c-3.9,3-7.5,5.7-11,8.4c3.8,4.9,7.2,9.5,10.8,14.2C513.3,984.3,516.8,981.6,520.5,978.8z M473.2,965.6c3.8,4.9,7.4,9.7,11.2,14.7
                     c3.5-2.6,6.7-5.1,10.1-7.7c-3.8-5-7.4-9.7-11.2-14.7C479.9,960.5,476.6,963,473.2,965.6z M481.4,982.4
                     c-3.9-5.1-7.5-9.9-11.3-14.9c-3.4,2.6-6.5,5-9.9,7.5c3.8,5,7.5,9.8,11.3,14.9C474.9,987.4,478,985,481.4,982.4z M484.2,1006.6
                     c3.5-2.7,6.7-5.1,10-7.6c-3.8-5-7.4-9.7-10.9-14.4c-3.5,2.6-6.7,5.1-10,7.6C477,997.2,480.5,1001.7,484.2,1006.6z M496.2,974.8
                     c-3.6,2.8-6.8,5.2-10.1,7.7c3.8,5,7.3,9.6,10.9,14.3c3.5-2.7,6.7-5.1,10.1-7.7C503.3,984.2,499.9,979.6,496.2,974.8z
                     M284.5,1197.3c-0.5-0.6-0.9-1.2-1.4-1.8c-2.3,0.2-5.3-0.3-6.7,0.9c-1.3,1.1-1.9,5.3-1,6.1c1.5,1.3,5,2,6.6,1.1
                     C283.6,1202.7,283.8,1199.5,284.5,1197.3z M254.3,1157.9c-0.4-0.6-0.9-1.3-1.3-1.9c-2.3,0.1-5.3-0.6-6.8,0.6
                     c-1.3,1-2.1,5.2-1.2,6.1c1.4,1.4,4.9,2.3,6.5,1.4C253.2,1163.4,253.5,1160.1,254.3,1157.9z M268.8,1177c-0.5-0.6-0.9-1.1-1.4-1.7
                     c-2.3,0.3-5.6-0.3-6.8,1c-1.2,1.3-1.3,5-0.3,6.7c0.6,1.1,4.9,1.4,6.2,0.4C268,1182.2,268.1,1179.2,268.8,1177z M231.6,1135.3
                     c-0.6,0.5-1.2,0.9-1.7,1.4c0.2,2.3-0.4,5.3,0.9,6.8c1.1,1.2,5.2,1.7,6.2,0.7c1.3-1.3,1.9-5,1-6.5
                     C237,1136.1,233.8,1136.1,231.6,1135.3z"/>
                  <path class="st13" d="M410.1,1094.1c-36.7,29.3-73.4,58.7-110.7,88.5c-5.1-5.8-10-11.3-15.2-17.2c1.3-1.2,2.3-2.3,3.5-3.1
                     c32.6-25.4,65.3-50.7,97.9-76.1c2.4-1.9,4-2.1,6.9-0.4C397.9,1089,403.8,1091.2,410.1,1094.1z"/>
                  <path class="st13" d="M267.4,1144.3c-4.3-5.7-8.5-11.2-13.1-17.2c38.8-28.7,77.3-57.2,115.6-85.6c1.4,7.2,2.7,14,3.8,20.8
                     c0.2,1-0.7,2.5-1.5,3.2c-34.4,26.2-68.9,52.3-103.4,78.4C268.5,1144.1,268.1,1144.1,267.4,1144.3z"/>
                  <path class="st13" d="M387.2,1083c-35,26.6-69.8,53.1-105,80c-4-5.2-8.2-10.7-12.5-16.4c34.9-26.6,70-53.3,105-80
                     C378.8,1072,382.7,1077.1,387.2,1083z"/>
                  <path class="st13" d="M483.1,1010.9c4,5.2,7.7,10.1,11.8,15.5c-9.3,7.1-18.5,14.1-27.3,20.8c-1.3-5.9-2.8-12.4-4.2-19
                     c-0.2-0.9,0.3-2.4,0.9-2.9C470.4,1020.5,476.6,1015.8,483.1,1010.9z"/>
                  <path class="st13" d="M468.8,992.2c-6.5,4.9-12.7,9.8-19.1,14.5c-0.8,0.5-2.5,0.4-3.4-0.1c-5.7-2.6-11.2-5.4-16.9-8.3
                     c9-6.9,18.4-14,28-21.3C461.1,982.2,464.8,987,468.8,992.2z"/>
                  <path class="st13" d="M758,773.1c-3.1-4-5.8-7.7-9-11.8c17.4-10.2,33.7-21.5,51.3-30.6c0.3,0.5,0.6,1,0.9,1.5
                     C787,745.7,772.6,759.3,758,773.1z"/>
                  <path class="st13" d="M481.7,1009.1c-6.7,5.1-13,9.9-19.7,15c-3.7-4.9-7.5-9.8-11.3-14.9c6.4-4.9,13-9.9,19.7-15
                     C474.2,999.3,477.7,1004,481.7,1009.1z"/>
                  <path class="st5" d="M726.3,800.1c3.1,4,5.9,7.8,9.1,12c-7.8,6-15.6,11.8-23.5,17.9c-2.9-3.9-5.9-7.8-9.1-12
                     C710.5,812.1,718.2,806.3,726.3,800.1z"/>
                  <path class="st5" d="M728.8,798c8.3-6.3,16.3-12.4,24.6-18.7c2.9,3.8,5.8,7.6,9,11.8c-8.1,6.2-16.2,12.3-24.6,18.7
                     C734.9,806.1,732,802.3,728.8,798z"/>
                  <path class="st5" d="M701.1,815.8c-3.2-4.2-6.1-8-9-11.9c7.9-6,15.6-11.9,23.5-17.9c3.2,4.2,6.1,8,9,11.9
                     C716.6,804,708.8,809.9,701.1,815.8z"/>
                  <path class="st5" d="M713.3,831.8c7.9-6,15.5-11.8,23.4-17.8c2.9,3.8,5.8,7.6,9,11.8c-7.7,5.9-15.5,11.8-23.4,17.8
                     C719.5,839.9,716.6,836.1,713.3,831.8z"/>
                  <path class="st5" d="M763.8,793c2.8,3.7,5.6,7.3,8.6,11.4c-8.2,6.3-16.2,12.4-24.5,18.7c-2.7-3.5-5.6-7.3-8.6-11.4
                     C747.4,805.5,755.5,799.3,763.8,793z"/>
                  <path class="st5" d="M751.8,777.5c-8.3,6.3-16.3,12.4-24.5,18.7c-3-4-5.9-7.8-8.9-11.7c8.2-6.2,16.2-12.3,24.5-18.7
                     C745.9,769.7,748.8,773.5,751.8,777.5z"/>
                  <path class="st5" d="M700.3,819.7c3.3,4.3,6.2,8.2,9.4,12.3c-7.5,5.7-14.7,11.2-22.2,16.9c-3.1-4.1-6.2-8.1-9.4-12.3
                     C685.5,830.9,692.8,825.4,700.3,819.7z"/>
                  <path class="st5" d="M698.9,817.8c-7.5,5.7-14.6,11.2-22.1,16.8c-3.1-4.1-6.2-8.1-9.4-12.4c7.3-5.5,14.6-11.1,22.1-16.8
                     C692.7,809.6,695.6,813.5,698.9,817.8z"/>
                  <path class="st5" d="M698,862.9c-2.9-3.8-5.8-7.6-9-11.8c7.4-5.6,14.7-11.2,22.3-17c2.8,3.7,5.7,7.5,9,11.8
                     C712.9,851.5,705.6,857,698,862.9z"/>
                  <path class="st5" d="M674.4,836.3c-7,5.3-13.7,10.5-20.8,15.8c-3.2-4.3-6.4-8.4-9.7-12.8c6.8-5.2,13.7-10.4,20.8-15.8
                     C668,827.9,671.1,831.9,674.4,836.3z"/>
                  <path class="st5" d="M675.5,881.1c-3.1-4-6.1-8.1-9.4-12.4c7-5.3,13.8-10.5,20.9-15.9c3,3.9,6,7.9,9.4,12.4
                     C689.5,870.4,682.7,875.5,675.5,881.1z"/>
                  <path class="st5" d="M664.5,866.6c-3.2-4.2-6.3-8.2-9.5-12.5c7-5.3,13.8-10.5,20.9-15.9c3.1,4.1,6.2,8.1,9.5,12.5
                     C678.6,855.9,671.8,861.1,664.5,866.6z"/>
                  <path class="st5" d="M641.5,841c3.4,4.5,6.5,8.5,9.7,12.8c-6.4,4.9-12.7,9.7-19.1,14.6c-3.3-4.4-6.5-8.5-9.7-12.8
                     C628.8,850.6,635.2,845.8,641.5,841z"/>
                  <path class="st5" d="M644.8,885c6.5-4.9,12.6-9.6,19.1-14.5c3.1,4.1,6.3,8.3,9.7,12.8c-6.3,4.8-12.6,9.6-19.1,14.5
                     C651.4,893.7,648.3,889.7,644.8,885z"/>
                  <path class="st5" d="M652.8,855.7c3.4,4.4,6.5,8.5,9.8,12.9c-6.3,4.8-12.5,9.5-19.1,14.5c-3.1-4.1-6.4-8.4-9.8-12.9
                     C640,865.5,646.2,860.7,652.8,855.7z"/>
                  <path class="st5" d="M629.5,870.4c-6,4.5-11.7,8.9-17.7,13.5c-3.4-4.5-6.6-8.7-10.1-13.2c5.9-4.5,11.7-8.9,17.7-13.5
                     C622.9,861.6,626.1,865.9,629.5,870.4z"/>
                  <path class="st5" d="M623.1,898.9c-3.2-4.3-6.4-8.4-9.8-12.9c5.9-4.5,11.7-8.9,17.8-13.6c3.2,4.3,6.4,8.4,9.8,12.9
                     C635,889.8,629.1,894.3,623.1,898.9z"/>
                  <path class="st5" d="M582,885.1c5.7-4.3,11.1-8.4,16.8-12.8c3.5,4.6,6.8,8.9,10.2,13.5c-5.4,4.1-10.9,8.3-16.8,12.8
                     C589,894.2,585.6,889.8,582,885.1z"/>
                  <path class="st5" d="M634.5,913.8c-3.1-4-6.3-8.2-9.8-12.8c5.8-4.4,11.7-8.9,17.8-13.5c3,4,6.2,8.2,9.8,12.8
                     C646.4,904.7,640.5,909.2,634.5,913.8z"/>
                  <path class="st5" d="M563.7,898.7c5.2-3.9,10.3-7.8,15.6-11.9c3.5,4.6,6.9,9,10.4,13.7c-5.1,3.9-10.2,7.8-15.6,11.9
                     C570.7,907.9,567.3,903.4,563.7,898.7z"/>
                  <path class="st5" d="M597.6,943.5c-3.5-4.6-6.9-9-10.4-13.7c5.3-4.1,10.4-7.9,15.8-12c3.4,4.5,6.8,8.9,10.4,13.7
                     C608.2,935.4,603.1,939.3,597.6,943.5z"/>
                  <path class="st5" d="M593.8,900.6c6-4.6,11.4-8.7,16.9-12.9c3.4,4.4,6.5,8.6,10,13.1c-5.6,4.2-11.1,8.5-16.9,12.9
                     C600.5,909.4,597.1,905,593.8,900.6z"/>
                  <path class="st5" d="M615.3,929.2c-3.4-4.4-6.5-8.6-10-13.2c5.6-4.3,11.2-8.5,17-12.9c3.2,4.3,6.5,8.5,10,13.2
                     C626.7,920.5,621.3,924.7,615.3,929.2z"/>
                  <path class="st5" d="M585.6,927.9c-3.5-4.6-6.7-8.8-10.1-13.3c5.3-4,10.5-8,15.9-12.1c3.4,4.4,6.6,8.7,10.1,13.3
                     C596.2,919.9,591.1,923.8,585.6,927.9z"/>
                  <path class="st5" d="M585.2,932c3.4,4.4,6.7,8.7,10.3,13.6c-4.9,3.7-9.7,7.3-14.7,11.2c-3.2-4.2-6.6-8.6-10.3-13.6
                     C575.1,939.6,580,936,585.2,932z"/>
                  <path class="st5" d="M546.4,911.6c5-3.8,9.7-7.4,14.6-11.1c3.5,4.6,6.8,9,10.4,13.6c-4.8,3.6-9.6,7.3-14.6,11.1
                     C553.4,920.8,550,916.3,546.4,911.6z"/>
                  <path class="st5" d="M564.5,969.8c-3.5-4.7-7-9.2-10.7-14.1c4.7-3.5,9.1-6.9,13.9-10.6c3.4,4.5,6.9,9.1,10.7,14.1
                     C574,962.6,569.5,966,564.5,969.8z"/>
                  <path class="st5" d="M554.3,927.3c-4.6,3.5-9.1,6.9-13.9,10.5c-3.5-4.7-7-9.2-10.7-14.1c4.5-3.4,9-6.9,13.9-10.5
                     C547.2,918.1,550.7,922.6,554.3,927.3z"/>
                  <path class="st5" d="M568.7,941.1c-3.4-4.5-6.7-8.8-10.3-13.5c4.8-3.7,9.6-7.3,14.7-11.2c3.3,4.4,6.7,8.8,10.3,13.5
                     C578.5,933.6,573.8,937.2,568.7,941.1z"/>
                  <path class="st5" d="M541.9,940c4.7-3.6,9.2-7,14-10.6c3.5,4.6,6.9,9.1,10.5,13.8c-4.7,3.5-9.2,7-14,10.6
                     C549,949.3,545.5,944.7,541.9,940z"/>
                  <path class="st5" d="M527.2,925.4c3.7,4.8,7.2,9.4,10.8,14.2c-4.4,3.4-8.7,6.7-13.2,10.1c-3.7-4.8-7.2-9.4-10.8-14.2
                     C518.4,932.1,522.7,928.8,527.2,925.4z"/>
                  <path class="st5" d="M551.9,957.7c3.6,4.7,7,9.2,10.7,14.1c-4.3,3.3-8.6,6.5-13.2,10c-3.3-4.3-6.9-9-10.7-14.1
                     C543,964.5,547.3,961.2,551.9,957.7z"/>
                  <path class="st5" d="M537.1,965.7c-3.5-4.6-6.9-9.1-10.6-13.9c4.3-3.3,8.6-6.5,13.1-10c3.5,4.5,6.9,9.1,10.6,13.9
                     C545.8,959.1,541.6,962.3,537.1,965.7z"/>
                  <path class="st5" d="M522.3,951.6c-3.8,2.9-7.6,5.8-11.6,8.8c-3.6-4.8-7.2-9.4-10.9-14.4c3.8-2.9,7.6-5.8,11.6-8.8
                     C515,942,518.5,946.6,522.3,951.6z"/>
                  <path class="st5" d="M547.3,984.4c-4,3.1-7.7,5.9-11.6,8.9c-3.7-4.8-7.3-9.6-11.1-14.6c3.9-2.9,7.6-5.8,11.6-8.9
                     C539.9,974.5,543.4,979.2,547.3,984.4z"/>
                  <path class="st5" d="M512.2,962.5c4-3,7.7-5.9,11.7-8.9c3.6,4.8,7.1,9.3,10.8,14.1c-3.9,3-7.7,5.8-11.7,8.9
                     C519.5,972,516.1,967.5,512.2,962.5z"/>
                  <path class="st5" d="M533.2,995.4c-3.6,2.8-7.1,5.4-11,8.4c-3.5-4.7-7.2-9.5-11.1-14.6c3.5-2.7,7.1-5.4,11-8.4
                     C525.7,985.4,529.4,990.3,533.2,995.4z"/>
                  <path class="st5" d="M508,962.3c-3.9,3-7.4,5.7-11.1,8.5c-3.7-4.8-7.2-9.4-10.9-14.4c3.6-2.8,7.2-5.5,11.1-8.5
                     C500.7,952.7,504.3,957.4,508,962.3z"/>
                  <path class="st5" d="M497.3,1023.7c-3.9-5.1-7.6-10-11.5-15.1c3.3-2.5,6.4-4.9,9.9-7.5c3.7,4.9,7.5,9.8,11.5,15.1
                     C504,1018.6,500.8,1021,497.3,1023.7z"/>
                  <path class="st5" d="M519.9,1006.1c-3.4,2.6-6.6,5-10,7.6c-3.7-4.9-7.5-9.9-11.4-15c3.4-2.6,6.6-5,10-7.6
                     C512.3,996.1,516,1000.9,519.9,1006.1z"/>
                  <path class="st5" d="M520.5,978.8c-3.7,2.8-7.2,5.5-11,8.4c-3.6-4.7-7.1-9.3-10.8-14.2c3.5-2.7,7.1-5.4,11-8.4
                     C513.2,969.2,516.7,973.7,520.5,978.8z"/>
                  <path class="st5" d="M473.2,965.6c3.4-2.6,6.6-5.1,10.1-7.7c3.8,5,7.4,9.7,11.2,14.7c-3.4,2.6-6.6,5-10.1,7.7
                     C480.6,975.3,477,970.5,473.2,965.6z"/>
                  <path class="st5" d="M481.4,982.4c-3.4,2.6-6.5,5-9.9,7.5c-3.8-5.1-7.5-9.9-11.3-14.9c3.3-2.5,6.4-4.9,9.9-7.5
                     C473.9,972.5,477.5,977.3,481.4,982.4z"/>
                  <path class="st5" d="M484.2,1006.6c-3.7-4.9-7.2-9.5-10.9-14.4c3.3-2.5,6.5-5,10-7.6c3.6,4.7,7.1,9.4,10.9,14.4
                     C490.9,1001.5,487.7,1003.9,484.2,1006.6z"/>
                  <path class="st5" d="M496.2,974.8c3.7,4.9,7.2,9.4,10.9,14.3c-3.4,2.6-6.6,5-10.1,7.7c-3.5-4.6-7.1-9.3-10.9-14.3
                     C489.3,980,492.5,977.5,496.2,974.8z"/>
                  <path class="st14" d="M284.5,1197.3c-0.8,2.2-0.9,5.5-2.5,6.3c-1.6,0.9-5.1,0.2-6.6-1.1c-0.9-0.8-0.3-5,1-6.1
                     c1.4-1.2,4.4-0.7,6.7-0.9C283.6,1196.1,284.1,1196.7,284.5,1197.3z"/>
                  <path class="st14" d="M254.3,1157.9c-0.9,2.2-1.2,5.4-2.7,6.2c-1.6,0.8-5.2,0-6.5-1.4c-0.9-0.9-0.1-5.1,1.2-6.1
                     c1.5-1.2,4.5-0.5,6.8-0.6C253.5,1156.7,253.9,1157.3,254.3,1157.9z"/>
                  <path class="st14" d="M268.8,1177c-0.7,2.2-0.8,5.2-2.3,6.4c-1.3,1-5.5,0.7-6.2-0.4c-1-1.7-1-5.4,0.3-6.7c1.2-1.3,4.4-0.7,6.8-1
                     C267.8,1175.8,268.3,1176.4,268.8,1177z"/>
                  <path class="st14" d="M231.6,1135.3c2.2,0.7,5.5,0.8,6.4,2.3c0.9,1.6,0.3,5.2-1,6.5c-1,1-5.1,0.6-6.2-0.7
                     c-1.2-1.4-0.7-4.4-0.9-6.8C230.4,1136.3,231,1135.8,231.6,1135.3z"/>
               </g>
               <g id="UKE-WOODGRAIN" class="st15">
                  <path class="st5" d="M405,1185.6L405,1185.6c0.7-0.5,1.3-1.7,0-1.3C404.3,1184.9,403.7,1186,405,1185.6z"/>
                  <path class="st5" d="M256.1,1217.7c3.8-3.8,7.6-7.2,11.1-10.5c-0.8-1.2-1.5-2.5-2.3-3.7c-7.2,8-15.4,15.3-22.9,23
                     c-2.5,2.6-10,9.1-9.7,11.3c0.1,0.7-1.5,0-1.3-0.2c-5.9,6.8-10.7,15.2-18.8,20.8c-2,3-4.3,5.8-6.7,8.6c-0.1,0.1-0.3,0.3-0.5,0.4
                     c0.9,0.9,1.8,1.8,2.7,2.6c9.1-9.1,17.8-18.3,25.8-27C241.3,1234.6,248.2,1225.6,256.1,1217.7z"/>
                  <path class="st5" d="M412.3,1176.4c-0.7,0.5-1.3,1.7,0,1.3l0,0.1C413,1177.2,413.6,1176,412.3,1176.4z"/>
                  <path class="st5" d="M407,1191.5c0-0.4-1.3-0.8-1.3-0.2l0,0.1C405.8,1191.7,407.1,1192.2,407,1191.5z"/>
                  <path class="st5" d="M405.9,1197.6c0-0.3-1.3-0.8-1.3-0.2l0,0C404.7,1197.9,405.9,1198.3,405.9,1197.6z"/>
                  <path class="st5" d="M424.5,1164.2l-6.1,5.5l0,0.1c-1,2-6.5,3.3-3.6,5.8c2.9-1.7,2.8-3.8,4.9-5.7l3.7-3.3
                     C424.3,1166.1,427,1163.1,424.5,1164.2z"/>
                  <path class="st5" d="M549.7,1029.8c0.1-0.1,0.2-0.2,0.3-0.3c-0.1-0.3-0.2-0.5-0.3-0.8C548.8,1029.5,547.7,1030.3,549.7,1029.8z"
                     />
                  <path class="st5" d="M432.8,1173.5c-0.5,0.7-0.9,1.5-1.1,2.2C432,1175,432.4,1174.2,432.8,1173.5z"/>
                  <path class="st5" d="M524.7,1027.8c0,0.4,1.3,0.8,1.3,0.2l0,0C526,1027.6,524.7,1027.1,524.7,1027.8z"/>
                  <path class="st5" d="M420.7,1189.5L420.7,1189.5c-0.9,0.8-2.2,2.3-0.6,1.8C421,1190.6,422.3,1189.1,420.7,1189.5z"/>
                  <path class="st5" d="M423.8,1192.1c0.4-0.8,0.8-1.7,1.2-2.5c-3.5,1.7-3.1,4.1-6.7,4.7C419.6,1196.1,422.2,1194.1,423.8,1192.1z"
                     />
                  <path class="st5" d="M525.9,1025.4L525.9,1025.4c0.4-0.3,0.2-1.2-0.6-0.7C524.9,1025,525.1,1025.9,525.9,1025.4z"/>
                  <path class="st5" d="M547.3,1032c0.6-0.5,1.2-1.7,0-1.3C546.6,1031.3,546,1032.5,547.3,1032z"/>
                  <path class="st5" d="M424.4,1186.2L424.4,1186.2c-0.9,0.5-3.7,3.6-1.2,2.4C424.1,1188.1,426.8,1185.1,424.4,1186.2z"/>
                  <path class="st5" d="M409.7,1200.6L409.7,1200.6c0,0.4,1.3,0.9,1.3,0.2C411,1200.5,409.7,1200,409.7,1200.6z"/>
                  <path class="st5" d="M411.4,1188.9c0-0.4-1.3-0.8-1.3-0.2l0,0C410.1,1189.1,411.4,1189.6,411.4,1188.9z"/>
                  <path class="st5" d="M412,1192.1c0.3-0.3,0.2-1.2-0.6-0.7l0,0.1C411.1,1191.8,411.2,1192.7,412,1192.1z"/>
                  <path class="st5" d="M408,1180.3L408,1180.3c0,0.4,1.2,0.9,1.2,0.2C409.3,1180.1,408,1179.6,408,1180.3z"/>
                  <path class="st5" d="M266.3,1034.6c-2.6,4.2-4,8.7-5.8,13.1c4.1-4.5,8-8.9,11.8-13.3c15.8-15.3,29.5-30.9,43.5-46.4
                     c-2.9,1.1-5.9,2.1-9.1,2.8c-6,7.4-13,14-19.6,21.1C280.2,1019.4,273.7,1027.5,266.3,1034.6z"/>
                  <path class="st5" d="M409,1193.6c-1,0.4-3.7,3.5-1.2,2.4l0,0.1C408.7,1195.6,411.4,1192.5,409,1193.6z"/>
                  <path class="st5" d="M538.6,1102c1.2-1.7,2.4-3.4,3.5-5.1c-6.7,5.4-13.3,10.9-20.3,15.9c-6.2,4.5-12.5,9.5-18.7,14.7
                     C517.1,1122.4,529.4,1114.5,538.6,1102z"/>
                  <path class="st5" d="M414.4,1189.9c0.1-2.5,5.1-4.4,4.8-6.9c-0.6,0.6-1.8,1.1-1.2-0.1c7.8-6.5,13.9-13.2,20-19.9
                     c0.4-0.9,0.9-1.7,1.3-2.6c0.2-0.3,0.3-0.6,0.5-1l-9.6,8.7c-8.6,5.9-16.8,12.4-24.5,19.4c-0.3,0.6-1.2,1.1-0.6,1.8l7.3-6.6
                     c4.6-2.6,10.5-8.1,16.5-13.6l1.3,1.4c-6.1,6-16.6,14.1-18.9,17c1.5,0.9,2.8-2.7,4.3-1.3l0,0.1
                     C414.6,1187.5,414,1188.7,414.4,1189.9z"/>
                  <path class="st5" d="M827.5,770.4c-0.9,0.2-1.7,0.2-2.5,0.2c-7.1,5.2-14.1,10.3-21,15.2c-2.2,2.4-4.6,1.8-6.7,4.8
                     c-2.7,0.4-2.7,2-3.7,2c-0.5,0-1.3-0.7-1.9,0.4c-15,13.4-31.7,25.3-45.9,36.1c-4.6,3.5-10,6.4-14.1,10.1c-4.4,4-7.6,8.6-12.2,12.2
                     c-3.6,2.9-7.8,4.9-11.7,7.9c-4.2,3.2-7.9,7.7-12.2,11c-6.1,4.6-13.2,8-19.7,12.5c-3.7,2.6-7.4,5.1-11,7.6
                     c-2.4,1.9-4.8,3.9-7.2,5.9c-1.7,1.4-2.9,3.4-4.7,5.7c-3.7-0.2-18.1,11-19.2,15.8c-0.7,0-1.6,0.3-2.6,0.7
                     c-3.4,3.7-6.6,7.6-9.6,11.9c-18.8,19.1-37.8,30.4-56.8,43.4c0,0.1,0,0.1,0,0.2c-0.1,0-0.2,0-0.2,0c-2.2,1.5-4.4,3.1-6.6,4.6
                     c-1.4,0.3-2.7,0.6-4.1,0.8c-2.5,2.5-4.2,5.5-3.9,7.9c-6.2-3.7-8.3,2.9-12.1,5c-1.4,0.8-1.7,3.3-2.7,5.4c-6.2-2.3-8.2,5.2-13.1,7
                     c0.5,1.7,0.9,3,1.6,5.5c-2.1-1.3-3.2-1.9-4.4-2.7c-3.1,2.4-6.3,4.8-9.4,7.2c0.5,1.6,0.9,3.1,1.7,5.9c-2.4-1.4-3.7-2.2-5.3-3.1
                     c-2.9,2.5-6,5-8.9,7.5c0.3,0.8,0.6,1.5,0.8,2.2c0.6-0.4,1.7-0.8,2.5,0.4c-0.5,0.4-1.1,0.8-1.8,1.3c0.1,0.3,0.2,0.6,0.4,1
                     c-0.3,0.3-0.7,0.6-1,0.8c-0.3-0.2-0.6-0.4-0.9-0.7c-3.9,2.7-9.3,6.6-11.2,10.7c7.6-2.5,15-16,22.7-16.5
                     c-4.2,3.6-8.4,7.3-9.7,11.3c7-4.9,13.8-16.1,20.9-13.6c-4.9,5.2-13.1,10-15.8,15.5c3.6-2.3,11.5-8.3,15.3-11.2
                     c3.9-2.9,6.9-7.5,11.1-6.1c-0.7,1.4-4.4,2.4-3,4c3.3,0.7,10-7,12.9-7.7c1.8,3.4-4.4,5.7-6.7,8.6c-2.2,2.8-7.7,5.3-7.9,8.4
                     c1-0.7,2-2.3,3.1-1.5c-2.4,3.2-4.9,2.4-7.4,5.3c-1.7,1.5,3.2,0.6,0.1,2.5c-2-0.9-0.5-0.6-2.5-0.3c-0.9,1.8-2.9,3.4-1.7,5.4
                     c6.4-6,13.5-12.7,19.5-18.8c3.1-3.2,5.8-6.8,8.5-9.6c0,0,0-0.1,0-0.1c-2-4.5-4.4-8.9-6.8-13.6c7.7-6.4,15.3-12.6,22.8-18.9
                     c37.3-30.7,74.6-61.4,111.9-92.1c34.6-28.5,69.3-56.9,103.8-85.4c9.6-7.9,19.8-13.9,32.6-14.9c6.6-0.5,12.4-3.1,17.8-7.1
                     c10.4-7.5,21.2-14.4,31.8-21.6c26.1-17.6,52.2-35.2,78.2-53c7.3-4.9,7.2-5.3,1.8-12c-3.3-4-6.4-8.1-9.2-12.4l-1.1,1
                     c-0.5,0.9-3.9,3.3-2.5,0.9l2.4-2.2c-17.9,13.5-20.9,18.9-37.3,32.2c-2.2-1.5,3.3-2.1,1.2-3.6c-0.7,0.7-1.5,1.4-2.2,2.2
                     c1,1.5,1.4,3.4,1.1,5.5c-0.5,3.9-2.8,6.4-6.8,6.9c-2.5,0.3-4.4-0.2-6-1.4c-14.6,13.3-29.9,25.6-45.2,37.2
                     C833.1,767.5,831,769.8,827.5,770.4z M528.6,1030.8c0-0.6,1.2-0.2,1.2,0.2C529.8,1031.6,528.6,1031.2,528.6,1030.8z"/>
                  <path class="st5" d="M273.2,1013.1c-4.7,4.9-9.2,9.2-14.1,13.8c-8.9,8.7-16.9,18.3-25.6,26.8c-9.1,9-17.8,17.5-26.2,26.1
                     c-26.3,20.8-52.5,44.5-78.7,68.1c1.1,5.2,2.5,10.4,4.4,15.6c17.3-15.7,36.1-31.3,54-47.3c0.5-0.4,0.9-0.8,1.4-1.3c0,0,0,0,0,0
                     c0-0.1,0.1-0.2,0.2-0.2c14.5-13.1,28.4-26.3,42.9-38.6c9.6-8.1,18.2-16.8,26.4-25.7c1.3-5.4,2.8-10.7,6.4-15.8
                     c6.7-5.6,11.3-13.3,17.2-19.4c8.5-8.8,16.1-15.9,23.7-24.2c-1.3,0.3-2.6,0.5-3.9,0.8c-3,0.5-6.1,1-9.1,1.6
                     C285.5,1001.2,279.1,1006.8,273.2,1013.1z M161.7,1138.1c-0.7,0.3-0.3-0.4-0.8-0.9C161.5,1136.9,162.1,1137.8,161.7,1138.1z"/>
                  <path class="st5" d="M147.1,1192.1c4.8-5.4,9.9-10.8,15.2-16.3c7.1-7.5,15.7-13.5,23.2-20.9c7.4-7.3,15.3-13.6,22.9-20.5
                     c-1.9-3.7-1.2-7.2,2-12.2c6-9.2,14.9-13.3,25.5-13.5c10.4-9.6,21.7-19.6,33.2-28.9c38.5-31.2,69.4-72,108.7-101.5
                     c-15.2,15.9-26.8,25.6-42.1,40.4c15.3-11,32.1-27.7,44-39.5c1.1,3.1-1.9,5.6-4.8,8.2c-3.9,2.9-2.6,4.8-6.1,5.5
                     c-19.8,16.2-36,35.9-51.8,51.7c-20.6,20.5-41.5,36.7-61,56.1c-2.3,2.3-4.7,4.4-7.3,6.6c-2.1,1.8-4,3.5-5.9,5.2
                     c0.2,0.2,0.3,0.4,0.5,0.6c1.9,2.6,3.6,5.4,5.3,8.3c1.5-1.4,3.1-2.7,4.7-4c13.6-11.1,26.7-21.9,39.1-32.6c2-1.7,2.9-4,4.8-5.6
                     c4.9-4.2,10.1-8,15.3-12.5c10.2-8.7,20.2-18.2,31.1-28c1.9,3-3.6,5-5.4,7.5c2.1,1,4.1-3.2,6.1-4.2c1.8-1.8,0-4.2,2.4-6l15.3-13.7
                     c2-3.4,4.2-0.2,6.2-3l11-9.9c-0.4,4.6-4.8,6.4-7.9,9.7c-9.2,9.6-19.9,19.6-30.5,28.7l-3.1,2.7c-0.8,0.8-2.5,1.9-1.9,0.4l1.8-1.7
                     c0.6-0.6-0.3-1.4-1.3-0.2c-0.6,0.8-1.8,1.4-1.2,2.4c0.1,2.2-2.6,1.2-4.3,3.8c-22.4,21.3-47.4,42.8-72.5,65.1
                     c36-26.6,72.1-53.2,108.1-79.9c1.8-1.3,3.3-3.7,3.9-5.8c5.6-22.5,26.1-37.8,49.2-36.7c1.5,0.1,3.1-0.3,4.6-0.8
                     c16.6-15,33.1-29.9,49.6-44.5c12.1-10.7,25.4-19.2,38-29c12.6-9.7,24.7-20.3,38-29c4.7-1.3,9.4-6.6,14.1-8.8
                     c2.2-2.8,4.5-3.1,6.7-4.8c2.5-1.5,4.8-6.3,7.4-4.1c-5.7,4.7-11,9.9-15.8,15.5c-7.7,3.4-12.9,10.1-19.6,15c-2.1,1.5-4.7,2-6.8,3.5
                     c-1.2,0.9-0.7,1.9-1.8,2.9c-0.8,0.7-2.9,1.3-3.7,2c-6.8,5.6-13.5,11.2-20.2,16.8c5.2-0.8,13.5-6.9,14.4-11.1
                     c4.3,0.2,15.3-7.9,16.3-12.1c5.3-0.4,16.7-8.9,17.1-12.4c2.4-0.9,5-1.2,6.6-2.7c4.1-3.7,10.4-5.1,12.5-11.3c0.4-1.1,3.8-1,5.4-2
                     c3.1-1.9,5.7-4.7,8.9-6.4c3.5-1.9,4.6-6.2,9.2-7.4c4.1-1,7.5-4.8,11-7.7c1.9-1.6,3.2-4,5.2-5.5c1.5-1.1,4-1.1,5.4-2.3
                     c3-2.4,5.9-5,8.8-7.6c7.8-7,15.4-14.3,23.2-21.6c4.4-4.1,8.7-8.2,13.1-12.4c-12.3,8.7-24.7,17.4-37,26.1c0,0-0.1,0.1-0.1,0.1
                     c0,0,0,0-0.1,0c-46.3,32.6-92.5,65.2-138.7,97.8c-2.8,2-4.2,1.4-6.7-0.6c-4.1-3.3-8.1-6.7-12.3-10c-1.8,1.4-3.5,2.7-5.3,4
                     c1.3-1.6,2.8-3.2,4.3-4.8c-4.3-3.3-8.7-6.5-13.3-9.1c-1.5-0.9-3.1-1.6-4.7-2.4c-11.4,10.8-22.9,21.6-35.1,33.2
                     c3-9.3,12.8-14.6,20.1-21.9c4.1-4.1,8.4-8.2,12.7-12.4c-13-5.7-26.7-7.5-39.8-5.7c-10.2,9.6-20.6,17.6-30.1,26.9
                     c-0.3,0.2-1.8,1.6-3.1,2.7c-0.9,0.7-2,1.2-3.1,1.5c0.4,2.8-10.6,7.3-14,12.6c-2.4-2.3,1.7-4.1,2.4-4.7
                     c12.7-12.8,30.1-25.6,43.7-38.4c-15,2.9-29.1,10.5-40.7,22.4c-9.1,9.3-15.7,20.3-21.1,32c-2.4,5.2-5.3,9.7-8.5,13.6
                     c0.2,0.1,0.3,0.2,0.5,0.4c-10.6,10.8-21,21.8-31.5,32.8c-2.8,3.7-5.8,7.5-9.7,11.1c-2.5,3.4-4.3,6.9-7.8,10.2
                     c0.9,1.4-1.8,2.4-1.2,3.8l-7.6,6.8c0-0.1-0.1-0.1-0.1-0.2c0.3-1,0.3-2.1-0.1-3.1c0.9-0.7,1.4-1.5,1.8-2.4
                     c-6.7,7-13.5,14.1-20.4,21c0,0.5,0.1,0.9,0.2,1.4l-2.9,2.6c-0.2-0.3-0.4-0.5-0.7-0.6c-11,11-22.4,21.8-34.4,32.3
                     c-20.7,18.1-40.1,37.4-60.4,55.6c-9.3,8.3-18.3,16-27.1,23.8c1.5,3.6,3.1,7.2,5,10.7C142.3,1183.8,144.6,1188,147.1,1192.1z
                     M366.2,1018.3c0-0.6,1.2-0.2,1.3,0.2C367.4,1019.1,366.2,1018.7,366.2,1018.3z M368,1016.7c0-0.7,1.3-0.2,1.3,0.2
                     C369.3,1017.5,368,1017,368,1016.7z M595.7,863.5c0,0.6-1.2,0.2-1.3-0.2C594.4,862.7,595.7,863.2,595.7,863.5z M590.8,866.7
                     c0,0.6-1.2,0.2-1.3-0.2C589.5,865.8,590.8,866.3,590.8,866.7z M588.3,867.6c0.8-0.5,1,0.4,0.6,0.7
                     C588.1,868.8,588,867.9,588.3,867.6z M390,999.4c-0.8,0.5-1-0.4-0.6-0.7C390.2,998.2,390.4,999.1,390,999.4z M457.1,934
                     c0.6-0.5,1.3,0.4,1.9,0.9C458.3,935.4,457.7,934.5,457.1,934z M458.9,933.6c0-0.6,1.2-0.2,1.3,0.2
                     C460.2,934.5,458.9,934,458.9,933.6z M462,932.2c-0.8,0.5-1-0.4-0.6-0.7C462.2,931,462.4,931.9,462,932.2z M464.4,928.7
                     c1.6-0.4,0.3,1.1-0.6,1.8C462.2,931,463.5,929.4,464.4,928.7z M463.7,925.5c0.2,2.3-4.8,6-6.7,6
                     C456.8,929.2,461.8,925.5,463.7,925.5z M456.5,935.8c0.8-0.5,1,0.4,0.6,0.7C456.3,937,456.2,936.1,456.5,935.8z M455.3,936.9
                     c-18.5,20.7-38.2,37-57.3,55.3c-1.3-3.2,2.3-4.5,3.6-5.8c4.5-4.5,11.5-11.2,15.8-15.5c0.5-0.5,1.2,0.2,1.2,0.2
                     c11-11.1,22.8-22.1,34.1-33.2C453.6,938.6,454.5,937.5,455.3,936.9z M438.1,948.5l1.8-1.6c0.9,0.7,1,1.4,0.7,2
                     c-26,22.3-42,40.9-67.1,62.9c-1.5-3.3,1.8-2,2.4-4.7C396.9,987.6,415.9,967.9,438.1,948.5z M383.2,977.2c0-0.6,1.2-0.2,1.3,0.2
                     C384.5,978,383.3,977.5,383.2,977.2z M394.8,965.5c2.2-5.9,10.6-11.7,17.6-18.4c0.8,0.7,1,1.4,0.7,2
                     C405.7,954,402.2,962.6,394.8,965.5z M395.9,955.6c13.3-13.3,22.1-19.7,35.4-33.1c0.3,0.9,0.6,1.8,0.1,2.5
                     c-9,9.1-18.3,18.1-28.7,27.1c-2.7,1.5-1,3.6-3,5.3c-3.2,3.1-7.8,6.1-9.1,9.5l-4.9,4.4c-1.6,0.7-3.2,5-4.9,1.9
                     C386.8,967.4,393.4,961.7,395.9,955.6z M362.8,962.3l0.5,0.5l-4.7,4.3C359.9,965.5,361.3,963.9,362.8,962.3z M361.3,960.6
                     l0.8,0.9c-3.1,3.7-6.5,7.3-9.6,11l-4.4,4C352.1,971.1,356.9,965.9,361.3,960.6z M344.9,964c0,0.6-1.2,0.2-1.2-0.2
                     C343.6,963.2,344.9,963.6,344.9,964z"/>
                  <path class="st5" d="M856.4,670.7c3.3,3.5,2.7,8.7-0.8,12C839.3,697.4,823,712.1,807,727c-15.4,14.3-30.5,28.9-45.7,43.3
                     c-1.3,1.3-2.5,2.6-4,4.1c0.4,0.5,0.8,1.1,1.2,1.6c2.3-2,4.6-4,7.1-6c5.4-4.4,11.5-8.3,17.2-12.8c40.8-33,80.6-71,120.2-108.6
                     c-3.9-3.6-7.4-7.7-10.5-12.1c-12.3,11.1-24.3,22.5-36.4,33.8C856.1,670.5,856.3,670.6,856.4,670.7z"/>
                  <path class="st5" d="M382.9,1200.3L382.9,1200.3c0,0.4,1.2,0.8,1.2,0.2C384.2,1200.1,382.9,1199.6,382.9,1200.3z"/>
                  <path class="st5" d="M441.8,1133.2c4.2-1.5,8.2-3.8,11.7-6.6c1.9,1.1,3.7-2.2,5.6-1.2c1.7,1.8,3.3-0.4,4.9-1.9
                     c3.5-0.8,7.1-0.2,10.6-1.8c3.4-3.7,7-1.6,10.5-4.3c1.7,0.4,3.3-0.5,4.9-1.9c1.7,0.1,3.4,3.7,5,0.7c4.1-2.3,8.4-4.1,13-5.2
                     c4.2-5.5,8.6-3.9,12.9-7.7c8.7-3.8,17.2-12.6,25.8-16.7c0.5-0.4,1-0.7,1.4-1.1c4.8-11,6.7-22.3,6-33.7
                     c-19.3,12.6-38.7,22.5-58,32.6l-10.4,9.3c-5.8,1.7-11.5,4.7-17.3,6.5c-18.9,10.9-37.6,28.7-56.4,40.4l-22.6,20.3
                     c-4.1,2.4-1.5,5.7-6.1,8l-6.7,6c0.7,0.7,1.2-0.1,1.9-0.4c0,3.7,2.6,0,4.3-1.3c1.6-3.1,3.4,1.2,4.9-1.9l10.4-9.3
                     c0.9,0.9,4.8-3.5,3.7-0.8c1.7,0.3,3.3-0.5,4.9-1.9C418.5,1149.6,430.3,1145,441.8,1133.2z"/>
                  <path class="st5" d="M403.9,1189.2c-2.7-0.1-5.3,4-8,5.9c-0.3,0.3-0.1,1.2,0.6,0.7l6.7-6l0,0.1
                     C403.6,1190.2,404.5,1190.1,403.9,1189.2z"/>
                  <path class="st5" d="M391,1196.9L391,1196.9c0,0.4,1.3,0.9,1.3,0.2C392.2,1196.7,391,1196.3,391,1196.9z"/>
                  <path class="st5" d="M392.8,1195.3L392.8,1195.3c0,0.4,1.3,0.9,1.2,0.2C394,1195.1,392.8,1194.6,392.8,1195.3z"/>
                  <path class="st5" d="M401.5,1195.1L401.5,1195.1c0,0.4,1.3,0.9,1.3,0.2C402.8,1195,401.5,1194.5,401.5,1195.1z"/>
                  <path class="st5" d="M402.6,1187.8c0-0.4-1.3-0.8-1.2-0.2l0,0.1C401.3,1188,402.6,1188.4,402.6,1187.8z"/>
                  <path class="st5" d="M398.9,1191c0.9-0.4,3.7-3.5,1.2-2.4l0,0.1C399.2,1189.2,396.4,1192.2,398.9,1191z"/>
                  <path class="st5" d="M547.3,1030.7C547.3,1030.7,547.3,1030.7,547.3,1030.7C547.3,1030.7,547.3,1030.7,547.3,1030.7L547.3,1030.7
                     z"/>
                  <path class="st5" d="M202.7,1201.2c12.3-11,24.6-22.1,36.6-33c-5-6-10.2-11.8-15.6-17.4c-2,1.2-3.9,2.5-5.9,3.3
                     c-19.5,18.8-41.8,36-61.2,53.5c4.6,7,9.4,13.9,14.5,20.6C181.7,1219.4,192.4,1210.5,202.7,1201.2z"/>
                  <path class="st5" d="M269.6,1232.6c2.7-3.6,5.5-7.3,8.4-10.9c-2.5-0.4-3.7-2.6-7.7-9.4c-0.4-0.7-0.8-1.3-1.2-2
                     c-13.9,15.2-28.7,30.4-41.4,45.8l-6.1,5.5c-1.8,2.5-4,4.9-5.4,7.5c-1.5,1.5-3.1,3.1-4.7,4.6c4.3,4,8.8,7.7,13.5,11.1
                     C240,1267.3,253.2,1249.8,269.6,1232.6z"/>
                  <path class="st5" d="M368.5,1172L368.5,1172c0,0.4,1.3,0.8,1.3,0.2C369.8,1171.8,368.5,1171.3,368.5,1172z"/>
                  <path class="st5" d="M209.6,1254.3c1.5-1.6,4-2.3,5.5-3.7c1.7-1.6,2.9-3.6,4.3-5.1c9.9-10.8,18.3-20.8,28-30.3
                     c5.1-5.1,10.7-9.5,15.5-15c-2.5-3.8-5-7.6-7.6-11.3c-6.4,6.5-12.9,13.1-19.3,19.8c-14.5,15-29.7,29.2-44.9,43.7
                     c3.5,3.9,7,7.7,10.7,11.4C204.7,1260.8,206.8,1257.3,209.6,1254.3z"/>
                  <path class="st5" d="M253.2,1186c-0.3-0.5-0.7-0.9-1-1.4c-20.7,21.7-42.5,42.5-65,63.4c0.7,0.8,1.4,1.6,2.1,2.5
                     C211.7,1229.5,232.7,1207.8,253.2,1186z"/>
                  <path class="st5" d="M245.3,1175.6c-1-1.2-1.9-2.4-2.9-3.6c-22.6,20.4-45.5,39.9-68.4,60.1c0.6,0.8,1.3,1.7,1.9,2.5
                     C199.1,1215.2,222.2,1195.3,245.3,1175.6z"/>
                  <path class="st5" d="M344.9,1229.3l-11,9.9c-1,0.4-3.7,3.5-1.2,2.4l12.8-11.5C345.9,1229.7,345.7,1228.8,344.9,1229.3z"/>
                  <path class="st5" d="M249,1180.4c-0.8-1-1.6-2.1-2.4-3.1c-16.7,21.9-42.2,42.7-65.4,63.8c0.8,0.9,1.5,1.8,2.3,2.7
                     c11.8-10,24.9-20.7,38.7-34.4C231.5,1200.3,239.8,1189.9,249,1180.4z"/>
                  <path class="st5" d="M255.2,1259.7c11.6-12.7,23.9-25.5,33.9-39.1c-0.5,0.1-0.9,0.1-1.4,0.2c-2.5,0.2-4.4,0.5-5.9,0.7
                     c-0.2,0.2-0.5,0.5-0.7,0.7c-0.8,1-0.8,2.9-1.8,4.2c-2.1,2.7-5,5.2-7.3,7.9c-11,13.1-22.9,26.7-33.4,39
                     c-3.7,4.3-7.2,8.9-10.8,13.5c1.4,0.9,2.9,1.9,4.3,2.7c6.5-8.4,12.9-16.8,20.6-25C253.4,1262.7,254.2,1261.1,255.2,1259.7z"/>
                  <path class="st5" d="M346.4,1288.6c-3.4,1.1-1.1-1-1.3-2.7c-3.3,2.8-6.6,6.4-8.5,7.7c-1,0.6-2,1.1-3.1,1.5
                     c-0.6,0.4-1.1,0.9-1.7,1.3c6.7-2.6,13.2-5.9,19.4-9.7C349.5,1286,347.9,1286.9,346.4,1288.6z"/>
                  <path class="st5" d="M915.9,658.1c-3.5-1.9-6.7-4.1-9.6-6.6c-35.8,32.7-68.4,64.6-103.8,93.2c-12.2,9.9-23.7,21.2-36.1,31.1
                     c-1.6,1.3-3.3,2.6-4.9,4c1.6,2.1,3.3,4.3,5,6.6c4.6-2.8,9.1-5.5,13.6-8.3c31.2-19.6,62.3-39.2,93.4-58.9c1.2-0.8,2.4-1.8,3.3-3
                     c1.5-1.9,3.1-3.1,5-3.6c14.5-12.8,28.8-25.5,42.4-37.6c-1.7-3.7-3.1-7.7-4-11.8C919.7,661.3,917.7,659.1,915.9,658.1z"/>
                  <path class="st5" d="M174.2,1101.9c10.3-8.4,20.8-17.1,30.5-26.2c9.8-9.1,20.1-18.1,29.3-27.6c3.2-3.4,6-7.5,9.1-10.8
                     c12.3-13,28.3-26.7,39-38.9c1.1-1.2,2.2-2.5,3.3-3.8c-17.5,3.3-34.8,6.9-52.2,10.5c-28,5.8-51.9,19.3-72.1,39.5
                     c-26.7,26.6-38.5,61.5-33.7,96.1C142.5,1127.4,158.4,1114.6,174.2,1101.9z"/>
                  <path class="st5" d="M372.6,1240.5c-4.1,4.4-11.3,6.4-10.4,10.6c3.2-1.7,6.1-3.8,8.6-6.4C369.9,1243.4,375.7,1239.9,372.6,1240.5
                     z"/>
                  <path class="st5" d="M329.2,1248.6c0.7-0.3,1.5-1.4,2.2-2.2C330.7,1247.1,330,1247.9,329.2,1248.6L329.2,1248.6z"/>
                  <path class="st5" d="M321.8,1255.2L321.8,1255.2c-0.2-1.9,6.8-6.2,4.2-6.4C324.4,1250.9,319.9,1252.6,321.8,1255.2z"/>
                  <path class="st5" d="M387.1,1244.2c0-0.3-1.3-0.8-1.2-0.1C385.8,1244.4,387.1,1244.9,387.1,1244.2z"/>
                  <path class="st5" d="M383.4,1247.5c-0.7,0.6-1.3,1.7,0,1.3C384.1,1248.2,384.7,1247.1,383.4,1247.5z"/>
                  <path class="st5" d="M497.9,942.7c4.2-1.3,8.5-4.8,9.8-8.2C504.5,937.2,501.2,939.9,497.9,942.7z"/>
                  <path class="st5" d="M623.1,923.8c-2.8,2.3-5.4,4.8-6.8,6.8C618.5,928.4,620.8,926.1,623.1,923.8z"/>
                  <path class="st5" d="M790.1,733.3c0.8-0.6,1.7-1.1,2.9-1.9c-0.9-0.9-1.7-1.8-2.5-2.8c-3.7,3.7-7.8,7.9-11.7,11.9
                     C782.6,738.3,786.4,735.9,790.1,733.3z"/>
                  <path class="st5" d="M490.7,948.7c-0.8,0.6-1.5,1.3-2.3,1.9C489.3,950,490.1,949.4,490.7,948.7z"/>
                  <path class="st5" d="M330.4,1158.4c-10,8-19.9,16-29.9,24c-0.5,0.4-0.9,0.9-1.3,1.3c1.5,2,2.9,3.9,4.3,5.8
                     c6.9-8.3,13.9-16.5,21.1-24.4C326.7,1162.9,328.6,1160.6,330.4,1158.4z"/>
                  <path class="st5" d="M802.5,717.8c2.1,2,2.4,5.6,1.6,9.9c15.6-14.7,30.2-28.6,45.3-42.8c-1.5-0.9-2.6-1.4-3.6-2.2
                     c-0.5-0.4-1-0.9-1.4-1.4c-2.9,2.7-5.9,5.4-8.9,8.1C825,698.7,812.7,708.9,802.5,717.8z"/>
                  <path class="st5" d="M328.6,1170.6c0.5-1.8,2.1-3.5,3-5.3c7-6.2,11.9-12.7,17-19.2c7.3-6.2,14-12.5,20.5-18.8
                     c-8.3,6.7-16.6,13.3-24.9,20c-2.4,2.6-4.9,5.3-7.2,7.9c-9.3,10.4-17.7,21.3-26.7,31.7c-1.5,1.7-3.1,3.4-4.6,5
                     c0.3,0.4,0.7,0.9,1,1.3c0.6,0.6,1,1.3,1.4,1.9C314.4,1186.9,320.1,1178.6,328.6,1170.6z"/>
                  <path class="st5" d="M613.6,933.3c-5.6,1.4-15.6,9.9-16.2,14.2c0.9-0.6,1.9-1.1,2.8-1.7C604.8,941.8,609.2,937.6,613.6,933.3z"/>
                  <path class="st5" d="M568,967.9c2.6-1.7,5.3-3.5,7.9-5.4C573.2,963.6,570.2,965.7,568,967.9z"/>
                  <path class="st5" d="M582.3,957.9c5-3.6,9.9-7.2,14.9-10.2C592.8,947.5,585.2,953.2,582.3,957.9z"/>
                  <path class="st5" d="M494.4,1028.3c-1.2,0.9-2.4,1.8-3.5,2.7c1.4-0.8,2.8-1.5,4.3-2.2C494.9,1028.6,494.6,1028.4,494.4,1028.3z"
                     />
                  <path class="st5" d="M348.5,1036.7c0-0.3-1.3-0.8-1.3-0.2C347.3,1036.9,348.5,1037.3,348.5,1036.7z"/>
                  <path class="st5" d="M879.8,627.1c-3.1,2.8-6.5,5.2-9.7,7.9c-30.2,25.7-60.1,51.7-90.5,77.2c-8,6.7-14.5,14-18.2,23.8
                     c-2.6,7.1-7.2,12.6-13.4,17c-17.7,12.5-35.2,25.2-52.9,37.7c-5.3,3.7-10.6,7.5-15.9,11.2c-11.8,11.2-23.3,22.5-35,33.5
                     c13.1-9.5,26.1-19.1,39.1-28.7c6.8-5,13.4-10,20.2-15c10.3-7.6,20.9-14.7,30.9-22.7c8.1-6.5,16.8-12,25.6-17.2
                     c6.3-6.5,12.5-13.1,19-19.5c3-3,6.1-5.8,9.3-8.6c-0.1-1.7,0.5-3.4,2.1-5.1c1.4-1.5,4.3-2.5,6.8-2.6c8-6.7,16.2-13.3,24-20.3
                     c7.2-6.5,14.3-13,21.4-19.5c0-1.9,0.8-3.9,2.2-5.4c1.7-1.8,4-2.6,6.3-2.4c12.9-11.9,25.8-23.8,38.9-35.2c-1.3-1.8-2.7-3.5-4-5.2
                     C884.1,625.4,882.3,624.8,879.8,627.1z"/>
                  <path class="st5" d="M331.2,1281.6c-2.2-1.6,3.3-2.1,1.2-3.6c-2.3,1.2-4.4,5.6-6.7,4.8c4.6-5.1,7.3-12.1,12.7-17.8
                     c3-3.2,7-5.3,10.4-8c7.6-6.2,14.6-13.6,22.6-20.3c1.5,2.7,3,2.2,4.4,2.5c-1.1,1.8,2.8-0.5,3.8,3c-2.6,2-2.6,2.6,0.1,2.5
                     c-1.1,2.2-4.1,4.1-4.2,6.4c3.9-1.7,8.5-6.1,9.2-8.2c1.1,0.9,2.1-0.4,3.1-0.2c0.7,1,0.3,2.3,1.9,0.9c1.6-1.7,3.3-2.6,4.9-1.9
                     c-0.9,1-5.9,4.6-3,4c2.5-1.6,5-5.7,7.6-6.3c4.2-6.7,7.9-13.6,11.5-20.7c-0.3-0.2-0.7-0.6-1-0.8c0.4-0.4,0.8,0,1.3,0.4
                     c0.3-0.6,0.6-1.2,0.9-1.8c-0.2,0-0.2-0.1,0-0.1c0.9-1.8,1.9-3.7,2.8-5.5l-2.6,2.4c-1.1-2.7,2.5-4.7,4.8-6.9c0,0,0.1,0,0.1,0
                     c0.4-0.8,0.8-1.6,1.2-2.3c-2.9,2.6-6.3,5.1-7.3,7.9c-3.6,0.4-3.4,5.7-8,4.6c1.3-3,5.8-5.5,6.7-8.6c2.6-1.3,1.4-3.2,3.6-4.6
                     c0.6-0.6-0.3-1.3-1.3-0.2c-0.7,0.6-1.1,1.5-1.2,2.4c-3.6,1.6-4.7,7.4-7.4,5.3c3.1-1.9,2.3-4.3,5.5-6.2c-0.3-2.6,1.9-4.8,1.1-7.4
                     c-2.3-0.1-4.5,4.1-6.8,3.5c-1.3-2.3-2.4,3.1-3.7,0.8c-2.7-0.6-0.6-0.9-1.3-2.7c-2.7,0.8-1.8,2.7-4.9,1.9c1.5-2.2,2.6-2.2,2.4-3.5
                     c-2.4-1-0.7,3-3.1,1.5c0.1,0.9-2,5.1-3.7,2c0.8-0.9,2.5-1.8,1.8-2.9c-4.3,3.2-6.3,5.6-10.4,8.1c-0.8-0.9,0.5-1.6-0.1-2.5
                     c-21.1,17.9-29.4,25-48.2,43.1c0.6-0.5,1.2-0.8,1.8-0.1c-4.7,4.8-3.9,4.5-9.1,9.5c-1.2,5.1-6.8,9.6-7.8,14.7l-11,9.9
                     c-2.2,0.7-4.5,2.5-6.7,4.8c-1.3-1.6-2.5,1.6-3.7,2l-1.8,1.7c-0.3,0.3-0.1,1.2,0.6,0.7c2-1.4,4-5.1,6.2-3
                     c-1.6,1.5-3.7,2.9-3.6,4.6l-7.3,6.6c-2.5,1.5-4.8,6.3-7.4,4.1c0.1-7.5,8.1-14,13.2-20.9c-1.2,0.4-2.4,3.7-3.7,2
                     c-0.1-1.7,2-3.1,3.6-4.6c1.2-1.5-1.1-3.5,1.1-4.9c0.7-1.4,4.4-2.4,3-4c-0.8,1.1-2.5,0.5-1.9-0.9c6.7-18,23.1-34.7,33.7-52.2
                     c13.1-13.6,23.8-27.5,34.6-41.4c-0.6,0.6-1.8,1.1-1.3-0.2c17.7-14.8,31.2-30.2,47-43.5c8.5-2.1,16.8-10.6,25.2-13.7l9.8-8.8
                     c11.5-7.1,23-13.2,34.5-19.4c7.3-7.8,14.7-9.6,22.1-16c9.7-3,19.3-8.3,29.1-10.7c7.3-5.9,14.7-10.1,22.1-14
                     c-0.5-5.3-1.5-10.5-3-15.9c-1.6,0.7-3.2,1.5-4.9,2.8c-2.8-4.4-2.8,1.6-6.2,0.4c-0.7-1.6,2.9-2.5,1.8-4.2l-7.3,6.6
                     c-6.7,1.5-11.5,6.4-19.1,8.2c-1-0.9,2.7-4.1-0.7-3.2c0.1,1.4-1.4,2.2-3.7,3.3c0.1,1.4-1.2,1.5-1.8,2.9c-1,2.2,0.2,0.7,0.1,2.5
                     c-3.5,1.4-7,4.1-10.4,6.8c-1.3-0.6-2.5-0.2-3.7,0.8c-1.2,2.7-2.5-1.9-3.7,0.8c-1.8,3.6-8.4,9.8-12.2,11c-1-1.2,1.3-1.9,0.6-3.1
                     c-5.3,5.1-10.7,6.3-16,9.2c-9.3,10-18.8,13.2-28.2,21.4c-1.7,1.5-0.3-3.3-2.5-0.3c-7.6,10.4-15.6,13.2-24.5,20.7
                     c-1.7,1.6,3.2,0.6,0.1,2.5c-8.7,8.2-17.5,15.6-26.3,22.3c-1.4,1.9-2.2,3.9-3.6,5.8c-14.2,12-24,24.5-35.3,36.9
                     c-1.7,7.8-7.1,15-14.4,22c-6.2,11.6-16.6,22.5-23.5,34c-7.8,7.2-9.8,15.1-16.3,22.4c-0.6,5.2-4.9,9.8-6.5,14.9
                     c-4.8,5.5-9.1,11.5-12.7,17.8c-1.3,1.5-2.6,3-3.9,4.4c4.8,0.9,9.7,1.6,14.8,1.8c10.1,0.6,19.7,0,28.9-1.7
                     C316.3,1295.1,323.5,1288.1,331.2,1281.6z M411.3,1213.5c0,0.7-1.2,0.2-1.2-0.2C410,1212.6,411.3,1213.1,411.3,1213.5z
                     M409.5,1215.1c0,0.7-1.2,0.2-1.2-0.2C408.2,1214.3,409.4,1214.8,409.5,1215.1z M407.2,1200.4c0,0.7-1.2,0.2-1.3-0.2
                     C406,1199.5,407.2,1200,407.2,1200.4z M406.1,1204c0.8-0.5,1,0.4,0.6,0.7C405.9,1205.2,405.7,1204.3,406.1,1204z M405.5,1205.8
                     c0,0.6-1.2,0.2-1.3-0.2C404.2,1205,405.5,1205.4,405.5,1205.8z M402,1214.1c0,0.7-1.3,0.2-1.2-0.2
                     C400.7,1213.3,401.9,1213.8,402,1214.1z M396.8,1208.4c0.8-0.5,1,0.4,0.6,0.7C396.7,1209.6,396.5,1208.7,396.8,1208.4z
                     M390.1,1241.5c0,0.7-1.2,0.2-1.2-0.1C388.9,1240.7,390.1,1241.1,390.1,1241.5z M378.9,1238.8c0,0.6-1.2,0.2-1.3-0.2
                     C377.6,1238,378.8,1238.4,378.9,1238.8z M368.2,1236.7c0.1,0.7-1.2,0.2-1.2-0.1C366.9,1235.9,368.2,1236.4,368.2,1236.7z
                     M368,1227.9c0,0.7-1.2,0.2-1.3-0.2C366.7,1227.1,368,1227.5,368,1227.9z M361.4,1237.7c0.8-0.5,1,0.4,0.6,0.7
                     C361.2,1238.9,361,1238,361.4,1237.7z M358.3,1240.5c0,0.6-1.2,0.2-1.3-0.2C357,1239.7,358.3,1240.1,358.3,1240.5z M357.1,1241.6
                     c0,0.6-1.2,0.2-1.2-0.2C355.8,1240.7,357.1,1241.2,357.1,1241.6z M357,1236.5c0,0.6-1.3,0.2-1.3-0.2
                     C355.7,1235.7,357,1236.2,357,1236.5z M354.6,1242.5c0,0.6-1.2,0.2-1.3-0.2C353.3,1241.7,354.6,1242.2,354.6,1242.5z
                     M364.5,1237.5c0.9-0.5,1.9,0.9,1.3,1.4c-8.3,8.3-12.7,13-23.2,20.9C345.6,1252.1,359.7,1242.9,364.5,1237.5z M325.6,1284
                     c0,0.6-1.2,0.2-1.3-0.2C324.4,1283.2,325.6,1283.6,325.6,1284z"/>
                  <path class="st5" d="M818.1,765c-0.2-0.5-0.4-0.9-0.9-1.9c-4.7,4.4-9.5,8.4-13.8,12.8c-2.7,2.7-5.4,5.4-8.1,8
                     c7.6-6.1,15.5-11.8,23.4-17.6C818.4,765.9,818.3,765.5,818.1,765z"/>
                  <path class="st5" d="M482.8,1037.1c-0.1,0.1-0.2,0.2-0.4,0.3c0,0,0.1,0,0.1,0C482.7,1037.3,482.8,1037.2,482.8,1037.1z"/>
                  <path class="st5" d="M776.2,799.3c7.1-6.5,14-12.7,20.8-19c6.2-5.8,12.4-11.6,18.6-17.4c1.1-1,2-2.2,2.7-3.5
                     c2.2-4.1,5.6-5.8,10-4.4c1.2,0.4,2.2,0.9,3,1.6c3.1-2.5,6.2-4.9,9.3-7.5c3.1-2.7,5.3-6.1,8.5-8.9c2.5-2.3,6-4.2,8.6-6.4
                     c3.5-3,7-6.1,10.4-9.1c-33.5,21.1-66.9,42.1-100.5,63.3C770.5,791.8,773.2,795.3,776.2,799.3z"/>
                  <path class="st5" d="M181.6,1177.7c5.4-4.4,10.3-9.8,15.9-14.3c7.1-5.8,14.5-11.3,21.9-17c-2.1-2.1-4.3-4.3-6.5-6.4
                     c-0.1-0.1-0.2-0.2-0.2-0.2c-10.4,9.3-20.1,18-30,27.9c-0.2,2.5-4,4.5-6.1,6.8c-4.1,0.5-2.3,3.4-4.8,5.6c-1-0.9,1.1-2.4-0.7-2
                     c-0.5,4.8-9.2,10.2-11.5,14.2l-8,7.2c0.3,0.6,0.7,1.1,1.1,1.7c2.9-2.6,5.7-5.4,8.8-8C167.8,1188,175,1183.1,181.6,1177.7z
                     M175.4,1176.9c0,0.6-1.2,0.2-1.2-0.2C174.1,1176.1,175.3,1176.6,175.4,1176.9z"/>
               </g>
            </g>
            <g id="HAND">
               <path class="st3" d="M805,844.7c5.7,32.8,11.4,65.7,12.7,98.9c1.3,33.2-1.9,67.1-13.6,98.2c-2.6,7-12.5,9.2-19.9,9.6
                  c-7.5,0.4-14.9-1.5-21.9-4.1c-36.7-13.6-65.8-45.9-75.6-83.8c-8.9-34.4-32.4-63.8-54.7-91.5c-6.1-3.3-10.3-9.8-10.7-16.7
                  s2.9-13.9,8.5-17.9c-7.5-1.4-10.7-11.7-6.7-18.1c3.9-6.5,12.5-8.9,20-7.7c7.5,1.2,14.1,5.4,20.4,9.6
                  c24.5,16.4,47.2,35.3,67.8,56.4c-4-16-15.2-30.1-29.9-37.6c-6.6-3.4-13.9-5.5-19.9-9.8c-6.1-4.3-10.9-11.6-9.2-18.8
                  c1.4-5.9,6.8-10.3,12.6-12c5.8-1.8,12.1-1.5,18.1-1.1c10.5,0.6,20.9,1.2,31.4,1.7c-14.1-14.8-27.5-28.2-41.6-43
                  c-4.1-4.3-8.3-8.8-10.3-14.3c-2-5.6-1.2-12.6,3.6-16.3c5.7-4.4,13.9-2,20.4,0.9c27.4,12.2,51.8,31.2,70.1,55
                  C791.1,800.9,801,821.4,805,844.7z"/>
               <path class="st2" d="M769.4,798.9c-10.8,1.4-21.8-2.4-30.9-8.2c-9.2-5.9-16.8-13.8-24.4-21.6c-7.6-7.9-15.2-15.7-22.8-23.6
                  c-3.4-3.5-6.7-7-10.1-10.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,2.5,0.1,5,0.9,7.3c2,5.6,6.2,10.1,10.3,14.3
                  c14.1,14.8,27.5,28.2,41.6,43c-4.6-0.3-9.2-0.5-13.9-0.8C735.6,804.2,753.5,804.6,769.4,798.9z"/>
               <path class="st2" d="M701.4,840c14,7.2,24.8,20.3,29.2,35.4c2.3,1.8,4.5,3.4,4.4,3.7c2.4-6.4,0.1-13.6-3.4-19.5
                  c-3.5-5.9-8-11.1-10.5-17.5c-2.5-6.3-2.4-14.4,2.6-19c-8.5,3.7-18,5-27.3,4.2c-4.3-0.4-13.5-3.2-17,1.3c0.7,0.6,1.4,1.2,2.1,1.7
                  C687.5,834.5,694.8,836.6,701.4,840z"/>
               <path class="st2" d="M771,848.9c-4-14.3-8.1-28.6-12.1-43c-1.2,13.5-1,27.1,0.7,40.5c0.4,3.4,1.2,7.3,3.9,8.9
                  c2.6,1.6,6.7-2.1,4.9-4.8"/>
               <path class="st2" d="M718.3,982.8c-27.6-15.5-44.6-47.9-41.7-79.4c-19.3-8.3-36.4-21.8-49-38.8c-4.7-6.3-7.8-17.1-0.7-20.5
                  c4.4-2.2,9.6,0.6,13.7,3.3c21.1,13.9,42.1,27.8,63.2,41.6c-7.5-8.4-15.5-16.5-23.8-24.1c-14.6-13.4-30.5-25.5-47.3-36.2
                  c-4-2.5-8.1-5-12.2-7.4c-3.8,6.5-0.6,16.6,6.8,17.9c-5.6,4-8.9,11.1-8.5,17.9s4.7,13.4,10.7,16.7c22.3,27.7,45.9,57.1,54.7,91.5
                  c4.8,18.7,14.4,36,27.2,50.4C711.8,1003.8,709.6,990.3,718.3,982.8z"/>
               <path class="st2" d="M752.5,994.7c0-2.5-1.5-5.7-2-8.2c-0.6-2.9-1.2-5.8-1.7-8.7c-1-5.7-1.7-11.4-2.1-17.2
                  c-0.9-11.7-0.6-23.4,0.8-35c0.1-1.1,0.7-10.1,2.1-9.9c1.2,0.1,1.7,6.3,1.8,7.1c1.4,10,0.8,20.3,0.9,30.3
                  C752.3,967,752.4,980.9,752.5,994.7z"/>
               <path class="st2" d="M733,894.1c3,3,3.8,8,2,11.8c-1.8,3.9-6.2,6.3-10.4,6c3.8,2.4,8.9,3,12.9,0.9c4-2.1,6.6-6.8,5.7-11.3
                  s-5.6-7.9-10-7"/>
               <path class="st2" d="M785.8,855.9c8.9,0.1,17.1-7.7,17.3-16.6c3.8,5.9,3.5,14.2-0.7,19.8c-4.3,5.6-12.2,8.1-18.9,6.1
                  c0.3-2.3,0.6-4.7,0.8-7"/>
               <path class="st2" d="M788.6,955.5c-0.2-2.3-1.3-5.1-1.9-7.3c-0.6-2.6-1.2-5.2-1.7-7.8c-1-5.1-1.9-10.3-2.5-15.5
                  c-1.4-10.5-2-21.2-1.8-31.8c0-1-0.2-9.2,0.7-9.1c0.8,0.1,1.5,5.6,1.6,6.3c1.6,9,1.9,18.3,2.6,27.5
                  C786.6,930.4,787.6,943,788.6,955.5z"/>
            </g>
         </g>
         <g id="NOTES">
            <path d="M129.7,325.4c5.1-11.9,5.1-23.8,0.1-35.7c-3.2-7.6-9.6-12.7-17-14c0,0.6-0.1,1.2-0.1,1.8c0,25-0.1,50,0,75
               c0,7-2.9,12.1-8.6,15.8c-10,6.6-24.9,5.4-33.2-2.7c-6.8-6.7-6.8-16.5,0.1-23.2c5.6-5.4,12.5-7.2,20.1-6.8c4.3,0.2,8.3,1.4,12.3,3.7
               c0-0.8,0.1-1.3,0.1-1.9c0-26.4,0-52.8,0-79.2c0-1.4,0.4-2,1.8-2.2c2.1-0.2,4.2-0.7,6.4-0.9c0.4-0.1,1,0.1,1.2,0.4
               c3.9,6.6,9.5,12,14.1,18c4.1,5.4,7.4,11.1,8.9,17.8c1.7,8.1,2.2,16.2-0.1,24.2c-0.9,3.2-2.4,6.1-4.6,8.7c-0.4,0.5-0.8,1-1.2,1.6
               C129.9,325.6,129.8,325.5,129.7,325.4z"/>
            <path d="M866.8,490.9c5.8-13.5,5.7-27,0.1-40.5c-3.6-8.6-10.9-14.4-19.3-15.9c-0.1,0.7-0.2,1.3-0.2,2c0,28.4-0.1,56.7,0,85.1
               c0,7.9-3.3,13.7-9.7,18c-11.4,7.5-28.3,6.1-37.6-3.1c-7.7-7.6-7.8-18.8,0.1-26.3c6.4-6.1,14.2-8.2,22.8-7.7
               c4.9,0.3,9.5,1.6,13.9,4.2c0-0.9,0.1-1.5,0.1-2.1c0-29.9,0-59.9,0-89.8c0-1.6,0.4-2.3,2.1-2.4c2.4-0.2,4.8-0.8,7.2-1.1
               c0.4-0.1,1.2,0.1,1.3,0.4c4.4,7.5,10.7,13.6,16,20.4c4.7,6.1,8.4,12.6,10.1,20.2c2,9.2,2.5,18.3-0.2,27.5c-1,3.6-2.8,6.9-5.2,9.8
               c-0.5,0.6-0.9,1.2-1.4,1.8C867,491.2,866.9,491.1,866.8,490.9z"/>
            <path class="alt-shape" d="M882.7,92.2c3.8-8.9,3.8-17.8,0.1-26.7c-2.4-5.7-7.2-9.5-12.7-10.4c0,0.4-0.1,0.9-0.1,1.3c0,18.7-0.1,37.3,0,56
               c0,5.2-2.2,9-6.4,11.8c-7.5,5-18.6,4-24.8-2.1c-5.1-5-5.1-12.3,0.1-17.3c4.2-4,9.4-5.4,15-5.1c3.2,0.2,6.2,1.1,9.2,2.8
               c0-0.6,0.1-1,0.1-1.4c0-19.7,0-39.4,0-59.1c0-1.1,0.3-1.5,1.4-1.6c1.6-0.2,3.2-0.5,4.8-0.7c0.3,0,0.8,0.1,0.9,0.3
               c2.9,5,7.1,8.9,10.5,13.5c3.1,4,5.5,8.3,6.6,13.3c1.3,6,1.6,12.1-0.1,18.1c-0.7,2.4-1.8,4.6-3.4,6.5c-0.3,0.4-0.6,0.8-0.9,1.2
               C882.9,92.4,882.8,92.3,882.7,92.2z"/>
            <g class="alt-shape" id="T9y8ZE.tif">
               <g>
                  <path d="M153.9,523.7c0-0.5,0-0.9,0-1.3c0-14.6,0-29.2,0-43.7c0-0.8,0.3-1,1.1-1.1c16-2.4,32.1-4.9,48.1-7.3
                     c0.4-0.1,0.7-0.1,1.2-0.1c0,0.4,0,0.8,0,1.1c0,17.3,0,34.7,0,52c0,3.6-1.6,6.3-4.6,8.5c-5.8,4.1-15.6,4.1-21.4-0.1
                     c-6.1-4.4-5.9-11.6,0.6-15.6c5.8-3.6,12-3.7,18.4-1.1c0.3,0.1,0.5,0.2,0.7,0.3c0,0,0.1,0,0.3,0c0-9.6,0-19.2,0-28.8
                     c-0.4,0-0.8,0.1-1.2,0.1c-12.2,1.8-24.3,3.7-36.5,5.5c-0.9,0.1-0.9,0.6-0.9,1.2c0,12.7-0.1,25.3,0,38c0,5-2.6,8.3-7.4,10.3
                     c-6.5,2.7-12.9,2.3-18.6-1.6c-6-4.1-6-11.2,0.1-15.2c5.8-3.9,12.1-4.1,18.6-1.5C153,523.3,153.4,523.5,153.9,523.7z"/>
               </g>
            </g>
            <g class="alt-shape" id="T9y8ZE.tif_1_">
               <g>
                  <path d="M184.9,123c0-0.5,0-0.9,0-1.3c0-14.7,0-29.4,0-44c0-0.8,0.2-1,1-1.2c14.5-2.4,29-4.9,43.5-7.4c0.3-0.1,0.7-0.1,1.1-0.1
                     c0,0.4,0,0.8,0,1.2c0,17.4,0,34.9,0,52.3c0,3.6-1.4,6.4-4.2,8.6c-5.3,4.1-14.1,4.1-19.3-0.1c-5.5-4.5-5.4-11.7,0.5-15.7
                     c5.2-3.6,10.9-3.7,16.6-1.1c0.2,0.1,0.4,0.2,0.7,0.3c0,0,0.1,0,0.2,0c0-9.6,0-19.3,0-29c-0.4,0-0.7,0.1-1.1,0.1
                     c-11,1.9-22,3.7-33,5.6c-0.8,0.1-0.8,0.6-0.8,1.2c0,12.7-0.1,25.5,0,38.2c0,5-2.3,8.4-6.7,10.3c-5.9,2.7-11.6,2.3-16.8-1.6
                     c-5.4-4.1-5.4-11.3,0.1-15.3c5.2-3.9,11-4.1,16.9-1.5C184.1,122.6,184.4,122.8,184.9,123z"/>
               </g>
            </g>
            <g id="T9y8ZE.tif_2_">
               <g>
                  <path d="M797.1,301.9c0-0.4,0-0.6,0-0.8c0-9.7,0-19.4,0-29.2c0-0.5,0.2-0.7,0.7-0.8c10.7-1.6,21.4-3.2,32.1-4.9
                     c0.2,0,0.5-0.1,0.8-0.1c0,0.3,0,0.5,0,0.8c0,11.6,0,23.1,0,34.7c0,2.4-1,4.2-3.1,5.7c-3.9,2.7-10.4,2.7-14.2-0.1
                     c-4.1-2.9-4-7.7,0.4-10.4c3.9-2.4,8-2.5,12.2-0.7c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.1,0,0.2,0c0-6.4,0-12.8,0-19.2
                     c-0.3,0-0.5,0.1-0.8,0.1c-8.1,1.2-16.2,2.5-24.3,3.7c-0.6,0.1-0.6,0.4-0.6,0.8c0,8.4,0,16.9,0,25.3c0,3.3-1.7,5.5-4.9,6.8
                     c-4.3,1.8-8.6,1.5-12.4-1.1c-4-2.7-4-7.5,0.1-10.1c3.9-2.6,8.1-2.7,12.4-1C796.5,301.6,796.7,301.7,797.1,301.9z"/>
               </g>
            </g>
         </g>
      </svg>
   </div>
</template>

<script>
export default {
  name: 'MeMusic',
}
</script>

<style lang="scss" scoped>
.st0{fill:#3B3B3D;}
.st1{fill:#0E0E11;}
.st2{fill:#D19477;}
.st3{fill:#F2CCB7;}
.st4{fill:#281305;}
.st5{fill:#84522C;}
.st6{fill:#00A0E7;}
.st7{fill:#FFFFFF;}
.st8{fill:#FEE3D4;}
.st9{fill:#B78B6E;}
.st10{fill:#B41C1E;}
.st11{fill:#D9D30E;}
.st12{opacity:0.4;fill-rule:evenodd;clip-rule:evenodd;fill:#6E4C41;enable-background:new    ;}
.st13{fill:#DB6F33;}
.st14{fill:#D2D2D2;}
.st15{opacity:0.47;}

@keyframes drift {
   from {
      opacity: 1;
      transform: translateY(0);
   }

   to {
      transform: translateY(-100px);
      opacity: 0;
   }
}

@keyframes handOnGuit {
   0% {
      transform: translate(-20px, 40px);
   }

   40% {
      transform: translate(-18px, 40px);
   }
}

@keyframes bop {
   from {
      transform: translate(0, -2px);
   }

   to {
      transform: translate(0, 2px);
   }
}

.me-music-wrapper {
   margin-top: -20px;

   ellipse, path {
      transition: all 0.2s;
   }

   #NOTES {
      * {
         fill: var(--primary);
         opacity: 0;
      }

      .alt-shape {
         fill: var(--accent);

         * {
            fill: var(--accent);
         }
      }
   }

   &:hover {
      .me-music {
         animation-name: bop;
         animation-duration: 1.5s;
         animation-iteration-count: infinite;
      }

      #HEAD {
         animation-name: bop;
         animation-duration: 0.5s;
         animation-iteration-count: infinite;
         animation-delay: 0.5s;
      }

      #NOTES {
         * {
            animation-name: drift;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-fill-mode: backwards;
         }
      }

      #HAND {
         animation-name: handOnGuit;
         animation-duration: 4s;
         animation-iteration-count: infinite;
         animation-fill-mode: forwards;
      }

      .left-eyebrow {
         transform: translateY(-15px);
      }
   }
}


</style>
